<template>
  <VDialog v-model="value" class="text-center " width="300" :dir="$i18n.locale=='ar'?'rtl':'ltr'">
    <v-card class="ma-auto " width="300">
        <v-card-title class="bg-primary text-white">
          <h3>
            {{ $t("globals.delete-confirm") }}
          </h3>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-3 py-2">
          <small>{{ $t("globals.confirm_delete") }}</small>
        </v-card-text>
        <v-divider />
        <v-card-actions style="min-height:40px; height:40px" class="pa-0 ma-0 d-flex mx-3"> 
            <v-btn @click="confirmDelete()" :loading="loading"  class="w-50" ripple>
              <span class="text-error">{{ $t("globals.delete") }}</span>
            </v-btn>
            <v-divider vertical/>
            <v-btn @click="value=false" :disabled="loading" class="w-50" >
              <span class="text-primary"> {{ $t("globals.cancel") }}</span>
            </v-btn>
        </v-card-actions>
      </v-card>
  </VDialog>
</template>
<script>
export default {
  props: {
    data_message: {
      type: Array,
      required: true,
    },
    title: String,
    modelValue: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    confirmDelete() {
      this.$emit("confirm-delete");
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>