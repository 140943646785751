<template>
  <v-form ref="form">
    <v-card class="pa-2">
      <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        <v-col cols="3">
          <v-autocomplete
            color="indigo"
            v-model="filter_data.fk_monthsemester"
            density="compact"
            item-title="month_name"
            item-value="month"
            prepend-inner-icon="mdi-calendar-month"
            :items="months"
            :label="$t('month.select')"
            :rules="[$required]"
            @update:model-value="getCurrentMonth"
          />
        </v-col>
        <v-col cols="2">
          <DatetimePicker
            v-model="filter_data.date"
            :minDate="minDate"
            :maxDate="maxDate"
            :rules="[$required]"
            :disabled="filter_data.fk_monthsemester ? false : true"
            @update:model-value="(recordVisualSubjects = []), (exist = 0)"
            forma="dd"
            readOnly
          />
        </v-col>
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="levels"
            v-model="filter_data.fk_level"
            prepend-inner-icon="mdi-stairs-box"
            item-title="level_with_phase_name"
            item-value="id"
            :label="$t('summer.level.name_ar')"
            clearable
            hide-details="auto"
            density="compact"
            @update:modelValue="
              getVisualSubjects(), ((this.filter_data.subject_id = null), (exist = 0))
            "
            :rules="[$required]"
          ></VAutocomplete>
        </VCol>
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="typesVisualSubjects"
            v-model="filter_data.typesubject"
            prepend-inner-icon="mdi-video"
            item-title="name"
            item-value="id"
            :label="$t('subject.type_visual_subjects')"
            clearable
            hide-details="auto"
            density="compact"
            :rules="[$required]"
            @update:modelValue="
              getVisualSubjects(), ((this.filter_data.subject_id = null), (exist = 0))
            "
          ></VAutocomplete>
        </VCol>
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="visualSubjects"
            v-model="filter_data.subject_id"
            prepend-inner-icon="mdi-shape-outline"
            item-title="name"
            item-value="id"
            :label="$t('subject.name_visual_subject')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            :rules="[$required]"
            @update:model-value="exist = 0"
          ></VAutocomplete>
        </VCol>
        <v-col cols="1" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="primary"
            :loading="loading"
            @click="getItems()"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-table
      density="compact"
      class="rounded elevation-1 striped-table"
      fixed-header
      :height="months?.length > 10 ? 400 : null"
      v-if="exist && recordVisualSubjects.length > 0"
    >
      <thead>
        <tr>
          <th>{{ $t("division.name") }}</th>
          <th v-if="!filter_data.subject_id">{{ $t("subject.name") }}</th>
          <th>{{ $t("summer.activity.is_done") }}</th>
          <th>{{ $t("summer.activity.start_show") }}</th>
          <th>{{ $t("summer.activity.time_show") }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(visual, vIndex) in recordVisualSubjects" :key="vIndex">
          <template v-for="(visual_subject, sIndex) in visual.divisions" :key="sIndex">
            <tr
              v-for="(student, wIndex) in visual_subject.list_visual_subjects"
              :key="wIndex"
            >
              <td
                v-if="wIndex === 0"
                :rowspan="visual_subject.list_visual_subjects.length"
              >
                {{ visual.level_name_ar }} - {{ visual_subject.division_name }}
              </td>
              <td v-if="!filter_data.subject_id">{{ student.subject_name }}</td>
              <td>
                <VCheckbox v-model="student.is_done" hide-details dense></VCheckbox>
              </td>
              <td>
                <VTextField
                  v-model="student.showtime"
                  :label="$t('school.schedule_start_time')"
                  density="compact"
                  type="time"
                  clearable
                >
                </VTextField>
              </td>
              <td>
                <VTextField
                  v-model="student.dateline"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('summer.activity.time_show')"
                  density="compact"
                  :placeholder="$t('summer.activity.time_show')"
                  :rules="[$ar_letters_only, $max_length(50)]"
                >
                </VTextField>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
      <v-card-actions class="mx-2 pt-0">
        <v-btn @click="saveItem" :loading="saveLoading" class="bg-primary" size="small">
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-table>
  </v-form>
</template>
<script>
import moment from "moment-hijri";
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
import { required, helpers } from "@vuelidate/validators";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      v$: useValidate(),
      addDialog: false,
      protected_records: false,
      data_message: [],
      recordVisualSubjects: [],
      visualSubjects: [],
      months: [],
      typesVisualSubjects: [],
      show: true,
      minDate: null,
      maxDate: null,
      filter_data: {
        fk_level: undefined,
        recordVisualSubjects: [],
      },
      loading: false,
      saveLoading: false,
      monthsemester: null,
      exist: undefined,
      dynamicHeaders: [],
      quranGroupChoice: [],
      saveDataLoading: false,
      editDataLoading: false,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
    };
  },
  computed: {
    ...mapState({
      levels: (state) => state.summer.levels,
      user: (state) => state.User,
    }),
    tableList() {
      if (this.recordVisualSubjects) {
        return JSON.parse(JSON.stringify(this.recordVisualSubjects));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.getTypesVisualSubjects();
      await this.getLevels();
      await this.getMonths();
      await this.$store.commit("updateLoadingValue", true);
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  validations() {
    return {
      filter_data: {
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        subject_id: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  methods: {
    getCurrentMonth() {
      if(this.filter_data.fk_monthsemester) {
        this.recordVisualSubjects = [];
        this.show = false;
        this.filter_data.date = null;

        const month =
          this.filter_data.fk_monthsemester < 10
            ? "0" + this.filter_data.fk_monthsemester.toString()
            : this.filter_data.fk_monthsemester;
        const now = moment();
        const startOfMonth = now.iYear() + "-" + month + "-01";
        const endOfMonth =
          now.iYear() +
          "-" +
          this.filter_data.fk_monthsemester +
          "-" +
          now.endOf("iMonth").iDate();
        this.minDate = startOfMonth;
        this.maxDate = endOfMonth;
        this.filter_data.date = this.minDate;
        this.$nextTick(() => {
          this.show = true;
        });
        }
    },
    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          this.filter_data.fk_monthsemester = response?.data?.find(
            (e) => e.current_month
          ).month;
          this.getCurrentMonth();
        })
        // .catch(() => {
        //   this.$emit("errorAlert", this.$t("globals.error_in_data"));
        // });
    },
    async getTypesVisualSubjects() {
      await this.axios(`${this.base_url}/choices/typesubject-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.typesVisualSubjects = response.data;
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    ...mapActions({
      getLevels: "summer/getLevels",
    }),
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    async getQuranGroupsChoice() {
      await this.axios(`${this.base_url}api/summer/quran-groups/list/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.quranGroupChoice = response.data;
      });
    },
    async getVisualSubjects() {
      if (this.filter_data.fk_level && this.filter_data.typesubject) {
        let response = await this.axios.get(
          this.base_url +
            `api/visual-subject/list/?fk_level=${this.filter_data.fk_level}&typesubject=${this.filter_data.typesubject}`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );
        this.visualSubjects = response.data;
      }
    },
    async getItems() {
      this.monthsemester = this.months.find(
        (month) => month.month === this.filter_data.fk_monthsemester
      );
      this.exist = undefined;
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        await this.axios(`${this.base_url}api/visuallog/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_level: this.filter_data.fk_level,
            subject_id: this.filter_data.subject_id,
            fk_monthsemester: this.monthsemester.id,
            date: this.filter_data.date,
          },
        })
          .then((response) => {
            this.exist = true;
            this.recordVisualSubjects = response.data;
            this.loading = false;
            if (this.recordVisualSubjects.length === 0) {
              this.$emit("warningAlert", this.$t("globals.not-found"));
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
    async saveItem() {
      let combinedSubjects = this.recordVisualSubjects.flatMap((level) =>
        level.divisions.flatMap((division) => division.list_visual_subjects)
      );
      combinedSubjects = combinedSubjects.map((item) => {
        return {
          ...item,
          date: this.filter_data.date,
        };
      });
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        await this.axios
          .post(this.base_url + `api/visuallog/`, combinedSubjects, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
          })
          .catch((error) => {
            if (error.response.data[0]) {
              this.$emit("warningAlert", this.$t("summer.enter-time"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
  },
};
</script>
