import i18n from '@/i18n'
const utils = {
  install(Vue) {
    // Required field validation
    Vue.config.globalProperties.$getBooleanValue = (value) => {
      return value ? i18n.global.t('globals.yes'):i18n.global.t('globals.no')
    }
    // Length validation
  }
}

export default utils
