<template>
  <v-expansion-panels v-if="role < 4" class="mb-2">
    <v-expansion-panel>
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <VCol cols="6" md="3" v-if="role < 2">
            <VAutocomplete
              :items="filterGovernorates(1)"
              v-model="filter.governorate"
              item-title="name_ar"
              item-value="id"
              hide-details="auto"
              :label="$t('globals.governorate')"
              density="compact"
              clearable
              prepend-inner-icon="mdi-city"
              @update:modelValue="filter.directorate = null"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="3" v-if="role <= 2 || role == 9 || role == 8">
            <VAutocomplete
              :items="filterDirectorates(filter.governorate)"
              v-model="filter.directorate"
              item-title="name_ar"
              item-value="id"
              hide-details="auto"
              clearable
              :label="$t('globals.directorate')"
              density="compact"
              prepend-inner-icon="mdi-map-outline"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="3">
            <VAutocomplete
              :items="[
                { id: true, val: $t('summer.funded') },
                { id: false, val: $t('summer.not-funded') },
              ]"
              v-model="filter.is_funded"
              item-title="val"
              item-value="id"
              hide-details="auto"
              clearable
              :label="$t('summer.fundation')"
              density="compact"
              prepend-inner-icon="mdi-currency-usd"
            ></VAutocomplete>
          </VCol>

          <VCol cols="6" md="2" class="mt-2">
            <v-btn
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100 text-white"
              color="primary"
              :loading="loading"
              @click="getSummerCamp((page = 1))"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </VCol>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <DataTable
    :headers="headers"
    :items="tableList"
    :method="getSummerCamp"
    :pagination="pagination"
    :editItem="editItem"
    :delItem ="deleteSummerSchool"
    :create="createSchool"
    :exports="exportToExcel"
    :del_perm="CheckGetPermission('legal_school.delete_schoolsummer',['sys_admin','gen_admin'])"
    :edit_perm="CheckGetPermission('legal_school.change_schoolsummer',['sys_admin','gen_admin'])"
    :add_perm="CheckGetPermission('legal_school.add_schoolsummer',['sys_admin','gen_admin'])"
  >
    <template v-slot:item.is_funded="{ item }">
      {{ $getBooleanValue(item.is_funded) }}
    </template>
    <template v-slot:item.have_license="{ item }">
      {{ $getBooleanValue(item.have_license) }}
    </template>
    <template v-slot:item.is_garden="{ item }">
      {{ $getBooleanValue(item.is_garden) }}
    </template>
    <template v-slot:item.logo="{ item }">
      <v-dialog max-width="900" v-if="item.logo">
        <template v-slot:activator="{ props: activatorProps }">
          <dir :title="$t('globals.show-logo')">
            <v-avatar v-bind="activatorProps" class="border">
              <v-img :src="`${base_url}/${item.logo}`"></v-img>
            </v-avatar>
          </dir>
        </template>
        <template v-slot:default="{ isActive }">
          <v-card :title="item.name_ar" :subtitle="$t('summer.summer_logo')">
            <template v-slot:append>
              <dir :title="$t('globals.close')">
                <v-btn @click="isActive.value = false" icon size="small">
                  <v-icon color="error">mdi-close</v-icon>
                </v-btn>
              </dir>
            </template>
            <v-responsive class="mx-5 my-2 border-md border-dashed">
              <v-img
                :src="`${base_url}/${item.logo}`"
                class="ma-1 rounded-lg"
              ></v-img>
            </v-responsive>
          </v-card>
        </template>
      </v-dialog>
    </template>
    <template v-slot:item.license_image="{ item }">
      <v-dialog max-width="900" v-if="item.license_image">
        <template v-slot:activator="{ props: activatorProps }">
          <dir :title="$t('globals.show-license')">
            <v-avatar v-bind="activatorProps" class="border">
              <v-img :src="`${base_url}/${item.license_image}`"></v-img>
            </v-avatar>
          </dir>
        </template>
        <template v-slot:default="{ isActive }">
          <v-card
            :title="item.name_ar"
            :subtitle="`${$t('globals.school-license')} : ${
              item.license_number
            }`"
          >
            <template v-slot:append>
              <dir :title="$t('globals.close')">
                <v-btn @click="isActive.value = false" icon size="small">
                  <v-icon color="error">mdi-close</v-icon>
                </v-btn>
              </dir>
            </template>
            <v-responsive class="mx-5 my-2 border-md border-dashed">
              <v-img
                :src="`${base_url}/${item.license_image}`"
                class="ma-1 rounded-lg"
              ></v-img>
            </v-responsive>
          </v-card>
        </template>
      </v-dialog>
    </template>
    <template v-slot:item.supervisors_names="{ item }">
      <v-chip
        v-for="sup in item.supervisors_names"
        :key="sup"
        class="px-2 py-1"
      >
        {{ sup }}
      </v-chip>
    </template>
  </DataTable>
  <VDialog v-model="showImg_dialog" max-width="400px" max-height="400px">
    <VCard align="center">
      <VCardTitle>
        <h1 class="text-h6">{{ dialog_title }}</h1>
      </VCardTitle>
      <VCardItem align="center">
        <VImg :src="dialog_image" max-height="300px"></VImg>
      </VCardItem>
      <VCardText>
        <tr v-for="(item, index) in dialog_text" :key="index">
          <td class="text-grey-darken-1 font-weight-bold">{{ item.type }} :</td>
          <td>{{ item.contact }}</td>
        </tr>
      </VCardText>
      <VCardActions>
        <VBtn class="bg-info" @click="showImg_dialog = false">{{
          $t("globals.ok")
        }}</VBtn>
      </VCardActions>
    </VCard>
  </VDialog>
  <alert-cascade-records
    v-model="alert_cascade"
    :data_message="data_message"
    :title="alert_cascade_title"
    :loading="deleteDataLoading"
    @confirm-delete="confirmDelete"
  >
  </alert-cascade-records>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
const englishOnly = helpers.regex(/^[a-zA-Z\-'\s]+$/);
const arabicOnly = helpers.regex(
  /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
);
export default {
  data() {
    return {
      filter: {
        country: undefined,
        governorate: undefined,
        directorate: undefined,
        is_funded: undefined,
      },
      schools_summer: [],
      protected_records: false,
      data_message: [],
      deleteDataLoading: false,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },

      alert: true,
      loading: false,
      v$: useValidate(),
      country: {},
      updated_id: undefined,
      del_dialog: false,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.center_name_ar"), key: "name_ar" },
        {
          title: this.$t("summer.center_name_en"),
          key: "name_en",
          show: false,
        },
        {
          title: this.$t("summer.manager"),
          key: "manager_name",
          sortable: false,
        },
        {
          title: this.$t("globals.phone"),
          key: "manager_phone",
          sortable: false,
        },
        { title: this.$t("summer.summer_logo"), key: "logo", sortable: false },
        { title: this.$t("summer.class-count"), key: "class_count" },
        { title: this.$t("globals.governorate"), key: "governorate_name" },
        { title: this.$t("globals.directorate"), key: "directorate_name" },
        { title: this.$t("summer.is-funded"), key: "is_funded" },
        { title: this.$t("summer.solitude"), key: "address" },
        {
          title: this.$t("school.establish_date"),
          key: "date_of_establishment",
        },
        {
          title: this.$t("summer.overall-assessment"),
          key: "evaluation_name",
          visible: false,
          sortable: false,
        },
        {
          title: this.$t("summer.school-has-license"),
          key: "have_license",
          show: false,
        },
        {
          title: this.$t("school.license_image"),
          key: "license_image",
          show: false,
          sortable: false,
        },
        {
          title: this.$t("school.license_number"),
          key: "license_number",
          show: false,
        },
        {
          title: this.$t("summer.school-has-garden"),
          key: "is_garden",
          show: false,
        },
        {
          title: this.$t("summer.place-type"),
          key: "place_type_name",
          show: false,
        },
        { title: this.$t("summer.place-name"), key: "place_name", show: false },
        {
          title: this.$t("summer.shift-time"),
          key: "shift_time_name",
          show: false,
        },
        {
          title: this.$t("school.students_type"),
          key: "students_type_name",
          show: false,
        },
        {
          title: this.$t("globals.data-entry"),
          key: "data_entry_name",
          show: false,
        },
        {
          title: this.$t("globals.updated-by"),
          key: "updated_by_name",
          show: false,
        },
        {
          title: this.$t("globals.created-at"),
          key: "created_at",
          show: false,
        },
        {
          title: this.$t("globals.updated-at"),
          key: "updated_at",
          show: false,
        },
        {
          title: this.$t("summer.supervising-authority-name"),
          key: "supervisors_names",
          sortable: false,
          show: false,
        },
        {
          title: this.$t("globals.note"),
          key: "note",
          sortable: false,
          show: false,
        },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      showImg_dialog: false,
      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      placeTypesList: (state) => state.summer.placeTypesList,
      evaluations: (state) => state.summer.evaluations,
      studentTypes: (state) => state.summer.studentTypes,
      shiftTimes: (state) => state.summer.shiftTimes,
      shoolTypes: (state) => state.summer.shoolTypes,
      supervisors: (state) => state.summer.supervisors,
      countries: (state) => state.school.countries,
      user: (state) => state.User,
      role: (state) => state.role,
    }),
    // role(){
    //     return localStorage.getItem('role')
    // },
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    tableList() {
      if (this.schools_summer) {
        var schools = JSON.parse(JSON.stringify(this.schools_summer));
        return schools;
      }
    },
    excelListData() {
      if (this.schools_summer) {
        var schools = JSON.parse(
          JSON.stringify(this.schools_summer),
          (key, value) => (value === null ? "---" : value)
        );
        var changed_schools = schools.map((school) => {
          const manager = school.manager;
          return {
            ...school,
            manager_name: manager?.name_ar ?? "",
            manager_phone: manager?.phone_number ?? "",
            shift_time: this.getShiftTimesName(school.shift_time),
            is_garden: this.$getBooleanValue(school.is_garden),
            is_funded: this.$getBooleanValue(school.is_funded),
            evaluation: this.getEvaluationsName(school.evaluation),
            have_license: this.$getBooleanValue(school.have_license),
            school_type: this.getShoolTypesName(school.school_type),
            place_type: this.getPlaceTypesListName(school.place_type),
            students_type: this.getStudentTypesName(school.students_type),
            fk_supervisor: this.getSupervisorsName(school.fk_supervisor),
          };
        });
        return changed_schools;
      }
    },
  },
  validations() {
    return {
      country: {
        name_ar: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          arabicOnly: helpers.withMessage(
            this.$t("globals.must_be_letters"),
            arabicOnly
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        name_en: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          englishOnly: helpers.withMessage(
            this.$t("globals.must_be_letters_en"),
            englishOnly
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        nati_name_ar: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          arabicOnly: helpers.withMessage(
            this.$t("globals.must_be_letters"),
            arabicOnly
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        nati_name_en: {
          required: helpers.withMessage(
            this.$t("globals.required_field"),
            required
          ),
          englishOnly: helpers.withMessage(
            this.$t("globals.must_be_letters_en"),
            englishOnly
          ),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(100)
          ),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(250)
          ),
        },
      },
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if (
        this.checkrole([
          "sys_admin",
          "gen_admin",
          "dir_admin",
          "gov_admin",
          "summer_admin",
        ])
      )
        await this.getSummerCamp();
      await this.getCountries();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getPlaceTypesList();
      await this.getEvaluations();
      await this.getStudentTypes();
      await this.getShiftTimes();
      await this.getShoolTypes();
      await this.getSupervisors();
      if (this.role == 2 || this.role == 3) {
        this.getGovernorateData();
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    createSchool() {
      this.$router.push({ name: "summer-school" });
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    async exportToExcel() {
      const response = await this.axios(`${this.base_url}/api/summer/school`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      var schools = JSON.parse(
        JSON.stringify(response.data?.results, (key, value) =>
          value == null ? "" : value
        )
      );
      schools?.forEach((school) => {
        school.is_funded = this.$getBooleanValue(school.is_funded);
        school.have_license = this.$getBooleanValue(school.have_license);
        school.is_garden = this.$getBooleanValue(school.is_garden);
      });
      const headers = this.headers
        .filter(
          (el) => !["actions", "logo", "license_image", "note"].includes(el.key)
        )
        .map((item) => {
          return { header: item.title, key: item.key };
        });
      this.$ExportToExcel(headers, schools, "بيانات الدورات الصيفية");
    },
    getGovernorateData() {
      this.axios(`${this.base_url}directorate/data/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.filter.country = response.data.country;
        this.filter.governorate = response.data.governorate;
      });
    },
    ...mapActions({
      getPlaceTypesList: "summer/getPlaceTypesList",
      getEvaluations: "summer/getEvaluations",
      getStudentTypes: "summer/getStudentTypes",
      getShiftTimes: "summer/getShiftTimes",
      getShoolTypes: "summer/getShoolTypes",
      getSupervisors: "summer/getSupervisors",
      getSupervisors: "summer/getSupervisors",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    getPlaceTypesListName(value) {
      let placeType = this.placeTypesList.find(
        (element) => element.id === value
      );
      return placeType ? placeType.name : "";
    },
    getEvaluationsName(value) {
      let evaluation = this.evaluations.find((element) => element.id === value);
      return evaluation ? evaluation.name : "";
    },
    getStudentTypesName(value) {
      let studentType = this.studentTypes.find(
        (element) => element.id === value
      );
      return studentType ? studentType.name : "";
    },
    getShiftTimesName(value) {
      let shiftTime = this.shiftTimes.find((element) => element.id === value);
      return shiftTime ? shiftTime.name : "";
    },
    getShoolTypesName(value) {
      let shoolType = this.shoolTypes.find((element) => element.id === value);
      return shoolType ? shoolType.name : "";
    },
    getSupervisorsName(value) {
      let supervisors = this.supervisors
        .filter((obj) => value.includes(obj.id))
        .map((obj) => obj.name)
        .join(", ");
      return supervisors ? supervisors : "";
    },
    async getSummerCamp(
      page = 1,
      per_page = 10,
      ordering = null,
      search = null
    ) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/school`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
          country: this.filter.country,
          governorate: this.filter.governorate,
          directorate: this.filter.directorate,
          is_funded: this.filter.is_funded,
        },
      }).then((response) => {
        this.schools_summer = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data?.pagination?.num_pages;
        this.loading = false;
      });
    },
    editItem(summer) {
      this.$router.push({
        name: "summer-school-edit",
        params: { id: summer.id },
      });
    },
    resetForm() {
      this.$refs.form.reset();
      this.updated_id = undefined;
      this.deleteDataLoading = false;
    },

    async confirmDelete() {
      this.force_delete = true;
      const res = await this.deleteSummerSchool(this.delete_id);
      if (res == true) {
        this.$emit("successAlert", this.$t("globals.data_deleted"));
        this.getSummerCamp();
      } else {
        this.$emit("errorAlert", this.$t("alert.failure.delete"));
      }
      this.alert_cascade = false;
    },
    async deleteSummerSchool(delete_id) {
      if (delete_id) {
        var status;
        this.delete_id = delete_id;
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}/api/summer/school/${delete_id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            status = true;
            this.deleteDataLoading = false
            this.force_delete=false

          })
          .catch((error) => {
           status = error
            this.force_delete=false
            if(error.message.includes('406')){
              this.alert_cascade=true
              this.alert_cascade_title=this.$t('alert.attention.delete-talent-with-student')
              this.data_message=error.response.data
            }
            this.deleteDataLoading = false;
          });
          return status
      }
    },
  },
};
</script>
<style scoped>
</style>
