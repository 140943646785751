
<template>

  <v-card class="pa-3"  v-if="!show">
    <v-card-title>
      {{ $t("globals.select-criteria") }}
    </v-card-title>
    <v-card-text class="mt-2">
      <v-form ref="form">
        <v-row>
          <v-col cols="3" md="3" >
            <v-autocomplete             
              :items="semesters"
              v-model="filter.fk_semester"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name_ar"
              item-value="id"
              :label="$t('semester.name')"
              clearable
              persistent-hint
              density="compact"
              :rules="[$required]"
              @update:modelValue="getMonthsBySemester(), (this.filter.fk_monthsemester = null)"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              color="indigo"
              v-model="filter.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="month"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
              @update:model-value="getCurrentMonth()"
            />            
          </v-col>
          <v-col md="3">
            <DatetimePicker
              v-model="filter.date"
              :minDate="minDate"
              :maxDate="maxDate"
              :rules="[$required]"
              :disabled="filter.fk_monthsemester ? false : true"
              @update:model-value="items = []"
              forma="dd"
              readOnly
            />
          </v-col>
          <v-col cols="1" class="mt-2">
            <custom-btn type="show" :click="() => getData()" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
      <custom-table-report
        v-else
        :close="() => reset()"
        :headers="headers"
        :items="items"
        counter
      />
</template>
<script>
import moment from "moment-hijri";

export default {
  data() {
    return {
      filter: {},
      items: [],
      headers: [],
      semesters: [],
      months: [],
      minDate: null,
      maxDate: null,
      btn_loading: false,
      show: false,
    };
  },
  created() {
    this.getSemester();
  },
  methods: {
    getCurrentMonth() {
      this.filter.date = undefined
      if (this.filter.fk_monthsemester) {
        this.items = [];
        this.filter.date = null;
        const month =
          this.filter.fk_monthsemester < 10
            ? "0" + this.filter.fk_monthsemester.toString()
            : this.filter.fk_monthsemester;
        const now = moment();
        const startOfMonth = now.iYear() + "-" + month + "-01";
        const endOfMonth = moment(
          `${now.iYear()}-${this.filter.fk_monthsemester + 1}-01`,
          "iYYYY-iM-iDD"
        )
          .subtract(1, "day")
          .format("iYYYY-iMM-iDD");
        this.minDate = startOfMonth;
        this.maxDate = endOfMonth;

        if (
          this.months.find(
            (e) =>
              e.month == this.filter.fk_monthsemester &&
              e.current_month == true
          ) &&
          this.filter.fk_monthsemester == moment().format("iM")
        )
          this.filter.date = moment().format("iYYYY-iMM-iDD");
        else this.filter.date = this.minDate;

        this.$nextTick(() => {
        });
      }
    },
    async getData() {
      const { valid } = await this.$refs.form.validate();
      if (valid)
        await this.axios(
          `${this.base_url}api/summer/report/report-on-admission-process-at-school/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              date: this.filter.date,
            },
          }
        ).then((response) => {
            this.items = response.data.body;
            if (this.items.length > 0) {
              this.headers = response.data.headers;
              this.show = true;
            } else this.$emit("infoAlert", this.$t("globals.not-found"));
          }).catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },
    reset() {
      this.show = false;
      this.items = [];      
    },   
    async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
      const current_semester = this.semesters.find((e) => e.current_semester);
      if (current_semester) {
        this.filter.fk_semester = current_semester.id;
        this.getMonthsBySemester()
      }
    },
    async getMonthsBySemester() {
      if(this.filter.fk_semester)
      await this.axios(
        `${this.base_url}/api/months-semester/?fk_semester=${this.filter.fk_semester}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.filter.fk_monthsemester = current_month.month;
          }
          this.getCurrentMonth();
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
      else this.months=[]
    },
    
  },
};
</script>
