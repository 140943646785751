<template>
  <!-- filter data  -->
  <v-card  v-if="!exist">
    <v-card-text>
      <h3 class="text-grey-darken-2 ">{{ $t("globals.select-criteria") }}</h3>
      <v-row class=" mt-4">
        <v-col cols="12" md="4" sm="6" >
            <v-select
              color="primary"
              v-model="filter_data.type"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-soccer"
              :items="activityType"
              :label="$t('summer.activity.type')"
              clearable
              @update:modelValue="getActivitiesByType(),filter_data.activity=null"
              :error-messages="v$.filter_data.type.$errors.map(e=>e.$message)" 
              :rules="rules.requird_filed"
            />
        </v-col>
        <v-col cols="12" md="4" sm="6" >
            <v-select
              color="primary"
              v-model="filter_data.activity"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-soccer"
              :items="allAvtivitiesByType"
              :label="$t('summer.activity.name')"
              clearable
              :error-messages="v$.filter_data.activity.$errors.map(e=>e.$message)" 
              :rules="rules.requird_filed"
            />
        </v-col>
        
        <v-col cols="6" sm="1" class="mt-2">
          <v-btn
            density="comfortable"
            class="w-100 text-white"
            color="primary"
            :loading="loading"
            @click="checkFilterData"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">{{ $t("report.all_activities_detail") }} ( {{getActivityTypeName(this.filter_data.activity)}} )</div>
        </div>
      </template>
    </ReportHeader>
    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            density="compact"
            @click="(exist = false), v$.filter_data.$reset()"
          >
            <span class="text-primary">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

         <v-row
        v-if="items.length > 0"
        class="mt-4 mb-5 text-grey-darken-2 borders pt-2 pb-2 mr-1 ml-1"
      >
        <v-col cols="3">
          <h3 class="fontsize text-grey-darken-2 mb-2" >

            <span v-if="this.filter_data.activity">
                {{ $t("activity.type") }} : {{getactivityType(this.filter_data.type) }}  
            </span>
        <br>
         
          <br>
           
          </h3>
        </v-col>
      </v-row>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading"  rounded="0" flat>
          <table  id="stable" class="table" style="width: 100%; border-collapse: collapse">

            <thead class="table-light"> 
              <tr>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("summer.activity.type") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("summer.activity.name") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("activity.goal") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("activity.iteration") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("activity.execution") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("activity.participating-group") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("activity.school-recommendation") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("activity.entity-to-coordinate") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("activity.media-recommendation") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("summer.activity.student_count") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("summer.activity.difficultie") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("summer.activity.solutions") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4 pa-2">{{ $t("summer.activity.evaluation_act") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr class="text-center" v-for="item in this.items" :key="item">
                <td>{{item.activity_type ? item.activity_type : '---'}}</td>
                <td>{{item.activity_name ? item.activity_name : '---'}}</td>
                <td>{{item.goal ? item.goal : '---'}}</td>
                <td>{{item.iteration ? item.iteration : '---'}}</td>
                <td>{{item.execution ? item.execution : '---'}}</td>
                <td>{{item.participating_group ? item.participating_group : '---'}}</td>
                <td>{{item.school_recommendation ? item.school_recommendation : '---'}}</td>
                <td>{{item.entity_to_coordinate ? item.entity_to_coordinate : '---'}}</td>
                <td>{{item.media_recommendation ? item.media_recommendation : '---'}}</td>
                <td>{{item.student_number ? item.student_number : '---'}}</td>
                <td v-if ="item.activity_difficulty && item.activity_difficulty.length == 0">
                  <span v-for="(act,key) in item.activity_difficulty" :key="key">
                    <p>
                      {{key + 1}} - {{act.difficulty}}
                    </p>
                  </span>
                </td>
                <td v-else>
                  ---
                </td>
                <td v-if ="item.activity_difficulty && item.activity_difficulty.length == 0">
                  <span v-for="(act,key) in item.activity_difficulty" :key="key">
                    <p>
                      {{key + 1}} - {{act.solution}}
                    </p>
                  </span>
                </td>
                <td v-else>
                  ---
                </td>
                <td>{{item.evalution ? item.evalution : '---'}}</td>
              </tr>
            </tbody>
          </table>
       
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit('updateLoadingValue',true)
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
     
      await this.getActivityType();
      await this.getYears();
      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit('updateLoadingValue',false)
    } catch (error) {
      this.$store.commit('updateLoadingValue',false)
      
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      
      },
      theaders:[],
      allAvtivitiesByType:[],
      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id","name", "adjective", "entity",  "phone_number"],
      headers: [
        
        { title: "#", key: "id" },
        { title: this.$t("globals.name"), key: "name" },
        { title: this.$t("globals.adjective"), key: "adjective" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity" },
        { title: this.$t("globals.phone_number"), key: "phone_number" },
      
      ],
      
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
      class_divisions_hall: [],

    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      activityType:(state)=>state.summer.activityType,
    }),

    ...mapGetters({
      getClassDivisions: "summer/getClassDivisions",
    }),
    class_divisions() {
      if (this.filter_data.fk_level) {
        return this.getClassDivisions(this.filter_data.fk_level);
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getActivityType:"summer/getActivityType",
    }),
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },
    

   getactivityType(driver_id){

  if (driver_id) {
        const activityTypes = this.activityType.find((activityTypes) => activityTypes.id === driver_id);
        if(activityTypes)
        return activityTypes.name;
      }
      return "-------------";
    },
   
   
    getActivityTypeName(driver_id) {
      if (driver_id) {
        const committees = this.allAvtivitiesByType.find((committees) => committees.id === driver_id);
        if(committees)
        return committees.name;
      }
      return "-------------";
    },
    checkFilterData() {   
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getActivitiesByType() {
      await this.axios
        .get(`${this.base_url}api/summer/get-activity-by-type`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") ,
          },
          params:{
            activity_type: this.filter_data.type,
          }
        })
        .then((response) => {
          if (response.data) {
            this.allAvtivitiesByType = response.data         
          }
        })
        .catch((error) => {
        });
    },
    async getData() {
       this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/all-detials-activities`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") ,
          "Content-Type":'application/json'
          },
           params: {
            activity_type: this.filter_data.type,
            activity_id: this.filter_data.activity,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;           
            this.loading = false;         
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
      
      
    },
  },
  validations() {
    return {
      filter_data: {
        type: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        activity: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>


#stable{

  width: 100%;

}


.pp2{
padding: 2px!important;

}
#stable th {
border:1px solid #649add !important;
background-color: #fff;
color:black
}

#stable td  {
border: 1px solid #649add !important;
background-color: #fff;
padding: 8px;
}

.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
