<template  >
  <SideBar
    :dir="getdir"
    :drawer="drawer"
    @selectOption="onSelectOption"
    :location="location"
    id="sidebar"
    :direction="getdir"
  />
  <Header
    :dir="getdir"
    :direction="getdir"
    :locationData="LocationBreadcrumb"
    @handelDrawer="drawer = !drawer"
  />
  <VMain :dir="getdir" >
    <VContainer class="h-100" fluid style="overflow-x: visible" ref="homePage">
      <v-card v-if="breadcrumb.length > 0 && showBreadcrumb" class="mb-2 " color="golden" variant="tonal"  border="1">
        <v-row class="py-2" >
          <v-col cols="12" md="8" >
            <v-breadcrumbs
              :items="breadcrumb"
              class="mb-0 pb-0"
            ></v-breadcrumbs>
            <h5
              class="
                text-start
                mx-4
                mb-3
                d-md-none d-lg-none d-sm-block d-xs-block
              "
            >
              {{ LocationBreadcrumb?.join(" / ") }} 
            </h5>
          </v-col>
          <v-col cols="12" md="4" class="d-none d-md-block d-lg-block">
            <v-breadcrumbs
              class="d-flex location-bread justify-start"
              :items="LocationBreadcrumb"
            ></v-breadcrumbs>
          </v-col>
        </v-row>
      </v-card>
      <!-- v-if="!error_connection"  -->
      <div >
        <router-view
          @errorAlert="setErrorAlert"
          @successAlert="setSuccessAlert"
          @warningAlert="setInfoAlert"
          @infoAlert="setInfoAlert"
        ></router-view>
      </div>
    </VContainer>
      <timeout-alert v-model="timeout_alert"></timeout-alert>
  </VMain>
  <Alert
    v-model="show_alert"
    :iconColor="alert_icon_color"
    :icon="alert_icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
  <v-dialog
    :close-on-content-click="false"
    v-model="loadingPage"
    persistentid="loadingPage"
    width="auto"
  >
    <h1 v-if="!loadingImage" class="text-white">l o a d i n g . . .</h1>
    <div style="width: 80px; height: 80px">
      <v-img :src="loadingImage"></v-img>
    </div>
  </v-dialog>
</template>

<script>
import Header from "@/components/Globals/Header.vue";
import SideBar from "@/components/Globals/SideBar.vue";
import TimeoutAlert from "@/components/Globals/TimeoutAlert.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import moment from "moment-hijri";
import { watch } from "@vue/runtime-core";

//   changeLocale: locale => current.value = locale

export default {
  components: {
    Header,
    SideBar,
    TimeoutAlert,
  },
  mounted() {
    document.addEventListener("keydown", this.handelKeydown);
  },
  beforeMount() {
    document.removeEventListener("keydown", this.handelKeydown);
  },
  data() {
    return {
      items: [],
      drawer: true,
      isWithColor: true,
      show_alert: false,
      alert_icon_color: undefined,
      alert_icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      user_profile: {},
    };
  },

  methods: {
    windowReload() {
      window.location.reload();
    },
    async getUserProfile() {
      await this.axios
        .get(`${this.base_url}api/user/profile`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.user_profile = response.data;
          if (this.user_profile?.role) {
            this.$store.commit("setRole", this.user_profile.role);
          }
        });
    },
    onSelectOption(isColor) {
      isWithColor.value = isColor;
    },
    getCurrentDate() {
      let current_date = moment().format("iYYYY/iMM/iDD");
      return current_date;
    },
    getCurrentDayName() {
      let formatter = new Intl.DateTimeFormat("ar", { weekday: "long" });
      let current_day_name = formatter
        .formatToParts(new Date())
        .find((part) => part.type === "weekday").value;
      return current_day_name;
    },
    setErrorAlert(message) {
      this.alert_icon_color = "error";
      this.alert_icon = "cancel";
      this.alert_title = this.$t("globals.failure");
      this.alert_message = message;
      this.showAlert();
    },
    setSuccessAlert(message) {
      this.alert_icon_color = "success";
      this.alert_icon = "check-circle";
      this.alert_title = this.$t("globals.success");
      this.alert_message = message;
      this.showAlert();
    },
    setWarningAlert(message) {
      this.alert_icon_color = "warning";
      this.alert_icon = "alert";
      this.alert_title = this.$t("globals.warning");
      this.alert_message = message;
      this.showAlert();
    },
    setInfoAlert(message) {
      this.alert_icon_color = "info";
      this.alert_icon = "information";
      this.alert_title = this.$t("globals.info");
      this.alert_message = message;
      this.showAlert();
    },
    showAlert() {
      this.show_alert = true;
      setTimeout(() => {
        this.show_alert = false;
      }, 1500);
    },
    handelKeydown(event) {
      const { ctrlKey, keyCode } = event;
      if (ctrlKey) {
        switch (keyCode) {
          case 36:
            this.sctollToTop();
            break;
          case 35:
            this.scrollToEnd();
            break;
        }
      }
    },
    sctollToTop() {
      const table = this.$refs.homePage.$el;
      table.scrollIntoView({ block: "start" });
    },
    scrollToEnd() {
      const table = this.$refs.homePage.$el;
      table.scrollIntoView({ block: "end" });
    },
  },
  created() {
    this.getUserProfile();
  },
  computed: {
    ...mapState({
      loadingPage: "loading",
      breadcrumb: "breadcrumb",
      error_connection: "error_connection",
      timeout_alert: "timeout_alert",
    }),

    showBreadcrumb() {
      if (this.$route.path == "/" || this.$route.path == "/summer/home") {
        // if(localStorage.getItem('role')==4)
        //     return true
        return false;
      }
      return true;
    },
    LocationBreadcrumb() {
      const list_data = [];
      if (this.user_profile.governorate)
        list_data.push(this.user_profile.governorate);
      if (this.user_profile.directorate)
        list_data.push(this.user_profile.directorate);
      if (this.user_profile.date_today)
        list_data.push(this.user_profile.date_today);
      return list_data;
    },
    loadingImage() {
      try {
        return require("../../assets/loading.gif");
      } catch (error) {
        return false;
      }
    },
    ...mapGetters({ getdir: "getdir" }),
    location() {
      return this.getdir === "rtl" ? "right" : "left";
      // return this.$i18n.locale==='ar'?'right':'left'
    },
  },
  watch: {
    loadingPage() {
      setTimeout(() => {
        this.$store.commit("updateLoadingValue", false);
      }, 300000);
    },
    error_connection(val) {
      if (val) {
        this.$store.commit("updateLoadingValue", false);
      }
    },
  },
};
</script>
<style>
/* to change direction with rtl  */



.v-navigation-drawer .vListHome {
  width: 250px !important ;
  border-radius: 10px !important;
}

.v-breadcrumbs-item--disabled {
  opacity: unset;
}
.location-bread li {
  font-size: 1rem !important;
}
</style>

