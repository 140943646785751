<template>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_calendar')"
    :create="
      () => {
        addDialog = true;
        if (this.items.length > 0) {
          this.dropList.start_date = this.date.start_data;
          this.dropList.end_data = this.date.end_data;
        }
      }
    "
    :items="items"
    :headers="headers"
    :method="getData"
    :pagination="pagination"
    :del_perm="false"
    :edit_perm="false"
    :add_perm="
      CheckGetPermission('legal_school.add_calendar', [
        'sys_admin',
        'gen_admin',
      ])
    "
  >
    <template v-slot:item.index="{ item, index }">
      {{ index + 1 }}
    </template>
    <template v-slot:item.vacation="{ item }">
      <v-chip v-for="(data, i) in item.vacation" :key="i" class="pa-2 mx-1">
        {{ data }}
      </v-chip>
    </template>
    <template v-slot:item.is_holiday="{ item }">
      <span>
        <v-icon v-if="item.is_holiday == true" color="success"
          >mdi-check-circle</v-icon
        >
        <v-icon v-if="item.is_holiday == false" color="error"
          >mdi-close-circle</v-icon
        >
      </span>
    </template>
  </DataTable>
  <v-dialog v-model="addDialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <v-form ref="form" @submit.prevent="saveData()">
          <VRow class="mt-2">
            <VCol cols="12">
              <DatetimePicker
                v-model="dropList.start_date"
                calendar="hijri"
                placeholder="1446-01-01"
                :label="$t('globals.start-date')"
                :disabled="items.lenght > 0 ? true : false"
                :rules="[$required]"
              />
            </VCol>
            <VCol cols="12">
              <DatetimePicker
                v-model="dropList.end_data"
                calendar="hijri"
                placeholder="1446-01-01"
                :label="$t('globals.end-date')"
                :rules="[ $required,
                  $compare_date(dropList.end_data, dropList.start_date),
                ]"
              />
            </VCol>
          </VRow>
        </v-form>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave @click="saveData()" :loading="btn_load">
          {{ $t("globals.add") }}
        </VBtnSave>

        <VBtn class="mx-3" size="small" @click="dropList.end_data = undefined">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dropList: {},
      items: [],
      addDialog: false,
      btn_load: false,
      headers: [
        { title: this.$t("globals.#"), key: "index" },
        { title: this.$t("day.day"), key: "day" },
        {
          title: this.$t("summer.calendar.hijri-date"),
          key: "date",
        },
        {
          title: this.$t("summer.calendar.gregorian-date"),
          key: "date_m"       
        },
        { title: this.$t("day.day_off"), key: "is_holiday" },
        {
          title: this.$t("summer.visitors.occasion"),
          key: "vacation",
          sortable: false,
        },
      ],
    };
  },
  async created() {},
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  methods: {
    async saveData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.btn_load = true;
        await this.axios
          .post(this.base_url + "/api/summer/calendar", this.dropList, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_added"));

            this.getData();
            this.addDialog = false;
          })
          .catch((error) => {
            if (
              error?.response?.data?.detail ==
              "ليس لديك صلاحية للقيام بهذا الإجراء."
            ) {
              this.$emit("errorAlert", "ليس لديك صلاحية للقيام بهذا الإجراء.");
              return;
            }
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.btn_load = false;
      }
    },
    async getData(page = 1, per_page = 10, ordering = "id", search = null) {
      await this.axios
        .get(this.base_url + "/api/summer/calendar", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            page: page,
            page_size: per_page,
            sort_by: ordering,
            search: search,
          },
        })
        .then((response) => {
          this.items = response.data.results.data;
          this.date = response.data.results.date;
          this.pagination = response.data.pagination;
        });
    },
  },
  watch: {},
};
</script>
