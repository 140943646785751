<template>
  <!-- filter data  -->
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader>
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("summer.teacher-attendance") }}
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="my-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 "
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0" rounded="0" flat>
          <v-data-table id="stable" class="table">
            <thead class="table-light">
              <th
                v-for="header in selectedHeaders"
                :key="header.key"
                class="bg-primary-grey-lighten-4"
              >
                {{ header.title }}
              </th>
              <th
                v-for="week in weekHeaderTitle.weeks"
                :key="week"
                class="bg-primary-grey-lighten-4 pt-2"
              >
                {{ week.week_name }}
                <table class="mt-1" style="border-collapse: collapse; width: 100%">
                  <thead>
                    <tr
                      class="bg-primary-grey-lighten-4"
                      style="border-width: 0; border-left: unset; border-right: unset"
                    >
                      <th
                        class="inside-th-cells "
                        style="
                          max-width: 14.27%;
                          width: 14.27%;
                          border-right: unset;
                        "
                      >
                        <span
                          style="writing-mode: vertical-rl; transform: rotate(180deg)"
                        >
                          السبت
                        </span>
                      </th>
                      <th
                        class="inside-th-cells"
                        style="max-width: 14.27%; width: 14.27%; padding: 5px 1px"
                      >
                        <span
                          style="writing-mode: vertical-rl; transform: rotate(180deg)"
                        >
                          الاحد
                        </span>
                      </th>
                      <th
                        class="inside-th-cells"
                        style="max-width: 14.27%; width: 14.27%; padding: 5px 1px"
                      >
                        <span
                          style="writing-mode: vertical-rl; transform: rotate(180deg)"
                        >
                          الاثنين
                        </span>
                      </th>
                      <th
                        class="inside-th-cells"
                        style="max-width: 14.27%; width: 14.27%; padding: 5px 1px"
                      >
                        <span
                          style="writing-mode: vertical-rl; transform: rotate(180deg)"
                        >
                          الثلاثاء
                        </span>
                      </th>
                      <th
                        class="inside-th-cells"
                        style="max-width: 14.27%; width: 14.27%; padding: 5px 1px"
                      >
                        <span
                          style="writing-mode: vertical-rl; transform: rotate(180deg)"
                        >
                          الاربعاء
                        </span>
                      </th>
                      <th
                        class="inside-th-cells"
                        style="max-width: 14.27%; width: 14.27%; padding: 5px 1px"
                      >
                        <span
                          style="writing-mode: vertical-rl; transform: rotate(180deg)"
                        >
                          الخميس
                        </span>
                      </th>
                      <th
                        class="inside-th-cells"
                        style="
                          max-width: 14.27%;
                          width: 14.27%;
                          padding: 5px 1px;
                          border-left: unset;
                        "
                      >
                        <span
                          style="writing-mode: vertical-rl; transform: rotate(180deg)"
                        >
                          الجمعة
                        </span>
                      </th>
                    </tr>
                  </thead>
                </table>
              </th>
              <th class="bg-primary-grey-lighten-4">
                <span >
                  {{ $t("summer.report.total-attendance-days") }}
                </span>
              </th>
              <th class="bg-primary-grey-lighten-4 ">
                {{ $t("summer.report.total-absence-days") }}
              </th>
            </thead>
            <tbody>
              <tr v-for="(item, key) in items" :key="item">
                <td
                  class="text-center border"
                  v-if="selectedHead.includes('id')"
                  style="width:50px"
                >
                  {{ key + 1 }}
                </td>
                <td class="text-center border" v-if="selectedHead.includes('name')">
                  {{ item.employee }}
                </td>
                <td
                  class="text-center border"
                  v-for="week in weekHeaderTitle.weeks"
                  :key="week"
                  style="padding: 0"
                >
                  <!-- {{ item.weeks }} -->
                  <table class="h-100" style="border-collapse: collapse; width: 100%">
                    <thead>
                      <tr style="border-width: 0">
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span
                              v-if="
                                getDayData(
                                  getWorkerWeekData(week, item.weeks).days,
                                  1
                                ).day
                              "
                            >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 1)
                                    .is_attendance
                                "
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 1)
                                    .is_permission
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 1)
                                    .is_attendance == false &&
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 1)
                                    .is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span
                              v-if="
                                getDayData(getWorkerWeekData(week, item.weeks).days, 2)
                                  .day
                              "
                            >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 2)
                                    .is_attendance
                                "
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 2)
                                    .is_permission
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 2)
                                    .is_attendance == false &&
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 2)
                                    .is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span
                              v-if="
                                getDayData(getWorkerWeekData(week, item.weeks).days, 3)
                                  .day
                              "
                            >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 3)
                                    .is_attendance
                                "
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 3)
                                    .is_permission
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 3)
                                    .is_attendance == false &&
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 3)
                                    .is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span
                              v-if="
                                getDayData(getWorkerWeekData(week, item.weeks).days, 4)
                                  .day
                              "
                            >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 4)
                                    .is_attendance
                                "
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 4)
                                    .is_permission
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 4)
                                    .is_attendance == false &&
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 4)
                                    .is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span
                              v-if="
                                getDayData(getWorkerWeekData(week, item.weeks).days, 5)
                                  .day
                              "
                            >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 5)
                                    .is_attendance
                                "
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 5)
                                    .is_permission
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 5)
                                    .is_attendance == false &&
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 5)
                                    .is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span
                              v-if="
                                getDayData(getWorkerWeekData(week, item.weeks).days, 6)
                                  .day
                              "
                            >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 6)
                                    .is_attendance
                                "
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 6)
                                    .is_permission
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 6)
                                    .is_attendance == false &&
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 6)
                                    .is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span
                              v-if="
                                getDayData(getWorkerWeekData(week, item.weeks).days, 7)
                                  .day
                              "
                            >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 7)
                                    .is_attendance
                                "
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 7)
                                    .is_permission
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 7)
                                    .is_attendance == false &&
                                  getDayData(getWorkerWeekData(week, item.weeks).days, 7)
                                    .is_permission == false
                                "
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </td>
                <!-- <td
                  class="text-center border"
                  v-for="week in item.weeks"
                  :key="week"
                  style="padding: 0"
                >
                  <table class="h-100" style="border-collapse: collapse; width: 100%">
                    <thead>
                      <tr style="border-width: 0">
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 1).day">
                              <v-icon
                                v-if="getDayData(week.days, 1).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 1).is_permission"
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 1).is_attendance == false &&
                                  getDayData(week.days, 1).is_permission == false
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 2).day">
                              <v-icon
                                v-if="getDayData(week.days, 2).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 2).is_permission"
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 2).is_attendance == false &&
                                  getDayData(week.days, 2).is_permission == false
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 3).day">
                              <v-icon
                                v-if="getDayData(week.days, 3).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 3).is_permission"
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 3).is_attendance == false &&
                                  getDayData(week.days, 3).is_permission == false
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 4).day">
                              <v-icon
                                v-if="getDayData(week.days, 4).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 4).is_permission"
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 4).is_attendance == false &&
                                  getDayData(week.days, 4).is_permission == false
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 5).day">
                              <v-icon
                                v-if="getDayData(week.days, 5).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 5).is_permission"
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 5).is_attendance == false &&
                                  getDayData(week.days, 5).is_permission == false
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 6).day">
                              <v-icon
                                v-if="getDayData(week.days, 6).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 6).is_permission"
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 6).is_attendance == false &&
                                  getDayData(week.days, 6).is_permission == false
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                        <td style="max-width: 14.27%; width: 14.27%; padding: 5px 1px">
                          <span
                            style="
                              border-top-width: 0px !important;
                              border-bottom-width: 0px !important;
                            "
                          >
                            <span v-if="getDayData(week.days, 7).day">
                              <v-icon
                                v-if="getDayData(week.days, 7).is_attendance"
                                color="success"
                                >mdi-check-circle</v-icon
                              >
                              <v-icon
                                v-if="getDayData(week.days, 7).is_permission"
                                color="error"
                                >mdi-close-circle</v-icon
                              >
                              <v-icon
                                v-if="
                                  getDayData(week.days, 7).is_attendance == false &&
                                  getDayData(week.days, 7).is_permission == false
                                "
                                color="primary"
                                >mdi-minus-circle</v-icon
                              >
                            </span>
                          </span>
                        </td>
                      </tr>
                    </thead>
                  </table>
                </td> -->
                <td class="text-center" style="width:80px">
                  {{ item.attendance_count }}
                </td>
                <td class="text-center" style="width:80px">
                  {{ item.absence_count }}
                </td>
              </tr>
            </tbody>
            <template v-slot:bottom></template>
          </v-data-table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "../../../components/SummerGlobals/ReportHeader";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));

      await this.getWeeks();
      await this.getYears();
      await this.getData();
      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      weekHeaderTitle: {},
      current_year: "",
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name"],
      headers: [
        { title: this.$t("globals.#"), key: "id" },
        { title: this.$t("summer.report.fullname-with-surname"), key: "name" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      weeks: (state) => state.summer.weeks,
    }),

    ...mapGetters({}),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getWeeks: "summer/getWeeks",
    }),
    getMaxWeeks(workers) {
      let maxWeeks = 0;
      let workerWithMaxWeeks = null;
      workers.forEach((worker) => {
        if (worker.weeks.length > maxWeeks) {
          maxWeeks = worker.weeks.length;
          workerWithMaxWeeks = worker;
        }
      });
      this.weekHeaderTitle = workerWithMaxWeeks;
    },
    getWorkerWeekData(week, workerWeeks) {
      let result = workerWeeks.find((obj) => obj.week_name == week.week_name);
      return result ? result : {};
    },
    getDayData(listWeeks, dayNumber) {
      if (listWeeks) {
        const week = listWeeks.find((element) => element.day == dayNumber);
        return week ? week : "";
      }
      return "";
    },
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },
    checkFilterData() {
      this.getData();
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/employee-attendance-report`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            search: this.txt_search,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;
            this.getMaxWeeks(this.items);
            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
     this.loading = false;
    },
  },

  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
#stable {
  /* width: 100%; */
}

#stable th {
  border: 1px solid #649add;
  background-color: #fff;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
}
.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}

.inside-th-cells {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 0px !important;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
