<template>
  <!-- added by samer -->
  <!-- تقرير الشهادة الطلاب النهائية -->
  <v-card class="pa-3" v-if="!show">
    <v-card-title>{{ $t("globals.select-criteria") }}</v-card-title>
    <v-card-text class="mt-2">
      <v-form ref="form">
        <v-row class="mt-2">
          <v-col cols="3">
            <v-autocomplete
              color="primary"
              v-model="filter.fk_level"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-stairs-box"
              :items="levels"
              clearable
              :label="$t('summer.level.name_ar')"
              :rules="[$required]"
              @update:model-value="getStudents(), getDivisionByLevel()"
              return-object
            />
          </v-col>
            <v-col cols="12" md="3" lg="3">
            <v-autocomplete
              :items="divisions"
              v-model="filter.fk_division"
              item-title="fk_division__name"
              item-value="fk_division__id"
              :label="$t('division.select')"
              persistent-hint
              hide-details="auto"
              prepend-inner-icon="mdi-shape-outline"
              density="compact"
              clearable
               @update:model-value="getStudents"
            />
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              color="primary"
              v-model="filter.fk_levelstudentyear"
              density="compact"
              item-title="fk_student__name"
              item-value="id"
              prepend-inner-icon="mdi-account"
              :items="students"
              clearable
              :label="$t('reportpublic.student-name')"
              return-object
              :rules="[$required]"
            />
          </v-col>
          <custom-btn type="show" class="mt-2" @click="getData" />
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  <v-card v-else>
    <v-card-text>
      <v-row class="text-center align-center hide-item pa-5">
        <custom-btn type="print" @click="printReport" class="me-3" />

        <v-spacer />
        <v-icon v-if="show" icon="mdi-close" class="me-2" @click="reset" end />
      </v-row>

      <v-card style="width: 340mm !important" class="pa-5" ref="report">
        <v-col>
          <custom-report-header
            :title="` شهادة تقويم اعمال السنة لمرحلة التعليم  ` + items.phase"
            :sub_title="
              $t('year.placeholder.for-year') +
              '( ' +
              items.year_h +
              ' )  (' +
              items.year_m +
              ' )'
            "
          />

          <v-container class="overflow-hidden">
            <v-row>
              <v-col cols="10" class="pa-0">
                <table class="t-bordered">
                  <thead>
                    <tr class="color_heade">
                      <th rowspan="3">
                        {{ $t("globals.subjects-initialize") }}
                      </th>

                      <!-- الترم -->
                      <th
                        colspan="6"
                        v-for="(item, index) in items.semesters"
                        :key="index"
                      >
                        {{ item.name_ar }}
                      </th>

                      <th rowspan="2" colspan="2">
                        {{ $t("report.final-total") }}
                      </th>
                      <th rowspan="3">{{ $t("school.estimiate") }}</th>
                    </tr>
                    <!-- المحصلة -->
                    <tr class="color_heade">
                      <template
                        v-for="(item, index) in items.semesters"
                        :key="index"
                      >
                        <th colspan="2">{{ $t("report.collector-1") }}</th>
                        <th colspan="2">{{ $t("class-marks.final-exam") }}</th>
                        <th colspan="2">{{ $t("class-marks.mark_total") }}</th>
                      </template>
                    </tr>

                    <tr class="color_heade">
                      <template
                        v-for="index in items.semesters.length * 3 + 1"
                        :key="index"
                      >
                        <th>{{ $t("globals.anumber") }}</th>
                        <th>{{ $t("report.writing") }}</th>
                      </template>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in this.items.data" :key="index">
                      <td class="py-5">{{ item.subject_name }}</td>

                      <template
                        v-for="(semester, index) in items.semesters"
                        :key="index"
                      >
                        <td>{{ item[`collector_${semester.id}`] }}</td>
                        <td>
                          {{ numbersToWords(item[`collector_${semester.id}`]) }}
                        </td>

                        <td>{{ item[`final_exam_${semester.id}`] }}</td>
                        <td>
                          {{
                            numbersToWords(item[`final_exam_${semester.id}`])
                          }}
                        </td>

                        <td>{{ item[`total_${semester.id}`] }}</td>
                        <td>
                          {{ numbersToWords(item[`total_${semester.id}`]) }}
                        </td>
                      </template>

                      <td>{{ item.grand_total }}</td>
                      <td>{{ numbersToWords(item.grand_total) }}</td>

                      <td>------</td>
                    </tr>

                    <tr>
                      <th
                        :colspan="items.semesters.length * 3 + 1"
                        class="color_heade"
                      >
                        {{ $t("report.final-total") }}
                      </th>
                      <th :colspan="items.semesters.length + 1">
                        {{ items.total_grades_stu }}
                      </th>
                      <th :colspan="items.semesters.length * 3 + 1">
                        {{ numbersToWords(items.total_grades_stu) }}
                      </th>
                    </tr>
                    <tr>
                      <th
                        :colspan="items.semesters.length + 1"
                        class="color_heade"
                      >
                        {{ $t("globals.percentage") }}
                      </th>
                      <th :colspan="items.semesters.length">
                        {{ items.percentage }} %
                      </th>

                      <th
                        :colspan="items.semesters.length + 1"
                        class="color_heade"
                      >
                        {{ $t("school.estimiate") }}
                      </th>
                      <th :colspan="items.semesters.length">--------</th>

                      <th
                        :colspan="items.semesters.length + 1"
                        class="color_heade"
                      >
                        {{ $t("monthly_marks.order") }}
                      </th>
                      <th :colspan="items.semesters.length + 1">
                        -------------
                      </th>
                    </tr>
                  </tbody>
                </table>
              </v-col>
              <v-col cols="2" class="pa-0">
                <v-container class="border" style="position: relative">
                  <v-col>
                    <v-row>
                      <p>{{ $t("summer.level.phase") }} :</p>
                      <p class="ps-1">{{ items.phase }}</p>
                    </v-row>

                    <v-row class="mt-5">
                      <p>{{ $t("report.class") }} :</p>
                      <p class="ps-1">
                        {{ items.level_name }}
                      </p>
                    </v-row>

                    <v-row class="mt-5">
                      <p>{{ $t("division.division") }} :</p>
                      <p class="ps-1">{{ items.division_name }}</p>
                    </v-row>

                    <v-row class="mt-5">
                      <p>{{ $t("globals.student_id") }} :</p>
                      <p class="ps-1">{{ items.academic_id }}</p>
                    </v-row>
                    <v-row class="mt-5">
                      <v-container class="border pa-0 text-center">
                        <p>{{ items.stu_name }}</p>
                      </v-container>
                    </v-row>
                  </v-col>
                  <img
                    :src="logo_center"
                    width="50"
                    height="50"
                    style="position: absolute; top: 0; left: 0"
                  />
                </v-container>
                <v-container class="border mt-1">
                  <v-col>
                    <v-row>
                      <h3>النتيجة العامة :</h3>
                      <h3>باقية للاعاد</h3>
                    </v-row>
                  </v-col></v-container
                >
              </v-col>
            </v-row>
          </v-container>
        </v-col></v-card
      >
    </v-card-text></v-card
  >
</template>
<script>
import tafqeet from "../../../assets/Tafqeet";
export default {
  data() {
    return {
      filter: {},

      items: [],
      students: [],
      levels: [],
      divisions: [],

      show: false,
    };
  },
  created() {
    this.getLevel();
  },
  computed: {
    logo_center() {
      return require("@/assets/12.jpg");
    },
  },
  methods: {
    reset() {
      this.show = false;
      this.items = [];
    },
    async getData() {
      const { valid } = await this.$refs.form.validate();
      if (valid)
        await this.axios(
          `${this.base_url}api/summer/report/certificates-report/final/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },

            params: {
              fk_levelstudentyear: this.filter.fk_levelstudentyear.id,
              fk_level: this.filter.fk_level.id,
            },
          }
        )
          .then((response) => {
            this.items = response.data;
            //   this.title = response.data.title;
            if (this.items.data.length > 0) {
              this.show = true;
            } else this.$emit("infoAlert", this.$t("globals.not-found"));
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },
    numbersToWords(total) {
      return tafqeet(total);
    },
    printReport() {
      setTimeout(() => {
        const printContent = this.$refs.report.$el.innerHTML;
        document.getElementById("printView").style.display = "block";
        document.getElementById("printView").innerHTML = printContent;
        document.getElementById("printView").style.direction = "rtl";
        document.getElementById("appViewId").style.display = "none";
        // document.getElementById('dataTableReport').style.height = 'auto'
        window.print();
        document.getElementById("printView").innerHTML = "";
        document.getElementById("printView").style.display = "none";
        document.getElementById("appViewId").style.display = "block";
        // document.getElementById('dataTableReport').style.height = '450px'
      }, 10);
    },
    async getStudents() {
      this.filter.fk_levelstudentyear = undefined;
      this.students = [];
      if (this.filter.fk_level)
        await this.axios(`${this.base_url}api/student-by-level-choices`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            fk_level: this.filter.fk_level.id,
            fk_division: this.filter.fk_division ?? "",
          },
        })
          .then((response) => (this.students = response.data))
          .catch(() => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },
    async getLevel() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivisionByLevel() {
      this.filter.fk_division = undefined;
      this.divisions = [];

      if (this.filter.fk_level)
        await this.axios(`${this.base_url}api/division-choices`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            fk_level: this.filter.fk_level.id,
          },
        })
          .then((response) => (this.divisions = response.data))
          .catch(() => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      else this.filter.fk_division = [];
    },
  },
};
</script>
<style scoped>
table td,
th {
  border: 1px solid;
  text-align: center;
  justify-content: center;
  padding: 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
}
</style>