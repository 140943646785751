<template>
    <v-btn variant="text" icon size="small" @click="changeTheme">
        <v-icon color='warning' size="large" :icon="theme.global.name=='light'?'mdi-white-balance-sunny':'mdi-weather-night'"></v-icon>
    </v-btn>
</template>
<script>
import { useTheme } from 'vuetify'
export default{
    data(){
        return{
            theme:useTheme(),
        }
    },
    methods:{
        changeTheme(){
            this.theme.global.name = this.theme.global.name=='light'?'dark':'light'
            localStorage.setItem('theme',this.theme.global.name)

        }
    }
}
</script>
