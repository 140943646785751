<template>
  <v-alert
    v-model="alert"
    border="top"
    color="white"
    type="warning"
    class="mb-2"
    border-color="warning"
    closable
    elevation="1"
  >
    {{ $t("alert.warnning.change-password") }}
  </v-alert>
  <v-card class="justify-center">
    <v-card-text>
      <v-form ref="form">
        <v-row class="mt-4">
          <v-col cols="12" md="6">
            <v-text-field
              color="primary"
              v-model="user_data.old_password"
              append-inner-icon="mdi-lock"
              :type="showOldPassword ? 'text' : 'password'"
              density="compact"
              :label="$t('user.old-password')"
              :error-messages="
                v$.user_data.old_password.$errors.map((e) => e.$message)
              "
            >
              <template v-slot:prepend-inner>
                <!-- <v-btn size="small" variant="text"  :disabled="!user_data.old_password" icon density="compact" > -->
                <v-icon
                  :color="primary"
                  @click="showOldPassword = !showOldPassword"
                  :icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                />
                <!-- </v-btn> -->
              </template>
            </v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              color="primary"
              v-model="user_data.password"
              :type="showPassword ? 'text' : 'password'"
              append-inner-icon="mdi-lock"
              density="compact"
              :label="$t('user.new-password')"
              :error-messages="
                v$.user_data.password.$errors.map((e) => e.$message)
              "
            >
              <template v-slot:prepend-inner>
                <!-- <v-btn size="small" variant="text"  :disabled="!user_data.password" icon density="compact" > -->
                <v-icon
                  @click="showPassword = !showPassword"
                  :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                />
                <!-- </v-btn> -->
              </template>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              color="primary"
              v-model="user_data.password2"
              :type="showPassword2 ? 'text' : 'password'"
              append-inner-icon="mdi-lock"
              density="compact"
              :label="$t('user.confirm-password')"
              :error-messages="
                v$.user_data.password2.$errors.map((e) => e.$message)
              "
            >
              <template v-slot:prepend-inner>
                <!-- <v-btn size="small" variant="text"  :disabled="!user_data.password2" icon density="compact" > -->
                <v-icon
                  @click="showPassword2 = !showPassword2"
                  :icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                />
                <!-- </v-btn> -->
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="mx-2">
      <v-btn
        :loading="saveProgress"
        @click="changePassword"
        class="bg-primary"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.save") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
      <v-btn @click="resetForm" class="mx-3" size="small">
        {{ $t("globals.clear") }}
        <v-icon icon="mdi-broom" color="golden" end></v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import useValidate from "@vuelidate/core";
import {
  required,
  helpers,
  maxLength,
  minLength,
  sameAs,
} from "@vuelidate/validators";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  created() {
    if(localStorage.getItem('must_change_password')){
        this.alert=true
        // this.$store.commit('updateBreadcrumbValue',[])
    }
  },
  mounted(){
    const items=[this.$t('user.user'),this.$t('user.account'),this.$t('user.change-password')]
    this.$store.commit('updateBreadcrumbValue',items)
  },
  data() {
    return {
      v$: useValidate(),
      alert: false,
      showPassword: false,
      showPassword2: false,
      showOldPassword: false,
      saveProgress: false,
      user_data: {
        password: null,
        password2: null,
        old_password: null,
      },
      process_alert: undefined,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
    };
  },
  computed: {
    ...mapActions(["logout"]),
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },

  validations() {
    return {
      user_data: {
        password: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(128)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.lowest_number_of_character") + "8",
            minLength(8)
          ),
        },
        password2: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(128)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.lowest_number_of_character") + "8",
            minLength(8)
          ),
          sameAs: helpers.withMessage(
            this.$t("user.not-match"),
            sameAs(this.user_data.password)
          ),
        },
        old_password: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(128)
          ),
        },
      },
    };
  },
  methods: {
    resetForm() {
      this.$refs.form.reset();
      this.v$.$reset();
    },

    async changePassword() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.saveProgress = true;
        this.axios
          .post(`${this.base_url}api/user/change-password`, this.user_data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit(
              "successAlert",
              this.$t("alert.success.changed-password")
            );
            this.resetForm();
            this.saveProgress = false;
            setTimeout(() => {
              localStorage.removeItem("token");
              window.location.reload();
            }, 1500);
            localStorage.removeItem('must_change_password')
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.changed-password"));
            this.saveProgress = false;
          });
      }
    },
  },
  watch: {},
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>