<template>
  <!-- added by samer -->
  <!-- شاشة تقييم المهام الادارية -->
  <v-card class="pa-3">
    <v-card-text>
      <v-form ref="form">
        <v-row>
          <v-col cols="4" v-if="role != 4">
            <v-autocomplete
              v-model="dropList.fk_summer"
              item-value="id"
              item-title="name_ar"
              prepend-inner-icon="mdi-domain"
              :items="schools"
              :label="$t('globals.summer-centre')"
              persistent-hint
              density="compact"
              clearable
              :rules="[$required]"
              @update:model-value="exist=false"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              v-model="dropList.responsible"
              :items="responsible"
              item-title="name"
              item-value="id"
              :label="$t('globals.responsible_task')"
              persistent-hint
              density="compact"
              prepend-inner-icon="mdi-account-tie"
              @update:model-value="items = [],exist=false"
              :rules="[$required]"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              color="indigo"
              v-model="dropList.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="month"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
              @update:model-value="items = [],exist=false"
            />          
          </v-col>
            <v-col cols="3">
              <v-autocomplete
                v-model="dropList.iteration"
                :items="iterations"
                item-title="name"
                item-value="id"
                :label="$t('globals.iteration')"
                persistent-hint
                prepend-inner-icon="mdi-refresh"
                density="compact"
                clearable
                @update:model-value="items = [],exist=false"
              />
            </v-col>
          
             <v-btn
              @click="getData"
              :loading="loading"
              color="primary"            
              density="comfortable"               
              class="me-3 mt-2"
            >
              <span class="text-white">
                {{ $t("globals.show") }}
              </span>
            </v-btn>
        </v-row>
      </v-form>
      <v-form ref="form_save" v-if="exist">
        <v-data-table-virtual
          :items="items"
          :headers="headers"
          :loading="loading"
          :no-data-text="$t('globals.not-found')"
           v-model:sort-by="sort_by"
        >
          <template v-slot:item.evalate="{ item }">
            <v-text-field
              v-model="item.evalate"
              :rules="item.evalate ? [$required , $max_number(100)] : ''"
              type="number"
              style="width: 200px"
            />
          </template>
          <template v-slot:item.proposals="{ item }">
            <v-text-field
              v-model="item.proposals"
              style="width: 400px"
              :rules="[$max_length(50),$ar_letters_only]"
              
            />
          </template>
        </v-data-table-virtual>
      </v-form>
    </v-card-text>
    <template v-slot:actions>
   
         <VBtnSave  v-if="items.length > 0" @click="saveData" :loading="save_loading">
          {{ $t("globals.save") }}
        </VBtnSave>
    </template>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dropList: {},

      schools: [],
      items: [],
      responsible: [],
      months: [],
      iterations:[],
      sort_by:[],
      exist: false,
      loading: false,
      save_loading: false,
    };
  },
  created() {
    this.getSchoolSummer();
    this.getResponsible();
    this.getMonths();
    this.getIterations();

  },
  methods: {
    async getData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        this.save_loading = true;

        await this.axios(`${this.base_url}/api/evalate-person-responsible`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            iteration:this.dropList.iteration,
            fk_summer: this.dropList.fk_summer,
            responsible: this.dropList.responsible,
            fk_monthsemester: this.months.find(
              (e) => e.month == this.dropList.fk_monthsemester
            ).id,
          },
        })
          .then((response) => {
            this.items = response.data;
            if(this.items.length === 0){
              this.$emit("warningAlert", this.$t("globals.not-found"));
            }  else {
              this.exist = true;
            }
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      } else this.items = [];

      this.loading = false;
      this.save_loading = false;
    },
    async getSchoolSummer() {
      await this.axios(`${this.base_url}api/school-summer-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.schools = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getResponsible() {
      await this.axios(`${this.base_url}/choices/specialists-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.responsible = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
      async getIterations() {
      await this.axios(`${this.base_url}/choices/activity-iterations`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.iterations = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          // this.dropList.fk_monthsemester = response?.data?.find(e=>e.current_month)
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async saveData() {
      this.save_loading = true;

      const data = this.items.map((e) => {
        return {
          ...e,
          fk_monthsemester: this.months.find(
            (e) => e.month == this.dropList.fk_monthsemester
          ).id,
        };
      });
      const { valid } = await this.$refs.form_save.validate();
      if (valid)
        await this.axios
          .post(`${this.base_url}/api/evalate-person-responsible`, data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((e) => {
            this.getData();
            this.$emit("successAlert", this.$t("globals.data_updated"));
          })
          .catch((e) => {
            
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      this.save_loading = false;
    },
  },
  computed: {
    ...mapState({
      role: (state) => state.role,
    }),
    headers() {
      return [
        // { title: this.$t("globals.responsible_task"), key: "school_name" },
        {
          title: this.$t("globals.iteration"),
          key: "iteration",
        },
        {
          title: this.$t("globals.tasks_that_have_been_performed"),
          key: "task_name",
        },
        {
          title: this.$t("summer-report.evaluation"),
          key: "evalate",
        },
        {
          title: this.$t("globals.proposals"),
          key: "proposals",
        },
      ];
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
};
</script>