<template>
  <v-card class="pa-3" v-if="!show">
    <v-card-title>
      {{ $t("globals.select-criteria") }}
    </v-card-title>
    <v-card-text class="mt-2">
      <v-form ref="form">
        <v-row>
          <VCol
            cols="3"
            v-if="
              CheckGetPermission('globaltable.view_governorate', [
                'sys_admin',
                'gen_admin',
              ])
            "
          >
            <VAutocomplete
              :items="summer_schools"
              v-model="filter.fk_summer"
              prepend-inner-icon="mdi-domain"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.summer-centre')"
              persistent-hint
              density="compact"
              clearable
              :rules="[$required]"
              @update:model-value="(filter.fk_division = undefined,filter.fk_level = undefined), getDivisionByLevel()"
            ></VAutocomplete>
          </VCol>
          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="filter.fk_level"
              :items="levels"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              :rules="[$required]"
              clearable
              @update:model-value="(filter.fk_division = undefined), getDivisionByLevel()"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              :items="divisions"
              v-model="filter.fk_division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="fk_division"
              :label="$t('division.name')"
              clearable
              persistent-hint
              density="compact"
            />
          </v-col>

          <v-col cols="3" md="3">
            <v-autocomplete
              :items="semesters"
              v-model="filter.fk_semester"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name_ar"
              item-value="id"
              :label="$t('semester.name')"
              clearable
              persistent-hint
              density="compact"
              :rules="[$required]"
              @update:modelValue="
                getMonthsBySemester(), (this.filter.fk_monthsemester = null)
              "
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              color="indigo"
              v-model="filter.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="month"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
              @update:model-value="getCurrentMonth(),filter.date_from=null,filter.date_to=null"
            />
          </v-col>
          <v-col md="3">
            <DatetimePicker
              v-model="filter.date_from"
              :minDate="minDate"
              :maxDate="maxDate"
              :rules="[$max_date(filter.date_to)]"
              :disabled="filter.fk_monthsemester ? false : true"
              @update:model-value="items = []"
              :label="$t('globals.start-date')"
              forma="dd"
              readOnly
            />               
          </v-col>
          <v-col md="3">
            <DatetimePicker
              v-model="filter.date_to"
              :minDate="minDate"
              :maxDate="maxDate"
              :rules="[$min_date(filter.date_from)]"
              :disabled="filter.fk_monthsemester ? false : true"
              @update:model-value="items = []"
              :label="$t('globals.end-date')"
              forma="dd"
              readOnly
            />            
          </v-col>
          <v-col cols="2" class="mt-2">
            <custom-btn type="show" :click="() => getData()" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  <custom-table-report
    v-else
    :close="() => reset()"
    :headers="headers"
    :items="items"
    counter
  />
</template>
<script>
import moment from "moment-hijri";

export default {
  data() {
    return {
      filter: {},
      items: [],
      headers: [],
      summer_schools: [],
      levels: [],
      divisions: [],
      semesters: [],
      months: [],
      minDate: null,
      maxDate: null,      
      btn_loading: false,
      show: false,
    };
  },
  created() {
    this.getLevels();
    this.getSemester();
    this.getSummerSchools();
  },
  methods: {
    getCurrentMonth() {
      this.filter.date = undefined
      if (this.filter.fk_monthsemester) {
        this.items = [];
        this.filter.date = null;
        const month =
          this.filter.fk_monthsemester < 10
            ? "0" + this.filter.fk_monthsemester.toString()
            : this.filter.fk_monthsemester;
        const now = moment();
        const startOfMonth = now.iYear() + "-" + month + "-01";
        const endOfMonth = moment(
          `${now.iYear()}-${this.filter.fk_monthsemester + 1}-01`,
          "iYYYY-iM-iDD"
        )
          .subtract(1, "day")
          .format("iYYYY-iMM-iDD");
        this.minDate = startOfMonth;
        this.maxDate = endOfMonth;

        if (
          this.months.find(
            (e) =>
              e.month == this.filter.fk_monthsemester &&
              e.current_month == true
          ) &&
          this.filter.fk_monthsemester == moment().format("iM")
        )
          this.filter.date = moment().format("iYYYY-iMM-iDD");
        else this.filter.date = this.minDate;

        this.$nextTick(() => {
        });
      }
    },    
    async getData() {
      const { valid } = await this.$refs.form.validate();
      if (valid)
        await this.axios(
          `${this.base_url}api/summer/report/lectures-seminars-presentations/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              fk_monthsemester: this.months.find(
                (e) => e.month == this.filter.fk_monthsemester
              ).id,
              date_from: this.filter.date_from,
              date_to: this.filter.date_to,
              fk_summer: this.filter.fk_summer,
              fk_semester: this.filter.fk_semester,
              fk_level: this.filter.fk_level,
              fk_division: this.filter.fk_division,
            },
          }
        )
          .then((response) => {
            this.items = response.data.data;
            if (this.items.length > 0) {
              this.headers= response.data.headers;
              this.show = true;
            } else this.$emit("infoAlert", this.$t("globals.not-found"));
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },
    reset() {
      this.show = false;
      this.items = [];
    },
    async getSummerSchools() {
      await this.axios
        .get(`${this.base_url}api/summer/school-select-list`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.summer_schools = response.data;
        });
    },
    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivisionByLevel() {
      if (this.filter.fk_level&&this.filter.fk_summer) {
        let levelDivisions = await this.axios.get(
          this.base_url + `api/summer/get-division-by-level-school/?fk_level=${this.filter.fk_level}&fk_summer=${this.filter.fk_summer}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.divisions = levelDivisions.data.division;
      }
    },
    async getSemester() {
      let semester = await this.axios.get(this.base_url + `api/summer/semesters/list/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.semesters = semester.data;
      const current_semester = this.semesters.find((e) => e.current_semester);
      if (current_semester) {
        this.filter.fk_semester = current_semester.id;
        this.getMonthsBySemester();
      }
    },
    async getMonthsBySemester() {
      if (this.filter.fk_semester)
        await this.axios(
          `${this.base_url}/api/months-semester/?fk_semester=${this.filter.fk_semester}`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        )
          .then((response) => {
            this.months = response.data;
            const current_month = response?.data?.find((e) => e.current_month);
            if (current_month) {
              this.filter.fk_monthsemester = current_month.month;
            }
          this.getCurrentMonth();
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      else this.months = [];
    },
  },
};
</script>
