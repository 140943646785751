<template>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_estimate')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="CheckGetPermission('legal_school.delete_estimate',['sys_admin','gen_admin'])"
    :edit_perm="CheckGetPermission('legal_school.change_estimate',['sys_admin','gen_admin'])"
    :add_perm="CheckGetPermission('legal_school.add_estimate',['sys_admin','gen_admin'])"
  >
  </DataTable>
   <v-dialog
    v-model="addDialog"
    max-width="700"
    
  >
   <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="is_update?updateItem():saveItem()">
          <VRow class="mt-2">
            <VCol  cols="6">
              <VTextField
                v-model="estimate.name_ar"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('estimate.name_ar')"
                density="compact"
                :placeholder="$t('estimate.name_ar_placeholder')"
                :rules="[$ar_letters_only, $required, $max_length(50)]"
              >
              </VTextField>
            </VCol>
            <VCol  cols="6">
              <VTextField
                v-model="estimate.name_en"
                prepend-inner-icon="mdi-alpha-e"
                :label="$t('estimate.name_en')"
                density="compact"
                :placeholder="$t('estimate.name_en_placeholder')"
                :rules="[$en_letters_only, $max_length(50)]"
              >
              </VTextField>
            </VCol>
            <VCol  cols="6" >
              <VTextField
                v-model="estimate.degree_max"
                maxLength="3"
                prepend-inner-icon="mdi-numeric"
                :label="$t('estimate.degree_max')"
                density="compact"
                :placeholder="$t('estimate.degree_max_placeholder')"
                :rules="[$required, $numeric,$more_than(estimate.degree_min),$max_value(100)]"
              >
              </VTextField>
            </VCol>
            <VCol  cols="6" >
              <VTextField
                v-model="estimate.degree_min"
                maxLength="2"
                prepend-inner-icon="mdi-numeric"
                :label="$t('estimate.degree_min')"
                density="compact"
                :placeholder="$t('estimate.degree_min_placeholder')"
                :rules="[$required, $numeric,$less_than(estimate.degree_max),]"
              >
              </VTextField>
            </VCol>
            <VCol cols="12">
                <VTextarea
                  v-model="estimate.note"
                  prepend-inner-icon="mdi-note-outline"
                  clearable
                  color="indigo"
                  :label="$t('globals.note')"
                  :placeholder="$t('school.placeholder_estimate_description')"
                  no-resize
                  counter="250"
                  density="compact"
                  rows="2"
                :rules="[$max_length(250)]"
                ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
            {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{  
    DataTable
  },
  data() {
    return {
      addDialog:false,
      protected_records: false,
      data_message: [],
      estimates: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      estimate: {
        name_ar: undefined,
        name_en: undefined,
        degree_max: undefined,
        degree_min:undefined,
        note: undefined
      },     
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("estimate.name_ar"), key: "name_ar" },
        { title: this.$t("estimate.name_en"), key: "name_en" },
        { title: this.$t("estimate.degree_max"), key: "degree_max" },
        { title: this.$t("estimate.degree_min"), key: "degree_min" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.estimates) {
        return JSON.parse(JSON.stringify(this.estimates));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    editItem(data) {
      this.estimate = Object.assign({},data)
      this.is_update = true;
      this.updated_id = data.id;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async getItems(page = 1,per_page = 10,ordering = null,search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/estimate/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      }).then((response) => {
        this.estimates = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.estimate.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/api/estimate/", this.estimate, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.non_field_errors) {
              this.$emit("warningAlert", this.$t("summer.already-estimate"));
            }
            else{ 
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.estimate.id != undefined) {
        this.editDataLoading = true;
        this.estimate.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}/api/estimate/${this.estimate.id}/`, this.estimate, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("errorAlert", this.$t("summer.already-governorate"));
            } else if (error.response.data.name_en) {
              this.$emit("warningAlert", this.$t("summer.already-governorate-en"));
            } else this.$emit("warningAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(`${this.base_url}/api/estimate/${delete_id}/`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          });
        return status
      
      }
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>
<style>

</style>
