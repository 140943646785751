<template>
  <!-- added by samer -->
  <!-- شاشة  تنقيذ الانشطة -->
  <v-form ref="form_1">
    <v-card class="pa-5">
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            :items="activityType"
            v-model="dropList.fk_activity_type"
            item-title="name"
            item-value="id"
            :label="$t('summer.activity.type')"
            persistent-hint
            prepend-inner-icon="mdi-soccer"
            density="compact"
            clearable
            @update:model-value="exist=false"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            color="indigo"
            v-model="dropList.fk_monthsemester"
            density="compact"
            item-title="month_name"
            item-value="month"
            prepend-inner-icon="mdi-calendar-month"
            :items="months"
            :label="$t('month.select')"
            :rules="[$required]"
            @update:model-value="getCurrentMonth"
          />
        </v-col>
        <v-col cols="4">
          <DatetimePicker
            v-if="show"
            v-model="dropList.date"
            :minDate="minDate"
            :maxDate="maxDate"
            :rules="[$required]"
            :disabled="dropList.fk_monthsemester ? false : true"
            @update:model-value="items = []"
            forma="dd"
            readOnly
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            v-model="dropList.iteration"
            :items="iterations"
            item-title="name"
            item-value="id"
            :label="$t('globals.iteration')"
            persistent-hint
            prepend-inner-icon="mdi-refresh"
            density="compact"
            clearable           
            :rules="[$required]"
            @update:model-value="items = [],exist=false"
          />
        </v-col>

        <v-btn :text="$t('globals.show')" color="primary" @click="getData" />
      </v-row>
    </v-card>
  </v-form>
  <v-card v-if="exist">
    <v-form ref="form">
    <v-data-table-virtual
      v-if="CheckGetPermission('legal_school.view_managmenttask')&&exist"
      :items="items"
      :headers="headers"
      density="compact"
      :loading="loading"
    >
      <template v-slot:item.is_done="{ item }">
        <v-checkbox-btn v-model="item.is_done" @change="item.from_time = null,item.to_time = null,item.img=null"       />
      </template>
      <template v-slot:item.from_time="{ item }">
         <v-text-field
                  v-model="item.from_time"
                  density="compact"
                  type="time"
                  clearable
                  style="width:160px;"         
                  :disabled="!item.is_done"
                  
                />
                
      </template>
      <template v-slot:item.to_time="{ item }">
         <v-text-field
                  v-model="item.to_time"
                  density="compact"
                  type="time"
                  clearable
                  style="width:160px;"
                  :rules="[$time_min_max(item.from_time,item.to_time)]"
                  :disabled="!item.is_done"     
                />
                
      </template>
      <template v-slot:item.img="{ item }">  
        <v-card  :disabled="!item.is_done"    elevation="0">
         <custom-img v-model="item.img" height="70" width="70" class="mb-2"   />      
        </v-card>
      </template>
      <template v-slot:item.note="{ item }">
         <v-text-field
                  v-model="item.note"
                  density="compact"               
                  clearable
                  style="width:200px;"
                  :rules="[$max_length(250)]"
                  :disabled="!item.is_done"     
                />
                
      </template>
    </v-data-table-virtual>
    </v-form>
    <template v-slot:actions>
      <v-btn
        v-if="items.length > 0"
        :text="$t('globals.save')"
        class="bg-primary"
        size="small"
        @click="saveData"
        :loading="save_loading"
      />
    </template>
  </v-card>
</template>
<script>
import DataTable from "@/components/Globals/DataTable.vue";
import moment from "moment-hijri";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      show: true,
      items: [],
      iterations: [],
      activityType: [],
      months: [],
      exist: false,
      loading: false,
      save_loading: false,
      dropList: {},
      minDate: null,
      maxDate: null,
    };
  },
  created() {
    this.getActivityType();
    this.getIterations();
    this.getMonths();
  },

  methods: {
    getCurrentMonth() {
      if(this.dropList.fk_monthsemester){
        this.items = []
        this.show = false;
        this.dropList.date = null;
        const month =
          this.dropList.fk_monthsemester < 10
            ? "0" + this.dropList.fk_monthsemester.toString()
            : this.dropList.fk_monthsemester;
        const now = moment();
        const startOfMonth = now.iYear() + "-" + month + "-01";
      

        // const endOfMonth =
        //   now.iYear() +
        //   "-" +
        //   month +
        //   "-" +       
        // now.endOf("iMonth").iDate()

        const endOfMonth = moment(`${now.iYear()}-${ this.dropList.fk_monthsemester +1 }-01`,'iYYYY-iM-iDD').subtract(1,'day').format('iYYYY-iMM-iDD')
        this.minDate = startOfMonth;
        this.maxDate = endOfMonth;

        if (this.months.find((e) =>e.month == this.dropList.fk_monthsemester && e.current_month == true)&& this.dropList.fk_monthsemester == moment().format("iM"))
          this.dropList.date = moment().format("iYYYY-iMM-iDD")
        else 
        this.dropList.date = this.minDate;


        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
    async getData() {
      this.items = [];
      const { valid } = await this.$refs.form_1.validate();

      if (valid) {
        this.loading = true;
        
        const fk_monthsemester = this.months.find((e) => e.month == this.dropList.fk_monthsemester)
        await this.axios(
          `${this.base_url}/api/activity-operation/get-activity-operation/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              iteration: this.dropList.iteration,
              fk_activity_type: this.dropList.fk_activity_type,
              date: this.dropList.date,
              fk_monthsemester: fk_monthsemester.id,
            },
          }
        )
          .then((response) => {
            this.items = response.data;
            if(this.items.length === 0){
              this.$emit("warningAlert", this.$t("globals.not-found"));
            }  else {
              this.exist = true;
            }
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      } else this.items = [];

      this.loading = false;
    },
    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.dropList.fk_monthsemester = current_month.month;
            this.getCurrentMonth();
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },  
    async saveData() {

      const {valid} = await this.$refs.form.validate()

      if(valid){
      this.save_loading = true;
      const data = this.items.map((e) => {
        return {
          ...e,
          fk_monthsemester: this.months.find(
            (e) => e.month == this.dropList.fk_monthsemester
          ).id,
        };
      });
        let form = new FormData();
        
      form.append("data", JSON.stringify(data))
      
      data.forEach((e,i)=>{
        if(e.img){
        if(typeof(e.img)=='object')
          form.append(`file_${i}`, e.img[0]);
        else form.append(`file_${i}`,  e.img);
        }else form.append(`file_${i}`, null);

      })

      await this.axios
        .post(
          `${this.base_url}/api/activity-operation/save-operations/`,
          form,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((e) => {
          this.getData();
          this.$emit("successAlert", this.$t("globals.data_updated"));
        })
        .catch((e) => {
          if (e.response.status == 406) {
            this.$emit("infoAlert", e.response.data);

            return;
          }

          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    }
    
    this.save_loading = false;
    },
    async getActivityType() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "ActivityType",
          fields: ["id", "name"],
        })
        .then((response) => {
          this.activityType = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getIterations() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "IterationActivityChoice",
          choices: true,
        })
        .then((response) => {
          this.iterations = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    headers() {
      return [
        { title: this.$t("activity.name"), key: "activity_name",sortable: false},
        { title: this.$t("globals.executed"), key: "is_done", sortable: false },
        { title: this.$t("globals.from"), key: "from_time", sortable: false },
        { title: this.$t("globals.to"), key: "to_time", sortable: false },
        { title: this.$t("globals.activity_img"), key: "img", sortable: false },
        { title: this.$t("globals.note"), key: "note", sortable: false },      
      ];
    },
  },
};
</script>

