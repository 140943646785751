<template>
      <v-form ref="form">
  <v-card class="mb-2">
    <v-card-title class="px-4">
      <span>{{ $t("globals.select-criteria") }}</span>
    </v-card-title>
    <v-card-text class="ma-2">
        <v-row class="py-2">
          <v-col cols="12" md="4" lg="4">
            <VAutocomplete
              prepend-inner-icon="mdi-account-group-outline"
              :items="committee"
              v-model="fk_committee"
              item-title="name"
              item-value="id"
              :label="$t('school.committee_name')"
              type="data"
              persistent-hint
              density="compact"
              clearable
              :rules="[$required]"
            ></VAutocomplete>
          </v-col>
          <v-btn
            @click="getmembercomit((page = 1))"
            color="primary"
            density="comfortable"
            append-icon="mdi-presentation"
            class="me-3 mt-2"
          >
            <span class="text-white">
              {{ $t("globals.show") }}
            </span>
          </v-btn>
        </v-row>
    </v-card-text>
  </v-card>
      </v-form>
  <v-card
    :loading="loading"
    v-if="
      CheckGetPermission('legal_school.view_commitee', [
        'sys_admin',
        'summer_admin',
        'user',
      ])
    "
  >
    <v-card-text>
      <DataTable
        :items="lectures_attendance"
        :headers="headers"
        density="compact"
        :method="getmembercomit"
        :create="() => dicultsandslotions()"
        :pagination="pagination"
        :editItem="editData"
        :delItem="delData"
      >
      </DataTable>
    </v-card-text>
  </v-card>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
export default {
  name: "LecturesList",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updateAuthenticated",
        localStorage.getItem("token")
      );
      await this.getCommittee();
 

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      v$: useValidate(),
      deleteProgress: false,
      fk_committee: undefined,
      dialog: false,
      addDialog: false,
      update: false,
      loading: false,
      user: null,
      sortBy: [{ key: "name", order: "asc" }],
      selectedHead: [
        "name",
        "adjective",
        "entity",
        "phone_number",
        "note",
        "actions",
      ],
      fk_level: null,
      fk_subject: null,
      fk_empoylee: null,
      fk_division: null,
      from_date: null,
      lectures_attendance: [],
      headers: [
        { title: this.$t("summer.member_name"), key: "name" },
        { title: this.$t("summer.member_adject"), key: "adjective" },
        { title: this.$t("summer.member_entity"), key: "entity" },
        { title: this.$t("summer.member_phonenumber"), key: "phone_number" },
        { title: this.$t("globals.description"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      perPage: 10,
      length: 0,
      page: 1,
      totalItems: null,
      pagination: {},
    };
  },
  computed: {
    ...mapState({
      teachers: (state) => state.summer.teachers,
      classes: (state) => state.summer.levels,
      divisions: (state) => state.summer.divisions,
      subjects: (state) => state.summer.subjects,
      teacher_class: (state) => state.school.teacher_class,
      committee: (state) => state.summer.committee,
    }),
    ...mapGetters({}),
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    tableList() {
      var list = JSON.parse(JSON.stringify(this.lectures_attendance));
      list.forEach((element) => {
        let fk_level = element.fk_level;
        let fk_empoylee = element.fk_empoylee;
        let fk_subject = element.fk_subject;
        let fk_division = element.fk_division;
        element.fk_level = this.getClassName(fk_level);
        element.fk_subject = this.getSubjectName(fk_subject);
        element.fk_empoylee = this.getTeacherName(fk_empoylee);
        element.fk_division = this.getDivisionName(fk_division);
      });
      return list;
    },
    class_subjects() {
      if (this.fk_level) {
        var class_data = this.classes.find((item) => item.id == this.fk_level);
        var subjects_data = [];
        class_data.subjects.forEach((element) => {
          subjects_data.push({
            id: element,
            name_ar: this.getSubjectName(element),
          });
        });
      }
      return subjects_data;
    },
    class_teachers() {
      var teacher_data = this.teachers;
      if (this.fk_level) {
        var teacher_id = this.teacher_class.results
          .filter((item) => item.fk_level == this.fk_level)
          .map((item) => item.fk_empoylee);
        var teacher_unique = [...new Set(teacher_id)];
        teacher_data = [];
        teacher_unique.forEach((el) => {
          teacher_data.push({ id: el, name_ar: this.getTeacherName(el) });
        });
      }
      return teacher_data;
    },
    teacher_subjects() {
      var subject_data = this.subjects;
      if (this.fk_empoylee && this.fk_level) {
        var subject_id = this.teacher_class.results
          .filter(
            (item) =>
              item.fk_empoylee === this.fk_empoylee &&
              item.fk_level == this.fk_level
          )
          .map((item) => item.fk_subject);
        var subjects_uniqe = [...new Set(subject_id)];
        subject_data = [];
        subjects_uniqe.forEach((el) => {
          subject_data.push({ id: el, name_ar: this.getSubjectName(el) });
        });
      } else if (!this.fk_empoylee && this.fk_level) {
        subject_data = this.class_subjects;
      }
      return subject_data;
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },

  validations() {
    return {
      lecture_attendance: {
        name: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(100)
          ),
        },
        lecture_date: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(250)
          ),
        },
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_subject: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_empoylee: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        data_entry: {},
        updated_by: {},
      },
    };
  },
  methods: {
    ...mapActions({
      getClasses: "summer/getLevels",
      getSubjects: "summer/getSubjects",
      getAllTeachers: "summer/getAllTeachers",
      getTeacherClass: "school/getTeacherClass",
      getDivisions: "summer/getDivisions",
      getCommittee: "summer/getCommittee",
    }),
    dicultsandslotions() {
      this.$router.push({ name: "membercommit_add" });
    },
    getClassName(id) {
      var item = this.classes.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },
    getSubjectName(id) {
      var item = this.subjects.find((item) => item.id === id);
      return item ? (this.$i18n.locale == "ar" ? item.name : item.name) : "";
    },
    getTeacherName(id) {
      var item = this.teachers.find((item) => item.id === id);
      return item
        ? this.$i18n.locale == "ar"
          ? item.name_ar
          : item.name_en
        : "";
    },
    getDivisionName(id) {
      var item = this.divisions.find((item) => item.id === id);
      return item ? (this.$i18n.locale == "ar" ? item.name : item.name) : "";
    },

    async getmembercomit(page = 1, per_page = 10, ordering = "id", search = null) {
      if(this.fk_committee)
  {    const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        await this.axios(`${this.base_url}api/summer/committee-member`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_committee: this.fk_committee,
            search: search,
            page: page,
            page_size: per_page,
            sort_by: ordering,
          },
        }).then((response) => {
          this.lectures_attendance = response.data.results;
          this.pagination = response.data.pagination;

          this.loading = false;
        });
      }}
    },
    editData(data) {
      this.$router.push(`${data.id}/edit`);
    },
    async delData(delete_id) {
      if (delete_id) {
        var status;
        await this.axios
          .delete(`${this.base_url}api/summer/committee-member/${delete_id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
  },
  watch: {
    perPage() {
      this.getmembercomit();
    },
    page() {
      this.getmembercomit(this.page);
    },
    order_data() {
      this.getmembercomit(this.page);
    },
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
#vDataTable span {
  font-size: 11px;
}
</style>
