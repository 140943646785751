<template>
  <!-- <v-expansion-panels class="mb-2">
    <v-expansion-panel>
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-1">
          <VCol cols="6" md="4" sm="6" class="mb-1">
            <VAutocomplete
              :items="levels"
              v-model="studenttalent.level"
              prepend-inner-icon="mdi-stairs-box"
              item-title="level_with_phase_name"
              item-value="id"
              :label="$t('summer.level.name_ar')"
              persistent-hint
              hide-details="auto"
              density="compact"
              clearable
              @update:modelValue="
                getDivisionByLevel(),
                  getStudentsbylevel(),
                  (this.studenttalent.division = null),
                  (this.student_levels = [])
              "
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="4" sm="6" class="mb-1">
            <VAutocomplete
              :items="divisions"
              v-model="studenttalent.division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="fk_division"
              :label="$t('division.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
              @update:modelValue="getStudentsbylevel"
            ></VAutocomplete>
          </VCol>
          <v-col cols="6" md="4" sm="6" class="mb-1">
            <v-select
              color="indigo"
              v-model="type"
              density="compact"
              item-title="name"
              item-value="id"
              hide-details="auto"
              prepend-inner-icon="mdi-soccer"
              :items="activityType"
              :label="$t('summer.activity.type')"
              @update:modelValue="getActivitiesByType(), (activity = null)"
            />
          </v-col>
          <VCol cols="12" class="mb-1">
            <VAutocomplete
              :items="allActivitiesByType"
              v-model="activities_selected"
              return-object
              prepend-inner-icon="mdi-soccer"
              item-title="name"
              multiple
              clearable
              chips
              closable-chips
              hide-details="auto"
              :item-value="(item) => item.id"
              :label="$t('summer.activity.activity_name')"
              persistent-hint
              density="compact"
            >
              <template v-slot:chip="{ props, item }">
                <v-chip
                  class="mx-1"
                  style="font-size: 0.9rem"
                  v-bind="props"
                  :text="item.name"
                >
                </v-chip>
              </template>
            </VAutocomplete>
          </VCol>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels> -->
  <VCard class="justify-center">
    <VForm ref="form">
      <v-card-text class="pb-0">
        <v-row class="my-2">
          <VCol cols="6">
            <v-text-field
              color="indigo"
              v-model="search_student"
              @input="getStudentsbylevel"
              density="compact"
              clearable
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              :label="$t('globals.student-search')"
            />
          </VCol>
        </v-row>
        <v-card
          v-if="student_levels?.length > 0"
          :loading="loading"
          :disabled="loading"
          class="mt-2 overflow-x-auto"
        >
          <div class="overflow-x-auto">
            <v-table
              fixed-header
              :height="student_levels?.length > 9 ? 400 : null"
              density="compact"
              class="w-100"
            >
              <thead>
                <tr>
                  <th style="min-width: 200px">
                    {{ $t("school.student-name") }}
                  </th>
                  <th class="text-center">{{ $t("school.is_attendance") }}</th>
                  <th class="text-center">{{ $t("school.attendance_date") }}</th>
                  <th class="text-center">{{ $t("school.first_phase") }}</th>
                  <th class="text-center">{{ $t("school.second_phase") }}</th>
                  <th class="text-center">{{ $t("school.third_phase") }}</th>
                  <th class="text-center">{{ $t("school.fk_monthsemester") }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(student, index) in student_levels" :key="student">
                  <td>
                    {{ student.student_name }}
                  </td>
                  <td>
                    <v-lazy>
                      <!-- :value="activity.id" -->
                      <v-checkbox hide-details density="compact" color="indigo">
                      </v-checkbox>
                    </v-lazy>
                  </td>
                  <td class="spsfic-width-lg">
                    <DatetimePicker
                      :calendar="'gregorg'"
                      :placeholder="$t('globals.year-month-day')"
                      :label="$t('school.end-date')"
                    ></DatetimePicker>
                  </td>
                  <td>
                    <v-lazy>
                      <!-- :value="activity.id" -->
                      <v-checkbox hide-details density="compact" color="indigo">
                      </v-checkbox>
                    </v-lazy>
                  </td>
                  <td>
                    <v-lazy>
                      <!-- :value="activity.id" -->
                      <v-checkbox hide-details density="compact" color="indigo">
                      </v-checkbox>
                    </v-lazy>
                  </td>
                  <td>
                    <v-lazy>
                      <!-- :value="activity.id" -->
                      <v-checkbox hide-details density="compact" color="indigo">
                      </v-checkbox>
                    </v-lazy>
                  </td>
                  <td class="spsfic-width-lg">
                    <VAutocomplete
                      :items="countries"
                      autofocus
                      prepend-inner-icon="mdi-flag"
                      item-title="name_ar"
                      item-value="id"
                      :label="$t('school.main_groupe')"
                      persistent-hint
                      :auto-select-first="true"
                      density="compact"
                    ></VAutocomplete>
                  </td>
                </tr>
              </tbody>
            </v-table>
            <v-divider />
            <div class="d-flex mt-1" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="length"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ totalItems }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </div>
        </v-card>
        <v-card v-else-if="!loading" class="pa-2 ma-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
      </v-card-text>
    </VForm>
    <VCardActions class="px-3" v-if="student_levels.length > 0">
      <VBtn
        :loading="loadingSave"
        v-if="
          CheckGetPermission('legal_school.add_studentsummer', [
            'sys_admin',
            'summer_admin',
            'user',
          ]) ||
          CheckGetPermission('legal_school.change_studentsummer', [
            'sys_admin',
            'summer_admin',
            'user',
          ])
        "
        class="bg-indigo"
        @click="saveData"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.save") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
</template>
<script>
import { isNumber } from "pdfmake/build/pdfmake";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      checkbox: false,
      element: [],
      add_activity: null,
      perPage: 10,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: null, text: this.$t("globals.all") },
      ],
      pagination: {},
      page: 1,
      length: null,
      totalItems: null,
      type: undefined,
      students: [],
      allActivitiesByType: [],
      search_student: "",
      activities_selected: [],
      filter_activities: [],
      loading: false,
      student_levels: [],
      loadingSave: false,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      divisions: [],
      studenttalent: {
        division: null,
        level: null,
      },
      perPage: 10,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: null, text: this.$t("globals.all") },
      ],
      currentPage: 1,
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.fk_year = localStorage.getItem("current_year");
      await this.getLevels();
      await this.getactivites();
      await this.getActivityType();
      await this.getStudentsbylevel();
      // this.filter_activities=this.activites.filter(activity=>!this.student_levels.some(student=>student.activity.includes(activity.id)))
      const not_assigned_activities = this.activites.filter(
        (activity) =>
          !this.student_levels.some((student) => student.activity.includes(activity.id))
      );
      if (this.activites.length > 0) {
        if (not_assigned_activities.length == 0) {
          this.filter_activities = [this.activites[this.activites.length - 1]].map(
            (item) => {
              return {
                id: item.id,
                name: item.name,
                checked: false,
              };
            }
          );
        } else {
          this.filter_activities = not_assigned_activities?.map((item) => {
            return {
              id: item.id,
              name: item.name,
              checked: false,
            };
          });
        }
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      levels: (state) => state.summer.levels,
      students: (state) => state.summer.students,
      user: (state) => state.User,
      activityType: (state) => state.summer.activityType,
      activites: (state) => state.summer.activites,
    }),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  methods: {
    filterInput(event) {
      // replace any non-numeric characters with an empty string
      const value = event.target.value.replace(/[^0-9]/g, "");
      event.target.value = value;
    },
    ...mapActions({
      getLevels: "summer/getLevels",
      getStudents: "summer/getStudents",
      getActivityType: "summer/getActivityType",
      getactivites: "summer/getactivites",
    }),
    addActivityForAllStudents(id, check) {
      if (check) {
        this.student_levels.forEach((student) => {
          student.activity.push(id);
        });
      } else {
        this.student_levels.forEach((student) => {
          student.activity = student.activity.filter((activity) => activity != id);
        });
      }
    },
    async getStudentsbylevel() {
      this.loading = true;
      let student_level = await this.axios.get(
        this.base_url + "api/summer/get-student-by-level-and-division",
        {
          params: {
            level: this.studenttalent.level,
            division: this.studenttalent.division,
            page: this.page,
            page_size: this.perPage,
            search_txt: this.search_student,
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.loading = false;
      this.student_levels = student_level.data.results;
      this.pagination = student_level.data.pagination;
      this.length = this.pagination?.num_pages;
      this.itemsPerPage[4]["value"] = this.pagination?.count;
      this.totalItems = this.pagination?.count;
    },
    async getDivisionByLevel() {
      if (this.studenttalent.level) {
        let levelDivisions = await this.axios.get(
          this.base_url + `api/summer/get-level-division/${this.studenttalent.level}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.divisions = levelDivisions.data.division;
      }
    },
    getActivityName(driver_id) {
      if (driver_id) {
        const committees = this.allActivitiesByType.find(
          (committees) => committees.id === driver_id
        );
        return committees.name;
      }
      return "===========";
    },
    async getActivitiesByType() {
      await this.axios
        .get(`${this.base_url}api/summer/get-activity-by-type`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            activity_type: this.type,
          },
        })
        .then((response) => {
          if (response.data) {
            this.allActivitiesByType = response.data;
          }
        })
        .catch((error) => {});
    },
    resetForm() {
      this.activities = [];
      this.students_activities = [];
      this.filter_activities = [];

      this.studenttalent.division = null;
      this.studenttalent.level = null;
      this.search_student = "";
      this.activities_selected = [];
      this.studenttalent = {};
      this.studentList = [];
    },
    async saveData() {
      this.loadingSave = true;
      let result = await this.axios
        .post(this.base_url + `api/summer/add-activity-to-student`, this.student_levels, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.loadingSave = false;
          this.$emit("successAlert", this.$t("globals.data_added"));
          // this.resetForm();
        })
        .catch((error) => {
          this.loadingSave = false;
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    isCheckAll() {
      this.filter_activities.forEach((activity) => {
        const checked = this.students_activities.every((student) =>
          student.activity.includes(activity.id)
        );
        if (checked) {
          activity.checked = activity.id;
        } else {
          activity.checked = false;
        }
      });
    },
  },
  watch: {
    activities_selected() {
      if (this.activities_selected.length > 0) {
        this.filter_activities = this.activities_selected?.map((item) => {
          return {
            id: item.id,
            name: item.name,
            checked: false,
          };
        });
      } else {
        this.filter_activities = this.activites?.map((item) => {
          return {
            id: item.id,
            name: item.name,
            checked: false,
          };
        });
      }
    },
    students_activities: {
      deep: true,
      handler() {
        this.isCheckAll();
      },
    },
    perPage() {
      this.page = 1;
      this.getStudentsbylevel();
    },
    page() {
      this.getStudentsbylevel(this.page);
    },
  },
};
</script>

<style scoped>
.spsfic-width {
  min-width: 120px;
}
.spsfic-width-lg {
  min-width: 150px;
}
.v-label {
  overflow: visible !important;
}
.v-input--density-compact {
  --v-input-control-height: 35px;
  --v-input-padding-top: 8px;
}
.select-container {
  height: 280px;
  box-shadow: rgb(0, 0, 0) -5px 5px 3px -5px inset;
  overflow-y: auto;
}
td span {
  padding: 0 !important;
  font-size: 12px !important;
}
</style>
