<template>
  <!-- added by samer -->
  <!-- شاشة اجازات الطالب -->
  <DataTable
    v-if="CheckGetPermission('legal_school.view_holiday')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getData"
    :create="
      () => {
        dialog = true;
        this.students =[]
      }
    "
    :pagination="pagination"
    :editItem="editData"
    :delItem="deleteData"
    :del_perm="
      CheckGetPermission('legal_school.delete_holiday', ['summer_admin'])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_holiday', ['summer_admin'])
    "
    :add_perm="CheckGetPermission('legal_school.add_holiday', ['summer_admin'])"
  >
  </DataTable>
  <v-dialog v-model="dialog" max-width="500">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          class=""
          @submit.prevent="dropList.id ? updateData() : saveData()"
        >
          <v-col>
            <v-autocomplete
              color="primary"
              v-model="dropList.fk_level"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-stairs-box"
              :items="levels"
              clearable
              :label="$t('summer.level.name_ar')"              
              :rules="[$required]"
              @update:model-value="getStudents"
              autofocus
            />
          </v-col>

          <v-col class="mt-3">
            <v-autocomplete
              color="primary"
              v-model="dropList.fk_levelstudentyear"
              density="compact"
              item-title="fk_student__name"
              item-value="id"
              prepend-inner-icon="mdi-account"
              :items="students"
              clearable
              :label="$t('reportpublic.student-name')"              
              :rules="[$required]"
            />
          </v-col>
          <v-col class="mt-3">
            <DatetimePicker
              v-model="dropList.start_date"
              :rules="[$required,$max_value(dropList.end_date)]"
              forma="dd"
              :label="$t('globals.start-date')"
          /></v-col>
          <v-col class="mt-3">
            <DatetimePicker
              v-model="dropList.end_date"
              :rules="[$required,$min_value(dropList.start_date)]"
              forma="dd"
              :label="$t('globals.end-date')"
          /></v-col>
          <v-col class="mt-3">
            <v-autocomplete
              color="primary"
              v-model="dropList.type"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-format-list-bulleted-type"
              :items="holiday_type"
              clearable
              :label="$t('globals.holiday_type')"              
              :rules="[$required]"
            />
          </v-col>
           <v-col class="mt-3">
              <v-text-field

                v-model="dropList.reason"
                prepend-inner-icon="mdi-flag"

                :label="$t('school.reason')"
                persistent-hint
                density="compact"
                :rules="[$required]"
              ></v-text-field>
            </v-col>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!dropList.id"
          @click="saveData()"
          :loading="loading_btn"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="dropList.id"
          @click="updateData()"
          :loading="loading_btn"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="dropList.id? dialog = false: dropList={}">
          {{ !dropList.id? $t("globals.clear"): $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
    
<script>
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      dropList: {},
      pagination: {},

      items: [],
      levels: [],
      students: [],
      holiday_type: [],

      dialog: false,
      loading_btn: false,
    };
  },
  created() {
    this.getLevel();
    this.getHolidayType();
  },
  methods: {
    async getData(page = 1, per_page = 10, ordering = "id", search = null) {
    
      if(ordering=='stu_name')
        ordering = 'fk_levelstudentyear__fk_student__name'
      else if(ordering=='-stu_name')
        ordering = '-fk_levelstudentyear__fk_student__name'
    
      if(ordering=='type_name')
        ordering = 'type'
      else if(ordering=='-type_name')
        ordering = '-type'

      await this.axios(`${this.base_url}/api/student-holiday/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      }).then((response) => {
        this.items = response.data.results;
        this.pagination = response.data.pagination;
      });
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading_btn = true
        await this.axios
          .post(this.base_url + "api/student-holiday/", this.dropList, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.dropList = {}
            this.getData();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.loading_btn = false        
      }
    },
    editData(data) {
        this.students =[]
        this.dropList ={}
        this.dropList = {...data}
        this.getStudents()
        this.dialog = true
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        await this.axios
          .put(
            this.base_url + "/api/student-holiday/" + this.dropList.id + "/",
            this.dropList,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));            
            this.dialog = false
            this.getData();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async deleteData(delete_id) {        
      if (delete_id) {
        var status;
        await this.axios
          .delete(this.base_url + "api/student-holiday/" + delete_id + "/", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((e) => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getLevel() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getStudents() {
    if(!this.dropList.id)
      this.dropList.fk_levelstudentyear = undefined;

      this.students = [];
      await this.axios(`${this.base_url}api/student-by-level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        params: {
          fk_level: this.dropList.fk_level,
        },
      })
        .then((response) => (this.students = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getHolidayType() {
      await this.axios(`${this.base_url}choices/holiday-type-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.holiday_type = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  computed: {
    headers() {
      return [
        { title: this.$t("year.placeholder.name"), key: "stu_name" },
        { title: this.$t("reportpublic.Type"), key: "type_name" },
        { title: this.$t("school.start-date"), key: "start_date" },
        { title: this.$t("school.end-date"), key: "end_date" },
        { title: this.$t("school.reason"), key: "reason" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ];
    },
  },
  watch:{
    dialog(){
        if(!this.dialog){
            this.dropList = {}
            this.students =[]
            }
    }
  }
};
</script>
