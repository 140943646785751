<template>
  <!-- added by samer -->
  <!-- شاشة المستلزمات  -->
  <v-dialog v-model="dialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-title>
        {{$t('summer-sidebar.supplies-add')}}
      </v-card-title>
      <v-card-text class="pb-0">
        <VForm ref="form">
          <!-- <custom-fields
            :data="dropList"
            url="api/summer/supplies/fields/"
            :object="{
              amount: {
                cond: dropList.is_found,
              },
              is_found: {
                radio: true,
                fun: () => (dropList.amount = null),
              },
            }"
          /> -->

       <v-select
            v-model="dropList.type"
            :label="$t('summer.type-statistic')"
            persistent-hint
            item-title="name"
            item-value="id"
            clearable
            density="compact"
            :items="statistics_mode"
            prepend-inner-icon="mdi-chart-pie"
            :rules="[$required]"
          />
          <VTextField
            v-model="dropList.name"
            :label="$t('summer-sidebar.supplies-name')"
            persistent-hint
            clearable
            density="compact"
            prepend-inner-icon="mdi-headphones"
            :rules="[$required, $max_length(100)]"
          />
          <VTextField
            v-model="dropList.company"
            :label="$t('report.company')"
            persistent-hint
            clearable
            density="compact"
            prepend-inner-icon="mdi-factory"
            :rules="[$max_length(100)]"
          />
          <VTextField
            v-model="dropList.provider"
            :label="$t('report.entity')"
            persistent-hint
            clearable
            density="compact"
            prepend-inner-icon="mdi-account"
            :rules="[$required, $max_length(100)]"
          />
          <DatetimePicker v-model="dropList.date" />
          <VRadioGroup
            inline
            :label="$t('summer.is-statistic')"
            v-model="dropList.is_found"
            @update:modelValue="this.dropList.amount = null"
            :rules="[$required]"
          >
            <VRadio :label="$t('globals.yes')" :value="true" />
            <VRadio :label="$t('globals.no')" :value="false" />
          </VRadioGroup>
          <VTextField
            v-if="dropList.is_found"
            v-model="dropList.amount"
            :label="$t('report.quantity')"
            persistent-hint
            type="number"
            clearable
            density="compact"
            prepend-inner-icon="mdi-numeric"
            :rules="[$max_length(9)]"
          />
          <VTextarea
            v-model="dropList.note"
            clearable
            :label="$t('summer.initiative.note')"
            no-resize
            counter="250"
            density="compact"
            rows="2"
            prepend-inner-icon="mdi-note-outline"
            :rules="[$max_length(250)]"
          /> 
   
   
        </VForm>
      </v-card-text>

      <VCardActions class="mx-3">
        <VBtnUpdate
          v-if="dropList.id"
          @click="updateData()"
          :loading="btn_loading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtnSave v-else @click="saveData()" :loading="btn_loading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtn class="mx-3" size="small" @click="dropList = {}">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>

  <DataTable
    v-if="CheckGetPermission('legal_school.view_supervisor')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getData"
    :create="() => (dialog = true)"
    :pagination="pagination"
    :editItem="editData"
    :delItem="delData"
    :del_perm="CheckGetPermission('legal_school.delete_supervisor')"
    :edit_perm="CheckGetPermission('legal_school.change_supervisor')"
    :add_perm="CheckGetPermission('legal_school.add_supervisor')"
  >
    <template v-slot:item.is_found="{ item }">
      <v-icon
        color="success"
        v-if="item.is_found == true"
        icon="mdi-check-circle"
      />
      <v-icon
        color="error"
        v-else-if="item.is_found == false"
        icon="mdi-close-circle"
      />
    </template>
  </DataTable>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      statistics_mode: [],

      dropList: {},
      pagination: {},

      dialog: false,
      btn_loading: false,
    };
  },
  created() {
    this.getStatisticsMode();
  },
  methods: {
    async getData(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      if(ordering == 'supp_type')
        ordering = 'type'
      else if(ordering == '-supp_type')
        ordering = '-type'

      await this.axios(`${this.base_url}/api/summer/supplies/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      })
        .then((response) => {
          this.items = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });

      this.loading = false;
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.btn_loading = true;
        await this.axios
          .post(this.base_url + "api/summer/supplies/", this.dropList, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.dropList = {};
            this.getData();
          })
          .catch((error) => {
            if (error?.response?.data?.non_field_errors) {
              this.$emit(
                "errorAlert",
                this.$t("alert.failure.unique") +
                  this.$t("summer-sidebar.supplies-name")
              );
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
        this.btn_loading = false;
      }
    },
    editData(data) {
      this.dropList = { ...data };
      this.dialog = true;
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.btn_loading = true;
        await this.axios
          .put(
            `${this.base_url}api/summer/supplies/${this.dropList.id}/`,
            this.dropList,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.dialog = false;
            this.getData();
          })
          .catch((error) => {
            if (error?.response?.data?.non_field_errors) {
              this.$emit(
                "errorAlert",
                this.$t("alert.failure.unique") +
                  this.$t("summer-sidebar.supplies-name")
              );
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
        this.btn_loading = false;
      }
    },
    async delData(delete_id) {
      if (delete_id) {
        var status;
        this.deleteDataLoading = true;
        await this.axios
          .delete(`${this.base_url}api/summer/supplies/${delete_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },

    async getStatisticsMode() {
      await this.axios(
        `${this.base_url}/api/summer/choices/get-statistics-mode`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
        .then((response) => (this.statistics_mode = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    headers() {
      return [
        { title: this.$t("report.entity"), key: "provider" },
        { title: this.$t("report.company"), key: "company" },
        {
          title: this.$t("summer.type-statistic"),
          key: "supp_type",  
        },
        { title: this.$t("summer-sidebar.supplies-name"), key: "name" },
        {
          title: this.$t("summer.is-statistic"),
          key: "is_found",
        },
        { title: this.$t("report.quantity"), key: "amount"},
        { title: this.$t("financial.date"), key: "date"},
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.dropList = {};
        this.$refs.form?.resetValidation();
      }
    },
  },
};
</script>