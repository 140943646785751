<template>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_semester')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      CheckGetPermission('legal_school.delete_semester', ['sys_admin', 'gen_admin'])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_semester', ['sys_admin', 'gen_admin'])
    "
    :add_perm="
      CheckGetPermission('legal_school.add_semester', ['sys_admin', 'gen_admin'])
    "
  >
    <template v-slot:item.current_semester="{ item }">
      <span>
        <v-icon v-if="item.current_semester == true" color="success"
          >mdi-check-circle</v-icon
        >
        <v-icon v-if="item.current_semester == false" color="error"
          >mdi-close-circle</v-icon
        >
      </span>
    </template>
  </DataTable>
  <v-dialog v-model="addDialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="is_update ? updateItem() : saveItem()">
          <VRow class="mt-2">
            <VCol cols="6">
              <VTextField
                v-model="semester.name_ar"
                prepend-inner-icon="mdi-alpha-e"
                :label="$t('semester.semester')"
                density="compact"
                :placeholder="$t('semester.enter')"
                :rules="[$ar_letters_only, $required, $max_length(50)]"
              >
              </VTextField>
            </VCol>
            <VCol cols="6">
              <VTextField
                v-model="semester.num_months"
                maxLength="2"
                prepend-inner-icon="mdi-numeric"
                :label="$t('globals.num-months')"
                density="compact"
                :placeholder="$t('globals.enter_num_months')"
                :rules="[$required, $numeric,$max_value(12)]"
              >
              </VTextField>
            </VCol>
            <VCol cols="9" class="pt-0">
              <VCardItem class="pa-0">
                <VCheckbox
                  v-model="semester.current_semester"
                  :label="$t('semester.acyual_semester')"
                  hide-details
                >
                </VCheckbox>
              </VCardItem>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="semester.note"
                prepend-inner-icon="mdi-note-outline"
                clearable
                color="indigo"
                :label="$t('globals.note')"
                :placeholder="$t('school.placeholder_governorate_description')"
                no-resize
                counter="250"
                density="compact"
                rows="2"
                :rules="rules.note"
              ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveItem()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateItem()" :loading="saveDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()" v-if="!is_close">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="closeForm()" v-if="is_close">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>         
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      protected_records: false,
      data_message: [],
      semesters: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      semester: {
        name_ar: undefined,
        num_months: undefined,
        current_semester: false,
        note: undefined,
      },
      rules: {
        current_semester: [(value) => !!value || this.$t("globals.required_field")],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_close: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("semester.semester"), key: "name_ar" },
        { title: this.$t("globals.num-months"), key: "num_months" },
        { title: this.$t("semester.acyual_semester"), key: "current_semester" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.semesters) {
        return JSON.parse(JSON.stringify(this.semesters));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    closeForm() {
      this.addDialog = false;
    },
    resetForm() {
      this.$refs.form.reset();
      this.is_close = false;
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
      this.semester.current_semester = false;
    },
    editItem(data) {
      this.semester = Object.assign({}, data);
      this.is_close = true;
      this.is_update = true;
      this.updated_id = data.id;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/semesters/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      }).then((response) => {
        this.semesters = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.saveDataLoading = true;
        this.semester.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/api/summer/semesters/", this.semester, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("warningAlert", this.$t("summer.already-semester"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.semester.id != undefined) {
        this.editDataLoading = true;
        this.semester.updated_by = this.user;
        await this.axios
          .put(
            `${this.base_url}/api/summer/semesters/${this.semester.id}/`,
            this.semester,
            {
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            }
          )
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
            this.closeForm();
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("errorAlert", this.$t("summer.already-semester"));
            } else this.$emit("warningAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(`${this.base_url}/api/summer/semesters/${delete_id}/`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>
<style></style>
