<template>
<DataTable
    v-if="CheckGetPermission('legal_school.view_parentsummer')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getParent"
    :create="
      () => {
        $router.push({ name: 'parent' })
      }
    "
    :pagination="pagination"
    :editItem="editData"
    :delItem="delData"
    :del_perm="CheckGetPermission('legal_school.delete_parentsummer',['sys_admin','summer_admin'])"
    :edit_perm="CheckGetPermission('legal_school.change_parentsummer',['sys_admin','summer_admin'])"
    :add_perm="CheckGetPermission('legal_school.add_parentsummer',['sys_admin','summer_admin'])"
  >
  <template v-slot:item.identity_image="{ item }">
    <a :href="item.identity_image" download>
      <img 
      :src="item.identity_image" 
      width="50" 
      style="cursor: pointer"
      />
    </a>
  </template>
  </DataTable>
</template>

<script>
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs=pdfFonts.pdfMake.vfs
import { mapState, mapActions } from "vuex";
export default {
  name: "ParentsList",
  data() {
    return {
      data_message: [],
      overlay: false,
      selectedHead: [
        "name",
        "identity_type",
        "identity_number",
        "identity_image",
        "job",
        "address",
        "phone_number",
        "home_number",
        "actions",
      ],
      txt_search: "",
      alert: false,
      totalItems: null,
      sortBy: [{ key: "id", order: "asc" }],
      perPage: 10,
      length: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 100000, text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      parents: [],
      loading: false,
    };
  },
  components: {},
  computed: {
    ...mapState({
      identify_type: (state) => state.summer_globals.identify_type,
      role:(state)=>state.role
    }),
    headers() {
      return [
        { title: this.$t("school.parent_name"), key: "name" },
        { title: this.$t("school.parent_id"), key: "identity_type" },
        { title: this.$t("school.parent_id_no"), key: "identity_number" },
        { title: this.$t("school.parent_id_image"),  key: "identity_image",  sortable: false, },
        { title: this.$t("school.parent_job"), key: "job" },
        { title: this.$t("school.parent_address"), key: "address" },
        { title: this.$t("school.parent_phone"), key: "phone_number" },
        { title: this.$t("school.parent_landline"), key: "home_number" },
        { title: this.$t("school.notes"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ];
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      let list = [];
      this.parents.forEach((element) => {
        let identity = this.identify_type.find(
          (item) => item.id == element.identity_type
        );
        if (identity != undefined) identity = identity.name;
        list.push({
          name: element.name,
          identity_type: identity,
          identity_number: element.identity_number,
          identity_image: element.identity_image,
          job: element.job,
          address: element.address,
          phone_number: element.phone_number,
          home_number: element.home_number,
          note: element.note,
          id: element.id,
        });
      });
      return list;
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getParent();
      await this.getIdentifyType();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getIdentifyType: "summer_globals/getIdentifyType",
    }),
    editData(data) {
      this.$router.push(`parent/${data.id}/edit/`);
    },
    selectImage(e) {
      this.parent.image = e;
    },
    async getParent(page = 1, per_page = 10, ordering = "id", search = null) {
      this.loading = true;
      return await this.axios(`${this.base_url}/api/summer/parent/`, {
        params: {
          search: search,
          page: page,
          page_size: this.perPage,
          // sort_by: this.order_data,
          ordering: ordering,
          
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.parents = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
          this.itemsPerPage[4].value = response.data.pagination.count;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    async delData(delete_id) {
      if (delete_id) {
        var status;
        this.deleteDataLoading = true;
        await this.axios
          .delete(
            `${this.base_url}api/summer/parent/${delete_id}/`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
  },
  mounted() {
    this.headersitems = [];
    const indexes = Object.values(this.selectedHeaders);
    indexes.forEach((el) => {
      if (el !== "") {
        this.headersitems.push(this.headers[el]);
      }
    });
  },
  watch: {
    selectedHeaders() {
      this.headersitems = [];
      const indexes = Object.values(this.selectedHeaders);
      indexes.forEach((el) => {
        if (el !== "") {
          this.headersitems.push(this.headers[el]);
        }
      });
    },
    perPage() {
      this.getParent();
    },
    page() {
      this.getParent(this.page);
    },
    order_data(){
     this.getParent(this.page); 
    }
  },
};
</script>
<style scoped>
#dataTable span {
  font-size: 12px;
}
</style>
