<template>
    <v-container class="ma-auto" >
    <v-locale-provider :locale="locale">
      <v-row align="center" justify="center" >
        <v-col cols="12" md="8" sm="12">
          <v-card elevation="6" class="mt-10 rounded-xl"  >
            <v-row>
                <v-col cols="12"  md="7"  sm="7" class="text-center mb-6 px-4">
                    <v-sheet class="my-16">
                        <h1  style="font-size:8rem;line-height:1;" >404</h1>
                        <h1  style="font-size:1.5rem;" class=" py-1 px-2">{{$t('globals.page-not-found')}}</h1>               
                        <h4 style="line-height:1;">{{$t('globals.page-not-found-title')}}</h4>
                        <v-btn class="mt-4" rounded color="primary" @click="$router.push({name:'summer_dashboard'})">{{$t('globals.back-to-home')}}</v-btn>
                    </v-sheet>
                </v-col>
                <v-col  
                    cols="12" 
                    sm="5"
                    md="5" 
                    class="d-flex align-center justify-center bg-primary"
                    :style="locale=='ar'?'border-radius: 0 25% 25% 0;':'border-radius: 25% 0 0 25%;'"
                >
                </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-locale-provider>
  </v-container>
</template>
<script>
export default {
    data(){
        return{
            
        }
    },
    computed:{
        locale(){
            return this.$i18n.locale
        }
    }
}
</script>