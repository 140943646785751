<template>
  <VCard >
    <v-card flat 
      v-if="
        CheckGetPermission('globaltable.add_country', ['sys_admin', 'gen_admin']) ||
        CheckGetPermission('globaltable.change_country', ['sys_admin', 'gen_admin'])
      "
    >
      <v-card-text class="pb-0">
        <VForm
          ref="form"
        >
          <VRow
            class="mt-2"
            v-if="CheckGetPermission('globaltable.add_country', ['sys_admin', 'gen_admin'])"
          >
            <VCol  cols="12" md="4">
                <VTextField
                  v-model="country.name_ar"
                  autofocus
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('school.country_name')"
                  :placeholder="$t('school.placeholder_country_name')"
                  density="compact"
                  :rules="rules.name_ar"
                >
                </VTextField>
            </VCol>
            <VCol  cols="12" md="4">
                <VTextField
                  v-model="country.name_en"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('school.country_name_en')"
                  :placeholder="$t('school.placeholder_country_name_en')"
                  density="compact"
                  :rules="rules.name_en"
                >
                </VTextField>
            </VCol>
          </VRow>
          <VRow >
            <VCol  cols="12" md="4">
                <VTextField
                  v-model="country.nati_name_ar"
                  prepend-inner-icon="mdi-abjad-arabic"
                  :label="$t('school.nationality_name')"
                  :placeholder="$t('school.placeholder_nationality_name')"
                  density="compact"
                  :rules="rules.name_ar"
                >
                </VTextField>
            </VCol>
            <VCol cols="12" md="4">
                <VTextField
                  v-model="country.nati_name_en"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('school.nationality_name_en')"
                  :placeholder="$t('school.placeholder_nationality_name_en')"
                  density="compact"
                  :rules="rules.name_en"
                >
                </VTextField>
            </VCol>
          </VRow>
          <VRow >
            <VCol cols="12"  md="8">
                <VTextarea
                  v-model="country.note"
                  prepend-inner-icon="mdi-note-outline"
                  clearable
                  :label="$t('globals.note')"
                  no-resize
                  color="primary"
                  counter="250"
                  density="compact"
                  :placeholder="$t('school.placeholder_country_description')"
                  rows="2"
                  :rules="rules.note"
                ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions
        class="px-3"
        v-if="
          CheckGetPermission('globaltable.add_country', ['sys_admin', 'gen_admin']) ||
          CheckGetPermission('globaltable.change_country', ['sys_admin', 'gen_admin'])
        "
      >
        <VBtn
          v-if="
            !is_update &&
            CheckGetPermission('globaltable.add_country', ['sys_admin', 'gen_admin'])
          "
          class="bg-primary"
          @click="saveCountry()"
          size="small"
          :loading="saveDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          v-if="
            is_update &&
            CheckGetPermission('globaltable.change_country', ['sys_admin', 'gen_admin'])
          "
          class="bg-primary"
          @click="updateCountry()"
          size="small"
          :loading="editDataLoading"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
    <!-- ##########    data Table  ########### -->
    <v-divider  />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card >
      <v-card-text>
        <div class="d-flex justify-space-between mb-2">
          <div class="d-flex flex-fill">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" variant="outlined" color="#ccc" size="small">
                  <v-icon style="color: #5e7e96" class="me-2">mdi-eye-outline</v-icon>
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="primary"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <div class="w-100 px-2">
              <v-text-field
                color="primary"
                style="
                  border-radius: 30px;
                  border: 1px solid #ccc;
                  color: black;
                  height: 30px;
                "
                class="search"
                hide-details
                prepend-inner-icon="mdi-magnify"
                single-line
                :placeholder="$t('globals.search-here')"
                density="compact"
                variant="text"
                v-model="txt_search"
                @input="getCountries((page = 1))"
              >
              </v-text-field>
            </div>
          </div>
        </div>
        <!-- ######## End Header Of Data Table Server ####### -->
        <v-data-table-server
          :headers="selectedHeaders"
          :items="tableList"
          fixed-footer
          :search="txt_search"
          density="compact"
          class="elevation-1 rounded"
          :loading="loading"
          v-model:sort-by="sortBy"
          item-value="name"
          item-key="id"
        >
          <template v-slot:column.checkbox="{ column }">
            <v-checkbox hide-details density="compact"> </v-checkbox>
          </template>
          <template v-slot:item="{ item }">
            <tr class="row-hover">
              <td v-for="(val, key) in item.columns" :key="key">
                <v-checkbox v-if="key == 'checkbox'" hide-details density="compact">
                </v-checkbox>
                <span> {{ val }} </span>
                <span v-if="key === 'actions'">
                  <v-btn
                    variant="text"
                    class="me-1 v-btn-icon"
                    size="large"
                    v-if="
                      CheckGetPermission('globaltable.change_country', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="editItem(item.raw)"
                  >
                    <v-icon color="warning">mdi-pencil-outline</v-icon>
                  </v-btn>
                  <v-btn
                    variant="text"
                    size="small"
                    class="v-btn-icon"
                    v-if="
                      CheckGetPermission('globaltable.delete_country', [
                        'sys_admin',
                        'gen_admin',
                      ])
                    "
                    @click="deleteItem(item.raw)"
                  >
                    <v-icon color="error">mdi-trash-can-outline</v-icon>
                  </v-btn>
                </span>
              </td>
            </tr>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card-text>

    </v-card>
  </VCard>
  <delete-dialog
            v-model="del_dialog"
            :loading="deleteDataLoading"
            @confirm-delete="deleteCountry()"
        />
  <alert-protected-records v-model="protected_records" :data_message="data_message"></alert-protected-records>

</template>
<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      countries: [],
      protected_records:false,
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      rules: {
        name_ar: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        name_en: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            !value || /^[A-Za-z-]+$/.test(value) || this.$t("globals.must_be_letters_en"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      alert: true,
      country: {},
      is_update: false,
      updated_id: undefined,
      del_dialog: false,
      delete_id: undefined,
      headers: [
        { title: this.$t("school.country_name"), key: "name_ar" },
        { title: this.$t("school.country_name_en"), key: "name_en" },
        { title: this.$t("school.nationality_name"), key: "nati_name_ar" },
        { title: this.$t("school.nationality_name_en"), key: "nati_name_en" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
      selectedHead: [
        "name_ar",
        "name_en",
        "nati_name_ar",
        "nati_name_en",
        "note",
        this.checkrole(['sys_admin', 'gen_admin']) ? "actions":null
      ],
      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.countries) {
        return JSON.parse(JSON.stringify(this.countries));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      if(this.checkrole(['sys_admin', 'gen_admin']))
        this.headers.push({ title: this.$t("globals.actions"), key: "actions", sortable: false })
      await this.getCountries()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    async getCountries(page = 1) {
      this.loading = true;
      await this.axios(`${this.base_url}/country-for-data-table/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
        },
      }).then((response) => {
        this.countries = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    editItem(data) {
      for (const key in data) this.country[key] = data[key];
      this.is_update = true;
      this.updated_id = data.id;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    deleteItem(data) {
      this.delete_id = data.id;
      this.del_dialog = true;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async saveCountry() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.country.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/country/", this.country, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
            this.saveDataLoading = false;
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("warningAlert", this.$t("summer.already-country"));
            } else if (error.response.data.name_en) {
              this.$emit("warningAlert", this.$t("summer.already-country-en"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
        this.getCountries();
      }
    },
    async deleteCountry() {
      if (this.delete_id != undefined) {
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}/country/${this.delete_id}/`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.deleteDataLoading = false;
          })
          .catch((error) => {
            if(error.message.includes(418)){
              this.protected_records=true
              let error_messages=[]
              error.response.data.data.forEach(error=>{
                error_messages.push(this.$t(`models.${error.model}`)+` = ${error.count}`)
              })
              this.data_message=error_messages  

            }
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.deleteDataLoading = false;
          });
        this.del_dialog = false;
        this.delete_id = undefined;
        this.getCountries();
      }
    },
    async updateCountry() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.country.id != undefined) {
        this.editDataLoading = true;
        this.country.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}/country/${this.country.id}/`, this.country, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("warningAlert", this.$t("summer.already-country"));
            } else if (error.response.data.name_en) {
              this.$emit("warningAlert", this.$t("summer.already-country-en"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.getCountries();
      }
    },
  },
  watch: {
    perPage() {
      this.getCountries();
    },
    page() {
      this.getCountries(this.page);
    },
    order_data(){
      this.getCountries(this.page)
    }
  },
};
</script>
<style scoped>

</style>
