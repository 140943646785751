<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card>
      <v-card-text class="pb-0">
        <VForm ref="form">
             <!-- <custom-fields :dropList="dropList" url_field="api/approach-view/fields/">
             
             </custom-fields> -->
          <v-autocomplete
            color="indigo"
            v-model="dropList.fk_monthsemester"
            density="compact"
            item-title="month_name"
            item-value="month"
            prepend-inner-icon="mdi-calendar-month"
            :items="months"
            :label="$t('month.select')"
            :rules="[$required]"
            @update:model-value="getCurrentMonth"
          />
          <DatetimePicker
            v-if="show"
            v-model="dropList.date"
            :minDate="minDate"
            :maxDate="maxDate"
            :rules="[$required]"
            :disabled="dropList.fk_monthsemester ? false : true"
            @update:model-value="items = []"
            forma="dd"
            readOnly
          />
          <VAutocomplete
            v-model="dropList.fk_level"
            :items="levels"
            item-title="name_ar"
            item-value="id"
            :label="$t('summer.level.level')"
            persistent-hint
            prepend-inner-icon="mdi-stairs-box"
            density="compact"
            hide-details="auto"
            :rules="[$required]"
            clearable
            @update:model-value="
              getLevelBySubject(), (dropList.fk_levelsubject = undefined)
            "
          />
          <VAutocomplete
            v-model="dropList.fk_levelsubject"
            :items="subjects"
            prepend-inner-icon="mdi-book-open"
            item-title="name"
            item-value="id"
            :label="$t('subject.subject')"
            persistent-hint
            density="compact"
            :rules="[$required]"
            clearable
            class="mt-3"
          />
          <VTextField
            v-model="dropList.received_books"
            prepend-inner-icon="mdi-numeric"
            :label="$t('summer.recived-books')"
            :placeholder="$t('summer.recived-books')"
            persistent-hint
            density="compact"
            clearable
            type="number"
            no-resize
            counter
            :rules="[$required,$max_length(9)]"
          />

          <VTextarea
            v-model="subjects.note"
            clearable
            :label="$t('globals.note')"          
            density="compact"
            rows="2"
            prepend-inner-icon="mdi-note-outline"
            :rules="[$max_length(250)]"
            counter="250"
          />
        </VForm>
      </v-card-text>

      <VCardActions class="mx-3">
        <VBtnUpdate
          v-if="dropList.id"
          @click="updateData()"
          :loading="btn_loading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtnSave v-else @click="saveData()" :loading="btn_loading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtn class="mx-3" size="small" @click="dropList.id?dialog=false:dropList={}">
          {{  !dropList.id ? $t("globals.clear") : $t('globals.cancel')}}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_change_approach')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getData"
    :create="
      () => {
          const current_month = this.months.find((e) => e.current_month);
          if (current_month) {
            this.dropList.fk_monthsemester = current_month.month;
            this.getCurrentMonth();
          }

        dialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editData"
    :delItem="delData"
    :del_perm="CheckGetPermission('legal_school.delete_change_approach')"
    :edit_perm="CheckGetPermission('legal_school.change_change_approach')"
    :add_perm="CheckGetPermission('legal_school.add_change_approach')"
  >
  </DataTable>
</template>

<script>
import moment from "moment-hijri";

export default {
  data() {
    return {
      dropList: {},
      pagination: {},

      items: [],
      subjects: [],
      months: [],
      levels: [],

      minDate: null,
      maxDate: null,

      dialog: false,
      btn_loading: false,
      show: true,
    };
  },
  created() {
    this.getMonths();
    this.getLevels();
  },
  methods: {
    async getData(page = 1, per_page = 10, ordering = null, search = null) {

      if(ordering == 'subject_name')
        ordering = 'fk_levelsubject__fk_subjecT__name'
      else if(ordering == '-subject_name')
        ordering = '-fk_levelsubject__fk_subjecT__name'
 
      if(ordering == 'level_name')
        ordering = 'fk_levelsubject__fk_level__name_ar'
      else if(ordering == '-level_name')
        ordering = '-fk_levelsubject__fk_level__name_ar'
 
      if(ordering == 'month_name')
        ordering = 'fk_monthsemester_id'
      else if(ordering == '-month_name')
        ordering = '-fk_monthsemester_id'
 
 
 this.loading = true;

      await this.axios(`${this.base_url}/api/approach-view/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      })
        .then((response) => {
          this.items = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });

      this.loading = false;
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.btn_loading = true;

        await this.axios
          .post(
            this.base_url + "api/approach-view/",
            {
              ...this.dropList,
              fk_monthsemester: this.months.find(
                (e) => e.month == this.dropList.fk_monthsemester
              ).id,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.dropList = {};
            this.getData();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.btn_loading = false;
      }
    },
    async editData(data) {
      this.dropList = { ...data };
      await this.getLevelBySubject();
      this.dropList.fk_monthsemester = this.months.find(
        (e) => e.id == data.fk_monthsemester
      ).month;
      this.dialog = true;
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.btn_loading = true;
        await this.axios
          .put(
            `${this.base_url}api/approach-view/${this.dropList.id}/`,
            {
              ...this.dropList,
              fk_monthsemester: this.months.find(
                (e) => e.month == this.dropList.fk_monthsemester
              ).id,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.dialog = false;
            this.getData();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.btn_loading = false;
      }
    },
    async delData(delete_id) {
      if (delete_id) {
        var status;
        this.deleteDataLoading = true;
        await this.axios
          .delete(`${this.base_url}api/approach-view/${delete_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },

    getCurrentMonth() {
      this.show = false;
      this.dropList.date = null;
      const month =
        this.dropList.fk_monthsemester < 10
          ? "0" + this.dropList.fk_monthsemester.toString()
          : this.dropList.fk_monthsemester;
      const now = moment();
      const startOfMonth = now.iYear() + "-" + month + "-01";

      // const endOfMonth =
      //   now.iYear() +
      //   "-" +
      //   month +
      //   "-" +
      // now.endOf("iMonth").iDate()

      const endOfMonth = moment(
        `${now.iYear()}-${this.dropList.fk_monthsemester + 1}-01`,
        "iYYYY-iM-iDD"
      )
        .subtract(1, "day")
        .format("iYYYY-iMM-iDD");
      this.minDate = startOfMonth;
      this.maxDate = endOfMonth;

      if (
        this.months.find(
          (e) =>
            e.month == this.dropList.fk_monthsemester && e.current_month == true
        )
      )
        this.dropList.date = moment().format("iYYYY-iMM-iDD");
      else this.dropList.date = this.minDate;

      this.$nextTick(() => {
        this.show = true;
      });
    },
    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.dropList.fk_monthsemester = current_month.month;
            this.getCurrentMonth();
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getLevelBySubject() {
      this.subjects = [];

      await this.axios(`${this.base_url}api/get-subject-by-level`, {
        params: {
          fk_level: this.dropList.fk_level,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.subjects = response.data;
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  computed: {
    headers() {
      return [
        { title: this.$t("summer.level.name_ar"), key: "level_name" },
        { title: this.$t("monthly_marks.month"), key: "month_name" },
        { title: this.$t("financial.date"), key: "date" },
        { title: this.$t("subject.subject"), key: "subject_name" },
        { title: this.$t("summer.recived-books"), key: "received_books" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.dropList = {};
        this.$refs.form?.resetValidation();
      }
    },
  },
};
</script>