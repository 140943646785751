<template>
<!-- added by samer -->
<!-- تقرير بطائق الطلاب  -->
  <v-card class="pa-3" v-if="!show">
    <v-card-title>
      {{ $t("globals.select-criteria") }}
    </v-card-title>
    <v-card-text class="mt-2">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="filter.fk_level"
              :items="levels"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              hide-details="auto"
              :rules="[$required]"
              clearable
              @update:model-value="
                (filter.fk_division = undefined), getDivisionByLevel()
              "
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              :items="divisions"
              v-model="filter.fk_division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="fk_division"
              :label="$t('division.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
            />
          </v-col>
          <v-col cols="1" class="mt-2">
            <custom-btn type="show" :click="() => getData()" />
          </v-col>
        </v-row> </v-form></v-card-text
  ></v-card>

  <v-card v-else ref="report" >
    <v-card-text>
      <v-row class="text-center align-center hide-item pa-5">
        <custom-btn type="print" @click="printReport" class="me-3" />

        <v-text-field
          v-model="search"
          hide-details
          prepend-inner-icon="mdi-magnify"
          variant="outlined"
          single-line
          class="ms-2"
        />
        <v-spacer />
        <v-icon v-if="show" icon="mdi-close" class="me-2" @click="reset" end />
      </v-row>

      <v-col v-for="(item, index) in filter_items" :key="item.fk_student" eager>
        <v-row class="my-3">
          <v-card
            class="text-center pa-3 me-2"
            border
            style="width: 100mm; height: 50mm"
            elevation="0"
          >
            <v-row align="center" justify="space-between">
              <v-col cols="4" class="text-right">
                <span class="school-name">المدارس الثانوية للعلوم الشرعية</span>
              </v-col>
              <v-col cols="4" align="center">
                <img :src="logo_center" width="50" height="50"/>
              </v-col>
              <v-col cols="4" align="left">
                <img :src="logo_left" width="50" height="50"/>
              </v-col>
            </v-row>

            <v-row align="center" justify="align-center my-2">
              <v-col cols="4">
                <v-divider
                  color="golden"
                  class="border-opacity-75"
                  thickness="2"
                />
              </v-col>

              <v-col cols="4" class="text-center">
                <span class="title">بطاقة تعريفية</span>
              </v-col>
              <v-col cols="4">
                <v-divider
                  color="golden"
                  class="border-opacity-75"
                  thickness="2"
                />
              </v-col>
            </v-row>
            <v-card
              class="student-info text-right   h-screen"
              elevation="0"
          

            >
              <v-row class="mt-2">
                <v-col cols="auto">
                  <h3><strong>اسم الطالب : </strong></h3>
                </v-col>
                <v-col class="dotted-line pe-5">
                  <h3>{{ item.full_name }}</h3>
                </v-col>
              </v-row>

              <v-row class="mt-4">
                <v-col cols="auto">
                  <h3><strong>رقم الطالب :</strong></h3>
                </v-col>
                <v-col cols="3" class="dotted-line pe-1">
                  <h3>{{ item.fk_student__academic_id }}</h3>
                </v-col>
              </v-row>
               <v-row class="mt-4">
                 <v-col cols="auto">
                  <h3><strong>اسم المجموعة :</strong></h3>
                </v-col>
                <v-col class="dotted-line pe-1">
                  <h3>{{ item.fk_residential_group__name }}</h3>
                </v-col>
               </v-row>
            </v-card>
          </v-card>

          <v-card
            class="text-center pa-5"
            color="#2c7274"
            border
            style="width: 100mm; height: 50mm; position: relative"
            elevation="0"
          >
            <v-row>
              <v-col cols="10">
                <div col="10" class="text-right">
                  <h3>
                    . هذه البطاقة تحتوي على رقم (المرقد , المجموعة, الغرفة)
                    فالحفاظ عليها حفاظ على مكانك داخل المدرسة.
                  </h3>
                  <h3 class="mt-2">
                    . يرجى اقتناء هذه البطاقة بشكل مستمر وإبرازها أثناء دخولك
                    قاعة الدروس والمحاضرات.
                  </h3>
                </div>
              </v-col>

              <img
                :src="logo_center"
                width="50"
                height="50"
                class="ma-5 position-absolute"
                style="left: 0; bottom: 0"
              />
            </v-row>
          </v-card>
        </v-row>
        <v-row class="hidden" v-if="(index + 1) % 5 != 0">
          <v-col><div class="dotted-line mt-1" /></v-col>
          <v-col cols="auto"><v-icon icon="mdi-content-cut" /></v-col>
        </v-row>
        <v-sheet v-else height="40"></v-sheet>
      </v-col>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      filter: {},

      items: [],
      levels: [],
      divisions: [],

      title: "",
      search: "",
      logo_center:require("@/assets/12.jpg"),
      logo_left:require("@/assets/logo-blue.png"),

      show: false,

    };
  },
  created() {
    this.getLevels();
  },
  methods: {
    printReport() {
      setTimeout(() => {
        const printContent = this.$refs.report.$el.innerHTML;
        document.getElementById("printView").style.display = "block";
        document.getElementById("printView").innerHTML = printContent;
        document.getElementById("printView").style.direction = "rtl";
        document.getElementById("appViewId").style.display = "none";
        // document.getElementById('dataTableReport').style.height = 'auto'
        window.print();
        document.getElementById("printView").innerHTML = "";
        document.getElementById("printView").style.display = "none";
        document.getElementById("appViewId").style.display = "block";
        // document.getElementById('dataTableReport').style.height = '450px'
      }, 10);
    },
    async getData() {
      // const { valid } = await this.$refs.form.validate();
      // if (valid)
      await this.axios(
        `${this.base_url}api/summer/report/student-data-report/card-report/`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },

          params: {
            fk_level: this.filter.fk_level,
            fk_division: this.filter.fk_division,
          },
        }
      )
        .then((response) => {
          this.items = response.data.data;
          this.title = response.data.title;
          if (this.items.length > 0) {
            this.show = true;
          } else this.$emit("infoAlert", this.$t("globals.not-found"));
        })
        .catch((e) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },

    reset() {
      this.show = false;
      this.items = [];
    },
    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivisionByLevel() {
      if (this.filter.fk_level) {
        let levelDivisions = await this.axios.get(
          this.base_url +
            `api/summer/get-level-division/${this.filter.fk_level}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.divisions = levelDivisions.data.division;
      }
    },
  },
  computed: {
    filter_items() {
        
      if (this.search)
        return this.items.filter((e) =>
          e.full_name.includes(this.search)
        );
      else return this.items;
    },
    
    
  },
};
</script>
<style scoped>
.dotted-line {
  border-bottom: 0.5px dotted #333 !important;
}
.student-info {
  font-size: 16px;
  line-height: 1.6;
}
.title {
  font-size: 15px;
  font-weight: bold;
  color: #0a8d82;
}
.hidden {
  opacity: 0;
}
@media print {
  .hidden {
    opacity: 100%;
  }
}
</style>