<template>
  <v-expansion-panels class="mb-2" model-value="1">
    <v-expansion-panel value="1">
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <VCol
            class="mt-1"
            cols="6"
            md="3"
            sm="4"
            v-if="role == 0 || role == 1"
          >
            <VAutocomplete
              :items="filterGovernorates(1)"
              v-model="fk_governorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              hide-details
              clearable
              :no-data-text="$t('governorate.not-found')"
              prepend-inner-icon="mdi-city"
              @update:modelValue="
                (page = 1),
                  getSummerSchools(),
                  (fk_directorate = null),
                  (fk_summer = null)
              "
            ></VAutocomplete>
          </VCol>
          <VCol
            class="mt-1"
            cols="6"
            md="3"
            sm="4"
            v-if="role >= 0 && role <= 2"
          >
            <VAutocomplete
              :items="filterDirectorates(fk_governorate)"
              v-model="fk_directorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.directorate')"
              persistent-hint
              hide-details
              density="compact"
              clearable
              :no-data-text="$t('directorate.not-found')"
              prepend-inner-icon="mdi-map-outline"
              @update:modelValue="
                (page = 1), getSummerSchools(), (fk_summer = null)
              "
            ></VAutocomplete>
          </VCol>
          <VCol
            class="mt-1"
            cols="6"
            md="3"
            sm="4"
            v-if="role >= 0 && role <= 3"
          >
            <VAutocomplete
              v-model="fk_summer"
              item-value="id"
              item-title="name_ar"
              prepend-inner-icon="mdi-domain"
              :items="summer_schools"
              :label="$t('globals.summer-centre')"
              persistent-hint
              hide-details
              density="compact"
              :no-data-text="$t('summer.not-found')"
              @update:modelValue="page = 1"
              clearable
            ></VAutocomplete>
          </VCol>

          <VCol cols="6" sm="4" md="3" lg="4" v-if="role == 4">
            <VAutocomplete
              :items="levels"
              v-model="fk_level"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name_ar"
              item-value="id"
              :label="$t('class.name')"
              persistent-hint
              density="compact"
              clearable
              @update:modelValue="(fk_division = undefined)"
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" sm="4" md="3" v-if="role == 4">
            <VAutocomplete
              :items="getClassDivisions(fk_level)"
              v-model="fk_division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="id"
              :label="$t('division.name')"
              persistent-hint
              density="compact"
              clearable
            ></VAutocomplete>
          </VCol>
          <VCol cols="6" md="3" class="mt-2">
            <VBtn
              class="bg-primary"
              type="submit"
              @click="getItems()"
              size="small"
              :loading="loading"
            >
              <span class="text-white">
                {{ $t("globals.show") }}
              </span>
              <VIcon icon="mdi-presentation" color="white" end></VIcon>
            </VBtn>
          </VCol>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <DataTable
    :headers="headers"
    :items="student_list"
    :method="getItems"
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteStudent"
    :create="role == 4 ? addStudent : null"
    :del_perm="
      CheckGetPermission('legal_school.delete_studentsummer', ['summer_admin']) && role == 4
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_studentsummer', ['summer_admin']) && role == 4
    "
    :add_perm="
      CheckGetPermission('legal_school.add_studentsummer', ['summer_admin']) && role == 4
    "
    :exports="exportToExcel"
 

    
  >
    <template v-slot:item.student_image_url="{ item }">
      <v-dialog max-width="900" v-if="item.student_image_url">
        <template v-slot:activator="{ props: activatorProps }">
          <dir :title="$t('school.student_image')">
            <v-avatar v-bind="activatorProps" class="border">
              <v-img :src="`${base_url}/${item.student_image_url}`"></v-img>
            </v-avatar>
          </dir>
        </template>
        <template v-slot:default="{ isActive }">
          <v-card :title="$t('school.student_image')" :subtitle="item.name">
            <template v-slot:append>
              <dir :title="$t('globals.close')">
                <v-btn @click="isActive.value = false" icon size="small">
                  <v-icon color="error">mdi-close</v-icon>
                </v-btn>
              </dir>
            </template>
            <v-responsive class="mx-5 my-2 border-md border-dashed">
              <v-img
                :src="`${base_url}/${item.student_image_url}`"
                class="ma-1 rounded-lg"
              ></v-img>
            </v-responsive>
          </v-card>
        </template>
      </v-dialog>
    </template>
    <template v-slot:item.registration_form_url="{ item }">
      <v-dialog max-width="900" v-if="item.registration_form_url">
        <template v-slot:activator="{ props: activatorProps }">
          <dir :title="$t('school.student_registration_form')">
            <v-avatar v-bind="activatorProps" class="border">
              <v-img :src="`${base_url}/${item.registration_form_url}`"></v-img>
            </v-avatar>
          </dir>
        </template>
        <template v-slot:default="{ isActive }">
          <v-card :title="$t('school.student_registration_form')" :subtitle="item.name">
            <template v-slot:append>
              <dir :title="$t('globals.close')">
                <v-btn @click="isActive.value = false" icon size="small">
                  <v-icon color="error">mdi-close</v-icon>
                </v-btn>
              </dir>
            </template>
            <v-responsive class="mx-5 my-2 border-md border-dashed">
              <v-img
                :src="`${base_url}/${item.registration_form_url}`"
                class="ma-1 rounded-lg"
              ></v-img>
            </v-responsive>
          </v-card>
        </template>
      </v-dialog>
    </template>
  </DataTable>
  <alert-cascade-records
    v-model="alert_cascade"
    :loading="del_loading"
    :data_message="data_message"
    :title="alert_cascade_title"
    @confirm-delete="confirmDelete"
  >
  </alert-cascade-records>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
export default {
  data() {
    return {
      summer_schools: [],
      levels: [],
      exportProgress: false,
      force_delete: false,
      loading: false,
      del_loading: false,
      alert_cascade_title: "",
      alert_cascade: false,
      fk_governorate: null,
      fk_directorate: null,
      fk_summer: null,
      v$: useValidate(),
      student_list: [],
      fk_level: undefined,
      fk_division: undefined,
      del_dialog: false,
      student_del_id: undefined,
      pagination: {},
    };
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      await this.getlevels();
      await this.getDivisions();
      await this.getClassAssignHall();
      await this.getGovernorates();
      await this.getDirectorate();
      if (this.role == 2) {
        this.fk_governorate = parseInt(localStorage.getItem("governorate"));
      }
      if (this.role == 3) {
        this.fk_directorate = parseInt(localStorage.getItem("directorate"));
        await this.getSummerSchools();
      }
      if (this.role >= 0 && this.role <= 3) {
        this.headers.splice(2, 0, {
          title: this.$t("summer.address-summer"),
          key: "summer_address",
          sortable: false,
        });
        this.headers.splice(2, 0, {
          title: this.$t("summer.summer-camp"),
          key: "fk_summer",
        });
        this.selectedHead.push("fk_summer");
        this.selectedHead.push("summer_address");
      }

      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  computed: {
    ...mapState({
      divisions: (state) => state.summer.divisions,
      gender: (state) => state.summer_globals.gender,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      user: (state) => state.User,
      role: (state) => state.role,
    }),
    ...mapGetters({
      getGovernoratesByCountry: "school/getGovernoratesByCountry",
      getDirectorateByGovernorates: "school/getDirectorateByGovernorates",
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
      getClassDivisions: "summer/getClassDivisions",
    }),
    headers(){
      return [
        // بيانات الطالب
          // البييانات الشخصية 

          { title:this.$t('globals.personal-information') ,class:"children-style",children:[
            // اسم الطالب
            { title:this.$t('reportpublic.student-name'), key:'name'},
            { title:this.$t('libraries.lname'), key:'last_name'},
            { title:this.$t('school.student_gender'),key:'gender_label'},
            { title:this.$t('globals.habby'),key:'habby'},
            { title:this.$t('school.card_id'),key:'id_card'},
            { title:this.$t('school.academic_id'),key:'academic_id'},
            { title:this.$t('school.student_image'),key:'student_image_url'},
          ]},
          // محل الميلاد
            { title:this.$t('summer.employee.birth-place'),children:[
          // تاريخ الميلاد الميلادي
              { title:this.$t('school.student_birthdate'),key:'birthdate'},
          //  تاريخ الميلاد الهجري
              { title:this.$t('summer.calendar.hijri-date'),key:'birthdate_h'},
          // المحافظة
              { title:this.$t('globals.governorate'),key:'fk_directorate__fk_governorate__name_ar'},
          // المديرية
              { title:this.$t('globals.directorate'),key:'fk_directorate__name_ar'},
          // الحي 
              { title:this.$t('school.student_birth_place'),key:'birth_place'},
          // القرية / الحارة
              { title:this.$t('globals.address'),key:'address'},                    
          
        ]},

        // محل الميلاد
            { title:this.$t('globals.teacher-location'),show:false,children:[
          // رقم الهاتف
              { title:this.$t('globals.phone_number'),key:'phone_number'},
          // رقم الهاتف الثابت
              { title:this.$t('globals.home_number'),key:'home_number'},
          // المحافظة
              { title:this.$t('globals.governorate'),key:'directorate_housing__fk_governorate__name_ar'},
          // المديرية
              { title:this.$t('globals.directorate'),key:'directorate_housing__name_ar'},
          // الحي 
              { title:'الحي العزلة',key:'neighbourhood_housing'},
          // القرية / الحارة
              { title:'القرية / الحارة',key:'village_housing'},                    
          
        ]},

          
        
       
        // بيانات الاسرة والحالة الاجتماعية
            { title:this.$t('globals.family_and_marital_status_data'),show:false,children:[
                { title:this.$t('globals.num_male_family'),key:'num_male_family'},
                { title:this.$t('globals.num_fmale_family'),key:'num_fmale_family'},
                { title:this.$t('globals.student_tripp_between_brother'),key:'student_tripp_between_brother'},
                { title:this.$t('globals.student_live_with'),key:'student_live_with'},
                { title:this.$t('globals.type_of_ownership'),key:'type_of_ownership'},
                { title:this.$t('globals.type_of_housing'),key:'type_of_housing'},
                { title:this.$t('globals.minute_distance'),key:'minute_distance'},
                { title:this.$t('globals.meter_distance'),key:'meter_distance'}]         
            },
          // البيانات الدراسية
          { title:this.$t('globals.acdemic_data'), show:false,children:[      
            { title:this.$t('summer.level.level'),key:'fk_level'},
            { title:this.$t('division.select'),key:'fk_division'},
            { title:this.$t('globals.school-name'),key:'school_name'},
            { title:this.$t('globals.pervious_class'),key:'pervious_class_label'},
            { title:this.$t('exam.seat-number'),key:'sitting_number'},
            { title:this.$t('report.percentage'),key:'outcome'},
            { title:this.$t('globals.date_of_acquisition'),key:'date_of_acquisition'},
            { title:this.$t('school.students_type'),key:'student_type_label'},
            { title:this.$t('summer.accommodation-type'),key:'accommodation_type_label'},
            

          ]},
 
        // المشاركات السابقة
          { title:this.$t('globals.previous_posts'), show:false,children:[ 

            { title:this.$t('globals.summer_course'),key:'summer_course'},
            { title:this.$t('globals.cultural_course'),key:'cultural_course'},
            { title:this.$t('globals.scientific_abandonment'),key:'scientific_abandonment'},
            { title:this.$t('globals.other'),key:'other_item'},
    
          ]},
       
      //  الترشيح
       { title:this.$t('globals.official_recommendation_data'), show:false,children:[ 
            { title:this.$t('globals.official_recommendation'),key:'official_recommendation'},
            { title:this.$t('globals.recommendator'),key:'recommendator'},
            { title:this.$t('globals.recommendator_work'),key:'recommendator_work'},
            { title:this.$t('globals.phone_number'),key:'recommendator_phone_number'},
           
    
          ]},
 
  
        // الامراض
          { title:this.$t('globals.type_of_disease'), show:false,children:[
                  { title:this.$t('globals.disease'),key:'disease' ,width:500},
                  { title:this.$t('globals.rating'),key:'evalate' ,width:500},
            ]},
            // بيانات ولي الامر
 { title:this.$t('school.parent_info'),show:false,children:[      
              { title:this.$t('globals.parent_relation'),key:'parent_relation'},
              { title:this.$t('globals.address'),key:'fk_parent__address'},
              { title:this.$t('globals.phone_number'),key:'fk_parent__phone_number'},
              { title:this.$t('school.parent_job'),key:'fk_parent__job'},

          ]},
            { title:this.$t('user.username'),key:'fk_user' ,width:500},

           { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ]
    }
  },
  methods: {
    async getlevels() {
      let levels = await this.axios.get(this.base_url + `api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.levels = levels.data;
    },
    async confirmDelete() {
      this.force_delete = true;
      await this.deleteStudent();
      this.alert_cascade = false;
    },
    async exportToExcel() {
      this.exportProgress = true;
      var modified_student = [];
      await this.axios(`${this.base_url}api/summer/student/`, {
        params: {
          year: localStorage.getItem("current_year"),
          level: this.fk_level,
          division: this.fk_division,
          governorate: this.fk_governorate,
          directorate: this.fk_directorate,
          school_summer: this.fk_summer,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          modified_student = response.data?.results?.map((obj) => {
            return Object.fromEntries(
              Object.entries(obj).map(([key, value]) => [
                key,
                !value ? "-" : value,
              ])
            );
          });
        })
        .catch((err) => {
          this.exportProgress = false;
        });
      const headers = this.headers
        .filter(
          (el) =>
            ![
              "actions",
              "fk_user",
              "note",
              "student_image_url",
              "registration_form",
            ].includes(el.key)
        )
        .map((item) => {
          return { header: item.title, key: item.key };
        });
      await this.$ExportToExcel(headers, modified_student, "بيانات الطلاب")
        .then(() => {
          this.exportProgress = false;
        })
        .catch((err) => {
          this.exportProgress = false;
        });
    },
    async getSummerSchools() {
      await this.axios
        .get(`${this.base_url}api/summer/school-select-list`, {
          params: {
            directorate: this.fk_directorate,
            governorate: this.fk_governorate,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.summer_schools = response.data;
        });
    },
    ...mapActions({
      getDivisions: "summer/getDivisions",
      getClassAssignHall: "summer/getClassAssignHall",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    addStudent() {
      this.$router.push("add");
    },
    editItem(item) {
      this.$router.push(`${item.id}/edit/`);
    },
    async deleteStudent(delete_id) {
      this.del_loading = true;
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(
            `${this.base_url}api/summer/student/${delete_id}/`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            status = true;
          })
          .catch((error) => {
            status = error
          });
        return status;
      }
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      // await this.axios.get(`${this.base_url}api/summer/student/?year=${localStorage.getItem('current_year')}&&level=${this.fk_level}&&division=${this.fk_division}`,
      await this.axios
        .get(`${this.base_url}api/summer/student/`, {
          params: {
            level: this.fk_level,
            division: this.fk_division,
            governorate: this.fk_governorate,
            directorate: this.fk_directorate,
            school_summer: this.fk_summer,
            search: search,
            page: page,
            page_size: per_page,
            sort_by: ordering,
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.loading = false;
          this.student_list = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.totalItems = response.data.pagination.count;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
  },

  watch: {
    alert_cascade(val) {
      if (val == false) {
        this.force_delete = false;
        this.student_del_id = undefined;
        this.del_loading = false;
      }
    },
  },
};
</script>
 
