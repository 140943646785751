// import Dashboard from '../views/Examples/Dashboard'
import store from "@/store/index";
import SummerSchool from "@/views/Summer/summerSchool/SummerSchool.vue";
import SummerCamp from "@/views/Summer/summerSchool/SummerCamp.vue";
// import SamerCamp from "@/views/samer/SummerCamp.vue";
import AcademicYear from "@/views/Summer/year/AcademicYear.vue";
import Countries from "../views/Summer/country/Countries.vue";
import groupQuran from "../views/Summer/groupQuran/groupQuran.vue";
import linkStudentWithQuranGroup from "../views/Summer/linkStudentWithQuranGroup/linkStudentWithQuranGroup.vue";
import meetings from "../views/Summer/meetings/meetings.vue";
import visualSubjects from "../views/Summer/visualSubjects/visualSubjects.vue";
import recoredVisualSubjects from "../views/Summer/visualSubjects/recoredVisualSubjects.vue";
import systemSetting from "../views/Summer/systemSetting/systemSetting.vue";
import estimates from "../views/Summer/estimates/estimates.vue";
import semester from "../views/Summer/semester/semester.vue";
import months from "../views/Summer/months/months.vue";
import monthSetting from "../views/Summer/months/monthSetting.vue";
import semesterPlan from "../views/Summer/semesterPlan/semesterPlan.vue";
import document from "../views/Summer/document/document.vue";
import addDocument from "../views/Summer/document/addDocument.vue";
import typeOfDocument from "../views/Summer/document/typeOfDocument.vue";
import Governorate from "../views/Summer/governorate/Governorate.vue";
import Directorate from "../views/Summer/directorate/Directorate.vue";
import Initiative from "@/views/Summer/initiative/Initialization.vue";
import initiativeassigin from "@/views/Summer/initiative/initiativeassigin.vue";
import ActivityOpreations from "@/views/Summer/activity/ActivityOpreations.vue";
import dicultsandslotions from "@/views/Summer/activity/dicultsandslotions.vue";
import ActivityStudentAssigin from "@/views/Summer/activity/ActivityStudentAssigin.vue";
import SummerSchedule from "@/views/Summer/initiative/Schedule.vue";
import commitsSummer from "@/views/Summer/teacherView/commitsSummer.vue";
import SubjectInitialization from "@/views/Summer/subject/SubjectInitialization.vue";
import Terms from "@/views/Summer/globals/TermsMonths.vue";
import SupervisorInitialization from "@/views/Summer/supervisor/SupervisorInitialization.vue";
import ManagmentTask from "@/views/Summer/ManagmentTask/ManagmentTaskInitialization.vue";
import EvalatePersonResponsible from "@/views/Summer/EvalatePersonResponsible/EvalatePersonResponsible.vue";
import EducationProgram from "@/views/Summer/EducationProgram/EducationProgram.vue";
import EducationProgramOperation from "@/views/Summer/EducationProgram/EducationProgramOperation.vue"
import Division from "../views/Summer/division/Initialization.vue";
import Classperouid from "@/views/Summer/classes/Classperouid.vue";

// import skills from "../views/Summer/employee/employeeskills.vue";
import Level from "@/views/Summer/level/Initialization.vue";

// yousef


import EmployeeStatusManage from "../views/Summer/employee/‏‏EmployeeStatusManage.vue";
import employeeassigin from "../views/Summer/employee/employeeassigin.vue";
import Skills from "../views/Summer/employee/employeeskills.vue";
import Circulars from '@/views/Summer/Circulars/Circulars.vue'
import membercommitassigin from "../views/Summer/employee/membercommitassigin.vue";
import ShowMemberCommit from "../views/Summer/employee/ShowMemberCommit.vue";

import Dropout from "@/views/Summer/dropout/Initialization.vue";
import AddStudentMovable from "@/views/Summer/student/AddStudentMovable.vue";
import moveStudent from "@/views/Summer/student/moveStudent.vue";
import ShowLectures from "@/views/Summer/Lectures/ShowLectures.vue";
import LessonRecord from "@/views/Summer/Lectures/LessonRecord.vue";
import AddandEditAssignTeacher from "@/views/Summer/AssignTeacher/AddandEditAssignTeacher.vue";
import ShowTeachers from "@/views/Summer/AssignTeacher/ShowTeachers.vue";
import AssigningDivisionsToLevel from "@/views/Summer/level/AssigningDivisionsToLevel.vue";
import Visitor from "@/views/Summer/visitor/Visitor.vue";
import AddVisitor from "@/views/Summer/visitor/AddVisitor.vue";
import SubjectsStatics from "@/views/Summer/Statistic/SubjectsStatics.vue";

import ManagamentTasks from "@/views/Summer/Statistic/ManagamentTasks.vue";
import AddManagamentTasks from "@/views/Summer/Statistic/AddManagamentTasks.vue";
import Supplies from "@/views/Summer/Statistic/Supplies.vue";
import Recomendations from "@/views/Summer/recomendations/Recomendations.vue";

import TypeActivity from "@/views/Summer/activity/TypeActivity.vue";
import Showdicultsandslotions from "@/views/Summer/activity/Showdicultsandslotions.vue";
import WeeklyActivityAssign from "@/views/Summer/activity/WeeklyActivityAssign.vue";
import AddTalent from "@/views/Summer/talent/AddTalent.vue";

import Week from "@/views/Summer/calender/InitializationWeek.vue";
import summerdays from "@/views/Summer/calender/summerdays.vue";
import Vacation from "@/views/Summer/calender/InitializationVacation.vue";
import Calendar from "@/views/Summer/calender/InitializeCalendar.vue";
import Address from "@/views/Summer/address/Initialization.vue";
import StudentAttendance from "@/views/Summer/student/StudentAttendance.vue";
import StudentAttendances from "@/views/Summer/student/StudentAttendance.vue";
import StudentList from "@/views/Summer/student/StudentList.vue";
import StudentView from "@/views/Summer/student/StudentView.vue";
import Parent from "../views/Summer/parent/ParentView.vue";
import ParentList from "../views/Summer/parent/ParentList.vue";
import ReceiveCurriculum from "@/views/Summer/Curriculum/ReceiveCurriculum.vue";

import AddStudent from "@/views/Summer/student/AddStudent.vue";
import markMonthly from "@/views/Summer/student/markMonthly.vue";
import finalMark from "@/views/Summer/student/finalMark.vue";
import InitializationEmployee from "@/views/Summer/employee/InitializationEmployee.vue";
import EmployeeList from "@/views/Summer/employee/EmployeeList.vue";
import EmployeeAttendance from "@/views/Summer/employee/EmployeeAttendance.vue";
import Dashboard from "@/views/Examples/SummerDashboard";

import talentassigins from "../views/Summer/talent/talentassigin.vue";
// import store from "@/store/index";
import TimeTable from "@/views/Summer/WeeklySchedule/TimeTableAdd";
import TimeTableUpdate from "@/views/Summer/WeeklySchedule/TimeTableUpdate";
import BackUp from "../views/Summer/backup/BackUp";
// wae
import ActivityList from "@/views/Summer/activity/ActivityList.vue";
import MobileVersions from "@/views/Summer/phone/MobileVersions.vue"
import UploadApplications from "@/views/Summer/phone/UploadApplications.vue"
import HosingGrouping from "@/views/Summer/StudentHousingQuranCircles/HosingGrouping.vue"
import linkStudentWithHousingGroup from "@/views/Summer/StudentHousingQuranCircles/linkStudentWithHousingGroup.vue"
import destributedStudentsInBeds from "@/views/Summer/StudentHousingQuranCircles/destributedStudentsInBeds.vue"
import BedViews from "@/views/Summer/StudentHousingQuranCircles/BedViews.vue"
import RoomViews from "@/views/Summer/StudentHousingQuranCircles/RoomViews.vue"
import archiveFile from "@/views/Summer/archiveFile/archiveFiles.vue"
import RoomBed from "@/views/Summer/StudentHousingQuranCircles/RoomBed.vue"
import TypesOfPersonalAssessments from "@/views/Summer/StudentHousingQuranCircles/TypesOfPersonalAssessments.vue"
import PersonalEvaluations from "@/views/Summer/StudentHousingQuranCircles/PersonalEvaluations.vue"
import StudentEvaluations from "@/views/Summer/student/StudentEvaluations.vue"
import StudentVacations from "@/views/Summer/StudentHousingQuranCircles/StudentVacations.vue"
import PreparingStudent from "@/views/Summer/StudentHousingQuranCircles/PreparingStudent.vue"
import EmployeeAttendanceAtResid  from "@/views/Summer/StudentHousingQuranCircles/EmployeeAttendanceAtResidence.vue"


import StudentHoliday from "@/views/Summer/student/StudentHoliday.vue";

import ActivitiesOfficers  from "@/views/Summer/activity/ActivitiesOfficers.vue"
// import Settings from "../views/Globals/Settings.vue";
// const hasPermission = (prem) => {
//   store.dispatch("updatePermission", localStorage.getItem("userinfo"));
//   return store.getters.checkpermission(prem);
// };

function CheckSummerCenterSystem(to, from, next) {
  const summer_center = localStorage.getItem("summer_center");
  if (summer_center === "true") {
    next();
  } else {
    next({
      path: "/",
      query: { redirect: from.fullPath },
    });
  }
}
// const requirePermission = (perm) => {
//   return (to, from, next) => {
//     if (
//       hasPermission(perm) &&
//       localStorage.getItem("summer_center") == "true"
//     ) {
//       next();
//     } else {
//       next({
//         path: "/",
//         query: { redirect: from.fullPath },
//       });
//     }
//   };
// };
const hasPermission = (prem,role) => {
  return store.getters.checkpermission(prem,role);
};
const requirePermission = (perm,role) => {
  return (to, from, next) => {
      if (
      hasPermission(perm,role) 
      ){
      next();
      } else {
      next({
          path: "/",
          query: { redirect: from.fullPath },
      });
      }
  };
};
const requireRole = (role) => {
  return (to, from, next) => {
      if (store.getters.checkrole(role)){
      next();
      } else {
      next({
          path: "/",
          query: { redirect: from.fullPath },
      });
      }
  };
};

export default [
  {
    path: "home",
    name: "summer_dashboard",
    alias:'/',
    component: Dashboard,
    // beforeEnter: requirePermission(""),
  },
  {
    path: "summer-school",
    name: "summer-school",
    component: SummerSchool,
    beforeEnter: requirePermission("legal_school.view_schoolsummer",['sys_admin','gen_admin','dir_admin','gov_admin','summer_admin','user']),
  },
  {
    path: "summer-school/:id/edit",
    name: "summer-school-edit",
    component: SummerSchool,
    props: true,
    beforeEnter: requirePermission("legal_school.view_schoolsummer"),
  },
  {
    path: "summer-camps",
    name: "summer-camps-list",
    component: SummerCamp,
    beforeEnter: requirePermission("legal_school.view_schoolsummer"),
  },
  
  {
    path: "dicultsandslotions/add",
    name: "dicultsandslotions_add",
    component: dicultsandslotions,
    beforeEnter: requirePermission("legal_school.view_activitydifficulty",['sys_admin','summer_admin','user']),
  },
  {
    path: "activity-student-assigin",
    name: "ActivityStudentAssigin",
    component: ActivityStudentAssigin,
    beforeEnter: requirePermission("legal_school.view_activitysummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "dicultsandslotions/:id/edit",
    name: "dicultsandslotions_edit",
    component: dicultsandslotions,
    props: true,
    beforeEnter: requirePermission("legal_school.view_activitydifficulty",['sys_admin','summer_admin','user']),
  },
  {
    path: "dicultsandslotions/list",
    name: "dicultsandslotions_list",
    component: Showdicultsandslotions,
    beforeEnter: requirePermission("legal_school.view_activitydifficulty",['sys_admin','summer_admin','user']),
  },
  {
    path: "Activity-Opreations",
    name: "Activity-Opreations",
    component: ActivityOpreations,
    beforeEnter: requirePermission("legal_school.view_activityoperation",['sys_admin','summer_admin','user']),
  },
  {
    path: "summer-schedule",
    name: "summer-schedule",
    component: SummerSchedule,
    beforeEnter: requirePermission("legal_school.view_schedule",['sys_admin','summer_admin','gen_admin']),
  },

  {
    path: "summer-academic-year",
    name: "summer-academic-year",
    component: AcademicYear,
    beforeEnter: requirePermission("globaltable.view_yearofstudy"),
  },
  {
    path: "countries",
    name: "summer-countries",
    component: Countries,
    beforeEnter: requirePermission("globaltable.view_country"),
  },
  {
    path: "governorate",
    name: "summer-governorate",
    component: Governorate,
    beforeEnter: requirePermission("globaltable.view_governorate"),
  },
  {
    path: "meetings",
    name: "meetings",
    component: meetings,
    // beforeEnter: requirePermission("globaltable.view_meetings"),
  },
  {
    path: "groupQuran",
    name: "groupQuran",
    component: groupQuran,
    // beforeEnter: requirePermission("globaltable.view_groupQuran"),
  },
  {
    path: "linkStudentWithQuranGroup",
    name: "linkStudentWithQuranGroup",
    component: linkStudentWithQuranGroup,
    // beforeEnter: requirePermission("globaltable.view_groupQuran"),
  },
  {
    path: "systemSetting",
    name: "systemSetting",
    component: systemSetting,
    // beforeEnter: requirePermission("globaltable.view_systemSetting"),
  },
  {
    path: "estimates",
    name: "estimates",
    component: estimates,
    // beforeEnter: requirePermission("globaltable.view_semester"),
  },
  {
    path: "semester",
    name: "semester",
    component: semester,
    // beforeEnter: requirePermission("globaltable.view_semester"),
  },
  {
    path: "months",
    name: "months",
    component: months,
    // beforeEnter: requirePermission("globaltable.view_semester"),
  },
  {
    path: "month-setting",
    name: "month-setting",
    component: monthSetting,
    // beforeEnter: requirePermission("globaltable.view_semester"),
  },
  {
    path: "semesterPlan",
    name: "semesterPlan",
    component: semesterPlan,
    // beforeEnter: requirePermission("globaltable.view_semester"),
  },
  {
    path: "typeOfDocument",
    name: "typeOfDocument",
    component: typeOfDocument,
    // beforeEnter: requirePermission("globaltable.view_leveldocument"),
  },
  {
    path: "document",
    name: "document",
    component: document,
    // beforeEnter: requirePermission("globaltable.view_leveldocument"),
  },
  {
    path: "addDocument",
    name: "addDocument",
    component: addDocument,
    // beforeEnter: requirePermission("globaltable.view_document"),
  },
  {
    path: "visualSubjects",
    name: "visualSubjects",
    component: visualSubjects,
    // beforeEnter: requirePermission("globaltable.view_visualSubjects"),
  },
  {
    path: "recoredVisualSubjects",
    name: "recoredVisualSubjects",
    component: recoredVisualSubjects,
    // beforeEnter: requirePermission("globaltable.view_visualSubjects"),
  },
  {
    path: "directorate",
    name: "summer-directorate",
    component: Directorate,
    beforeEnter: requirePermission("globaltable.view_directorate"),
  },
  
  {
    path: "commits-summer",
    name: "commits-summer",
    component: commitsSummer,
    beforeEnter: requirePermission("legal_school.view_commitee"),
  },

  {
    path: "initiative",
    name: "summer_initiative",
    component: Initiative,
    beforeEnter: requirePermission("legal_school.view_initiative",['sys_admin','summer_admin','user']),
  },


  {
    path: "initiativeassigin",
    name: "initiativeassigin",
    component: initiativeassigin,
    beforeEnter: requirePermission("legal_school.view_initiative",['sys_admin','summer_admin','user']),
  },

  {
    path: "initialization-subjects",
    name: "initialization-subjects",
    component: SubjectInitialization,
    beforeEnter: requirePermission("legal_school.view_subjectsummer"),
  },
  {
    path: "terms",
    name: "Terms",
    component: Terms,
    beforeEnter: requirePermission("legal_school.view_semester"),
  },
  {
    path: "initialization-supervisor",
    name: "initialization-supervisor",
    component: SupervisorInitialization,
    beforeEnter: requirePermission("legal_school.view_supervisor"),
  },
  {
    path: "initialization-managmenttask",
    name: "initialization-managmenttask",
    component: ManagmentTask,
    beforeEnter: requirePermission("legal_school.view_supervisor"),
  },
  {
    path: "evalate-person-responsible",
    name: "evalate-person-responsible",
    component: EvalatePersonResponsible,
    beforeEnter: requirePermission("legal_school.view_evalatepersonresponsible"),
  },
  {
    path: "education-program-data",
    name: "education_program_data",
    component: EducationProgram,
    beforeEnter: requirePermission("legal_school.view_educationprogram"),
  },
  {
    path: "education-program-operation",
    name: "education_program_operation",
    component: EducationProgramOperation,
    beforeEnter: requirePermission("legal_school.view_educationprogramoperation"),
  },
  {
    path: "initialization-divisions",
    name: "initialization-divisions",
    component: Division,
    beforeEnter: requirePermission("legal_school.view_divisionsummer"),
  },
  {
    path: "skills",
    name: "skills",
    component: Skills,
    beforeEnter: requirePermission("legal_school.view_skill"),
  },
  {
    path: "circulars",
    name: "circulars",
    component: Circulars,
    beforeEnter: requirePermission("legal_school.view_generalization"),
  },
  {
    path: "SubjectsStatics",
    name: "SubjectsStatics",
    component: SubjectsStatics,

    beforeEnter: requirePermission("legal_school.view_approach",['sys_admin','summer_admin','user']),
  },

  {
    path: "membercommit/add",
    name: "membercommit_add",
    component: membercommitassigin,
    beforeEnter: requirePermission("legal_school.view_commitee",['sys_admin','summer_admin','user']),
  },
  {
    path: "membercommit/:id/edit",
    name: "membercommit_edit",
    component: membercommitassigin,
    beforeEnter: requirePermission("legal_school.change_commite",['sys_admin','summer_admin','user']),
    props: true,
    // beforeEnter: requirePermission("legal_school.view_lecturesummer"),
  },
  {
    path: "membercommit/list",
    name: "membercommit_list",
    component: ShowMemberCommit,
    beforeEnter: requirePermission("legal_school.view_commitee",['sys_admin','summer_admin','user']),
  },

  {
    path: "level",
    name: "level",
    component: Level,
    beforeEnter: requirePermission("legal_school.view_level"),
  },
 
  {
    path: "lecture/list",
    name: "lecture_list",
    component: ShowLectures,
    beforeEnter: requirePermission("legal_school.view_lecturesummer"),
  },
  {
    path: "lesson-record",
    name: "lesson-record",
    component: LessonRecord,
    beforeEnter: requirePermission("legal_school.view_lecturelog",['sys_admin','summer_admin','user']),
  },
  {
    path: "assign-teacher/add",
    name: "assign-teacher_add",
    component: AddandEditAssignTeacher,
    beforeEnter: requirePermission("legal_school.view_teachersubjectsummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "assign-teacher/:id/edit",
    name: "assign-teacher_edit",
    component: AddandEditAssignTeacher,
    beforeEnter: requirePermission("legal_school.change_teachersubjectsummer",['sys_admin','summer_admin','user']),
    props: true,
    beforeEnter: requirePermission("legal_school.view_teachersubjectsummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "assign-teacher/list",
    name: "assign-teacher_list",
    component: ShowTeachers,
    beforeEnter: requirePermission("legal_school.view_employeelevel",['summer_admin']),
  },
  {
    path: "dropout",
    name: "dropout",
    component: Dropout,
    beforeEnter: requirePermission("legal_school.view_levelstudentyear",['sys_admin','summer_admin','user']),
  },
  {
    path: "add-student-movable",
    name: "add-student-movable",
    component: AddStudentMovable,
    beforeEnter: requirePermission("legal_school.view_studentsummer",['sys_admin','gen_admin']),
  },
  {
    path: "moveStudent",
    name: "moveStudent",
    component: moveStudent,
    beforeEnter: requirePermission("legal_school.view_studentsummer",['sys_admin','summer_admin']),
  },

  {
    path: "talent",
    name: "talent",
    component: AddTalent,
    beforeEnter: requirePermission("legal_school.view_talent"),
  },
  {
    path: "assigning-divisions-to-level",
    name: "assigning-divisions-to-level",
    component: AssigningDivisionsToLevel,
    beforeEnter: requirePermission("legal_school.view_leveldivision",['sys_admin','summer_admin','user']),
  },

  // wael

 
  {
    path: "activity-list",
    name: "activity_list",
    component: ActivityList,
    beforeEnter: requirePermission("legal_school.view_activitysummer",['sys_admin','gen_admin','summer_admin','user']),
  },
  {
    path: "type-activity",
    name: "type-activity",
    component: TypeActivity,
    beforeEnter: requirePermission("legal_school.view_activitytype"),
  },

  {
    path: "week",
    name: "week",
    component: Week,
    beforeEnter: requirePermission("legal_school.view_week"),
  },
  {
    path: "summerdays",
    name: "summerdays",
    component: summerdays,
    beforeEnter: requirePermission("legal_school.view_daysummer"),
  },

  {
    path: "vacation",
    name: "vacation",
    component: Vacation,
    beforeEnter: requirePermission("legal_school.view_vacation"),
  },
  {
    path: "calendar",
    name: "summer-calendar",
    component: Calendar,
    beforeEnter: requirePermission("legal_school.view_calendar"),
  },
  {
    path: "address",
    name: "address",
    component: Address,
    beforeEnter: requirePermission("legal_school.view_schoolsummer"),
  },
  {
    path: "classperouid",
    name: "classperouid",
    component: Classperouid,
    beforeEnter: requirePermission("legal_school.view_schedulesummer"),
  },
  {
    path: "student-attendance",
    name: "student-attendance-view",
    component: StudentAttendance,
    beforeEnter: requirePermission(
      "legal_school.view_studentattendancesummer",['sys_admin','summer_admin','user']
    ),
  },

  {
    path: "student-attendances",
    name: "student-attendance-views",
    component: StudentAttendances,
    beforeEnter: requirePermission(
      "legal_school.view_studentattendancesummer",['sys_admin','summer_admin','user']
    ),
  },


  {
    path: "student-holiday",
    name: "student-holiday-view",
    component: StudentHoliday,
    beforeEnter: requirePermission(
      "legal_school.view_holiday",['sys_admin','summer_admin','user']
    ),
  },
  {
    path: "student/add",
    name: "summer-student-add",
    component: StudentView,
    beforeEnter: requirePermission("legal_school.view_studentsummer",['summer_admin']),
  },
  {
    path: "student/:id/edit",
    name: "summer-student-edit",
    component: StudentView,
    props: true,
    beforeEnter: requirePermission("legal_school.change_studentsummer",['summer_admin']),
  },
  {
    path: "receive-curriculum",
    name: "receive-curriculum",
    component: ReceiveCurriculum,
    beforeEnter: requirePermission("legal_school.change_employeelevel",['sys_admin','summer_admin','user']),
  },
  {
    path: "student/list",
    name: "summer-student-list",
    component: StudentList,
    beforeEnter: requirePermission("legal_school.view_studentsummer"),
  },
  {
    path: "parent",
    name: "parent",
    component: Parent,
    beforeEnter: requirePermission("legal_school.view_parentsummer",['sys_admin','summer_admin','user']),
    props: true,
  },
  {
    path: "visitor/list",
    name: "visitor",
    component: Visitor,
    beforeEnter: requirePermission("legal_school.view_visitor",['sys_admin','summer_admin','user']),
  },
  {
    path: "visitor/add",
    name: "add-visitor",
    component: AddVisitor,
    beforeEnter: requirePermission("legal_school.view_visitor",['sys_admin','summer_admin','user']),
  },
  {
    path: "visitor/:id/edit",
    name: "visitor_edit",
    component: AddVisitor,
    beforeEnter: requirePermission("legal_school.change_visitor",['sys_admin','summer_admin','user']),
    props: true,
    beforeEnter: requirePermission("legal_school.view_visitor",['sys_admin','summer_admin','user']),
  },
  {
    path: "parent/:id/edit",
    name: "summer-parent",
    component: Parent,
    beforeEnter: requirePermission("legal_school.change_parentsummer",['sys_admin','summer_admin','user']),
    props: true,
  },
  {
    path: "parents-list",
    name: "parents-list",
    component: ParentList,
    beforeEnter: requirePermission("legal_school.view_parentsummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "employeeassigin",
    name: "employeeassigin",
    component: employeeassigin,
    beforeEnter: requirePermission("legal_school.view_employeelevel",['summer_admin']),
  },
  {
    path: "EmployeeStatusManage",
    name: "EmployeeStatusManage",
    component: EmployeeStatusManage,
    beforeEnter: requirePermission("legal_school.view_employee",['sys_admin','summer_admin','gen_admin']),
  },

  
  {
    path: "add-student",
    name: "add-student",
    component: AddStudent,
    beforeEnter: requirePermission("legal_school.view_studentsummer"),
  },
  {
    path: "mark-monthly",
    name: "markMonthly",
    component: markMonthly,
    beforeEnter: requirePermission("legal_school.view_studentsummer"),
  },
  {
    path: "finalMark",
    name: "finalMark",
    component: finalMark,
    beforeEnter: requirePermission("legal_school.view_studentsummer"),
  },
  {
    path: "add-employee",
    name: "add-employee",
    component: InitializationEmployee,
    beforeEnter: requirePermission("legal_school.view_employee",['sys_admin', 'gen_admin',]),
  },
  {
    path: "edit-employee/:id",
    name: "edit-employee",
    component: InitializationEmployee,
    props: true,
    beforeEnter: requirePermission("legal_school.change_employee",['sys_admin', 'gen_admin',]),
  },
  {
    path: "employees",
    name: "employees",
    component: EmployeeList,
    beforeEnter: requirePermission("legal_school.view_employee"),
  },
  {
    path: "employee-attendance",
    name: "employee-attendance",
    component: EmployeeAttendance,
    beforeEnter: requirePermission("legal_school.view_employeeattendance",['sys_admin','summer_admin','user']),
  },
  {
    path: "talentassigins",
    name: "talentassigins",
    component: talentassigins,
    beforeEnter: requirePermission("legal_school.view_studentsummer",['sys_admin','summer_admin','user']),
  },
  {
    path: "supplies/list",
    name: "supplies",
    component: Supplies,
    beforeEnter: requirePermission("legal_school.view_supplies",['sys_admin','summer_admin','user']),
  },
  {
    path: "managament-tasks/list",
    name: "managament_tasks",
    component: ManagamentTasks,
    beforeEnter: requirePermission("legal_school.view_managmenttask",['sys_admin','summer_admin','user']),
  },
  {
    path: "managament-tasks/add",
    name: "add-managament-tasks",
    component: AddManagamentTasks,
    beforeEnter: requirePermission("legal_school.view_managmenttask",['summer_admin']),
  },
  {
    path: "managament-tasks/:id/edit",
    name: "managament-tasks_edit",
    component: AddManagamentTasks,
    beforeEnter: requirePermission("legal_school.change_managmenttask",['summer_admin']),
    props: true,
  },
  {
    path: "positives-negatives",
    name: "recomendations",
    component: Recomendations,
    beforeEnter: requirePermission("legal_school.view_recommendation",['sys_admin','summer_admin','user']),
  },
  {
    path: "time-table-add",
    name: "summer_time_table_add",
    component: TimeTable,
    beforeEnter: requirePermission("legal_school.view_schoolweeklyschedulesummer",['sys_admin','summer_admin','gen_admin']),
  },
  // {
  //   path: "time-table-update",
  //   name: "summer_time_table_update",
  //   component: TimeTableUpdate,
  //   beforeEnter: requirePermission("legal_school.view_schoolweeklyschedulesummer"),
  // },
  {
    path: "activity-weekly-assign",
    name: "WeeklyActivityAssign",
    component: WeeklyActivityAssign,
    beforeEnter: requirePermission("legal_school.view_schoolweeklyactivity",['sys_admin','summer_admin','gen_admin']),
  },
  // {
  //   path: "summer-settings",
  //   name: "summer-settings",
  //   component: Settings,
  //   beforeEnter: requirePermission("globaltable.change_settingsystem"),
  // },


  {
    path: "mobile-versions",
    name: "mobile_versions",
    component: MobileVersions,
    beforeEnter: requirePermission('legal_school.view_mobileversion', ['sys_admin', 'gen_admin']) ,
    
  },
  {
    path: "upload-apps",
    name: "upload_applications",
    component: UploadApplications,
    beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  ////////////////////////////////////////////////-----------السكن الطلابي---------------////////////////////////////////////
  {
    path: "hosing-grouping",
    name: "hosing_grouping",
    component: HosingGrouping,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  ////////////////////////////////////////////////-----------ربط الطلاب بالمجموعات السكنية---------------////////////////////////////////////
  {
    path: "linkStudentWithHousingGroup",
    name: "linkStudentWithHousingGroup",
    component: linkStudentWithHousingGroup,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  ////////////////////////////////////////////////-----------ربط الطلاب بالاسرة---------------////////////////////////////////////
  {
    path: "destributedStudentsInBeds",
    name: "destributedStudentsInBeds",
    component: destributedStudentsInBeds,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  {
    path: "bed-views",
    name: "bed_views",
    component: BedViews,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  {
    path: "room-views",
    name: "room_views",
    component: RoomViews,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  {
    path: "archiveFile",
    name: "archiveFile",
    component: archiveFile,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  {
    path: "room-bed",
    name: "room_bed",
    component: RoomBed,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  {
    path: "types-of-personal-assessments",
    name: "types_of_personal_assessments",
    component: TypesOfPersonalAssessments,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  {
    path: "personal-evaluations",
    name: "personal_evaluations",
    component: PersonalEvaluations,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  {
    path: "student-evaluations",
    name: "student_evaluations",
    component: StudentEvaluations,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  {
    path: "student-vacations",
    name: "student_vacations",
    component: StudentVacations,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  {
    path: "preparing-student",
    name: "preparing_student",
    component: PreparingStudent,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  {
    path: "employee-attendance",
    name: "employee_attendance",
    component: EmployeeAttendanceAtResid,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  {
    path: "activities-officers",
    name: "activities_officers",
    component: ActivitiesOfficers,
    // beforeEnter: requirePermission('legal_school.view_app', ['sys_admin', 'gen_admin']) ,
  },
  
];
  