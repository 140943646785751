<template>
  <!-- <v-container> -->
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("reportpublic.PrintedCurricula") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title class="pa-0">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable()"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{
                $t("reportpublic.ATotal") +
                " " +
                $t("reportpublic.PrintedCurricula")
              }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ bookCharts.data.total }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-container>
        <v-row
          class="py-2 justify-center d-flex align-center ma-0"
          style="row-gap: 2rem"
        >
          <div class="" v-for="(book, key) in bookCharts.data.books" :key="key">        
            <v-card
              :class="`mb-3 mx-2 ${
                this.$i18n.locale == 'ar' ? 'mr-3' : 'ml-3'
              }`"
              elevation="7"
              height="110"
              width="80"
              color="primary-grey-lighten-4"
              rounded
              link
            >
               <v-img
                height="200px"
                cover
                :src="checkIfFileExists(key)?require(`@/assets/img/books_images/book-${key + 1}.jpg`):''"                
              >
              </v-img>
            </v-card>
            <v-sheet
              class="text-center text-white"
              :color="key % 2 != 0 ? '#11698e' : '#0b2663'"
              height="50"
              width="100"
              :rounded="
                key == 0
                  ? this.$i18n.locale == 'ar'
                    ? 'e'
                    : 's'
                  : key == bookCharts.data.books.length - 1
                  ? this.$i18n.locale == 'ar'
                    ? 's'
                    : 'e'
                  : false
              "
            >
              <v-icon
                icon="mdi-triangle"
                style="transform: rotate(180deg); margin-top: -8px"
              >
              </v-icon>

              <span>
                <h6 class="pa-0 ma-0">{{ book.level_name_ar }}</h6>
                {{ book.received_books.toLocaleString() }}
              </span>
            </v-sheet>
            <!-- </div> -->
          </div>
        </v-row>
      </v-container>
    </v-card-item>
  </v-card>
  <!-- </v-container> -->
</template>

<script>
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { required } from '@vuelidate/validators';

export default {
  data() {
    return {
      bookCharts: {
        data: {
          books: [],
          total: null,
        },
      },
    };
  },
  methods: {
    fetchData() {
      axios
        .get(`${this.base_url}public-reports/summer-curricula`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.bookCharts.data.books = response.data.results;
          this.bookCharts.data.books.forEach((item) => {
          this.bookCharts.data.total += item.received_books;
          });
        })
        .catch((error) => {
          console.error("خطأ في السيرفر", error);
        });
    },

    async printTable(all) {
      window.print();
    },

    checkIfFileExists(key){
      const filename = `@/assets/img/books_images/book-${key + 1}.jpg`;
      try{
        
        require(`@/assets/img/books_images/book-${key + 1}.jpg`)
        return true
      }catch(error){
        return false
      }
    }
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },

  components: {
    ReportHeader,
  },
};
</script>
