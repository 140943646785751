<template>
  <v-table
    density="compact"
    class="rounded elevation-1 striped-table"
    fixed-header
  >
    <thead>
      <tr>
        <th class="text-center border">{{ $t("semester.month") }}</th>
        <th class="border">{{ $t("semester.month-close") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="month in semestersPlans" :key="month.id">
        <td class="text-center border w-25">{{ month.month_name }}</td>
        <td class="border">
          <!-- <v-row dense> -->
            <v-col cols="2">
              <VCheckbox
                :value="semestersPlan.is_open"
                v-model="month.is_open"
                hide-details
              >
              </VCheckbox>
            </v-col>
          <!-- </v-row> -->
        </td>
      </tr>
    </tbody>
    <VBtnSave @click="saveItem()" :loading="saveDataLoading" class="ma-2">
      {{ $t("globals.save") }}
    </VBtnSave>
  </v-table>
</template>
<script>
import { mapState } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      protected_records: false,
      data_message: [],
      semestersPlan: {},
      semestersPlans: [],
      saveDataLoading: false,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      updated_id: undefined,
      delete_id: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/setting-month-lock/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.semestersPlans = response.data;
        this.loading = false;
      });
    },
    async saveItem() {
          this.saveDataLoading = true;
          await this.axios
            .post(this.base_url + "api/setting-month-lock/", 
            {
              months_to_lock:this.semestersPlans,
            }, {
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            })
            .then(() => {
              this.$emit("successAlert", this.$t("globals.data_added"));
              this.saveDataLoading = false;
            })
            .catch((error) => {
              if (error.response.data[0]) {
                this.$emit("warningAlert", this.$t("summer.already-releated"));
              } else {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
              }
              this.saveDataLoading = false;
            });
          this.getItems();
    },
  },
};
</script>
<style></style>
