<template>
  <!-- filter data  -->
  <v-card  v-if="!exist">
    <v-card-text>

      <h3 class="text-grey-darken-2 ">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        <v-col cols="12" md="4" sm="6" class="mb-3">
          <v-select
            color="primary"
            v-model="filter_data.fk_level"
            density="compact"
            item-title="level_with_phase_name"
            item-value="id"
            prepend-inner-icon="mdi-stairs-box"
            :items="levels"
            :label="$t('summer.level.name_ar')"
            @update:modelValue="
              getstudentbytalentsss(), (this.filter_data.fk_division = null)
            "
            hide-details
            clearable
          />
        </v-col>

        <v-col cols="12" md="4" sm="6" class="mb-3">
          <v-select
            color="primary"
            v-model="filter_data.fk_division"
            density="compact"
            item-title="name"
            item-value="fk_division"
            prepend-inner-icon="mdi-shape-outline"
            :items="divisions"
            :label="$t('division.name')"
            hide-details
            clearable
          />
        </v-col>

        <v-col cols="6" sm="1" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="primary"
            :loading="loading"
            @click="checkFilterData"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">{{ $t("report.droupout_resons_report") }}</div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="mb-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-start" style="flex: 1">
          <v-menu :close-on-content-click="false">
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header"
                  :label="header.title"
                  :value="header.key"
                  color="primary"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            density="compact"
            @click="(exist = false), v$.filter_data.$reset()"
          >
            <span class="text-primary">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <div
        v-if="items.length > 0"
        class=" text-grey-darken-2   rounded border pa-2 mb-2"
      >
          <h3 class="fontsize text-grey-darken-2 mb-2">
            <span v-if="this.filter_data.fk_level">
              {{ $t("summer.level.name_ar") }} :
              {{ getLevelName(this.filter_data.fk_level) }}
            </span>

            <br />

            <span v-if="this.filter_data.fk_division">
              {{ $t("division.name") }} :
              {{ getDivisionName(this.filter_data.fk_division) }}
            </span>
          </h3>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading" v-if="items.length > 0" rounded="0" flat>
          <table class="report-t">
            <thead >
              <th
                v-for="header in items"
                :key="header.key"
                
              >
                {{ header.reason }}
              </th>
            </thead>
            <tbody>
           
                <tr >
                  <td v-for="item in items" :key="item" class="text-center">
                    {{ item.count }}
                  </td>
               
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import useValidate from "@vuelidate/core";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));

      await this.getCommittee();

      await this.getLevels();

      await this.getYears();

      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      alllevelDivisions: [],
      divisions: [],
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },

      school: [],
      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",

      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      class_assign_hall: (state) => state.school.class_assign_hall,

      semesters: (state) => state.school.semesters,
      subjects: (state) => state.school.subjects,
      user: (state) => state.User,
      committee: (state) => state.summer.committee,
      levels: (state) => state.summer.levels,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getClasses: "school/getClasses",

      getSemesters: "school/getSemesters",
      getClassAssignHall: "school/getClassAssignHall",
      getSubjects: "school/getSubjects",
      getCommittee: "summer/getCommittee",
      getLevels: "summer/getLevels",
    }),

    async getstudentbytalentsss() {
      if (this.filter_data.fk_level) {
        let alllevelDivisions = await this.axios.get(
          this.base_url + `api/summer/get-level-division/${this.filter_data.fk_level}`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );

        this.divisions = alllevelDivisions.data.division;
      }
    },
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },

    getLevelName(driver_id) {
      if (driver_id) {
        const level = this.levels.find((level) => level.id === level.id);
        return level.level_with_phase_name;
      }
      return "===========";
    },
    getDivisionName(driver_id) {
      if (driver_id) {
        const division = this.divisions.find((division) => division.id === division.id);
        return division.name;
      }
      return "===========";
    },

    checkFilterData() {
      this.v$.filter_data.$validate();
      if (!this.v$.filter_data.$error) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/dropping-out-reasons`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            level: this.filter_data.fk_level,
            division: this.filter_data.fk_division,
          },
        })
        .then((response) => {
          this.exist = true;
          if (response.data) {
            this.items = response.data;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
    },
  },
  validations() {
    return {
      filter_data: {},
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
