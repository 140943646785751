<template>
  <v-col v-for="(field, index) in fields" :key="index">      
    <slot :field="field">
      <template v-if="field.type == 'IntegerField' && field.choices">
        <v-select
          v-if="cond(field.name)"
          v-model="data[field.name]"
          :label="$t(field.title)"
          persistent-hint
          item-title="name"
          item-value="id"
          clearable
          density="compact"
          :items="field.choices"
          :rules="[...getRules(field)]"
          @update:model-value="fun(field.name)"
        />
      </template>
      <template v-if="field.type == 'CharField'">
        <v-sheet v-if="field.name == 'date' && cond(field.name)">
          <DatetimePicker
            v-model="data[field.name]"
            :label="$t(field.title)"
            @update:model-value="fun(field.name)"
          />
        </v-sheet>
        <v-text-field
          v-else-if="field.name != 'date' && cond(field.name)"
          v-model="data[field.name]"
          :label="$t(field.title)"
          :rules="[...getRules(field)]"
          @update:model-value="fun(field.name)"
        />
      </template>
      <template v-if="field.type == 'IntegerField' && !field.choices">
        <v-text-field
          v-if="cond(field.name)"
          v-model="data[field.name]"
          :label="$t(field.title)"
          :rules="[...getRules(field),$max_value(999),$min_value(1)]"
          type="number"
          @update:model-value="fun(field.name)"
        />
      </template>
      <template v-if="field.type == 'TextField'">
        <v-textarea
          v-if="cond(field.name)"
          v-model="data[field.name]"
          :label="$t(field.title)"
          :rules="[...getRules(field)]"
          @update:model-value="fun(field.name)"
        />
      </template>
      <template v-if="field.type == 'BooleanField'">
        <v-checkbox-btn
          v-if="cond(field.name) && !isRadioButton(field.name)"
          v-model="data[field.name]"
          :label="$t(field.title)"
          :rules="[...getRules(field)]"
          @update:model-value="fun(field.name)"
          class="mb-3"          
        />
        <VRadioGroup
          v-if="cond(field.name) && isRadioButton(field.name)"
          v-model="data[field.name]"
          :label="$t(field.title)"
          :rules="[...getRules(field)]"
          @update:model-value="fun(field.name)"
          :value="true"
          inline
        >
          <VRadio :label="$t('globals.yes')" :value="true" />
          <VRadio :label="$t('globals.no')" :value="false" />
        </VRadioGroup>
      </template>
    </slot>
  </v-col>
</template>
<script>
export default {
  props: {
    object: {
      type: Object,
      default() {
        return {};
      },
    },
    data: {},
    fields: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      
    };
  },
  async created() {
    
  },
  methods: {
    isRadioButton(item) {
      if (item in this.object) {
        if ("radio" in this.object[item]) return true;
        return false;
      }
    },
    cond(item) {
      if (item in this.object)
        if ("cond" in this.object[item]) return this.object[item]["cond"];

      return true;
    },
    fun(item) {
      if (item in this.object) {
        if ("fun" in this.object[item]) {
          return this.object[item]["fun"]();
        }
      }
      return true;
    },
    getRules(data) {
      var rules = [];
      if (!data.null) rules.push(this.$required);
      if (data.max_length) rules.push(this.$max_length(data.max_length));
      return rules;
    },

    async getFields() {
      await this.axios(`${this.base_url}${this.url}`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.fields = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  computed: {
    value: {
      get() {
        return this.data;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
};
</script>