<template>
  <DataTable
    v-if="CheckGetPermission('globaltable.view_governorate')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      CheckGetPermission('globaltable.delete_governorate', ['sys_admin', 'summer_admin'])
    "
    :edit_perm="
      CheckGetPermission('globaltable.change_governorate', ['sys_admin', 'summer_admin'])
    "
    :add_perm="
      CheckGetPermission('globaltable.add_governorate', ['sys_admin', 'summer_admin'])
    "
  >
  </DataTable>
  <v-dialog v-model="addDialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="is_update ? updateItem() : saveItem()">
          <VRow class="mt-2">
            <VCol cols="6">
              <VTextField
                v-model="meeting.item"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('summer.name-visitor')"
                density="compact"
                :placeholder="$t('semester.enter_visitor')"
                :rules="[$required,$max_length(50)]"
              >
              </VTextField>
            </VCol>
            <VCol class="pt-0 px-3" cols="6">
              <VCardItem class="pa-0">
                <v-select
                  v-model="meeting.type"
                  :label="$t('school.type')"
                  persistent-hint
                  item-title="name"
                  item-value="id"
                  clearable
                  density="compact"
                  :items="typesMetting"
                  prepend-inner-icon="mdi-account"
                  counter="250"
                  rows="2"
                  :rules="[$required]"
                ></v-select>
              </VCardItem>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="meeting.discussion_points"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('semester.discussion_points')"
                density="compact"
                :placeholder="$t('semester.enter_point')"
                counter="500"
                rows="3"
                :rules="[$required,$max_length(500)]"
              >
              </VTextarea>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="meeting.output"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('school.output')"
                density="compact"
                counter="500"
                :placeholder="$t('semester.enter_result')"
                rows="3"
                :rules="[$required,$max_length(500)]"
              >
              </VTextarea>
            </VCol>
            <VCol cols="6">
              <VTextField
                v-model="meeting.reason"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('school.reason')"
                density="compact"
                :placeholder="$t('semester.enter_reason')"
                counter="100"
                rows="2"
                :rules="[$required,$max_length(100)]"
              >
              </VTextField>
            </VCol>
            <VCol class="pt-0 px-3" cols="6">
              <VCardItem class="pa-0">
                <v-select
                  v-model="meeting.fk_month_semester"
                  :label="$t('semester.months')"
                  persistent-hint
                  item-title="month_name"
                  item-value="id"
                  clearable
                  density="compact"
                  :items="monthsSemester"
                  prepend-inner-icon="mdi-account"
                  :rules="[$required]"
                  @update:model-value="getCurrentMonth"
                ></v-select>
              </VCardItem>
            </VCol>
            <v-col cols="6">
              <DatetimePicker
                v-if="show"
                v-model="meeting.meeting_date"
                :minDate="minDate"
                :maxDate="maxDate"
                :rules="[$required]"
                :disabled="meeting.fk_month_semester ? false : true"
                @update:model-value="meetings = []"
                forma="dd"
                readOnly
              />
            </v-col>
            <VCol cols="12">
              <VTextarea
                v-model="meeting.note"
                prepend-inner-icon="mdi-note-outline"
                clearable
                color="indigo"
                :label="$t('globals.note')"
                :placeholder="$t('semester.enter_note')"
                no-resize
                counter="250"
                density="compact"
                rows="2"
                :rules="rules.note"
              ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveItem()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateItem()" :loading="saveDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()" v-if="!is_close">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="removeDialog()" v-if="is_close">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
import moment from "moment-hijri";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      protected_records: false,
      data_message: [],
      meetings: [],
      typesMetting: [],
      monthsSemester: [],
      show: true,
      minDate: null,
      maxDate: null,
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      meeting: {},
      rules: {
        num_months: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value.length <= 2) ||
            `${this.$t("globals.biggest_number_of_character")} 99`,
          (value) => /^\d*$/.test(value) || this.$t("globals.must_be_numbers"),
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_close: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("globals.the_item"), key: "item" },
        { title: this.$t("school.type"), key: "type_name" },
        { title: this.$t("semester.discussion_points"), key: "discussion_points" },
        { title: this.$t("school.reason"), key: "reason" },
        { title: this.$t("school.output"), key: "output" },
        { title: this.$t("semester.meeting_date"), key: "meeting_date" },
        { title: this.$t("semester.months"), key: "fk_month_semester" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.meetings) {
        return JSON.parse(JSON.stringify(this.meetings));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getMeetingTypingChoices();
      await this.getMonthsSemester();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    getCurrentMonth() {
      // this.meetings = [];
      this.show = false;
      this.meeting.meeting_date = null;
      if(this.meeting.fk_month_semester){
        const monthId = this.monthsSemester.filter((e)=>e.id == this.meeting.fk_month_semester).reduce((map, item) => {
          map[item.id] = item.month;
          return map;
        });
        const month =
          monthId.month < 10
            ? "0" + monthId.month.toString()
            : monthId.month;
        const now = moment();
        const startOfMonth = now.iYear() + "-" + month + "-01";
        const endOfMonth =
          now.iYear() +
          "-" +
          monthId.month +
          "-" +
          now.endOf("iMonth").iDate();
        this.minDate = startOfMonth;
        this.maxDate = endOfMonth;
        this.meeting.meeting_date = this.minDate;
      }
      this.$nextTick(() => {
        this.show = true;
      });
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    resetForm() {
      // this.$refs.form.reset();
      this.meeting.item = undefined;
      this.meeting.type = undefined;
      this.meeting.discussion_points = undefined;
      this.meeting.output = undefined;
      this.meeting.reason = undefined;
      this.meeting.note = undefined;
      this.updated_id = undefined;
      this.is_close = false;
      this.is_update = false;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    removeDialog() {
      this.addDialog = false;
    },    
    editItem(data) {
      this.meeting = Object.assign({}, data);
      this.is_close = true;
      this.is_update = true;
      this.updated_id = data.id;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async getMeetingTypingChoices() {
      await this.axios(`${this.base_url}choices/meetin-typeg-choices`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.typesMetting = response.data;
      });
    },
    async getMonthsSemester() {
      this.loading = true;
      await this.axios(`${this.base_url}api/months-semester/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.monthsSemester = response.data;
        this.meeting.fk_month_semester = this.monthsSemester[0].id;
        this.loading = false;
        this.getCurrentMonth();
      });
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/meetings/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      }).then((response) => {
        this.meetings = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.meeting.data_entry = this.user;
        await this.axios
          .post(this.base_url + "/api/meetings/", this.meeting, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            this.saveDataLoading = false;

            if (error.response) {
              this.$emit("warningAlert", this.$t("summer.already-semester"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.meeting.id != undefined) {
        this.editDataLoading = true;
        this.meeting.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}api/meetings/${this.meeting.id}/`, this.meeting, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
            this.removeDialog();
          })
          .catch((error) => {
            if (error.response.data) {
              this.$emit("errorAlert", this.$t("summer.already-semester"));
            } else this.$emit("warningAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(`${this.base_url}api/meetings/${delete_id}/`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>
<style></style>
