<template>
  <v-form ref="form">
    <v-card class="pa-2">
      <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="semesters"
            v-model="filter_data.fk_semester"
            prepend-inner-icon="mdi-calendar-month"
            item-title="name_ar"
            item-value="id"
            :label="$t('semester.semester')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            @update:model-value="exist=0"
            :rules="[$required]"
          ></VAutocomplete>
        </VCol>
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="levels"
            v-model="filter_data.fk_level"
            prepend-inner-icon="mdi-stairs-box"
            item-title="name_ar"
            item-value="id"
            :label="$t('summer.level.name_ar')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            :rules="[$required]"
            @update:modelValue="getDivisionByLevel(),getSubjects(), (this.filter_data.fk_division = null,this.filter_data.subject_id = null,exist=0)"
          ></VAutocomplete>
        </VCol>
        <VCol cols="3" class="mb-1">
            <VAutocomplete
              :items="divisions"
              v-model="filter_data.fk_division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="fk_division"
              :label="$t('division.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
            @update:model-value="exist=0"
            ></VAutocomplete>
        </VCol>
        <VCol cols="2" class="mb-1">
          <VAutocomplete
            :items="subjects"
            v-model="filter_data.subject_id"
            prepend-inner-icon="mdi-book"
            item-title="subject_name"
            item-value="id"
            :label="$t('subject.name')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            :rules="[$required]"
            @update:model-value="exist=0"
          ></VAutocomplete>
        </VCol>
        <v-col cols="1" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="primary"
            :loading="loading"
            @click="getItems()"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-table
      density="compact"
      class="rounded elevation-1 striped-table"
      fixed-header
      v-if="exist" 
    >
      <thead>
        <tr>
          <th>{{ $t("globals.number") }}</th>
          <th>{{ $t("school.student_name") }} </th>
          <th>{{ $t("class-marks.collector") }} </th>
          <th>{{ $t("class-marks.final-exam") }} </th>
          <th>{{ $t("class-marks.mark_total") }} </th>
        </tr>
      </thead>
      <tbody>
          <tr v-for="(student, wIndex) in students_list" :key="wIndex">
            <td style="width: 7%;"> {{ wIndex + 1 }}</td>
            <td style="width: 30%;"> {{ student.student_name }}</td>
            <td style="width: 25%;"> 
                <VTextField
                  v-model="student.collector"
                  :label="$t('monthly_marks.enter_mark')"
                  density="compact"
                  clearable
                  disabled="true"
                  @input="student.collector = parseInt(student.collector)||0"
                  maxLength="2"
                  :rules="[$max_value(setting.collector),$numeric]"
                >
                </VTextField>
            </td>
            <td style="width: 25%;"> 
                <VTextField
                  v-model="student.final_exam"
                  :label="$t('monthly_marks.enter_mark')"
                  clearable
                  @input="student.final_exam = parseInt(student.final_exam)||0"
                  maxLength="2"
                  :rules="[$max_value(setting.final_exam),$numeric]"                  
                >
                </VTextField>
            </td>
            <td style="width: 13%;"> {{ getTotal(student) }} </td>
          </tr>
      </tbody>
      <v-card-actions class="mx-2 pt-0">
        <v-btn @click="saveItem" :loading="saveDataLoading" class="bg-primary" size="small">
          <span class="text-white"> {{ $t("globals.save") }} </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-table>
    <!-- <v-card>
    <h2 v-if="students_list.length == 0 && isShow" class="text-center"> لايوجد بيانات </h2>
    </v-card> -->
  </v-form>
</template>
<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
import { required, helpers } from "@vuelidate/validators";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      v$: useValidate(),
      addDialog: false,
      protected_records: false,
      data_message: [],
      students_list: [],
      divisions: [],
      levels: [],
      semesters: [],
      subjects: [],
      show: true,
      filter_data: {
        fk_level: undefined,
        students_list: [],
      },
      loading: false,
      exist: undefined,
      isShow: undefined,
      dynamicHeaders: [],
      quranGroupChoice: [],
      setting: {},
      saveDataLoading: false,
      editDataLoading: false,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    tableList() {
      if (this.students_list) {
        return JSON.parse(JSON.stringify(this.students_list));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getSemester();
      await this.getlevels();
      await this.getSetting();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  validations() {
    return {
      filter_data: {
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        subject_id: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  methods: {
    getTotal(obj){
      let sum = obj.collector + obj.final_exam;
      return sum;
    },
    async getDivisionByLevel() {
      if (this.filter_data.fk_level) {
        let levelDivisions = await this.axios.get(
          this.base_url + `api/summer/get-level-division/${this.filter_data.fk_level}`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );
        this.divisions = levelDivisions.data.division;
      }
    },
    async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
      const s = this.semesters.filter((e)=>e.current_semester==true);
      this.filter_data.fk_semester = s[0].id;
    },
    async getSetting() {
      let semester = await this.axios.get(
        this.base_url + `/api/setting-system/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.setting = semester.data;
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    async getlevels() {
      let levels = await this.axios.get(this.base_url + `api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.levels = levels.data;
    },   
    async getSubjects() {
      if (this.filter_data.fk_level) {
        let response = await this.axios.get(
          this.base_url + `api/level-subject/?fk_level=${this.filter_data.fk_level}`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );
        this.subjects = response.data;
      }
    },
    async getItems() {
      this.exist = undefined;
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.isShow = true;
        this.loading = true;
        await this.axios(`${this.base_url}api/student-subject-final/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_semester: this.filter_data.fk_semester,
            fk_level: this.filter_data.fk_level,
            fk_division: this.filter_data.fk_division,
            LevelSubject: this.filter_data.subject_id,
          },
        }).then((response) => {
          this.students_list = response.data;
          if(this.students_list.length === 0){
            this.$emit("warningAlert", this.$t("globals.not-found"));
          }  else {
            this.exist = true;
          }
          this.loading = false;
        }).catch((error) => {
            this.loading = false;
          });
      }
    },
    async saveItem() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        await this.axios
          .post(this.base_url + `api/student-subject-final/`, this.students_list, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.getItems();
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("warningAlert", this.$t("summer.already-governorate"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
      }
    },
  },
};
</script>