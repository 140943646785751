// import Dashboard from '../views/Examples/Dashboard'
import store from "@/store/index";
import VacationsReport from "@/views/Summer/reports/VacationsReport.vue";
import ScheduleSummerReport from "@/views/Summer/reports/ScheduleSummerReport.vue";
import TeacherDataReport from "@/views/Summer/reports/TeacherDataReport.vue";
import EmployeeAttendanceReport from "@/views/Summer/reports/EmployeeAttendanceReport.vue";
import EmployeeAttendanceReportFinal from "@/views/Summer/reports/EmployeeAttendanceReportFinal.vue";
import VisitorReport from "@/views/Summer/reports/VisitorReport.vue";
import ManagementStatisticReport from "@/views/Summer/reports/ManagementStatisticReport.vue";
import ManagementStatisticByWeekReport from "@/views/Summer/reports/ManagementStatisticByWeekReport.vue";
import SuppliesStatisticReport from "@/views/Summer/reports/SuppliesStatisticReport.vue";
import ScheduleStatisticReport from "@/views/Summer/reports/ScheduleStatisticReport.vue";
import ScheduleStatisticByLevelReport from "@/views/Summer/reports/ScheduleStatisticByLevelReport.vue";
import studentmarksreports from "@/views/Summer/reports/studentmarksreports.vue";
import supportVisitorsEntity from "@/views/Summer/reports/supportVisitorsEntity.vue";
import positivesNegatives from "@/views/Summer/reports/positivesNegatives.vue";
import positivesNegativesFinal from "@/views/Summer/reports/positivesNegativesFinal.vue";
import socailcommittees from "@/views/Summer/reports/socailcommittees.vue";
import approachstatistics from "@/views/Summer/reports/approachstatistics.vue";
import absorptionStatistics from "@/views/Summer/reports/absorptionStatistics.vue";
import approachstatisticsweek from "@/views/Summer/reports/approachstatisticsweek.vue";
import employeedisciplineweek from "@/views/Summer/reports/employeedisciplineweek.vue";
import employeedisciplinefinal from "@/views/Summer/reports/employeedisciplinefinal.vue";
import clenderreport from "@/views/Summer/reports/clenderreport.vue";
import approachstatisticsfinal from "@/views/Summer/reports/approachstatisticsfinal.vue";
import materialstatics from "@/views/Summer/reports/materialstatics.vue";
import studentsstatistics from "@/views/Summer/reports/studentsstatistics.vue";
import weeklyActivities from "@/views/Summer/reports/weeklyActivities.vue";
import studentsInfo from "@/views/Summer/reports/studentsInfo.vue";
import allinitiative from "@/views/Summer/reports/allinitiative.vue";
import allinitiativeSuscriber from "@/views/Summer/reports/allinitiativeSuscriber.vue";
import allActivitiesFinal from "@/views/Summer/reports/allActivitiesFinal.vue";
import SummerCenterData from "@/views/Summer/reports/SummerCenterData.vue";
import talents from "@/views/Summer/reports/talents.vue";
import allActivitiesByTypeWeek from "@/views/Summer/reports/allActivitiesByTypeWeek.vue";
import employeeDisciplinePortfolioByWeek from "@/views/Summer/reports/employeeDisciplinePortfolioByWeek.vue";
import employeeDisciplinePortfolioFinal from "@/views/Summer/reports/employeeDisciplinePortfolioFinal.vue";
import allActivitiesByType from "@/views/Summer/reports/allActivitiesByType.vue";
import StudentAttendanceReport from "@/views/Summer/reports/StudentAttendanceReport.vue";
import StudentDailyAttendanceAndAbsenceReport from "@/views/Summer/reports/StudentDailyAttendanceAndAbsenceReport.vue";
import studentsstatisticsweek from "@/views/Summer/reports/studentsstatisticsweek.vue";
import studentsstatisticsfinal from "@/views/Summer/reports/StudentsStatisticsFinal.vue";
import allDetailsActivities from "@/views/Summer/reports/allDetailsActivities.vue";
import studentsbyTypeactivity from "@/views/Summer/reports/studentsbyTypeactivity.vue";
import droupoutreport from "@/views/Summer/reports/droupoutreport.vue";
import droupoutreports from "@/views/Summer/reports/DropoutWeekReport.vue";
import droupoutreportfinal from "@/views/Summer/reports/DropoutReportFinal.vue";
import supportVisitorsEntityfinal from "@/views/Summer/reports/supportVisitorsEntityfinal.vue";
import EmployeesByRole from "@/views/Summer/reports/EmployeesByRole.vue";
import StudentsDropedout from "@/views/Summer/summerSchool/StudentsDropedout.vue"






// added by Youse Alsoudi 
import EvaluationReport from "@/views/Report/New/EvaluationReport.vue"
import ResidentialStudentsReport from "@/views/Report/New/ResidentialStudentsReport.vue"
import SchoolEnrollementWorkFolwReport from "@/views/Report/New/SchoolEnrollementWorkFolwReport.vue"
import DailyQuoteImplementationReport from "@/views/Report/New/DailyQuoteImplementationReport.vue"
import ImplementationActivitiesReport from "@/views/Report/New/ImplementationActivitiesReport.vue"
import StudentsDropoutReport from "@/views/Report/New/StudentsDropoutReport.vue"
import EducationProgramOperationReport from "@/views/Report/New/EducationProgramOperationReport.vue"
import StudentDataReport from "@/views/Report/New/StudentDataReport.vue"
import CardReport from "@/views/Report/New/CardReport.vue"
import CertificatesReport from "@/views/Report/New/CertificatesReport.vue"
import CertificatesFinalReport from "@/views/Report/New/CertificatesFinalReport.vue"

// added by yousef 
import MarkStudentReport from "@/views/Report/New/MarkStudentReport.vue"
import LessonImplementForSchoolReport from "@/views/Report/New/LessonImplementForSchoolReport.vue"

// const hasPermission = (prem) => {
//   // store.dispatch("updateAuthenticated", localStorage.getItem("token"));
//   store.dispatch("updatePermission", localStorage.getItem("userinfo"));
//   return store.getters.checkpermission(prem);
// };

function CheckSummerCenterSystem(to, from, next) {
  const summer_center = localStorage.getItem("summer_center");
  if (summer_center === "true") {
    next();
  } else {
    next({
      path: "/",
      query: { redirect: from.fullPath },
    });
  }
}
// const requirePermission = (perm) => {
//   return (to, from, next) => {
//     if (
//       hasPermission(perm) &&
//       localStorage.getItem("summer_center") == "true"
//     ) {
//       next();
//     } else {
//       next({
//         path: "/",
//         query: { redirect: from.fullPath },
//       });
//     }
//   };
// };
const hasPermission = (prem, role) => {
  return store.getters.checkpermission(prem, role);
};
const requirePermission = (perm, role = []) => {
  return (to, from, next) => {
    if (hasPermission(perm, role)) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};
const requireRole = (role) => {
  return (to, from, next) => {
    if (store.getters.checkrole(role)) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};

export default [
  {
    path: "vacations",
    name: "vacations",
    component: VacationsReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "schedule-summer",
    name: "schedule-summer",
    component: ScheduleSummerReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "teachers-data",
    name: "teachers-data",
    component: TeacherDataReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "employee-attendance",
    name: "employee-attendance-report",
    component: EmployeeAttendanceReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "employee-attendances",
    name: "employee-attendance-report-final",
    component: EmployeeAttendanceReportFinal,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "employee-attendance-dayly-report",
    name: "employee-attendance-dayly-report",
    component: EmployeeAttendanceReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "visitors",
    name: "visitors-report",
    component: VisitorReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "management-statistic",
    name: "management-statistic",
    component: ManagementStatisticReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "management-statistic-by-week",
    name: "management-statistic-by-week",
    component: ManagementStatisticByWeekReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "supplies-statistic",
    name: "supplies-statistic",
    component: SuppliesStatisticReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "student-marks",
    name: "student-marks-reports",
    component: studentmarksreports,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "support-visitors-entity",
    name: "support-visitors-entity",
    component: supportVisitorsEntity,
  },
  {
    path: "positives-negatives",
    name: "positives-negatives",
    component: positivesNegatives,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "positives-negativess",
    name: "positives-negatives-final",
    component: positivesNegativesFinal,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "schedule-statistic",
    name: "schedule-statistic",
    component: ScheduleStatisticReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "schedule-statistic-by-level",
    name: "schedule-statistic-by-level",
    component: ScheduleStatisticByLevelReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },

  {
    path: "socail-committees",
    name: "socail-committees",
    component: socailcommittees,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },

  {
    path: "approach-statistics",
    name: "approach-statistics",
    component: approachstatistics,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "absorption-statistics",
    name: "absorption-statistics",
    component: absorptionStatistics,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "approach-statistics-week",
    name: "approach-statistics-week",
    component: approachstatisticsweek,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "employee-discipline-week",
    name: "employee-discipline-week",
    component: employeedisciplineweek,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "employee-disciplines",
    name: "employee-discipline-final",
    component: employeedisciplinefinal,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },

  {
    path: "clender-report",
    name: "clender-report",
    component: clenderreport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },

  {
    path: "approach-statisticss",
    name: "approach-statistics-final",
    component: approachstatistics,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },

  {
    path: "material-statics",
    name: "material-statics",
    component: materialstatics,
  },

  {
    path: "students-statistics",
    name: "students-statistics",
    component: studentsstatistics,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },

  {
    path: "students-statistics-week",
    name: "students-statistics-week",
    component: studentsstatisticsweek,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "students-statisticss",
    name: "students-statistics-final",
    component: studentsstatisticsfinal,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },

  {
    path: "school-weekly-activity",
    name: "weekly-activities",
    component: weeklyActivities,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "student-info-by-level-and-division",
    name: "students-info",
    component: studentsInfo,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "all-initiative-report",
    name: "all-initiative",
    component: allinitiative,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "initiative-suscriber",
    name: "all-initiative-suscriber",
    component: allinitiativeSuscriber,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "all-activitiess",
    name: "all-activities",
    component: allActivitiesFinal,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "all-activities-by-week-type",
    name: "all-activities-by-week-type",
    component: allActivitiesByTypeWeek,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "employee-discipline-portfolio-by-week",
    name: "employee-discipline-portfolio-by-week",
    component: employeeDisciplinePortfolioByWeek,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "employee-discipline-portfolios",
    name: "employee-discipline-portfolio-final",
    component: employeeDisciplinePortfolioFinal,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "all-activities-by-type",
    name: "all-activities-by-type-activity",
    component: allActivitiesByType,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },

  {
    path: "student-attendance",
    name: "student-attendance-report",
    component: StudentAttendanceReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "student-daily-attendance-absence-report",
    name: "student-daily-attendance-absence-report",
    component: StudentDailyAttendanceAndAbsenceReport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "get-talent-for-student-by-level",
    name: "talents",
    component: talents,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "all-details-activities",
    name: "all-details-activities",
    component: allDetailsActivities,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "student-activity",
    name: "students-by-Type-activity",
    component: studentsbyTypeactivity,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },

  {
    path: "summer-center-data",
    name: "summer-center-data",
    component: SummerCenterData,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "droupout-report",
    name: "droupout-report",
    component: droupoutreport,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },

  {
    path: "droupout-report-week",
    name: "droupout-report-week",
    component: droupoutreports,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "droupout-reports",
    name: "droupout-report-final",
    component: droupoutreportfinal,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "support-Visitors-Entitys",
    name: "support-Visitors-Entity-final",
    component: supportVisitorsEntityfinal,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },
  {
    path: "employees-by-role",
    name: "employees-by-role",
    component: EmployeesByRole,
    beforeEnter: requireRole(["summer_admin", "user"]),
  },

  {
    path: "open-school-droped-out",
    name: "open_school_droped_out",
    component: StudentsDropedout,
  },


  // added by samer
  {
    path: "evaluation-report",
    name: "evaluation-report",
    component: EvaluationReport
  },
  {
    path: "mark-student-report",
    name: "mark-student-report",
    component: MarkStudentReport
  },
  {
    path: "lesson-implementFor-school-report",
    name: "lesson-implementFor-school-report",
    component: LessonImplementForSchoolReport
  },
  {
    path: "residential-students-report",
    name: "residential-students-report",
    component: ResidentialStudentsReport
  },
  {
    path: "school-enrollement-workfolw-report",
    name: "school-enrollement-workfolw-report",
    component: SchoolEnrollementWorkFolwReport
  },
  {
    path: "daily-quote-implementation-report",
    name: "daily-quote-implementation-report",
    component: DailyQuoteImplementationReport
  },
  {
    path: "implementation-activities-report",
    name: "implementation-activities-report",
    component: ImplementationActivitiesReport
  },
  {
    path: "students-dropout-report",
    name: "students-dropout-report",
    component: StudentsDropoutReport
  },
  {
    path: "education-program-operation-report",
    name: "education-program-operation-report",
    component: EducationProgramOperationReport
  },
  {
    path: "student-data-report",
    name: "student-data-report",
    component: StudentDataReport
  },

  {
    path: "card-report",
    name: "card-report",
    component: CardReport
  },
  {
    path: "certificates-report",
    name: "certificates-report",
    component: CertificatesReport
  },
  
  {
    path: "certificates-final-report",
    name: "certificates-final-report",
    component: CertificatesFinalReport
  },
  
];
