<template>
  <div class="image-card ">
    <v-card class="w-100 border-lg border-dashed pa-1" elevation="0" style="height: 200px" @click="choseImage">
      <span
        v-if="!image && !imageRender"
        class="w-100 h-100 d-flex justify-center align-center bg-ccc"
        >{{ text }}
      </span>
      <input
        type="file"
        ref="imageInput"
        class="d-none"
        @input="selectImage"
        accept="image/*"
      />
      <img v-if="imageRender" :src="imageRender" class="w-100 h-100 rounded" />
      <img v-if="image && !imageRender" :src="image" class="w-100 h-100 rounded" />
    </v-card>
    <span v-show="imageError" class="text-red pa-2"
      >{{ $t("summer.imageError") }} 2mb</span
    >
    <span class="bg-grey image-icon" v-if="imageRender || (image && !imageRender)">
      <v-icon size="large" color="error" @click="removeLogo">mdi-close-circle</v-icon>
    </span>
  </div>
</template>
<script>
import { file } from "@babel/types";
import { watch } from "@vue/runtime-core";
export default {
  name: "ImageInput",
  props: ["image", "text", "reset"],
  data() {
    return {
      imageRender: "",
      imageError: false,
    };
  },
  methods: {
    choseImage() {
      this.$refs.imageInput.click();
    },
    selectImage(e) {
      const input = this.$refs.imageInput;
      const files = input.files;
      if (files && files[0]) {
        const maxSizeInBytes = 2 * 1024 * 1024;
        if (files[0].size > maxSizeInBytes) {
          this.imageError = true;
        } else {
          this.imageError = false;
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageRender = e.target.result;
            this.$emit("ImageSelected", files[0]);
          };
          reader.readAsDataURL(files[0]);
          this.$emit("resetRequest", 0);
        }
      }
    },
    removeLogo() {
      this.imageRender = null;
      this.$refs.imageInput.value = null;
      this.$emit("ImageSelected", null);
      this.$emit("resetRequest", 1);
    },
  },
  watch: {
    reset() {
      this.imageRender = "";
    },
  },
};
</script>
<style scoped>
.image-card {
  position: relative;
}

.image-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  border-bottom-left-radius: 23px;
  padding: 5px;
}
</style>
