<template>

  <!-- added by samer -->
  <!-- شاشة البرامج التربوية -->
  <DataTable
    v-if="CheckGetPermission('legal_school.view_educationprogram')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getData"
    :create="() => (dialog = true)"
    :pagination="pagination"
    :editItem="editData"
    :delItem="deleteData"
    :del_perm="
      CheckGetPermission('legal_school.delete_educationprogram', [
        'summer_admin',
      ])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_educationprogram', [
        'summer_admin',
      ])
    "
    :add_perm="
      CheckGetPermission('legal_school.add_educationprogram', ['summer_admin'])
    "
  >
    <template v-slot:item.status="{ item }">
      <span>
        <v-icon v-if="item.status == true" color="success"
          >mdi-check-circle</v-icon
        >
        <v-icon v-if="item.status == false" color="error"
          >mdi-close-circle</v-icon
        >
      </span>
    </template>
    <template v-slot:item.access="{ item }">
      <span>
        <span v-if="item.access == true" >{{item.summer_name}}</span> 
        <span v-if="item.access == false" > {{$t('globals.globals_admin')}}</span>
        
      </span>
    </template>
  </DataTable>

  <v-dialog v-model="dialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="saveData">
          <VTextField
            v-model="dropList.name"
            :label="$t('globals.program')"
            density="compact"
            :rules="[$required,$max_length(100)]"
            prepend-inner-icon="mdi-office-building"
          />
          <VAutocomplete
            v-model="dropList.iteration"
            :items="iterations"
            item-title="name"
            item-value="id"
            :label="$t('globals.iteration')"
            persistent-hint
            prepend-inner-icon="mdi-refresh"
            density="compact"
            clearable
            :rules="[$required]"
          />
          <v-checkbox
            v-model="dropList.status"
            :label="$t('globals.currently_required')"
             :rules="[$required]"
             hide-details ="auto"
          />
          <VTextarea
            v-model="dropList.note"
            clearable
            prepend-inner-icon="mdi-note-outline"
            :label="$t('globals.note')"
            counter="250"          
            density="compact"
            rows="2"
            :rules="[$max_length(250)]"
          ></VTextarea>
        </VForm>
      </v-card-text>

      <VCardActions class="mx-3">
        <VBtnUpdate
          v-if="dropList.id"
          @click="updateData()"
          :loading="loading_btn"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtnSave v-else @click="saveData()" :loading="loading_btn">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtn class="mx-3" size="small" @click="dropList.id ? dialog = false:dropList={}">
          {{dropList.id ? $t('globals.cancel') : $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import DataTable from "@/components/Globals/DataTable.vue";
import { mapState } from "vuex";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      dropList: {
        status:true
      },
      pagination: {},
      items: [],
      schools: [],
      iterations: [],
      dialog: false,
      loading_btn: false,
    };
  },
  created() {
    this.getSchoolSummer();
    this.getIterations();
  },
  methods: {
    async getData(page = 1, per_page = 10, ordering = "id", search = null) {
      if(ordering == 'iteration_label')
        ordering ='iteration'
      else if(ordering == '-iteration_label')
        ordering ='-iteration'
      await this.axios(`${this.base_url}/api/education-program/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      }).then((response) => {
        this.items = response.data.results;
        this.pagination = response.data.pagination;
      });
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading_btn = true;
        await this.axios
          .post(this.base_url + "api/education-program/", this.dropList, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.dropList = { status:true};
            this.getData();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.loading_btn = false;
      }
    },
    editData(data) {
      this.dropList = { ...data };
      this.dialog = true;
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        await this.axios
          .put(
            this.base_url + "/api/education-program/" + this.dropList.id + "/",
            this.dropList,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.dialog = false;
            this.getData();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.title"));
          });
      }
    },
    async deleteData(delete_id) {
      if (delete_id) {
        var status;
        await this.axios
          .delete(this.base_url + "api/education-program/" + delete_id + "/", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((e) => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getSchoolSummer() {
      await this.axios(`${this.base_url}api/school-summer-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.schools = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getIterations() {
      await this.axios(`${this.base_url}/choices/activity-iterations`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.iterations = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  computed: {
      ...mapState({
      role: (state) => state.role,
    }),
    headers() {
      return [
        { title: this.$t("globals.program"), key: "name" },
        { title: this.$t("globals.iteration"), key: "iteration_label" },
        { title: this.$t("globals.currently_required"), key: "status" },
        { title: this.$t("globals.access"), key: "access" },
        { title: this.$t("globals.note"), key: "note" ,show:false},
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) this.dropList = { status:true};
    },
  },
};
</script>