<template>
  <!-- added by samer  -->
  <!-- شاشة اضافة العاملين -->
  <!-- <pre dir="ltr">{{dropList}}</pre> -->
  <v-card rounded="lg" elevation="3">
    <v-tabs v-model="step" v-bind="steps" stacked>
      <v-tab
        v-for="(tab, key) in tabs"
        :key="key"
        :value="key"
        density
        slider-color="black"
        rounded="lg"
        @click="next(key)"
      >
        <v-icon
          v-if="!tab.valid"
          icon="mdi-alert-circle"
          color="red"
          class="mb-2"
        />
        <v-avatar
          v-else
          :color="tab.valid ? 'primary' : 'red'"
          size="18"
          class="mb-2"
        >
          <h5>{{ key + 1 }}</h5>
        </v-avatar>
        {{ tab.name }}
      </v-tab>
    </v-tabs>
  </v-card>

  <v-card rounded class="mt-3" :disabled="loading" :loading="loading">
    <v-card-text>
      <v-window v-model="steps">
        <v-window-item :value="0" class="mt-5" eager>
          <v-form ref="form_1" class="mb-3">
            <v-col>
              <v-row>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    v-model="dropList.name_ar"
                    :label="$t('summer.name-employees')"
                    persistent-hint
                    clearable
                    density="compact"
                    prepend-inner-icon="mdi-account-tie"
                    :rules="[$required, $max_length(100)]"
                    :error-messages="
                      dropList.uniqe_name ? $t('errors.unique') : ''
                    "
                    @update:modelValue="dropList.uniqe_name = false"
                  />
                </v-col>

                <v-col cols="12" :md="col" :lg="col">
                  <v-autocomplete
                    :items="identifyType"
                    v-model="dropList.identity_type"
                    item-title="name"
                    item-value="id"
                    :label="$t('driver.card_type')"
                    persistent-hint
                    density="compact"
                    prepend-inner-icon="mdi-format-list-bulleted"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    v-model="dropList.identity_id"
                    :label="$t('driver.card_no')"
                    persistent-hint
                    clearable
                    density="compact"
                    type="number"
                    prepend-inner-icon="mdi-numeric"
                    :rules="[$max_length(11),$min_length(6),$positive]"
                    :error-messages="
                      dropList.uniqe_identity_id ? $t('errors.unique') : ''
                    "
                    @update:modelValue="dropList.uniqe_identity_id = false"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-autocomplete
                    :items="genders"
                    v-model="dropList.gender"
                    item-title="name"
                    item-value="id"
                    :label="$t('globals.gender')"
                    persistent-hint
                    density="compact"
                    prepend-inner-icon="mdi-gender-male-female"
                    :rules="[$required]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" :md="col" :lg="col">
                  <v-autocomplete
                    v-model="dropList.marital_status"
                    :items="maritalStatus"
                    item-title="name"
                    item-value="id"
                    :label="$t('globals.marital-status')"
                    persistent-hint
                    density="compact"
                    prepend-inner-icon="mdi-ring"
                  />
                </v-col>

                <v-col
                  cols="12"
                  :md="col"
                  :lg="col"
                  v-if="dropList.marital_status && dropList.marital_status != 1"
                >
                  <v-text-field
                    v-model="dropList.children"
                    :label="$t('summer.employee.children-numbers')"
                    persistent-hint
                    clearable
                    density="compact"
                    type="number"
                    prepend-inner-icon="mdi-human-child"
                    :rules="[$max_length(9),$positive]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  :md="col"
                  :lg="col"
                  v-if="dropList.marital_status && dropList.marital_status != 1"
                >
                  <v-text-field
                    v-model="dropList.female_childern"
                    :label="$t('globals.female_childern')"
                    persistent-hint
                    clearable
                    density="compact"
                    type="number"
                    prepend-inner-icon="mdi-human-female"
                    :rules="[$max_length(9),$positive]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" :md="col" :lg="col">
                  <v-autocomplete
                    v-model="dropList.health_situation"
                    :items="health_situation"
                    item-title="name"
                    item-value="id"
                    :label="$t('globals.health_situation')"
                    persistent-hint
                    density="compact"
                    prepend-inner-icon="mdi-heart-pulse"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col" v-if="dropList.health_situation >1">
                  <v-text-field
                    v-model="dropList.type_of_disease"
                    :label="$t('globals.type_of_disease')"
                    persistent-hint
                    clearable
                    density="compact"
                    prepend-inner-icon="mdi-format-list-bulleted"
                    :rules="[$max_length(100)]"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col" v-if="dropList.health_situation >1">
                  <v-text-field
                    v-model="dropList.nature_of_illness"
                    :label="$t('globals.nature_of_illness')"
                    persistent-hint
                    clearable
                    density="compact"
                    prepend-inner-icon="mdi-blood-bag"
                    :rules="[$max_length(100)]"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" :md="col" :lg="col">
                  <VTextarea
                    v-model="dropList.note"
                    clearable
                    :label="$t('summer.initiative.note')"
                    counter="250"
                    density="compact"
                    rows="2"
                    :rules="[$max_length(250)]"
                    prepend-inner-icon="mdi-note-outline"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-window-item>
        <v-window-item :value="1" class="mt-5" eager>
          <v-form ref="form_2" class="mb-3">
            <v-col>
              <v-row>
                <v-col cols="12" :md="col" :lg="col">
                  <v-autocomplete
                    :items="schools"
                    v-model="dropList.fk_summer"
                    item-title="name_ar"
                    item-value="id"
                    :label="$t('summer.summer-camp')"
                    persistent-hint
                    prepend-inner-icon="mdi-school"
                    density="compact"
                    :rules="[$required]"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    v-model="dropList.surname"
                    :label="$t('globals.college')"
                    persistent-hint
                    clearable
                    density="compact"
                    prepend-inner-icon="mdi-collage"
                    :rules="[$max_length(14)]"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-autocomplete
                    v-model="dropList.role"
                    :items="
                      dropList.role != 1
                        ? roles.filter((role) => role.id != 1)
                        : roles
                    "
                    item-title="name"
                    item-value="id"
                    :label="$t('summer.work-type')"
                    persistent-hint
                    prepend-inner-icon="mdi-list-status"
                    density="compact"
                    :rules="[$required]"
                  />
                </v-col>
                <v-col
                  cols="12"
                  :md="col"
                  :lg="col"
                  v-if="dropList.role && dropList.role !== 3"
                >
                  <v-text-field
                    v-model="dropList.job_id"
                    :label="$t('globals.job-id')"
                    persistent-hint
                    clearable
                    density="compact"
                    type="number"
                    prepend-inner-icon="mdi-numeric"
                    :rules="[$max_length(18),$positive]"
                    :error-messages="
                      dropList.uniqe_job_id ? $t('errors.unique') : ''
                    "
                    @update:modelValue="dropList.uniqe_job_id = false"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    v-model="dropList.entity_type"
                    :label="$t('summer.employee.place-of-work')"
                    persistent-hint
                    clearable
                    density="compact"
                    prepend-inner-icon="mdi-account-hard-hat"
                    :rules="[$max_length(100)]"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-autocomplete
                    :items="workPeriods"
                    v-model="dropList.work_period"
                    item-title="name"
                    item-value="id"
                    :label="$t('summer.shift-time')"
                    persistent-hint
                    density="compact"
                    prepend-inner-icon="mdi-clock-time-four-outline"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-autocomplete
                    :items="qualifications"
                    v-model="dropList.qualifications"
                    item-title="name"
                    item-value="id"
                    :label="$t('globals.qualifications')"
                    persistent-hint
                    density="compact"
                    prepend-inner-icon="mdi-certificate-outline"
                    clearable
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    v-model="dropList.other_work"
                    :label="$t('summer.employee.other-work')"
                    persistent-hint
                    clearable
                    density="compact"
                    prepend-inner-icon="mdi-account-hard-hat"
                    :rules="[$max_length(50)]"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    v-model="dropList.work_place"
                    :label="$t('globals.work_place')"
                    persistent-hint
                    clearable
                    density="compact"
                    prepend-inner-icon="mdi-map-marker"
                    :rules="[$max_length(14)]"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col" class="mb-5">
                  <v-text-field
                    v-model="dropList.full_time_percentage"
                    :label="$t('globals.fulltime_ratio')"
                    persistent-hint
                    clearable
                    density="compact"
                    type="number"
                    prepend-inner-icon="mdi-clock-outline"
                    :rules="[$max_length(3),$max_value(100)]"
                  />
                </v-col>
              </v-row>
              <!--بيانات الترشيح -->
              <v-card :title="$t('globals.official_recommendation_data')">
                <v-card-text class="mt-5 mb-5">
                  <v-row>
                    <v-col cols="8" sm="12" md="3">
                      <v-text-field
                        v-model="dropList.official_recommendation"
                        prepend-inner-icon="mdi-account-group"
                        :label="$t('globals.official_recommendation')"
                        density="compact"
                        clearable
                        :rules="[$max_length(100)]"
                      />
                    </v-col>
                    <v-col cols="8" sm="12" md="3">
                      <v-text-field
                        v-model="dropList.recommendator"
                        prepend-inner-icon="mdi-filter"
                        :label="$t('globals.recommendator')"
                        density="compact"
                        clearable
                        :rules="[$max_length(100)]"
                      />
                    </v-col>
                    <v-col cols="8" sm="12" md="3">
                      <v-text-field
                        v-model="dropList.recommendator_phone_number"
                        prepend-inner-icon="mdi-phone"
                        :label="$t('globals.phone_number')"
                        density="compact"
                        clearable
                        :rules="[$max_length(9),$positive]"
                        type="number"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-form>
        </v-window-item>

        <v-window-item :value="2" class="mt-5" eager>
          <v-form ref="form_3" class="mb-3">
            <v-col>
              <v-card :title="$t('school.student_birth_place')">
                <v-card-text class="mt-5 mb-5">
                  <v-row>
                    <v-col cols="12" :md="col" :lg="col">
                      <datetime-picker
                        v-model="dropList.birthdate"
                        :calendar="'gregorg'"
                        :placeholder="$t('globals.year-month-day')"
                        :label="$t('school.student_birthdate')"
                        :rules="[$required]"
                      />
                    </v-col>
                    <v-col cols="12" :md="col" :lg="col">
                      <v-autocomplete
                        :items="countries"
                        v-model="dropList.country_id"
                        item-title="name"
                        item-value="id"
                        :label="$t('globals.country')"
                        density="compact"
                        prepend-inner-icon="mdi-flag"
                        @update:modelValue="
                          (dropList.governorate_id = undefined),
                            (dropList.fk_directorate = undefined)
                        "
                        :rules="[$required]"
                      />
                    </v-col>
                    <v-col cols="12" :md="col" :lg="col">
                      <v-autocomplete
                        :items="
                          governorates.filter(
                            (e) => e.fk_country == dropList.country_id
                          )
                        "
                        v-model="dropList.governorate_id"
                        item-title="name"
                        item-value="id"
                        :label="$t('globals.governorate')"
                        density="compact"
                        prepend-inner-icon="mdi-city"
                        @update:modelValue="dropList.fk_directorate = undefined"
                        :rules="[$required]"
                      />
                    </v-col>
                    <v-col cols="12" :md="col" :lg="col">
                      <v-autocomplete
                        :items="
                          directorates.filter(
                            (e) => e.fk_governorate == dropList.governorate_id
                          )
                        "
                        v-model="dropList.fk_directorate"
                        item-title="name"
                        item-value="id"
                        :label="$t('globals.directorate')"
                        density="compact"
                        prepend-inner-icon="mdi-map-outline"
                        :rules="[$required]"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" :md="col" :lg="col">
                      <v-text-field
                        counter="150"
                        type="input"
                        v-model="dropList.village_birthdate"
                        density="compact"
                        clearable
                        prepend-inner-icon="mdi-map-marker"
                        :label="$t('summer.solitude')"
                        :rules="[$max_length(50)]"
                      />
                    </v-col>
                    <v-col cols="12" :md="col" :lg="col">
                      <v-text-field
                        v-model="dropList.neighbourhood_birthdate"
                        :label="$t('globals.neirghbourhood')"
                        persistent-hint
                        clearable
                        density="compact"
                        prepend-inner-icon="mdi-traffic-light"
                        :rules="[$max_length(50)]"
                      />
                    </v-col>

                    <v-col cols="12" :md="col" :lg="col">
                      <v-autocomplete
                        v-model="dropList.accommodation_type"
                        item-value="id"
                        item-title="name"
                        prepend-inner-icon="mdi-home"
                        :items="accommodation_types"
                        :label="$t('summer.accommodation-type')"
                        persistent-hint
                        density="compact"
                        clearable
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12" :md="col" :lg="col">
                      <v-text-field
                        v-model="dropList.phone_number"
                        :label="$t('summer.employee.mobile-number')"
                        persistent-hint
                        clearable
                        density="compact"
                        type="number"
                        prepend-inner-icon="mdi-cellphone-text"
                        :rules="[$max_length(14),$positive]"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card :title="$t('globals.residence_place')" class="mt-3">
                <v-card-text class="mt-5 mb-5">
                  <v-col>
                    <v-row>
                      <v-col cols="12" :md="col" :lg="col">
                        <v-autocomplete
                          :items="countries"
                          v-model="dropList.country_housing"
                          item-title="name"
                          item-value="id"
                          :label="$t('globals.country')"
                          persistent-hint
                          density="compact"
                          prepend-inner-icon="mdi-flag"
                          @update:modelValue="
                            (dropList.governorate_housing = undefined),
                              (dropList.directorate_housing = undefined)
                          "
                          :rules="[$required]"
                        />
                      </v-col>
                      <v-col cols="12" :md="col" :lg="col">
                        <v-autocomplete
                          :items="
                            governorates.filter(
                              (e) => e.fk_country == dropList.country_housing
                            )
                          "
                          v-model="dropList.governorate_housing"
                          item-title="name"
                          item-value="id"
                          :label="$t('globals.governorate')"
                          persistent-hint
                          density="compact"
                          prepend-inner-icon="mdi-city"
                          @update:modelValue="
                            dropList.directorate_housing = undefined
                          "
                          :rules="[$required]"
                        />
                      </v-col>

                      <v-col cols="12" :md="col" :lg="col">
                        <v-autocomplete
                          v-model="dropList.directorate_housing"
                          :items="
                            directorates.filter(
                              (e) =>
                                e.fk_governorate == dropList.governorate_housing
                            )
                          "
                          item-title="name"
                          item-value="id"
                          :label="$t('globals.directorate')"
                          persistent-hint
                          density="compact"
                          prepend-inner-icon="mdi-map-outline"
                          :rules="[$required]"
                        />
                      </v-col>
                      <v-col cols="12" :md="col" :lg="col">
                        <v-text-field
                          counter="150"
                          type="input"
                          v-model="dropList.village_housing"
                          density="compact"
                          clearable
                          prepend-inner-icon="mdi-map-marker"
                          :label="$t('summer.solitude')"
                          :rules="[$max_length(50)]"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" :md="col" :lg="col">
                        <v-text-field
                          v-model="dropList.neighbourhood_housing"
                          :label="$t('globals.neirghbourhood')"
                          persistent-hint
                          clearable
                          density="compact"
                          prepend-inner-icon="mdi-traffic-light"
                          :rules="[$max_length(50)]"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                </v-card-text>
              </v-card>
            </v-col>
          </v-form>
        </v-window-item>
        <v-window-item :value="3" class="mt-5" eager>
          <v-form ref="form_4" class="mb-3">
            <v-col>
              <v-row>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    color="primary"
                    v-model="dropList.user.first_name"
                    clearable
                    density="compact"
                    prepend-inner-icon="mdi-abjad-arabic"
                    :label="$t('user.fname')"
                    :rules="[$max_length(150)]"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    color="primary"
                    v-model="dropList.user.last_name"
                    clearable
                    density="compact"
                    prepend-inner-icon="mdi-abjad-arabic"
                    :label="$t('user.lname')"
                    :rules="[$max_length(150)]"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    color="primary"
                    v-model="dropList.user.email"
                    autocomplete="off"
                    clearable
                    type="email"
                    counter="100"
                    name="email"
                    id="email"
                    density="compact"
                    prepend-inner-icon="mdi-at"
                    :label="$t('user.email')"
                    :rules="[$email, $max_length(244)]"
                    :error-messages="
                      dropList.uniqe_user_email ? $t('errors.unique') : ''
                    "
                    @update:modelValue="dropList.uniqe_user_email = false"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    color="primary"
                    v-model="dropList.user.username"
                    clearable
                    autocomplete="off"
                    density="compact"
                    prepend-inner-icon="mdi-abjad-arabic"
                    :label="$t('user.username')"
                    :rules="[$max_length(40)]"
                  />
                </v-col>
                    <!-- @update:modelValue="dropList.uniqe_user_name = false" -->
                    <!-- :error-messages="
                      dropList.uniqe_user_name ? $t('errors.unique') : ''
                    " -->
              </v-row>
              <v-row>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    type="password"
                    v-model="dropList.user.password"
                    name="password"
                    id="password"
                    clearable
                    counter="64"
                    density="compact"
                    autocomplete="new-password"
                    prepend-inner-icon="mdi-alpha-e"
                    :label="$t('user.password')"
                    :rules="[$min_length(8), $max_length(64)]"
                  />
                </v-col>
                <v-col cols="12" :md="9" :lg="9">
                  <v-autocomplete
                    color="primary"
                    multiple
                    v-model="dropList.user.groups"
                    density="compact"
                    item-title="name"
                    item-value="pk"
                    prepend-inner-icon="mdi-account-group"
                    clearable
                    chips
                    closable-chips
                    :items="groups_list"
                    :label="$t('user.group')"
                  >
                    <template v-slot:chip="{ props, item }">
                      <v-chip
                        class="mx-1"
                        style="font-size: 0.9rem"
                        v-bind="props"
                        :text="item.name"
                      >
                      </v-chip>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-checkbox
                    v-model="dropList.user.is_active"
                    :label="$t('user.active')"
                    color="primary"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-form>
        </v-window-item>
        <v-window-item :value="4" class="mt-5" eager>
          <v-form ref="form_5" class="mb-3">
            <v-col>
              <v-row>
                <v-col cols="12" :md="col" :lg="col">
                  <datetime-picker
                    v-model="dropList.jihadi_launch_date"
                    :calendar="'gregorg'"
                    placeholder="yyy-mm-dd"
                    :label="$t('globals.jihadi_launch_date')"
                  />
                </v-col>
                <v-col cols="12" :md="col" :lg="col">
                  <v-text-field
                    color="primary"
                    v-model="dropList.jihadi_starting_place"
                    clearable
                    density="compact"
                    prepend-inner-icon="mdi-map-marker"
                    :label="$t('summer.activity.place')"
                    :rules="[$max_length(14)]"
                  />
                </v-col>
              </v-row>

              <!-- الاعمال الحهادية -->
              <v-card :title="$t('globals.jihadist_actions')" class="mt-3">
                <v-card-text class="mt-5 mb-5">
                  <v-row>
                    <v-col cols="11">
                      <v-table
                        v-if="dropList.jihadistAction"
                        style="width: 100%"
                      >
                        <thead style="width: 100%">
                          <tr>
                            <td>{{ $t("globals.jihadist_work") }}</td>
                            <td>{{ $t("report.entity") }}</td>
                            <td>{{ $t("globals.specialization") }}</td>
                            <td>{{ $t("globals.work_start_date") }}</td>
                            <td>{{ $t("activity.place") }}</td>
                            <td>{{ $t("globals.work-period") }}</td>
                            <td>{{ $t("globals.fulltime_ratio") }}</td>
                            <td>{{ $t("student-bus.supervisor") }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in dropList.jihadistAction"
                            :key="index"
                          >
                            <td>
                              <v-text-field
                                v-model="item.name"
                                density="compact"
                                clearable
                                :rules="[$required,$max_length(150)]"
                                style="width: 100px"
                              />
                            </td>
                            <td>
                              <v-text-field
                                v-model="item.authority"
                                density="compact"
                                clearable
                                :rules="[$max_length(100)]"
                                style="width: 100px"
                              />
                            </td>
                            <td>
                              <v-text-field
                                color="primary"
                                v-model="item.specialization"
                                clearable
                                density="compact"
                                :rules="[$max_length(100)]"
                                style="width: 100px"
                              />
                            </td>
                            <td>
                              <v-text-field
                                color="primary"
                                v-model="item.starting_date"
                                clearable
                                density="compact"
                                :rules="[$max_length(100)]"
                                style="width: 100px"
                              />
                            </td>
                            <td>
                              <v-text-field
                                color="primary"
                                v-model="item.place"
                                clearable
                                density="compact"
                                :rules="[$max_length(100)]"
                                style="width: 100px"
                              />
                            </td>
                            <td>
                              <v-text-field
                                color="primary"
                                v-model="item.period_work"
                                clearable
                                density="compact"
                                :rules="[$max_length(100)]"
                                style="width: 100px"
                              />
                            </td>
                            <td>
                              <v-text-field
                                color="primary"
                                v-model="item.fulltime_ratio"
                                clearable
                                density="compact"
                                :rules="[$max_length(100)]"
                                style="width: 100px"
                              />
                            </td>

                            <td>
                              <v-text-field
                                color="primary"
                                v-model="item.supervisor"
                                clearable
                                density="compact"
                                :rules="[$max_length(100)]"
                                style="width: 100px"
                              />
                            </td>

                            <v-btn
                              v-if="dropList.jihadistAction.length > 0"
                              icon="mdi-minus"
                              variant="tonal"
                              density="compact"
                              @click="removeJihadist(index)"
                            />
                          </tr>
                        </tbody>
                      </v-table>
                    </v-col>

                    <v-col class="ms-5">
                      <v-row class="mt-5 h-100">
                        <v-btn
                          icon="mdi-plus"
                          variant="tonal"
                          density="compact"
                          @click="addJihadist"
                        />
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- ألدورات -->

              <v-card :title="$t('globals.TrainingCourse')" class="mt-3">
                <v-card-text class="mt-5 mb-5">
                  <v-row>
                    <v-col cols="11">
                      <v-table
                        v-if="dropList.trainingCourse"
                        style="width: 100%"
                      >
                        <thead style="width: 100%">
                          <tr>
                            <td>{{ $t("globals.type_training_courses") }}</td>
                            <td>{{ $t("globals.number_training_courses") }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(item, index) in dropList.trainingCourse"
                            :key="index"
                          >
                            <td>
                              <v-autocomplete
                                :items="trainingCourse"
                                v-model="item.typecourse"
                                item-title="name"
                                item-value="id"
                                persistent-hint
                                density="compact"
                                style="width: 200px"
                                :rules="[$required]"
                              />
                            </td>

                            <td>
                              <v-text-field
                                v-model="item.num"
                                density="compact"
                                clearable
                                type="number"
                                :rules="[$max_length(9),$positive]"
                                style="width: 100px"
                              />
                            </td>

                            <v-btn
                              v-if="dropList.trainingCourse.length > 0"
                              icon="mdi-minus"
                              variant="tonal"
                              density="compact"
                              @click="removeTrainingCourse(index)"
                            />
                          </tr>
                        </tbody>
                      </v-table>
                    </v-col>

                    <v-col class="ms-5">
                      <v-row class="mt-5 h-100">
                        <v-btn
                          icon="mdi-plus"
                          variant="tonal"
                          density="compact"
                          @click="addTrainingCourse"
                        />
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>

              <!-- ألتخصصات -->
              <v-card :title="$t('globals.specialization')" class="mt-3">
                <v-card-text class="mt-5 mb-5">
                  <v-row>
                    <v-col cols="11">
                      <v-table
                        v-if="dropList.scientificDiscipline"
                        style="width: 100%"
                      >
                        <thead style="width: 100%">
                          <tr>
                            <td>{{ $t("globals.specialization") }}</td>
                            <td>{{ $t("globals.date") }}</td>
                            <td>{{ $t("globals.educational_entity") }}</td>
                            <td>{{ $t("globals.country") }}</td>
                            <td>{{ $t("globals.duration") }}</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(
                              item, index
                            ) in dropList.scientificDiscipline"
                            :key="index"
                          >
                            <td>
                              <VAutocomplete
                                :items="qualifications"
                                v-model="item.name"
                                item-title="name"
                                item-value="id"
                                persistent-hint
                                density="compact"
                                style="width: 200px"
                                :rules="[$required]"
                              />
                            </td>

                            <td>
                              <DatetimePicker
                                v-model="item.date"
                                :calendar="'gregorg'"
                                style="width: 200px"
                              />
                            </td>

                            <td>
                              <v-text-field
                                v-model="item.educational_entity"
                                density="compact"
                                clearable
                                :rules="[$max_length(100)]"
                                style="width: 150px"
                              />
                            </td>

                            <td>
                              <v-text-field
                                v-model="item.contury"
                                density="compact"
                                clearable
                                :rules="[$max_length(100)]"
                                style="width: 150px"
                              />
                            </td>

                            <td>
                              <v-text-field
                                v-model="item.duration"
                                density="compact"
                                clearable
                                :rules="[$max_length(100)]"
                                style="width: 100px"
                              />
                            </td>

                            <v-btn
                              v-if="dropList.scientificDiscipline.length > 0"
                              icon="mdi-minus"
                              variant="tonal"
                              density="compact"
                              @click="removeScientificDiscipline(index)"
                            />
                          </tr>
                        </tbody>
                      </v-table>
                    </v-col>

                    <v-col class="ms-5">
                      <v-row class="mt-5 h-100">
                        <v-btn
                          icon="mdi-plus"
                          variant="tonal"
                          density="compact"
                          @click="addScientificDiscipline"
                        />
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-form>
        </v-window-item>
      </v-window>
    </v-card-text>

    <VCardActions class="px-3">
      <VBtn
        v-if="!id && CheckGetPermission('legal_school.add_employee')"
        class="bg-primary"
        @click.prevent="saveData()"
        size="small"
        :loading="btn_loading"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="id && CheckGetPermission('legal_school.change_employee')"
        class="bg-primary"
        @click.prevent="updateData()"
        size="small"
        :loading="btn_loading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        class="ma-3"
        size="small"
        @click="id ? $router.push({ name: 'employees' }) : resetForm()"
      >
        {{ !id ? $t("globals.clear") : $t("globals.cancel") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </v-card>
</template>
<script>
export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      step: 0,
      steps: 0,
      col: 3,
      dropList: {
        user: {},
        jihadistAction: [],
        trainingCourse: [],
        scientificDiscipline: [],
      },

      tabs: [
        { name: this.$t("globals.personal-information"), valid: true },
        { name: this.$t("globals.employment-information"), valid: true },
        { name: this.$t("globals.birth_residence"), valid: true },
        { name: this.$t("globals.user-data"), valid: true },
        { name: this.$t("globals.jihadist_actions"), valid: true },
      ],
      validList: ["form_1", "form_2", "form_3", "form_4", "form_5"],
      identifyType: [],
      genders: [],
      maritalStatus: [],
      health_situation: [],
      roles: [],
      workPeriods: [],
      qualifications: [],
      schools: [],
      countries: [],
      governorates: [],
      directorates: [],
      accommodation_types: [],
      groups_list: [],
      trainingCourse: [],
      btn_loading: false,
      loading: false,
    };
  },
  async created() {
    this.loading = true;
    await this.getIdentifyType();
    await this.getGender();
    await this.getMaritalStatus();
    await this.getHealthSituationChoice();
    await this.getRole();
    await this.GetWorkPeriodsChoices();
    await this.getQulification();
    await this.getAllSchools();
    await this.getCountries();
    await this.getGovernorates();
    await this.getDirectorates();
    await this.getAccommodations();
    await this.getGroups();
    await this.getTypeOfCourseChoice();

    if (this.id) await this.getDataById(this.id);

    this.loading = false;
  },
  methods: {
    resetForm() {
      this.dropList = {
        user: {},
        jihadistAction: [],
        trainingCourse: [],
        scientificDiscipline: [],
      };
    },
    async next(key) {
      let index = this.steps;

      const data = this.validList[index];
      const { valid } = await this.$refs[data].validate();
      if (!valid) {
        this.tabs[index].valid = false;
      } else this.tabs[index].valid = true;

      this.steps = key;
    },
    async validate() {
      for (let i = 0; i < this.validList.length; i++) {
        try {
          const v = this.validList[i];
          const { valid } = await this.$refs[v].validate();

          if (valid) this.tabs[i].valid = true;
          else {
            this.tabs[i].valid = false;
            this.step = i;
            this.steps = i;
            return;
          }
        } catch {
          this.tabs[i].valid = false;
          this.step = i;
          this.steps = i;
          return;
        }
      }

      return this.tabs.every((e) => e.valid === true);
    },

    async saveData() {
      const valid = await this.validate();
      if (valid) {
        this.btn_loading = true;
      if(this.dropList.user.username == undefined || this.dropList.user.username == null)
        delete this.dropList.user;
        await this.axios
          .post(this.base_url + "api/summer/employee", this.dropList, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
          })
          .catch((error) => {
            try {
              if ("non_field_errors" in error.response.data) {
                this.tabs[0].valid = false;
                this.step = 0;
                this.steps = 0;
                this.dropList.uniqe_name = true;
                this.btn_loading = false;
                return;
              }

              if ("job_id" in error.response.data) {
                this.tabs[1].valid = false;
                this.step = 1;
                this.steps = 1;
                this.dropList.uniqe_job_id = true;
                this.btn_loading = false;
                return;
              }

              if ("identity_id" in error.response.data) {
                this.tabs[0].valid = false;
                this.step = 0;
                this.steps = 0;
                this.dropList.uniqe_identity_id = true;
                this.btn_loading = false;
                return;
              }

              if ("user" in error.response.data) {
                if ("username" in error.response.data.user)
                  this.dropList.uniqe_user_name = true;
                if ("email" in error.response.data.user)
                  this.dropList.uniqe_user_email = true;

                this.tabs[3].valid = false;
                this.step = 3;
                this.steps = 3;
                this.btn_loading = false;
                return;
              }
            } catch {}
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.btn_loading = false;
      }
    },

    async updateData() {
      const valid = await this.validate();
      if (valid) {
        this.btn_loading = true;
      if(this.dropList.user.username == undefined || this.dropList.user.username == null)
        delete this.dropList.user;        
        await this.axios
          .put(
            this.base_url + "api/summer/employee/" + this.dropList.id,
            this.dropList,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            setTimeout(() => {
              this.$router.push({ name: "employees" });
            }, 1500);
          })
          .catch((error) => {
            try {
              if ("non_field_errors" in error.response.data) {
                this.tabs[0].valid = false;
                this.step = 0;
                this.steps = 0;
                this.dropList.uniqe_name = true;
                this.btn_loading = false;
                return;
              }

              if ("job_id" in error.response.data) {
                this.tabs[1].valid = false;
                this.step = 1;
                this.steps = 1;
                this.dropList.uniqe_job_id = true;
                this.btn_loading = false;
                return;
              }

              if ("identity_id" in error.response.data) {
                this.tabs[0].valid = false;
                this.step = 0;
                this.steps = 0;
                this.dropList.uniqe_identity_id = true;
                this.btn_loading = false;
                return;
              }

              if ("user" in error.response.data) {
                if ("username" in error.response.data.user)
                  this.dropList.uniqe_user_name = true;
                if ("email" in error.response.data.user)
                  this.dropList.uniqe_user_email = true;

                this.tabs[3].valid = false;
                this.step = 3;
                this.steps = 3;
                this.btn_loading = false;
                return;
              }
            } catch {}
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }

      this.btn_loading = false;
    },

    async getDataById(id) {
      await this.axios(`${this.base_url}/api/summer/employee/` + id, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
       

        this.dropList = { ...response.data };
      });
    },

    addJihadist() {
      if (!("jihadistAction" in this.dropList))
        this.dropList.jihadistAction = [];

      this.dropList.jihadistAction.push({
        name: null,
        authority: null,
        place: null,
        period_work: null,
        supervisor: null,
        specialization: null,
        starting_date: null,
        fulltime_ratio: null,
      });
    },
    removeJihadist(index) {
      this.dropList.jihadistAction.splice(index, 1);
    },

    addTrainingCourse() {
      if (!("trainingCourse" in this.dropList))
        this.dropList.trainingCourse = [];

      this.dropList.trainingCourse.push({
        typecourse: null,
        num: null,
      });
    },
    removeTrainingCourse(index) {
      this.dropList.trainingCourse.splice(index, 1);
    },

    addScientificDiscipline() {
      if (!("scientificDiscipline" in this.dropList))
        this.dropList.scientificDiscipline = [];

      this.dropList.scientificDiscipline.push({
        name: null,
        date: null,
        educational_entity: null,
        contury: null,
        duration: null,
      });
    },
    removeScientificDiscipline(index) {
      this.dropList.scientificDiscipline.splice(index, 1);
    },

    async getIdentifyType() {
      await this.axios(`${this.base_url}choices/get-identify-type-choice`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.identifyType = response.data;
      });
    },

    async getGender() {
      await this.axios(`${this.base_url}choices/gender`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.genders = response.data;
      });
    },
    async getMaritalStatus() {
      await this.axios(`${this.base_url}choices/marital-status`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.maritalStatus = response.data;
      });
    },
    async getHealthSituationChoice() {
      await this.axios(`${this.base_url}choices/get-health-situation-choice`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.health_situation = response.data;
      });
    },

    async getRole() {
      await this.axios(`${this.base_url}choices/role`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.roles = response.data;
      });
    },

    async GetWorkPeriodsChoices() {
      await this.axios(`${this.base_url}choices/work-periods`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.workPeriods = response.data;
      });
    },

    async getQulification() {
      await this.axios(`${this.base_url}choices/qulification`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.qualifications = response.data;
      });
    },
    async getAccommodations() {
      await this.axios(`${this.base_url}choices/accommodation-type`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.accommodation_types = response.data;
      });
    },

    async getAllSchools() {
      await this.axios(`${this.base_url}/api/get-all-shool-choices`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.schools = response.data;
      });
    },
    async getCountries() {
      await this.axios(`${this.base_url}choices/countries`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.countries = response.data;
      });
    },
    async getGovernorates() {
      await this.axios(`${this.base_url}choices/governorates`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.governorates = response.data;
      });
    },
    async getDirectorates() {
      await this.axios(`${this.base_url}choices/directorates`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.directorates = response.data;
      });
    },
    async getGroups() {
      await this.axios(`${this.base_url}groups/?user=1`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.groups_list = response.data;
      });
    },

    async getTypeOfCourseChoice() {
      await this.axios(`${this.base_url}choices/get-type-course-choice`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.trainingCourse = response.data;
      });
    },
  },
};
</script>