<template>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_activitydifficulty')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getsloutiondifcltiess"
    :create="
      () => {
          $router.push({ name: 'dicultsandslotions_add' })
      }
    "
    :pagination="pagination"
    :editItem="editData"
    :delItem="delData"
    :del_perm="CheckGetPermission('legal_school.delete_activitydifficulty',['sys_admin','summer_admin'])"
    :edit_perm="CheckGetPermission('legal_school.change_activitydifficulty',['sys_admin','summer_admin'])"
    :add_perm="CheckGetPermission('legal_school.add_activitydifficulty',['sys_admin','summer_admin'])"
  >
  </DataTable>
</template>
<script>
import useValidate from "@vuelidate/core";
export default {
  name: "LecturesList",
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updateAuthenticated",
        localStorage.getItem("token")
      );
      await this.getsloutiondifcltiess()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      v$: useValidate(),
      txt_search: null,
      loading: false,
      user: null,
      sortBy: [{ key: "id", order: "asc" }],
      selectedHead: [
        "difficulty",
        "solution",
        "activity_name",
        "start_date",
        "note",
        "actions",
      ],
      lectures_attendance: [],
      headers: [
        { title: this.$t("summer.activity.activity_name"), key: "activity_name", },
        { title: this.$t("summer.activity.difficultie"), key: "difficulty" },
        { title: this.$t("summer.activity.solutions"), key: "solution" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      perPage: 10,
      length: 0,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000000, text: this.$t("globals.all") },
      ],
      page: 1,
      totalItems: null,
      pagination: {},
    };
  },
  computed: {
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    tableList() {
      if (this.lectures_attendance) {
        return JSON.parse(JSON.stringify(this.lectures_attendance));
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },
  methods: {
    dicultsandslotions() {
      this.$router.push({ name: "dicultsandslotions_add" });
    },
    editData(data) {
      this.$router.push(`${data.id}/edit/`);
    },
    async delData(delete_id) {
      if (delete_id) {
        var status;
        this.deleteDataLoading = true;
        await this.axios
          .delete(
            `${this.base_url}api/summer/activity-difficulty/${delete_id}`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getsloutiondifcltiess(page = 1, per_page = 10, ordering = "id", search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/summer/activity-difficulty`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          sort_by: ordering,
          search: search,
          page: page,
          page_size: this.perPage,
        },
      }).then((response) => {
        this.lectures_attendance = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.totalItems = response.data.pagination.count;
        this.loading = false;
      });
    },
  },
  watch: {
    perPage() {
      this.getsloutiondifcltiess();
    },
    page() {
      this.getsloutiondifcltiess(this.page);
    },
    order_data(){
      this.getsloutiondifcltiess(this.page);
    }
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
#vDataTable span {
  font-size: 11px;
}
</style>
