<template>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_semester')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      CheckGetPermission('legal_school.delete_semester', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_semester', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :add_perm="
      CheckGetPermission('legal_school.add_semester', [
        'sys_admin',
        'gen_admin',
      ])
    "
  >
    <template v-slot:item.months_names="{ item }">
      <v-chip
        v-for="month in item?.months_names"
        :key="month"
        class="me-1"
        color="warning"
      >
        {{ month }}
      </v-chip>
    </template>
  </DataTable>
  <v-dialog v-model="addDialog" max-width="700">
    <v-card flat>
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          class=""
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <VCol cols="12">
              <VTextField
                v-model="item.name"
                :label="$t('terms.name')"
                persistent-hint
                autofocus="true"
                clearable
                density="compact"
                prepend-inner-icon="mdi-bookshelf"
                :error-messages="v$.item.name.$errors.map((e) => e.$message)"
              ></VTextField>
            </VCol>
            <VCol cols="12">
              <VTextField
                v-model="item.name_en"
                :label="$t('terms.name-en')"
                persistent-hint
                clearable
                density="compact"
                prepend-inner-icon="mdi-bookshelf"
                :error-messages="v$.item.name_en.$errors.map((e) => e.$message)"
              ></VTextField>
            </VCol>
            <DatetimePicker
              v-model="date_1"
              :calendar="'hijri'"
              :placeholder="$t('globals.year-month-day')"
              :label="$t('school.student_birthdate')"
            ></DatetimePicker>
            <DatetimePicker
              v-model="date_2"
              :calendar="'hijri'"
              :placeholder="$t('globals.year-month-day')"
              :label="$t('school.student_birthdate')"
            ></DatetimePicker>
            <VCol cols="12">
              <fieldset class="d-flex">
                <legend>الاشهر الدراسية</legend>
                <v-checkbox
                  v-for="month in hijri_months_non_choiced"
                  :value="month.id"
                  :label="month.name"
                  :key="month"
                ></v-checkbox>
              </fieldset>
              <!-- <VAutocomplete
                v-model="item.months"
                :label="$t('terms.months')"
                :items="hijri_months_non_choiced"
                item-title="name"
                item-value="id"
                multiple
                persistent-hint
                prepend-inner-icon="mdi-calendar"
                :error-messages="v$.item.months.$errors.map((e) => e.$message)"
              ></VAutocomplete> -->
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="item.note"
                clearable
                :label="$t('summer.initiative.note')"
                no-resize
                color="primary"
                counter="250"
                density="compact"
                rows="2"
                prepend-inner-icon="mdi-note-outline"
                :error-messages="v$.item.note.$errors.map((e) => e.$message)"
              ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
import useValidate from "@vuelidate/core";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      v$: useValidate(),
      date_1: null,
      date_2: null,
      addDialog: false,
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      items: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      item: {
        name: null,
        name_en: null,
        months: [],
      },

      dialogVisible: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("terms.name"), key: "name", sortable: false },
        { title: this.$t("terms.name-en"), key: "name_en", sortable: false },
        {
          title: this.$t("terms.months"),
          key: "months_names",
          sortable: false,
        },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      hijri_months_choices: (state) => state.hijri_months_choices,
    }),
    hijri_months_non_choiced() {
      const months_choiced = [];
      this.hijri_months_choices.forEach((month) => {
        if (
          [].concat(...this.items.map((item) => item.months)).includes(month.id)
        ) {
          this.items.forEach((item) => {
            if (item.months.includes(month.id)) {
              months_choiced.push({
                id: month.id,
                name: month.name,
                fk_term: item.id,
              });
            }
          });
        } else {
          months_choiced.push({
            id: month.id,
            name: month.name,
            fk_term: null,
          });
        }
      });
      if (this.item?.id) {
        return months_choiced.filter(
          (month) => month.fk_term == null || month.fk_term == this.item.id
        );
      }
      return months_choiced.filter((month) => month.fk_term == null);
    },
    ...mapGetters({}),
    tableList() {
      if (this.items) {
        return JSON.parse(JSON.stringify(this.items));
      }
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.v$.$reset();
      this.item = {};
      this.addDialog = false;
      this.is_update = false;
      this.updated_id = undefined;
    },
    editItem(subject) {
      this.item = subject;
      this.is_update = true;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveItem() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.saveDataLoading = true;
        this.item.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/semester/", this.item, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.item = {};
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error.response.data.name) {
              this.$emit(
                "warningAlert",
                this.$t("globals.error_in_repeted_data")
              );
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.getItems();
      }
    },
    async updateItem() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.editDataLoading = true;
        this.item.data_entry = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}api/summer/semester/${this.item.id}/`,
            this.item,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            this.editDataLoading = false;
            if (error.response.data.name) {
              this.$emit(
                "warningAlert",
                this.$t("globals.error_in_repeted_data")
              );
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}api/summer/semester/${delete_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/semester/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.items = response.data.results;
        this.pagination = response.data.pagination;
        // this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  validations() {
    return {
      item: {
        name: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(20)
          ),
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        name_en: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(20)
          ),
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        months: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        note: {
          maxLength: helpers.withMessage(
            this.$t("errors.max-entry"),
            maxLength(250)
          ),
        },
      },
    };
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>

<style scoped>
</style>
