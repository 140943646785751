<template>
  <v-btn    
    v-if="isIcon"
    icon
    density="comfortable"
    :disabled="disabled"
    @click="onClick"
    :color="data.color"
  >
    <template v-slot:default>
      <v-icon>{{ data.icon }}</v-icon>
      <v-tooltip activator="parent" location="top">{{ data.label }}</v-tooltip>
    </template>
  </v-btn>

  <v-btn
    v-else
    :type="is_submit?'submit':'button'"
    density="comfortable"
    :color="data.color"
    :loading="loading"
    @click="onClick"
    :append-icon="data.icon"
    :title="label"
    :variant="variant"
    :class="{'ms-2':type=='cancel'}"
  >
    <spam>{{ data.label }}</spam>
  </v-btn>
</template>
<script>
export default {
  is_submit:Boolean,
  name: "CustomBtn",
  props: {
    text: String,
    click: Function,
    text_color: String,
    cols: {
      type: String,
      default: "1",
    },
    isIcon: Boolean,
    disabled: false,
    type: String,
    variant: "elevated",
    icon: {
      type: String,
      default(props) {
        
      },
    },
    label: {
      type: String,
      default() {
        return ''
      },
    },
    color: { type: String ,
      default() {
        return 'primary'
      },
    },
  },
  data() {
    return {
      loading: false,
    };
  },
    created() {
 
  },
  methods: {
    async onClick() {
       if(this.type !='print'){
      if (this.click) {
        this.loading = true
        try {
         await this.click()
         this.loading = false 
        } catch {
          this.loading = false
          this.click()
        }
      }
      }
    },
  },
  computed:{
    data(){
      //  return {icon: this.icon,label:this.label,color:this.color}
      switch (this.type) {
          case "create":
            return { icon:"mdi-plus" ,label:this.$t('create'),color:'indigo'}
            break;
          case "add":
            return {icon: "mdi-plus",label:this.$t('add'),color:'indigo'}
            break
          case "save":
            return {icon: "mdi-content-save",label:this.$t('save'),color:'indigo'}
            break  
          case "ok":
            return {icon: "mdi-content-save",label:this.$t('ok'),color:'indigo'}
            break  
          case "update":
            return {icon: "mdi-pencil-outline",label:this.$t('update'),color:'green'}
            break
          case "export":
              return {icon: "mdi-export-variant",label:this.$t('export'),color:'grey'}
            break  
          case "additional_exports":
            return {icon: "mdi-export-variant",label:this.$t('additional_exports'),color:'grey'}
            break  
          case "del":
            return {icon: "mdi-trash-can-outline",label:this.$t('delete'),color:'red'}
            break  
          case "done":
            return {icon: "mdi-check",label:this.$t('done'),color:'indigo'}
            break  
          case "reset":
            return {icon: "mdi-broom",label:this.$t('reset'),color:'indigo'}
            break  
          case "show":
            return {icon: "mdi-eye-outline",label:this.$t('globals.show'),color:'primary'}
            break  
          case "imports":
            return {icon: "mdi-tray-arrow-down",label:this.$t('imports'),color:'grey'}
            break  
          case "print":
            return {icon: "mdi-printer",label:this.$t('globals.print'),color:'indigo'}
            break  
          case "filter":
            return {icon: "mdi-filter-variant",label:this.$t('filter'),color:'black'}
            break  
          case "cancel_filter":
            return {icon: "mdi-filter-off",label:this.$t('cancel_filter'),color:'indigo'}
            break  
          case "next":
            return {icon: "mdi-chevron-right",label:this.$t('next'),color:'indigo'}
            break  
          case "cancel":
            return {icon: "mdi-cancel",label:this.$t('cancel'),color:'white'}
            break  
          default:
            return {icon: this.icon,label:this.label,color:this.color}
         // return {icon: "mdi-cancel",label:this.$t('cancel'),color:'white'}

      
      }              
      
    }
  }
};
</script>