<template>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_daysummer')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      CheckGetPermission('legal_school.delete_daysummer', ['sys_admin'])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_daysummer', ['sys_admin', 'gen_admin'])
    "
    :add_perm="
      CheckGetPermission('legal_school.add_daysummer', ['sys_admin'])
    "
  >
    <template v-slot:item.is_holiday="{ item }">
      <v-icon v-if="item.is_holiday" color="green-darken-2">mdi-check-circle</v-icon>
      <v-icon v-else color="red-darken-2">mdi-close-circle</v-icon>
    </template>
  </DataTable>
  <v-dialog v-model="addDialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <v-form ref="form" @submit.prevent="is_update ? updateItem() : saveItem()">
          <VRow class="mt-2">
            <VCol cols="12" md="6">
              <VTextField
                v-model="day.name"
                autofocus
                prepend-inner-icon="mdi-calendar-edit"
                :label="$t('day.day')"
                :placeholder="$t('day.day_placeholder')"
                density="compact"
                type="text"
                clearable
                :rules="rules.name"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" md="6">
              <VCheckbox
                v-model="day.is_holiday"
                :label="$t('day.day_off')"
                color="primary"
              >
              </VCheckbox>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="day.note"
                hide-details="auto"
                clearable
                color="primary"
                :label="$t('globals.note')"
                counter
                no-resize
                density="compact"
                rows="2"
                :rules="rules.note"
              >
              </VTextarea>
            </VCol>
          </VRow>
        </v-form>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveItem()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateItem()" :loading="saveDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()" v-if="!is_close">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="closeForm()" v-if="is_close">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>        
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      rules: {
        name: [
          (value) => {
            if (value == null || value == undefined)
              return this.$t("globals.required_field");
            if (value.length > 20) {
              return `${this.$t("globals.max_characters")} 20`;
            }
            return true;
          },
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      saveDataLoading: false,
      day: {
        name: undefined,
        is_holiday: false,
        note: undefined,
        data_entry: undefined,
        updated_by: undefined,
        id: undefined,
      },
      days: [],
      is_update: false,
      is_close: false,
      headers: [
        { title: this.$t("day.day"), key: "name" },
        { title: this.$t("day.day_off"), key: "is_holiday" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
    };
  },
  async created() {
    await this.$store.commit("updateLoadingValue", true);
    await this.getItems();
    this.$store.commit("updateLoadingValue", false);
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    tableList() {
      if (this.days) {
        return JSON.parse(JSON.stringify(this.days));
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  methods: {
    closeForm() {
      this.addDialog = false;
      this.is_update = false;
    },
    resetForm() {
      this.$refs.form.reset();
      // this.day.id = undefined;
      this.is_close = false;
      this.is_update = false;
      // this.updated_id = undefined;
      this.day.is_holiday = false;
    },
    editItem(data) {
      this.day = Object.assign({}, data);
      this.addDialog = true;
      this.is_close = true;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        if (this.days.length < 8) {
          this.day.data_entry = this.user;
          this.saveDataLoading = true;
          await this.axios
            .post(this.base_url + "api/summer/day-summer", this.day, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((response) => {
              this.saveDataLoading = false;
              this.$emit("successAlert", this.$t("globals.data_added"));
              this.resetForm();
              this.getItems();
            })
            .catch((error) => {
              this.saveDataLoading = false;
              if (error.response && error.response.data == "overload") {
                this.$emit("errorAlert", this.$t("day.day_overload"));
              } else this.$emit("errorAlert", this.$t("globals.error_in_data"));

              if (error.response.data.name) {
                this.$emit("errorAlert", this.$t("globals.error_in_repeted_data"));
              } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            });
        } else {
          this.$emit("infoAlert", this.$t("day.day_overload"));
        }
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.day.id != undefined) {
        this.day.updated_by = this.user;
        this.saveDataLoading = true;
        await this.axios
          .put(`${this.base_url}api/summer/day-summer/${this.day.id}`, this.day, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.saveDataLoading = false;
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.resetForm();
            this.closeForm();
            this.getItems();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        await this.axios
          .delete(`${this.base_url}api/summer/day-summer/${delete_id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      await this.axios
        .get(this.base_url + "api/summer/day-summer", {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            search: search,
            page: page,
            page_size: per_page,
            sort_by: ordering,
          },
        })
        .then((response) => {
          if (response.data) {
            this.days = response.data.results;
            this.pagination = response.data.pagination;
          }
        });
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>
