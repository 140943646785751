
<template>
  <v-card class="pa-3">
    <v-card-title v-if="!show">
      {{ $t("globals.select-criteria") }}
    </v-card-title>
    <v-card-text class="mt-2">
      <v-form ref="form" v-if="!show">
        <v-row>
          <v-col cols="3" md="3" >
            <v-autocomplete             
              :items="semesters"
              v-model="filter.fk_semester"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name_ar"
              item-value="id"
              :label="$t('semester.name')"
              clearable
              persistent-hint
              density="compact"
              :rules="[$required]"
              @update:modelValue="getMonthsBySemester(), (this.filter.fk_monthsemester = null)"
            />
          </v-col>
          <v-col cols="12" md="2">
            <v-autocomplete            
              color="indigo"
              v-model="filter.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="month"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="filter.fk_level"
              :items="levels"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              hide-details="auto"
              clearable
              :rules="[$required]"
              @update:model-value="
                getLevelBySubject(), (filter.fk_levelsubject = undefined)
              "                 
            />
          </v-col>
          <v-col md="3">
            <VAutocomplete
              v-model="filter.fk_levelsubject"
              :items="subjects"
              prepend-inner-icon="mdi-book-open"
              item-title="name"
              item-value="id"
              :label="$t('subject.subject')"
              persistent-hint
              density="compact"
              clearable
            />
          </v-col>          
          <v-col cols="1" class="mt-1">
            <custom-btn type="show" :click="() => getData()" />
          </v-col>
        </v-row>
      </v-form>
      <custom-table-report
        v-else
        :close="() => reset()"
        :headers="headers"
        :items="items"
        counter
      />
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      filter: {},
      items: [],
      headers: [],
      semesters: [],
      months: [],
      levels: [],
      subjects: [],
      btn_loading: false,
      show: false,
    };
  },
  created() {
    this.getLevels();
    this.getSemester();
  },
  methods: {
    async getData() {
      this.items = [];
      const { valid } = await this.$refs.form.validate();
      if (valid)
        await this.axios(
          `${this.base_url}api/summer/report/monthly-absorption-statistics-for-materials/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              fk_monthsemester: this.months.find(
                (e) => e.month == this.filter.fk_monthsemester
              ).id,
              LevelSubject :this.filter.fk_levelsubject,
              fk_level :this.filter.fk_level,
            },
          }
        )
          .then((response) => {
            this.items = response.data.data;
            if (this.items.length > 0) {
              this.headers= response.data.header;
              this.show = true;
            } else this.$emit("infoAlert", this.$t("globals.not-found"));
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },
    async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
      const current_semester = this.semesters.find((e) => e.current_semester);
      if (current_semester) {
        this.filter.fk_semester = current_semester.id;
        this.getMonthsBySemester()
      }
    },
    async getMonthsBySemester() {
      if(this.filter.fk_semester)
      await this.axios(
        `${this.base_url}/api/months-semester/?fk_semester=${this.filter.fk_semester}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.filter.fk_monthsemester = current_month.month;
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
      else this.months=[]
    },
    async getLevelBySubject() {
      this.subjects = [];
      await this.axios(`${this.base_url}api/get-subject-by-level`, {
        params: {
          fk_level: this.filter.fk_level,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.subjects = response.data;
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },    
    reset() {
      this.show = false;
      this.items = [];
    },
  },
};
</script>