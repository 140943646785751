<template>
  <v-locale-provider :locale="locale">
    <v-row class="mt-2"  justify="center">
      <v-col cols="12"  md="4" class="mt-2">
        <v-card class="  text-center pb-4 border" >
          <v-img :src="require('@/assets/background.jpg')" cover height="100" >
            
          </v-img>
          <v-avatar  size="70" class=" mt-n9 elevation-2">
            <img :src="require('@/assets/profile.jpg')"  style="height:100%;width:100%;object-fit:cover" />
          </v-avatar>
          <h2>{{employee_data.account_data.username}}</h2>
          <h3>{{employee_data.account_data.role_name}}</h3>
        </v-card>
      </v-col>

      <v-col cols="12" md="8"  class="mt-2">
        <v-card class="border" >
          <v-card class="ma-2 pa-2">
            <VTabs
              class=" text-center"
              v-model="tab"
              color="primary"
              fixed-tabs
              align-tabs="center"
              show-arrows
              density="compact"
              slider-color="primary"
            >
              <VTab v-for="(item, index) in tabs" :key="index" :value="index" >
                <v-icon class="mx-3">{{item.icon}}</v-icon>
                {{ item.title }}
              </VTab>
            </VTabs>
          </v-card>
          <v-card-text class="my-4" :class="{ 'mt-6 mb-6': breakPointXS }">
            <v-window v-model="tab"  >
              <v-window-item :value="0">
                <h2
                  class="text-center text-h5"
                  style="font-family: 'Almarai' !important"
                >
                  {{$t('user.profile')}}
                </h2>
                <br />
                <h4 class="text-center grey--text">
                  {{ $t("user.profile-data") }}
                </h4>
                <v-row align="center" justify="center" class="mb-2 pb-2">
                  <v-col cols="12" sm="10">
                    <v-text-field
                      readonly
                      v-model="employee_data.account_data.full_name"
                      :label="$t('user.full-name')"
                      :placeholder="$t('user.full-name')"
                      dense
                      prepend-inner-icon="mdi-account"
                      variant="outlined"
                      class="mt-10"
                    >
                    </v-text-field>
                    <v-text-field
                      readonly
                      v-model="employee_data.account_data.username"
                      :label="$t('user.username')"
                      :placeholder="$t('user.username')"
                      dense
                      prepend-inner-icon="mdi-lock"
                      variant="outlined"
                    >
                    </v-text-field>
                    <v-text-field
                      readonly
                      color="primary"
                      v-model="employee_data.account_data.emial"
                      :placeholder="$t('globals.email')"
                      dense
                      variant="outlined"
                      :label="$t('globals.email')"
                      prepend-inner-icon="mdi-email"
                    >
                    </v-text-field>
                    <v-text-field
                      readonly
                      v-model="employee_data.account_data.role_name"
                      color="primary"
                      :placeholder="$t('user.role')"
                      dense
                      variant="outlined"
                      :label="$t('user.role')"
                      prepend-inner-icon="mdi-card-account-details"
                    >
                    </v-text-field>
                    <v-text-field
                      readonly
                      v-model="employee_data.account_data.governorate"
                      color="primary"
                      :placeholder="$t('globals.governorate')"
                      dense
                      variant="outlined"
                      :label="$t('globals.governorate')"
                      prepend-inner-icon="mdi-card-account-details"
                    >
                    </v-text-field>
                    <v-text-field
                      readonly
                      v-model="employee_data.account_data.directorate"
                      color="primary"
                      :placeholder="$t('globals.directorate')"
                      dense
                      variant="outlined"
                      :label="$t('globals.directorate')"
                      prepend-inner-icon="mdi-card-account-details"
                    >
                    </v-text-field>
                    <v-btn tile color="primary" dark block disabled>
                      {{ $t("globals.save") }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="1">
                <v-row align="center" justify="center" class="mt-1 mb-2 pb-2">
                  <v-col cols="12" md="10">
                    <v-alert
                      v-model="alert"
                      border="top"
                      color="white"
                      type="warning"
                      class="mb-2"
                      border-color="warning"
                      closable
                      elevation="1"
                    >
                      {{ $t("alert.warnning.change-password") }}
                    </v-alert>
                  </v-col>
                  <v-col cols="12">
                    <h2
                      class="text-center text-h5"
                      style="font-family: 'Almarai' !important"
                    >
                      {{$t('user.change-password')}}
                    </h2>
                    <br />
                    <h4 class="text-center grey--text">
                      {{ $t("user.profile-data") }}
                    </h4>
                  </v-col>
                  <v-col cols="12" sm="10" class="mt-4">
                    <v-text-field
                      color="primary"
                      v-model="user_data.old_password"
                      variant="outlined"
                      append-inner-icon="mdi-lock"
                      :type="showOldPassword ? 'text' : 'password'"
                      :label="$t('user.old-password')"
                      :error-messages="
                        v$.user_data.old_password.$errors.map((e) => e.$message)
                      "
                    >
                      <template v-slot:prepend-inner>
                        <!-- <v-btn size="small" variant="text"  :disabled="!user_data.old_password" icon density="compact" > -->
                        <v-icon
                          :color="primary"
                          @click="showOldPassword = !showOldPassword"
                          :icon="showOldPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        />
                        <!-- </v-btn> -->
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-text-field
                      color="primary"
                      v-model="user_data.password"
                      variant="outlined"
                      :type="showPassword ? 'text' : 'password'"
                      append-inner-icon="mdi-lock"
                      :label="$t('user.new-password')"
                      :error-messages="
                        v$.user_data.password.$errors.map((e) => e.$message)
                      "
                    >
                      <template v-slot:prepend-inner>
                        <!-- <v-btn size="small" variant="text"  :disabled="!user_data.password" icon density="compact" > -->
                        <v-icon
                          @click="showPassword = !showPassword"
                          :icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        />
                        <!-- </v-btn> -->
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-text-field
                      color="primary"
                      v-model="user_data.password2"
                      variant="outlined"
                      :type="showPassword2 ? 'text' : 'password'"
                      append-inner-icon="mdi-lock"
                      :label="$t('user.confirm-password')"
                      :error-messages="
                        v$.user_data.password2.$errors.map((e) => e.$message)
                      "
                    >
                      <template v-slot:prepend-inner>
                        <!-- <v-btn size="small" variant="text"  :disabled="!user_data.password2" icon density="compact" > -->
                        <v-icon
                          @click="showPassword2 = !showPassword2"
                          :icon="showPassword2 ? 'mdi-eye' : 'mdi-eye-off'"
                        />
                        <!-- </v-btn> -->
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <v-btn
                      :loading="saveProgress"
                      @click="changePassword"
                      block
                      class="bg-primary "
                      size="small"
                    >
                      <span class="text-white">
                        {{ $t("globals.save") }}
                      </span>
                      <v-icon icon="mdi-content-save" color="white" end></v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-locale-provider>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapActions, mapGetters, mapState } from "vuex";
import {
  required,
  helpers,
  maxLength,
  minLength,
  sameAs,
} from "@vuelidate/validators";
export default {
  mounted() {
    const items = [
      this.$t("user.user"),
      this.$t("user.account"),
      this.$t("user.profile"),
    ];
    this.$store.commit("updateBreadcrumbValue", items);
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getEmployeeProfile();
      if(localStorage.getItem('must_change_password')){
        this.alert=true
        this.tab=1
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      v$: useValidate(),
      alert: false,
      showPassword: false,
      showPassword2: false,
      showOldPassword: false,
      saveProgress: false,
      user_data: {
        password: null,
        password2: null,
        old_password: null,
      },
      step: 1,
      tabs: [
        { title:this.$t("user.profile"),icon:'mdi-account-circle' },
        { title:this.$t("user.change-password"),icon:'mdi-form-textbox-password' }
      ],
      tab:0,
      employee_data: {
        account_data: {},
        personal_data: {},
      },
    };
  },
  methods: {
    async getEmployeeProfile() {
      await this.axios(this.base_url + "api/summer/employee/profile", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.employee_data = response.data;
      });
    },
    ...mapActions(["logout"]),
    async changePassword() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.saveProgress = true;
        this.axios
          .post(`${this.base_url}api/user/change-password`, this.user_data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit(
              "successAlert",
              this.$t("alert.success.changed-password")
            );
            this.saveProgress = false;
            setTimeout(() => {
              this.logout()
            }, 1500);
            localStorage.removeItem('must_change_password')
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.changed-password"));
            this.saveProgress = false;
          });
      }
    },
  },
  computed: {
    personal_data_exists() {
      return Object.keys(this.employee_data.personal_data).length > 0;
    },
    locale() {
      return this.$i18n.locale;
    },
  },
  validations() {
    return {
      user_data: {
        password: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(128)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.lowest_number_of_character") + "8",
            minLength(8)
          ),
        },
        password2: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(128)
          ),
          minLength: helpers.withMessage(
            this.$t("globals.lowest_number_of_character") + "8",
            minLength(8)
          ),
          sameAs: helpers.withMessage(
            this.$t("user.not-match"),
            sameAs(this.user_data.password)
          ),
        },
        old_password: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(
            this.$t("globals.max_characters"),
            maxLength(128)
          ),
        },
      },
    };
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}


</style>