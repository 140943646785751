<template>
  <v-form ref="form">
    <v-card class="pa-2">
      <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="levels"
            v-model="filter_data.fk_level"
            prepend-inner-icon="mdi-stairs-box"
            item-title="name_ar"
            item-value="id"
            :label="$t('summer.level.name_ar')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            @update:modelValue="getDivisionByLevel(), (this.filter_data.fk_division = null,this.exist)"
            :rules="[$required]"
          ></VAutocomplete>
        </VCol>
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="divisions"
            v-model="filter_data.fk_division"
            prepend-inner-icon="mdi-shape-outline"
            item-title="name"
            item-value="fk_division"
            :label="$t('division.name')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            @update:model-value="this.exist=0"
          ></VAutocomplete>
        </VCol>
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="quranGroupChoice"
            v-model="filter_data.fk_qurangroup"
            prepend-inner-icon="mdi-group"
            item-title="name"
            item-value="id"
            :label="$t('summer-sidebar.name_groups_quran')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            :rules="[$required]"
            @update:model-value="this.exist=0"
          ></VAutocomplete>
        </VCol>
        <v-col cols="1" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="primary"
            :loading="loading"
            @click="getItems()"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-table
      density="compact"
      class="rounded elevation-1 striped-table"
      fixed-header
      v-if="exist"
    >
      <thead>
        <tr>
          <th>{{ $t("school.student_name") }}</th>
          <th>   
              <VCheckbox
                  color="primary"
                  hide-details="true"
                  v-model="check_all"
                  density="compact"
                  @update:modelValue="checkAll()"
                  :label="$t('school.is_add')"
                  >
              </VCheckbox>
          </th>
        </tr>
      </thead>
      <tbody>
          <tr v-for="(student, wIndex) in groupsQuran" :key="wIndex">
            <td> {{ student.student_name }}</td>
            <td>
              <VCheckbox
                v-model="student.is_in_qurangroup"
                hide-details
                dense
              >
              </VCheckbox>
            </td>
            <td></td>
          </tr>
      </tbody>
      <v-card-actions class="mx-2 pt-0">
        <v-btn @click="saveItem" :loading="saveLoading" class="bg-primary" size="small">
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-table>
  </v-form>
</template>
<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
import { required, helpers } from "@vuelidate/validators";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      v$: useValidate(),
      addDialog: false,
      protected_records: false,
      data_message: [],
      groupsQuran: [],
      levels: [],
      divisions: [],
      filter_data: {
        fk_level: undefined,
        groupsQuran: [],
      },
      loading: false,
      saveLoading: false,
      check_all:false,
      exist: undefined,
      dynamicHeaders: [],
      quranGroupChoice: [],
      saveDataLoading: false,
      editDataLoading: false,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    tableList() {
      if (this.groupsQuran) {
        return JSON.parse(JSON.stringify(this.groupsQuran));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getQuranGroupsChoice();
      await this.getlevels();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  validations() {
    return {
      filter_data: {
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        fk_qurangroup: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  methods: {
    async getlevels() {
      let levels = await this.axios.get(this.base_url + `api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.levels = levels.data;
    },      
    checkRow(item){
        if(!item.is_in_qurangroup){
            this.check_all = false
        }
    },
    checkAll(){
        if(this.check_all)
        this.groupsQuran.forEach(element=>{
            element.is_in_qurangroup=true
        })
        else
        this.groupsQuran.forEach(element=>{
            element.is_in_qurangroup=false
        })
    },    
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    async getQuranGroupsChoice() {
      await this.axios(`${this.base_url}api/summer/quran-groups/list/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.quranGroupChoice = response.data;
      });
    },
    async getDivisionByLevel() {
      if (this.filter_data.fk_level) {
        let levelDivisions = await this.axios.get(
          this.base_url + `api/summer/get-level-division/${this.filter_data.fk_level}`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );
        this.divisions = levelDivisions.data.division;
      }
    },
    async getItems() {
      this.exist = undefined;
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
          this.loading = true;
          await this.axios(`${this.base_url}api/students-with-qurangroup/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_level: this.filter_data.fk_level,
            fk_division: this.filter_data.fk_division,
            fk_qurangroup: this.filter_data.fk_qurangroup,
          },
        }).then((response) => {
          this.groupsQuran = response.data;
            if(this.groupsQuran.length === 0){
              this.$emit("infoAlert", this.$t("globals.not-found"));
            } else {
              this.exist = true;
            }
            this.loading = false;
        });
      }
    },
    async saveItem() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveLoading = true;
        let result = await this.axios
          .post(this.base_url + `api/students-with-qurangroup/?fk_qurangroup=${this.filter_data.fk_qurangroup}`, this.groupsQuran, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveLoading = false;
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("warningAlert", this.$t("summer.already-governorate"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveLoading = false;
          });
        this.getItems();
      }
    },
  },
};
</script>
<style></style>
