<template>
  <!-- added by samer -->
  <!-- شاشة تعيين مواهب للطلاب -->
 
 <v-card>
  <v-card-title>
      <span>{{ $t("globals.select-criteria") }}</span>
  </v-card-title>
 <v-card-text class="my-3">
   <v-row class="">
    <v-col cols="3" md="3">
            <VAutocomplete
              clearable
              :items="levels"
              v-model="filter.fk_level"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              hide-details="auto"
              @update:model-value="
                getDivisionByLevel(),
                  (filter.fk_division = undefined),
                  getData()
              "
            ></VAutocomplete>
          </v-col>
          <v-col cols="3" md="3">
            <VAutocomplete
              clearable
              :items="divisions"
              v-model="filter.fk_division"
              item-title="fk_division__name"
              item-value="fk_division__id"
              :label="$t('division.select')"
              persistent-hint
              hide-details="auto"
              prepend-inner-icon="mdi-shape-outline"
              density="compact"
              @update:model-value="getData()"
            ></VAutocomplete>
          </v-col>
  </v-row>
 </v-card-text>
 </v-card>
  <v-card v-if="exist">
    <v-card-item>
      <v-data-table-virtual :headers="headers" :items="items" :loading="loading">
        <template v-slot:headers="{ columns }">
          <tr>
            <th>#</th>
            
            <th v-for="(column, i) in columns" :key="column.key">
                            
              <v-sheet width="250" v-if="column.key == 'name'">{{
                column.title
              }}</v-sheet>

           <v-lazy v-else>
               <v-checkbox
                v-model="selected[i - 1]"
                @change="checkAll(selected[i - 1], i - 1)"
                :indeterminate="indeterminate(i - 1)"
                :label="truncateText(column.title)"
                style="width: 150px"
                hide-details
              />
           </v-lazy>
                 <v-tooltip v-if="column.title.length > 20" activator="parent" location="top">{{
                column.title
              }}</v-tooltip>
            </th>
          </tr>
        </template>
        <template v-slot:item="{ item ,index }">
          <tr>
            <td>{{ index +1}}</td>
            <td v-if="item.name">{{ item.name }}</td>
            <td v-for="(i, index) in item.talents" :key="index">
               <v-lazy>
              <v-checkbox-btn
                v-model="i.status"
                :key="index"
                @change="checked(index)"
                hide-details="true"
              />
              </v-lazy>
            </td>
          </tr>
        </template>
      </v-data-table-virtual>
    </v-card-item>
    <v-card-actions>
      <VBtnSave @click="saveData()" :loading="btn_loading" class="ma-5">
        {{ $t("globals.save") }}
      </VBtnSave>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      filter: {},

      items: [],
      selected: [],
      levels: [],
      divisions: [],
      activites: [],
      headers: [],
    
      btn_loading: false,
      loading: false,
      exist: false,
    };
  },

  async created() {
    this.loading = true;
    await this.getData();
    await this.getLevels();
    this.loading = false;
  },

  methods: {
    checkAll(value, index) {
      this.items.map((e) => {
        e.talents[index].status = value;
      });
    },
    checked(index) {
      const status = this.items.map((e) => e.talents[index].status);
      this.selected[index] = status.every((e) => e === true);
    },
    indeterminate(index) {
      const status = this.items.map((e) => e.talents[index].status);
      return status.some((e) => e === true) && !status.every((e) => e === true);
    },
    truncateText(text) {
      return text.length > 20 ? text.slice(0, 20) + ".." : text;
    },
    async getData() {
      this.loading = true;
      this.headers = [
        { title: this.$t("reportpublic.student-name"), key: "name" },
      ];

      await this.axios
        .get(`${this.base_url}/api/student-talents/`, {
          params:{
            fk_level:this.filter.fk_level,
            fk_division:this.filter.fk_division,            
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
         
        })
        .then((response) => {
          this.items = response.data.results;
          if(response.data.results.length==0) {
            this.$emit("infoAlert", this.$t("globals.not-found"));
            this.exist = false;
          } else {
            this.exist = true;
          }
          response.data.headers.forEach((e,index) => {
            this.headers.push({ title: e, key: e });
            this.checked(index)
          });
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });

      this.loading = false;
    },
     async saveData() {
        this.loading_btn = true;
        await this.axios
          .post(this.base_url + "/api/student-talents/", this.items, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));            
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });

        this.loading_btn = false;
      
    },
    async getLevels() {
      await this.axios.get(`${this.base_url}api/level-choices`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.levels = response.data
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivisionByLevel() {
      await this.axios(`${this.base_url}api/division-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        params: {
          fk_level: this.filter.fk_level,
        },
      })
        .then((response) => (this.divisions = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
 
};
</script>