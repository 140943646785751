export const themes = {
    light:{
        dark:false,
        colors:{
            'primary':'#169b88',
            'golden':'#000',
            'background':'#f4f5fa',
            'on-backround':'#2E263D',
            'surface':'#fff',
            'on-surface':'#2E263D',
            'grey-lighten-5':'#BDBDBD'
        }
    },
    dark:{
        dark:true,
        colors:{
            'primary':'#169b88',
            'golden':'#fff',
            'background':'#282828', 
            'on-backround':'#E7E3FC',
            'surface':'#363636',
            'on-surface':'#fff',
            'grey-lighten-5':'#FAFAFA'
        }
    },
}