<template>
<!-- added by samer -->
<!-- تقرير السكن -->
  <v-card class="pa-3" v-if="!show">
    <v-card-title>
      {{ $t("globals.select-criteria") }}
    </v-card-title>
    <v-card-text class="mt-2">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="3" lg="3">
            <v-autocomplete
              v-model="filter.fk_level"
              :items="levels"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"              
              clearable
              @update:model-value="
                (filter.fk_division = undefined), getDivisionByLevel()
              "
            />
          </v-col>
          <v-col cols="12" md="3" lg="3">
            <v-autocomplete
              :items="divisions"
              v-model="filter.fk_division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="fk_division"
              :label="$t('division.name')"
              clearable              
              persistent-hint
              density="compact"
            />
          </v-col>

          <v-col cols="12" md="3" lg="3">
            <v-autocomplete
              :items="rooms"
              v-model="filter.fk_centerandroom"
              prepend-inner-icon="mdi-office-building"
              item-title="name"
              item-value="id"
              :label="$t('globals.center_room')"
              clearable              
              persistent-hint
              density="compact"
            />
          </v-col>
           <v-col cols="12" md="3" lg="3">
          <v-autocomplete
            :items="quranGroupChoice"
            v-model="filter.fk_qurangroup"
            prepend-inner-icon="mdi-group"
            item-title="name"
            item-value="id"
            :label="$t('summer-sidebar.name_groups_quran')"
            clearable            
            persistent-hint
            density="compact"       
          />
        </v-col>           
        </v-row>
        <v-row>
            <v-col cols="12" md="3" lg="3">
          <v-autocomplete
            :items="groupsHousing"
            v-model="filter.fk_residential_group"
            prepend-inner-icon="mdi-shape-outline"
            item-title="name"
            item-value="id"
            :label="$t('summer-sidebar.name_groups_housing')"
            clearable            
            persistent-hint
            density="compact"
            @update:modelValue="this.exist = false"
          ></v-autocomplete>
        </v-col>

          <v-col cols="1" class="mt-2">
            <custom-btn type="show" :click="() => getData()" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
      <custom-table-report
        v-else
        :close="() => reset()"
        :headers="headers"
        :items="items"
        counter
        :title="title"
      />
</template>
<script>
export default {
  data() {
    return {
      items: [],
      headers: [],
      levels: [],
      divisions: [],
      rooms: [],
      quranGroupChoice: [],
      groupsHousing: [],

      filter: {},

      show: false,

      title: "",
    };
  },
  created() {
    this.getLevels();
    this.getRoom();
    this.getQuranGroupsChoice();
    this.getGroupsHousing();
  },
  methods: {
    async getData() {
      const { valid } = await this.$refs.form.validate();
      if (valid)
        await this.axios(
          `${this.base_url}api/summer/report/residential-students-report/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },

            params: {
              fk_level: this.filter.fk_level,
              fk_division: this.filter.fk_division,
              fk_centerandroom: this.filter.fk_centerandroom,
              fk_qurangroup: this.filter.fk_qurangroup,
              fk_residential_group: this.filter.fk_residential_group,
            },
          }
        )
          .then((response) => {
            this.items = response.data.data;
            if (this.items.length > 0) {
              this.headers = response.data.headers;
              this.title = response.data.title;
              this.show = true;
            } else this.$emit("infoAlert", this.$t("globals.not-found"));
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("  globals.error_in_data"));
          });
    },
    reset() {
      this.show = false;
      this.items = [];
    },

    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },

    async getDivisionByLevel() {
      if (this.filter.fk_level) {
        let levelDivisions = await this.axios.get(
          this.base_url +
            `api/summer/get-level-division/${this.filter.fk_level}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.divisions = levelDivisions.data.division;
      } else this.divisions = [];
    },
    async getRoom() {
      await this.axios(`${this.base_url}api/center-and-room/list/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.rooms = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getQuranGroupsChoice() {
      await this.axios(`${this.base_url}api/summer/quran-groups/list/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.quranGroupChoice = response.data;
      });
    },
    async getGroupsHousing() {
      let response = await this.axios.get(this.base_url + `api/residential-group/list/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.groupsHousing = response.data;
    },
  },
};
</script>