<template>
  <DataTable
    v-if="
      CheckGetPermission('legal_school.view_week')
    "
    :items="weeks"
    :headers="headers"
    density="compact"
    :method="getItems"
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
     CheckGetPermission('legal_school.delete_week', ['sys_admin', 'gen_admin'])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_week', ['sys_admin', 'gen_admin'])
    "
    :add_perm="
      CheckGetPermission('legal_school.add_week', ['sys_admin', 'gen_admin'])
    "
  >
  <template v-slot:item.curent_week="{item}">
    <v-icon v-if="item.curent_week" color="green-darken-2">mdi-check-circle</v-icon>
    <v-icon v-else color="red-darken-2">mdi-close-circle</v-icon>
  </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    
  >
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <v-form ref="form" @submit.prevent="is_update?updateItem():saveItem()">
          <VRow class="mt-2">
            <VCol  cols="12" md="6">
              <v-text-field
                style="min-width: 220px"
                v-model="week.statrt_week"
                disabled
                density="compact"
                prepend-inner-icon="mdi-calendar-clock-outline"
              >
              </v-text-field>
            </VCol>
            <VCol  cols="12" md="6">
              <v-text-field
                style="min-width: 220px"
                v-model="week.end_week"
                density="compact"
                prepend-inner-icon="mdi-calendar-clock-outline"
                disabled
              >
              </v-text-field>
            </VCol>
            <VCol  cols="12" md="6">
                <VAutocomplete
                  :items="week_list"
                  v-model="week.name"
                  item-title="name"
                  item-value="id"
                  :label="$t('summer.calendar.week-name')"
                  persistent-hint
                  prepend-inner-icon="mdi-calendar-week"
                  density="compact"
                  disabled
                ></VAutocomplete>
            </VCol>
            <VCol cols="12"  md="6">
                <VRadioGroup
                  inline
                  :label="$t('summer.calendar.is-it-the-current-week')"
                  v-model="week.curent_week"
                >
                  <VRadio :label="$t('globals.yes')" :value="true"></VRadio>
                  <VRadio :label="$t('globals.no')" :value="false"></VRadio>
                </VRadioGroup>
            </VCol>
            <VCol cols="12">
                <VTextarea
                  v-model="week.note"
                  clearable
                  :label="$t('summer.initiative.note')"
                  no-resize
                  counter="250"
                  density="compact"
                  rows="2"
                  :rules="rules.note"
                  prepend-inner-icon="mdi-note-outline"
                ></VTextarea>
            </VCol>
          </VRow>
        </v-form>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{
    DataTable
  },
  data() {
    return {
      addDialog:false,
      saveDataLoading:false,
      deleteProgress:false,
      weeks: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },      
      weeks: [],
      week: {}, 
      rules: {
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      is_update: false,
      updated_id: undefined,
      headers: [
        { title: this.$t("summer.calendar.academic-year"), key: "year_name" },
        { title: this.$t("summer.calendar.week-name"), key: "week_name" },
        { title: this.$t("summer.calendar.is-it-the-current-week"), key: "curent_week" },
        { title: this.$t("summer.calendar.start-date-of-the-week"), key: "statrt_week" },
        { title: this.$t("summer.calendar.end-date-of-the-week"), key: "end_week" },
        { title: this.$t("globals.note"), key: "note", sortable: false,show:false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      week_list: (state) => state.summer_globals.week,
    }),    
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.getWeekChoises();
      this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getWeekChoises: "summer_globals/getWeek",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.week = {};
      this.addDialog = false;
      this.is_update = false;
      this.updated_id = undefined;
    },
    editItem(item) {
      this.week = Object.assign({},item);
      this.is_update = true;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        let week = {
          curent_week: this.week.curent_week,
          note: this.week.note,
          data_entry: this.user,
          fk_year: localStorage.getItem("current_year"),
        };
        this.week.data_entry = this.user;
        this.week.fk_year = localStorage.getItem("current_year");
        this.saveDataLoading=true
        let result = await this.axios
          .put(this.base_url + "api/summer/week/" + this.week.id, week, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.resetForm();
            this.getItems();
            this.saveDataLoading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;

          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        this.deleteProgress=true
        var status;
        let result = await this.axios
          .delete(this.base_url + "api/summer/week/" + delete_id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            status = true;
          })
          .catch((error) => {
            status = error
          });
        return status
      }
    },
    async getItems(page = 1,per_page=10,ordering='id',search=null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/week`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.weeks = response.data.results;
        this.pagination = response.data.pagination;
      });
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>
