<template>
  <DataTable
    v-if="CheckGetPermission('globaltable.view_yearofstudy')"
    :items="academicYears"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm=" CheckGetPermission('globaltable.delete_yearofstudy', ['sys_admin','gen_admin']) "
    :edit_perm="
      CheckGetPermission('globaltable.change_yearofstudy', ['sys_admin','gen_admin'])
    "
    :add_perm="
      CheckGetPermission('globaltable.add_yearofstudy', ['sys_admin','gen_admin'])
    "
  >
  <template v-slot:item.curentyear="{item}">
    <v-icon v-if="item.curentyear" color="green-darken-2">mdi-check-circle</v-icon>
    <v-icon v-else color="red-darken-2">mdi-close-circle</v-icon>
  </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    
  >
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <v-form ref="form" @submit.prevent="is_update?updateItem():saveItem()">
              <v-row class="mt-2">
                <v-col cols="12" md="4">
                  <v-text-field
                    color="primary"
                    v-model="academicYear.year"
                    :placeholder="$t('summer.year.placeholder.year-h')"
                    counter="5"
                    autofocus
                    prepend-inner-icon="mdi-calendar-range"
                    :label="$t('year.name-h')"
                    :rules="rules.year"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field
                    color="primary"
                    v-model="academicYear.year_m"
                    clearable
                    counter="5"
                    :placeholder="$t('summer.year.placeholder.year-m')"
                    density="compact"
                    prepend-inner-icon="mdi-calendar-range"
                    :label="$t('year.name-m')"
                    :rules="rules.year_m"
                  />
                </v-col>
                <v-col cols="12" md="4">
                  <v-checkbox
                    :disabled="update && academicYear.curentyears"
                    v-model="academicYear.curentyear"
                    :label="$t('year.current-year')"
                    color="primary"
                    @change="checkMustBeTrue"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    color="primary"
                    v-model="academicYear.note"
                    hide-details="auto"
                    density="compact"
                    counter="250"
                    rows="2"
                    clearable
                    prepend-inner-icon="mdi-note-outline"
                    :label="$t('globals.note')"
                    :rules="rules.note"
                  />
                </v-col>
              </v-row>
            </v-form>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()" v-if="!is_close">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="closeForm()" v-if="is_close">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import Datetime from "vue3-datetime-js";
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  name: "AcademicYear",
  components: {
    Datetime,
    DataTable,
  },
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      rules: {
        year_m: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value?.length <= 5) ||
            `${this.$t("globals.biggest_number_of_character")} 99999`,
          (value) =>
            (value && /^[0-9]+$/.test(value)) ||
            this.$t("globals.must_be_numbers"),
        ],
        year: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value?.length <= 5) ||
            `${this.$t("globals.biggest_number_of_character")} 99999`,
          (value) =>
            (value && /^[0-9]+$/.test(value)) ||
            this.$t("globals.must_be_numbers"),
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      is_close:false,
      is_update:false,
      academicYears: [],
      saveDataLoading: false,
      editDataLoading: false,
      addDialog:false,
      user: null,
      current_year: null,
      selectedHead: [
        "year",
        "year_m",
        "curentyear",
        this.checkrole(["sys_admin", "gen_admin"]) ? "actions" : null,
      ],
      selectedAcademicYear: null,
      academicYear: {
        current_year:false
      },
      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("year.name-h"), key: "year" },
        { title: this.$t("year.name-m"), key: "year_m" },
        { title: this.$t("year.current-year"), key: "curentyear" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
    };
  },
  computed: {
    years() {
      return this.academicYears.map((item) => item.year.split("/")[0]);
    },
    years_m() {
      return this.academicYears.map((item) => item.year_m.split("/")[0]);
    },

    ...mapState({
      // academicYears: (state) => state.school.academic_years,
    }),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },

  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      // getItems: "school/getItems",
    }),
    async getCurrentYear() {
      await this.axios
        .get(`${this.base_url}/academic-years/current-year/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          localStorage.setItem("current_year", res.data.id);
        });
    },
    
    editItem(item) {
      this.academicYear = Object.assign({}, item);
      this.is_close = true;
      this.is_update = true;
      this.addDialog = true;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.academicYear.data_entry = this.user;
        this.axios
          .post(`${this.base_url}academic-years/`, this.academicYear, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("alert.success.insert"));
            this.resetForm();
            this.getItems();
            this.getCurrentYear();
            this.saveDataLoading = false;
          })
          .catch((error) => {
            if (error.message.includes("Network Error")) {
              this.$emit("errorAlert", this.$t("alert.failure.connect"));
            } else {
              this.$emit("errorAlert", this.$t("alert.failure.insert"));
            }
            if (error.response.data.year_m) {
              this.$emit("warningAlert", this.$t("summer.already-year"));
            }
            if (error.response.data.year) {
              this.$emit("warningAlert", this.$t("summer.already-year-m"));
            }
            this.saveDataLoading = false;
          });
      }
    },
    closeForm() {
      this.addDialog=false;
    },
    resetForm() {
      this.is_update = false;
      this.is_close = false;
      this.academicYear.curentyear = false;
      this.$refs.form.reset();
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid&&this.academicYear.id) {
        this.editDataLoading = true;
        this.academicYear.updated_by = this.user;
        await this.axios
          .put(`${this.base_url}academic-years/${this.academicYear.id}/`, this.academicYear, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.editDataLoading = false;
            this.update = false;
            this.$emit("successAlert", this.$t("alert.success.update"));
            this.getItems();
            this.resetForm();
            this.closeForm();
            this.getCurrentYear();
          })
          .catch((error) => {
            this.editDataLoading = false;
            if (error.response.data.year) {
              this.$emit("warningAlert", this.$t("summer.already-year"));
            } else if (error.response.data.year_m) {
              this.$emit("warningAlert", this.$t("summer.already-year-m"));
            } else this.$emit("errorAlert", this.$t("alert.failure.update"));
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        await this.axios
          .delete(`${this.base_url}academic-years/${delete_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status
      }
    },
    async getItems(page = 1,per_page = 10,ordering = 'id',search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}academic-years-for-data-table/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.academicYears = response.data.results;
        this.pagination = response.data.pagination;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>

<style scoped>
.row-hover:hover {
  background: #d4d3d3;
}
</style>
