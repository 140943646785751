<template>
  <v-card>
    <v-card-title class="px-4">
      <span>{{ $t("globals.select-criteria") }}</span>
    </v-card-title>
    <v-card-text>
      <v-row class="py-2">
        <VCol
          cols="4"
          v-if="
            CheckGetPermission('globaltable.view_governorate', ['sys_admin', 'gen_admin'])
          "
        >
          <VAutocomplete
            :items="summer_schools"
            v-model="document.fk_summer"
            prepend-inner-icon="mdi-domain"
            item-title="name_ar"
            item-value="id"
            :label="$t('globals.summer-centre')"
            persistent-hint
            density="compact"
            hide-details="auto"
            clearable
            @update:modelValue="getItems()"
          ></VAutocomplete>
        </VCol>
        <VCol cols="3">
          <VAutocomplete
            :items="typedocuments"
            v-model="document.fk_type_document"
            prepend-inner-icon="mdi-flag"
            item-title="name"
            item-value="id"
            :label="$t('document.type')"
            persistent-hint
            density="compact"
            hide-details="auto"
            clearable
            @update:modelValue="getItems()"
          ></VAutocomplete>
        </VCol>
        <VCol cols="4">
          <DatetimePicker
            v-model="document.date"
            forma="dd"
            :label="$t('financial.date')"
            @update:modelValue="getItems()"
          />
        </VCol>
      </v-row>
    </v-card-text>
  </v-card>
  <DataTable
    v-if="CheckGetPermission('globaltable.view_governorate')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      CheckGetPermission('globaltable.delete_governorate', ['sys_admin', 'summer_admin'])
    "
    :edit_perm="
      CheckGetPermission('globaltable.change_governorate', ['sys_admin', 'summer_admin'])
    "
    :add_perm="
      CheckGetPermission('globaltable.add_governorate', ['sys_admin', 'summer_admin'])
    "
  >
    <template v-slot:item.file_url="{ item }">
      <!-- <template v-if="isImage(item.file_url)"> -->
      <a :href="item.file_url" :download="item.file_url">
        <v-icon>mdi-download</v-icon>
        <!-- <img 
        :src="item.file_url" 
        width="50" 
        style="cursor: pointer"
        />-->
      </a>
      <!-- </template>
      <template v-else>
        <v-icon>mdi-file</v-icon>
      </template> -->
    </template>
  </DataTable>
  <v-dialog v-model="addDialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="is_update ? updateItem() : saveItem()">
          <VRow class="mt-2">
            <VCol cols="6">
              <VTextField
                v-model="groupQuran.name"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('globals.name')"
                density="compact"
                :placeholder="$t('globals.name')"
                :rules="[$ar_letters_only, $required, $max_length(50)]"
              >
              </VTextField>
            </VCol>
            <VCol cols="6">
              <VAutocomplete
                :items="typedocuments"
                v-model="groupQuran.fk_type_document"
                prepend-inner-icon="mdi-flag"
                item-title="name"
                item-value="id"
                :label="$t('document.type')"
                persistent-hint
                density="compact"
                :rules="[$required]"
              ></VAutocomplete>
            </VCol>
            <VCol cols="6">
              <VAutocomplete
                :items="employees"
                v-model="groupQuran.fk_emplyee_sender"
                prepend-inner-icon="mdi-flag"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.employee-name')"
                persistent-hint
                density="compact"
                :rules="[$required]"
              ></VAutocomplete>
            </VCol>
            <VCol cols="6">
              <VTextField
                v-model="groupQuran.receiver"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('summer.curriculum.reciever_name')"
                density="compact"
                :placeholder="$t('summer.curriculum.reciever_name')"
                :rules="[$ar_letters_only, $required, $max_length(50)]"
              >
              </VTextField>
            </VCol>
            <VCol class="pt-0 px-3" cols="6">
              <v-select
                v-model="groupQuran.fk_monthsemester"
                :label="$t('semester.months')"
                persistent-hint
                item-title="month_name"
                item-value="id"
                clearable
                density="compact"
                :items="monthsSemester"
                prepend-inner-icon="mdi-account"
              ></v-select>
            </VCol>
            <VCol class="pt-0 px-3" cols="6">
              <VCardItem class="pa-0">
                <VFileInput
                  v-model="groupQuran.file_url"
                  :label="$t('document.add')"
                  density="compact"
                  :messages="
                    $t('document.current_document') +
                    ':' +
                    decodeURI(groupQuran.file_text).split('/').pop()
                  "
                  ref="imageInput"
                  @input="selectImage"
                  :rules="requireds"
                >
                </VFileInput>
              </VCardItem>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveItem()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateItem()" :loading="saveDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()" v-if="!is_update">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="closeForm()" v-if="is_update">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      groupsQuran: [],
      employees: [],
      typedocuments: [],
      summer_schools: [],
      monthsSemester: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      document: {},
      groupQuran: {},
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("globals.name"), key: "name" },
        { title: this.$t("document.type"), key: "type_document_name" },
        { title: this.$t("globals.employee-name"), key: "emplyee_sender_name" },
        { title: this.$t("summer.curriculum.reciever_name"), key: "receiver" },
        { title: this.$t("globals.upload-date"), key: "upload_date" },
        { title: this.$t("document.the_document"), key: "file_url" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    requireds() {
      if (this.is_update == false)
        return [(value) => !!value || this.$t("globals.required_field")];
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.groupsQuran) {
        return JSON.parse(JSON.stringify(this.groupsQuran));
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getEmployees();
      await this.getSummerSchools();
      await this.getTypeDocument();
      await this.getMonthsSemester();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    isImage(fileUrl) {
      const extenction = fileUrl.split(".").pop().toLowerCase();
      return ["jpg", "jpeg", "png", "gif"].includes(extenction);
    },
    async getSummerSchools() {
      await this.axios
        .get(`${this.base_url}api/summer/school-select-list`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.summer_schools = response.data;
        });
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    closeForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
      this.addDialog = false;
    },
    editItem(data) {
      this.groupQuran = Object.assign({}, data);
      this.groupQuran.file_text = this.groupQuran.file_url;
      this.groupQuran.file_url = null;
      this.is_update = true;
      this.updated_id = data.id;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async getEmployees() {
      await this.axios(`${this.base_url}api/summer/select-employee`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.employees = response.data;
      });
    },
    async getTypeDocument() {
      await this.axios(`${this.base_url}api/type-summer-document-select/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.typedocuments = response.data;
      });
    },
    async getMonthsSemester() {
      this.loading = true;
      await this.axios(`${this.base_url}api/months-semester/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.monthsSemester = response.data;
        this.groupQuran.fk_monthsemester = response?.data?.results.find(
          (e) => e.current_month
        ).id;
        this.loading = false;
      });
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/summer-documents/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          fk_type_document: this.document.fk_type_document,
          date: this.document.date,
          fk_summer: this.document.fk_summer,
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      }).then((response) => {
        this.groupsQuran = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        if (!this.groupQuran.file_url) {
          this.$emit("warningAlert", "يجب اختيار ملف");
          return;
        }
        this.saveDataLoading = true;
        this.groupQuran.file_url = this.groupQuran.file_url[0];
        await this.axios
          .post(this.base_url + "api/summer-documents/", this.groupQuran, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.file_url) {
              this.$emit("warningAlert", this.$t("summer.can-not-upload"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.groupQuran.id != undefined) {
        this.editDataLoading = true;
        if (this.groupQuran.file_url) {
          this.groupQuran.file_url = this.groupQuran.file_url[0];
        }
        await this.axios
          .put(
            `${this.base_url}api/summer-documents/${this.groupQuran.id}/`,
            this.groupQuran,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
            this.closeForm();
          })
          .catch((error) => {
            if (error.response.data.name) {
              this.$emit("errorAlert", this.$t("summer.already-governorate"));
            } else this.$emit("warningAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        await this.axios
          .delete(`${this.base_url}api/summer-documents/${delete_id}/`, {
            "Content-Type": "multipart/form-data",
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>
<style></style>
