<template>
  <div class="border-sm">
    <v-col>
      <v-row>
        <div class="text-start mt-6" >
          <v-col>
            <!-- <h5 class="text-center">الجمهورية اليمنية</h5>
            <h5 class="text-center">وزارة الإرشاد وشؤون الحج والعمرة</h5>
            <h5 class="text-center">الأكاديمية العليا للقرآن الكريم وعلومه</h5>
            <h5 class="text-center">كلية القرآن الكريم وعلومه</h5>
            <h5 class="text-center">المركز الرئيسي</h5> -->
          </v-col>
        </div>
        <v-col class="text-center mt-4">
          <p style="font-size:30px; height: 5mm; ">﷽</p>
          <img :src="require('@/assets/12.jpg')" alt="" height="100" width="150"  />
        </v-col>
        <div style="250" class="text-center mt-4">
          <v-col>

            <!-- <h5>Republic of Yemen</h5>
            <h5>Ministry of Guidance</h5>
            <h5>Hajj and Umrah matters</h5>
            <h5>the Supreme Academy of the Holy</h5>
            <h5>Quran and its Sciences</h5>
            <h5>College of the Holy Quran</h5>
            <h5>MAIN CENTER</h5> -->
          </v-col>
        </div>
      </v-row>
      <h3 class="text-center mt-3">{{ title }}</h3>
      <h4 class="text-center mt-3">{{ sub_title }}</h4>
      
    </v-col>
  </div>
</template>
<script>
// import logo from '@/assets/12.png'
export default {
  props:{
    title:{
      type:String,
      default(){
        return '';
      }
    },
    sub_title:{
      type:String,
      default(){
        return '';
      }
    }
    },  
  data() {
    return {
      mainImage:null,     
    }
  },
}
</script>