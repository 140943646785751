<template>
  <!-- added by samer -->
  <!-- شاشة تنفيذ البرامج التربوية -->
  <v-form ref="form">
    <v-card class="pa-5">
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            color="indigo"
            v-model="dropList.fk_monthsemester"
            density="compact"
            item-title="month_name"
            item-value="month"
            prepend-inner-icon="mdi-calendar-month"
            :items="months"
            :label="$t('month.select')"
            :rules="[$required]"
            @update:model-value="getCurrentMonth"
          />
        </v-col>
        <v-col cols="3">
          <DatetimePicker
            v-if="show"
            v-model="dropList.date"
            :minDate="minDate"
            :maxDate="maxDate"
            :rules="[$required]"
            :disabled="dropList.fk_monthsemester ? false : true"
            @update:model-value="items = []"
            forma="dd"
            readOnly
          />
        </v-col>

        <v-col cols="3">
          <v-autocomplete
            v-model="dropList.iteration"
            :items="iterations"
            item-title="name"
            item-value="id"
            :label="$t('globals.iteration')"
            persistent-hint
            prepend-inner-icon="mdi-refresh"
            density="compact"
            clearable
            :rules="[$required]"
            @update:model-value="items = []"
          />
        </v-col>
        <v-btn
          :text="$t('globals.show')"
          color="primary"
          @click="getData"
          density="comfortable"
          append-icon="mdi-presentation"
          class="mt-2"
          :loading="btn_loading"
        />
      </v-row>
    </v-card>
  </v-form>
  <v-card :loading="loading" class="mt-3">
    <v-card-text>
      <v-form ref="form_save">
        <v-data-table-virtual
          v-if="CheckGetPermission('legal_school.view_managmenttask')"
          :items="items"
          :headers="headers"
          density="compact"
        >
          <template v-slot:item.is_done="{ item }">
            <v-checkbox-btn v-model="item.is_done" />
          </template>

          <template v-slot:item.from_time="{ item }">
            <v-text-field
              v-model="item.from_time"
              type="time"
              :rules="[item.is_done ? $required : []]"
              :disabled="item.is_done ? false : true"
            />
          </template>

          <template v-slot:item.from_date="{ item }">
            <div style="width:160px; !important">
              <datetime-picker
                v-model="item.from_date"
                calendar="hijri"
                :disabled="item.is_done ? false : true"
                :rules="[item.is_done ? $required : []]"
              />
            </div>
          </template>

          <template v-slot:item.to_time="{ item }">
            <v-text-field
              v-model="item.to_time"
              type="time"
              :rules="[item.is_done ? $required : []]"
              :disabled="item.is_done ? false : true"
            />
          </template>
          <template v-slot:item.to_date="{ item }">
            <div style="width:160px; !important">
              <datetime-picker
                v-model="item.to_date"
                calendar="hijri"
                :disabled="item.is_done ? false : true"
                :rules="[item.is_done ? $required : []]"
              />
            </div>
          </template>

          <template v-slot:item.number_of_attendees="{ item }">
            <v-text-field
              v-model="item.number_of_attendees"
              type="number"
              style="width: 120px"
              :disabled="item.is_done ? false : true"
              :rules="[$max_value(1000)]"
            />
          </template>
          <template v-slot:item.number_of_absentees="{ item }">
            <v-text-field
              v-model="item.number_of_absentees"
              type="number"
              style="width: 120px"
              :disabled="item.is_done ? false : true"
              :rules="[$max_value(1000)]"
            />
          </template>
          <template v-slot:item.note="{ item }">
            <v-text-field
              v-model="item.note"
              style="width: 150px"
              :disabled="item.is_done ? false : true"
              :rules="[$max_length(250)]"
            />
          </template>
        </v-data-table-virtual>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="items.length > 0"
        :text="$t('globals.save')"
        class="bg-primary ma-3"
        @click="saveData"
        :loading="save_loading"
      />
    </v-card-actions>
  </v-card>
</template>
<script>
import DataTable from "@/components/Globals/DataTable.vue";
import moment from "moment-hijri";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      show: true,
      items: [],
      iterations: [],
      months: [],
      loading: false,
      save_loading: false,
      btn_loading: false,
      dropList: {},
      minDate: null,
      maxDate: null,
    };
  },
  created() {
    this.getIterations();
    this.getMonths();
  },

  methods: {
    getCurrentMonth() {
      this.dropList.date = undefined
      if(this.dropList.fk_monthsemester){
        this.items = []
        this.show = false;
        this.dropList.date = null;
        const month =
          this.dropList.fk_monthsemester < 10
            ? "0" + this.dropList.fk_monthsemester.toString()
            : this.dropList.fk_monthsemester;
        const now = moment();
        const startOfMonth = now.iYear() + "-" + month + "-01";
        const endOfMonth = moment(`${now.iYear()}-${ this.dropList.fk_monthsemester +1 }-01`,'iYYYY-iM-iDD').subtract(1,'day').format('iYYYY-iMM-iDD')
        this.minDate = startOfMonth;
        this.maxDate = endOfMonth;
        if (this.months.find((e) =>e.month == this.dropList.fk_monthsemester && e.current_month == true)&& this.dropList.fk_monthsemester == moment().format("iM"))
          this.dropList.date = moment().format("iYYYY-iMM-iDD")
        else 
        this.dropList.date = this.minDate;
        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
    async getData() {
      if (this.dropList.fk_monthsemester) {
        const { valid } = await this.$refs.form.validate();
        if (valid) {
          this.loading = true;
          this.btn_loading = true;
          await this.axios(
            `${this.base_url}/api/education-program-operation/`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              params: {
                iteration: this.dropList.iteration,
                responsible: this.dropList.responsible,
                date: this.dropList.date,
              },
            }
          )
            .then((response) => {
              this.items = response.data.data;
            })
            .catch((e) => {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            });
        }
      }

      this.loading = false;
      this.btn_loading = false;
    },
    async getIterations() {
      await this.axios(`${this.base_url}/choices/activity-iterations`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.iterations = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.dropList.fk_monthsemester = current_month.month;
            this.getCurrentMonth();
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async saveData() {
      const { valid } = await this.$refs.form_save.validate();

      if (valid) {
        this.save_loading = true;
        this.loading = true;
        const fk_monthsemester = this.months.find(
          (e) => e.month == this.dropList.fk_monthsemester
        );

        const data = this.items.map((e) => {
          return {
            ...e,
            fk_monthsemester: fk_monthsemester.id,
          };
        });

        await this.axios
          .post(`${this.base_url}/api/education-program-operation/`, data, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((e) => {
            this.getData();
            this.$emit("successAlert", this.$t("globals.data_updated"));
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.save_loading = false;
        this.loading = false;
      }
    },
  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    headers() {
      return [
        {
          title: this.$t("globals.program"),
          key: "education_name",
          sortable: false,
        },
        { title: this.$t("globals.executed"), key: "is_done" },
        { title: this.$t("globals.from"), key: "from_time" },
        { title: "", key: "from_date" },
        { title: this.$t("globals.to"), key: "to_time" },
        { title: "", key: "to_date" },
        {
          title: this.$t("globals.number_of_attendees"),
          key: "number_of_attendees",
        },
        {
          title: this.$t("globals.number_of_absentees"),
          key: "number_of_absentees",
        },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ];
    },
  },
};
</script>

