<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      sm="6"
      v-if="CheckGetPermission('legal_school.view_studentsummer')"
    >
      <v-card class="px-2 pt-1 my-4">
        <div style="height: 150px">
          <chart :option="option_students" autoresize />
        </div>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="6"
      sm="6"
      v-if="CheckGetPermission('legal_school.view_employee')"
    >
      <v-card class="px-2 pt-1 my-4">
        <div style="height: 150px">
          <chart :option="option_teachers" autoresize />
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
const colors = ["#1098F7", "#80ced7"];
const legend = {
  show: true,
  top: "10px",
  left: "left",
  orient: "vertical",
  textStyle: { fontFamily: "Almarai", fontSize: "0.9rem" },
};
const tooltip = {
  trigger: "item",
  formatter: function (params) {
    var name = params.seriesName;
    var percent = params.percent;
    var value = params.value;
    return name + "<br/>" + value + "(" + percent * 2 + "%)";
  },
};
export default {
  name: "Home",
  async created() {
    setTimeout(() => {
      this.drawStudentsPieChart();
      this.drawTeachersPieChart();
    }, 1000);
  },
  props: {
    students: {
      type: Object,
      required: true,
    },
    teachers: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      series_students: [],
      series_teachers: [],
      option_students: {},
      option_teachers: {},
    };
  },
  methods: {
    drawStudentsPieChart() {
      this.series_students = [
        {
          top: "50px",
          type: "pie",
          radius: ["80%", "150%"],
          name: this.$t("globals.students"),
          center: ["50%", "70%"],
          startAngle: 180,
          data: [
            {
              value: this.students["allStudentMale"],
              name: this.$t("globals.male"),
            },
            {
              value: this.students["allStudentMaleFemale"],
              name: this.$t("globals.female"),
            },
            {
              value:
                this.students["allStudentMale"] +
                this.students["allStudentMaleFemale"],
              itemStyle: {
                color: "none",
                decal: {
                  symbol: "none",
                },
              },
              label: {
                show: false,
              },
            },
          ],
          label: {
            show: true,
            formatter: "{b}:({c})",
            textStyle: {
              fontFamily: "Almarai",
              fontSize: "0.9rem",
              
            },
          },
        },
      ];

      this.option_students = {
        title: [
          {
            text: this.$t("dashboard.students-statistics"),
            left: "center",
            textStyle: {
              fontFamily: "Almarai",
              fontSize: "1.1rem",
              color:'#169b88'
            },
          },
        ],
        legend: legend,
        toolbox: {
          feature: {
            saveAsImage: {
              title: this.$t("dashboard.save-as-png"),
            },
          },
        },
        series: this.series_students,
        tooltip: tooltip,
      };
    },
    drawTeachersPieChart() {
      this.series_teachers = [
        {
          top: "50",
          type: "pie",
          radius: ["80%", "150%"],
          name: this.$t("globals.teachers"),
          center: ["50%", "70%"],
          startAngle: 180,
          data: [
            {
              value: this.teachers["allEmployeeMale"],
              name: this.$t("globals.male"),
            },
            {
              value: this.teachers["allEmployeeMaleFemale"],
              name: this.$t("globals.female"),
            },
            {
              value:
                this.teachers["allEmployeeMale"] +
                this.teachers["allEmployeeMaleFemale"],
              itemStyle: {
                color: "none",
                decal: {
                  symbol: "none",
                },
              },
              label: {
                show: false,
              },
            },
          ],
          label: {
            show: true,
            formatter: "{b}:({c})",
            textStyle: {
              fontFamily: "Almarai",
              fontSize: "0.9rem",
            },
          },
        },
      ];
      this.option_teachers = {
        title: [
          {
            text: this.$t("dashboard.teachers-statistics"),
            left: "center",
            textStyle: {
              fontFamily: "Almarai",
              fontSize: "1.1rem",
              color:'#169b88'
            },
          },
        ],
        toolbox: {
          feature: {
            saveAsImage: {
              title: this.$t("dashboard.save-as-png"),
            },
          },
        },
        series: this.series_teachers,
        legend: legend,
        tooltip: tooltip,
      };
    },
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  watch: {
    students() {
      this.drawStudentsPieChart();
      this.drawTeachersPieChart();
    },
    teachers() {
      this.drawStudentsPieChart();
      this.drawTeachersPieChart();
    },
  },
};
</script>

