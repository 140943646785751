<template>
  <v-form  ref="form">
    <v-card class="pa-2">
      <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="levels"
            v-model="filter_data.fk_level"
            prepend-inner-icon="mdi-stairs-box"
            item-title="level_with_phase_name"
            item-value="id"
            :label="$t('summer.level.name_ar')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            :rules="[$required]"
            @update:modelValue="(this.exist=false)"
          ></VAutocomplete>
        </VCol>
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="dynamicHeaders"
            v-model="filter_data.typesubject"
            prepend-inner-icon="mdi-video"
            item-title="name"
            item-value="id"
            :label="$t('subject.type_visual_subjects')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            :rules="[$required]"
            @update:modelValue="(this.exist=false)"
          ></VAutocomplete>
        </VCol>
        <v-col cols="1" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="primary"
            :loading="loading"
            @click="getItems(),getVisualSubjects()"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-table
      density="compact"
      class="rounded elevation-1 striped-table"
      fixed-header
      :height="months?.length > 10 ? 400 : null"
      v-if="exist"
    >
      <thead>
        <tr>
          <th>{{ $t("month.months") }}</th>
          <th>{{ $t("summer.calendar.week") }}</th>
          <th v-if="CheckGetPermission('legal_school.add_visualsubject',['sys_admin','gen_admin'])">{{ $t("subject.add_sub") }}</th>
          <!-- <template v-for="month in groupsQuran" :key="month.month_name">
            <template v-for="(week, wIndex) in month.weeks" :key="wIndex">
              <th v-for="subject in week.subjects" :key="subject.subject_id">{{ $t("subject.name") }}</th>
            </template>
          </template> -->
          <th v-for="number in subjects_count" :key="number">{{$t("subject.name")}}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="month in groupsQuran" :key="month.month_name">
          <tr v-for="(week, wIndex) in month.weeks" :key="wIndex">
            <td v-if="wIndex === 0" :rowspan="month.weeks.length" style="width:10%">
              {{ month.month_name }}
            </td>
            <td style="width:10%">{{ week.week_name }}
            </td>
            <td style="border-bottom:none;width:8%" v-if="CheckGetPermission('legal_school.add_visualsubject',['sys_admin','gen_admin'])">
              <VIcon color="primary" @click="addNote(month,week)">mdi-plus-circle</VIcon>
            </td>  
            <!-- <template v-for="number in subjects_count" :key="number"> -->
              <template v-for="subject in week.subjects" :key="subject.subject_id">
                <td style="width:100px">
                    <!-- v-model="subject[header.name]"
                    @input="validUnique(subject[header.name]),subject[header.name] == '' ? (subject[header.name] = null) : ''" -->
                  <v-text-field
                    v-model="subject.name"                  
                    class="mt-1"
                    density="compact"
                    type="text"
                    variant="underlined"
                    :disabled="!CheckGetPermission('legal_school.add_visualsubject',['sys_admin','gen_admin'])"
                    :rules="[$max_length(50)]"
                    hide-details="auto"
                  >
                  </v-text-field>
                </td>
              </template>
            <!-- </template> -->          
          </tr>
        </template>
      </tbody>
      <v-card-actions class="mx-2 pt-0" v-if="CheckGetPermission('legal_school.add_visualsubject',['sys_admin','gen_admin'])">
        <v-btn @click="saveItem" :loading="saveDataLoading" class="bg-primary" size="small">
          <span class="text-white"  v-if="CheckGetPermission('legal_school.add_visualsubject')">
            {{ $t("globals.save") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-table>
  </v-form>
</template>
<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
import { required, helpers } from "@vuelidate/validators";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      v$: useValidate(),
      addDialog: false,
      protected_records: false,
      data_message: [],
      groupsQuran: [],
      filter_data: {
        fk_level: undefined,
        typesubject: undefined,
        groupsQuran: [],
      },
      subjects_count:0,
      exist: undefined,
      dynamicHeaders: [],
      visualSubjects: [],
      saveDataLoading: false,
      editDataLoading: false,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("globals.school"), key: "fk_country" },
        { title: this.$t("globals.name"), key: "name_ar" },
        { title: this.$t("monthly_marks.order"), key: "name_en" },
        { title: this.$t("globals.employee-name"), key: "code_number" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      levels: (state) => state.summer.levels,
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.groupsQuran) {
        return JSON.parse(JSON.stringify(this.groupsQuran));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getVisualSubjectChoice();
      await this.getLevels();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  validations() {
    return {
      filter_data: {
        fk_level: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  methods: {
    addNote(month,week){
      week.subjects.unshift({week_id: week.week_id,fk_monthsemester:month.fk_monthsemester,fk_level:month.fk_level,subject_id:month.subject_id,name:""});
      if(week.subjects.length > this.subjects_count) {
        this.subjects_count  = week.subjects.length;
      }
    },
    ...mapActions({
      getLevels: "summer/getLevels",
    }),
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    // check if the value is unique 
    isUniqueValue(value) {
      const occurrences = this.visualSubjects.filter(subject => subject.name === value).length;
      return occurrences;
    },
    validUnique(value){
     return this.isUniqueValue(value);
    },
    async getVisualSubjects() {
      if(this.filter_data.fk_level){
        await this.axios(`${this.base_url}api/visual-subject/list/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
              fk_level: this.filter_data.fk_level,
            },
        }).then((response) => {
          this.visualSubjects = response.data;
        });
      }
    },
    async getVisualSubjectChoice() {
      await this.axios(`${this.base_url}/choices/typesubject-choices`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.dynamicHeaders = response.data;
      });
    },
    async getItems() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate()
      if (valid) {
        this.loading = true;
        this.exist = undefined;
      // if (!this.v$.filter_data.$error) {
        await this.axios(`${this.base_url}api/visual-subject/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_level: this.filter_data.fk_level,
            typesubject: this.filter_data.typesubject,
          },
        }).then((response) => {
          this.exist = true;
          this.groupsQuran = response.data.result_list;
          this.subjects_count = response.data.subjects_count;
          this.loading = false;
        }).catch((error) => {
            this.loading = false;
          });
      }
    },
    async saveItem() {
      const combinedSubjects = this.groupsQuran.flatMap((month) =>
        month.weeks.flatMap((week) => week.subjects)
      );
      const subjectHeader = this.dynamicHeaders.map((item) => item.name);
      const renameKeys = (obj, headersSubject, newkey) => {
        headersSubject.forEach((oldkey) => {
          if (oldkey in obj) {
            obj[newkey] = obj[oldkey];
            delete obj[oldkey];
          }
        });
        return obj;
      };
      const updatedSubjects = combinedSubjects.map((subject) =>
        renameKeys(subject, subjectHeader, "name")
      );
      this.v$.$validate()
      const { valid } = await this.$refs.form.validate()
      if (valid) {
      this.saveDataLoading = true;
      // this.filter_data.data_entry = this.user;
      await this.axios
        .post(this.base_url + "api/visual-subject/", updatedSubjects, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then(() => {
          this.$emit("successAlert", this.$t("globals.data_added"));
          this.saveDataLoading = false;
        })
        .catch((error) => {
          if (error.response.data) {
            this.$emit("warningAlert", this.$t("summer.already-subject"));
          } else {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          }
          this.saveDataLoading = false;
        });
      this.getItems();
      }
    },
  },
};
</script>
<style>

</style>
