<template>
  <v-card id="report">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{
              this.$t("reportpublic.TotalSchoolsWorkersStudentsByGovernorate")
            }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="pa-0">
            <v-sheet
              class="chart-label-sheet text-wrap"
              elevation="0"
              rounded="0"
            >
              {{ this.$t("reportpublic.TotalSchools") }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              class="border"
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ results.total_summers }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
    <v-card-item>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="px-2 pt-1 my-4" style="height: 400px">
            <chart :option="option" autoresize class="m-chart" />
          </v-card>
        </v-col>
        <v-col>
          <summer-all-detailed-summary-table
            :totals="totals"
            :governorate_data="governorate_data"
          ></summer-all-detailed-summary-table>
        </v-col>
      </v-row>
    </v-card-item>
  </v-card>
</template>

<script>
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import SummerAllDetailedSummaryTable from "@/components/reports/SummerAllDetailedSummaryTable.vue";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.fetchData();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
    this.option = {
      title: {
        text: this.$t("reportpublic.NumberOfSchools"),
        right: "center",
        textStyle: {
          fontFamily: "Almarai",
          fontSize: 12,
        },
        subtextStyle: {
          fontFamily: "Almarai",
          fontSize: 10,
        },
      },
      dataset: [
        {
          dimensions: [
            "name",
            this.$t("reportpublic.TotalOpenSchools"),
            this.$t("reportpublic.TotalTypicalSchools"),
            this.$t("reportpublic.Open")    + " " + this.$t("reportpublic.Students"),
            this.$t("reportpublic.Typical") + " " + this.$t("reportpublic.Students"),
            this.$t("reportpublic.Open")    + " " + this.$t("reportpublic.Workers"),            
            this.$t("reportpublic.Typical") + " " + this.$t("reportpublic.Workers"),
            ""
          ],
          source: this.governorate_data.map((item) => [
            this.$i18n.locale == "ar"? item.governorate_name_ar:item.governorate_name_en,
            item.school_summer_opened_counts,
            item.school_summer_typical_counts,
            item.student_summer_opened_counts,
            item.student_summer_typical_counts,
            item.worker_summer_opened_counts,
            item.worker_summer_typical_counts,
          ]),
        },
        {
          transform: {
            type: "sort",
            config: { dimension: "constant", order: "desc" },
          },
        },
      ],
      xAxis: {
        type: "category",
        axisLabel: {
          interval: 0,
          rotate: 60,
        },
      },
      yAxis: {
        type: "value",
        position: this.$i18n.locale == "ar" ? "right" : "",
        boundaryGap: [0, 0.2],
      },
      series:Array.from({length:7},()=>(   {
          type: "bar",
          itemStyle: { borderRadius: [4, 4, 0, 0] },
          label: {
            show: true,
            position: "top",
            rotate: "90",
            offset: [5, 7],
          },
        })),
      legend: {
        bottom: "bottom",
        left: "center",
        textStyle: {
          fontSize: 10,
        },
        itemHeight: 10,
        itemWidth: 13,
        borderWidth: 1,
        borderColor: "black",
        top: "80%",
      },
      
      
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },    
      },
      grid: {
        height: "50%",
        top: "12%",
      },
    };
  },
  methods: {
    async fetchData() {
      await axios
        .get(`${this.base_url}public-reports/summer-all-detailed-summary`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.results = response.data.results;
          this.totals = response.data.results.total;
          this.governorate_data = response.data.results.governorate_data;
        })
        .catch((erroe) => {
          console.error(erroe);
        });
    },

    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },

  data() {
    return {
      governorate_data: [],
      totals: [],
      results: [],
      option: {},
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  components: {
    ReportHeader,
    SummerAllDetailedSummaryTable,
  },
};
</script>
