<template>
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-row class="my-2 mt-6">
          <v-col cols="3" md="3" >
            <v-autocomplete             
              :items="semesters"
              v-model="filter_data.fk_semester"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name_ar"
              item-value="id"
              :label="$t('semester.name')"
              clearable
              persistent-hint
              density="compact"
              :rules="[$required]"
              @update:modelValue="getMonthsBySemester(), (this.filter_data.fk_monthsemester = null)"
            />
          </v-col>
          
          <v-col cols="12" md="3">
            <v-autocomplete            
              color="indigo"
              v-model="filter_data.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="month"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
            />
          </v-col>
          <v-col cols="12" md="3" sm="6" class="mb-3">
              <v-select
                color="primary"
                v-model="filter_data.type"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-soccer"
                :items="activityType"
                :label="$t('summer.activity.type')"
                hide-details
                clearable
                @update:modelValue="getActivitiesByType(),filter_data.activity=null"
                :error-messages="v$.filter_data.type.$errors.map(e=>e.$message)" 
                :rules="rules.requird_filed"
                />
          </v-col>
            <v-col cols="12" md="2" sm="6" class="mb-3">
              <v-select
                color="primary"
                v-model="filter_data.activity"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-clipboard-check-multiple"
                :items="allAvtivitiesByType"
                :label="$t('summer.activity.name')"
                hide-details
                clearable
                :error-messages="v$.filter_data.activity.$errors.map(e=>e.$message)" 
                :rules="rules.requird_filed"
                />
          </v-col>      
          <v-col cols="6" sm="1" class="mt-2 align-center">
            <v-btn
              density="comfortable"
              class="w-100"
              color="primary"
              :loading="loading"
              @click="checkFilterData"
            >
              <span class="text-white">{{ $t("globals.show") }}</span>
              <VIcon icon="mdi-presentation" color="white" end></VIcon>
            </v-btn>
          </v-col>
    </v-row>
  </v-card>

  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">{{ $t("report.all_activities") }} ( {{getActivityTypeName(filter_data.type)}} )</div>
        </div>
      </template>
    </ReportHeader>
    

    <v-card-text class="mb-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
 
        <!-- search box  -->
     
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            v-bind="props"
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable((all = false))"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            density="compact"
            class="mx-1"
            @click="(exist = false),(exist_table=false) , v$.filter_data.$reset()"
          >
            <span class="text-primary">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <div id="myTable" >
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading"  rounded="0" flat v-if="exist_table">
          <table    class="report-table">

            <thead class="table-light"> 
              <!-- <tr class="text-center" > -->
                <th class="text-center border bg-primary-grey-lighten-4" style="width:50px"> {{ $t("globals.#") }}</th>
                <th class="text-center border bg-primary-grey-lighten-4" > {{ $t("summer.activity.name") }} </th>
                <th class="text-center border bg-primary-grey-lighten-4" > {{ $t("report.count_activity") }}</th>
                     <!-- </tr> -->
            </thead>
            <tbody>
              <tr class="text-center" v-for="(item,key) in items" :key="item">
                <td>
                  {{key + 1}}
                </td>
                <td>
                  {{item.activity_name}}
                </td>
                <td>
                  {{item.count_of_activity}}
                </td>
              </tr>
            </tbody>
          </table>
       
        </v-card>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import useValidate from "@vuelidate/core";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    
    try {
      await this.$store.commit('updateLoadingValue',true)
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));

      await this.getActivityType();
      await this.getSemester();
      await this.getYears();
      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit('updateLoadingValue',false)
    } catch (error) {
      this.$store.commit('updateLoadingValue',false)
      
    }
  },

  data() {
    return {
      rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },
      theaders:[],
      allAvtivitiesByType:[],
      current_year: "",
      fees_types: [],
      sub_list: [],
      drivers: [],
      semesters: [],
      months: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      exist_table: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id","name", "adjective", "entity",  "phone_number"],
      headers: [
        
        { title: "#", key: "id" },
        { title: this.$t("globals.name"), key: "name" },
        { title: this.$t("globals.adjective"), key: "adjective" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity" },
        { title: this.$t("globals.phone_number"), key: "phone_number" },
  
      ],
      
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},

    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      activityType:(state)=>state.summer.activityType,
    }),
    ...mapGetters({
      getClassDivisions: "summer/getClassDivisions",
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getActivityType:"summer/getActivityType",
      getClassAssignHall: "summer/getClassAssignHall",
    }),
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },
  
  
    getActivityTypeName(driver_id) {
      if (driver_id) {
        const activity_type = this.activityType.find((activity_type) => activity_type.id === driver_id);
        if(activity_type)
          return activity_type.name;
        else 
          return ''
      }
      return 
    },
  async checkFilterData(){
      this.v$.filter_data.$validate();
          if (!this.v$.filter_data.$error) {
            this.getData();
      }
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.getData();
      }
      window.print();
    },
    async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
      const current_semester = this.semesters.find((e) => e.current_semester);
      if (current_semester) {
        this.filter_data.fk_semester = current_semester.id;
        this.getMonthsBySemester()
      }
    },
    async getMonthsBySemester() {
      if(this.filter_data.fk_semester)
      await this.axios(
        `${this.base_url}/api/months-semester/?fk_semester=${this.filter_data.fk_semester}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
        .then((response) => {
          this.months = response.data;
           const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.filter_data.fk_monthsemester = current_month.month;
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
      else this.months=[]
    },    
    async getData() {
      // const { valid } = await this.$refs.form.validate();
        // if (valid) {
       this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/all-activities`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") ,
          "Content-Type":'application/json'
          },
         params: {
            type: this.filter_data.type,
            fk_activity: this.filter_data.activity,
            fk_monthsemester: this.months.find(
              (e) => e.month == this.filter_data.fk_monthsemester
            ).id,
            fk_semester: this.filter_data.fk_semester,
          },
        })
        .then((response) => {
          this.exist = true;
          this.exist_table = true;
          if (response.data) {
            this.items = response.data;
            if(this.items.length<1){
              this.exist_table = false;
            }
            this.loading = false;
         
          }
        })
        .catch((error) => {
          this.loading = false;
          this.icon = "cancel";
          this.icon_color = "error";
          if (error.message.includes("Network Error")) {
            this.alert_title = this.$t("alert.failure.title");
            this.alert_message = this.$t("alert.failure.connect");
          } else {
            this.alert_title = this.$t("alert.not-found.title");
            this.alert_message = this.$t("alert.not-found.message");
          }
          this.process_alert = true;
          setTimeout(() => {
            this.process_alert = false;
          }, 2000);
        });
      
        // }
    },
    async getActivitiesByType() {
      await this.axios
        .get(`${this.base_url}api/summer/get-activity-by-type`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") ,
          },
          params:{
            activity_type: this.filter_data.type,
          }
        })
        .then((response) => {
          if (response.data) {
            this.allAvtivitiesByType = response.data         
          }
        })
        .catch((error) => {
        });
    },
  },
  validations() {
    return {
      filter_data: {
        type: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        activity: {
            required: helpers.withMessage(
                this.$t("errors.required"),
                required
            ),
        },
      },
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>


#stable{

  width: 100%;

}


.pp2{
padding: 2px!important;

}
#stable th {
padding: 10px;
border:2px solid #649add !important;
background-color: #fff;
color:black
}

#stable td  {
border: 1px solid #649add !important;
background-color: #fff;
padding: 8px;
}

/* #stable tr:nth-child(even){
  background-color: skyprimary;

} */

/* #stable tr:hover{background-color: skyprimary;} */

/* #stable th {
border: 2px solid skyprimary;
padding-top: 12px;
padding-bottom: 12px;
text-align: center;
background-color: primary;
color: black;

} */

/* td {
  padding: 2px;
  font-size: 12px;
}
th {
  font-size: 14px;
  padding-bottom: 2px;
} */

.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
