<template>
  <VCard class="h-100 mb-2 pa-0">
    <VForm ref="form" class="" @submit.prevent="saveStudentAttendance">
      <VCard-title class="my-4">
        <VIcon icon="mdi-clipboard-check-outline" />
        <h2 class="d-inline" style="padding: 0 4px">
          {{ $t("summer.student.recording-student-attendance") }}
        </h2>
        <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
      </VCard-title>
      <VRow class="pt-0 px-2 px-md-16 pb-0">
        <VCol class="pt-0 px-3" cols="12" md="3">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="levels"
              v-model="studentAttendance.level"
              item-title="level_with_phase_name"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              :rules="rules.level"
              @update:model-value="
                getDivisionByLevel(),
                  getTeachers(),
                  (studentAttendance.division = null),
                  (studentAttendance.employee = null),
                  (is_found_data = false)
              "
            ></VAutocomplete>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" md="3">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="divisions"
              v-model="studentAttendance.division"
              item-title="name"
              item-value="fk_division"
              :label="$t('division.select')"
              persistent-hint
              prepend-inner-icon="mdi-shape-outline"
              density="compact"
              :rules="rules.division"
              @update:model-value="is_found_data = false"
            ></VAutocomplete>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" md="3">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="weeks"
              v-model="studentAttendance.week"
              item-title="week_name"
              item-value="id"
              :label="$t('summer.calendar.select-week')"
              persistent-hint
              prepend-inner-icon="mdi-calendar-week"
              density="compact"
              :rules="rules.week"
              @update:model-value="is_found_data = false"
            ></VAutocomplete>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" md="3">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="teachers"
              v-model="studentAttendance.employee"
              item-title="teacher_name"
              item-value="fk_employee"
              :label="$t('permission.teacher')"
              persistent-hint
              prepend-inner-icon="mdi-account-tie"
              density="compact"
              :rules="rules.employee"
              @update:model-value="
                (studentAttendance.subject = null),
                  getSubjectsByTeacherId(),
                  (is_found_data = false)
              "
            ></VAutocomplete>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" md="3">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="subjects"
              v-model="studentAttendance.subject"
              item-title="subject_name"
              item-value="fk_subject"
              :label="$t('subject.select')"
              persistent-hint
              prepend-inner-icon="mdi-bookshelf"
              density="compact"
              :rules="rules.subject"
              @update:model-value="
                (studentAttendance.lecture = null),
                  getLectures(),
                  (is_found_data = false)
              "
            ></VAutocomplete>
          </VCardItem>
        </VCol>
        <VCol class="pt-0 px-3" cols="12" md="6">
          <VCardItem class="pa-0">
            <VAutocomplete
              :items="lectures"
              v-model="studentAttendance.lecture"
              item-title="name"
              item-value="id"
              :label="$t('summer.lecture.name')"
              persistent-hint
              prepend-inner-icon="mdi-google-classroom"
              density="compact"
              :rules="rules.lecture"
              @update:model-value="getStudentsDataToAttendance()"
            ></VAutocomplete>
          </VCardItem>
        </VCol>
      </VRow>
    </VForm>
    <VCardActions
      class="mx-16"
      v-if="
        CheckGetPermission('legal_school.add_studentattendancesummer', [
          'sys_admin',
          'summer_admin',
          'user',
        ]) ||
        CheckGetPermission('legal_school.change_studentattendancesummer', [
          'sys_admin',
          'summer_admin',
          'user',
        ])
      "
      v-show="is_found_data"
    >
      <VBtn class="bg-primary" @click="saveStudentAttendance()" size="small">
        <span class="text-white">
          {{ $t("globals.students-attendance") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
    <v-divider class="my-4" />
    <!-- ######## Header Of Data Table  ####### -->
    <v-card class="pa-2" v-show="is_found_data">
      <div class="d-flex justify-space-between pa-2">
        <div class="d-flex flex-fill">
          <v-menu :close-on-content-click="false">
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                variant="outlined"
                color="#ccc"
                size="small"
              >
                <v-icon style="color: #5e7e96" class="me-2"
                  >mdi-eye-outline</v-icon
                >
                <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in headers"
                  :key="header.key"
                  :label="header.title"
                  :value="header.key"
                  color="primary"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
          <div style="width: 100%">
            <v-text-field
              color="primary"
              v-model="txt_search"
              style="
                border-radius: 30px;
                border: 1px solid #ccc;
                color: black;
                height: 30px;
                width: 90%;
              "
              class="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              single-line
              :placeholder="$t('globals.search-here')"
              density="compact"
              variant="text"
            >
            </v-text-field>
          </div>
        </div>
      </div>
      <!-- ######## End Header Of Data Table Server ####### -->
      <v-data-table
        :loading="loading"
        :headers="selectedHeaders"
        :items="students"
        item-key="id"
        id="vDataTable"
        fixed-footer
        density="compact"
        class="elevation-1"
        :search="txt_search"
      >
        <template v-slot:item="{ item, index }">
          <tr class="row-hover">
            <td v-for="(val, key) in item.columns" :key="key">
              <span v-if="key === 'index'">
                {{ index + 1 }}
              </span>
              <span v-if="key === 'student_name'">
                {{ val }}
              </span>
              <span
                v-if="key === 'is_attendance'"
                v-bind="(checkAttendance[index] = val)"
              >
                <v-checkbox
                  v-model="checkAttendance[index]"
                  color="primary"
                  density="compact"
                  hide-details
                  @change="updateAttendance(index)"
                >
                </v-checkbox>
              </span>
              <span
                v-if="key === 'is_permission'"
                v-bind="(checkPermission[index] = val)"
              >
                <v-checkbox
                  :value="item.is_permission"
                  v-model="checkPermission[index]"
                  color="primary"
                  density="compact"
                  hide-details
                  @change="updatePermission(index)"
                >
                </v-checkbox>
              </span>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </VCard>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
export default {
  data() {
    return {
      is_found_data: false,
      checkAttendance: [],
      checkPermission: [],
      divisions: [],
      lectures: [],
      students: [],
      studentAttendance: {},
      teachers: [],
      subjects: [],
      sortBy: [
        {
          key: "student_name",
          order: "asc",
        },
      ],
      rules: {
        level: [(value) => !!value || this.$t("globals.required_field")],
        division: [(value) => !!value || this.$t("globals.required_field")],
        week: [(value) => !!value || this.$t("globals.required_field")],
        employee: [(value) => !!value || this.$t("globals.required_field")],
        subject: [(value) => !!value || this.$t("globals.required_field")],
        lecture: [(value) => !!value || this.$t("globals.required_field")],
      },
      dialogVisible: false,
      process_alert: false,
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      delete_id: undefined,
      selectedHead: ["index", "student_name", "is_attendance", "is_permission"],
      headers: [
        // { title: "", key: "checkbox" },
        { title: "#", key: "index" },
        { title: this.$t("school.student_name"), key: "student_name" },
        { title: this.$t("permission.is_attendance"), key: "is_attendance" },
        { title: this.$t("permission.is_permission"), key: "is_permission" },
      ],
      txt_search: undefined,
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.school.countries,
      levels: (state) => state.summer.levels,
      weeks: (state) => state.summer.weeks,
      user: (state) => state.User,
    }),
    ...mapGetters({}),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getLevels();
      await this.getWeeks();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getLevels: "summer/getLevels",
      getWeeks: "summer/getWeeks",
    }),
    async getDivisionByLevel() {
      let levelDivisions = await this.axios.get(
        this.base_url +
          `api/summer/get-level-division/${this.studentAttendance.level}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.divisions = levelDivisions.data.division;
    },
    async getLectures() {
      let lectures = await this.axios.get(
        this.base_url + `api/summer/get-lecture-by-level-and-division`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            level: this.studentAttendance.level,
            division: this.studentAttendance.division,
            subject: this.studentAttendance.subject,
            empoylee: this.studentAttendance.employee,
          },
        }
      );
      this.lectures = lectures.data.results;
    },
    async getTeachers() {
      let teachers = await this.axios.get(
        this.base_url + `api/summer/employee-level-custom`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            level: this.studentAttendance.level,
          },
        }
      );
      this.teachers = teachers.data.results;
    },
    async getSubjectsByTeacherId() {
      let subjects = await this.axios.get(
        this.base_url + `api/summer/teacher-subject-summer`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            level: this.studentAttendance.level,
            employee: this.studentAttendance.employee,
          },
        }
      );
      this.subjects = subjects.data.results;
    },
    async getStudentsDataToAttendance() {
      let students = await this.axios.get(
        this.base_url + `api/summer/get-student-by-lecture`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            lecture: this.studentAttendance.lecture,
          },
        }
      );
      this.students = students.data.results;
      if (this.students.length > 0) this.is_found_data = true;
      if (this.students.length <= 0) {
        this.is_found_data = false;
        this.icon_color = "error";
        this.icon = "cancel";
        this.alert_title = this.$t("globals.failure");
        this.alert_message = this.$t("globals.error_in_data");
        this.process_alert = true;
        setTimeout(() => {
          this.process_alert = false;
        }, 2000);
      }
    },
    updateAttendance(index) {
      this.students[index].is_attendance = !this.students[index].is_attendance;
      if (this.students[index].is_attendance)
        this.students[index].is_permission = false;
    },
    updatePermission(index) {
      this.students[index].is_permission = !this.students[index].is_permission;
      if (this.students[index].is_permission)
        this.students[index].is_attendance = false;
    },

    resetForm() {
      this.$refs.form.resetValidation();
      this.studentAttendance = {};
      this.is_found_data = false;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async saveStudentAttendance() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.studentAttendance.data_entry = this.user;
        let newStudents = this.students.map((object) => ({
          id: object.id,
          student: object.fk_student,
          lecture: this.studentAttendance.lecture,
          is_attendance: object.is_attendance,
          is_permission: object.is_permission,
        }));
        this.studentAttendance.data = newStudents;
        let result = await this.axios
          .put(
            this.base_url + "api/summer/add-attendance-to-student",
            this.studentAttendance,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.icon_color = "success";
            this.icon = "check-circle";
            this.alert_title = this.$t("globals.success");
            this.alert_message = this.$t("globals.data_added");
          })
          .catch((error) => {
            this.icon_color = "error";
            this.icon = "cancel";
            this.alert_title = this.$t("globals.failure");
            this.alert_message = this.$t("globals.error_in_data");
          });
        this.process_alert = true;
        this.resetForm();
        setTimeout(() => {
          this.process_alert = false;
        }, 2000);
      }
    },
  },
};
</script>

<style>

</style>
