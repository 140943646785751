<template>
 <div id="printView"></div>
  <VLocaleProvider :locale="$i18n.locale"   id="appViewId">
      <VApp  >
        <router-view />
      </VApp>

  </VLocaleProvider>
</template>

<script>
import { mapActions,mapState} from "vuex";
export default {
  async created(){
    const locale=localStorage.getItem('user-locale')
    if(locale=='en'){
      this.$store.dispatch("updatedirection", 'ltr');
      this.$store.dispatch("updatelanguage",'en');
      this.$i18n.locale="en"
    }else{
      this.$store.dispatch("updatedirection", 'rtl');
      this.$store.dispatch("updatelanguage",'ar');
      this.$i18n.locale="ar"
    }
    if(localStorage.getItem('token')){
      this.getActiveCirculars()
      this.getUserProfile()
      await this.loadingInitialData()
    }
    
    
  },
  computed:{
    ...mapState({
      hijri_months_choices: (state) => state.hijri_months_choices,
    }),
  },
  methods:{
    ...mapActions(['GetHijriMonthsChoices','getAdministrativeChoices']),
    async loadingInitialData(){
      await this.GetHijriMonthsChoices()
      await this.getAdministrativeChoices()
    },
    async getActiveCirculars() {
      this.axios(
        `${this.base_url}/api/summer/generalization/active-circulars/`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      ).then((res) => {
        this.$store.commit('setCirculars',res.data)
      });
    },
    async getUserProfile() {
      await this.axios
        .get(`${this.base_url}api/user/profile`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.user_profile = response.data;
          if(this.user_profile?.role){
            this.$store.commit('setRole',this.user_profile.role)
          }
        });
    },
  },
  mounted(){
    if (this.$i18n.locale=='en'){
      document.title="Summer Centers System"
    }
    
  }
}
</script>
<style >
.v-data-table-footer__items-per-page >span{
  display: none;
}

</style>


