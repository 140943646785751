<template>
  <v-form ref="form">
    <v-card class="pa-2">
      <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="activityTypes"
            v-model="filter_data.fk_activity_type"
            prepend-inner-icon="mdi-shape-outline"
            item-title="name"
            item-value="id"
            :label="$t('activity.type')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            @update:model-value="this.exist=0"
          ></VAutocomplete>
        </VCol>
        <v-col cols="1" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="primary"
            :loading="loading"
            @click="getActivityByType(),getItems()"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-table
      density="compact"
      class="rounded elevation-1 striped-table"
      fixed-header
      :height="months?.length > 10 ? 400 : null"
      v-if="exist"
    >
      <thead>
        <tr>
          <th>{{ $t("globals.number") }}</th>
          <th>{{ $t("globals.employee-name") }}</th>
          <th>{{ $t("permission.activity") }}</th>
        </tr>
      </thead>
      <tbody>
          <tr v-for="(student, wIndex) in activityOfficers" :key="wIndex">
            <td> {{ wIndex + 1 }}</td>
            <td> {{ student.name_ar }}</td>
            <td>
                <v-row dense>
                  <v-col v-for="activity in activities" :key="activity.id" cols="2">
                    <VCheckbox
                      :value="activity.id"
                      :label="activity.name"
                      v-model="student.fk_activities"
                      hide-details
                      dense
                    >
                    </VCheckbox>
                  </v-col>
                </v-row>
            </td>
          </tr>
      </tbody>
      <v-card-actions class="mx-2 pt-0">
        <v-btn @click="saveItem" :loading="saveLoading" class="bg-primary" size="small">
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-table>
  </v-form>
</template>
<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
import { required, helpers } from "@vuelidate/validators";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      v$: useValidate(),
      activityOfficers: [],
      filter_data: {
        fk_activity_type: null,
        activityOfficers: [],
      },
      loading: false,
      saveLoading: false,
      exist: undefined,
      activityTypes: [],
      activities: [],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getActivityType();
      await this.getActivityByType();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  validations() {
    return {
      filter_data: {
        fk_activity_type: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    async getActivityType() {
      await this.axios(`${this.base_url}api/summer/activity-type`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.activityTypes = response.data;
      });
    },
    async getActivityByType() {
      await this.axios(`${this.base_url}api/summer/activity/${this.filter_data.fk_activity_type?'?fk_activity_type='+this.filter_data.fk_activity_type:''}`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.activities = response.data.results;
      });
    },
    async getItems() {
      this.exist = undefined;
      this.loading = true;
      this.v$.$validate();
        await this.axios(`${this.base_url}api/activity-responsible/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }).then((response) => {
          this.exist = true;
          this.activityOfficers = response.data;
          this.loading = false;
        });
    },
    async saveItem() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveLoading = true;
        // this.filter_data.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + `api/activity-responsible/`, 
          {
            employee_activities: this.activityOfficers,
          },
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveLoading = false;
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("warningAlert", this.$t("summer.already-governorate"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveLoading = false;
          });
        this.getItems();
      }
    },
  },
};
</script>