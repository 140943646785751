<template>
  <VNavigationDrawer
    v-model="collapse"
    :temporary="breakPointXS || breakPointSM"
    :permanent="breakPointMD"
    :rail="expand"
    width="250"
    ref="drawer"
  >
    <v-card
      class="w-100 logo-box d-flex text-center border-bottom"
      style="overflow: hidden"
      rounded="0"
    >
      <div style="flex: 4" class="align-self-center">
        <div
          v-show="!expand || breakPointXS || breakPointSM"
          class="text-primary text-h6"
        >
          {{ $t("globals.legal-schools") }}
        </div>
      </div>
      <div style="flex: 1">
        <v-btn
          v-if="(breakPointMD || breakPointLG) && $i18n.locale == 'ar'"
          @click="Expand()"
          density="compact"
          variant="text"
          :icon="expand ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right'"
          class="mt-1"
        ></v-btn>
        <v-btn
          v-else-if="(breakPointMD || breakPointLG) && $i18n.locale == 'en'"
          @click="Expand()"
          density="compact"
          variant="text"
          :icon="expand ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'"
          class="mt-1"
        ></v-btn>
        <v-btn
          v-else
          @click="Expand()"
          density="compact"
          variant="text"
          icon="mdi-chevron-double-right"
          class="mt-1"
        ></v-btn>
      </div>
    </v-card>
    <div class="mt-14">
      <!-- <VDivider /> -->
      <VList
        v-model:opened="open"
        active-color="primary"
        active-class="rounded-e-xl"
        open-strategy="single"
        v-model:selected="selectedList"
        @click="(expand = false), (show = false)"
        @click:select="tempOpenLists = open"
      >
        <div class="summer-camp">
          <VListItem
            class="border-bottom"
            :value="$t('globals.dashboard')"
            min-height="40"
            @click="open = [0]"
            :to="{ name: 'summer_dashboard' }"
          >
            <template v-slot:title>
              <span class="v-select-title">{{ $t("globals.dashboard") }}</span>
            </template>

            <template v-slot:prepend>
              <v-tooltip :text="$t('globals.dashboard')">
                <template v-slot:activator="{ props }">
                  <v-icon v-show="expand == true" v-bind="props"> mdi-home </v-icon>
                  <v-icon v-show="expand == false"> mdi-home </v-icon>
                </template>
              </v-tooltip>
            </template>
          </VListItem>
          <VListGroup
            class="border-bottom"
            :value="$t('globals.system-initialize')"
            :fluid="false"
            v-if="checkRole(['sys_admin', 'gen_admin', 'summer_admin'])"
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("globals.system-initialize")
                  }}</span>
                </template>

                <template v-slot:prepend>
                  <v-tooltip :text="$t('globals.general-initialize')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-cog-outline
                      </v-icon>
                      <v-icon v-show="expand == false"> mdi-cog-outline </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListGroup :value="$t('globals.general-initialize')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:prepend>
                    <v-icon size="medium">mdi-cog-refresh-outline</v-icon>
                  </template>
                  <template v-slot:title>
                    <span>{{ $t("globals.general-initialize") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('summer.initialization-supervising-authority')"
                :to="{ name: 'initialization-supervisor' }"
                v-if="
                  CheckGetPermission('legal_school.view_supervisor', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.initialization-supervising-authority")
                  }}</span>
                </template>
              </VListItem>

              <VListItem
                :value="$t('summer.summer-information')"
                :to="{ name: 'summer-school' }"
                v-if="
                  CheckGetPermission('legal_school.view_schoolsummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.summer-information")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer.summer-camp-list')"
                :to="{ name: 'summer-camps-list' }"
                v-if="
                  CheckGetPermission('legal_school.view_schoolsummer', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.summer-camp-list")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('subject.subjects')"
                :to="{ name: 'initialization-subjects' }"
                v-if="
                  CheckGetPermission('legal_school.view_subject', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("subject.subjects") }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('globals.system_setting')"
                :to="{ name: 'systemSetting' }"
                v-if="
                  CheckGetPermission('legal_school.view_semester', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.system_setting")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('document.view')"
                :to="{ name: 'addDocument' }"
                v-if="
                  CheckGetPermission('legal_school.view_document', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("document.view") }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('permission.estimate')"
                :to="{ name: 'estimates' }"
                v-if="
                  CheckGetPermission('legal_school.view_semester', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("permission.estimate") }}</span>
                </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :value="$t('summer-sidebar.init-student-talents')"
                :to="{ name: 'talent' }"
                v-if="
                  CheckGetPermission('legal_school.change_talent', [
                    'sys_admin',
                    'gen_admin',
                  ]) ||
                  CheckGetPermission('legal_school.add_talent', [
                    'sys_admin',
                    'gen_admin',
                  ]) ||
                  CheckGetPermission('legal_school.view_talent', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.init-student-talents")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer-sidebar.init-employee-skills')"
                :to="{ name: 'skills' }"
                v-if="
                  CheckGetPermission('legal_school.view_skill', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.init-employee-skills")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.countries')"
                :to="{ name: 'summer-countries' }"
                v-if="CheckGetPermission('globaltable.add_country', ['donotshow'])"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("school.countries") }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.governorates')"
                :to="{ name: 'summer-governorate' }"
                v-if="
                  CheckGetPermission('globaltable.view_governorate', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("school.governorates") }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer.directorates')"
                :to="{ name: 'summer-directorate' }"
                v-if="
                  CheckGetPermission('globaltable.view_directorate', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.directorates") }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              :value="$t('globals.school-calendar')"
              :fluid="false"
              v-if="
                CheckGetPermission('globaltable.view_yearofstudy', [
                  'sys_admin',
                  'gen_admin',
                ]) ||
                CheckGetPermission('globaltable.view_yearofstudy', [
                  'sys_admin',
                  'gen_admin',
                  'summer_admin',
                ])
              "
            >
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <span>{{ $t("globals.school-calendar") }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon size="medium">mdi-calendar-month-outline</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('globals.study-years')"
                :to="{ name: 'summer-academic-year' }"
                v-if="
                  CheckGetPermission('globaltable.view_yearofstudy', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="small" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("globals.study-years") }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer.days')"
                v-if="
                  CheckGetPermission('legal_school.view_daysummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :to="{ name: 'summerdays' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.days") }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_week', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.weekly-data')"
                :to="{ name: 'week' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.weekly-data") }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer.academic-calendar-data')"
                v-if="
                  CheckGetPermission('legal_school.view_calendar', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :to="{ name: 'summer-calendar' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.academic-calendar-data")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_vacation', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.add-holidays')"
                :to="{ name: 'vacation' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.add-holidays") }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              :value="$t('globals.classes')"
              :fluid="false"
              v-if="
                CheckGetPermission('legal_school.view_level', [
                  'sys_admin',
                  'gen_admin',
                ]) ||
                CheckGetPermission('legal_school.view_leveldivision', [
                  'sys_admin',
                  'summer_admin',
                ])
              "
            >
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <span>{{ $t("globals.classes") }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon size="medium">mdi-google-classroom</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.change_level', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ]) ||
                  CheckGetPermission('legal_school.view_level', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.levels')"
                :to="{ name: 'level' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.levels") }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_divisionsummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('calendar.semester')"
                :to="{ name: 'semester' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("calendar.semester") }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_divisionsummer', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :value="$t('semester.semesterPlan')"
                :to="{ name: 'semesterPlan' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("semester.semesterPlan") }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_divisionsummer', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :value="$t('semester.months')"
                :to="{ name: 'months' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("semester.months") }}</span>
                </template>
              </VListItem>              
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_divisionsummer', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :value="$t('semester.month-setting')"
                :to="{ name: 'month-setting' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("semester.month-setting") }}</span>
                </template>
              </VListItem>              
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_leveldocument', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :value="$t('document.document_screen')"
                :to="{ name: 'document' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("document.document_screen")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_divisionsummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ]) ||
                  CheckGetPermission('legal_school.change_divisionsummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('globals.division')"
                :to="{ name: 'initialization-divisions' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("globals.division") }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.change_leveldivision', [
                    'sys_admin',
                    'summer_admin',
                  ]) ||
                  CheckGetPermission('legal_school.view_leveldivision', [
                    'sys_admin',
                    'summer_admin',
                  ]) ||
                  CheckGetPermission('legal_school.add_leveldivision', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.assigning-division-to-levels')"
                :to="{ name: 'assigning-divisions-to-level' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.assigning-division-to-levels")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              :value="$t('globals.evaluations')"
              :fluid="false"
            >
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <span>{{ $t("globals.evaluations") }}</span>
                  </template>
                  <template v-slot:prepend>
                    <v-icon size="medium">mdi-star</v-icon>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('summer-sidebar.types_of_personal_assessments')"
                :to="{ name: 'types_of_personal_assessments' }"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.types_of_personal_assessments")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer-sidebar.personal-evaluations')"
                :to="{ name: 'personal_evaluations' }"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.personal-evaluations")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
            </VListGroup>
          </VListGroup>
          <VListGroup
            class="border-bottom"
            :value="$t('globals.student-affairs')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{ $t("globals.student-affairs") }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('globals.student-affairs')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props"> mdi-school </v-icon>
                      <v-icon v-show="expand == false">mdi-school </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>

            <VListGroup :value="$t('globals.registration-addmission')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2">mdi-account-check</v-icon>
                    <span>{{ $t("globals.registration-addmission") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('globals.new-registration')"
                :to="{ name: 'summer-student-add' }"
                v-if="
                  CheckGetPermission('legal_school.add_studentsummer', ['summer_admin'])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.new-registration")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_studentsummer', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
                :value="$t('summer.move-students')"
                :to="{ name: 'add-student-movable' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.move-students") }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('school.show_registered_students')"
                :to="{ name: 'summer-student-list' }"
                v-if="
                  CheckGetPermission('legal_school.view_studentsummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("school.show_registered_students")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_studentsummer', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :to="{ name: 'moveStudent' }"
                :value="$t('summer.move-student')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.move-student") }}</span>
                </template>
                <template v-slot:prepend> </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_studentsummer', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :to="{ name: 'talentassigins' }"
                :value="$t('summer.assign-student-skills')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.assign-student-skills")
                  }}</span>
                </template>
                <template v-slot:prepend> </template>
              </VListItem>
              <VListItem
                :value="$t('summer.receiving-curricula')"
                :to="{ name: 'receive-curriculum' }"
                v-if="
                  CheckGetPermission('legal_school.change_employeelevel', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.receiving-curricula")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="CheckGetPermission('legal_school.add_parentsummer', ['donshow'])"
                :value="$t('globals.parent-add')"
                :to="{ name: 'parent' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("globals.parent-add") }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_parentsummer', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('globals.parent-list')"
                :to="{ name: 'parents-list' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("globals.parent-list") }}</span>
                </template>
              </VListItem>
            </VListGroup>

            <VListGroup :value="$t('globals.drades_evaluations')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-if="
                    CheckGetPermission('legal_school.view_studentsummer', [
                      'sys_admin',
                      'summer_admin',
                    ])
                  "
                  v-bind="props"
                  :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                >
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2">mdi-numeric-10-box-outline</v-icon>
                    <span>{{ $t("globals.drades_evaluations") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_studentsummer', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer-sidebar.student-evaluations')"
                :to="{ name: 'student_evaluations' }"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.student-evaluations")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_studentsummer', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('year.placeholder.monthly-scores')"
                :to="{ name: 'markMonthly' }"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("year.placeholder.monthly-scores")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_studentsummer', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('year.placeholder.finaly-scores')"
                :to="{ name: 'finalMark' }"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("year.placeholder.finaly-scores")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup :value="$t('summer-report.attendance_student')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  v-if="
                    CheckGetPermission('legal_school.view_studentattendancesummer', [
                      'sys_admin',
                      'summer_admin',
                    ])
                  "
                >
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2">mdi-account-check</v-icon>
                    <span>{{ $t("summer-report.attendance_student") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_studentattendancesummer', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('permission.studentattendances')"
                :to="{ name: 'student-attendance-views' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("permission.studentattendances")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_holiday', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer-sidebar.student-vacations')"
                :to="{ name: 'student-holiday-view' }"
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.student-vacations")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup
              :value="$t('summer.dropout-students')"
              :fluid="false"
              v-if="
                CheckGetPermission('legal_school.view_parentsummer', [
                  'sys_admin',
                  'summer_admin',
                ])
              "
            >
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2">mdi-progress-check</v-icon>
                    <span>{{ $t("summer.dropout-students") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('globals.student_status')"
                :to="{ name: 'dropout' }"
                v-if="
                  CheckGetPermission('legal_school.view_levelstudentyear', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.student_status")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
          </VListGroup>
          <VListGroup
            class="title border-bottom"
            v-if="
              CheckGetPermission('legal_school.add_supplies', [
                'sys_admin',
                'summer_admin',
              ])
            "
            :value="$t('summer-sidebar.student-housing')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <dir :title="$t('summer-sidebar.student-housing')">
                <VListItem v-bind="props" min-height="40">
                  <template v-slot:title>
                    <span class="v-select-title">{{
                      $t("summer-sidebar.student-housing")
                    }}</span>
                  </template>

                  <template v-slot:prepend>
                    <v-tooltip :text="$t('summer-sidebar.student-housing')">
                      <template v-slot:activator="{ props }">
                        <v-icon v-show="expand == true" v-bind="props">
                          mdi-home-city-outline
                        </v-icon>
                        <v-icon v-show="expand == false">mdi-home-city-outline </v-icon>
                      </template>
                    </v-tooltip>
                  </template>
                </VListItem>
              </dir>
            </template>
            <VListGroup 
            :value="$t('summer-sidebar.student-housings')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2">mdi-home-city-outline</v-icon>
                    <span>{{ $t("summer-sidebar.housing") }}</span>
                  </template>
                </VListItem>
              </template>
              <!-- ///////////////////----- المجموعات السكنية--------//////////////////// -->
              <VListItem
                :value="$t('summer-sidebar.groups')"
                :to="{ name: 'hosing_grouping' }"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{ $t("summer-sidebar.groups") }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <!-- ///////////////////----- ربط الطلاب المجموعات السكنية--------//////////////////// -->
              <VListItem
                :value="$t('summer-sidebar.link_groups_housing')"
                :to="{ name: 'linkStudentWithHousingGroup' }"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.link_groups_housing")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <!-- ///////////////////----- الاسرة--------//////////////////// -->
              <VListItem
                :value="$t('summer-sidebar.beds')"
                :to="{ name: 'bed_views' }"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{ $t("summer-sidebar.beds") }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <!-- ///////////////////----- غرف السكن--------//////////////////// -->
              <VListItem
                :value="$t('summer-sidebar.room')"
                :to="{ name: 'room_views' }"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.room")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <!-- ///////////////////----- غرف السكن--------//////////////////// -->
              <VListItem
                :value="$t('summer-sidebar.room-bed')"
                :to="{ name: 'room_bed' }"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.room-bed")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <!-- ///////////////////----- غرف السكن--------//////////////////// -->
              <VListItem
                :value="$t('summer-sidebar.student-bed')"
                :to="{ name: 'destributedStudentsInBeds' }"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.student-bed")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup :value="$t('summer-sidebar.groups_divide')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2">mdi-book-open-outline</v-icon>
                    <span>{{ $t("summer-sidebar.groups_divide") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('summer-sidebar.groupsQuran')"
                :to="{ name: 'groupQuran' }"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.groupsQuran")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
              <VListItem
                :value="$t('summer-sidebar.link_groups_Quran')"
                :to="{ name: 'linkStudentWithQuranGroup' }"
                :class="direction === 'rtl' ? 'prtl-30' : 'pltr-20'"
              >
                <template v-slot:title>
                  <span class="v-select-subtitle">{{
                    $t("summer-sidebar.link_groups_Quran")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-icon size="small">mdi-minus</v-icon>
                </template>
              </VListItem>
            </VListGroup>
          </VListGroup>
          <VListGroup
            v-if="
              CheckGetPermission('legal_school.view_employee', [
                'sys_admin',
                'gen_admin',
                'summer_admin',
              ])
            "
            class="border-bottom"
            :value="$t('summer-sidebar.personnel-authority')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("summer-sidebar.personnel-authority")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer-sidebar.personnel-authority')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-account-tie
                      </v-icon>
                      <v-icon v-show="expand == false">mdi-account-tie </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.add_employee', [
                  'sys_admin',
                  'gen_admin',
                ])
              "
              :value="$t('summer.add-employee')"
              :to="{ name: 'add-employee' }"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{ $t("summer.add-employee") }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_employee', [
                  'sys_admin',
                  'gen_admin',
                  'summer_admin',
                ])
              "
              :value="$t('summer.employees')"
              :to="{ name: 'employees' }"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{ $t("summer.employees") }}</span>
              </template>
            </VListItem>
            <VListItem
              :to="{ name: 'employeeassigin' }"
              :value="$t('summer.assign-employee-skills')"
              v-if="
                CheckGetPermission('legal_school.change_employee', [
                  'sys_admin',
                  'summer_admin',
                ]) ||
                CheckGetPermission('legal_school.add_employee', [
                  'sys_admin',
                  'summer_admin',
                ]) ||
                CheckGetPermission('legal_school.view_employee', [
                  'sys_admin',
                  'summer_admin',
                ])
              "
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.assign-employee-skills")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.add_teachersubjectsummer', [
                  'sys_admin',
                  'summer_admin',
                ])
              "
              :value="$t('summer.add-teachers-plan')"
              :to="{ name: 'assign-teacher_add' }"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.add-teachers-plan")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_employeelevel', ['summer_admin'])
              "
              :value="$t('summer.view-teachers-plan')"
              :to="{ name: 'assign-teacher_list' }"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.view-teachers-plan")
                }}</span>
              </template>
            </VListItem>

            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_employee', [
                  'sys_admin',
                  'summer_admin',
                  'gen_admin',
                ])
              "
              :value="$t('summer.manage-state-employee')"
              :to="{ name: 'EmployeeStatusManage' }"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.manage-state-employee")
                }}</span>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup
            class="border-bottom"
            :value="$t('summer-sidebar.study-timetable')"
            :fluid="false"
            v-if="
              CheckGetPermission('legal_school.view_schedule', [
                'sys_admin',
                'gen_admin',
                'summer_admin',
              ])
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("summer-sidebar.study-timetable")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer-sidebar.study-timetable')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-timetable
                      </v-icon>
                      <v-icon v-show="expand == false">mdi-timetable </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_schedule', [
                  'sys_admin',
                  'gen_admin',
                  'summer_admin',
                ])
              "
              :to="{ name: 'summer-schedule' }"
              :value="$t('permission.schedule')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{ $t("permission.schedule") }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_schoolweeklyschedulesummer', [
                  'sys_admin',
                  'summer_admin',
                ])
              "
              :to="{ name: 'summer_time_table_add' }" 
              :value="$t('summer-sidebar.add-timetable')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.add-timetable")
                }}</span>
              </template>
            </VListItem>

          </VListGroup>
          <VListGroup
            class="border-bottom"
            :value="$t('summer-sidebar.activities-intiatives')"
            :fluid="false"
            v-if="
              CheckGetPermission('view_activitytype', ['sys_admin', 'gen_admin']) ||
              CheckGetPermission('view_activitysummer', ['sys_admin', 'summer_admin'])
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("summer-sidebar.activities-intiatives")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer-sidebar.activities-intiatives')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-bullseye-arrow
                      </v-icon>
                      <v-icon v-show="expand == false"> mdi-bullseye-arrow</v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListGroup :value="$t('summer-sidebar.activities')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2"> mdi-bullseye-arrow</v-icon>
                    <span>{{ $t("summer-sidebar.activities") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_activitytype', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.initialize-types-activities')"
                :to="{ name: 'type-activity' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.initialize-types-activities")
                  }}</span>
                </template>
              </VListItem>
              <!-- <VListItem
                v-if="
                  CheckGetPermission('legal_school.add_activitysummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :to="{ name: 'add_activity' }"
                :value="$t('summer.add-activity')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.add-activity") }}</span>
                </template>
              </VListItem> -->
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_activitysummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :to="{ name: 'activity_list' }"
                :value="$t('summer.view-activity')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.view-activity") }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_activityoperation', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :to="{ name: 'Activity-Opreations' }"
                :value="$t('summer.initialize-activity-processes')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.initialize-activity-processes")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'activities_officers' }"
                :value="$t('school.activity-officers')"
                  v-if="
                  CheckGetPermission('legal_school.view_activityoperation', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("school.activity-officers")
                  }}</span>
                </template>
              </VListItem>

              <VListItem
                v-if="
                  CheckGetPermission('legal_school.add_activitydifficulty', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.activity.difficulties_and_solutions_assign')"
                :to="{ name: 'dicultsandslotions_add' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.activity.difficulties_and_solutions_assign")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_activitydifficulty', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.activity.difficulties_and_solutions_show')"
                :to="{ name: 'dicultsandslotions_list' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.activity.difficulties_and_solutions_show")
                  }}</span>
                </template>
              </VListItem>

              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_activitysummer', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.assign-student-activities')"
                :to="{ name: 'ActivityStudentAssigin' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.assign-student-activities")
                  }}</span>
                </template>
              </VListItem>

              <VListItem
                v-if="
                  CheckGetPermission('legal_school.add_activity-difficulty', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :to="{ name: 'dicultsandslotions_add' }"
                :value="$t('summer.activity.difficulties_and_solutions_assign')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.activity.difficulties_and_solutions_assign")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_activity-difficulty', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.activity.difficulties_and_solutions_show')"
                :to="{ name: 'dicultsandslotions_list' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.activity.difficulties_and_solutions_show")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_schoolweeklyactivity', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.set-weekly-activities')"
                :to="{ name: 'WeeklyActivityAssign' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.set-weekly-activities")
                  }}</span>
                </template>
              </VListItem>    
            </VListGroup>
            <VListGroup :value="$t('summer-sidebar.intiatives')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  v-if="
                    CheckGetPermission('legal_school.view_initiative', [
                      'sys_admin',
                      'summer_admin',
                    ])
                  "
                >
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2">mdi-handshake</v-icon>
                    <span>{{ $t("summer-sidebar.intiatives") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :to="{ name: 'summer_initiative' }"
                v-if="
                  CheckGetPermission('legal_school.view_initiative', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.add-an-initiative')"
              >
                <!-- :value="$t('summer.initiatives')" -->
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.add-an-initiative")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_initiative', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :to="{ name: 'initiativeassigin' }"
                :value="$t('summer.assigning-students-initiatives')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.assigning-students-initiatives")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
          </VListGroup>
          <VListGroup
            class="border-bottom"
            :value="$t('summer-sidebar.community-committees')"
            :fluid="false"
            v-if="
              CheckGetPermission('add_commitee', [
                'sys_admin',
                'gen_admin',
                'summer_admin',
              ]) ||
              CheckGetPermission('add_commitee', [
                'sys_admin',
                'gen_admin',
                'summer_admin',
              ])
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("summer-sidebar.community-committees")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer-sidebar.community-committees')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-human-capacity-increase
                      </v-icon>
                      <v-icon v-show="expand == false"
                        >mdi-human-capacity-increase</v-icon
                      >
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_commitee', [
                  'sys_admin',
                  'gen_admin',
                  'summer_admin',
                ])
              "
              :to="{ name: 'commits-summer' }"
              :value="$t('summer.initialize-committees')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.initialize-committees")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.add_commiteemember', [
                  'sys_admin',
                  'summer_admin',
                ])
              "
              :to="{ name: 'membercommit_add' }"
              :value="$t('summer.assign-committees-to-members')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.assign-committees-to-members")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_commiteemember', [
                  'sys_admin',
                  'summer_admin',
                ])
              "
              :to="{ name: 'membercommit_list' }"
              :value="$t('summer.show-assign-committees-to-members')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer.show-assign-committees-to-members")
                }}</span>
              </template>
            </VListItem>
          </VListGroup>

          <VListGroup
            class="border-bottom"
            :value="$t('summer-report.daily-check-up')"
            :fluid="false"
            v-if="
              CheckGetPermission('legal_school.view_studentattendancesummer', [
                'sys_admin',
                'gen_admin',
                'summer_admin',
              ])
            "
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("summer-report.daily-check-up")
                  }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer-report.daily-check-up')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-clipboard-check-multiple
                      </v-icon>
                      <v-icon v-show="expand == false">
                        mdi-clipboard-check-multiple</v-icon
                      >
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListGroup :value="$t('summer-sidebar.attendnace-protoflio')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  v-if="
                    CheckGetPermission('legal_school.view_studentattendancesummer', [
                      'sys_admin',
                      'summer_admin',
                    ])
                  "
                >
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2">mdi-account-check</v-icon>
                    <span>{{ $t("summer-sidebar.attendnace-protoflio") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_studentattendancesummer', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('permission.studentattendance')"
                :to="{ name: 'student-attendance-view' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("permission.studentattendance")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_employeeattendance', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.employee.attendance')"
                :to="{ name: 'employee-attendance' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("summer.employee.attendance")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup :value="$t('summer-sidebar.lessons')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2"
                      >mdi-newspaper-variant-multiple</v-icon
                    >
                    <span>{{ $t("summer-sidebar.lessons") }}</span>
                  </template>
                </VListItem>
              </template>

              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_lecturesummer', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('lecture.view')"
                :to="{ name: 'lecture_list' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("lecture.view") }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_lecturelog', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.lesson-record')"
                :to="{ name: 'lesson-record' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.lesson-record") }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup :value="$t('summer-sidebar.visitors')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem
                  v-bind="props"
                  v-if="
                    CheckGetPermission('legal_school.add_visitor', [
                      'sys_admin',
                      'summer_admin',
                    ])
                  "
                >
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2"
                      >mdi-clipboard-check-outline</v-icon
                    >
                    <span>{{ $t("summer-sidebar.visitors") }} </span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.add_visitor', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.add-visitors')"
                :to="{ name: 'add-visitor' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.add-visitors") }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_visitor', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :value="$t('summer.all-visitors')"
                :to="{ name: 'visitor' }"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{ $t("summer.all-visitors") }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup :value="$t('subject.visual_subjects')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2"
                      >mdi-clipboard-check-outline</v-icon
                    >
                    <span>{{ $t("subject.visual_subjects") }} </span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('subject.decision_visual_subjects')"
                :to="{ name: 'visualSubjects' }"
                v-if="
                  CheckGetPermission('legal_school.view_subject', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("subject.decision_visual_subjects")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('subject.recored_visual_subjects')"
                :to="{ name: 'recoredVisualSubjects' }"
                v-if="
                  CheckGetPermission('legal_school.view_subject', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("subject.recored_visual_subjects")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListItem
              :value="$t('document.typeOfDocument')"
              :to="{ name: 'typeOfDocument' }"
              v-if="
                CheckGetPermission('legal_school.view_document', [
                  'sys_admin',
                  'gen_admin',
                ])
              "
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{ $t("document.typeOfDocument") }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_summerdocument', [
                  'sys_admin',
                  'gen_admin',
                  'summer_admin',
                ])
              "
              :value="$t('document.document_school')"
              :to="{ name: 'archiveFile' }"
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("document.document_school")
                }}</span>
              </template>
            </VListItem>
          </VListGroup>
          <VListGroup :value="$t('summer.weekly-follow-up')" :fluid="false">
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{ $t("summer.weekly-follow-up") }}</span>
                </template>
                <template v-slot:prepend>
                  <v-tooltip :text="$t('summer.weekly-follow-up')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-calendar-week-begin
                      </v-icon>
                      <v-icon v-show="expand == false">mdi-calendar-week-begin </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListGroup
              :value="$t('permission.managmenttasks')"
        
              v-if="
                CheckGetPermission('legal_school.add_managmenttask', [
                  'sys_admin',
                  'gen_admin',
                  'summer_admin',
                ])
              "
            >
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2">mdi-chart-box-outline</v-icon>
                    <span>{{ $t("permission.managmenttasks") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('permission.managmenttask')"
                :to="{ name: 'initialization-managmenttask' }"
                v-if="
                  CheckGetPermission('legal_school.view_managmenttask', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("permission.managmenttask")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('globals.evalate_person_responsible')"
                :to="{ name: 'evalate-person-responsible' }"
                v-if="
                  CheckGetPermission('legal_school.view_evalatepersonresponsible', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.evalate_person_responsible")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                :value="$t('globals.execute_person_responsible')"
                :to="{ name: 'add-managament-tasks' }"
                v-if="
                  CheckGetPermission('legal_school.view_evalatepersonresponsible', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.execute_person_responsible")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListGroup :value="$t('globals.education_program')" :fluid="false">
              <template v-slot:activator="{ props }">
                <VListItem v-bind="props">
                  <template v-slot:title>
                    <v-icon size="medium" class="me-2">mdi-counter</v-icon>
                    <span>{{ $t("globals.education_program") }}</span>
                  </template>
                </VListItem>
              </template>
              <VListItem
                :value="$t('globals.add_education_program')"
                :to="{ name: 'education_program_data' }"
                v-if="
                  CheckGetPermission('legal_school.view_educationprogram', [
                    'sys_admin',
                    'gen_admin',
                    'summer_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.add_education_program")
                  }}</span>
                </template>
              </VListItem>
              <VListItem
                v-if="
                  CheckGetPermission('legal_school.view_educationprogramoperation', [
                    'sys_admin',
                    'summer_admin',
                  ])
                "
                :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
                :to="{ name: 'education_program_operation' }"
                :value="$t('globals.education_program_operation')"
              >
                <template v-slot:title>
                  <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                  <span class="v-select-subtitle">{{
                    $t("globals.education_program_operation")
                  }}</span>
                </template>
              </VListItem>
            </VListGroup>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_marksummer', [
                  'sys_admin',
                  'summer_admin',
                ])
              "
              :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
              :to="{ name: 'meetings' }"
              :value="$t('globals.meetings')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{ $t("globals.meetings") }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_supplies', [
                  'sys_admin',
                  'summer_admin',
                ])
              "
              :to="{ name: 'supplies' }"
              :value="$t('summer.supplies')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{ $t("summer.supplies") }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_approach', [
                  'sys_admin',
                  'summer_admin',
                ])
              "
              :to="{ name: 'SubjectsStatics' }"
              :value="$t('summer-sidebar.received-currical')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.received-currical")
                }}</span>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('legal_school.view_recommendation', [
                  'sys_admin',
                  'summer_admin',
                ])
              "
              :to="{ name: 'recomendations' }"
              :value="$t('summer-sidebar.positives-negatives')"
            >
              <template v-slot:title>
                <v-icon size="medium" class="me-2">mdi-minus</v-icon>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.positives-negatives")
                }}</span>
              </template>
            </VListItem>
          </VListGroup>
          <DailyReports
            v-if="CheckGetPermission('usermanage.view_report')"
            :direction="direction"
            :show="show"
            :expand="expand"
            :CheckGetPermission="CheckGetPermission"
          ></DailyReports>
          <GeneralManagementReport
            v-if="CheckGetPermission('usermanage.view_report')"
            :direction="direction"
            :show="show"
            :expand="expand"
            :CheckGetPermission="CheckGetPermission"
          ></GeneralManagementReport>
          <GovernorateReport
            v-if="CheckGetPermission('usermanage.view_report')"
            :direction="direction"
            :show="show"
            :expand="expand"
            :CheckGetPermission="CheckGetPermission"
          ></GovernorateReport>
          <DirectorateReport
            v-if="CheckGetPermission('usermanage.view_report')"
            :direction="direction"
            :show="show"
            :expand="expand"
            :CheckGetPermission="CheckGetPermission"
          ></DirectorateReport>
          <SummerCenterReport
            v-if="CheckGetPermission('usermanage.view_report')"
            :direction="direction"
            :show="show"
            :expand="expand"
            :CheckGetPermission="CheckGetPermission"
          ></SummerCenterReport>
          <VListGroup
            class="title border-bottom"
            v-if="
              CheckGetPermission('usermanage.view_user', [
                'sys_admin',
                'gen_admin',
                'summer_admin',
              ])
            "
            :value="$t('globals.users-adminstration')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <VListItem v-bind="props" min-height="40">
                <template v-slot:title>
                  <span class="v-select-title">{{
                    $t("globals.users-adminstration")
                  }}</span>
                </template>

                <template v-slot:prepend>
                  <v-tooltip :text="$t('globals.users-adminstration')">
                    <template v-slot:activator="{ props }">
                      <v-icon v-show="expand == true" v-bind="props">
                        mdi-account-multiple
                      </v-icon>
                      <v-icon v-show="expand == false">mdi-account-multiple </v-icon>
                    </template>
                  </v-tooltip>
                </template>
              </VListItem>
            </template>
            <VListItem
              v-if="
                CheckGetPermission('usermanage.add_user', [
                  'sys_admin',
                  'gen_admin',
                  'summer_admin',
                ])
              "
              :value="$t('libraries.add-user')"
              :to="{ name: 'summer_add_user' }"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{ $t("libraries.add-user") }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('usermanage.view_user', [
                  'sys_admin',
                  'gen_admin',
                  'summer_admin',
                ])
              "
              :value="$t('user.view-users')"
              :to="{ name: 'summer_user_list' }"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{ $t("user.view-users") }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="
                CheckGetPermission('auth.add_group', [
                  'sys_admin',
                  'gen_admin',
                  'summer_admin',
                ])
              "
              :value="$t('group.add_group')"
              :to="{ name: 'summer_add_group' }"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{ $t("group.add_group") }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              v-if="CheckGetPermission('', ['sys_admin', 'gen_admin', 'summer_admin'])"
              :value="$t('summer-sidebar.logs')"
              :to="{ name: 'log_entries' }"
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{ $t("summer-sidebar.logs") }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup>

          <!-- <VListGroup
            class="title border-bottom"
            v-if="role == 0 || role == 1"
            :value="$t('summer-sidebar.mobile-apps')"
            :fluid="false"
          >
            <template v-slot:activator="{ props }">
              <dir :title="$t('summer-sidebar.mobile-apps')">
                <VListItem v-bind="props" min-height="40">
                  <template v-slot:title>
                    <span class="v-select-title">{{
                      $t("summer-sidebar.mobile-apps")
                    }}</span>
                  </template>

                  <template v-slot:prepend>
                    <v-tooltip :text="$t('summer-sidebar.mobile-apps')">
                      <template v-slot:activator="{ props }">
                        <v-icon v-show="expand == true" v-bind="props">
                          mdi-cellphone-cog
                        </v-icon>
                        <v-icon v-show="expand == false">mdi-cellphone-cog </v-icon>
                      </template>
                    </v-tooltip>
                  </template>
                </VListItem>
              </dir>
            </template>
            <VListItem
              :value="$t('summer-sidebar.mobile-versions')"
              :to="{ name: 'mobile_versions' }"
              v-if="
                CheckGetPermission('legal_school.view_mobileversion', [
                  'sys_admin',
                  'gen_admin',
                ])
              "
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.mobile-versions")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
            <VListItem
              :value="$t('summer-sidebar.upload-apps')"
              :to="{ name: 'upload_applications' }"
              v-if="
                CheckGetPermission('legal_school.view_app', ['sys_admin', 'gen_admin'])
              "
            >
              <template v-slot:title>
                <span class="v-select-subtitle">{{
                  $t("summer-sidebar.upload-apps")
                }}</span>
              </template>
              <template v-slot:prepend>
                <v-icon size="small">mdi-minus</v-icon>
              </template>
            </VListItem>
          </VListGroup> -->

          <VListItem
            :value="$t('summer-sidebar.circulars')"
            :to="{ name: 'circulars' }"
            v-if="
              CheckGetPermission('legal_school.view_generalizations', [
                'sys_admin',
                'gen_admin',
              ])
            "
          >
            <!-- v-if="role==0 || role ==1" -->
            <template v-slot:title>
              <span class="v-select-title">{{ $t("summer-sidebar.circulars") }}</span>
            </template>
            <template v-slot:prepend>
              <v-icon size="small">mdi-bullhorn-outline</v-icon>
            </template>
          </VListItem>
        </div>
      </VList>
    </div>
  </VNavigationDrawer>
  <!-- end side bar  -->
</template>

<script>
import { mapGetters, mapState } from "vuex";
import GeneralManagementReport from "./GeneralManagementReport";
import SummerCenterReport from "./SummerCenterReport";
import GovernorateReport from "./GovernorateReport";
import DirectorateReport from "./DirectorateReport";
import DailyReports from "./DailyReports";
// document.addEventListener('click',dr)
export default {
  props: {
    // drawer: Boolean,
    direction: String,
    lang: String,
  },
  components: {
    GeneralManagementReport,
    SummerCenterReport,
    GovernorateReport,
    DirectorateReport,
    DailyReports,
  },
  data() {
    return {
      selected_list: [],
      selectedList: [],
      expand: false,
      clickOpen: false,
      tempOpenLists: [],
      summer_center: localStorage.getItem("summer_center"),
      collapse: this.$store.state.drawer,
      open: [],
      temp: [],
      clean: [0],
      isOpen: false,
      logo: undefined,
      name: undefined,
      school_type: null,
    };
  },

  computed: {
    ...mapGetters(["getdrawer"]),
    ...mapState(["role"]),
    drawerVal() {
      return this.getdrawer;
    },

    CheckGetPermission() {
      return (prem, role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkpermission(prem, role);
      };
    },
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo")) return this.$store.getters.checkrole(role);
      };
    },

    breakPointXS() {
      return this.$vuetify.display.xs;
    },
    breakPointSM() {
      return this.$vuetify.display.sm;
    },
    breakPointMD() {
      return this.$vuetify.display.md;
    },
    breakPointLG() {
      return this.$vuetify.display.lg;
    },
  },
  watch: {
    selectedList(value) {
      this.setBreadcrumb();
    },
    getdrawer() {
      this.collapse = this.getdrawer;
    },
    collapse() {
      this.$store.commit("updateDrawer", this.collapse);
    },
  },
  mounted() {
    var active_elements = document.querySelectorAll(".v-list-item--active");
    if (active_elements.length > 0) {
      const active_list_item = active_elements[active_elements.length - 1].querySelector(
        ".v-list-item__content .v-list-item-title span"
      ).innerHTML;
      this.setBreadcrumb(active_list_item);
    } else {
      this.setBreadcrumb();
    }
  },
  async created() {
    window.addEventListener("beforeunload", this.BeforeReload);
    if (this.$vuetify.display.xs || this.$vuetify.display.sm) {
      this.$store.commit("updateDrawer", false);
      this.collapse = false;
    }
    // this.collapse=this.$store.state.drawer
    this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
    if (this.role == 4) {
      await this.axios
        .get(`${this.base_url}api/summer/school/info`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.school_type = response.data?.school_type;
        });
    }

    // this.drawerVal=this.getdrawer
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.BeforeReload);
  },
  methods: {
    BeforeReload() {
      this.selectedList.forEach((item) => {
        localStorage.setItem("selected_list", item);
      });
    },
    setBreadcrumb(active = null) {
      let items = [];
      items = Object.values(this.open).reverse();
      if (active != null) {
        items.push(active);
        this.$store.commit("updateBreadcrumbValue", items);
      } else if (this.selectedList.length > 0) {
        this.selectedList.forEach((item) => {
          items.push(item);
        });
        this.$store.commit("updateBreadcrumbValue", items);
      }
    },
    Expand() {
      this.show = !this.show;
      if (this.expand == false) {
        this.tempOpenLists = this.open;
        this.open = [];
      } else {
        if (this.open[0] != 0) {
          this.open = this.tempOpenLists;
        }
      }
      this.expand = !this.expand;
    },
    changeDrawer() {
      this.$store.commit("updateDrawer", !this.drawerVal);
    },
  },
};
</script>
<style scoped></style>
