<template>
  <v-card ref="report" class="pa-2">
    <v-card  elevation="0"/>
    <v-card-title class="text-center hide-item">
            <h3  class="d-inline-block  pa-3 rounded-3"> {{title}} </h3>
    </v-card-title>
    <v-data-table
      id="dataTableReport"
      ref="dataTableRef"
      :headers="visibleHeaders"
      :items="itemsList"
      fixed-header
      fixed-footer
      density="compact"
      class="table-bordereds centered-header-text"
      items-per-page="-1"
      :search="search"      
      :no-data-text="$t('globals.not-found')"                      
    >
      <template v-slot:loading>
        <v-skeleton-loader :type="`table-row-divider@${items?.length || 4}`" />
      </template>
      <template v-slot:top>
        <custom-report-header class="show-item" :title="title" />
      
        <v-col cols="12"  class="hide-item pa-5">
          <v-row class="text-center align-center">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn
                  v-bind="props"                 
                  density="comfortable"
                  variant="elevated"               
                >
                  <v-icon style="color: #3487f6">mdi-eye-outline</v-icon>
                  <v-icon style="color: #5e7e96">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="h in header"
                    :key="h.key"
                    :label="h.title"
                    color="indigo"
                    v-model="h.show"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <custom-btn type="print" @click="printReport" class="me-3"/>
            <v-text-field
              v-model="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              variant="outlined"
              single-line
              class="ms-2 me-3"            
            />
            عدد السجلات  {{itemsList.length}}
            <v-spacer />
            <v-icon
              v-if="close"
              icon="mdi-close"
              class="me-2 "
              @click="closeClick"
              end
            />
          </v-row>
      </v-col>
      </template>      
      <template v-slot:item="{ item, index ,columns }">
        <tr>
          <template v-for="(h, i) in columns" :key="i">          
            
              <template v-if="h?.children!= undefined">
                <template v-for="(child, ic) in h.children" :key="ic">
                  <td
                    :style="{child: h.width+'px'}"
                    :class="[
                    child.classCell?child.classCell:'',
                      ...styleCell(index, child.value),
                      {
                        'border-hide-top':
                          hideItem(child.value) && !item[child.value],
                      },
                    ]"
                  >
                    {{ item[child.value] }}
                  </td>
                </template>
              </template>          
                <template v-else-if="h.value == 'index' && counter && h?.children== undefined">
                <td
                  :class="[...styleCell(index, h.value)]"
                  style="width: 20px;"
                >
                  {{ index+1 }}
                </td>
              </template> 
              <template v-else>
                <td
                  :style="{width: h.width+'px' , color:h.color}"
                  :class="[
                    h.classCell?h.classCell:'',
                    ...styleCell(index, h.value),
                    { 'border-hide-top': hideItem(h.value) && !item[h.value] },
                  ]"
                >  
                  <span v-if="h.type == 'check'">
                    <span v-if="item[h.value] == true" >نعم</span>
                    <span v-if="item[h.value] == false" >لا</span>
                </span>
                <span v-else>{{ item[h.value] }}</span>
                </td>
              </template>       
          </template>          
        </tr>
        <slot name="total" v-if="index === items.length-1">        
        </slot>
                
      </template>
      <template v-slot:bottom>
        <slot name="bottom"/>
      </template>
    </v-data-table>
  </v-card>
  <!-- FILTER -->
  <v-dialog v-model="show_dialog_filter" width="700">
    <v-card>
      <v-card-title class="text-end">
        <v-icon icon="mdi-close" @click="show_dialog_filter = false" />
      </v-card-title>
      <v-card-item>
        <template v-for="(filter, index) in filter_list" :key="filter">
          <v-row>
            <v-col cols="11">
              <v-form ref="form_1">
                <v-row>
                  <v-col cols="3"
                    ><v-select
                      v-if="index > 0"
                      v-model="filter.logiccalOperatore"
                      label="الشرط"
                      :items="[
                        { id: 1, name: 'و' },
                        { id: 2, name: 'او' },
                      ]"
                      item-title="name"
                      item-value="id"
                      density="compact"
                      :rules="[$require]"
                      clearable
                  /></v-col>
                  <v-col cols="3"
                    ><v-select
                      v-model="filter.field"
                      label="الحقل"
                      :items="headers"
                      item-value="value"
                      item-title="title"
                      density="compact"
                      :rules="[$require]"
                  /></v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="filter.condition"
                      label="العملية"
                      :items="condition_list"
                      item-title="name"
                      item-value="id"
                      density="compact"
                      :rules="[$require]"
                  /></v-col>
                  <v-col cols="3"
                    ><v-text-field
                      v-model="filter.value"
                      label="القيمة"
                      density="compact"
                      :rules="[$require]"
                  /></v-col>
                </v-row>
              </v-form>
            </v-col>

            <v-col>
              <v-icon
                v-if="index > 0"
                icon="mdi-minus"
                @click="removeFilter"
                class="text-center mt-5"
              />
            </v-col>
          </v-row>
        </template>
      </v-card-item>
      <v-card-item class="text-end">
        <v-icon icon="mdi-plus" @click="addFilter" />
      </v-card-item>

      <template v-slot:actions>
        <custom-btn type="create" @click="items_filter(true)" />
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    total_list: {
      type: Array,
      default() {
        return []
      },
    },
    hideItems: [],
    title: '',
    close: Function,
    counter: Boolean,
    disabledClass: Boolean,
  },
  data() {
    return {
      is_total: this.total_list.length > 0 ? true : false,
      isPrint: false,
      header: [],
      show_dialog_filter: false,
      filter: false,
      filter_items: [],
      filter_list: [
        {
          logiccalOperatore: undefined,
          field: undefined,
          condition: undefined,
          value: undefined,
        },
      ],
      search:''
    }
  },
  created() {
    this.header = this.counter
      ? [{ title: '#', value: 'index' }, ...this.headers]
      : [...this.headers]
    this.header
      .filter((e) => e.show == undefined)
      .map((r) => {
        r.show = true
      })
  },
  mounted() {
    const tableHeader =
      this.$refs.dataTableRef.$el.querySelectorAll('.v-data-table__th')

    let index = 0
    this.headers.map((item) => {
      if(item.children)
        item.children.map(()=>{
          index ++
          tableHeader[index]?.classList?.add(item.classHeader)
          })
      else{ 
      index ++
        tableHeader[index]?.classList?.add(item.classHeader)
      }    
    })
  },
  methods: {
    plusIndex() {
      if (!this.inde) this.inde = 0
      this.inde = this.inde + 1
      return this.inde
    },
    styleCell(index, item) {
      let list = ['border-cell']
      if (!this.isLastCell(index)) list.push('border-hide-bottom')

      return list
    },
    hideItem(item) {
      if (this.hideItems?.length > 0) {
        if (this.hideItems?.find((e) => e === item)) {
          return true
        } else return false
      }
    },
    isLastCell(index) {
      let lengthItem = this.filter
        ? this.filter_items.length - 1
        : this.items.length - 1

      return index === lengthItem
    },
    indexHide(isChild, item) {
      let list = []
      if (isChild)
        if (this.hideItem(this.visibleHeaders[1]['children'][0]?.value))
          // list.push('border-hide-top')

          return list
    },
    printReport() {
      this.isPrint = true

      setTimeout(() => {
        const printContent = this.$refs.report.$el.innerHTML
        document.getElementById('printView').style.display = 'block'
        document.getElementById('printView').innerHTML = printContent
        document.getElementById('appViewId').style.display = 'none'
        // document.getElementById('dataTableReport').style.height = 'auto'
        window.print()
        document.getElementById('printView').innerHTML = ''
        document.getElementById('printView').style.display = 'none'
        document.getElementById('appViewId').style.display = 'block'
        // document.getElementById('dataTableReport').style.height = '450px'
        this.isPrint = false
      }, 10)
    },
    async items_filter(status) {
      this.filter = status
      if (this.filter) {
        this.filter_items = [...this.applayFilter()]
      } else this.filter_items = [...this.items]
    },
    addFilter() {
      this.filter_list.push({
        logiccalOperatore: undefined,
        field: undefined,
        condition: undefined,
        value: undefined,
      })
    },
    removeFilter(index) {
      this.filter_list.splice(index, 1)
    },
    applayFilter() {
      this.filter = true
      const data = this.items.filter((item) => {
        let isMatch = true
        for (const filter of this.filter_list) {
          const field = item[filter.field]
          const value = filter.value
          let match = false
          switch (filter.condition) {
            case 1:
              match = field == value
              break
            case 2:
              match = field < value
              break
            case 3:
              match = field > value
              break
          }
          if (filter.logiccalOperatore === 1) {
            isMatch = isMatch && match
          } else if (filter.logiccalOperatore == 2) {
            isMatch = isMatch || match
          } else {
            isMatch = isMatch == match
          }
        }
        return isMatch
      })
      return data
    },
    closeClick() {
      this.close()
    },
  },
  computed: {
    itemsList() {
      let items = []
      if(this.items)
        items = this.filter ? [...this.filter_items] : [...this.items]
      else items = []
      return items
    },
    visibleHeaders() {
      return this.header.filter((header) => header.show)
    },
    condition_list() {
      return [
        { id: 1, name: 'يساوي' },
        { id: 2, name: 'اكبر من' },
        { id: 3, name: 'اصغر من' },
        { id: 4, name: 'لايساوي' },
        { id: 5, name: 'يحتوي' },
        { id: 6, name: 'لا يحتوي' },
        { id: 7, name: 'يبدء بـ' },
        { id: 8, name: 'ينتهي بـ' },
        { id: 9, name: 'بين' },
      ]
    },
  },
}
</script>
<style>
.table-bordereds thead th {
  border: 1px solid rgba(var(--v-border-color), var(--v-border-opacity)) !important ;
  background: rgba(var(--v-border-color), var(--v-border-opacity)) !important;
}

.border-cell {
  
  border: thin solid rgba(var(--v-border-color), var(--v-border-opacity)) !important;  
 
}

.border-hide-top {
  border-top: 0 !important;
}
.border-hide-bottom {
  border-bottom: 0 !important;
}

.border-hide-left {
  border-left: 0 !important;
}
.border-hide-right {
  border-right: 0 !important;
}
.centered-header-text .v-data-table-header__content{
  text-align: center;
  justify-content: center;
}

</style>