<template>
    <v-form  ref="form"  v-if="CheckGetPermission('legal_school.add_marksummer',['sys_admin','gen_admin','user'])">
        <v-card class="justify-center mt-2" > 
            <v-card-text class="pb-0">
                <v-card>
                    <v-table density="compact">
                        <thead>
                            <tr >
                                <th >{{$t('globals.number')}}</th>
                                <th >{{$t('globals.name')}}</th>
                                <th class="text-center">{{$t('summer-sidebar.mark')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>                                
                                <td>1</td>
                                <td>درجات الحضور</td>
                                <td style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                            class="mt-1 "
                                            v-model="student_list.attendance"
                                            @input="student_list.attendance==''?student_list.attendance=null:''"
                                            density="compact"
                                            type="number"
                                            variant="underlined"
                                            :rules="quran_rule"
                                            hide-details="auto"
                                            >
                                            <!-- @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''" -->
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                            </tr>
                            <tr>                                
                                <td>1</td>
                                <td>درجات الواجبات</td>
                                <td style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                            class="mt-1 "
                                            v-model="student_list.assignment"
                                            @input="student_list.assignment==''?student_list.assignment=null:''"
                                            density="compact"
                                            type="number"
                                            variant="underlined"
                                            :rules="quran_rule"
                                            hide-details="auto"
                                            >
                                            <!-- @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''" -->
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                            </tr>
                            <tr>                                
                                <td>2</td>
                                <td>الدرجات الشفوي</td>
                                <td style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                            class="mt-1 "
                                            v-model="student_list.oral"
                                            @input="student_list.oral==''?student_list.oral=null:''"
                                            density="compact"
                                            type="number"
                                            variant="underlined"
                                            :rules="quran_rule"
                                            hide-details="auto"
                                            >
                                            <!-- @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''" -->
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                            </tr>
                            <tr>  
                                <td>3</td>                              
                                <td>درجة الانشطة</td>
                                <td style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                            class="mt-1 "
                                            v-model="student_list.monthly_activity"
                                            @input="student_list.monthly_activity==''?student_list.monthly_activity=null:''"
                                            density="compact"
                                            type="number"
                                            variant="underlined"
                                            :rules="quran_rule"
                                            hide-details="auto"
                                            >
                                            <!-- @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''" -->
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                                
                            </tr>
                            <tr>                                 
                                <td>4</td>                             
                                <td>درجة السلوك</td>
                                <td style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                            class="mt-1 "
                                            v-model="student_list.behaviior_charity"
                                            @input="student_list.behaviior_charity==''?student_list.behaviior_charity=null:''"
                                            density="compact"
                                            type="number"
                                            variant="underlined"
                                            :rules="quran_rule"
                                            hide-details="auto"
                                            >
                                            <!-- @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''" -->
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                                
                            </tr>
                            <tr>        
                                <td>5</td>                                                 
                                <td>درجة مواد القران الكريم الاسبوعية</td>
                                <td style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                            class="mt-1 "
                                            v-model="student_list.quran_degree_weekly"
                                            @input="student_list.quran_degree_weekly==''?student_list.quran_degree_weekly=null:''"
                                            density="compact"
                                            type="number"
                                            variant="underlined"
                                            :rules="quran_rule"
                                            hide-details="auto"
                                            >
                                            <!-- @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''" -->
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                            </tr>
                            <tr>        
                                <td>6</td>                                                 
                                <td>درجة المحصلة الشهرية</td>
                                <td style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                            class="mt-1 "
                                            v-model="student_list.collector"
                                            @input="student_list.collector==''?student_list.collector=null:''"
                                            density="compact"
                                            type="number"
                                            variant="underlined"
                                            :rules="quran_rule"
                                            hide-details="auto"
                                            >
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                            </tr>
                            <tr>        
                                <td>7</td>                                                 
                                <td>درجة الاختبار النهائي</td>
                                <td style="min-width:100px; max-width:100px;">
                                    <v-lazy>
                                        <v-text-field
                                            class="mt-1 "
                                            v-model="student_list.final_exam"
                                            @input="student_list.final_exam==''?student_list.final_exam=null:''"
                                            density="compact"
                                            type="number"
                                            variant="underlined"
                                            :rules="quran_rule"
                                            hide-details="auto"
                                            >
                                            <!-- @focus="student_weekly_mark.changed!=true?isRecordChanged(student_weekly_mark):''" -->
                                        </v-text-field>
                                    </v-lazy>
                                </td>
                            </tr>
                        </tbody>
                    </v-table>
                </v-card>
            </v-card-text>
            <v-card-actions class="mx-2 pt-0">
                <v-btn v-if="CheckGetPermission('legal_school.add_marksummer',['sys_admin','gen_admin','user'])" @click="saveClassMarks" :loading="saveLoading"   class="bg-primary"  size="small">
                    <span class="text-white">
                        {{$t('globals.save')}}
                    </span>
                    <v-icon icon="mdi-content-save" color="white" end></v-icon>
                </v-btn>
                <v-btn  @click="cleanStudentSubjectMark()" class="mx-3 bg-ccc"  size="small">
                    <span>
                        {{$t('globals.clear')}}
                    </span>
                    <v-icon icon="mdi-broom" color="golden" end></v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-form> 

<Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
    >
</Alert>
</template>

<script>
 
import useValidate from '@vuelidate/core'
import {mapState,mapActions,mapGetters} from 'vuex'
import {required,helpers,maxLength,numeric} from '@vuelidate/validators'
export default{
    async created(){
        try {
            await this.$store.commit('updateLoadingValue',true)
            await this.getSettingSystem()
            this.$store.commit('updateLoadingValue',false)
        } catch (error) {
            this.$store.commit('updateLoadingValue',false)
            
        }
    },
    data(){
        return{
            v$: useValidate(),
            student_mark_list:[],
            weekly_marks:{
                fk_student:undefined,
                data_entry:undefined,
                student_weekly_mark:[]
            },
            valid:false,
            loading:false,
            student_list:{},
            quran_rule:[
            // (value) => !!value || this.$t('globals.required_field'),
            (value) => (!value || /^[-0-9]+$/.test(value)) || this.$t('errors.int'),
            (value) => (!value || value <= 100) || `${this.$t('globals.biggest_number_of_character')} 100`,
            (value) => (!value || value >= 0) || `${this.$t('globals.lowest_number_of_character')} 0`,
            ],            
            note_max_entry:[
                value =>!value ||value.length<250 || ''
            ],
            process_alert:false,
            icon_color:undefined,
            icon:undefined,
            alert_title:undefined,
            alert_message:undefined,
            saveLoading:false
        }
    },
    computed:{
        
        ...mapState({
            user: (state) => state.User,
        }),
        CheckGetPermission(){
            return (prem,role)=>{
                return this.$store.getters.checkpermission(prem,role);
            }
        },
        
    },
    methods:{
        async getSettingSystem(){
                this.loading=true
                await this.axios(`${this.base_url}api/setting-system/`, {
                    headers:{'Authorization':'Bearer '+localStorage.getItem('token')}
                }).then(response=>{
                    this.student_list=response.data
                    this.loading=false
                }).catch(error=>{
                    this.loading=false
                })
        },
        async saveClassMarks(){
            this.v$.$validate()
            const { valid } = await this.$refs.form.validate()
            // if(!this.v$.student_list.$error && valid){
                this.saveLoading=true
                this.student_list.data_entry=this.user
                await this.axios
                .post(this.base_url + "api/setting-system/", this.student_list, {
                    headers: { Authorization: "Bearer " + localStorage.getItem("token") },
                })
                .then(response=>{
                    this.getSettingSystem()
                    this.icon_color="success"
                    this.icon="check-circle"
                    this.alert_title=this.$t('alert.success.title')
                    this.alert_message=this.$t('alert.success.save')
                    this.saveLoading=false
                    
                }).catch((error)=>{
                    this.icon_color="error"
                    this.icon="cancel"
                    this.alert_title=this.$t('globals.failure')
                    if (error.response?.data.attendance) {
                        this.alert_message= 'درجة الحضور اجباري'
                    } else if(error.response?.data.assignment) {
                        this.alert_message= "درجات الواجبات اجباري"
                    } else if(error.response?.data.oral) {
                        this.alert_message= "درجة الشفهي اجباري"
                    } else if(error.response?.data.monthly_activity) {
                        this.alert_message= 'درجة الانشطة اجباري'
                    } else if(error.response?.data.behaviior_charity) {
                        this.alert_message= 'درجة السلوك اجباري'
                    } else {
                        this.alert_message= "جميع الحقول اجباري"
                    }
                    this.saveLoading=false
                })
                this.process_alert=true
                // this.cleanStudentSubjectMark()
                setTimeout(()=>{
                    this.process_alert=false
                },2000)
            // }
            // else{
            //     this.icon_color="error"
            //     this.icon="cancel"
            //     this.alert_title=this.$t('globals.failure')
            //     this.alert_message=this.$t('globals.error_in_data')
            //     this.process_alert=true
            //     setTimeout(()=>{
            //         // this.getSettingSystem()
            //         this.process_alert=false
            //     },2000)
            // }
        },
        cleanStudentSubjectMark(){
            this.student_list={}
        },
    },
}
</script>
<style scoped>

</style>