<template>
  <v-col>
    <v-row class="mt-2" style="justify-content: center">
      <v-label class="text-center pa-1" v-if="title">
        {{ title }}
      </v-label>
    </v-row>

    <v-row class="mb-1 justify-content: center" style="justify-content: center">
      <v-sheet
        :height="height"
        :width="width"
        :class="[
          'border-dashed cursor-pointer border-md',
          'pa-1',
          { image: imageSrc },
        ]"
      >
        <v-row
          v-if="value"
          :style="`z-index:1000; position:absolute !important; width:${width}`"
        >
          <v-icon
            class="ma-3"
            size="large"
            color="error"
            @click="(value = undefined), (imageSrc = '')"
            >mdi-alpha-x-circle</v-icon
          >

          <v-dialog v-model="dialog" width="500" height="500">
            <template v-slot:activator="{ props }">
              <v-icon
                v-bind="props"
                class="ma-3"
                @click="dialog = true"
                size="large"
                >mdi-eye-circle</v-icon
              >
            </template>
            <v-card>
              <v-img :src="imageSrc" cover />
            </v-card>
          </v-dialog>
        </v-row>
        <v-img
          v-if="imageSrc || typeof value == 'string'"
          :height="height"
          :width="width"
          :src="imageSrc"
          class="rounded"
          cover
        />
        <v-sheet
          v-else
          height="100%"
          width="100%"
          class="d-flex justify-center align-center"
          @click="triggerFileInput"
        >
          <v-col>
            <v-row class="justify-center image center">
              <v-icon size="30" color="primary">mdi-cloud-upload</v-icon></v-row>
            <v-row class="justify-center"><v-label :text="$t('summer-sidebar.upload-image')" /></v-row>
          </v-col>
          <v-file-input
            v-show="false"
            ref="fileInput"
            v-model="value"
            accept="image/*"
            :hide-input="true"
            @change="onImageChange"
            show-size
            class="position-absolute hide-input-img"
            style="inset: 45%"
            prepend-icon=""
          ></v-file-input>
        </v-sheet>
      </v-sheet>
    </v-row>
  </v-col>
</template>
<script>
export default {
  data() {
    return {
      imageSrc: "",
      dialog: false,
    };
  },

  props: {
    title: String,
    modelValue: {
      type: [String],
      default: null,
    },
    height: {
      type: String,
      default() {
        return "200";
      },
    },
    width: {
      type: String,
      default() {
        return "200";
      },
    },
  },

  methods: {
    triggerFileInput() {
      this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
    },
    onImageChange() {
      const file = this.value[0];
      if (typeof file == "object") {
        if (file && file.type.startsWith("image/")) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.imageSrc = e.target.result;
          };
          reader.readAsDataURL(file);
        }
      }
    },
  },
  computed: {
    value: {
      get() {
        if (typeof this.modelValue == "string") {
          this.imageSrc = this.base_url + this.modelValue;
        }else if(this.modelValue ==null)  this.imageSrc= ''
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  watch: {
    modelValue() {
      if (typeof this.modelValue == "string") {
        this.imageSrc = this.base_url + this.modelValue;
      }
    },
  },
};
</script>
<style>
.hide-input-img .v-field--appended {
  display: none;
}
.image:hover {
  transform: scale(1.05);
}
.image-card .v-card:hover {
  cursor: pointer;
}
.close-btn {
  position: absolute;
  top: 6px;
  right: 6px;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
  color: white;
  border: none;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  font-size: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding-bottom: 3px;
}
.close-btn:hover {
  background: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}
</style>