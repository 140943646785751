<template>
<!-- added by samer -->
<!-- تقرير بيتانات الطلاب-->
  <v-card class="pa-3" v-if="!show">
    <v-card-title>
      {{ $t("globals.select-criteria") }}
    </v-card-title>
    <v-card-text class="mt-2">
      <v-form ref="form">
        <v-row>
          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="filter.fk_level"
              :items="levels"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              hide-details="auto"
              :rules="[$required]"
              clearable
              @update:model-value="
                (filter.fk_division = undefined), getDivisionByLevel()
              "
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              :items="divisions"
              v-model="filter.fk_division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="fk_division"
              :label="$t('division.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
            />
          </v-col>
          <v-col cols="1" class="mt-2">
            <custom-btn type="show" :click="() => getData()" />
          </v-col>
        </v-row> </v-form></v-card-text
  ></v-card>
   <custom-table-report
        v-else
        :close="() => reset()"
        :headers="headers"
        :items="items"
        :title="title"
        counter
      />
</template>
<script>
export default {
  data() {
    return {
        filter:{},
        items: [],
        levels: [],
        divisions: [],

        title:'',

        show: false,
    };
  },
  created() {
    this.getLevels();
  },
  methods: {
   async getData() {
      // const { valid } = await this.$refs.form.validate();
      // if (valid)
        await this.axios(
          `${this.base_url}api/summer/report/student-data-report/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },

            params: {             
           
              fk_level: this.filter.fk_level,
              fk_division: this.filter.fk_division,             
            },
          }
        )
          .then((response) => {
            this.items = response.data.data;
            this.title = response.data.title;
            if (this.items.length > 0) {               
            

              this.show = true;
            } else this.$emit("infoAlert", this.$t("globals.not-found"));
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },

    reset() {
      this.show = false;
      this.items = [];
      
    },

    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivisionByLevel() {
      if (this.filter.fk_level) {
        let levelDivisions = await this.axios.get(
          this.base_url +
            `api/summer/get-level-division/${this.filter.fk_level}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.divisions = levelDivisions.data.division;
      }
    },

  
  },
  computed:{
      headers(){
      return [
        // بيانات الطالب
        { title:this.$t('school.student_info'),children:[
          // اسم الطالب
          { title:this.$t('report.student-name'),key:'fk_student__name'},
          // اللقب
          { title:this.$t('libraries.lname'),key:'fk_student__last_name'},

          // محل الميلاد
            { title:this.$t('summer.employee.birth-place'),children:[
          // المحافظة
          { title:this.$t('globals.governorate'),key:'fk_student__fk_directorate__fk_governorate__name_ar'},
          // المديرية
          { title:this.$t('globals.directorate'),key:'fk_student__fk_directorate__name_ar'},
          // الحي 
          { title:'الحي العزلة',key:'fk_student__birth_place'},
          // القرية / الحارة
          { title:'القرية / الحارة',key:'fk_student__address'},                    
          
        ]},

        // محل الميلاد
            { title:this.$t('globals.teacher-location'),children:[
          // المحافظة
          { title:this.$t('globals.governorate'),key:'fk_student__directorate_housing__fk_governorate__name_ar'},
          // المديرية
          { title:this.$t('globals.directorate'),key:'fk_student__directorate_housing__name_ar'},
          // الحي 
          { title:'الحي العزلة',key:'fk_student__neighbourhood_housing'},
          // القرية / الحارة
          { title:'القرية / الحارة',key:'fk_student__village_housing'},                    
          
        ]},
        // رقم الهاتف
        { title:this.$t('globals.phone_number'),key:'fk_student__phone_number'},
          
        ]},
        // بيانات الاسرة والحالة الاجتماعية
        { title:this.$t('globals.family_and_marital_status_data'),children:[
          { title:this.$t('school.parent_info'),children:[
            { title:this.$t('globals.parent_name'),key:'fk_parent__name'},
            { title:this.$t('globals.parent_relation'),key:'fk_student__parent_relation'},
            { title:this.$t('globals.address'),key:'fk_parent__address'},
               { title:this.$t('globals.phone_number'),key:'fk_parent__phone_number'},
          ]},
        

        ]},
        
     
            { title:this.$t('globals.type_of_disease'),key:'disease' ,width:500},
        
      ]
    }
  }
};
</script>