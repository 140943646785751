<template>
  <v-row class="mt-1 mb-2 hide-item">
    <v-col cols="12" class="text-end">
      <v-btn-group class="ms-2">
        <v-btn
          @click="
            () => {
              addDialog = true;
            }
          "
        >
          <span>إضافة غرفة جديدة</span>
          <v-icon icon="mdi-plus-thick" end></v-icon>
        </v-btn>
      </v-btn-group>
      <slot name="action"></slot>
    </v-col>
  </v-row>
  <v-dialog v-model="addDialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="addBed()">
          <VRow class="mt-2">
            <VCol cols="12">
              <VTextField
                v-model="bed.name"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('school.room_name')"
                density="compact"
                :placeholder="$t('school.enter_room_name')"
                :rules="[$required,$max_length(50)]"
              >
              </VTextField>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave  @click="addBed()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
  <v-table
    density="compact"
    class="rounded elevation-1 striped-table"
    fixed-header
    :height="beds?.length > 10 ? 400 : null"
  >
    <thead>
      <tr>
        <th class="text-center border">{{ $t("school.room_name") }}</th>
        <th class="text-center border">{{ $t("summer-sidebar.beds") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(term, tIndex) in roomBeds" :key="tIndex">
        <td class="text-center border">{{term.centerandroom_name}}</td>
        <td class="border">
          <v-row dense>
            <v-col v-for="bed in beds" :key="bed.id" cols="2">
              <VCheckbox
                :value="bed.id"
                :label="bed.name"
                v-model="term.fk_beds"
                hide-details
                dense
              >
              </VCheckbox>
            </v-col>
          </v-row>
        </td>
      </tr>
    </tbody>
    <VBtnSave @click="saveItem()" :loading="saveDataLoading" class="ma-2">
      {{ $t("globals.save") }}
    </VBtnSave>
  </v-table>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      protected_records: false,
      data_message: [],
      beds: [],
      roomBeds: [],
      bed: {},
      semester: {},
      saveDataLoading: false,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      updated_id: undefined,
      delete_id: undefined,
    };
  },
  computed: {
    ...mapState({
      // 8000096 618,
      levels: (state) => state.summer.levels,
      user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getBeds();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      GetHijriMonthsChoices: "GetHijriMonthsChoices",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.saveDataLoading = false;
      this.addDialog = false;
    },
    async getBeds() {
      await this.axios(`${this.base_url}api/bed/list/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.beds = response.data;
      });
    },
    async addBed() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
      this.saveDataLoading = true;
      await this.axios
        .post(this.base_url + "api/center-and-room/", this.bed, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(() => {
          this.$emit("successAlert", this.$t("globals.data_added"));
          this.saveDataLoading = false;
          this.resetForm();
        })
        .catch((error) => {
          if (error.response.data.name_ar) {
            this.$emit("warningAlert", this.$t("summer.already-semester"));
          } else {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          }
          this.saveDataLoading = false;
        });
        await this.getBeds();
              this.getItems();
      }
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/center-and-room-bed/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.roomBeds = response.data;
        this.pagination = response.data.pagination;
        this.loading = false;
      });
    },
    async saveItem() {
      this.saveDataLoading = true;
      let result = await this.axios
        .post(
          this.base_url + "api/center-and-room-bed/",
          {
            center_and_room_bed: this.roomBeds,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then(() => {
          this.$emit("successAlert", this.$t("globals.data_added"));
          this.saveDataLoading = false;
          // this.resetForm();
        })
        .catch((error) => {
          if (error.response.data[0].model) {
            // this.$emit("warningAlert", this.$t("summer.already-semester"));
            this.$emit("errorAlert", this.$t("alert.failure.cannot-delete"));

          } else {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          }
          this.saveDataLoading = false;
        });
      this.getItems();
    },

  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>

<style></style>
