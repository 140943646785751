<template>
  <v-card class="pa-2" v-if="!exist">
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-form ref="form">
        <v-row class="my-2 mt-6">
      <v-col cols="12" md="4" sm="6" class="mb-3">
        <v-select
          color="primary"
          v-model="gov_id"
          density="compact"
          item-title="name"
          item-value="id"
          prepend-inner-icon="mdi-stairs-box"
          :items="listgov"
          :label="`${$t('reportpublic.Governorate')}`"
           clearable
          :menu-props="{ offsetY: true, offsetX: true }"
            :rules="rules"

        />
      </v-col>

      <v-col cols="6" sm="1" class="d-flex align-center">
        <v-btn
          :loading="loading"
          density="comfortable"
          class="w-100 text-white"
          color="primary"
          @click="getGovForReports()"
        >
          <span>{{ $t("globals.show") }}</span>
        </v-btn>
      </v-col>
    </v-row>
    </v-form>
  
  </v-card>
  <v-card id="report" v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{
              $t(
                "reportpublic.StatisticsOfSummerCerntersInTheCapitalsSecretariatDistricts"
              )
            }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-title>
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-end" style="flex: 1">
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                v-bind="props"
                density="compact"
                class="pa-0 mx-1"
                style="min-width: 25px"
                @click="printTable((all = true))"
              >
                <v-icon color="icon-color">mdi-printer</v-icon>
                <v-tooltip class="tooltip" activator="parent" location="top">
                  <small>{{ $t("globals.print-file") }}</small>
                </v-tooltip>
              </v-btn>
            </template>
          </v-menu>
          <v-btn density="compact" class="mx-1" @click="exist = false">
            <span class="text-primary">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <v-container>
        <v-row align="center">
          <v-col cols="auto" class="px-0">
            <v-sheet class="chart-label-sheet" elevation="0" rounded="0">
              {{ $t("reportpublic.StatisticsOfCerntersInDistricts") }}

              {{ $t("reportpublic.InGovernorate") }}
              {{ governorate_name_ar }}
            </v-sheet>
          </v-col>
          <v-col class="pa-0" cols="auto">
            <v-sheet
              :class="`border chart-label-arrow-${this.$i18n.locale} text-center`"
            >
              <h4>
                {{ results.totals_summer }}
              </h4>
            </v-sheet>
          </v-col>
          <v-col cols="max" align-self="center" class="pa-0">
            <v-divider
              :thickness="1"
              class="border-opacity-100"
              color="black"
            ></v-divider>
          </v-col>
          <v-sheet
            color="black"
            height="6"
            width="6"
            style="border-radius: 50%"
          >
          </v-sheet>
        </v-row>
      </v-container>
    </v-card-title>
 
    <v-card-item>
      <!-- <v-row v-if="option_school_number.dataset[0].source.length > 0"> -->
      <v-row>
        <v-col>
          <v-card class="px-2 pt-1 my-4" style="height: 350px">
            <chart :option="option_school_number" autoresize />
          </v-card>
        </v-col>
      </v-row>
      <!-- <v-card-item v-else>
        <h3 class="text-center text-grey-darken-2">
          {{ $t("globals.not-found-items") }}
        </h3>
      </v-card-item> -->
      <!-- <v-row v-if="totalOfStudentsMF.dataset[0].source.length > 0" class="mt-2"> -->
      <v-row>
            <v-col>
        <v-card class="px-2 pt-1 my-4" style="height: 350px">
          <chart :option="totalOfStudentsMF" autoresize />
        </v-card>
            </v-col>
      </v-row>
      <!-- <v-card-item v-else>
        <h3 class="text-center text-grey-darken-2">
          {{ $t("globals.not-found-items") }}
        </h3>
      </v-card-item> -->
    </v-card-item>
  </v-card>
</template>

<script>
import * as echarts from "echarts";
import axios from "axios";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
       await this.getGovernorates();
      await this.$store.commit("updateLoadingValue", false);
    } catch (erroe) {
      await this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async getGovernorates() {
      await axios
        .get(this.base_url + "governorate/?country=" + 1, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.listgov = response.data.map((item) => {
            return {
              id: item.id,
              name: this.$i18n.locale == "ar" ? item.name_ar : item.name_en,
            };
          });
        })
        .catch((error) => {});
    },
    async getGovForReports() {
    const { valid } = await this.$refs.form.validate()
      if(valid)
      {
        this.id = this.gov_id;

        this.results = [];
        this.data = [];
        await this.fetchData();
        this.option_school_number = {
          // color: ["#ff5252", "#445069", "#536dfe", "#FFD95A"],
          title: {
            text: this.$t("reportpublic.NumberOfSchools"),
            right: "center",
            textStyle: {
              fontFamily: "Almarai",
              fontSize: 12,
            },
            subtextStyle: {
              fontFamily: "Almarai",
              fontSize: 10,
            },
          },
          dataset: [
            {
              dimensions: ["name", this.$t("reportpublic.centers")],
              source: this.data.map((item) => [
                this.$i18n.locale == "ar"
                  ? item.directorate_name_ar
                  : item.directorate_name_en,
                item.school_summer_counts,
              ]),
            },
            {
              transform: {
                type: "sort",
                config: { dimension: "constant", order: "desc" },
              },
            },
          ],
          xAxis: {
            type: "category",
            axisLabel: {
              interval: 0,
              rotate: 60,
            },
          },
          yAxis: {
            type: "value",
            position: "right",
          },
          series: [
            {
              type: "bar",
              itemStyle: { borderRadius: [4, 4, 0, 0] },
              label: {
                show: true,
                position: "top",
                rotate: "90",
                offset: [5, 7],
              },
            },
          ],
          legend: {
            bottom: "bottom",
            left: "center",
            itemHeight: 10,
            itemWidth: 13,
            borderWidth: 1,
            borderColor: "black",
            top: "90%",
          },
          transform: {
            type: "sort",
            config: { dimension: "constant", order: "desc" },
          },

          toolbox: {
            feature: {
              saveAsImage: {
                title: this.$t("dashboard.save-as-png"),
              },
            },
          },
          tooltip: {
            trigger: "item",
            axisPointer: {
              type: "shadow",
            },
            formatter: function (params) {
              return (
                "<h5>" +
                params.name +
                "</h5>" +
                params.value[params.componentIndex + 1] +
                "&nbsp;&nbsp;&nbsp;" +
                params.marker +
                " " +
                params.seriesName
              );
            },
          },
          grid: {
            bottom: "30%",
            height: "50%",
            top: "20%",
          },
        };
        this.totalOfStudentsMF = {
          // color: ["#ff5252", "#445069", "#536dfe", "#FFD95A"],
          title: {
            text: this.$t("reportpublic.NumberOfStudentsAndWorkers"),
            right: "center",
            textStyle: {
              fontFamily: "Almarai",
              fontSize: 12,
            },
            subtextStyle: {
              fontFamily: "Almarai",
              fontSize: 10,
            },
          },
          dataset: [
            {
              dimensions: [
                "name",
                this.$t("reportpublic.Students"),
                this.$t("reportpublic.Workers"),
              ],
              source: this.data.map((item) => [
                this.$i18n.locale == "ar"
                  ? item.directorate_name_ar
                  : item.directorate_name_en,
                item.student_summer_counts,
                item.worker_summer_counts,
              ]),
            },
            {
              transform: {
                type: "sort",
                config: { dimension: "constant", order: "desc" },
              },
            },
          ],
          xAxis: {
            type: "category",
            axisLabel: {
              interval: 0,
              rotate: 60,
            },
          },
          yAxis: {
            type: "value",
            position: "right",
          },
          series: [
            {
              type: "bar",
              itemStyle: { borderRadius: [4, 4, 0, 0] },
              label: {
                show: true,
                position: "top",
                rotate: "90",
                offset: [5, 7],
              },
            },
            {
              type: "bar",
              itemStyle: { borderRadius: [4, 4, 0, 0] },
              label: {
                show: true,
                position: "top",
                rotate: "90",
                offset: [5, 7],
              },
            },
          ],
          legend: {
            bottom: "bottom",
            left: "center",
            itemHeight: 10,
            itemWidth: 13,
            borderWidth: 1,
            borderColor: "black",
            top: "90%",
          },
          transform: {
            type: "sort",
            config: { dimension: "constant", order: "desc" },
          },

          toolbox: {
            feature: {
              saveAsImage: {
                title: this.$t("dashboard.save-as-png"),
              },
            },
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
            formatter: function (params) {
              let tooltipText =
                "<h5 class='pa-0 ma-0'>" + params[0].axisValue + "</h5>";
              params.forEach((item, index) => {
                tooltipText +=
                  item.value[index + 1] +
                  "&nbsp;&nbsp;&nbsp;" +
                  item.marker +
                  " " +
                  item.seriesName +
                  "<br/>";
              });

              return tooltipText;
            },
          },
          grid: {
            bottom: "30%",
            height: "50%",
            top: "20%",
          },
        };

        const item = this.listgov.find((i) => i.id === this.gov_id);
        this.governorate_name_ar = item ? item.name : "";
      }
    },
    async fetchData() {
      this.loading = true;
      await axios
        .get(
          `${this.base_url}public-reports/summer-directorate-courses?gov_id=${this.id}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          this.results = response.data.results;
          this.data = response.data.results.details;
 
          if ( this.data.length == 0 )
            {this.$emit("infoAlert", this.$t("globals.not-found-items"));}
          else{ this.exist = true};
        }).catch(()=>{
          
        });
      this.loading = false;
    },
    printChart2() {
      if (this.CheckGetPermission("school.view_student")) {
        const chartElement2 = document.getElementById("totalOfStudentsMF");
        const chart2 = echarts.init(chartElement2);
        chart2.setOption(this.totalOfStudentsMF);
        const element2 = document.getElementById("card2");
        chart2.resize({
          width: element2.offsetWidth - 12,
          height: element2.offsetHeight,
        });
      }
    },
    printChart() {
      if (this.CheckGetPermission("school.view_student")) {
        const chartElement = document.getElementById("studentStatistics");
        const chart = echarts.init(chartElement);
        chart.setOption(this.option_school_number);
        const element = document.getElementById("card");
        chart.resize({
          width: element.offsetWidth - 12,
          height: element.offsetHeight,
        });
      }
    },
    async printTable(all) {
      if (all) {
        this.txt_search = null;
        this.perPage = this.totalItems;
        await this.fetchData();
      }
      window.print();
    },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.printChart2);
    window.removeEventListener("resize", this.printChart);
  },

  data() {
    return {
      data: [],
      id: 1,
      results: [],
      statistics: [],
      option_school_number: {
        dataset: [
          {
            source: [],
          },
        ],

      },
      totalOfStudentsMF: {
        dataset: [
          {
            source: [],
          },
        ],
      },
      size: null,
      gov_id: undefined,
      listgov: [],
      exist: false,
      governorate_name_ar: "",
      loading: false,
      rules: [(value) => (value ? true : this.$t("globals.required_field"))],

    };
  },
  computed: {
    CheckGetPermission() {
      return (prem) => {
        return this.$store.getters.checkpermission(prem);
      };
    },
  },
  components: {
    ReportHeader,
  },
};
</script>
