<template>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_subjectsummer')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      CheckGetPermission('legal_school.delete_subjectsummer', ['sys_admin', 'gen_admin'])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_subjectsummer', ['sys_admin', 'gen_admin'])
    "
    :add_perm="CheckGetPermission('legal_school.add_subjectsummer', ['sys_admin', 'gen_admin'])"
  >
    <template v-slot:item.optional="{ item }">
      <span>
        <v-icon v-if="item.optional == true" color="success">mdi-check-circle</v-icon>
        <v-icon v-if="item.optional == false" color="error">mdi-close-circle</v-icon>
      </span>
    </template>
    <template v-slot:item.is_qauran="{ item }">
      <span>
        <v-icon v-if="item.is_qauran == true" color="success">mdi-check-circle</v-icon>
        <v-icon v-if="item.is_qauran == false" color="error">mdi-close-circle</v-icon>
      </span>
    </template>
    <template v-slot:item.add_to_total="{ item }">
      <span>
        <v-icon v-if="item.add_to_total == true" color="success">mdi-check-circle</v-icon>
        <v-icon v-if="item.add_to_total == false" color="error">mdi-close-circle</v-icon>
      </span>
    </template>
  </DataTable>
  <v-dialog v-model="addDialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          class=""
          @submit.prevent="is_update ? updateSubject() : saveSubject()"
        >
          <VRow class="mt-2">
            <VCol cols="12">
              <VTextField
                v-model="subject.name"
                :label="$t('subject.name')"
                persistent-hint
                autofocus="true"
                clearable
                density="compact"
                prepend-inner-icon="mdi-bookshelf"
                :rules="[$required, $ar_letters_only,$max_length(100)]"
              ></VTextField>
            </VCol>
            <VCol cols="6">
              <VTextField
                v-model="subject.min_score"
                :label="$t('subject.min-score')"
                persistent-hint
                type="number"
                clearable
                density="compact"
                prepend-inner-icon="mdi-bookshelf"
                :rules="[$required, $numeric,$less_than(subject.max_score),$max_value(250)]"
              ></VTextField>
            </VCol>
            <VCol cols="6">
              <VTextField
                v-model="subject.max_score"
                :label="$t('subject.max-score')"
                persistent-hint
                type="number"
                clearable
                density="compact"
                prepend-inner-icon="mdi-bookshelf"
                :rules="[$required, $numeric,$more_than(subject.min_score),$max_value(300)]"
              ></VTextField>
            </VCol>
            <VCol cols="6">
              <v-checkbox
                color="primary"
                v-model="subject.optional"
                :label="$t('subject.optional')"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </VCol>
            <VCol cols="6">
              <v-checkbox
                color="primary"
                v-model="subject.add_to_total"
                :label="$t('subject.add_to_total')"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </VCol>
            <VCol cols="6" class="mb-3">
              <v-checkbox
                color="primary"
                v-model="subject.is_qauran"
                :label="$t('subject.is_quran')"
                density="compact"
                hide-details
              >
              </v-checkbox>
            </VCol>
            <v-col cols="6" class="mb-3" v-if="subject.is_qauran">
              <v-autocomplete
                color="primary"
                v-model="subject.qaran_subject_type"
                density="compact"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-account-outline"
                clearable
                hide-details
                :items="typedocuments"
                :label="$t('subject.quran_type')"
              />
            </v-col>
            <VCol cols="12">
              <VTextarea
                v-model="subject.note"
                clearable
                :label="$t('summer.initiative.note')"
                no-resize
                color="primary"
                counter="250"
                density="compact"
                rows="2"
                :rules="rules.note"
                prepend-inner-icon="mdi-note-outline"
              ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveSubject()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateSubject()" :loading="editDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()" v-if="!is_close">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="closeForm()" v-if="is_close">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      typedocuments: [],
      subjects: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      subject: {
        optional:false,
        is_qauran:false,
        add_to_total:false,
      },
      rules: {
        subjectNameRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,
      is_close: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("subject.name"), key: "name" },
        { title: this.$t("subject.min-score"), key: "min_score", sortable: false },
        { title: this.$t("subject.max-score"), key: "max_score", sortable: false },
        { title: this.$t("subject.optional"), key: "optional", sortable: false },
        { title: this.$t("subject.add_to_total"), key: "add_to_total", sortable: false ,},
        { title: this.$t("subject.is_quran"), key: "is_qauran", sortable: false},
        { title: this.$t("subject.quran_type"), key: "subject_type_name", sortable: false},
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    ...mapGetters({}),
    tableList() {
      if (this.subjects) {
        return JSON.parse(JSON.stringify(this.subjects));
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getTypeDocument();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async getTypeDocument() {
      await this.axios(`${this.base_url}choices/get-type-qaran-subject-choices`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.typedocuments = response.data;
      });
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    closeForm() {
      this.addDialog = false;
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.subject = {};
      this.is_close = false;
      this.is_update = false;
      this.updated_id = undefined;
    },
    editItem(subject) {
      this.subject = subject;
      this.is_close = true;
      this.is_update = true;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveSubject() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.subject.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/subject", this.subject, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.subject = {};
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error.response.data.name) {
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.getItems();
      }
    },
    async updateSubject() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;
        this.subject.data_entry = this.user;
        let result = await this.axios
          .put(this.base_url + "api/summer/subject/" + this.subject.id, this.subject, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
            this.closeForm();
          })
          .catch((error) => {
            this.editDataLoading = false;
            if (error.response.data.name) {
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            } else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(this.base_url + "api/summer/subject/" + delete_id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/subject`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.subjects = response.data.results;
        this.pagination = response.data.pagination;
        // this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>

<style scoped></style>
