<template>
  <DataTable
    v-if="CheckGetPermission('globaltable.view_governorate')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm=" CheckGetPermission('globaltable.delete_governorate', ['sys_admin', 'summer_admin']) "
    :edit_perm=" CheckGetPermission('globaltable.change_governorate', ['sys_admin', 'summer_admin']) "
    :add_perm=" CheckGetPermission('globaltable.add_governorate', ['sys_admin', 'summer_admin']) "
  >
  </DataTable>
  <v-dialog v-model="addDialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="is_update ? updateItem() : saveItem()">
          <VRow class="mt-2">
            <VCol cols="6">
              <VTextField
                v-model="groupQuran.name"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('summer-sidebar.name_groups_quran')"
                density="compact"
                :placeholder="$t('globals.name')"
                :rules="[$ar_letters_only, $required, $max_length(50)]"
              >
              </VTextField>
            </VCol>
            <VCol cols="6">
              <VTextField
                v-model="groupQuran.order"
                maxLength="2"
                prepend-inner-icon="mdi-numeric"
                :label="$t('monthly_marks.order')"
                density="compact"
                :placeholder="$t('monthly_marks.order')"
                :rules="[$required, $numeric]"
              >
              </VTextField>
            </VCol>
            <VCol cols="6">
              <VAutocomplete
                :items="employees"
                v-model="groupQuran.fk_employee"
                prepend-inner-icon="mdi-flag"
                item-title="name_ar"
                item-value="id"
                :label="$t('globals.employee-name')"
                persistent-hint
                density="compact"
                :rules="[$required]"
              ></VAutocomplete>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="groupQuran.note"
                prepend-inner-icon="mdi-note-outline"
                clearable
                color="indigo"
                :label="$t('globals.note')"
                :placeholder="$t('globals.note')"
                no-resize
                counter="250"
                density="compact"
                rows="2"
                :rules="[$max_length(250)]"
              ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveItem()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateItem()" :loading="saveDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()" v-if="!is_close">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="removeDialog()" v-if="is_close">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      protected_records: false,
      data_message: [],
      groupsQuran: [],
      employees: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      groupQuran: {
        name: undefined,
        fk_employee: undefined,
        order: undefined,
        note: undefined,
      },
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_update: false,
      is_close: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer-sidebar.name_groups_quran"), key: "name" },
        { title: this.$t("monthly_marks.order"), key: "order" },
        { title: this.$t("globals.employee-name"), key: "employee_name" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.groupsQuran) {
        return JSON.parse(JSON.stringify(this.groupsQuran));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getEmployees();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.reset();
      this.is_close = false;
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    removeDialog() {
      this.addDialog = false;
    },
    editItem(data) {
      this.groupQuran = Object.assign({}, data);
      this.is_close = true;
      this.is_update = true;
      this.updated_id = data.id;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async getEmployees() {
      await this.axios(`${this.base_url}api/summer/select-employee`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.employees = response.data;
      });
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/quran-groups/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      }).then((response) => {
        this.groupsQuran = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.groupQuran.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/api/summer/quran-groups/", this.groupQuran, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.name) {
              this.$emit("warningAlert", this.$t("summer.already-governorate"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.groupQuran.id != undefined) {
        this.editDataLoading = true;
        this.groupQuran.updated_by = this.user;
        await this.axios
          .put(
            `${this.base_url}/api/summer/quran-groups/${this.groupQuran.id}/`,
            this.groupQuran,
            {  headers: { Authorization: "Bearer " + localStorage.getItem("token") }, }
          )
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
            this.removeDialog();
          })
          .catch((error) => {
            if (error.response.data.name) {
              this.$emit("errorAlert", this.$t("summer.already-governorate"));
            } else this.$emit("warningAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(`${this.base_url}/api/summer/quran-groups/${delete_id}/`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>
<style></style>
