<template>
  <VAppBar density="compact" id="header" elevation='1' >
    <template v-slot:prepend>
      <VAppBarNavIcon id="appIconMenu" @click="changeDrawer()"></VAppBarNavIcon>
    </template>
    <span class=" mt-1"> {{ user_profile.role_name }}</span>
    <!-- log out dialog  -->
    <v-dialog
      v-model="exitDialog"
      width="300"
      :dir="direction"
      class="text-center"
    >
      <v-card class="ma-auto" width="300" elevation="1">
        <v-card-title class="bg-primary text-white">
          <h3>
            {{ $t("globals.log-out") }}
          </h3>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-3 py-4">
          <small>{{ $t("user.logout-confirm") }}</small>
        </v-card-text>
        <v-divider />
        <v-card-actions
          style="min-height: 40px; height: 40px"
          class="pa-0 ma-0 d-flex mx-3"
        >
          <v-btn
            @click="(exitDialog = false), logoutfromsys()"
            class="w-50"
            ripple
          >
            <span class="text-error">{{ $t("globals.exit") }}</span>
          </v-btn>
          <v-divider vertical />
          <v-btn @click="exitDialog = false" class="w-50">
            <span class="text-primary"> {{ $t("globals.cancel") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <VSpacer />

    <themeSwitcher></themeSwitcher>
    <v-btn icon variant="text" size="small" @click="toggleFullscreen"
      ><v-icon :icon="full_screen_icon"></v-icon
    ></v-btn>

    <v-menu :close-on-content-click="false">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          icon
          variant="text"
          size="small"
          class="mt-1"
          v-if="circulars?.length > 0"
          :disabled="!circulars?.length > 0"
        >
          <v-badge color="pink-darken-4" :content="circulars?.length">
            <v-icon class="ma-1">mdi-bell-outline</v-icon>
          </v-badge>
        </v-btn>
        <v-icon v-else class="ma-1">mdi-bell-outline</v-icon>
      </template>
      <v-card
        :dir="direction"
        class="pb-2"
        width="400"
        elevation="1"
        max-height="500"
      >
        <v-card-title class="pa-2">
          <h3>{{ $t("circular.circulars") }}</h3>
        </v-card-title>
        <v-divider class="mb-2"></v-divider>
        <v-card-item
          v-for="circular in circulars"
          class="py-0 my-0"
          :key="circular"
        >
          <v-alert
            class="my-1"
            elevation="0"
            icon="mdi-alert-circle-outline"
            border="start"
            border-color="primary"
            closable
            density="compact"
          >
            <v-alert-title style="font-size: 0.8rem">
              {{ circular.date }}
            </v-alert-title>
            {{ circular.title }}
          </v-alert>
        </v-card-item>
      </v-card>
    </v-menu>
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn v-bind="props" variant="text" size="small" icon>
          <v-img v-if="user_profile.image_user">
            <v-avatar
              size="20"
              :image="base_url + user_profile.image_user"
            ></v-avatar>
          </v-img>
          <v-icon v-else size="large">mdi-account</v-icon>
        </v-btn>
      </template>
      <v-card :dir="direction" rounded="lg" elevation="1" class="px-2">
        <v-layout>
          <v-list >
            <v-list-item
              :prepend-avatar="
                user_profile.image_user
                  ? base_url + user_profile.image_user
                  : ''
              "
              :title="user_profile.username"
              :subtitle="user_profile.email"
              :prepend-icon="user_profile.image_user ? '' : 'mdi-account'"
            >
            </v-list-item>
            <v-divider style="border-width: 1px; opacity: 0.5" class="my-2" />
            <v-list-item
              density="compact"
              prepend-icon="mdi-lock-reset"
              :title="$t('user.reset-password')"
              @click="resetDialog = true"
            >
            </v-list-item>
            <v-divider class="mx-2" />

            <v-list-item
              density="compact"
              prepend-icon="mdi-account-circle-outline"
              :title="$t('user.profile')"
              :to="{ name: 'user_profile' }"
            >
            </v-list-item>
            <v-divider  class="mx-2" />

            <v-list-item
              density="compact"
              prepend-icon="mdi-logout"
              :title="$t('globals.log-out')"
              @click="exitDialog = true"
            >
            </v-list-item>
          </v-list>
        </v-layout>
      </v-card>
    </v-menu>
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          variant="text"
          append-icon="mdi-translate"
          size="small"
        >
          <span class="mt-1">{{
            $i18n.locale == "en" ? "English" : "العربية"
          }}</span>
        </v-btn>
      </template>
      <v-card width="100" rounded="lg" elevation="1" class="px-2">
        <v-list v-model="language" density="compact" >
          <template v-for="(item, i) in languages" :key="i">
            <v-list-item
              min-height="30px"
              density="compact"
              class="rounded-xl"
              :title="languagename[item]"
              @click="changeLocale(item)"
              >
            </v-list-item>
            <v-divider  />
          </template>
        </v-list>
      </v-card>
    </v-menu>
    <v-dialog
      v-model="resetDialog"
      width="300"
      :dir="direction"
      class="text-center"
    >
      <v-card elevation="1">
        <v-card-title>
          <h3>
            {{ $t("globals.confirm") }}
          </h3>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-2 py-1">
          <small>{{ $t("user.confirm-reset") }}</small>
        </v-card-text>
        <v-divider />
        <v-card-actions class="pa-2 justify-space-between">
          <v-btn :loading="resetProgress" @click="resetPassword" class="w-50">
            <span class="text-error">{{ $t("globals.restore") }}</span>
          </v-btn>
          <v-divider vertical />
          <v-btn @click="resetDialog = false" class="w-50">
            <span class="text-primary"> {{ $t("globals.cancel") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </VAppBar>
</template>

<script>
import i18n from "@/i18n";
import themeSwitcher from '@/components/layout/themeSwitcher.vue'
import { mapActions, mapGetters, mapState } from "vuex";
import store from "@/store";
export default {
  components:{
    themeSwitcher
  },
  data() {
    return {
      resetDialog: false,
      resetProgress: false,
      user_profile: {
        email: null,
        username: null,
        full_name: null,
        image_user: null,
      },
      drawer: true,
      create_backup: false,
      exitDialog: false,
      username: undefined,
      overlay: false,
      language: null,
      full_screen_icon: "mdi-fullscreen",
      languages: i18n.global.availableLocales,
      languagename: {
        ar: "العربية",
        en: "English",
      },
    };
  },
  methods: {
    async resetPassword() {
      this.resetProgress = true;
      await this.axios(`${this.base_url}api/user/reset-password`, {
        params: {
          refresh: localStorage.getItem("refresh"),
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          setTimeout(() => {
            localStorage.removeItem("token");
            this.resetProgress = false;
            window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          this.resetProgress = false;
        });
    },
    getFullscreenElement() {
      return (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullscreenElement ||
        document.msFullscreenElement
      );
    },
    toggleFullscreen() {
      if (this.getFullscreenElement()) {
        document.exitFullscreen();
        this.full_screen_icon = "mdi-fullscreen";
      } else {
        document.documentElement.requestFullscreen().catch(console.log);
        this.full_screen_icon = "mdi-fullscreen-exit";
      }
    },
    async getUserProfile() {
      await this.axios
        .get(`${this.base_url}api/user/profile`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.user_profile = response.data;
          if (this.user_profile?.role) {
            this.$store.commit("setRole", this.user_profile.role);
          }
        });
    },
    changeDrawer() {
      this.$store.commit("updateDrawer", !this.getdrawer);
    },

    async changeLocale(currentLocal) {
      if (this.$i18n.locale != currentLocal) {
        i18n.global.locale = currentLocal;
        localStorage.setItem("user-locale", currentLocal);
        const dir =
          localStorage.getItem("user-locale") === "en" ? "ltr" : "rtl";
        this.$store.dispatch(
          "updatelanguage",
          localStorage.getItem("user-locale")
        );
        this.$store.dispatch("updatedirection", dir);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    },
    async logoutfromsys() {
      this.logout;
    },
  },

  async created() {
    // this.$store.dispatch("updatelanguage", localStorage.getItem("user-locale"));
    await this.getUserProfile();
    this.username = localStorage.getItem("username");
  },
  provide() {
    return {};
  },
  computed: {
    direction() {
      return this.$i18n.locale == "en" ? "ltr" : "rtl";
    },
    ...mapGetters(["getlang", "getuser", "getdrawer"]),
    ...mapActions(["logout"]),
    ...mapState({
      circulars: (state) => state.circulars,
    }),
  },
};
</script>

<style scoped>
#search {
  outline: unset;
  color: white;
}
</style>

