<template>
  <!-- added by samer -->
  <!-- شاشة تحضير الطلاب -->
  <VForm ref="form">
    <v-card>
      <v-card-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-card-title>
      <v-card-text class="mt-3">
        <v-row class="py-2">
          <v-col cols="3">
            <v-autocomplete
              color="indigo"
              v-model="dropList.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="month"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
              @update:model-value="getCurrentMonth"
            />
          </v-col>
          <v-col cols="3">
            <DatetimePicker
              v-if="show"
              v-model="dropList.date"
              :minDate="minDate"
              :maxDate="maxDate"
              :rules="[$required]"
              :disabled="dropList.fk_monthsemester ? false : true"
              @update:model-value="items = []"
              forma="dd"
              readOnly
            />
          </v-col>
          <v-col cols="3" md="3">
            <VAutocomplete
              clearable
              :items="levels"
              v-model="dropList.fk_level"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              hide-details="auto"
              :rules="[$required]"
              @update:model-value="
                getDivisionByLevel(),
                  (dropList.fk_division = undefined),
                  (items = []),this.exist = false
              "
            ></VAutocomplete>
          </v-col>
          <v-col cols="3" md="2">
            <VAutocomplete
              clearable
              :items="divisions"
              v-model="dropList.fk_division"
              item-title="fk_division__name"
              item-value="fk_division__id"
              :label="$t('division.select')"
              persistent-hint
              hide-details="auto"
              prepend-inner-icon="mdi-shape-outline"
              :rules="[$required]"
              density="compact"
              @update:model-value="items = [],this.exist = false"
            ></VAutocomplete>
          </v-col>
          <v-col cols="3" md="1" class="mt-2">
            <v-btn
              @click="getData"
              :loading="loading_btn"
              color="primary"
              density="comfortable"
              append-icon="mdi-presentation"
              class="me-3"
            >
              <span class="text-white">
                {{ $t("globals.show") }}
              </span>
            </v-btn>
            <v-label
              v-if="items.length > 0 && 'attendance_id' in items[0]"
              :text="$t('globals.prepared_in_advance')"
            ></v-label>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </VForm>
  <VForm ref="form_1">
    <v-card>
      <v-card-item v-if="this.exist == true">
        <v-data-table-virtual
          :headers="headers"
          :items="items"
          :loading="loading"
          :no-data-text="$t('globals.not-found')"
        >

      <template width="200px !important" v-slot:header.god_men_program="{ column ,index }">
        {{column.title}}{{index}}
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.god_men_program = 0
          })"   color="red"  class="btn-count"
        >0
        </v-btn>
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.god_men_program =1
          })" color="blue" class="btn-count"
          >1
        </v-btn>
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.god_men_program = 2
          })" color="green" class="btn-count"          
          >2
        </v-btn>
      </template>
      <template v-slot:header.curriculum="{ column ,index }">
        {{column.title}}{{index}}
        
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.curriculum = 0
          })" color="red" class="btn-count"
        >0
        </v-btn>
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.curriculum =1
          })" color="blue" class="btn-count"
        >1
        </v-btn>
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.curriculum = 2
          })" color="green" class="btn-count"          
        >2
        </v-btn>
      </template>
      <template v-slot:header.afternoon_prayer="{ column ,index }">
        {{column.title}}{{index}}
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.afternoon_prayer = 0
          })" color="red" class="btn-count"
          >0
        </v-btn>
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.afternoon_prayer =1
          })" color="blue" class="btn-count"
          >1
        </v-btn>
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.afternoon_prayer = 2
          })" color="green" class="btn-count"          
          >2
        </v-btn>
      </template>
      <template v-slot:header.cultural_program="{ column ,index }">
        {{column.title}}{{index}}
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.cultural_program = 0
          })"   color="red"  class="btn-count"
        >0
        </v-btn>
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.cultural_program =1
          })" color="blue" class="btn-count"
          >1
        </v-btn>
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.cultural_program = 2
          })" color="green" class="btn-count"          
          >2
        </v-btn>
      </template>
      <template v-slot:header.evening_prayer="{ column ,index }">
        {{column.title}}{{index}}
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.evening_prayer = 0
          })" color="red" class="btn-count"
          >0
        </v-btn>
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.evening_prayer =1
          })" color="blue" class="btn-count"
          >1
        </v-btn>
        <v-btn @click="items.map(e=>{
          if(e.is_attendance ==true)
          e.evening_prayer = 2
          })" color="green" class="btn-count"          
          >2
        </v-btn>
      </template>        
      <template v-slot:item.index="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:item.is_attendance="{ item }">
        <v-lazy>
          <v-checkbox-btn
            v-model="item.is_attendance"
            @change="item.is_attendance ? (item.is_permission = false) : ''"
            :disabled="item.reason"
            :readonly="item.reason"
          />
        </v-lazy>
      </template>
      <template v-slot:item.is_permission="{ item }">
        <v-lazy>
          <v-checkbox-btn
            v-model="item.is_permission"
            @change="item.is_permission ? (item.is_attendance = false) : ''"
            :readonly="item.reason && item.is_permission"
          >
            <template v-slot:label>
              <v-icon
                v-if="item.reason"
                icon="mdi-alert-circle"
                color="red"
                style="direction: rtl"
                class="ms-4"
              />
              <v-tooltip activator="parent" location="top">
                {{ item.reason }}
              </v-tooltip>
            </template>
          </v-checkbox-btn>
        </v-lazy>
      </template>
      <template v-slot:item.god_men_program="{ item }">
        <v-text-field
          v-model="item.god_men_program"
          type="number"
          style="width: 100px"
          :disabled="!item.is_attendance"
          :rules="[$max_value(2), $positive]"
        />
      </template>
      <template v-slot:item.curriculum="{ item }">
        <v-text-field
          v-model="item.curriculum"
          type="number"
          class="mt-3 mb-3"
          style="width: 100px"
          :disabled="!item.is_attendance"
          :rules="[$max_value(2), $positive]"
        />
      </template>
      <template v-slot:item.afternoon_prayer="{ item }">
        <v-text-field
          v-model="item.afternoon_prayer"
          type="number"
          style="width: 100px"
          :disabled="!item.is_attendance"
          :rules="[$max_value(2), $positive]"
        />
      </template>
      <template v-slot:item.cultural_program="{ item }">
        <v-text-field
          v-model="item.cultural_program"
          type="number"
          style="width: 100px"
          :disabled="!item.is_attendance"
          :rules="[$max_value(2), $positive]"
        />
      </template>
      <template v-slot:item.evening_prayer="{ item }">
        <v-text-field
          v-model="item.evening_prayer"
          type="number"
          style="width: 100px"
          :disabled="!item.is_attendance"
          :rules="[$max_value(2), $positive]"
        />
      </template>
      <template v-slot:item.total="{ item }">
        <h4>{{ item.total }}</h4>
      </template>
        </v-data-table-virtual>
      </v-card-item>
      <v-card-actions>
        <VBtn
          v-if="items.length > 0 && !('attendance_id' in items[0])"
          class="bg-primary ma-3"
          @click.prevent="saveData()"
          size="small"
          :loading="save_loading"
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          v-else-if="items.length > 0 && 'attendance_id' in items[0]"
          class="bg-primary ma-3"
          @click.prevent="saveData()"
          size="small"
          :loading="save_loading"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
      </v-card-actions>
    </v-card>
  </VForm>
</template>

<script>
import moment from "moment-hijri";

export default {
  data() {
    return {
      dropList: {},
      items: [],
      levels: [],
      divisions: [],
      months: [],
      exist: false,
      loading_btn: false,
      loading: false,
      save_loading: false,
      show: true,

      minDate: null,
      maxDate: null,
    };
  },
  async created() {
    this.getlevels();
    await this.getMonths();
  },
  methods: {
    getCurrentMonth() {
        this.dropList.date = undefined
      if (this.dropList.fk_monthsemester) {
        this.items = [];
        this.show = false;
        this.dropList.date = null;
        const month =
          this.dropList.fk_monthsemester < 10
            ? "0" + this.dropList.fk_monthsemester.toString()
            : this.dropList.fk_monthsemester;
        const now = moment();
        const startOfMonth = now.iYear() + "-" + month + "-01";
        const endOfMonth = moment(
          `${now.iYear()}-${this.dropList.fk_monthsemester + 1}-01`,
          "iYYYY-iM-iDD"
        )
          .subtract(1, "day")
          .format("iYYYY-iMM-iDD");
        this.minDate = startOfMonth;
        this.maxDate = endOfMonth;

        if (
          this.months.find(
            (e) =>
              e.month == this.dropList.fk_monthsemester &&
              e.current_month == true
          ) &&
          this.dropList.fk_monthsemester == moment().format("iM")
        )
          this.dropList.date = moment().format("iYYYY-iMM-iDD");
        else this.dropList.date = this.minDate;

        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.dropList.fk_monthsemester = current_month.month;
            this.getCurrentMonth();
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getlevels() {
      let levels = await this.axios.get(this.base_url + `api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.levels = levels.data;
    },  
    async getDivisionByLevel() {
      await this.axios(`${this.base_url}api/division-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        params: {
          fk_level: this.dropList.fk_level,
        },
      })
        .then((response) => (this.divisions = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.exist = false;
        this.loading = true;
        this.loading_btn = true;
        await this.axios(`${this.base_url}/api/student-attendance/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_level: this.dropList.fk_level,
            fk_division: this.dropList.fk_division,
            fk_monthsemester: this.months.find(
              (e) => e.month == this.dropList.fk_monthsemester
            ).id,
            date: this.dropList.date,
          },
        })
          .then((response) => {
            this.items = response.data;
            this.items.map((e) => {
              if (e.attendance_id == undefined && e.reason == undefined)
                e.is_attendance = true;
            });
            if (response.data.length == 0) {
              this.$emit("warningAlert", this.$t("globals.not-found"));
            } else {
              this.exist = true;
            }
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.loading = false;
        this.loading_btn = false;
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form_1.validate();
      if (valid) {
        this.save_loading = true;
        const data = this.items.map((e) => {
          return {
            ...e,
            fk_monthsemester: this.months.find(
              (e) => e.month == this.dropList.fk_monthsemester
            ).id,
            attendance_date: this.dropList.date,
          };
        });

        await this.axios
          .post(
            `${this.base_url}/api/student-attendance/`,
            {
              data: data,
              attendance_date: this.dropList.date,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((e) => {
            this.getData();
            this.$emit("successAlert", this.$t("globals.data_updated"));
          })
          .catch((e) => {
            // if (e.response.status == 406) {
            //   this.$emit("infoAlert", e.response.data);
            //   return;
            // }  
            if (e.response.status == 403) {
              this.$emit("warningAlert", e.response.data.error);
              } else if (e.response.status == 406){
                this.$emit("warningAlert", e.response.data);
              }
              else {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
              }     

            // this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.save_loading = false;
      }
    },
  },
  computed: {
    headers() {
      return [
        { title: this.$t("globals.#"), key: "index" },
        {
          title: this.$t("year.placeholder.name"),
          key: "stu_name",
          sortable: false,
        },
        {
          title:
            this.$t("student-attendance.is-attendance") +
            " / " +
            this.$t("student-attendance.absent"),
          key: "is_attendance",
          sortable: false,
        },
        {
          title: this.$t("student-attendance.is-permission"),
          key: "is_permission",
          sortable: false,
        },

        {
          title: this.$t("globals.god_men_program"),
          key: "god_men_program",
          sortable: false,
        },
        {
          title: this.$t("summer.curriculum.curriculum"),
          key: "curriculum",
          sortable: false,
        },
        {
          title: this.$t("globals.afternoon_prayer"),
          key: "afternoon_prayer",
          sortable: false,
        },
        {
          title: this.$t("globals.cultural_program"),
          key: "cultural_program",
          sortable: false,
        },
        {
          title: this.$t("globals.evening_prayer"),
          key: "evening_prayer",
          sortable: false,
        },
        {
          title: this.$t("school.total_mark_daily"),
          key: "total",
          sortable: false,
        },
      ];
    },
  },
};
</script>
<style scoped>
.btn-count {
    min-width: auto;
    height: auto;
    padding: 0px 6px;
}
</style>