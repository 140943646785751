<template>
  <!-- added by samer -->
  <!-- شاشة تنفيذ المهام الادارية -->
  <v-form ref="form">
    <v-card class="pa-5">
      <v-row>
        <v-col cols="3">
          <v-autocomplete
            v-model="dropList.responsible"
            :items="responsible"
            item-title="name"
            item-value="id"
            :label="$t('globals.responsible_task')"
            persistent-hint
            density="compact"
            :rules="[$required]"
            prepend-inner-icon="mdi-account-tie"
            @update:model-value="items = [],exist=false"
          />
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            color="indigo"
            v-model="dropList.fk_monthsemester"
            density="compact"
            item-title="month_name"
            item-value="month"
            prepend-inner-icon="mdi-calendar-month"
            :items="months"
            :label="$t('month.select')"
            :rules="[$required]"
            @update:model-value="getCurrentMonth,exist=false"
          />
        </v-col>
        <v-col cols="3">
          <DatetimePicker
            v-if="show"
            v-model="dropList.date"
            :minDate="minDate"
            :maxDate="maxDate"
            :rules="[$required]"
            :disabled="dropList.fk_monthsemester ? false : true"
            @update:model-value="items = [],exist=false"
            forma="dd"
            readOnly
          />
          <!-- :defaultData="dropList.date" -->
        </v-col>
        <v-col cols="2">
          <v-autocomplete
            v-model="dropList.iteration"
            :items="iterations"
            item-title="name"
            item-value="id"
            :label="$t('globals.iteration')"
            persistent-hint
            prepend-inner-icon="mdi-refresh"
            density="compact"
            clearable
            :rules="[$required]"
            @update:model-value="items = [],exist=false"
            
          />
        </v-col>
            <v-btn
              @click="getData"
              :loading="loading_btn"
              color="primary"            
              density="comfortable"               
              append-icon="mdi-presentation"
              class="me-3 mt-2"
            >
              <span class="text-white">
                {{ $t("globals.show") }}
              </span>
            </v-btn>
      </v-row>
    </v-card>
  </v-form>
  <v-card v-if="exist">
    <v-data-table-virtual
      v-if="CheckGetPermission('legal_school.view_managmenttask')&&exist"
      :items="items"
      :headers="headers"
      density="compact"
      :loading="loading"
      v-model:sort-by="sortBy"
    >
    <template v-slot:item.index="{item,index}">
      {{ index +1 }}
    </template>
      <template v-slot:item.is_done="{ item }">
        <v-checkbox-btn v-model="item.is_done" />
      </template>
    </v-data-table-virtual>
    <template v-slot:actions>
      
       <VBtnSave  v-if="items.length > 0" @click="saveData" :loading="save_loading">
          {{ $t("globals.save") }}
        </VBtnSave>
    </template>
  </v-card>
</template>
<script>
import DataTable from "@/components/Globals/DataTable.vue";
import moment from "moment-hijri";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      show: true,
      items: [],
      iterations: [],
      responsible: [],
      months: [],

      exist: false,
      loading: false,
      save_loading: false,

      dropList: {},

      minDate: null,
      maxDate: null,

      sortBy:[]
    };
  },
  created() {
    this.getIterations();
    this.getResponsible();

    this.getMonths();
  },

  methods: {
    getCurrentMonth() {
      this.dropList.date = undefined
      if(this.dropList.fk_monthsemester){
        this.items = []
        this.show = false;
        this.dropList.date = null;
        const month =
          this.dropList.fk_monthsemester < 10
            ? "0" + this.dropList.fk_monthsemester.toString()
            : this.dropList.fk_monthsemester;
        const now = moment();
        const startOfMonth = now.iYear() + "-" + month + "-01";
      

        // const endOfMonth =
        //   now.iYear() +
        //   "-" +
        //   month +
        //   "-" +       
        // now.endOf("iMonth").iDate()

        const endOfMonth = moment(`${now.iYear()}-${ this.dropList.fk_monthsemester +1 }-01`,'iYYYY-iM-iDD').subtract(1,'day').format('iYYYY-iMM-iDD')
        this.minDate = startOfMonth;
        this.maxDate = endOfMonth;

        if (this.months.find((e) =>e.month == this.dropList.fk_monthsemester && e.current_month == true)&& this.dropList.fk_monthsemester == moment().format("iM"))
          this.dropList.date = moment().format("iYYYY-iMM-iDD")
        else 
        this.dropList.date = this.minDate;


        this.$nextTick(() => {
          this.show = true;
        });
      }
    },

    async getData() {
     
      this.items = [];
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.loading = true;
        this.loading_btn = true;
        await this.axios(
          `${this.base_url}/api/initialization-managmenttask/get-management-task/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              iteration: this.dropList.iteration,
              responsible: this.dropList.responsible,
              date: this.dropList.date,
            
            },
          }
        )
          .then((response) => {
            this.items = response.data;
            if(this.items.length === 0){
              this.$emit("warningAlert", this.$t("globals.not-found"));
            }  else {
              this.exist = true;
            }
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      } else this.items = [];

      this.loading = false;
      this.loading_btn = false;
    },
    async getIterations() {
      await this.axios(`${this.base_url}/choices/activity-iterations`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.iterations = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getResponsible() {
      await this.axios(`${this.base_url}/choices/specialists-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.responsible = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.dropList.fk_monthsemester = current_month.month;
            this.getCurrentMonth();
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async saveData() {
      this.save_loading = true;
      const data = this.items.map((e) => {
        return {
          ...e,
          fk_monthsemester: this.months.find(
            (e) => e.month == this.dropList.fk_monthsemester
          ).id,
        };
      });

      await this.axios
        .post(
          `${this.base_url}/api/initialization-managmenttask/save-operations/`,
          data,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((e) => {
          this.getData();
          this.$emit("successAlert", this.$t("globals.data_updated"));
        })
        .catch((e) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
      this.save_loading = false;
    },
  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    headers() {
      return [
        { title: this.$t("globals.#"), key: "index" },
        { title: this.$t("globals.task-name"), key: "task_name" },
        { title: this.$t("globals.executed"), key: "is_done" },
        { title: this.$t("globals.note"), key: "note" },
      ];
    },
  },
};
</script>

