<template>
    <div class="d-flex text-center align-center pt-2 border mb-1">
      <div class="w-25">
        <slot name="header-start">
          <div class="d-flex align-center flex-column justify-center">
            <div class="text-h5">
              {{ $t("globals.rep-of-yemen-ar") }}
            </div>
            <div class="text-h6 pt-1">
              {{ $t("summer.prime-minister-office") }}
            </div>
            <div class="text-subtitle-1 pt-1">
              {{
                $t("summer.supreme-committee-for-activities-and-summer-courses")
              }}
            </div>
          </div>
        </slot>
      </div>
      <div class="w-50">
        <slot name="header-center">
          <div class="d-flex align-center flex-column">
            <div class=" pb-2" >
              {{ $t("globals.in_the_name_of_allah") }}
            </div>
            <img :src="mainImage" alt="" style="width: 20%" />
          </div>
          <slot name="header-title">
            <div class="d-flex justify-center py-2">
              <div class="text-h4">
                {{ $t("globals.in_the_name_of_allah") }}
              </div>
            </div>
          </slot>
        </slot>
      </div>
      <div class="p-2 w-25">
        <slot name="header-end">
          <div class="d-flex align-center flex-column">
            <img :src="summerImage" alt="" style="width: 60%" />
            <!-- <div class="text-subtitle-1 text-green-darken-4">
              {{ $t("summer.general-administration-for-summer-courses") }}
            </div> -->
          </div>
        </slot>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      mainImage: require("@/assets/summer-report-image.png"),
      summerImage: require("@/assets/summer_centers_logo.png"),
    };
  },
};
</script>

<style scoped>
.flex {
  flex: 1;
}
*{
  font-family: "Almarai" !important;
}
</style>
