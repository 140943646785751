<template>
  <v-form ref="form">
    <v-card class="pa-2">
      <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="semesters"
            v-model="filter_data.fk_semester"
            prepend-inner-icon="mdi-stairs-box"
            item-title="name_ar"
            item-value="id"
            :label="$t('semester.name')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            @update:modelValue="getMonthsBySemester(), (this.filter_data.fk_monthsemester = null,exist=0)"
            :rules="[$required]"
          ></VAutocomplete>
        </VCol>
        <v-col cols="3">
          <v-autocomplete
            v-model="filter_data.fk_monthsemester"
            density="compact"
            item-title="month_name"
            item-value="month"
            prepend-inner-icon="mdi-calendar-month"
            :items="months"
            :label="$t('month.select')"
            :rules="[$required]"
            @update:model-value="exist=0"
          />
        </v-col>
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="levels"
            v-model="filter_data.fk_level"
            prepend-inner-icon="mdi-stairs-box"
            item-title="name_ar"
            item-value="id"
            :label="$t('summer.level.name_ar')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            :rules="[$required]"
            @update:modelValue="getDivisionByLevel(),getSubjects(), (this.filter_data.fk_division = null,this.filter_data.subject_id = null,exist=0)"
          ></VAutocomplete>
        </VCol>
        <VCol cols="3" class="mb-1">
            <VAutocomplete
              :items="divisions"
              v-model="filter_data.fk_division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="fk_division"
              :label="$t('division.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
              @update:model-value="exist=0"
            ></VAutocomplete>
        </VCol>
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="subjects"
            v-model="filter_data.subject_id"
            prepend-inner-icon="mdi-shape-outline"
            item-title="subject_name"
            item-value="id"
            :label="$t('subject.name')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            :rules="[$required]"
            @update:model-value="exist=0"
          ></VAutocomplete>
        </VCol>
        <v-col cols="1" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="primary"
            :loading="loading"
            @click="getItems()"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-table
      density="compact"
      class="rounded elevation-1 striped-table"
      fixed-header
      :height="months?.length > 10 ? 400 : null"
      v-if="exist&&!isQuran" 
    >
      <thead>
        <tr>
          <th :rowspan="2">{{ $t("school.student_name") }}</th>
          <th>{{ $t("monthly_marks.attendance") }}</th>
          <th>{{ $t("monthly_marks.assigments") }}</th>
          <th>{{ $t("monthly_marks.oral") }}</th>
          <th>{{ $t("monthly_marks.ehsan") }}</th>
          <th>{{ $t("monthly_marks.activity") }}</th>
          <th>{{ $t("monthly_marks.total") }}</th>
        </tr>
        <tr>
          <th>{{ $t("monthly_marks.mark") }} {{setting.attendance}}</th>
          <th>{{ $t("monthly_marks.mark") }} {{setting.assignment}}</th>
          <th>{{ $t("monthly_marks.mark") }} {{setting.oral}}</th>
          <th>{{ $t("monthly_marks.mark") }} {{setting.behaviior_charity}}</th>
          <th>{{ $t("monthly_marks.mark") }} {{setting.monthly_activity}}</th>
          <th>{{ $t("monthly_marks.mark") }} {{getTotalHead(setting)}}</th>
        </tr>
      </thead>
      <tbody>
          <tr v-for="(student, wIndex) in students_list" :key="wIndex">
            <td style="width: 18%;"> {{ student.student_name }}</td>
            <td style="width: 15%;"> 
                <VTextField
                  v-model="student.attendance"
                  :label="$t('monthly_marks.enter_mark')"
                  density="compact"
                  clearable
                  maxLength="2"
                  @input="student.attendance = parseInt(student.attendance)||0"
                  :rules="[$max_value(setting.attendance),$numeric]"
                >
                </VTextField>
                  <!-- @input="handleInput('attendance',$event)" -->
            </td>
            <td style="width: 15%;"> 
                <VTextField
                  v-model="student.assigments"
                  :label="$t('monthly_marks.enter_mark')"
                  density="compact"
                  clearable
                  @input="student.assigments = parseInt(student.assigments)||0"
                  maxLength="2"
                  :rules="[$max_value(setting.assignment),$numeric]"
                >
                </VTextField>
            </td>
            <td style="width: 15%;"> 
                <VTextField
                  v-model="student.oral"
                  :label="$t('monthly_marks.enter_mark')"
                  density="compact"
                  clearable
                  @input="student.oral = parseInt(student.oral)||0"
                  maxLength="2"
                  :rules="[$max_value(setting.oral),$numeric]"
                >
                </VTextField>
            </td>
            <td style="width: 15%;"> 
                <VTextField
                  v-model="student.behaviour_charity"
                  :label="$t('monthly_marks.enter_mark')"
                  density="compact"
                  clearable
                  maxLength="2"
                  @input="student.behaviour_charity = parseInt(student.behaviour_charity)||0"
                  :rules="[$max_value(setting.behaviior_charity),$numeric]"
                >
                </VTextField>
            </td>
            <td style="width: 15%;"> 
                <VTextField
                  v-model="student.monthly_activity"
                  :label="$t('monthly_marks.enter_mark')"
                  density="compact"
                  clearable
                  @input="student.monthly_activity = parseInt(student.monthly_activity)||0"
                  maxLength="2"
                  :rules="[$max_value(setting.monthly_activity),$numeric]"
                >
                </VTextField>
            </td>
            <td style="width: 7%;"> {{ getTotal(student) }}</td>

          </tr>
      </tbody>
      <v-card-actions class="mx-2 pt-0">
        <v-btn @click="saveItem" :loading="saveDataLoading" class="bg-primary" size="small">
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-table>
    <v-table
      density="compact"
      class="rounded elevation-1 striped-table"
      fixed-header
      :height="months?.length > 10 ? 400 : null"
      v-if="exist&&isQuran" 
    >
      <thead>
        <tr>
          <th>{{ $t("school.student_name") }}</th>
          <th>{{ $t("monthly_marks.first_week") }}</th>
          <th>{{ $t("monthly_marks.second_week") }}</th>
          <th>{{ $t("monthly_marks.third_week") }}</th>
          <th>{{ $t("monthly_marks.four_week") }}</th>
          <th>{{ $t("monthly_marks.total") }}</th>
          <th>{{ $t("monthly_marks.estimate") }}</th>
          <th v-if="qaran_subject_type==1">{{ $t("monthly_marks.pre_save") }}</th>
          <th v-if="qaran_subject_type==1">{{ $t("monthly_marks.save") }}</th>
          <th v-if="qaran_subject_type==2">{{ $t("monthly_marks.pre_etqan") }}</th>
          <th v-if="qaran_subject_type==2">{{ $t("monthly_marks.etqan") }}</th>
        </tr>
      </thead>
      <tbody>
          <tr v-for="(student, wIndex) in students_list" :key="wIndex">
            <td style="width: 12%;"> {{ student.student_name }}</td>
            <td >
                <VTextField
                  v-model="student.first_week"
                  :label="$t('monthly_marks.enter_mark')"
                  density="compact"
                  clearable
                  @input="student.first_week = parseInt(student.first_week)||0"
                  maxLength="2"
                  :rules="[$max_value(setting.quran_degree_weekly),$numeric]"
                >
                </VTextField>
            </td>
            <td> 
              <VTextField
                  v-model="student.second_week"
                  :label="$t('monthly_marks.enter_mark')"
                  density="compact"
                  clearable
                  @input="student.second_week = parseInt(student.second_week)||0"
                  maxLength="2"
                  :rules="[$max_value(setting.quran_degree_weekly),$numeric]"
                >
                </VTextField>
            </td>
            <td > 
              <VTextField
                  v-model="student.third_week"
                  :label="$t('monthly_marks.enter_mark')"
                  density="compact"
                  clearable
                  @input="student.third_week = parseInt(student.third_week)||0"
                  maxLength="2"
                  :rules="[$max_value(setting.quran_degree_weekly),$numeric]"
                >
                </VTextField>
            </td>
            <td> 
                <VTextField
                  v-model="student.four_week"
                  :label="$t('monthly_marks.enter_mark')"
                  density="compact"
                  clearable
                  @input="student.four_week = parseInt(student.four_week)||0"
                  maxLength="2"
                  :rules="[$max_value(setting.quran_degree_weekly),$numeric]"
                >
                </VTextField>
            </td>
            <td > {{ getTotalWeekly(student)}}</td>
            <td> {{student.estimate_name_ar}}
            </td>
            <td v-if="qaran_subject_type==1"> 
                <VAutocomplete
                :items="surah"
                v-model="student.pervious_save_quran"
                prepend-inner-icon="mdi-stairs-box"
                item-title="name"
                item-value="id"
                :label="$t('monthly_marks.to')"
                clearable
                hide-details="auto"
                disabled="true"
                persistent-hint
                density="compact"
              ></VAutocomplete>
            </td>
            <td v-if="qaran_subject_type==1"> 
                <VAutocomplete
                :items="surah"
                v-model="student.save_quran"
                prepend-inner-icon="mdi-stairs-box"
                item-title="name"
                item-value="id"
                :label="$t('monthly_marks.to')"
                clearable
                hide-details="auto"
                persistent-hint
                density="compact"
              ></VAutocomplete>
            </td>
            <td v-if="qaran_subject_type==2"> 
                <VAutocomplete
                :items="surah"
                v-model="student.pervious_perfection_quran"
                prepend-inner-icon="mdi-stairs-box"
                item-title="name"
                item-value="id"
                :label="$t('monthly_marks.to')"
                clearable
                hide-details="auto"
                persistent-hint
                disabled="true"
                density="compact"
              ></VAutocomplete>
            </td>
            <td v-if="qaran_subject_type==2">
                <VAutocomplete
                :items="surah"
                v-model="student.perfection_quran"
                prepend-inner-icon="mdi-stairs-box"
                item-title="name"
                item-value="id"
                :label="$t('monthly_marks.to')"
                clearable
                hide-details="auto"
                persistent-hint
                density="compact"
              ></VAutocomplete>
            </td>
          </tr>
      </tbody>
      <v-card-actions class="mx-2 pt-0">
        <v-btn @click="saveItem" :loading="saveDataLoading" class="bg-primary" size="small">
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-table>
  </v-form>
</template>
<script>
  import useValidate from "@vuelidate/core";
  import { mapState, mapActions } from "vuex";
  import DataTable from "@/components/Globals/DataTable.vue";
  import { required, helpers } from "@vuelidate/validators";
  import moment from "moment-hijri";

  export default {
    components: {
      DataTable,
    },
    data() {
      return {
        v$: useValidate(),
        addDialog: false,
        protected_records: false,
        data_message: [],
        students_list: [],
        levels: [],
        divisions: [],
        semesters: [],
        subjects: [],
        estimates: [],
        surah: [],
        months: [],
        show: true,
        filter_data: {
          fk_level: undefined,
          students_list: [],
        },
        saveLoading: false,
        monthsemester:  null,
        exist: undefined,
        isQuran: undefined,
        qaran_subject_type: undefined,
        dynamicHeaders: [],
        quranGroupChoice: [],
        student: {
          attendance:'',
          assigments:'',
          oral:'',
          monthly_activity:'',
          behaviour_charity:'',
        },
        setting: {},
        saveDataLoading: false,
        editDataLoading: false,
        pagination: {
          count: 0,
          current_page: 1,
          num_pages: 0,
        },
        is_update: false,
        updated_id: undefined,
        delete_id: undefined,
      };
    },
    computed: {
      ...mapState({
        user: (state) => state.User,
      }),
      tableList() {
        if (this.students_list) {
          return JSON.parse(JSON.stringify(this.students_list));
        }
      },
      order_data() {
        try {
          return this.sortBy[0].order == "desc"
            ? `-${this.sortBy[0].key}`
            : this.sortBy[0].key;
        } catch (error) {
          return "id";
        }
      },
      CheckGetPermission() {
        return (prem, role) => {
          return this.$store.getters.checkpermission(prem, role);
        };
      },
    },
    async created() {
      try {
        await this.$store.commit("updateLoadingValue", true);
        await this.getSemester();
        await this.getlevels();
        await this.getSetting();
        await this.getMonths();
        await this.getSurah();
        await this.getEstimates();
        this.$store.commit("updateLoadingValue", false);
      } catch (error) {
        this.$store.commit("updateLoadingValue", false);
      }
    },
    validations() {
      return {
        filter_data: {
          fk_level: {
            required: helpers.withMessage(this.$t("errors.required"), required),
          },
          subject_id: {
            required: helpers.withMessage(this.$t("errors.required"), required),
          },
        },
      };
    },
    methods: {
      getTotalHead(obj){
        let sum = parseInt(obj.attendance) + parseInt(obj.assignment) + parseInt(obj.oral) + parseInt(obj.behaviior_charity) + parseInt(obj.monthly_activity);
        return sum;
      },
      getTotal(obj){
        let sum = obj.attendance + obj.assigments + obj.oral + obj.behaviour_charity + obj.monthly_activity;
        return sum;
      },
      getTotalWeekly(obj){
        let sum = obj.first_week + obj.second_week + obj.third_week + obj.four_week;
        return sum;
      },
      async getDivisionByLevel() {
        if (this.filter_data.fk_level) {
          let levelDivisions = await this.axios.get(
            this.base_url + `api/summer/get-level-division/${this.filter_data.fk_level}`,
            {
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            }
          );
          this.divisions = levelDivisions.data.division;
        }
      },
      async getSetting() {
        let semester = await this.axios.get(
          this.base_url + `/api/setting-system/`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );
        this.setting = semester.data;
      },
      async getSurah() {
        let semester = await this.axios.get(
          this.base_url + `choices/sural-quran-choices`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );
        this.surah = semester.data;
      },
      async getlevels() {
        let levels = await this.axios.get(this.base_url + `api/level-choices`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        });
        this.levels = levels.data;
      },    
      async getEstimates() {
        let semester = await this.axios.get(
          this.base_url + `/api/estimate/list/`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );
        this.estimates = semester.data;
      },
      async getSemester() {
        let semester = await this.axios.get(
          this.base_url + `api/summer/semesters/list/`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );
        this.semesters = semester.data;
      },
      async getMonthsBySemester() {
      if(this.filter_data.fk_semester)
        await this.axios(
          `${this.base_url}/api/months-semester/?fk_semester=${this.filter_data.fk_semester}`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        )
          .then((response) => {
            this.months = response.data;
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },
    getCurrentMonth() {
      this.list_students = [];
      this.show = false;
      this.filter_data.date = null;
      const month =
        this.filter_data.fk_monthsemester < 10
          ? "0" + this.filter_data.fk_monthsemester.toString()
          : this.filter_data.fk_monthsemester;
      const now = moment();
      const startOfMonth = now.iYear() + "-" + month + "-01";
      const endOfMonth =
        now.iYear() +
        "-" +
        this.filter_data.fk_monthsemester +
        "-" +
        now.endOf("iMonth").iDate();
      this.minDate = startOfMonth;
      this.maxDate = endOfMonth;
      this.filter_data.date = this.minDate;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.filter_data.fk_monthsemester = current_month.month;
            this.filter_data.fk_semester = current_month.fk_semester;
            this.getCurrentMonth();
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
      checkrole(role = []) {
        return this.$store.getters.checkrole(role);
      },
      async getSubjects() {
        if (this.filter_data.fk_level) {
          let response = await this.axios.get(
            this.base_url + `api/level-subject/?fk_level=${this.filter_data.fk_level}`,
            {
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            }
          );
          this.subjects = response.data;
        }
      },
      async getItems() {
        this.monthsemester = this.months.find(month => month.month === this.filter_data.fk_monthsemester);
        this.v$.$validate();
        const { valid } = await this.$refs.form.validate();
        if (valid) {
          this.exist = undefined;
          this.loading = true;
          await this.axios(`${this.base_url}api/mark-summer-month/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              fk_level: this.filter_data.fk_level,
              LevelSubject: this.filter_data.subject_id,
              fk_division: this.filter_data.fk_division,
              fk_monthsemester: this.monthsemester.id,
            },
          }).then((response) => {
            this.students_list = response.data.students_list;
            if(this.students_list.length === 0){
              this.$emit("warningAlert", this.$t("globals.not-found"));
            }  else {
              this.exist = true;
            }
            this.isQuran = response.data.subject_is_quran;
            this.qaran_subject_type = response.data.qaran_subject_type;
            this.loading = false;
          }).catch((error) => {
              this.loading = false;
            });
        }
      },
      async saveItem() {
        this.v$.$validate();
        const { valid } = await this.$refs.form.validate();
        if (valid) {
          this.saveDataLoading = true;
          await this.axios
            .post(this.base_url + `api/mark-summer-month/`, this.students_list, {
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            })
            .then(() => {
              this.$emit("successAlert", this.$t("globals.data_added"));
              this.saveDataLoading = false;
              this.getItems();
            })
            .catch((error) => {
              if (error.response.data.error) {
                this.$emit("warningAlert", error.response.data.error);
              } else {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
              }
              this.saveDataLoading = false;
            });
        }
      },
    },
  };
</script>