<template>
  <!-- added by samer -->
  <!-- شاشة  عرض خطة المدرسين -->
  <DataTable
    v-if="CheckGetPermission('legal_school.view_employeelevel')"
    :items="items"
    :headers="headers"
    :method="getData"
    :create="() => (dialog = true)"
    :pagination="pagination"
    :editItem="editData"
    :delItem="deleteData"
    :del_perm="
      CheckGetPermission('legal_school.delete_employeelevel', ['summer_admin'])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_employeelevel', ['summer_admin'])
    "
    :add_perm="
      CheckGetPermission('legal_school.add_employeelevel', ['summer_admin'])
    "
  >
  </DataTable>
    
  <v-dialog v-model="dialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="saveData">
      
          <v-autocomplete
            v-model="dropList.fk_employee"
            item-title="name_ar"
            item-value="id"
            prepend-inner-icon="mdi-account-tie"
            clearable
            :items="teachers"
            :label="$t('teacher.select')"
            :rules="[$required]"
          />
          <v-autocomplete
            :items="semesters"
            v-model="dropList.fk_semester"
            prepend-inner-icon="mdi-calendar-month"
            item-title="name_ar"
            item-value="id"
            :label="$t('semester.semester')"
            clearable
            density="compact"
            :rules="[$required]"
          />
          <v-autocomplete
            clearable
            :items="levels"
            v-model="dropList.fk_level"
            item-title="name_ar"
            item-value="id"
            :label="$t('summer.level.level')"
            prepend-inner-icon="mdi-stairs-box"
            :rules="[$required]"
            @update:model-value="
              getDivisionByLevel(),
                (dropList.fk_division = undefined),
                (dropList.fk_subject_id = undefined)
            "
          />

          <v-autocomplete
            color="primary"
            v-model="dropList.fk_subject_id"
            item-title="name"
            item-value="subject_id"
            prepend-inner-icon="mdi-bookshelf"
            clearable
            :label="$t('subject.select')"
            :items="subjects.filter((e) => e.fk_level == dropList.fk_level)"
            :rules="[$required]"
          />
          <v-autocomplete
            clearable
            :items="divisions"
            v-model="dropList.fk_division"
            item-title="fk_division__name"
            item-value="fk_division__id"
            :label="$t('division.select')"
            prepend-inner-icon="mdi-shape-outline"
            :rules="[$required]"
          />
          <VTextarea
            v-model="dropList.note"
            clearable
            prepend-inner-icon="mdi-note-outline"
            :label="$t('globals.note')"
            counter="250"
            density="compact"
            rows="2"
            :rules="[$max_length(250)]"
          ></VTextarea>
        </VForm>
      </v-card-text>

      <VCardActions class="mx-3">
        <VBtnUpdate
          v-if="dropList.id"
          @click="updateData()"
          :loading="loading_btn"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtnSave v-else @click="saveData()" :loading="loading_btn">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtn class="mx-3" size="small" @click="dropList = {}">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      dropList: {},
      pagination: {},
      items: [],
      teachers: [],
      levels: [],
      divisions: [],
      subjects: [],
      semesters: [],
      loading_btn: false,
      dialog: false,
    };
  },
  created() {
    this.getTeacher();
    this.getLevels();
    this.getAllSubjects();
    this.getSemester();
  },
  methods: {
    async getData(page = 1, per_page = 10, ordering = "id", search = null) {
      if(ordering == 'employee_name') ordering ='fk_employee__name_ar'
      else if(ordering == '-employee_name') ordering ='-fk_employee__name_ar'
      if(ordering == 'subject_name') ordering ='fk_subject_id__name'
      else if(ordering == '-subject_name') ordering ='-fk_subject_id__name'
      if(ordering == 'division_name') ordering ='fk_division__name'
      else if(ordering == '-division_name') ordering ='-fk_division__name'
      if(ordering == 'level_name') ordering ='fk_level__name_ar'
      else if(ordering == '-level_name') ordering ='-fk_level__name_ar'
      if(ordering == 'semester_name') ordering ='fk_semester__name_ar'
      else if(ordering == '-semester_name') ordering ='-fk_semester__name_ar'
      if(ordering == 'year_name') ordering ='fk_year__year'
      else if(ordering == '-year_name') ordering ='-fk_year__year'
      await this.axios(`${this.base_url}api/summer/employee-level/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      })
        .then((response) => {
          this.items = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading_btn = true;
        await this.axios
          .post(this.base_url + "api/summer/employee-level/", this.dropList, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.dropList = {};
            this.getData();
          })
          .catch((error) => {
            if (error.response?.data?.non_field_errors) {
                this.$emit("errorAlert", this.$t("alert.failure.unique") + '  '+'الموظف والمرحلة والشعبة والمادة' );
                   this.loading_btn = false;
                return 
              }   
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.loading_btn = false;
      }
    },
    async editData(data) { 
      this.dropList = { ...data };
      await this.getDivisionByLevel()
      this.dialog = true;
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        await this.axios
          .put(
            this.base_url +
              "/api/summer/employee-level/" +
              this.dropList.id +
              "/",
            this.dropList,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.dialog = false;
            this.getData();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async deleteData(delete_id) {
      if (delete_id) {
        var status;
        await this.axios
          .delete(
            this.base_url + "api/summer/employee-level/" + delete_id + "/",
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((e) => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getTeacher() {
      await this.axios(`${this.base_url}api/summer/get-teacher`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.teachers = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivisionByLevel() {
      await this.axios(`${this.base_url}api/division-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        params: {
          fk_level: this.dropList.fk_level,
        },
      })
        .then((response) => (this.divisions = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getAllSubjects() {
      await this.axios(`${this.base_url}/api/summer/subjects-by-level`, {
        params: {
          level: "all",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.subjects = response.data;
      });
    },
    async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
    },
  },
  computed: {
    headers() {
      return [
        { title: this.$t("summer.level.name_ar"), key: "level_name" },
        { title: this.$t("division.name"), key: "division_name" },
        { title: this.$t("subject.name"), key: "subject_name" },
        { title: this.$t("teacher.name"), key: "employee_name" },
        { title: this.$t("semester.semester"), key: "semester_name" },
        { title: this.$t("year.name"), key: "year_name" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) this.dropList = {};
    },
  },
};
</script>