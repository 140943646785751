<template>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_supervisor')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getSupervisors"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editSupervisor"
    :delItem="deleteSupervisor"
    :del_perm="CheckGetPermission('legal_school.delete_supervisor')"
    :edit_perm="CheckGetPermission('legal_school.change_supervisor')"
    :add_perm="CheckGetPermission('legal_school.add_supervisor')"
  >
  
    <template v-slot:item.type="{ item }">
      {{ getSuperVisorTypeName(item.type) }}
    </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
    
  >
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="saveSupervisor">
          <VRow class="mt-2">
            <VCol cols="12">
              <VTextField
                v-model="supervisor.name"
                ref="supName"
                autofocus
                :label="$t('summer.supervising-authority-name')"
                density="compact"
                :rules="rules.supervisor_name"
                prepend-inner-icon="mdi-office-building"
              >
              </VTextField>
            </VCol>
            <VCol cols="12">
              <VAutocomplete
                :items="SuperVisorType"
                v-model="supervisor.type"
                item-title="name"
                item-value="id"
                :label="$t('summer.supervision-type')"
                persistent-hint
                density="compact"
                :rules="supervisor_type_rule"
                prepend-inner-icon="mdi-text"
              ></VAutocomplete>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-3">
        <VBtnSave
          v-if="!is_update "
          @click="saveSupervisor()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update "
          @click="updateSupervisor()"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()" v-if="!is_close">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="removeDialog()" v-if="is_close">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
  <alert-cascade-records
    v-model="alert_cascade"
    :data_message="data_message"
    :title="alert_cascade_title"
    :loading="deleteDataLoading"
    @confirm-delete="confirmDelete"
  >
  </alert-cascade-records>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      saveDataLoading: false,
      addDialog: false,
      editDataLoading: false,
      deleteDataLoading: false,
      data_message: [],
      alert_cascade_title: "",
      alert_cascade: false,
      force_delete: false,
      supervisors: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      supervisor: {},
      
      rules: {
        supervisor_name: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) ||
            this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
      },
      is_close: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.supervising-authority-name"), key: "name" },
        {
          title: this.$t("summer.supervision-type"),
          key: "type",
          sortable: false,
        },
        {
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
      SuperVisorType: (state) => state.summer.SuperVisorType,
    }),
    ...mapGetters({}),
    tableList() {
      if (this.supervisors) {
        return JSON.parse(JSON.stringify(this.supervisors));
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getSupervisors();
      await this.getSuperVisorType();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    removeDialog() {
      this.addDialog = false;
    },
    async confirmDelete() {
      this.force_delete = true;
      const res = await this.deleteSupervisor(this.delete_id);
      if (res == true) {
        this.$emit("successAlert", this.$t("globals.data_deleted"));
        this.getSupervisors();
      } else {
        this.$emit("errorAlert", this.$t("alert.failure.delete"));
      }
      this.alert_cascade = false;
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getSuperVisorType: "summer/getSuperVisorType",
    }),
    resetForm() {
      this.$refs.form?.resetValidation();
      this.supervisor = {};
      this.is_close = false;
      this.is_update = false;
      this.updated_id = undefined;
    },
    editSupervisor(supervisor) {
      this.supervisor = supervisor;
      this.is_close = true;
      this.is_update = true;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    getSuperVisorTypeName(type) {
      let superVisor = this.SuperVisorType.find(
        (element) => element.id == type
      );
      return superVisor ? superVisor.name : "";
    },
    async saveSupervisor() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.supervisor.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/supervisor/", this.supervisor, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.addDialog = false;
            this.saveDataLoading = false;
            this.$refs.supName.focus();
            this.resetForm();
            this.getSupervisors();
          })
          .catch((error) => {
            if (error.response.data.name)
              this.$emit("errorAlert", error.response.data.name[0]);
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
      }
    },
    async updateSupervisor() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;
        this.supervisor.data_entry = this.user;
        let result = await this.axios
          .put(
            `${this.base_url}api/summer/supervisor/${this.supervisor.id}/`,
            this.supervisor,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.getSupervisors();
            setTimeout(() => {
              this.resetForm();
              this.removeDialog();
            }, 1000);
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
      }
    },
    async deleteSupervisor(delete_id) {
      if (delete_id) {
        this.delete_id = delete_id;
        var status;
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(`${this.base_url}api/summer/supervisor/${delete_id}/`, {
            params: {
              force_delete: this.force_delete,
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            status = true;
          })
          .catch((error) => {
            if (error.response?.status == 406) {
              this.deleteDataLoading = false;
              this.alert_cascade = true;
              this.alert_cascade_title = this.$t(
                "alert.attention.delete-supervisor-with-school"
              );
              this.data_message = error.response.data;
            } else {
              this.deleteDataLoading = false;
              status = error;
            }
          });
        return status;
      }
    },
    async getSupervisors(
      page = 1,
      per_page = 10,
      ordering = null,
      search = null
    ) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/supervisor/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.supervisors = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
    alert_cascade(val) {
      if (val == false) {
        this.force_delete = false;
        this.delete_id = undefined;
        this.del_loading = false;
      }
    },
  },
};
</script>
