<template>
  <VListGroup
    :value="$t('report.summer-center')"
    :fluid="false"
    v-if="checkRole(['summer_admin', 'user'])"
  >
    <template v-slot:activator="{ props }">
      <VListItem v-bind="props" min-height="40">
        <template v-slot:title>
          <span class="v-select-title">{{ $t("report.summer-center") }}</span>
        </template>
        <template v-slot:prepend>
          <v-tooltip :text="$t('report.summer-center')">
            <template v-slot:activator="{ props }">
              <v-icon v-show="expand == true" v-bind="props">
                mdi-chart-pie
              </v-icon>
              <v-icon v-show="expand == false">mdi-chart-pie </v-icon>
            </template>
          </v-tooltip>
        </template>
      </VListItem>
    </template>
    <VListGroup :value="$t('summer-report.general-report')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.general-report") }}</span>
          </template>
        </VListItem>
      </template>

      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'summer-center-data' }"
        :value="$t('summer-report.summer-center-data')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.summer-center-data")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'approach-statistics' }"
        :value="$t('summer-report.approach-statistics-by-level')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.approach-statistics-by-level")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'supplies-statistic' }"
        :value="$t('summer-report.material-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.material-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employees-by-role' }"
        :value="$t('summer-report.employee-available-by-level')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.employee-available-by-level")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'clender-report' }"
        :value="$t('summer-report.school-calendar')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.school-calendar")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'vacations' }"
        :value="$t('summer-report.occasion')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.occasion")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'support-Visitors-Entity-final' }"
        :value="$t('summer-report.supporting-entity-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.supporting-entity-statistics")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup :value="$t('summer-report.students-reports')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.students-reports") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'students-info' }"
        :value="$t('summer-report.student-personal-data')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.personal-data")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'student-attendance-report' }"
        :value="$t('summer-report.attendance')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.attendance")
          }}</span>
        </template>
      </VListItem>      
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'student-daily-attendance-absence-report' }"
        :value="$t('summer-report.attendance_daily')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.attendance_daily")
          }}</span>
        </template>
      </VListItem>      
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'droupout-report-week' }"
        :value="$t('summer-report.student-statistics-by-leaking')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.student-statistics-by-leaking")
          }}</span>
        </template>
      </VListItem>            
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'talents' }"
        v-if="
          CheckGetPermission('legal_school.change_talent') ||
          CheckGetPermission('legal_school.add_talent') ||
          CheckGetPermission('legal_school.view_talent')
        "
        :value="$t('summer-report.students-talents')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.students-talents")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'talented_students' }"
        v-if="CheckGetPermission('legal_school.view_studentsummer')"
        :value="$t('summer-sidebar.talented-students')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-sidebar.talented-students")
          }}</span>
        </template>
      </VListItem>
      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'dropout_students' }"
        v-if="CheckGetPermission('legal_school.view_studentsummer')"
        :value="$t('summer-sidebar.dropout-students')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-sidebar.dropout-students")
          }}</span>
        </template>
      </VListItem> -->
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'droupout-report' }"
        :value="$t('summer-report.walkout-students-by-reason')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.walkout-students-by-reason")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'students-statistics' }"
        :value="$t('summer-report.students-statistics-by-level')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.students-statistics-by-level")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'student-marks-reports' }"
        :value="$t('summer-report.students-marks-sheet')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.students-marks-sheet")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-initiative-suscriber' }"
        :value="$t('summer-report.initiatives-participant')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.initiatives-participant")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup :value="$t('summer-report.lectures-timetable')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.lectures-timetable") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        v-if="CheckGetPermission('legal_school.view_marksummer')"
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'schedule-summer' }"
        :value="$t('summer-report.weekly-lectures-timetable')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.weekly-lectures-timetable")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup :value="$t('summer-report.teachers-reports')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.teachers-reports") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'teachers-data' }"
        :value="$t('summer-report.teacher-personal-data')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.personal-data")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-attendance-report' }"
        :value="$t('summer-report.employee-attendance')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.employee-attendance")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'visitors-report' }"
        :value="$t('summer-report.visits-reports')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.visits-reports")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-initiative' }"
        :value="$t('summer-report.initiatives-reports')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.initiatives-reports")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup :value="$t('summer-report.activity-reports')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.activity-reports") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-activities-by-type-activity' }"
        :value="$t('summer-report.summer-center-activities')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.summer-center-activities")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'weekly-activities' }"
        :value="$t('summer-report.weekly-activity-schedule')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.weekly-activity-schedule")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'students-by-Type-activity' }"
        :value="$t('summer-report.activity-participated-student')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.activity-participated-student")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-details-activities' }"
        :value="$t('summer-report.activity-full-data')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.activity-full-data")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <!-- <VListGroup :value="$t('summer-report.weeekly-reports')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.weeekly-reports") }}</span>
          </template>
        </VListItem>
      </template> -->

      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'approach-statistics-week' }"
        :value="$t('summer-report.curreculam-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.curreculam-statistics")
          }}</span>
        </template>
      </VListItem> -->
      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-activities-by-week-type' }"
        :value="$t('summer-report.actiivty-excution-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.actiivty-excution-statistics")
          }}</span>
        </template>
      </VListItem> -->

      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'management-statistic-by-week' }"
        :value="$t('summer-report.management-tasks-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.management-tasks-statistics")
          }}</span>
        </template>
      </VListItem> -->
      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'positives-negatives' }"
        :value="$t('summer-report.positives-negatives')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.positives-negatives")
          }}</span>
        </template>
      </VListItem> -->


    <!-- </VListGroup> -->
    <VListGroup :value="$t('summer-report.final-reports')" :fluid="false">
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.final-reports") }}</span>
          </template>
        </VListItem>
      </template>
      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-attendance-report-final' }"
        :value="$t('summer.report.employee-attendance-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer.report.employee-attendance")
          }}</span>
        </template>
      </VListItem> -->
      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-discipline-week' }"
        :value="$t('summer-report.workers-displine-level')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.workers-displine-level")
          }}</span>
        </template>
      </VListItem>       -->
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'absorption-statistics' }"
        :value="$t('summer-report.absorption-statistics')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.absorption-statistics")
          }}</span>
        </template>
      </VListItem>
      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-discipline-final' }"
        :value='$t("summer-report.teachers-statistics-final")'
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.teachers-statistics")
          }}</span>
        </template>
      </VListItem> -->
      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-discipline-portfolio-final' }"
        :value="$t('report.staff-meeting-minutes-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("report.staff-meeting-minutes")
          }}</span>
        </template>
      </VListItem> -->
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-discipline-portfolio-by-week' }"
        :value="$t('report.staff-meeting-minutes')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("report.staff-meeting-minutes")
          }}</span>
        </template>
      </VListItem>      
      <!-- <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'students-statistics-final' }"
        :value="$t('summer-report.students-count-statistic-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.students-count-statistic")
          }}</span>
        </template>
      </VListItem> -->
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'droupout-report-final' }"
        :value="$t('summer-report.student-statistics-by-leaking-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.student-statistics-by-leaking")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'approach-statistics-final' }"
        :value="$t('summer-report.curreculam-statistics-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.curreculam-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'schedule-statistic' }"
        :value="$t('summer-report.lectures-statistics-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.lectures-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'all-activities' }"
        :value="$t('summer-report.actiivty-excution-statistics-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.actiivty-excution-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'management-statistic' }"
        :value="$t('summer-report.management-tasks-statistics-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.management-tasks-statistics")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'positives-negatives-final' }"
        :value="$t('summer-report.positives-negatives-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.positives-negatives")
          }}</span>
        </template>
      </VListItem>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'employee-discipline-final' }"
        :value="$t('summer-report.workers-displine-level-final')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.workers-displine-level")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>
    <VListGroup
      :value="$t('summer-report.committee-members-reports')"
      :fluid="false"
    >
      <template v-slot:activator="{ props }">
        <VListItem
          v-bind="props"
          :class="direction === 'rtl' ? 'prtl-50' : 'pltr-50'"
        >
          <template v-slot:title>
            <v-icon size="small" class="me-2">mdi-minus</v-icon>
            <span>{{ $t("summer-report.committee-members-reports") }}</span>
          </template>
        </VListItem>
      </template>
      <VListItem
        :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
        :to="{ name: 'socail-committees' }"
        :value="$t('summer-report.committee-members')"
      >
        <template v-slot:title>
          <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
          <span class="v-select-subtitle">{{
            $t("summer-report.committee-members")
          }}</span>
        </template>
      </VListItem>
    </VListGroup>

    <VListItem
      :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
      :to="{ name: 'evaluation-report' }"
      :value="$t('globals.evaluation_report')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          $t("globals.evaluation_report")
        }}</span>
      </template>
    </VListItem>

    <VListItem
      :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
      :to="{ name: 'residential-students-report' }"
      :value="$t('globals.residential_students_report')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          $t("globals.residential_students_report")
        }}</span>
      </template>
    </VListItem>

    <VListItem
      :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
      :to="{ name: 'education-program-operation-report' }"
      :value="$t('globals.education_program_operation_report')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          $t("globals.education_program_operation_report")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
      :to="{ name: 'student-data-report' }"
      :value="$t('globals.student_data_report')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          $t("globals.student_data_report")
        }}</span>
      </template>
    </VListItem>

    <VListItem
      :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
      :to="{ name: 'card-report' }"
      :value="$t('globals.cards')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{ $t("globals.cards") }}</span>
      </template>
    </VListItem>

    <VListItem
      :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
      :to="{ name: 'certificates-report' }"
      :value="$t('report.report_header.Monthly_Marks')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          $t("report.report_header.Monthly_Marks")
        }}</span>
      </template>
    </VListItem>

    <VListItem
      :class="direction === 'rtl' ? 'prtl-60' : 'pltr-60'"
      :to="{ name: 'certificates-final-report' }"
      :value="$t('report.finalmarkreport')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="mx-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          $t("report.finalmarkreport")
        }}</span>
      </template>
    </VListItem>
  </VListGroup>
</template>

<script>
export default {
  props: {
    show: Boolean,
    expand: Boolean,
    direction: String,
    CheckGetPermission: {
      type: Function,
      required: true,
    },
  },
  computed: {
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
  },
};
</script>
<style scoped>
</style>


