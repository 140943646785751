<template>
  <v-expansion-panels model-value="1" class="mb-2" >
    <v-expansion-panel  value="1">
      <v-expansion-panel-title class="px-4">
          <span>{{$t('globals.select-criteria')}}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text >
        <v-row class="py-2">
          <v-col cols="12" md="4" sm="4">
            <v-autocomplete
              color="primary"
              v-model="visitor.visitortype"
              density="compact"
              item-title="name"
              item-value="key"
              prepend-inner-icon="mdi-account-outline"
              clearable
              hide-details
              :items="type_statistics"
              :label="$t('summer.visitors.type-visitor')"
            />
          </v-col>
          <v-col cols="1">
            <v-btn 
            :loading="loading" 
            @click="getItems((page = 1)),
            loading=true" 
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white mt-2"
            color="primary"        
            >
              {{ $t("globals.show") }}
              <!-- <v-icon icon="mdi-content-save" color="white" end></v-icon> -->
            </v-btn>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
    <DataTable
      v-if="CheckGetPermission('globaltable.view_governorate')"
      :items="tableList"
      :headers="headers"
      density="compact"
      :method="getItems"
      :create="
        () => {
          $router.push({ name: 'add-visitor' })
        }
      "
      :pagination="pagination"
      :editItem="editItem"
      :delItem="deleteItem"
      :del_perm="
        CheckGetPermission('globaltable.delete_governorate', ['sys_admin', 'summer_admin'])
      "
      :edit_perm="
        CheckGetPermission('globaltable.change_governorate', ['sys_admin', 'summer_admin'])
      "
      :add_perm="
        CheckGetPermission('globaltable.add_governorate', ['sys_admin', 'summer_admin'])
      "
    >
    <template v-slot:item.visitortype="{ item }">
      <span>
        <v-span v-if="item.visitortype == false">زيارة</v-span>
        <v-span v-if="item.visitortype == true">جهات مساندة</v-span>
      </span>
    </template>
    <template v-slot:item.img="{ item }">
      <v-dialog max-width="900" v-if="item.img">
        <template v-slot:activator="{ props: activatorProps }">
          <dir :title="$t('school.student_image')">
            <v-avatar v-bind="activatorProps" class="border">
              <v-img :src="`${item.img}`"></v-img>
            </v-avatar>
          </dir>
        </template>
        <template v-slot:default="{ isActive }">
          <v-card :title="$t('school.student_image')" :subtitle="item.name">
            <template v-slot:append>
              <dir :title="$t('globals.close')">
                <v-btn @click="isActive.value = false" icon size="small">
                  <v-icon color="error">mdi-close</v-icon>
                </v-btn>
              </dir>
            </template>
            <v-responsive class="mx-5 my-2 border-md border-dashed">
              <v-img
                :src="`${item.img}`"
                class="ma-1 rounded-lg"
              ></v-img>
            </v-responsive>
          </v-card>
        </template>
      </v-dialog>
    </template>
    <!-- <template v-slot:item.img="{ item }">
      <a :href="item.img" download>
        <img 
        :src="item.img" 
        width="50" 
        style="cursor: pointer"
        />
      </a>
    </template> -->
    </DataTable>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
export default {
  data() {
    return {
      exist:false,
      loading:false,
      loading_delete:false,
      homeworks: [],
      visitors: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 1000, text: this.$t("globals.all") },
      ],
      visitor: {
        name_ar: undefined,
        adjective: "",
        impression: "",
        recommendation: "",
        evaluation: "",
        occasion: undefined,
        entity: undefined,
        date: undefined,
        data_entry: undefined,
        updated_by: undefined,
      },
      type_statistics: [
        { key: 0, name: "زيارة", is_visior: false },
        { key: 1, name: "جهة مساندة", is_visior: true },
        // { key: 3, name: "الكل" },
      ],
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      is_visior: true,
      rules: {
        name_ar: [(value) => !!value || this.$t("globals.required_field")],
        occasion: [(value) => !!value || this.$t("globals.required_field")],
        date: [(value) => !!value || this.$t("globals.required_field")],
        is_visior: [(value) => !!value || this.$t("globals.required_field")],

        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,
      visitType: 3,
      v$: useValidate(),
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      adj_entity: "adjective",
      selectedHead: [
        "semester_name",
        "name_ar",
        "entity_name",
        "visitortype",
        "date",
        "subject_submitted_to_school",
        "occasion",
        "adjective",
        "impression",
        "evaluation",
        "note",
        "actions",
      ],
      headers: [
        { title: this.$t("semester.name"), key: "semester_name" },
        { title: this.$t("summer.visitors.name-visitor"), key: "name_ar" },
        { title: this.$t("summer.visitors.entity-visitor"), key: "entity_name", sortable: false, },
        { title: this.$t("summer.visitors.type-visitor"), key: "visitortype" },
        { title: this.$t("summer.visitors.date-visitor"), key: "date" },
        { title: this.$t("summer.visitors.title-to-school"), key: "subject_submitted_to_school" },
        { title: this.$t("summer.visitors.occasion"), key: "occasion  " },
        { title: this.$t("summer.visitors.image-visitor"), key: "img", sortable: false },
        { title: this.$t("summer.visitors.adjective-visitor"), key: "adjective" },
        { title: this.$t("summer.visitors.impression"), key: "impression" },
        { title: this.$t("summer.visitors.recommendations"), key: "recommendation" },
        { title: this.$t("summer.visitors.evaluation"), key: "evaluation" },
        { title: this.$t("globals.note"), key: "note",sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      txt_search: undefined,
    };
  },
  components: { Datetime },
  computed: {
    statisticdata() {
      if (this.visitType == 1 || this.visitType == 2) {
        return this.statistics.filter((el) => el.type == this.visitType);
      } else {
        return this.statistics;
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.visitors) {
        return JSON.parse(JSON.stringify(this.visitors));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems()
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    resetForm() {
      this.$refs.form.resetValidation();
      this.visitor = {};
      this.is_update = false;
      this.updated_id = undefined;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        this.loading_delete = true;
        await this.axios
          .delete(
            `${this.base_url}api/summer/visitor/${delete_id}`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },  
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/visitor`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          visitortype: this.visitor.visitortype,
          page_size: this.perPage,
          sort_by: ordering,
        },
      }).then((response) => {
        this.visitors = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        if (this.visitors.length==0) {
          this.$emit("infoAlert", this.$t("globals.not-found"));
        } 
        this.loading = false;
      }).catch((error)=>{
        this.loading=false;
        this.$emit("errorAlert", this.$t("globals.error_in_data"));
          setTimeout(()=>{
              this.showAlert=false
          },1500)
      });

    },
    editItem(item) {
      this.$router.push(`${item.id}/edit/`);
    },
    addvisitor() {
      this.$router.push({ name: "add-visitor" });
    },
  },
  watch: {
    perPage() {
      this.getItems();
    },
    page() {
      this.getItems(this.page);
    },
  },
};
</script>