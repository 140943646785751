<template>
  <v-table
    density="compact"
    class="rounded elevation-1 striped-table"
    fixed-header
    :height="months?.length > 10 ? 400 : null"
  >
    <thead>
      <tr>
        <th class="text-center border">{{ $t("semester.semester") }}</th>
        <th class=" border">{{ $t("month.cureent_month") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="text-center border w-25">{{ semestersPlan.semester_name_ar }}</td>
        <td class="border">
          <v-row dense>
            <v-col v-for="month in months" :key="month.id" cols="2">
              <VCheckbox
                :value="month.fk_monthsemester"
                :label="month.month_name"
                v-model="semestersPlan.fk_monthsemester"
                hide-details
              >
              </VCheckbox>
            </v-col>
          </v-row>
        </td>
      </tr>
    </tbody>
    <VBtnSave @click="saveItem()" :loading="saveDataLoading" class="ma-2">
      {{ $t("globals.save") }}
    </VBtnSave>
  </v-table>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      protected_records: false,
      data_message: [],
      semestersPlan: {
        fk_monthsemester:false
      },
      semestersPlans: [],
      months: [],
      saveDataLoading: false,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      updated_id: undefined,
      delete_id: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getMonthsChoices();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    async getMonthsChoices() {
      this.loading = true;
      await this.axios(`${this.base_url}api/summer/semester-plan/get-semester-months/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.months = response.data;
        this.loading = false;
      });
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/summer/semester-plan/months-semester-current/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.semestersPlan = response.data;
        this.loading = false;
      });
    },
    async saveItem() {
          this.saveDataLoading = true;
          await this.axios
            .post(this.base_url + "api/summer/semester-plan/save-current-month/", 
            {
              fk_semester: this.semestersPlan.fk_semester,
              fk_monthsemester: this.semestersPlan.fk_monthsemester,
            }, {
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            })
            .then(() => {
              this.$emit("successAlert", this.$t("globals.data_added"));
              this.saveDataLoading = false;
            })
            .catch((error) => {
              if (error.response.data[0]) {
                this.$emit("warningAlert", this.$t("summer.already-releated"));
              } else {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
              }
              this.saveDataLoading = false;
            });
          this.getItems();
    },
  },
};
</script>
<style></style>
