<template>
  <!-- <v-card elevation="2" class="v-card-table border border-primary" rounded> -->
  <v-card ref="report" class="pb-2">
    <custom-report-header
      class="show-item"
      
    />
    <v-card-text class="mb-0 pb-0">
      <v-row class="mt-1 mb-2 hide-item">
        <v-col cols="4">
          <v-text-search
            class="search"
            v-model="txt_search"
            @input="!txt_search ? fetchData() : ''"
            @keyup.enter="fetchData()"
          />
        </v-col>
        <v-col cols="8" class="text-end">
          <v-btn-group class="ms-2">
            <v-btn @click="deleteItem(selectedItems)" color="error" v-if="selectedItems?.length>0">
              <span>{{$t('globals.delete')}}</span>
              <v-icon icon="mdi-trash-can" end></v-icon>
            </v-btn>
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props">
                  <v-icon class="me-2">mdi-eye-outline</v-icon>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="h in header"
                    :key="h.key"
                    :label="h.title"
                    color="primary"
                    v-model="h.show"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-btn v-if="print" @click="printReport">
              <span>{{ $t("globals.print") }}</span>
              <v-icon icon="mdi-printer" end></v-icon>
            </v-btn>

            <v-btn v-if="imports" @click="importsdrawer">
              <span>{{ $t("import.import") }}</span>
              <v-icon icon="mdi-file-excel" color="success" end></v-icon>
            </v-btn>

            <v-btn v-if="exports" @click="exportdata()" :loading="exportProgress">
              <span>{{ $t("export.export") }}</span>
              <v-icon icon="mdi-file-excel"  end></v-icon>
            </v-btn>
            <v-btn  @click="create" v-if="add_perm && create">
              <span>{{ $t("globals.create") }}</span>
              <v-icon icon="mdi-plus-thick"  end></v-icon>
            </v-btn>
          </v-btn-group>
          <slot name="action"></slot>
        </v-col>
      </v-row>
      <v-card  :disabled="loading" >
        <v-data-table-server
          id="vDataTable"
          :show-select="deleteMultiple?true:false"
         
          :height="items?.length<=10?null:480"
          v-model="selectedItems"
          v-model:sort-by="sortBy"
          :loading="loading"
          :headers="
            !isPrint
              ? visibleHeaders
              : visibleHeaders.filter((e) => e.key != 'actions')
          "
          :items="filter ? filter_items : items"
          density="compact"
          @update:options="fetchData(page=1,perPage=10)"
          item-value="id"
          :no-data-text="$t('globals.not-found')"
          class="custom-header"
        >
          <template v-slot:loading>
            <v-skeleton-loader :type="`table-row-divider@${4}`" />
          </template>
          <template
            v-for="(slot, name) in $slots"
            :slot="name"
            v-slot:[name]="scope"
            :key="slot"
          >
            <slot :name="name" v-bind="scope"></slot>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn-group class="bg-background" >
              <v-btn
              variant="text"
              class="v-btn-icon"
              v-if="edit_perm"
              @click="edit(item)"
            >
              <v-icon color="warning">mdi-pencil-outline</v-icon>
            </v-btn>
            <v-btn
              variant="text"
              class="v-btn-icon"
              v-if="del_perm"
              @click="deleteItem(item.id)"
            >
              <v-icon color="error">mdi-trash-can-outline</v-icon>
            </v-btn>
            </v-btn-group>
          </template>

          <template #bottom>
            <div class="d-flex" id="bottom">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="listItemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card>
    </v-card-text>
    <slot name="card-actions"></slot>
  </v-card>
  <!-- </v-card> -->

  <!-- FILTER -->

  <!-- <custom-delete-dialog v-model="del_dialog" @confirmDelete="deleteData" /> -->
  <delete-dialog
    v-model="del_dialog"
    :loading="deleteDataLoading"
    @confirm-delete="deleteData"
  ></delete-dialog>

  <alert
    v-model="show_alert"
    :iconColor="alert_icon_color"
    :icon="alert_icon"
    :title="alert_title"
    :message="alert_message"
  >
  </alert>

  <v-dialog
    color="primary"
    :close-on-content-click="false"
    v-model="loadingPage"
    persistentid="loadingPage"
    width="auto"
  >
    <h1 v-if="!loadingImage" class="text-white">l o a d i n g . . .</h1>
    <div style="width: 80px; height: 80px">
      <v-img :src="loadingImage"></v-img>
    </div>
  </v-dialog>
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
  
</template>
<script>
import { log } from 'pdfmake/build/pdfmake';
// import * as method from '@/plugins/methods/global-methods'
export default {
  name: "CustomDataTable",
  data() {
    return {
      showSelect:false,
      exportProgress:false,
      selectedItems:[],
      selectedRow: null,
      perm: this.$route.matched.find((e) => e.name == this.$route.name).props
        .default.perm,
      printContent: undefined,
      // added by basel
      file: null,
      import_drawer: false,
      show_dialog_filter: false,
      filter: false,
      filter_items: [],
      isPrint: false,
      header: [],
      txt_search: undefined,
      sortBy: [],
      loading: false,
      page: 1,
      perPage: 10,
      delBtn: false,
      editBtn: false,
      listItemsPerPage: [
        { id: 0, value: 10, text: "10" },
        { id: 1, value: 25, text: "25" },
        { id: 2, value: 50, text: "50" },
        { id: 3, value: 100, text: "100" },
        { id: 4, value: "all", text: "الكل" },
      ],
      del_dialog: false,
      delete_id: undefined,
      deleteDataLoading: false,
      show_alert: false,
      alert_title: "",
      alert_message: "",
      alert_icon_color: "",
      alert_icon: "",
      data_message: [],
      protected_records: false,
      showImg_dialog: false,
      dialog_title: "",
      dialog_image: "",
      filter_list: [
        {
          logiccalOperatore: undefined,
          field: undefined,
          condition: undefined,
          value: undefined,
        },
      ],
    };
  },
  async created() {
    this.headers
      .filter((e) => e.show == undefined)
      .map((r) => {
        r.show = true;
      });
    if(!this.del_perm&&!this.edit_perm){
      this.header = [...this.headers.filter(header=>header.key!='actions')];
    }else{
      this.header = [...this.headers];
    }

  },
  props: {
    perm: Object,
    deleteMultiple:Function,
    actionList: [Array, Boolean],
    method: Function,
    delItem: Function,
    editItem: Function,
    exports: [Function, Boolean],
    del_perm: {
      type: Boolean,
      default: true,
    },
    edit_perm: {
      type: Boolean,
      default: true,
    },
    add_perm: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    pagination: {
      type: Object,
      default: () => ({
        count: 0,
        current_page: 1,
        num_pages: 0,
      }),
    },
    export: {
      type: Boolean,
      default() {
        return false;
      },
    },
    create: [Function,Boolean],

    // add by basel
    url: null,
    parms: null,
    imports: [Function, Boolean],
    additional_exports: Function,
    file_name: "ملف",
    // add by basel
  },
  computed: {
    condition_list() {
      return [
        { id: 1, name: "يساوي" },
        { id: 2, name: "اكبر من" },
        { id: 3, name: "اصغر من" },
        { id: 4, name: "لايساوي" },
        { id: 5, name: "يحتوي" },
        { id: 6, name: "لا يحتوي" },
        { id: 7, name: "يبدء بـ" },
        { id: 8, name: "ينتهي بـ" },
        { id: 9, name: "بين" },
      ];
    },
    itemsPerPage() {
      if(this.items )
      if (this.items.length < 10)
        return this.listItemsPerPage.filter((e) => e.id == 0 || e.id == 4);
      else if (this.items.length < 25)
        return this.listItemsPerPage.filter((e) => e.id <= 1 || e.id == 4);
      else if (this.items.length < 50)
        return this.listItemsPerPage.filter((e) => e.id <= 2 || e.id == 4);
      else return this.listItemsPerPage;
    },
    visibleHeaders() {
      return this.header.filter((header) => header.show);
    },
  },
  watch: {
    del_dialog(val){
      if(!val){
        this.deleteDataLoading = false;
      }
    },
    headers() {
      this.header = [];
      this.headers
        .filter((e) => e.show == undefined)
        .map((r) => {
          r.show = true;
        });
      this.header = [...this.headers];
    },
    perPage() {
      this.selectedItems=[]
      this.page=1
      this.fetchData(this.page, this.perPage);
    },
    page() {
      this.selectedItems=[]
      this.fetchData(this.page, this.perPage);
    },
  },

  methods: {
    selectRow(item) {
      if (this.selectedRow === item.id) this.selectedRow = null;
      else this.selectedRow = item.id;
    },
    printReport() {
      this.isPrint = true;
      setTimeout(() => {
        const printContent = this.$refs.report.$el.innerHTML;
        document.getElementById("printView").style.display = "block";
        document.getElementById("printView").innerHTML = printContent;
        document.getElementById("appViewId").style.display = "none";
        document.getElementById("vDataTable").style.height = "auto";
        window.print();
        document.getElementById("printView").innerHTML = "";
        document.getElementById("printView").style.display = "none";
        document.getElementById("appViewId").style.display = "block";
        document.getElementById("vDataTable").style.height = "450px";
        this.isPrint = false;
      }, 100);
    },
    async items_filter(status) {
      this.filter = status;
      if (this.filter) {
        // const { valid } = await this.$refs.form_1.validate()
        // if(valid)
        this.filter_items = [...this.applayFilter()];
      } else this.filter_items = [...this.items];
    },
    addFilter() {
      this.filter_list.push({
        logiccalOperatore: undefined,
        field: undefined,
        condition: undefined,
        value: undefined,
      });
    },
    removeFilter(index) {
      this.filter_list.splice(index, 1);
    },
    applayFilter() {
      this.filter = true;
      const data = this.items.filter((item) => {
        let isMatch = true;
        for (const filter of this.filter_list) {
          const field = item[filter.field];
          const value = filter.value;
          let match = false;
          switch (filter.condition) {
            case 1:
              match = field == value;
              break;
            case 2:
              match = field < value;
              break;
            case 3:
              match = field > value;
              break;
          }
          if (filter.logiccalOperatore === 1) {
            isMatch = isMatch && match;
          } else if (filter.logiccalOperatore == 2) {
            isMatch = isMatch || match;
          } else {
            isMatch = isMatch == match;
          }
        }
        return isMatch;
      });
      return data;
    },
    // add by basel
    async importData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        const formData = new FormData();
        formData.append("file", this.file[0]);
        await this.$axios
          .post(this.url, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.resetImportForm();
          })
          .catch(() => {
            this.$emit("alert", "errorData");
          });
      }
    },
    async exportdata() {
      this.exportProgress = true;
      await this.exports()
      this.exportProgress = false;
    },
    importsdrawer() {
      this.import_drawer = true;
    },
    resetImportForm() {
      (this.file = null), (this.import_drawer = !this.import_drawer);
    },

    // add by basel
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    deleteItem(data) {
      this.delete_id = data;
      this.del_dialog = true;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async fetchData(page = 1, perPage = 10) {
      this.loading = true;
      await this.method(page, perPage, this.order_data(), this.txt_search);
      this.loading = false;
      this.listItemsPerPage[4].value=this.pagination?.count
    },
    addMenu(item) {
      try {
        return this.actionList(item);
      } catch (error) {
        return [];
      }
    },
    async deleteData() {
      this.deleteDataLoading = true;
      await this.delItem(this.delete_id).then((e) => {
        if (e == true) {
          this.fetchData();
          this.selectedItems=[];
          this.setSuccessAlert();
        } else {
          if (e?.message?.includes(418)) {
            this.protected_records = true;
            let error_messages = [];
            e.response?.data?.data?.forEach((error) => {
              error_messages.push(
                this.$t(`models.${error.model}`) + ` = ${error.count}`
              );
            });
            this.data_message = error_messages;
          } else {
            this.setErrorAlert();
          }
        }
      });
      this.deleteDataLoading = false;
      this.del_dialog = false;
      this.delete_id = undefined;
    },
    setErrorAlert() {
      this.alert_icon_color = "error";
      this.alert_icon = "cancel";
      this.alert_title = this.$t("alert.failure.title");
      this.alert_message = this.$t("alert.failure.delete");
      this.showAlert();
    },
    setSuccessAlert() {
      this.alert_icon_color = "success";
      this.alert_icon = "check-circle";
      this.alert_title = this.$t("alert.success.title");
      this.alert_message = this.$t("alert.success.delete");
      this.showAlert();
    },
    setWarningAlert(message) {
      this.alert_icon_color = "warning";
      this.alert_icon = "alert";
      this.alert_title = this.$t("warning");
      this.alert_message = message;
      this.showAlert();
    },
    showAlert() {
      this.show_alert = true;
      setTimeout(() => {
        this.show_alert = false;
      }, 1500);
    },
    edit(object) {
      this.editItem(object);
    },
    showImage(value, key) {
      this.dialog_text = [];
      if (value != null && value.search(this.base_url) != -1)
        this.dialog_image = value;
      else this.dialog_image = this.base_url + value;
      this.dialog_title = key;
      this.showImg_dialog = true;
    },
  },
};
</script>
<style >

.custom-header .v-data-table__th{
  border-top: rgba(var(--v-border-color), var(--v-border-opacity));
  background: rgba(var(--v-border-color), var(--v-border-opacity));
  }

.custom-header .v-data-table__td{      
  border-left: thin solid rgba(var(--v-border-color), var(--v-border-opacity));  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-header .v-data-table__th--sortable{
 
  font-weight: bold;
}

.v-selection-control--density-default {
    --v-selection-control-size: 20px !important;
}
.selected-row {
  background-color: rgb(182, 195, 199);
}
.v-btn-icon {
  min-width: 22px;
  height: 24px !important;
  padding: 0;
}
.fixed-header-table > thead tr th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}
.show-item {
  display: none;
}
/* .v-table--fixed-height > .v-table__wrapper{ overflow: hidden !important;} */
@media print {
  @page {
    margin: 1px !important;
  }
  footer {
    display: none;
  }
  .v-table__wrapper {
    overflow: hidden !important;
  }
  .v-table--fixed-height > .v-table__wrapper {
    overflow: hidden !important;
  }
  #footer {
    display: none !important;
  }
  body {
    font-family: Arial, sans-serif;
  }
  .v-data-table__footer,
  .v-data-footer {
    display: none !important;
  }
  .v-data-table {
    direction: rtl;
    font-size: 1px !important;
  }
  .hide-item {
    display: none;
  }
  .show-item {
    display: block;
  }
  .v-table__wrapper {
    overflow: hidden !important;
    font-size: 1px !important;
    color: black !important;
  }
}
</style>
