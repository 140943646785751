<template>
  <!-- added by samer -->
  <!-- شاشة المهام الادارية -->
  <DataTable
    v-if="CheckGetPermission('legal_school.view_supervisor')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getData"
    :create="
      () => {
        dialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editData"
    :delItem="delData"
    :del_perm="CheckGetPermission('legal_school.delete_supervisor')"
    :edit_perm="CheckGetPermission('legal_school.change_supervisor')"
    :add_perm="CheckGetPermission('legal_school.add_supervisor')"
  >
    <template v-slot:item.status="{ item }">
      <v-checkbox-btn v-model="item.status" disabled />
    </template>
     <template v-slot:item.access="{ item }">
      <span>
        <span >{{item.school_name}}</span> 
       
        
      </span>
    </template>
  </DataTable>
  <v-dialog v-model="dialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form">
          <VTextField
            v-model="dropList.task_name"
            ref="supName"
            autofocus
            :label="$t('globals.task-name')"
            density="compact"
            :rules="[$required]"
            prepend-inner-icon="mdi-calendar-check"
          />

          <VAutocomplete
            v-model="dropList.responsible"
            :items="responsible"
            item-title="name"
            item-value="id"
            :label="$t('globals.responsible_task')"
            persistent-hint
            density="compact"
            :rules="[$required]"
            prepend-inner-icon="mdi-account-tie"
          />
          <v-radio-group v-model="dropList.iteration"  inline :label="$t('globals.iteration')">
            <v-radio v-for="item in iterations" :key="item.id"  :label="item.name" :value="item.id"/>
          </v-radio-group>
      

          <v-checkbox
            v-model="dropList.status"
            :label="$t('globals.currently_required')"
          />
          <VTextField
            v-model="dropList.orderactivaty"                   
            :label="$t('monthly_marks.order')"
            density="compact"
            prepend-inner-icon="mdi-numeric"
            type="number"
            :rules="[$max_value(9)]"
          />

          <VTextarea
            v-model="dropList.note"
            clearable
            prepend-inner-icon="mdi-note-outline"
            :label="$t('globals.note')"
            counter="250"
            density="compact"
            rows="2"
            :rules="[$max_length(250)]"
          ></VTextarea>
        </VForm>
      </v-card-text>

      <VCardActions class="mx-3">
        <VBtnUpdate
          v-if="dropList.id"
          @click="updateData"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtnSave v-else @click="saveData()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtn class="mx-3" size="small" @click=" !dropList.id  ?dropList = {}: dialog=false">
           {{ !dropList.id ? $t("globals.clear") : $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      items: [],
      responsible: [],
      iterations: [],
      
      saveDataLoading: false,
      dialog: false,
      editDataLoading: false,
      
      dropList: {},
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
    };
  },
  created() {
    this.getResponsible();
    this.getIterations();
  },

  methods: {
    resetForm() {
      this.dialog = false;
    },

    editData(data) {
      this.dropList = { ...data };
      this.dialog = true;
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
         this.dropList.orderactivaty = this.dropList.orderactivaty ?this.dropList.orderactivaty:null
         await this.axios
          .post(
            this.base_url + "api/initialization-managmenttask/",
            this.dropList,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.addDialog = false;

            this.getData();
            this.dialog = false;
          })
          .catch((error) => {
            if('task_name' in error?.response?.data)
              this.$emit("infoAlert", error?.response?.data.task_name)                              
            else             
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.saveDataLoading = false;
      }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;

        this.dropList.orderactivaty = this.dropList.orderactivaty ?this.dropList.orderactivaty:null
        await this.axios
          .put(
            `${this.base_url}api/initialization-managmenttask/${this.dropList.id}/`,
            this.dropList,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.getData();

            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.dialog = false;
          })
          .catch((error) => {
              if('task_name' in error?.response?.data)
                this.$emit("infoAlert", error?.response?.data.task_name)                              
            else             
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.editDataLoading = false;
      }
    },
    async delData(delete_id) {
      if (delete_id) {
        var status;
        this.deleteDataLoading = true;
        await this.axios
          .delete(
            `${this.base_url}api/initialization-managmenttask/${delete_id}/`,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getData(page = 1, per_page = 10, ordering = null, search = null) {


      if(ordering =='responsible_name')
        ordering = 'responsible'
      else if(ordering =='-responsible_name')
        ordering = '-responsible'
  
      if(ordering =='iteration_name')
        ordering = 'iteration'
      else if(ordering =='-iteration_name')
        ordering = '-iteration'
  
      if(ordering =='school_name')
          ordering = 'fk_summer__name_ar'
        else if(ordering =='-school_name')
          ordering = '-fk_summer__name_ar'



     

      this.loading = true;

      await this.axios(`${this.base_url}/api/initialization-managmenttask/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      })
        .then((response) => {
          this.items = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });

      this.loading = false;
    },
    async getResponsible() {
      await this.axios(`${this.base_url}/choices/specialists-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.responsible = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },

    async getIterations() {
      await this.axios(`${this.base_url}/choices/activity-iterations`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.iterations = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    headers() {
      return [
        { title: this.$t("globals.task-name"), key: "task_name" },
        { title: this.$t("globals.responsible_task"), key: "responsible_name" },
        { title: this.$t("summer.summer-camp"), key: "school_name" },
        { title: this.$t("globals.iteration"), key: "iteration_name" },
        { title: this.$t("globals.access"), key: "access" },
        { title: this.$t("monthly_marks.order"), key: "orderactivaty" },
        { title: this.$t("globals.currently_required"), key: "status" },
        { title: this.$t("globals.note"), key: "note" },

        {
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
        },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.dropList = {};
        this.$refs.form?.resetValidation();
      }
    },
  },
};
</script>
   