<template>
  <VCard>
    <v-card-text class="pb-0">
      <VForm
        ref="form"
        class="mt-3"
        v-if="
          CheckGetPermission('legal_school.add_activitydifficulty',['sys_admin','summer_admin','user'])
        "
      >
        <VRow class="mb-2" >
          <VCol  cols="12" xs="12" md="4" lg="4" class="mb-1">
            <VAutocomplete
              prepend-inner-icon="mdi-soccer"
              :items="activites"
              v-model="difcltiess.fk_activity"
              item-title="name"
              item-value="id"
              :label="$t('summer.activity.name')"
              type="data"
              persistent-hint
              density="compact"
              hide-details="auto"
              :rules="[$required]"
            ></VAutocomplete>
              <!-- :error-messages="v$.difcltiess.fk_activity.$errors.map((e) => e.$message)" -->
          </VCol>
        </VRow>
        <v-card  flat v-for="doc in difucltsfileds" :key="doc">
          <VRow class="my-2">
            <VCol cols="12" xs="12" md="5" lg="4">
              <VTextField
                v-model="doc.difficulty"
                prepend-inner-icon="mdi-alert-octagon-outline"
                :label="$t('summer.activity.difficultie')"
                :placeholder="$t('summer.activity.difficultie_placeholder')"
                density="compact"
                clearable
                hide-details="auto"
                :rules="required_rule"
              >
              </VTextField>
            </VCol>
            <VCol cols="12" xs="12" md="5" lg="4">
              <VTextField
                v-model="doc.solution"
                prepend-inner-icon="mdi-check-decagram-outline"
                :label="$t('summer.activity.solutions')"
                :placeholder="$t('summer.activity.solutions_placeholder')"
                density="compact"
                clearable
                :rules="required_rule"
              >
              </VTextField>
          </VCol>
          </VRow>
        </v-card>
        <VRow  class="my-2 px-3 ">
          <VBtn
            v-if="!is_update"
            size="small"
            density="compact"
            icon
            @click.prevent="addDefucltis()"
          >
            <v-tooltip activator="parent" location="top"> {{$t('activity.add-dif-sol')}}</v-tooltip>
            <VIcon icon="mdi-plus-thick" color="success"></VIcon>
          </VBtn>
          <VBtn
            v-if="!is_update&&this.difucltsfileds.length>1"
            size="small"
            icon
            density="compact"
            class="mx-1"
            @click.prevent="removeDefcultis()"
          >
            <v-tooltip activator="parent" location="top"> {{$t('activity.del-dif-sol')}}</v-tooltip>
            <VIcon icon="mdi-minus-thick" color="error"></VIcon>
          </VBtn>
        </VRow>
        <VRow>
            <VCol cols="12"  xs="12" md="8" lg="8">
                <VTextarea
                  prepend-inner-icon="mdi-note-outline"
                  v-model="difcltiess.note"
                  clearable
                  :label="$t('globals.note')"
                  :placeholder="$t('summer.enter_note')"
                  no-resize
                  counter="250"
                  density="compact"
                  hide-details="auto"
                  rows="2"
                  :rules="note_rule"
                ></VTextarea>
            </VCol>
        </VRow>
      </VForm>
    </v-card-text>
    <VCardActions
      class="px-3"
      v-if="
        CheckGetPermission('legal_school.add_activitydifficulty',['sys_admin','summer_admin','user']) ||
        CheckGetPermission('legal_school.change_activitydifficulty',['sys_admin','summer_admin','user'])
      "
    >
      <VBtn
        v-if="
          !is_update &&
          CheckGetPermission('legal_school.add_activitydifficulty',['sys_admin','summer_admin','user'])
        "
        :loading="loading"
        class="bg-primary"
        @click="savesloutiondifcltiess()"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>

      <VBtn
        v-if="
          is_update &&
          CheckGetPermission('legal_school.change_activitydifficulty',['sys_admin','summer_admin','user'])
        "
        class="bg-green"
        :loading="loading"
        @click="updatesloutiondifcltiess()"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
    
      <VBtn class="mx-3" size="small" @click="resetForm()">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
    <!-- ######## Header Of Data Table  ####### -->
  </VCard>
  <delete-dialog
      v-model="del_dialog"
      :loading="deleteProgress"
      @confirm-delete="deletesloutiondifcltiess()"
  />
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import Datetime from "vue3-datetime-js";
import { helpers} from "@vuelidate/validators";
const englishOnly = helpers.regex(/^[a-zA-Z\-'\s]+$/);
const arabicOnly = helpers.regex(
  /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
);
export default {
    props: {
    id: {
      type: Number,
      },
  },
  data() {
    return {
      v$: useValidate(),
      difucltsfileds: [
        { 
          difficulty: null,
          solution: null,
          note:null  
        }
      ],
      difucltonlylist: [],
      difucltonlylist2:[],
      loading:false,
      difcltiess: {},
      doc: {
        difficulty: [],
      },
      docs: {
        solution: [],
      },
      required_rule:[
        (value) => !!value || this.$t('globals.required_field'),
        (value) => (value && value.length <= 100) || `${this.$t('globals.biggest_number_of_character')} 100`,
      ],
      note_rule:[
        (value) => value==undefined || (value.length <= 250) || `${this.$t('globals.biggest_number_of_character')} 250`,
      ],
      is_update: false,
      updated_id: undefined,
      del_dialog: false,
      delete_id: undefined,
    };
  },
  components: { Datetime },

  computed: {
      ...mapState({           
          activites:(state)=>state.summer.activites,            
        }),

    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    CheckGetPermission() {
      return (prem,role) => {
        return this.$store.getters.checkpermission(prem,role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);  
      await this.getactivites()    
      if (this.id != undefined) {
      await this.axios
        .get(`${this.base_url}/api/summer/activity-difficulty/${this.id}`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {          
          this.difucltsfileds[0].difficulty = "dsasadsaddsa";
          this.difcltiess = response.data.results;
          for (let i = 0; i < this.difucltsfileds.length; i++) {
              this.difucltsfileds[i].difficulty = this.difcltiess.difficulty;
              this.difucltsfileds[i].solution = this.difcltiess.solution;
          }
        });

      this.is_update = true;
    }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
     
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
  ...mapActions({
          getactivites: "summer/getactivites",
        }),
    addDefucltis() {
      this.difucltsfileds.push({
        difficulty: undefined,
        solution: undefined,
        note:undefined,
      });
    },
    removeDefcultis() {
      if (this.difucltsfileds.length != 1) {    
        this.difucltsfileds.pop();
      }
    },
    resetForm() {
      this.difucltonlylist=[];
      this.difucltonlylist2=[];
      this.$refs.form.reset();
      // this.v$.difcltiess.$reset();
      this.is_update = false;
      this.updated_id = undefined;
    },
    getStartWeek(e) {
      let date = e;
      if (this.$i18n.locale == "ar") {
        date = this.toEnglishString(e);
      }
      this.difcltiess.statrt_week = date;
    },
    editItem(data) {
      for (const key in data) this.difcltiess[key] = data[key];
      this.is_update = true;
      this.updated_id = data.id;
      let country = this.countries.find((con) => con.name_ar == data.country);
      this.difcltiess.country = country.id;
      let governorate = this.governorates.find(
        (gov) => gov.name_ar == data.fk_governorate
      );
      this.difcltiess.fk_governorate = governorate.id;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    deleteItem(data) {
      this.delete_id = data.id;
      this.del_dialog = true;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async savesloutiondifcltiess() {
      const { valid } = await this.$refs.form.validate()
      this.v$.$validate();
      if (!this.v$.$error && valid) {
        this.loading=true;
        this.difucltsfileds.forEach((element) => {
          this.difucltonlylist.push(element.difficulty)
        });
          this.difucltsfileds.forEach((element) => {
          this.difucltonlylist2.push(element.solution)
        });
        this.difcltiess.data_entry = this.user;
        this.difcltiess.difficulty = this.difucltonlylist;
        this.difcltiess.solution = this.difucltonlylist2;
        
        await this.axios
          .post(
            this.base_url + "/api/summer/activity-difficulty",
            this.difcltiess,
    
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((responce) => {
            this.loading=false;
            this.$emit('successAlert',this.$t('globals.data_added'))
            this.resetForm();
            this.difcltiess={}
          })
          .catch((error) => {
            this.loading=false;
            this.$emit('errorAlert',this.$t('globals.error_in_data'))
          });
      }
    },
    async updatesloutiondifcltiess() {
      const { valid } = await this.$refs.form.validate()
      this.v$.$validate();
      if (!this.v$.$error && valid) {
        this.loading=true,
        this.difcltiess.updated_by=this.user
          for (let i = 0; i < this.difucltsfileds.length; i++) {
                this.difcltiess.difficulty =this.difucltsfileds[i].difficulty ;
                this.difcltiess.solution = this.difucltsfileds[i].solution ;
          }

        
        let result = await this.axios.put(`${this.base_url}/api/summer/activity-difficulty/${this.difcltiess.id}`,
        this.difcltiess,{headers:{'Authorization':'Bearer '+localStorage.getItem('token')}})
        .then((responce)=>{
            this.loading=false,
             this.$emit('successAlert',this.$t('globals.data_updated'))

        
        }).catch((error)=>{
            this.loading=false,
            this.$emit('errorAlert',this.$t('globals.error_in_data'))
 
     
        })
       
        this.resetForm()

        setTimeout(()=>{
          
              this.$router.push({name:"dicultsandslotions_list"})
        },2000);
      }
                    
    },
  },
};
</script>
