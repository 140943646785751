import { createApp } from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import i18n from "./i18n";
import axios from "axios";
import VueAxios from "vue-axios";
import "@/assets/style.css";
import store from "./store/index";
import useValidate from "@vuelidate/core";
import Alert from "./components/Globals/Alert";
import Cliche from "./components/Globals/Cliche";
import AlertCascadeRecords from "./components/Globals/AlertCascadeRecords";
import AlertProtectedRecords from "./components/Globals/AlertProtectedRecords";
import DeleteDialog from "./components/Globals/DeleteDialog";
import DatetimePicker from '@/components/Globals/DatetimePicker.vue'
import validation from './plugins/validation-plugin';
import utils from './plugins/utils'
import DataTable from '@/components/Globals/DataTable'
import CustomFields from '@/components/Globals/CustomFields'
import CustomImg from "@/components/Globals/CustomImg.vue";
import CustomTableReport from "@/components/Globals/CustomTableReport.vue";
import CustomReportHeader from "@/components/Globals/CustomReportHeader.vue";
import CustomBtn from "@/components/Globals/CustomBtn.vue";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { helpers } from "@vuelidate/validators";
import echarts from "vue-echarts";

let app = createApp(App);
const v$ = useValidate();
app.config.warnHandler = function (msg, vm, trace) {
  return null;
};

// export const api_url = "http://127.0.0.1:9090/";

// export const api_url = "https://gmsl.gov.ye/";

// export const api_url = "http://gmsl.yemenexam.net/";

export const api_url = "https://ilearnb.opensoftye.net/";

// export const api_url = "https://sssb.mesrye.com/";

// export const api_url = "http://127.0.0.1:9001/";

// export const api_url = "http://opensoftye.net/";

// export const api_url = "http://schoolmange.opensoftye.com/";

app.config.globalProperties.base_url = api_url;
app.config.globalProperties.protected_records = false;
app.config.globalProperties.data_message = [];

function host() {
  return app.config.globalProperties.base_url;
} 

app.config.globalProperties.$v = v$;
app.config.globalProperties.toHijriDate = function (date) {
  let dateVal = date.toLocaleDateString("en-US", { calendar: "islamic" });
  let dateFormat = dateVal.slice(0, 10).split("/").reverse();
  let day = dateFormat[1];
  let month = dateFormat[2];
  dateFormat[1] = month;
  dateFormat[2] = day;
  
  return dateFormat.join("-");
};
app.config.globalProperties.toEnglishString = function (string) {
  const arabic_numbers = {
    "\u0660": 0,
    "\u0661": 1,
    "\u0662": 2,
    "\u0663": 3,
    "\u0664": 4,
    "\u0665": 5,
    "\u0666": 6,
    "\u0667": 7,
    "\u0668": 8,
    "\u0669": 9,
    "/": "-",
  };

  var english_string = "";
  if (typeof string == "string") {
    for (let index = 0; index < string.length; index++) {
      if (Object.keys(arabic_numbers).includes(string[index])) {
        english_string += arabic_numbers[string[index]];
      } else {
        english_string += string[index];
      }
    }
    return english_string;
  } else {
    return string;
  }
};
app.config.globalProperties.toGregorianDate = function (date) {
  const datetime = new Date(date);
  const gregorianString = datetime.toLocaleString("en-US", {
    calendar: "islamic",
    timeZone: "UTC",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "numeric",
  });
  return gregorianString;
};
app.config.globalProperties.substring = function (string,count) {
  if(typeof(string)=='string'){
    if(string.length>count){
      return string.substring(0,count)+'...'
    }
    return string
  }
  return string
};
app.config.globalProperties.CheckGetPermission= function(){
  return (prem, role) => {
    return store.getters.checkpermission(prem, role);
  };
},
app.config.globalProperties.$ExportToExcel=function(header,data,name='ملف'){
  return new Promise((resolve,reject)=>{
    try {
      const today = new Date()
      const options ={year:'numeric',month:'2-digit',day:'2-digit'}
      const dateString = today.toLocaleDateString('en-US',options).replace(/\//g,'-')
      const style = {
        font: {
            bold: true,
            color: { argb: "FFFFFF" },
        },
        fill: {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "2F75B5" },
        },
      };
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Data');
      worksheet.columns=header
      const row=worksheet.getRow(1)
      row.eachCell(cell=>{
          cell.style=style
      })
      worksheet.addRows(data);  
      worksheet.eachRow(row=>{
          row.values.forEach((value,col)=>{
              const column = worksheet.getColumn(col);
              const cell = value?value.length:0;
              if(cell>column.width){
                  column.width=cell+2;
              }
          })
          row.eachCell(cell=>{
              cell.alignment={horizontal:'center',vertical:'middle'}
              cell.border={
                  top:{style:'thin'},
                  left:{style:'thin'},
                  right:{style:'thin'},
                  bottom:{style:'thin'}
              }
          })
      })
      const file_name=`${dateString}-${name}.xlsx`
      workbook.xlsx.writeBuffer().then((buffer)=>{
          const data = new Blob([buffer],{ type:'application/octet-stream'});
          saveAs(data,file_name)
      })
      resolve()
    } catch (error) {
      reject(error)
    }
    
  })
}
app.config.globalProperties.validators = {
  uniqueValidator: (list) => {
    return (value) => {
      return !list.includes(value?.trim());
    };
  },
  lessDate: (date) => {
    return (value) => {
      return date < value;
    };
  },
  moreDate: (date) => {
    return (value) => {
      return date > value;
    };
  },
  timeValidator: (startTime) => {
    return (value) => {
      return x < value;
    };
  },
  unique: (list, val) => {
    console.log("validations work ","list = ",list ,"value",val);
    return !list.includes(val) ? true : i18n.global.t("errors.unique");
  },
  englishOnly: helpers.regex(/^[a-zA-Z\-'\s]+$/),
  arabicOnly: helpers.regex(
    /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
  ),
};
const custom_axios = axios.create({
  timeout: 600000,
  headers:{
    common:{
      'Accept-Language':'ar'
    }
  }
});
custom_axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
custom_axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if(error.message=="Network Error"){
      store.commit('setErrorConnection',true)
    }else if(error.message.includes("timeout")) {
      store.commit('setTimeoutAlert',true)
    }
    return Promise.reject(error);
  }
);

async function loadSystemRole(){
  try {
    if(localStorage.getItem('token')){
      const res = await axios.get(`${api_url}/api/user/profile`,{
        headers:{
          Authorization: "Bearer " + localStorage.getItem("token")
        }
      })
      store.commit('setRole',res.data?.role)
    }
    app
    .use(store)
    .use(i18n)
    .use(vuetify)
    .use(router)
    .use(validation)
    .use(VueAxios, custom_axios)
    // Register the validation plugin
    .use(validation)
    .use(utils)
    .component("Alert", Alert)
    .component("Cliche", Cliche)
    .component("chart", echarts)
    .component("DataTable", DataTable)
    .component("CustomFields", CustomFields)
    .component("CustomImg", CustomImg)
    .component("CustomTableReport", CustomTableReport)
    .component("CustomReportHeader", CustomReportHeader)
    .component("CustomBtn", CustomBtn)
    .component("AlertProtectedRecords", AlertProtectedRecords)
    .component("AlertCascadeRecords", AlertCascadeRecords)
    .component("DeleteDialog", DeleteDialog)
    .component("DatetimePicker", DatetimePicker)
    .mount("#app");
  } catch (error) {

    app
      .use(store)
      .use(validation)
      .use(i18n)
      .use(vuetify)
      .use(router)
      .use(VueAxios, custom_axios)
      .use(validation)
      .use(utils)
      .component("Alert", Alert)
      .component("Cliche", Cliche)
      .component("chart", echarts)
      .component("AlertProtectedRecords", AlertProtectedRecords)
      .component("AlertCascadeRecords", AlertCascadeRecords)
      .component("DeleteDialog", DeleteDialog)
      .component("DatetimePicker", DatetimePicker)
      .mount("#app");
  }
}
loadSystemRole()
export default host;

