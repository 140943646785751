<template>
  <v-expansion-panels class="mb-2" v-if="role >= 0 && role <= 3">
    <v-expansion-panel>
      <v-expansion-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-expansion-panel-title>
      <v-expansion-panel-text>
        <v-row class="py-2">
          <VCol
            class="mt-1"
            cols="6"
            md="3"
            sm="4"
            v-if="role == 0 || role == 1"
          >
            <VAutocomplete
              :items="filterGovernorates(1)"
              v-model="fk_governorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.governorate')"
              persistent-hint
              density="compact"
              hide-details
              clearable
              :no-data-text="$t('governorate.not-found')"
              prepend-inner-icon="mdi-city"
              @update:modelValue="
                getEmployees(),
                  getSummerSchools(),
                  (fk_directorate = null),
                  (fk_summer = null)
              "
            ></VAutocomplete>
          </VCol>
          <VCol
            class="mt-1"
            cols="6"
            md="3"
            sm="4"
            v-if="role >= 0 && role <= 2"
          >
            <VAutocomplete
              :items="filterDirectorates(fk_governorate)"
              v-model="fk_directorate"
              item-title="name_ar"
              item-value="id"
              :label="$t('globals.directorate')"
              persistent-hint
              hide-details
              density="compact"
              clearable
              :no-data-text="$t('directorate.not-found')"
              prepend-inner-icon="mdi-map-outline"
              @update:modelValue="
                getEmployees(), getSummerSchools(), (fk_summer = null)
              "
            ></VAutocomplete>
          </VCol>
          <VCol
            class="mt-1"
            cols="6"
            md="3"
            sm="4"
            v-if="role >= 0 && role <= 3"
          >
            <VAutocomplete
              v-model="fk_summer"
              item-value="id"
              item-title="name_ar"
              prepend-inner-icon="mdi-domain"
              :items="summer_schools"
              :label="$t('globals.summer-centre')"
              persistent-hint
              hide-details
              density="compact"
              :no-data-text="$t('summer.not-found')"
              @update:modelValue="getEmployees(), getSummerSchools()"
              clearable
            ></VAutocomplete>
          </VCol>
          <v-col class="mt-1" cols="6" md="3">
            <VAutocomplete
              v-model="fk_role"
              clearable
              :items="roles"
              item-title="name"
              item-value="id"
              :label="$t('summer.work-type')"
              persistent-hint
              density="compact"
              hide-details="auto"
              prepend-inner-icon="mdi-format-list-bulleted"
              @update:modelValue="getEmployees((page = 1))"
            ></VAutocomplete>
          </v-col>
        </v-row>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
  <!-- ######## Header Of Data Table  ####### -->
  <v-card class="mt-2" v-if="CheckGetPermission('legal_school.view_employee')">
    <v-card-text>
      <v-row class="mt-1 mb-2">
        <v-col cols="4">
          <v-text-search
            v-model="txt_search"
            @keyup.enter="getEmployees((page = 1))"
            class="search"
          >
          </v-text-search>
        </v-col>
        <v-col cols="8" class="text-end">
          <v-btn-group divided>
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props">
                  <v-icon class="me-2">mdi-eye-outline</v-icon>
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-text>
                  <v-checkbox
                    v-for="header in headers"
                    :key="header.key"
                    :label="header.title"
                    :value="header.key"
                    color="primary"
                    v-model="selectedHead"
                    density="compact"
                    hide-details
                  >
                  </v-checkbox>
                </v-card-text>
              </v-card>
            </v-menu>
            <v-btn
              size="small"
              @click="exportToExcel"
              :loading="exportProgress"
            >
              <span>{{ $t("export.export") }} </span>
              <v-icon size="large" color="green-darken-2" end
                >mdi-file-excel</v-icon
              >
            </v-btn>
            <VBtn
              @click="$router.push({ name: 'add-employee' })"
              size="small"
              v-if="role == 1"
            >
              <span>{{ $t("globals.create") }}</span>
              <VIcon icon="mdi-plus-thick" color="success" end></VIcon>
            </VBtn>
          </v-btn-group>
        </v-col>
      </v-row>

      <!-- ######## End Header Of Data Table Server ####### -->
      <v-card :disabled="DataLoading" :loading="DataLoading">
        <v-data-table-server
          :headers="selectedHeaders"
          :items="tableList"
          v-model:sort-by="sortBy"
          item-value="name"
          item-key="id"
          id="vDataTable"
          fixed-footer
          density="compact"
          class="elevation-1"
          :no-data-text="$t('globals.not-found')"
        >
          <!-- <template v-slot:item="{ item }">
              <tr class="row-hover">
                <template v-for="(val, key) in item.columns" :key="key">
                  <td v-if="Array.isArray(val)">
                    <span v-for="(item, index) in val" :key="index">
                      {{ item }} ,
                    </span>
                  </td>
                    <td v-else-if="key === 'actions'">
                      <span>
                        <v-btn
                          variant="text"
                          class="me-1 v-btn-icon"
                          size="small"
                          @click="editEmployee(item.raw)"
                          v-if="
                            CheckGetPermission('legal_school.change_employee', [
                              'sys_admin',
                              'summer_admin',
                              'user',
                            ])
                          "
                        >
                          <v-icon color="warning">mdi-pencil-outline</v-icon>
                        </v-btn>
                      </span>
                      <span>
                        <v-btn
                          variant="text"
                          size="small"
                          class="v-btn-icon"
                          @click="
                            (dialogVisible = true),
                              ((this.delete_id = item.id),
                              (this.user_delete_id = item.fk_user))
                          "
                          v-if="
                            CheckGetPermission('legal_school.delete_employee', [
                              'sys_admin',
                              'summer_admin',
                              'user',
                            ])
                          "
                        >
                          <v-icon color="error">mdi-trash-can-outline</v-icon>
                        </v-btn>
                      </span>
                    </td>
                    <td v-else-if="key === 'state'">
                        <span v-if="val === true" >
                              
                            مستمر
                        </span>

                        <span v-else>
                            
                          منقطع
                        </span>
                    </td>
                  <td v-else>
                    {{ key == "state" && key !== "actions"? getEmployeeStatesName(val): val }}
                  </td>
                </template>
              </tr>
            </template> -->

          <template v-slot:item.group_user_name="{ item }">
            <span v-for="(data, index) in item.group_user_name" :key="index">
              {{ data }}
            </span>
          </template>
          <template v-slot:item.state="{ item }">
            <span v-if="item.state === true"> مستمر </span>
            <span v-else> منقطع </span>
          </template>
          <template v-slot:item.actions="{ item }">
            <span>
              <v-btn
                variant="text"
                class="me-1 v-btn-icon"
                size="small"
                @click="editEmployee(item)"
                v-if="
                  CheckGetPermission('legal_school.change_employee', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
              >
                <v-icon color="warning">mdi-pencil-outline</v-icon>
              </v-btn>
            </span>
            <span>
              <v-btn
                variant="text"
                size="small"
                class="v-btn-icon"
                @click="
                  (dialogVisible = true),
                    ((this.delete_id = item.id),
                    (this.user_delete_id = item.fk_user))
                "
                v-if="
                  CheckGetPermission('legal_school.delete_employee', [
                    'sys_admin',
                    'gen_admin',
                  ])
                "
              >
                <v-icon color="error">mdi-trash-can-outline</v-icon>
              </v-btn>
            </span>
          </template>
          <template v-slot:bottom>
            <div class="d-flex" id="pagination-bar">
              <v-pagination
                v-model="page"
                :length="pagination.num_pages"
                density="compact"
                show-first-last-page
              >
              </v-pagination>
              <div class="d-flex">
                <v-select
                  v-model="perPage"
                  class="pa-0"
                  :items="itemsPerPage"
                  item-value="value"
                  item-title="text"
                  density="compact"
                  hide-details
                  variant="text"
                >
                </v-select>
                <span class="mt-2 px-2"
                  >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                  {{ pagination.count }} ] {{ $t("globals.item") }}</span
                >
              </div>
            </div>
          </template>
        </v-data-table-server>
      </v-card>
    </v-card-text>
  </v-card>
  <delete-dialog
    v-model="dialogVisible"
    :loading="DataLoading"
    @confirm-delete="deleteEmployee()"
  />
  <alert-protected-records
    v-model="protected_records"
    :data_message="data_message"
  ></alert-protected-records>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Datetime from "vue3-datetime-js";
import useValidate from "@vuelidate/core";
export default {
  data() {
    return {
      DataLoading: false,
      exportProgress: false,
      protected_records: false,
      fk_governorate: null,
      fk_directorate: null,
      fk_summer: null,
      fk_role: null,
      summer_schools: [],
      data_message: [],
      tab: null,
      employee: {},
      employees: [],
      page: 1,
      perPage: 10,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: "all", text: "all" },
      ],
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],

      dialogVisible: false,
      v$: useValidate(),
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      user_delete_id: undefined,
      selectedHead: [
        "name_ar",
        "entity_type",
        "role_name",
        "user_username",
        "user_email",
        "state",
        "actions",
        "group_user_name",
      ],
      headers: [
        { title: this.$t("summer.name-employees"), key: "name_ar" },
        { title: this.$t("globals.gender"), key: "gender_type" },
        { title: this.$t("globals.marital-status"), key: "marital_status_name", },
        { title: this.$t("summer.employee.children-numbers"), key: "children" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity_type" },
        { title: this.$t("user.role"), key: "role_name" },
        { title: this.$t("globals.job-id"), key: "job_id" },
        { title: this.$t("summer.shift-time"), key: "work_period_name" },
        { title: this.$t("summer.employee.qualifications"), key: "qualifications_name",  },
        { title: this.$t("globals.address"), key: "address" },
        { title: this.$t("summer.accommodation-type"), key: "accommodation_type_name",        },
        { title: this.$t("summer.employee.mobile-number"), key: "phone_number", },
        { title: this.$t("user.fname"), key: "user_first_name" },
        { title: this.$t("user.lname"), key: "user_last_name" },
        { title: this.$t("user.username"), key: "user_username" },
        { title: this.$t("user.email"), key: "user_email" },
        { title: this.$t("summer.card-number"), key: "identity_id" },
        { title: this.$t("summer.employee.state"), key: "state" },
        { title: this.$t("summer.employee.other-work"), key: "other_work" },
        { title: this.$t("globals.fulltime_ratio"), key: "full_time_percentage" },
        { title: this.$t("summer.employee.birth-place"), key: "birth_place" ,children:[
        { title: this.$t("globals.birth_date"), key: "birthdate" },
          { title: this.$t("globals.country"), key: "country_name" },
        { title: this.$t("globals.governorate"), key: "governorate_name" },
        { title: this.$t("globals.directorate"), key: "directorate_name" },
        { title: this.$t("summer.solitude"), key: "village_birthdate" },
        { title: this.$t("globals.neirghbourhood"), key: "neighbourhood_birthdate" },
        ]},

        { title: this.$t("globals.residence_place"), key: "residence_place",children:[
            { title: this.$t("globals.country"), key: "country_name_housing" },
        { title: this.$t("globals.governorate"), key: "governorate_name_housing" },
        { title: this.$t("globals.directorate"), key: "directorate_name_housing" },
          { title: this.$t("summer.solitude"), key: "village_housing" },
        { title: this.$t("globals.neirghbourhood"), key: "neighbourhood_housing" },

        ] },

        { title: this.$t("user.group"), key: "group_user_name" , sortable: false },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ],
      txt_search: undefined,
    };
  },
  components: { Datetime },
  computed: {
    ...mapState({
      subjects: (state) => state.summer.subjects,
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      employeeStates: (state) => state.summer.employeeStates,
      nationalities: (state) => state.school.nationalities,
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      roles: (state) => state.summer.roles,
      user: (state) => state.User,
      role: (state) => state.role,
    }),
    ...mapGetters({
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
    tableList() {
      if (this.employees) {
        return JSON.parse(
          JSON.stringify(this.employees, (key, value) =>
            value == null ? "---" : value
          )
        );
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    items() {
      return [
        {
          key: "personal_information",
          value: this.$t("globals.personal-information"),
        },
        {
          key: "employment_information",
          value: this.$t("globals.employment-information"),
        },
        {
          key: "contact_information",
          value: this.$t("globals.contact-information"),
        },
        { key: "user_data", value: this.$t("globals.user-data") },
      ];
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getRoles();
      await this.getEmployeeStates();
      await this.getGovernorates();
      await this.getDirectorate();
      await this.getEmployees();
      if (this.role == 2) {
        this.fk_governorate = localStorage.getItem("governorate");
      }
      if (this.role == 3) {
        this.fk_directorate = localStorage.getItem("directorate");
        this.getSummerSchools();
      }
      if (this.role == 1) {
        this.headers.push({
          title: this.$t("globals.actions"),
          key: "actions",
          sortable: false,
        });
      }
      if (this.role >= 0 && this.role <= 3) {
        this.headers.splice(2, 0, {
          title: this.$t("summer.address-summer"),
          key: "summer_address",
          sortable: false,
        });
        this.headers.splice(2, 0, {
          title: this.$t("summer.summer-camp"),
          key: "school_summer",
        });
        this.selectedHead.push("school_summer");
        this.selectedHead.push("summer_address");
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async exportToExcel() {
      this.exportProgress = true;
      var employees = [];
      await this.axios(`${this.base_url}/api/summer/employee`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          role: this.fk_role,
          governorate: this.fk_governorate,
          directorate: this.fk_directorate,
          school_summer: this.fk_summer,
        },
      }).then((response) => {
        employees = JSON.parse(
          JSON.stringify(response.data, (key, value) =>
            value == null ? "" : value
          )
        );
        employees.forEach((obj) => {
          obj.user_first_name = obj?.user_first_name ?? "";
          obj.user_last_name = obj?.user_last_name ?? "";
          obj.user_username = obj?.user_username ?? "";
          obj.user_email = obj?.user_email ?? "";
          obj.state =
            obj.state == true
              ? this.$t("globals.continus")
              : this.$t("globals.drop-out");
          if (typeof obj.group_user_name == "object") {
            obj.group_user_name = obj.group_user_name?.join(" - ");
          }
        });
      });
      const headers = this.headers
        .filter((el) => !["actions"].includes(el.key))
        .map((item) => {
          return { header: item.title, key: item.key };
        });

      await this.$ExportToExcel(headers, employees, "بيانات العاملين")
        .then((res) => {
          this.exportProgress = false;
        })
        .catch((err) => {
          this.exportProgress = false;
        });
    },
    async getSummerSchools() {
      await this.axios
        .get(`${this.base_url}api/summer/school-select-list`, {
          params: {
            directorate: this.fk_directorate,
            governorate: this.fk_governorate,
          },
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.summer_schools = response.data;
        });
    },
    ...mapActions({
      getEmployeeStates: "summer/getEmployeeStates",
      getRoles: "summer/getRoles",
      getCountries: "school/getCountries",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    getEmployeeStatesName(value) {
      let employeeState = this.employeeStates.find(
        (element) => element.id === value
      );
      return employeeState ? employeeState.name : "";
    },
    editEmployee(employee) {
      this.$router.push({ name: "edit-employee", params: { id: employee.id } });
      this.tab = this.items[0].key;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async deleteEmployee() {
      if (this.delete_id) {
        this.DataLoading = true;
        let result = await this.axios
          .delete(this.base_url + "api/summer/employee/" + this.delete_id, {
            data: { user_delete_id: this.user_delete_id },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.DataLoading = false;
          })
          .catch((error) => {
            this.dialog = false;
            if ((error.code = 418)) {
              this.protected_records = true;
              let error_messages = [];
              error.response.data.data.forEach((error) => {
                error_messages.push(
                  this.$t(`models.${error.model}`) + ` = ${error.count}`
                );
              });
              this.data_message = error_messages;
            } else {
              this.$emit("errorAlert", this.$t("alert.failure.delete"));
            }
            this.DataLoading = false;
          });
        this.dialogVisible = false;
        this.delete_id = undefined;
        this.getEmployees();
        this.getEmployeeStates();
      }
    },
    async getEmployees(page = 1) {
      this.DataLoading = true;
      await this.axios(`${this.base_url}/api/summer/employee`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: this.txt_search,
          page: page,
          page_size: this.perPage,
          sort_by: this.order_data,
          role: this.fk_role,
          governorate: this.fk_governorate,
          directorate: this.fk_directorate,
          school_summer: this.fk_summer,
        },
      }).then((response) => {
          this.employees = response.data.results;
          this.pagination = response.data.pagination;
          this.length = response.data.pagination.num_pages;
          this.DataLoading = false;
        })
        .catch((error) => {
          this.DataLoading = false;
        });
    },
  },
  watch: {
    perPage() {
      this.getEmployees();
    },
    page() {
      this.getEmployees(this.page);
    },
    order_data() {
      this.getEmployees(this.page);
    },
  },
};
</script>