<template>
  <VCard class="justify-center">
    <VCardTitle>
      <!-- <VIcon icon="mdi-account-multiple-plus"/>
              <h3 class="d-inline" style="padding:0 4px;" >{{$t('group.add_group')}}</h3>
              <VDivider style="border-width:1px; opacity: 0.5;" class="my-2"/> -->
    </VCardTitle>
    <VCardText class="mt-2">
      <VRow>
        <VCol cols="12" md="10">
          <VForm ref="form">
            <VRow justify="center" class="mt-4">
              <VCol cols="12" :md="checkRoleAdmin() ? 4 : 6">
                <VAutocomplete
                  v-model="group.id"
                  color="indigo"
                  density="compact"
                  item-title="name"
                  item-value="pk"
                  prepend-inner-icon="mdi-account-group"
                  clearable
                  :items="group_list"
                  @update:modelValue="isUpdate"
                  :label="$t('user.group')"
                />
              </VCol>
              <VCol cols="12" :md="checkRoleAdmin() ? 4 : 6">
                <VTextField
                  v-model="group.name"
                  color="indigo"
                  clearable
                  counter="100"
                  density="compact"
                  prepend-inner-icon="mdi-alpha-e"
                  :label="$t('user.group-name')"
                  :error-messages="v$.group.name.$errors.map((e) => e.$message)"
                  :rules="[$max_length(100)]"
                />
              </VCol>
              <VCol cols="12" md="4" v-if="checkRoleAdmin()">
                <VAutocomplete
                  v-model="group.group_for"
                  color="indigo"
                  density="compact"
                  item-title="name"
                  item-value="id"
                  prepend-inner-icon="mdi-account"
                  clearable
                  :items="roleList"
                  :label="$t('user.group-for')"
                  multiple
                />
              </VCol>
            </VRow>
          </VForm>
          <VCardActions>
            <VBtn
              :loading="saveProgress"
              v-if="!is_update"
              @click="saveGroup"
              class="bg-indigo"
              size="small"
            >
              <span class="text-white">
                {{ $t("globals.add") }}
              </span>
              <VIcon icon="mdi-content-save" color="white" end></VIcon>
            </VBtn>
            <VBtn
              :loading="saveProgress"
              v-if="is_update"
              @click="updateGroup"
              class="bg-success"
              size="small"
            >
              <span class="text-white">
                {{ $t("globals.edit") }}
              </span>
              <VIcon icon="mdi-pencil" color="white" end></VIcon>
            </VBtn>
            <VBtn
              v-if="is_update"
              @click="del_dialog = true"
              class="bg-error"
              size="small"
            >
              <span class="text-white">
                {{ $t("globals.delete") }}
              </span>
              <VIcon icon="mdi-trash-can" color="white" end></VIcon>
            </VBtn>
            <VBtn @click="resetForm" class="mx-3" size="small">
              {{ $t("globals.clear") }}
              <VIcon icon="mdi-broom" color="golden" end></VIcon>
            </VBtn>
          </VCardActions>
        </VCol>
      </VRow>
      <!-- ##########    data Table Server  ########### -->

      <VDivider class="my-2" />
      <v-card>
        <v-table fixed-header height="450" density="compact">
          <thead>
            <tr>
              <th>
                <VCheckbox
                  color="indigo"
                  hide-details="true"
                  v-model="check_all"
                  density="compact"
                  @update:modelValue="checkAll()"
                  :label="$t('permission.permissions')"
                >
                </VCheckbox>
              </th>
              <th>{{ $t("permission.add") }}</th>
              <th>{{ $t("permission.change") }}</th>
              <th>{{ $t("permission.delete") }}</th>
              <th>{{ $t("permission.view") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in permission_list" :key="item" class="">
              <td class="text-center" style="max-width: 100px">
                <VCheckbox
                  color="indigo"
                  hide-details="true"
                  v-model="item.is_all"
                  density="compact"
                  @update:modelValue="checkRow(item)"
                  :label="$t('permission.' + item.table)"
                >
                </VCheckbox>
              </td>
              <td class="text-center">
                <VCheckbox
                  color="indigo"
                  hide-details="true"
                  v-model="item.is_add"
                  density="compact"
                  @update:modelValue="addPermission(item.add)"
                ></VCheckbox>
              </td>
              <td class="text-center">
                <VCheckbox
                  color="indigo"
                  hide-details="true"
                  v-model="item.is_change"
                  density="compact"
                  @update:modelValue="addPermission(item.change)"
                ></VCheckbox>
              </td>
              <td class="text-center">
                <VCheckbox
                  color="indigo"
                  hide-details="true"
                  v-model="item.is_delete"
                  density="compact"
                  @update:modelValue="addPermission(item.delete)"
                ></VCheckbox>
              </td>
              <td class="text-center">
                <VCheckbox
                  color="indigo"
                  hide-details="true"
                  v-model="item.is_view"
                  density="compact"
                  @update:modelValue="addPermission(item.view)"
                ></VCheckbox>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card>
    </VCardText>

    <!-- ############### Dialog Delete  ################### -->
    <delete-dialog
      v-model="del_dialog"
      :loading="deleteProgress"
      @confirm-delete="deleteGroup()"
    ></delete-dialog>
    <!-- ############### end Dialog Delete ################### -->
  </VCard>
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import {
  email,
  required,
  helpers,
  maxLength,
  url,
  alpha,
  alphaNum,
  numeric,
} from "@vuelidate/validators";
import ImageInput from "@/components/Libraries/ImageInput.vue";
import usersModule from "@/store/Users/users.module";

const arabicOnly = helpers.regex(
  /^[\u0600-\u06ff\u0750-\u077f-\u080a-\u08ff\ufb50-\ufdff\ufe70-\ufeff\s]*$/
);
const englishOnly = helpers.regex(/^[a-zA-Z\-'\s]+$/);

// const dateValidation=function(value){
//     const date=new Date().toJSON().slice(0,10)
//     return (value&&value>date)?true:false
// }

export default {
  name: "summer_group",
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getGroups();
      await this.getPermissions();
      await this.axios.get(`${this.base_url}user-role/`).then((response) => {
        this.role_list = response.data;
      });
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      test: 300,
      v$: useValidate(),
      group_list: [],
      role_list: [],
      saveProgress: false,
      deleteProgress: false,
      all_permission_list: undefined,
      // exclude:['admin','contenttype','accessattempt','session','financial','sessionuser','summeropenemployee','summeropenvisits','summeropentaskmangement','summeropenactivitystatistic','summeropenlevelstatistics','school','bus_system','django_apscheduler','settingsystem','groupextra','contactsummer','userauthority','activitystatistic','levelstatistics','operation','summeropencontact'],
      // enabled temprory untile finish the supplies and gifts screen
      exclude: [
        "erroroffiled",
        "rolesstatus",
        "erroroffiledwithopensummer",
        "admin",
        "contenttype",
        "accessattempt",
        "directoratecertficate",
        "gift",
        "managmenttasktitle",
        "permitanddocument",
        "sportaccessorie",
        "supportcommitteestitle",
        "sportaccessoriestatistic",
        "permitanddocumentstatistic",
        "opensummerdirectoratecardstatistic",
        "opensummerdirectorateapproachstatistic",
        "directorategiftstatistic",
        "directoratecertficatestatistic",
        "summeropendropout",
        "summeropenactivitytype",
        "summeropenactivity",
        "session",
        "financial",
        "sessionuser",
        "summeropenvisits",
        "summeropentaskmangement",
        "summeropenactivitystatistic",
        "summeropenlevelstatistics",
        "school",
        "bus_system",
        "django_apscheduler",
        "settingsystem",
        "groupextra",
        "contactsummer",
        "userauthority",
        "activitystatistic",
        "levelstatistics",
        "operation",
        "summeropencontact",
        "notefollwedevaluationstudentdegree",
      ],
      // exclude:['admin','contenttype','session','financial','sessionuser','school','bus_system','django_apscheduler','yearofstudy','levelstudentyear','settingsystem','groupextra','contactsummer'],
      admin_exclude: [],
      summer_exclude: [
        "erroroffiled",
        "summeropenemployee",
        "country",
        "generalizations",
        "mobileversion",
        "schoolweeklyactivity",
        "governorate",
        "directorate",
        "supervisor",
        "talent",
        "divisionsummer",
        "daysummer",
        "schedulesummer",
        "activitytype",
        "subjectsummer",
        "level",
        "skill",
        "plan",
        "talent",
        "week",
        "commitee",
        "lecturesummer",
        "schoolweeklyschedulesummer",
        "calendar",
        "vacation",
        "initiative",
        "yearofstudy",
        "summeropencontact",
      ],
      permission_list: [],
      check_all: false,
      is_update: false,
      del_dialog: false,
      group_del_id: undefined,
      default_permission: [],
      group: {
        permissions: [],
        group_for: [],
        name: undefined,
        id: undefined,
      },
    };
  },
  validations() {
    return {
      group: {
        name: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          maxLength: helpers.withMessage(this.$t("errors.max-entry"), maxLength(150)),
        },
      },
    };
  },
  computed: {
    roleList() {
      return this.role_list.filter(
        (role) => (role.id > 1 && role.id < 5) || role.id == 8
      );
    },
    ...mapState(["role"]),
  },
  methods: {
    checkRoleAdmin() {
      if (this.role < 2) return true;
      return false;
    },
    async getGroups() {
      let group = await this.axios.get(`${this.base_url}groups/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      if (group) this.group_list = group.data;
    },
    getPermissions() {
      this.axios
        .get(`${this.base_url}permission/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((responce) => {
          this.all_permission_list = responce.data;
          if (this.all_permission_list != undefined) {
            // if(localStorage.getItem('role') >= 4)
            this.all_permission_list.forEach((element) => {
              if (
                !(
                  this.exclude.includes(element.content) ||
                  this.exclude.includes(element.content_label)
                )
              ) {
                if (element.codename.search("view") != -1) {
                  this.default_permission.push(element.id);
                }
              }
            });
            this.default_permission.forEach((element) => {
              this.group.permissions.push(element);
            });
            if (this.role >= 2) {
              this.summer_exclude.forEach((element) => {
                this.exclude.push(element);
              });
            }
            this.permission_list = this.orderPermissions(
              this.all_permission_list,
              this.exclude,
              this.default_permission
            );
          }
        })
        .catch((error) => {});
    },
    orderPermissions(permissions, exclude = [], selected = []) {
      let list = [];
      let current = 0;
      let obj = {};
      permissions.forEach((element) => {
        if (exclude.includes(element.content) || exclude.includes(element.content_label))
          return 0;
        if (element.content_type != current && current != 0) {
          list.push(obj);
          obj = {};
          current = element.content_type;
        }
        if (!obj.add) {
          obj.add = element.id;
          obj.table = element.content;
          obj.is_add = selected.includes(element.id);
          obj.is_all;
          current = element.content_type;
        } else if (!obj.change) {
          obj.change = element.id;
          obj.is_change = selected.includes(element.id);
        } else if (!obj.delete) {
          obj.delete = element.id;
          obj.is_delete = selected.includes(element.id);
        } else {
          obj.view = element.id;
          obj.is_view = selected.includes(element.id);
        }
      });
      list.push(obj);
      return list;
    },
    addPermission(id) {
      if (this.group.permissions.includes(id))
        this.group.permissions.splice(this.group.permissions.indexOf(id), 1);
      else this.group.permissions.push(id);
    },
    checkRow(item) {
      if (item.is_all) {
        if (!item.is_add) this.addPermission(item.add);
        if (!item.is_change) this.addPermission(item.change);
        if (!item.is_delete) this.addPermission(item.delete);
        if (!item.is_view) this.addPermission(item.view);
      } else {
        if (item.is_add) this.addPermission(item.add);
        if (item.is_change) this.addPermission(item.change);
        if (item.is_delete) this.addPermission(item.delete);
        if (item.is_view) this.addPermission(item.view);
      }
      item.is_change = item.is_all;
      item.is_delete = item.is_all;
      item.is_add = item.is_all;
      item.is_view = item.is_all;
    },
    checkAll() {
      this.group.permissions = [];
      if (this.check_all)
        this.permission_list.forEach((element) => {
          this.group.permissions.push(element.add);
          this.group.permissions.push(element.delete);
          this.group.permissions.push(element.change);
          this.group.permissions.push(element.view);
          element.is_change = true;
          element.is_delete = true;
          element.is_add = true;
          element.is_view = true;
          element.is_all = true;
        });
      else
        this.permission_list.forEach((element) => {
          element.is_change = false;
          element.is_delete = false;
          element.is_add = false;
          element.is_view = false;
          element.is_all = false;
        });
    },
    isUpdate() {
      this.group.group_for = [];
      if (this.group.id == undefined) {
        this.is_update = false;
        this.group.name = undefined;
        this.group.permissions = [];
      } else {
        this.is_update = true;
        let group = this.group_list.find((item) => item.pk == this.group.id);
        if (group) {
          this.group.name = group.name;
          this.permission_list = this.orderPermissions(
            this.all_permission_list,
            this.exclude,
            group.permissions
          );
          this.group.permissions = group.permissions;
          if (group.group_for) {
            let group_for_list = group.group_for.replace(/^\[|\]$/g, "").split(",");
            group_for_list.forEach((element) => {
              this.group.group_for.push(parseInt(element));
            });
          }
        }
      }
    },
    saveGroup() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.saveProgress = true;
        // if(localStorage.getItem('role') >= 4)
        // this.group.permissions.push(...this.default_permission)
        this.axios
          .post(`${this.base_url}groups/`, this.group, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
            this.getGroups();
            this.saveProgress = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveProgress = false;
          });
      }
    },
    updateGroup() {
      this.v$.$validate();
      if (!this.v$.$error && this.group.id != undefined) {
        this.saveProgress = true;

        // if(this.default_permission.length && !this.group.permissions.includes(this.default_permission[0]))
        //     this.group.permissions.push(...this.default_permission)
        this.axios
          .put(`${this.base_url}groups/${this.group.id}/`, this.group, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then((response) => {
            if (response.data && response.data == "group_for_error") {
              this.$emit("errorAlert", this.$t("globals.group_for_error"));
            } else {
              this.$emit("successAlert", this.$t("globals.data_updated"));
              this.resetForm();
              this.getGroups();
            }
            this.saveProgress = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveProgress = false;
          });
      }
    },
    async deleteGroup() {
      if (this.group.id != undefined) {
        this.deleteProgress = true;
        let result = await this.axios
          .delete(`${this.base_url}groups/${this.group.id}/`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_deleted"));
            this.resetForm();
            this.getGroups();
            this.del_dialog = false;
            this.deleteProgress = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.deleteProgress = false;
          });
      }
    },
    resetForm() {
      this.check_all = false;
      this.is_update = false;
      this.group.id = undefined;
      this.group.name = undefined;
      this.default_permission.forEach((element) => {
        this.group.permissions.push(element);
      });
      this.group.group_for = [];
      this.v$.group.$reset();
      this.permission_list = this.orderPermissions(
        this.all_permission_list,
        this.exclude,
        this.default_permission
      );
    },
  },
};
</script>
<style scoped>
td,
th {
  text-align: start;
}
.row-hover:hover {
  background: #d4d3d3;
}
</style>
