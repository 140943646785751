<template>
  <v-table
    density="compact"
    class="rounded elevation-1 striped-table"
    fixed-header
    :height="months?.length > 10 ? 400 : null"
  >
    <thead>
      <tr>
        <th class="text-center border">{{ $t("semester.semester") }}</th>
        <th class="text-center border">{{ $t("month.months") }}</th>
        <th class="text-center border">{{ $t("semester.acyual_semester") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(term, tIndex) in semestersPlans" :key="tIndex">
        <td class="text-center border">{{ term.name_ar }}</td>
        <td class="border">
          <v-row dense>
            <v-col v-for="month in hijri_months_choices" :key="month.id" cols="2">
              <VCheckbox
                :value="month.id"
                :label="month.name"
                v-model="term.selected_months"
                hide-details
                dense
                @change="handleMonthChange(month.id, term)"
              >
              </VCheckbox>
            </v-col>
          </v-row>
        </td>
        <td class="border">
          <VCheckbox
            v-model="term.current_semester"
            @change="handleActiveTermChange(term)"
            hide-details
          >
          </VCheckbox>
        </td>
      </tr>
    </tbody>
    <VBtnSave @click="saveItem()" :loading="saveDataLoading" class="ma-2">
      {{ $t("globals.save") }}
    </VBtnSave>
  </v-table>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      protected_records: false,
      data_message: [],
      semestersPlans: [],
      saveDataLoading: false,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      updated_id: undefined,
      delete_id: undefined,
    };
  },
  computed: {
    ...mapState({
      hijri_months_choices: (state) => state.hijri_months_choices,
      user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.GetHijriMonthsChoices();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    handleMonthChange(monthId, currentTerm) {
      if(currentTerm.selected_months.length > currentTerm.num_months) {
         this.$emit("warningAlert", `لا يمكنك اختيار أكثر من ${currentTerm.num_months} أشهر`);
         this.$nextTick(()=>{
           const index = currentTerm.selected_months.indexOf(monthId);
           if(index > -1){
             currentTerm.selected_months.splice(index, 1);
           }
         });
        return;
      }

      this.semestersPlans.forEach((term) => {
        if (term !== currentTerm && term.selected_months.includes(monthId)) {
          const monthIndex = term.selected_months.indexOf(monthId);
          if (monthIndex > -1) {
            term.selected_months.splice(monthIndex, 1);
          }
        }
      });
      currentTerm.selected_months.sort((a,b)=> a - b);
    },
    handleActiveTermChange(currentTerm) {
      if (currentTerm.current_semester) {
        this.semestersPlans.forEach((term) => {
          if (term !== currentTerm) {
            term.current_semester = false;
          }
        });
      }
    },
    validateMonthConsecutiveness() {
      for (let term of this.semestersPlans) {
        const selected_months = term.selected_months;
        if (selected_months.length > 0) {
          const minIndex = Math.min(...selected_months);
          const maxIndex = Math.max(...selected_months);
          const expectedLength = maxIndex - minIndex + 1;
          if (selected_months.length !== expectedLength) {
             return false;
          }
        }
      }
      return true;
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      GetHijriMonthsChoices: "GetHijriMonthsChoices",
    }),
    resetForm() {
      // this.$refs.form.reset();
      // this.updated_id = undefined;
      // this.saveDataLoading = false;
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/summer/semester-plan/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.semestersPlans = response.data;
        // this.pagination = response.data.pagination;
        this.loading = false;
      });
    },
    async saveItem() {
      if (this.validateMonthConsecutiveness()) {
          this.saveDataLoading = true;
          let result = await this.axios
            .post(this.base_url + "api/summer/semester-plan/", 
            {
              months_semester: this.semestersPlans
            }, {
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            })
            .then(() => {
              this.$emit("successAlert", this.$t("globals.data_added"));
              this.saveDataLoading = false;
              this.resetForm();
            })
            .catch((error) => {
              if (error.response.data[0]) {
                this.$emit("warningAlert", this.$t("summer.already-releated"));
              } else {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
              }
              this.saveDataLoading = false;
            });
          this.getItems();
      } else {
        this.$emit("warningAlert", this.$t("semester.secuence_semester"));
      }
    },
  },
};
</script>
<style></style>
