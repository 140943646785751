<template>
  <v-card class="pa-3">
    <v-card-title>
      {{ $t("globals.select-criteria") }}
    </v-card-title>
    <v-card-text class="mt-2">
      <v-form ref="form" v-if="!show">
        <v-row>
          <v-col cols="12" md="3">
            <v-select
              v-model="filter.type"
              :items="[
                {
                  id: 1,
                  title: 'طلاب',
                },
                {
                  id: 2,
                  title: 'معلمين',
                },
              ]"
              item-title="title"
              item-value="id"
              density="compact"
              :label="$t('globals.type_report')"
              :rules="[$require]"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="filter.fk_level"
              :items="levels"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              hide-details="auto"
              :rules="[$required]"
              clearable
              @update:model-value="
                getLevelBySubject(), (filter.fk_levelsubject = undefined)
              "
            />
          </v-col>
          <v-col md="3">
            <VAutocomplete
              v-model="filter.fk_levelsubject"
              :items="subjects"
              prepend-inner-icon="mdi-book-open"
              item-title="name"
              item-value="id"
              :label="$t('subject.subject')"
              persistent-hint
              density="compact"
              clearable
            />
          </v-col>
          <v-col cols="2" class="mt-2">
            <custom-btn type="show" :click="() => getData()" />
          </v-col>
        </v-row>
      </v-form>
      <custom-table-report
        v-else
        :close="() => reset()"
        :headers="headers"
        :items="items"        
        counter
      />
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      filter: { type: 1 },
      items: [],
      headers: [],
      levels: [],
      subjects: [],
      btn_loading: false,
      show: false,
    };
  },
  created() {
    this.getLevels();
  },
  methods: {
    async getData() {
      this.items = [];
      const { valid } = await this.$refs.form.validate();
      if (valid)
        await this.axios(`${this.base_url}api/summer/report/approaches-statistics/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            the_type: this.filter.type,
            fk_level: this.filter.fk_level,
            LevelSubject: this.filter.fk_levelsubject,
          },
        })
          .then((response) => {
            this.items = response.data[0].data;
            if (this.items.length > 0) {
              this.headers = response.data[0].headers;
              this.show = true;
            } else this.$emit("infoAlert", this.$t("globals.not-found"));
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },

    reset() {
      this.show = false;
      this.items = [];
    },
    async getLevelBySubject() {
      this.subjects = [];
      await this.axios(`${this.base_url}api/get-subject-by-level`, {
        params: {
          fk_level: this.filter.fk_level,
        },
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.subjects = response.data;
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
};
</script>
