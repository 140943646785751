<template>
  <!-- added by samer -->
  <!-- شاشة تحضير الموظفين -->
  <VForm ref="form">
    <v-card>
      <v-card-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-card-title>
      <v-card-text class="my-2">
        <v-row class="py-2">
          <v-col cols="3">
            <v-autocomplete
              color="indigo"
              v-model="dropList.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="month"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
              @update:model-value="getCurrentMonth"
            />
          </v-col>
          <v-col cols="4">
            <DatetimePicker
              v-if="show"
              v-model="dropList.date"
              :minDate="minDate"
              :maxDate="maxDate"
              :rules="[$required]"
              :disabled="dropList.fk_monthsemester ? false : true"
              @update:model-value="items = []"
              forma="dd"
              readOnly
            />
          </v-col>

          <v-col cols="3" md="4" class="mt-2">
            <v-btn
              @click="getData"
              :loading="loading_btn"
              color="primary"
              density="comfortable"
              append-icon="mdi-presentation"
              class="me-3"
            >
              <span class="text-white">
                {{ $t("globals.show") }}
              </span>
            </v-btn>

            <v-label
              v-if="items.length > 0 && 'attendance_id' in items[0]"
              :text="$t('globals.prepared_in_advance')"
            ></v-label>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </VForm>

  <v-card>
    <v-card-item>
      <v-data-table-virtual
        :headers="headers"
        :items="items"
        :loading="loading"
        :no-data-text="$t('globals.not-found')"
      >
        <template v-slot:item.is_attendance="{ item }">
          <v-checkbox-btn
            v-model="item.is_attendance"
            @change="
              () => {
                if (!item.is_attendance) {
                  item.first_phase = false;
                  item.second_phase = false;
                  item.third_phase = false;
                } else{
                  item.is_permission = false;
                }
              }
            "
          />
        </template>
        <template v-slot:item.is_permission="{ item }">
        <v-lazy>
          <v-checkbox-btn
            v-model="item.is_permission"
            @change="item.is_permission ? (item.is_attendance = false,item.first_phase = false,
                  item.second_phase = false,
                  item.third_phase = false) : ''"
            :readonly="item.reason && item.is_permission"
          >
            <template v-slot:label>
              <v-icon
                v-if="item.reason"
                icon="mdi-alert-circle"
                color="red"
                style="direction: rtl"
                class="ms-4"
              />
              <v-tooltip activator="parent" location="top">
                {{ item.reason }}
              </v-tooltip>
            </template>
          </v-checkbox-btn>
        </v-lazy>
      </template>
        <template v-slot:item.first_phase="{ item }">
          <v-checkbox-btn
            v-model="item.first_phase"
            :disabled="!item.is_attendance"
          />
        </template>
        <template v-slot:item.second_phase="{ item }">
          <v-checkbox-btn
            v-model="item.second_phase"
            :disabled="!item.is_attendance"
          />
        </template>
        <template v-slot:item.third_phase="{ item }">
          <v-checkbox-btn
            v-model="item.third_phase"
            :disabled="!item.is_attendance"
          />
        </template>
      </v-data-table-virtual>
    </v-card-item>

    <v-card-actions>
      <VBtn
        v-if="items.length > 0 && !('attendance_id' in items[0])"
        class="bg-primary ma-3"
        @click.prevent="saveData()"
        size="small"
        :loading="save_loading"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-else-if="items.length > 0 && 'attendance_id' in items[0]"
        class="bg-primary ma-3"
        @click.prevent="saveData()"
        size="small"
        :loading="save_loading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
    </v-card-actions>
  </v-card>
</template>

<script>
import moment from "moment-hijri";

export default {
  data() {
    return {
      dropList: {},
      items: [],
      months: [],
      loading_btn: false,
      loading: false,
      save_loading: false,
      show: true,
      minDate: null,
      maxDate: null,
    };
  },
  async created() {
    await this.getMonths();

    this.getData();
  },
  methods: {
    getCurrentMonth() {
        this.dropList.date = undefined
      if (this.dropList.fk_monthsemester) {
        this.items = [];
        this.show = false;
        this.dropList.date = null;
        const month =
          this.dropList.fk_monthsemester < 10
            ? "0" + this.dropList.fk_monthsemester.toString()
            : this.dropList.fk_monthsemester;
        const now = moment();
        const startOfMonth = now.iYear() + "-" + month + "-01";
        const endOfMonth = moment(
          `${now.iYear()}-${this.dropList.fk_monthsemester + 1}-01`,
          "iYYYY-iM-iDD"
        )
          .subtract(1, "day")
          .format("iYYYY-iMM-iDD");
        this.minDate = startOfMonth;
        this.maxDate = endOfMonth;

        if (
          this.months.find(
            (e) =>
              e.month == this.dropList.fk_monthsemester &&
              e.current_month == true
          ) &&
          this.dropList.fk_monthsemester == moment().format("iM")
        )
          this.dropList.date = moment().format("iYYYY-iMM-iDD");
        else this.dropList.date = this.minDate;

        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.dropList.fk_monthsemester = current_month.month;
            this.getCurrentMonth();
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        this.loading_btn = true;
        await this.axios(`${this.base_url}/api/employee-attendance/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_monthsemester: this.months.find(
              (e) => e.month == this.dropList.fk_monthsemester
            ).id,
            date: this.dropList.date,
          },
        })
          .then((response) => {
            this.items = response.data;
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.loading = false;
        this.loading_btn = false;
      }
    },
    async saveData() {
      this.save_loading = true;
      const data = this.items.map((e) => {
        return {
          ...e,
          fk_monthsemester: this.months.find(
            (e) => e.month == this.dropList.fk_monthsemester
          ).id,
          attendance_date: this.dropList.date,
        };
      });

      await this.axios
        .post(
          `${this.base_url}/api/employee-attendance/`,
          {
            data: data,
            attendance_date: this.dropList.date,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((e) => {
          this.getData();
          this.$emit("successAlert", this.$t("globals.data_updated"));
        })
        .catch((e) => {
          if (e.response.status == 406) {
            this.$emit("infoAlert", e.response.data);

            return;
          }

          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
      this.save_loading = false;
    },
  },
  computed: {
    headers() {
      return [
        { title: this.$t("year.placeholder.name"), key: "emp_name" },
        { title: this.$t("summer.work-type"), key: "role" },
        { title: this.$t("student-attendance.is-permission"), key: "is_permission",
        },
        {
          title:
            this.$t("student-attendance.is-attendance") +
            " / " +
            this.$t("student-attendance.absent"),
          key: "is_attendance",
        },
        {
          title: this.$t("globals.first_phase"),
          key: "first_phase",
        },
        { title: this.$t("globals.second_phase"), key: "second_phase" },
        { title: this.$t("globals.third_phase"), key: "third_phase" },
      ];
    },
  },
};
</script>
