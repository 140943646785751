<template>
  <!-- added by samer -->
  <!-- تقرير الشهادة الطلاب الشهرية -->
  <v-card class="pa-3" v-if="!show">
    <v-card-title>{{ $t("globals.select-criteria") }}</v-card-title>
    <v-card-text class="mt-2">
      <v-form ref="form">
        <v-row class="mt-2">
          <v-col cols="3" md="3">
            <v-autocomplete
              :items="semesters"
              v-model="filter.fk_semester"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name_ar"
              item-value="id"
              :label="$t('semester.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
              :rules="[$required]"
              @update:modelValue="
                getMonthsBySemester(), (this.filter.fk_monthsemester = null)
              "
            />
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              color="indigo"
              v-model="filter.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="id"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              color="primary"
              v-model="filter.fk_level"
              density="compact"
              item-title="name_ar"
              item-value="id"
              prepend-inner-icon="mdi-stairs-box"
              :items="levels"
              clearable
              :label="$t('summer.level.name_ar')"
              :rules="[$required]"
              @update:model-value="getStudents(), getDivisionByLevel()"
              return-object
            />
          </v-col>
          <v-col cols="12" md="3" lg="3">
            <v-autocomplete
              :items="divisions"
              v-model="filter.fk_division"
              item-title="fk_division__name"
              item-value="fk_division__id"
              :label="$t('division.select')"
              persistent-hint
              hide-details="auto"
              prepend-inner-icon="mdi-shape-outline"
              density="compact"
              clearable
              @update:model-value="getStudents"
            />
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              color="primary"
              v-model="filter.fk_levelstudentyear"
              density="compact"
              item-title="fk_student__name"
              item-value="id"
              prepend-inner-icon="mdi-account"
              :items="students"
              clearable
              :label="$t('reportpublic.student-name')"
              return-object
            />
          </v-col>
          <custom-btn type="show" class="mt-2" :click="() => getData()" />
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>

  <v-card v-else>
    <v-card-text>
      <v-row class="text-center align-center hide-item pa-5">
        <custom-btn type="print" @click="printReport" class="me-3" />

        <v-spacer />
        <v-icon v-if="show" icon="mdi-close" class="me-2" @click="reset" end />
      </v-row>
      <!-- -->
      <v-card style="width: 210mm" class="pa-5" ref="report">
        <v-col v-for="(items, index) in data" :key="index" class="heigth-print">
          <!-- <custom-report-header
            :title=""
            :sub_title="
              $t('year.placeholder.for-year') +
              '( ' +
              items.year_h +
              ' )  (' +
              items.year_m +
              ' )'
            "
          /> -->
            <ReportHeader class="pb-0">
                <template v-slot:header-title>
                  <div class="d-flex justify-center py-2">
                    <v-col>
                       <div class="text-h5">
                        {{$t('report.month_grade_report')  }} {{ items.month_name}}
                         </div>
                    <p >
                      {{$t('year.placeholder.for-year')  }} ( {{items.year_h}} ) ( `{{ items.year_m}} )
                    </p>

                    </v-col>
                   
                  </div>
                </template>
            </ReportHeader>
          <v-container class="border color_heade">
            <v-row>
              <span>{{ $t("globals.student_id") }} : </span>
              <span>{{ items.stu_no }}</span>

              <span class="ms-3">{{ $t("school.student_name") }} : </span>
              <span>{{ items.stu_name }}</span>
              <v-spacer />

              <span>{{ $t("report.class") }} : </span>
              <span>{{ filter.fk_level ? filter.fk_level.name_ar : "" }}</span>
            </v-row>
          </v-container>

          <v-container class="ma-0 ps-0 overflow-hidden">
            <v-row>
              <v-col cols="9">
                <table class="t-bordered">
                  <tbody>
                    <tr class="color_heade">
                      <th style="width: 50mm">{{ $t("subject.subject") }}</th>

                      <th class="px-1">
                        {{ $t("monthly_marks.attendance") }} <br />
                        {{ setting.attendance }}
                      </th>
                      <th class="px-1">
                        {{ $t("monthly_marks.assigments") }} <br />
                        {{ setting.assignment }}
                      </th>
                      <th class="px-1">
                        {{ $t("monthly_marks.oral") }} <br />
                        {{ setting.oral }}
                      </th>
                      <th class="px-1">
                        {{ $t("monthly_marks.ehsan") }} <br />
                        {{ setting.behaviior_charity }}
                      </th>
                      <th class="px-1">
                        {{ $t("monthly_marks.activity") }} <br />
                        {{ setting.monthly_activity }}
                      </th>
                      <th class="px-1">
                        {{ $t("monthly_marks.total") }} <br />
                        {{ getTotalHead(setting) }}
                      </th>
                      <th style="width: 15mm">{{ $t("report.writing") }}</th>
                      <th style="width: 15mm">{{ $t("school.estimiate") }}</th>
                    </tr>
                    <tr v-for="(item, index) in items.data" :key="index">
                      <td class="pa-2" :colspan="!item.add_to_total ? 6 : ''">
                        {{ item.subject_name }}
                      </td>
                      <td class="pa-2" v-if="item.add_to_total">
                        {{ item.attendance }}
                      </td>
                      <td class="pa-2" v-if="item.add_to_total">
                        {{ item.assigments }}
                      </td>
                      <td class="pa-2" v-if="item.add_to_total">
                        {{ item.oral }}
                      </td>
                      <td class="pa-2" v-if="item.add_to_total">
                        {{ item.behaviior_charity }}
                      </td>
                      <td class="pa-2" v-if="item.add_to_total">
                        {{ item.monthly_activity }}
                      </td>
                      <td class="pa-2 color_heade">{{ item.total }}</td>
                      <td class="pa-2">{{ numbersToWords(item.total) }}</td>
                      <td class="pa-2">{{ item.estimate }}</td>
                    </tr>

                    <!-- مجموع الدرجات النهائي -->
                    <tr>
                      <td colspan="6" class="text-start pa-2 color_heade">
                        {{ $t("report.total-degrees") }}
                      </td>
                      <td class="color_heade">{{ items.total_degrees }}</td>
                      <td colspan="2">
                        {{ numbersToWords(items.total_degrees) }}
                      </td>
                    </tr>

                    <!-- مجموع الدرجات بعد خصم درجات الغياب -->
                    <!-- <tr>
                      <td colspan="6" class="text-start pa-2 color_heade">
                        {{ $t("globals.total_grades_after_absence") }}
                      </td>
                      <td class="color_heade">720</td>
                      <td colspan="2">720</td>
                    </tr> -->
                  </tbody>
                </table>
              </v-col>
              <v-col cols="3" class="pa-0 ma-0">
                <v-container style="border: 1px solid">
                  <h3
                    class="text-center pa-5 color_heade"
                    style="border: 1px solid"
                  >
                    {{ $t("globals.number_days_attendance") }}
                  </h3>

                  <div class="d-flex justify-center align-center">
                    <span class="px-2 my-2" style="border: 1px solid">{{
                      items.total_attendance
                    }}</span>
                  </div>
                  <h3
                    class="text-center pa-5 color_heade"
                    style="border: 1px solid"
                  >
                    {{ $t("globals.number_days_absence") }}
                  </h3>
                  <v-row class="mt-5" justify="align-center">
                    <v-col>
                      <h4 class="text-center pa-1">
                        {{ $t("globals.is_permission") }}
                      </h4>
                    </v-col>
                    <v-col>
                      <p class="text-center py-1" style="border: 1px solid">
                        {{ items.permission }}
                      </p>
                    </v-col>
                  </v-row>

                  <v-row class="mt-5" justify="align-center">
                    <v-col>
                      <h4 class="text-center">{{ $t("globals.with_out") }}</h4>
                    </v-col>
                    <v-col>
                      <p class="text-center py-1" style="border: 1px solid">
                        {{ items.absent }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="mt-5" justify="align-center">
                    <v-col>
                      <h4 class="text-center">{{ $t("globals.total") }}</h4>
                    </v-col>
                    <v-col>
                      <p class="text-center py-1" style="border: 1px solid">
                        {{ items.total_absent }}
                      </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="border color_heade">
            <v-row>
              <v-col lass="text-start">
                <span>{{ $t("globals.percentage") }}: </span>
                <span> {{ items.percentage }} %</span>
              </v-col>
              <v-col class="text-center">
                <span class="ms-3">{{ $t("monthly_marks.order") }} : </span>
                <span>------</span>
              </v-col>

              <v-col class="text-end">
                <span>{{ $t("report.estimate") }} : </span>
                <span> ------</span>
              </v-col>
            </v-row>
          </v-container>
          <v-container class="border mt-2">
            <v-row>
              <v-col class="text-center">
                <p><strong>ملاحظات رائد الفصل</strong></p>
                <p class="mt-10 me-5 text-start ms-5">
                  <strong>التوقيع</strong>
                </p>
              </v-col>
              <v-col class="text-center">
                <p>
                  <strong
                    >{{ $t("globals.comments") }}
                    {{ $t("report.parent_remaining") }}</strong
                  >
                </p>
                <p class="mt-10 me-5 text-start ms-5">
                  <strong>التوقيع</strong>
                </p>
              </v-col>
            </v-row>
          </v-container>
          <v-container>
            <v-row>
              <v-col cols="auto">
                <p>ملاحظة : (*) تعني ان المادة غير داخلة في المجموع</p></v-col
              >
              <v-spacer />
              <v-col class="text-end"
                ><p>{{ $t("school.headmaster") }}</p></v-col
              >
            </v-row>
          </v-container>
        </v-col>
      </v-card>
    </v-card-text>
  </v-card>
  <chart :option="op" autoresize />
</template>
<script>
import tafqeet from "../../../assets/Tafqeet";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";

export default {
    components: {
    ReportHeader,
  },
  data() {
    return {
      search: "",
      filter: {},
      setting: {},

      data: [],
      students: [],
      levels: [],
      semesters: [],
      months: [],
      divisions: [],

      show: false,

      minDate: null,
      maxDate: null,
    };
  },
  created() {
    this.getLevel();
    this.getSetting();
    this.getSemester();
  },
  methods: {
    reset() {
      this.show = false;
      this.data = [];
    },
    async getData() {
      const { valid } = await this.$refs.form.validate();
      if (valid)
        await this.axios(
          `${this.base_url}api/summer/report/certificates-report/month/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },

            params: {
              fk_levelstudentyear: this.filter.fk_levelstudentyear
                ? this.filter.fk_levelstudentyear.id
                : "",
              fk_level: this.filter.fk_level.id,
              fk_division: this.filter.fk_division,
              fk_monthsemester: this.filter.fk_monthsemester,
            },
          }
        )
          .then((response) => {
            this.data = response.data;
            this.show = true;
            //   this.title = response.data.title;
            // if (this.data.data.length > 0) {
            // } else this.$emit("infoAlert", this.$t("globals.not-found"));
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },

    numbersToWords(total) {
      return tafqeet(total);
    },
    printReport() {
      setTimeout(() => {
        const printContent = this.$refs.report.$el.innerHTML;
        document.getElementById("printView").style.display = "block";
        document.getElementById("printView").innerHTML = printContent;
        document.getElementById("printView").style.direction = "rtl";
        document.getElementById("appViewId").style.display = "none";
        // document.getElementById('dataTableReport').style.height = 'auto'
        window.print();
        document.getElementById("printView").innerHTML = "";
        document.getElementById("printView").style.display = "none";
        document.getElementById("appViewId").style.display = "block";
        // document.getElementById('dataTableReport').style.height = '450px'
      }, 10);
    },
    async getStudents() {
      this.filter.fk_levelstudentyear = undefined;
      this.students = [];
      if (this.filter.fk_level)
        await this.axios(`${this.base_url}api/student-by-level-choices`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            fk_level: this.filter.fk_level.id,
            fk_division: this.filter.fk_division ?? "",
          },
        })
          .then((response) => (this.students = response.data))
          .catch(() => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },
    async getLevel() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getSetting() {
      let semester = await this.axios.get(
        this.base_url + `/api/setting-system/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.setting = semester.data;
    },
    getTotalHead(obj) {
      let sum =
        parseInt(obj.attendance) +
        parseInt(obj.assignment) +
        parseInt(obj.oral) +
        parseInt(obj.behaviior_charity) +
        parseInt(obj.monthly_activity);
      return sum;
    },

    async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
      const current_semester = this.semesters.find((e) => e.current_semester);
      if (current_semester) {
        this.filter.fk_semester = current_semester.id;
        this.getMonthsBySemester();
      }
    },
    async getMonthsBySemester() {
      if (this.filter.fk_semester)
        await this.axios(
          `${this.base_url}/api/months-semester/?fk_semester=${this.filter.fk_semester}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then((response) => {
            this.months = response.data;
            const current_month = response?.data?.find((e) => e.current_month);
            if (current_month) {
              this.filter.fk_monthsemester = current_month.month;
            }
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      else this.months = [];
    },
    async getDivisionByLevel() {
      this.filter.fk_division = undefined;
      this.divisions = [];

      if (this.filter.fk_level)
        await this.axios(`${this.base_url}api/division-choices`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          params: {
            fk_level: this.filter.fk_level.id,
          },
        })
          .then((response) => (this.divisions = response.data))
          .catch(() => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      else this.filter.fk_division = [];
    },
  },
};
</script>
<style scoped>
table td,
th {
  border: 1px solid;
  text-align: center;
  justify-content: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}
.heigth-print{
    margin-top: 20px;
  }
@media print {
.heigth-print{
    height:297mm;    
    margin-top: 20px;
  }
}
</style>