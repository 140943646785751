<template>
  <DataTable
    v-if="CheckGetPermission('globaltable.view_governorate')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      CheckGetPermission('globaltable.delete_governorate', ['sys_admin', 'gen_admin'])
    "
    :edit_perm="
      CheckGetPermission('globaltable.change_governorate', ['sys_admin', 'gen_admin'])
    "
    :add_perm="
      CheckGetPermission('globaltable.add_governorate', ['sys_admin', 'gen_admin'])
    "
  >
  </DataTable>
  <v-dialog v-model="addDialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-2">
        <VForm ref="form" @submit.prevent="is_update ? updateItem() : saveItem()">
          <VRow class="mt-2">
            <VCol cols="6">
              <VAutocomplete
                :items="countries"
                autofocus
                v-model="governorate.fk_country"
                prepend-inner-icon="mdi-flag"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.student-in-semester')"
                persistent-hint
                :auto-select-first="true"
                density="compact"
                :rules="rules.fk_country"
              ></VAutocomplete>
            </VCol>
            <VCol cols="6">
              <VAutocomplete
                :items="countries"
                autofocus
                v-model="governorate.fk_country"
                prepend-inner-icon="mdi-flag"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.type')"
                persistent-hint
                :auto-select-first="true"
                density="compact"
                :rules="rules.fk_country"
              ></VAutocomplete>
            </VCol>
            <VCol cols="6">
              <VAutocomplete
                :items="countries"
                autofocus
                v-model="governorate.fk_country"
                prepend-inner-icon="mdi-flag"
                item-title="name_ar"
                item-value="id"
                :label="$t('school.reason')"
                persistent-hint
                :auto-select-first="true"
                density="compact"
                :rules="rules.fk_country"
              ></VAutocomplete>
            </VCol>
            <VCol cols="6">
              <DatetimePicker
                :calendar="'gregorg'"
                :placeholder="$t('globals.year-month-day')"
                :label="$t('school.start-date')"
                :rules="rules.birthdate"
              ></DatetimePicker>
            </VCol>
            <VCol cols="6">
              <DatetimePicker
                :calendar="'gregorg'"
                :placeholder="$t('globals.year-month-day')"
                :label="$t('school.end-date')"
                :rules="rules.birthdate"
              ></DatetimePicker>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave v-if="!is_update" @click="saveItem()" :loading="saveDataLoading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate v-if="is_update" @click="updateItem()" :loading="saveDataLoading">
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
import DatetimePicker from "@/components/Globals/DatetimePicker.vue";

export default {
  components: {
    DataTable,
    DatetimePicker,
  },
  data() {
    return {
      isChecked: false,
      isChecked: false,
      addDialog: false,
      protected_records: false,
      data_message: [],
      governorates: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      governorate: {
        name_ar: undefined,
        name_en: undefined,
        fk_country: undefined,
        note: undefined,
        code_number: undefined,
      },
      rules: {
        fk_country: [(value) => !!value || this.$t("globals.required_field")],
        name_ar: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 2) ||
            `${this.$t("globals.lowest_number_of_character")} 2`,
        ],
        code_number: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (value && value.length <= 2) ||
            `${this.$t("globals.biggest_number_of_character")} 99`,
          (value) => /^\d*$/.test(value) || this.$t("globals.must_be_numbers"),
        ],
        name_en: [
          (value) =>
            !value || /^[A-Za-z-]+$/.test(value) || this.$t("globals.must_be_letters_en"),
          (value) =>
            !value ||
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            !value ||
            (value && value.length >= 2) ||
            `${this.$t("globals.lowest_number_of_character")} 2`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },

      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },

      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("school.student_level"), key: "name_ar" },
        { title: this.$t("school.personal_evaluation"), key: "name_ar2" },
        { title: this.$t("school.degree"), key: "name_ar3" },
        { title: this.$t("school.estimiate"), key: "name_ar4" },
        { title: this.$t("school.plan_sumester"), key: "name_ar4" },
        { title: this.$t("school.type"), key: "name_ar4" },
        { title: this.$t("school.note"), key: "name_ar4" },
      ],
    };
  },
  computed: {
    ...mapState({
      countries: (state) => state.school.countries,
      governorates: (state) => state.school.governorates,
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.governorates) {
        return JSON.parse(JSON.stringify(this.governorates));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getCountries();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getCountries: "school/getCountries",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
      this.addDialog = false;
    },
    editItem(data) {
      this.governorate = Object.assign({}, data);
      this.is_update = true;
      this.updated_id = data.id;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    getCountryName(fk_country) {
      let country = this.countries.find((cun) => cun.id == fk_country);
      return country.name_ar;
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async getItems(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/governorate-for-data-table/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.governorates = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.saveDataLoading = true;
        this.governorate.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/governorate/", this.governorate, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("warningAlert", this.$t("summer.already-governorate"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.governorate.id != undefined) {
        this.editDataLoading = true;
        this.governorate.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}/governorate/${this.governorate.id}/`, this.governorate, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("errorAlert", this.$t("summer.already-governorate"));
            } else if (error.response.data.name_en) {
              this.$emit("warningAlert", this.$t("summer.already-governorate-en"));
            } else this.$emit("warningAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(`${this.base_url}/governorate/${delete_id}/`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>
<style></style>
