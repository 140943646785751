<template>
  <v-dialog v-model="dialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form">
          <v-autocomplete
            color="indigo"
            v-model="dropList.fk_monthsemester"
            density="compact"
            item-title="month_name"
            item-value="id"
            prepend-inner-icon="mdi-calendar-month"
            :items="months"
            :label="$t('month.select')"
            :rules="[$required]"
          />
          <VTextField
            v-model="dropList.responsible"
            :label="$t('summer.recommendations.implementation-officer')"
            persistent-hint
            clearable
            density="compact"
            prepend-inner-icon="mdi-account-tie"
            :rules="[$required, $max_length(100)]"
          ></VTextField>
          <VRadioGroup
            v-model="dropList.is_positive"
            inline
            :label="$t('summer.recommendations.is-optimastic')"
            :rules="[$required]"
            @change="dropList.solution = null"
          >
            <VRadio :label="$t('globals.yes')" :value="true"></VRadio>
            <VRadio :label="$t('globals.no')" :value="false"></VRadio>
          </VRadioGroup>
          <VTextField
            v-model="dropList.title"
            :label="
              dropList.is_positive == 1
                ? $t('summer.recommendations.address')
                : $t('summer.recommendations.address-negative')
            "
            persistent-hint
            rows="2"
            clearable
            density="compact"
            prepend-inner-icon="mdi-format-title"
            :rules="[$required, $max_length(100)]"
          ></VTextField>
          <VTextField
            v-if="dropList.is_positive == 0"
            v-model="dropList.solution"
            :label="$t('summer.recommendations.solutions')"
            persistent-hint
            clearable
            density="compact"
            prepend-inner-icon="mdi-lightbulb-outline"
            :rules="[$max_length(100)]"
          ></VTextField>
          <VTextarea
            clearable
            v-model="dropList.note"
            :label="$t('summer.initiative.note')"
            
            counter="250"
            density="compact"
            rows="2"
            prepend-inner-icon="mdi-note-outline"
            :rules="[$max_length(250)]"
          ></VTextarea>          
        </VForm>
      </v-card-text>

      <VCardActions class="mx-3">
        <VBtnUpdate
          v-if="dropList.id"
          @click="updateData()"
          :loading="btn_loading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtnSave v-else @click="saveData()" :loading="btn_loading">
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtn
          class="mx-3"
          size="small"
          @click="dropList = { is_positive: true }"
        >
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_recommendation')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getData"
    :create="
      () => {
        dialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editData"
    :delItem="delData"
    :del_perm="CheckGetPermission('legal_school.delete_recommendation')"
    :edit_perm="CheckGetPermission('legal_school.change_recommendation')"
    :add_perm="CheckGetPermission('legal_school.add_recommendation')"
  >
    <template v-slot:item.is_positive="{ item }">
      <v-icon color="success" v-if="item.is_positive == true">
        mdi-check-circle
      </v-icon>
      <v-icon color="error" v-else-if="item.is_positive == false">
        mdi-close-circle
      </v-icon>
    </template>
  </DataTable>
</template>
<script>
export default {
  data() {
    return {
      items: [],
      months: [],

      dropList: {
        is_positive: true,
      },
      pagination: {},

      btn_loading: false,
      dialog: false,
    };
  },
  created() {
    this.getMonths();
  },
  methods: {
    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.dropList.fk_monthsemester = current_month.month;
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getData(page = 1, per_page = 10, ordering = null, search = null) {
      this.loading = true;
      if(ordering=='month_name')
        ordering = 'fk_monthsemester_id'
  
      else if(ordering=='-month_name')
        ordering = '-fk_monthsemester_id'


      await this.axios(`${this.base_url}api/summer/recommendation/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      })
        .then((response) => {
          this.items = response.data.results;
          this.pagination = response.data.pagination;
        })
        .catch((e) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });

      this.loading = false;
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.btn_loading = true;
        await this.axios
          .post(
            `${this.base_url}api/summer/recommendation/`,
            {
              ...this.dropList,       
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((e) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.dropList = { is_positive: true };
            this.getData();
          })
          .catch((error) => {
            if (error?.response?.data?.non_field_errors) {
              this.$emit(
                "errorAlert",
                this.$t("alert.failure.unique") + "  العنوان "
              );
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
        this.btn_loading = false;
      }
    },
    editData(data) {
      this.dropList = { ...data };
      this.dialog = true;
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.btn_loading = true;
        await this.axios
          .put(
            `${this.base_url}api/summer/recommendation/${this.dropList.id}/`,
            this.dropList,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.dialog = false;
            this.getData();
          })
          .catch((error) => {
            if (error?.response?.data?.non_field_errors) {
              this.$emit(
                "errorAlert",
                this.$t("alert.failure.unique") + "  العنوان "
              );
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
        this.btn_loading = false;
      }
    },
    async delData(delete_id) {
      if (delete_id) {
        var status;
        this.deleteDataLoading = true;
        await this.axios
          .delete(`${this.base_url}api/summer/recommendation/${delete_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          title: this.$t("month.name"),
          key: "month_name",
     
        },
        {
          title: this.$t("summer.recommendations.name-implementation-officer"),
          key: "responsible",
        },
        { title: this.$t("summer.recommendations.solutions"), key: "solution" },
        {
          title: this.$t("summer.recommendations.is-optimastic"),
          key: "is_positive",
        },
        {
          title: this.$t("summer.recommendations.title-weekly-recommendations"),
          key: "title",
        },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ];
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) {
        this.dropList = {};
        this.$refs.form?.resetValidation();
      }
    },
  },
};
</script>