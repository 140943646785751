<template>
  <v-card rounded="lg" elevation="3" :loading="loading">
    <v-tabs v-model="step" v-bind="steps" stacked>
      <v-tab
        v-for="(item, key) in items"
        :key="key"
        :value="key"
        density
        slider-color="black"
        rounded="lg"
        @click="next(key)"
      >
        <v-icon
          v-if="!item.valid"
          icon="mdi-alert-circle"
          color="red"
          class="mb-2"
        />
        <v-avatar
          v-else
          :color="item.valid ? 'primary' : 'red'"
          size="18"
          class="mb-2"
        >
          <h5>{{ key + 1 }}</h5>
        </v-avatar>
        {{ item.name }}
      </v-tab>
    </v-tabs>
  </v-card>

  <v-card
    rounded
    class="mt-3"
    :disabled="
      !CheckGetPermission('legal_school.change_schoolsummer') ||
      !CheckGetPermission('legal_school.add_schoolsummer')
    "
  >
    <v-card-text>
      <v-window v-model="steps" class="pa-5">
        <v-form ref="form_1" class="mb-3">
          <v-window-item :value="0" class="mt-5" eager>
            <VRow>
              <VCol cols="12" md="8">
                <VRow>
                  <VCol cols="12" md="6">
                    <VTextField
                      v-model="dropList.name_ar"
                      prepend-inner-icon="mdi-abjad-arabic"
                      :label="$t('summer.center_name_ar')"
                      :placeholder="$t('summer.center_name_ar_placeholder')"
                      density="compact"
                      :rules="[$required, $max_length(100), $ar_letters_only]"
                      clearable
                    >
                    </VTextField>
                  </VCol>
                  <VCol cols="12" md="6">
                    <VTextField
                      v-model="dropList.name_en"
                      prepend-inner-icon="mdi-alpha-e"
                      :label="$t('summer.center_name_en')"
                      :placeholder="$t('summer.center_name_en_placeholder')"
                      density="compact"
                      :rules="[$en_letters_only, $max_length(100)]"
                      clearable
                    >
                    </VTextField>
                  </VCol>
                  <VCol cols="12" md="6">
                    <DatetimePicker
                      v-model="dropList.date_of_establishment"
                      :calendar="'gregorg'"
                      :placeholder="$t('school.establish_date')"
                      :label="$t('school.establish_date')"
                    ></DatetimePicker>
                  </VCol>

                  <VCol cols="12" md="6">
                    <VAutocomplete
                      v-model="dropList.students_type"
                      item-title="name"
                      item-value="id"
                      :items="students_type_list"
                      :label="$t('school.school_type')"
                      persistent-hint
                      density="compact"
                      prepend-inner-icon="mdi-calendar-clock-outline"
                      :rules="[$required]"
                      clearable
                    ></VAutocomplete>
                  </VCol>

                  <VCol cols="12" md="6">
                    <VAutocomplete
                      v-model="dropList.shift_time"
                      item-title="name"
                      item-value="id"
                      :items="shift_times_list"
                      :label="$t('summer.shift-time')"
                      persistent-hint
                      density="compact"
                      prepend-inner-icon="mdi-calendar-clock-outline"
                      :rules="[$required]"
                      clearable
                    ></VAutocomplete>
                  </VCol>
                  <VCol cols="12" md="6">
                    <VTextField
                      v-model="dropList.class_count"
                      prepend-inner-icon="mdi-numeric"
                      :label="$t('summer.class-count')"
                      :placeholder="$t('summer.add-class-count')"
                      density="compact"
                      type="number"
                      :rules="[$required, $max_length(2)]"
                      clearable
                    >
                    </VTextField>
                  </VCol>
                  <VCol cols="12" md="6">
                    <v-checkbox
                      v-model="dropList.is_funded"
                      hide-details="auto"
                      :label="$t('summer.is-funded')"
                      :rules="[$required]"
                    />
                  </VCol>

                  <VCol cols="12" md="12" lg="12">
                    <VTextarea
                      v-model="dropList.note"
                      clearable
                      :label="$t('globals.note')"
                      color="primary"
                      density="compact"
                      rows="2"
                      prepend-inner-icon="mdi-note-outline"
                      :rules="[$max_length(250)]"
                    />
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="12" md="4">
                <custom-img
                  v-model="dropList.logo"
                  :title="$t('summer.summer_logo')"
                />
              </VCol>
            </VRow>
          </v-window-item>
        </v-form>
        <v-form ref="form_2" class="mb-3">
          <v-window-item :value="1" class="mt-5" eager>
            <v-row>
              <VCol cols="12" md="4">
                <VTextField
                  v-model="dropList.manager.name_ar"
                  :label="$t('summer.manager')"
                  persistent-hint
                  clearable
                  density="compact"
                  prepend-inner-icon="mdi-account-tie"
                  :rules="[$required, $max_length(100), $ar_letters_only]"
                  :error-messages="
                    dropList.uniqe_manager ? $t('summer.employee.unique') : ''
                  "
                  @update:modelValue="dropList.uniqe_manager = false"
                ></VTextField>
              </VCol>
              <VCol cols="12" md="4">
                <VAutocomplete
                  v-model="dropList.manager.gender"
                  :items="genders"
                  item-title="name"
                  item-value="id"
                  :label="$t('globals.gender')"
                  persistent-hint
                  density="compact"
                  prepend-inner-icon="mdi-gender-male-female"
                  clearable
                ></VAutocomplete>
              </VCol>
            </v-row>
            <v-row>
              <VCol cols="12" md="4">
                <VTextField
                  v-model="dropList.manager.phone_number"
                  :label="$t('summer.employee.mobile-number')"
                  persistent-hint
                  clearable
                  density="compact"
                  type="number"
                  prepend-inner-icon="mdi-cellphone-text"
                  :rules="[$max_length(14), $min_length(9)]"
                ></VTextField>
              </VCol>
              <VCol cols="12" md="4">
                <VAutocomplete
                  v-model="dropList.manager.country_id"
                  :items="countries"
                  item-title="name_ar"
                  item-value="id"
                  :label="$t('globals.country')"
                  persistent-hint
                  density="compact"
                  prepend-inner-icon="mdi-flag"
                  @update:modelValue="
                    (dropList.manager.governorate_id = undefined),
                      (dropList.manager.fk_directorate = undefined)
                  "
                  :rules="[$required]"
                  clearable
                ></VAutocomplete>
              </VCol>
            </v-row>
            <v-row>
              <VCol cols="12" md="4">
                <VAutocomplete
                  v-model="dropList.manager.governorate_id"
                  :items="
                    governorates.filter(
                      (e) => e.fk_country === dropList.manager.country_id
                    )
                  "
                  item-title="name_ar"
                  item-value="id"
                  :label="$t('globals.governorate')"
                  persistent-hint
                  density="compact"
                  prepend-inner-icon="mdi-city"
                  @update:modelValue="
                    dropList.manager.fk_directorate = undefined
                  "
                  :rules="[$required]"
                  clearable
                ></VAutocomplete>
              </VCol>
              <VCol cols="12" md="4">
                <VAutocomplete
                  v-model="dropList.manager.fk_directorate"
                  :items="
                    directorates.filter(
                      (e) => e.fk_governorate == dropList.manager.governorate_id
                    )
                  "
                  item-title="name_ar"
                  item-value="id"
                  :label="$t('globals.directorate')"
                  persistent-hint
                  density="compact"
                  prepend-inner-icon="mdi-map-outline"
                  :rules="[$required]"
                  clearable
                ></VAutocomplete>
              </VCol>
              -
            </v-row>

            <v-row v-if="role < 4 && !dropList.manager['id']">
              <v-col cols="6" md="4">
                <VTextField
                  v-model="dropList.username"
                  :label="$t('user.username')"
                  :placeholder="$t('user.username')"
                  maxLength="50"
                  persistent-hint
                  clearable
                  density="compact"
                  autocomplete="new-username"
                  prepend-inner-icon="mdi-account"
                  :rules="
                    dropList.password?.length > 0
                      ? [$required, $max_length(40)]
                      : ''
                  "
                  :error-messages="
                    dropList.uniqe_user ? $t('user.username-unique') : ''
                  "
                  @update:modelValue="dropList.uniqe_user = false"
                ></VTextField>
              </v-col>
              <v-col cols="6" md="4">
                <VTextField
                  v-model="dropList.password"
                  :label="$t('user.password')"
                  :placeholder="$t('user.password')"
                  persistent-hint
                  clearable
                  type="password"
                  maxLength="128"
                  autocomplete="new-password"
                  density="compact"
                  prepend-inner-icon="mdi-lock"
                  :rules="
                    dropList.username?.length > 0
                      ? [$required, $max_length(100)]
                      : ''
                  "
                ></VTextField>
              </v-col>
            </v-row>
          </v-window-item>
        </v-form>
        <v-form ref="form_3" class="mb-3">
          <v-window-item :value="2" class="mt-5" eager>
            <VRow>
              <VCol cols="12" md="8" lg="8">
                <VRow>
                  <VCol cols="12" md="6">
                    <VAutocomplete
                      v-model="dropList.country_id"
                      :items="countries"
                      item-title="name_ar"
                      item-value="id"
                      :label="$t('globals.country')"
                      persistent-hint
                      density="compact"
                      prepend-inner-icon="mdi-flag"
                      :disabled="role > 1"
                      @update:modelValue="
                        (dropList.governorate_id = null),
                          (dropList.fk_directorate = null)
                      "
                      :rules="[$required]"
                      clearable
                    ></VAutocomplete>
                  </VCol>
                  <VCol cols="12" md="6">
                    <VAutocomplete
                      v-model="dropList.governorate_id"
                      :items="
                        governorates.filter(
                          (e) => e.fk_country == dropList.country_id
                        )
                      "
                      item-title="name_ar"
                      item-value="id"
                      :label="$t('globals.governorate')"
                      persistent-hint
                      density="compact"
                      :rules="[$required]"
                      :disabled="role > 1"
                      prepend-inner-icon="mdi-city"
                      @update:modelValue="dropList.fk_directorate = null"
                      clearable
                    ></VAutocomplete>
                  </VCol>
                  <VCol cols="12" md="6">
                    <VAutocomplete
                      v-model="dropList.fk_directorate"
                      :items="
                        directorates.filter(
                          (e) => e.fk_governorate == dropList.governorate_id
                        )
                      "
                      item-title="name_ar"
                      item-value="id"
                      :label="$t('globals.directorate')"
                      persistent-hint
                      density="compact"
                      :rules="[$required]"
                      :disabled="role > 2"
                      prepend-inner-icon="mdi-map-outline"
                      clearable
                    ></VAutocomplete>
                  </VCol>
                  <VCol cols="12" md="6">
                    <v-text-field
                      v-model="dropList.address"
                      type="input"
                      density="compact"
                      clearable
                      prepend-inner-icon="mdi-map-marker"
                      :label="$t('summer.solitude')"
                    />
                  </VCol>

                  <VCol cols="12" md="6">
                    <VAutocomplete
                      v-model="dropList.is_garden"
                      :items="is_garden"
                      item-title="name"
                      item-value="key"
                      :label="$t('summer.school-has-garden')"
                      persistent-hint
                      density="compact"
                      prepend-inner-icon="mdi-soccer-field"
                      :rules="[$required]"
                      clearable
                    ></VAutocomplete>
                  </VCol>
                  <VCol cols="12" md="6">
                    <VAutocomplete
                      v-model="dropList.evaluation"
                      :items="evaluation_list"
                      item-title="evaluate_name"
                      item-value="id"
                      persistent-hint
                      density="compact"
                      prepend-inner-icon="mdi-list-status"
                      :label="$t('summer.overall-assessment')"
                      :placeholder="$t('summer.add-overall-assessment')"
                      :rules="[$required]"
                      clearable
                    ></VAutocomplete>
                  </VCol>
                  <v-col cols="6">
                    <v-text-field
                      v-model="dropList.place_name"
                      class="my-1"
                      density="compact"
                      :placeholder="$t('summer.place-name')"
                      :label="$t('summer.place-name')"
                      hide-details="auto"
                      prepend-inner-icon="mdi-home-edit-outline"
                      :rules="[$required, $max_length(100), $min_length(3)]"
                      clearable
                    ></v-text-field>
                  </v-col>
                </VRow>
              </VCol>
            </VRow>
            <v-col class="mt-7">
              <v-table
                density="compact"
                class="border border-grey-lighten-5 rounded"
              >
                <thead>
                  <tr>
                    <th class="pt-2">مرفقات المدرسة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ $t("globals.the_item") }}</td>
                    <td
                      v-for="evaluate in dropList?.evaluations"
                      :key="evaluate"
                    >
                      {{ evaluate.evaluate_name }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("globals.rating") }}</td>
                    <td
                      v-for="evaluate in dropList?.evaluations"
                      :key="evaluate"
                    >
                      <v-text-field
                        v-model="evaluate.evalate"
                        hide-details="auto"
                        class="my-1"
                        :placeholder="$t('globals.type-here')"
                        variant="underlined"
                        :rules="[$max_length(100), $min_length(3)]"
                        clearable
                      ></v-text-field>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-col>
          </v-window-item>
        </v-form>
        <v-form ref="form_4" class="mb-3">
          <v-window-item :value="3" class="mt-5" eager>
            <VRow>
              <VCol cols="12" md="8">
                <VRow>
                  <VCol cols="12" md="6">
                    <VAutocomplete
                      :items="supervisors"
                      v-model="dropList.fk_supervisor"
                      item-title="name"
                      item-value="id"
                      :label="$t('summer.supervising-authority-name')"
                      density="compact"
                      prepend-inner-icon="mdi-account-tie"
                      :rules="[rules.required]"
                      multiple
                      chips
                      closable-chips
                      clearable
                    >
                      <template v-slot:chip="{ props, item }">
                        <v-chip
                          class="mx-1"
                          style="font-size: 0.9rem"
                          v-bind="props"
                          :text="item.name"
                        >
                        </v-chip>
                      </template>
                    </VAutocomplete>
                  </VCol>
                  <VCol cols="12" md="6">
                    <VAutocomplete
                      :items="is_garden"
                      v-model="dropList.have_license"
                      item-title="name"
                      item-value="key"
                      :label="$t('summer.school-has-license')"
                      persistent-hint
                      density="compact"
                      prepend-inner-icon="mdi mdi-notebook-outline"
                      :rules="[$required]"
                      @update:model-value="
                        this.dropList.have_license == false
                          ? (this.dropList.license_number = null)
                          : ''
                      "
                      clearable
                    />
                  </VCol>
                  <VCol cols="12" md="6" v-if="dropList.have_license">
                    <VTextField
                      v-model="dropList.license_number"
                      prepend-inner-icon="mdi-numeric"
                      :label="$t('school.license_number')"
                      :placeholder="$t('school.placeholder_license_number')"
                      density="compact"
                      type="number"
                      :rules="[dropList.have_license?$required:'', $max_length(15)]"
                      clearable
                    >
                    </VTextField>
                  </VCol>
                </VRow>
              </VCol>
              <VCol cols="12" md="4" v-if="dropList.have_license">
                <custom-img
                  v-model="dropList.license_image"
                  :title="$t('school.license_image')"
                />
              </VCol>
            </VRow>
          </v-window-item>
        </v-form>
        <v-form ref="form_5" class="mb-3">
          <v-window-item :value="4" class="mt-5" eager>
            <v-col>
              <VRow v-for="(contact, index) in dropList.contacts" :key="index">
                <v-btn
                  class="my-1 me-1"
                  density="compact"
                  variant="tonal"
                  icon
                  @click="removeContact(index)"
                  :disabled="dropList.contacts?.length == 1"
                >
                  <v-icon color="error">mdi-trash-can-outline</v-icon>
                </v-btn>
                <VCol cols="4">
                  <VAutocomplete
                    :items="contact_type_list"
                    v-model="contact.type"
                    item-title="name"
                    item-value="id"
                    :label="$t('school.contact_type')"
                    persistent-hint
                    hide-details="false"
                    :prepend-inner-icon="
                      contact.type == 1
                        ? 'mdi mdi-phone'
                        : contact.type == 2
                        ? 'mdi mdi-email'
                        : contact.type == 3
                        ? 'mdi mdi-cellphone'
                        : contact.type == 4
                        ? 'mdi mdi-facebook'
                        : contact.type == 5
                        ? 'mdi mdi-whatsapp'
                        : contact.type == 6
                        ? 'mdi mdi-telegram'
                        : ''
                    "
                    @update:model-value="contact.summer_contact = null"
                  ></VAutocomplete>
                </VCol>
                <VCol cols="12" md="4">
                  <VTextField
                    v-model="contact.summer_contact"
                    :label="$t('school.contact')"
                    density="compact"
                    :hide-details=" index == dropList.contacts?.length - 1 ? 'auto' : null"
                    :prepend-inner-icon="
                        contact.type == 1 ? 'mdi mdi-phone'
                      : contact.type == 2 ? 'mdi mdi-email'
                      : contact.type == 3 ? 'mdi mdi-cellphone'
                      : contact.type == 4 ? 'mdi mdi-facebook'
                      : contact.type == 5 ? 'mdi mdi-whatsapp'
                      : contact.type == 6 ? 'mdi mdi-telegram'
                      : ''
                    "
                    clearable
                  >
                  </VTextField>
                </VCol>
              </VRow>
              <VRow class="pt-0 px-md-6 pb-0">
                <dir :title="$t('globals.add')">
                  <VBtn
                    variant="tonal"
                    density="compact"
                    class="ma-3"
                    icon
                    @click.prevent="addContact()"
                  >
                    <VIcon icon="mdi-plus-thick" color="success"></VIcon>
                  </VBtn>
                </dir>
              </VRow>
            </v-col>
          </v-window-item>
        </v-form>
      </v-window>
    </v-card-text>
    <VCardActions class="px-3">
      <VBtn
        v-if="
          !dropList.id &&
          CheckGetPermission('legal_school.add_schoolsummer', [
            'sys_admin',
            'gen_admin',
          ])
        "
        class="bg-primary"
        @click.prevent="saveData()"
        size="small"
        :loading="btn_loading"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="
          dropList.id && CheckGetPermission('legal_school.change_schoolsummer')
        "
        class="bg-primary"
        @click.prevent="updateData()"
        size="small"
        :loading="btn_loading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="ma-3" size="small" @click="resetForm()" v-if="role != 4 &&  !dropList.id">
        {{ $t("globals.cancel") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
      <VBtn class="ma-3" size="small" @click="resetForm()" v-if="dropList.id">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </v-card>
</template>
<script>
import CustomImg from "@/components/Globals/CustomImg.vue";
import DatetimePicker from "@/components/Globals/DatetimePicker.vue";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    id: {
      type: Number,
    },
  },

  components: {
    CustomImg,
    DatetimePicker,
  },

  data() {
    return {
      dropList: {
        contacts: [{}],
        evaluations: [],
        username: null,
        password: null,
        groups: null,
        is_funded: false,
        manager: {
          name_ar: null,
          gender: null,
          phone_number: null,
          country: null,
          governorate: null,
          fk_directorate: null,
        },
      },

      rules: {
        required: (value) =>
          (Array.isArray(value) && value.length > 0) ||
          this.$t("globals.required-field"),
      },
      step: 0,
      steps: 0,

      loading: false,
      btn_loading: false,

      is_garden: [
        { key: true, name: "نعم" },
        { key: false, name: "لا" },
      ],
      items: [
        { name: this.$t("school.main_data"), valid: true },
        { name: this.$t("summer.manager"), valid: true },
        { name: this.$t("school.location_data"), valid: true },
        { name: this.$t("summer.supervising-authority"), valid: true },
        { name: this.$t("school.contact_data"), valid: true },
      ],
      students_type_list: [],
      shift_times_list: [],
      countries: [],
      governorates: [],
      directorates: [],
      evaluation_list: [],
      administrative: [],
      supervisors: [],
      contact_type_list: [],
      validList: ["form_1", "form_2", "form_3", "form_4", "form_5"],
    };
  },
  async created() {
    this.loading = true;
    this.getStudentsTypeList();
    this.getShiftTimesList();
    this.getEvaluationList();
    this.getCountry();
    this.getGov();
    this.getDir();
    this.getGenders();
    this.getAdministrative();
    this.getSupervisor();
    this.getContactType();

    const id =
      this.role > 1
        ? localStorage.getItem("schoolsummer")
        : this.id
        ? this.id
        : undefined;

    if (id) await this.getSummerSchoolDataById(id);

    this.loading = false;
  },
  methods: {
    ...mapActions({
      getGenders: "summer/getGenders",
    }),
    addContact() {
      this.dropList.contacts.push({
        type: undefined,
        summer_contact: undefined,
      });
    },
    removeContact(index) {
      this.dropList.contacts.splice(index, 1);
    },
    async getSummerSchoolDataById(id) {
      await this.axios
        .get(`${this.base_url}api/summer/school/${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          const result = response.data;
          let data = {};

          if ("evaluations" in result) data.evaluations = result.evaluations;

          let list = result.contact_data.map((element) => ({
            id: element?.id,
            type: element.type,
            summer_contact: element.summer_contact,
          }));

          data.contacts = list;
          this.dropList = { ...data, ...result.summer_data };
        });
    },
    async getEvaluationList() {
      this.evaluation_list = (
        await this.axios.get(this.base_url + "api/summer/choices/evaluation", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
    },
    async getShiftTimesList() {
      this.shift_times_list = (
        await this.axios.get(this.base_url + "api/summer/choices/shift-time", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
    },
    async getStudentsTypeList() {
      this.students_type_list = (
        await this.axios.get(this.base_url + "api/summer/choices/student-type", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
      ).data;
    },
    async getCountry(country) {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "Country",
          fields: ["id", "name_ar"],
        })
        .then((response) => {
          this.countries = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getGov() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "Governorate",
          fields: ["id", "name_ar", "fk_country"],
        })
        .then((response) => {
          this.governorates = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDir() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "Directorate",
          fields: ["id", "name_ar", "fk_governorate"],
        })
        .then((response) => {
          this.directorates = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getContactType() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "ContactTypeChoice",
          choices: true,
        })
        .then((response) => {
          this.contact_type_list = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getSupervisor() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "Supervisor",
          fields: ["id", "name"],
        })
        .then((response) => {
          this.supervisors = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getAdministrative() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "AdministrativeChoice",
          choices: true,
        })
        .then((response) => {
          this.dropList.evaluations = [];
          this.dropList.evaluations = response.data.map(e=>{
            return {
              adminidtrative_item:e.id,
              evaluate_name:e.name,
              evalate:null

            }
          });
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },

    checkContactType(value, type) {
      if (value == undefined || value.length == 0)
        return this.$t("globals.required_field");
      let number = /^\d+$/;
      if (type == 1) {
        if (!number.test(value)) return this.$t("globals.must_be_numbers");
        if (value.length > 8) return `${this.$t("globals.max_numbers")} 8`;
        if (value.length < 6) return `${this.$t("globals.min_numbers")} 6`;
      }
      if (type == 2) {
        let email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email.test(value)) return this.$t("errors.email");
        if (value.length > 100) return this.$t("globals.max_characters");
      }
      if (type == 3) {
        if (!number.test(value)) return this.$t("globals.must_be_numbers");
        if (value.length > 14) return `${this.$t("globals.max_numbers")} 14`;
        if (value.length < 9) return `${this.$t("globals.min_numbers")} 9`;
      }
      if (type == 4) {
        if (value.length > 100) return this.$t("globals.max_characters");
      }
      if (type == 5) {
        if (!number.test(value)) return this.$t("globals.must_be_numbers");
        if (value.length > 14) return this.$t("globals.max_numbers");
        // if (value.length < 7) return this.$t("globals.min_numbers");
      }
      if (type == 6) {
        let telegram_regex = /^[a-zA-Z0-9_]{5,32}$/;
        if (!telegram_regex.test(value))
          return this.$t("globals.must_be_telegram");
      }

      return true;
    },
    resetForm() {
      this.dropList = {
        contacts: [{}],
        evaluations: [],
        username: null,
        password: null,
        groups: null,
        is_funded: false,
        manager: {
          name_ar: null,
          gender: null,
          phone_number: null,
          country: null,
          governorate: null,
          fk_directorate: null,
        },
      };

      this.items.map((e) => {
        e.valid = true;
      });
    },
    async saveData() {

      const valid = await this.validate();

      if (valid) {
        this.btn_loading = true;
        let form = new FormData();

        if (this.dropList.logo && typeof(this.dropList.logo) == "object")
          form.append("logo", this.dropList.logo[0]);

        if (this.dropList.license_image && typeof(this.dropList.license_image) == "object")
          form.append("license_image", this.dropList.license_image[0]);

        form.append("data", JSON.stringify(this.dropList));

        await this.axios
          .post(this.base_url + "api/summer/school", form, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            if (response.data == 1) {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            } else {
              this.$emit("successAlert", this.$t("globals.data_added"));

              this.resetForm();
            }
          })
          .catch((error) => {
            let error_message = null;
            try {
              if (error.response) {
                error_message = error.response.data;
              }
              if (
                error_message &&
                error_message ==
                  "{'non_field_errors': [ErrorDetail(string='الحقول name_ar, fk_directorate يجب أن تشكل مجموعة فريدة.', code='unique')]}"
              ) {
                this.step = 1;
                this.steps = 1;
                this.dropList.uniqe_manager = true;
              } else if (error?.response?.data?.includes("School admin")) {
                this.step = 1;
                this.steps = 1;
                this.dropList.uniqe_user = true;
              } else {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
              }
            } catch {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });
        this.btn_loading = false;
      }
    },
    async updateData() {
      const valid = await this.validate();
      if (valid) {
        this.btn_loading = true;
        let form = new FormData();
        if (this.dropList.logo && typeof(this.dropList.logo == "object"))
          form.append("logo", this.dropList.logo[0]);
        else
          form.append("logo", this.dropList.logo)

        if (
          this.dropList.license_image &&
          typeof(this.dropList.license_image == "object")
        )
          form.append("license_image", this.dropList.license_image[0]);
        
        else  form.append("license_image", this.dropList.license_image);

          
        
        form.append("data", JSON.stringify(this.dropList));

        await this.axios
          .put(this.base_url + "api/summer/school/" + this.dropList.id, form, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            if (response.data == -1) {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            } else {
              this.$emit("successAlert", this.$t("globals.data_updated"));
              if (this.role != 4)
                setTimeout(() => {
                  this.$router.push({ name: "summer-camps-list" });
                }, 1500);
            }
          })
          .catch((error) => {
            let error_message = null;
            try {
              error_message = error.response.data;
              if (
                error_message &&
                error_message ==
                  "{'non_field_errors': [ErrorDetail(string='الحقول name_ar, fk_directorate يجب أن تشكل مجموعة فريدة.', code='unique')]}"
              ) {
                this.step = 1;
                this.steps = 1;
                this.dropList.uniqe_manager = true;
              } else if (error?.response?.data?.includes("School admin")) {
                this.step = 1;
                this.steps = 1;
                this.dropList.uniqe_user = true;
              } else {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
              }
            } catch (error) {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
          });

        this.btn_loading = false;
      }
    },

    async validate() {
      for (let i = 0; i < this.validList.length; i++) {
        try {
          const v = this.validList[i];
          const { valid } = await this.$refs[v].validate();
          if (valid) this.items[i].valid = true;
          else {
            this.items[i].valid = false;
            this.step = i;
            this.steps = i;
            return;
          }
        } catch {
          this.items[i].valid = false;
          this.step = i;
          this.steps = i;
          return;
        }
      }

      return this.items.every((e) => e.valid === true);
    },
    async next(key) {
      let index = this.steps;

      const data = this.validList[index];
      const { valid } = await this.$refs[data].validate();
      if (!valid) {
        this.items[index].valid = false;
      } else this.items[index].valid = true;

      this.steps = key;
    },
  },
  computed: {
    ...mapState({
      genders: (state) => state.summer.genders,
      role: (state) => state.role,
    }),
  },
};
</script>