<template>
    
    <VDialog
        v-model="value"
        scrollable
        :overlay="false"
        max-width="350"
        elevation="12"
    >
        <VSheet 
            rounded="lg"
            width="100%"
            class="pa-4 text-center mx-auto"
        >
            <VIcon
                class="mb-3"
                :color="iconColor"
                :icon="`mdi-${icon}`"
                size="55"
            ></VIcon>

            <h2 class="text-h4 mb-6 text-primary-grey-darken-4" > {{ title }} </h2>
            <span class="mb-4 text-medium-emphasis text-subtitle text-primary-grey-darken-4"  > {{ message }} </span>
            
        </VSheet>
    </VDialog>
</template>

<script>

export default{
    props:{
        modelValue:{
            type:Boolean,
            default: false,
        },
        iconColor:String,
        icon:String,
        title:String,
        message:String,
        
    },
    
    computed:{
        value: {
            get(){
                return this.modelValue
            },
            set(value){
                this.$emit('update:modelValue',value)
            }
        },
    }
}


</script>