<template>
  <v-form ref="form">
    <v-card class="pa-2">
      <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="levelsdivisions"
            v-model="filter_data.fk_level_divison"
            prepend-inner-icon="mdi-shape-outline"
            item-title="name"
            item-value="id"
            :label="$t('division.divisionlevel')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            :rules="[$required]"
            @update:modelValue="this.exist = false"
          ></VAutocomplete>
        </VCol>
        <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="groupsHousing"
            v-model="filter_data.fk_residential_group"
            prepend-inner-icon="mdi-shape-outline"
            item-title="name"
            item-value="id"
            :label="$t('summer-sidebar.name_groups_housing')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            @update:modelValue="this.exist = false"
          ></VAutocomplete>
        </VCol>
        <!-- <VCol cols="3" class="mb-1">
          <VAutocomplete
            :items="rooms"
            v-model="filter_data.fk_centerandroom"
            prepend-inner-icon="mdi-office-building"
            item-title="name"
            item-value="id"
            :label="$t('school.room_name')"
            clearable
            hide-details="auto"
            persistent-hint
            density="compact"
            @update:modelValue="(this.exist=false)"
          ></VAutocomplete>
        </VCol> -->
        <v-col cols="1" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="primary"
            :loading="loading"
            @click="getItems(), getBeds()"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-table
      density="compact"
      class="rounded elevation-1 striped-table"
      fixed-header
      v-if="exist"
    >
      <thead>
        <tr>
          <th>{{ $t("globals.number") }}</th>
          <th>{{ $t("school.student_name") }}</th>
          <th cols="3">{{ $t("school.bad_name") }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(student, wIndex) in students_list" :key="wIndex">
          <td>{{ wIndex + 1 }}</td>
          <td>{{ student.student_name }}</td>
          <td cols="3">
            <VAutocomplete
              :items="availableBedsInRoom(student)"
              v-model="student.fk_centerandroombed"
              prepend-inner-icon="mdi-bed"
              item-title="full_name"
              item-value="id"
              :label="$t('school.bad_name')"
              clearable
              hide-details="auto"
              persistent-hint
              @click="previousBedAssignment(student)"
              @update:modelValue="updateBedAssignment"
              density="compact"
            ></VAutocomplete>
          </td>
        </tr>
      </tbody>
      <v-card-actions class="mx-2 pt-0">
        <v-btn @click="saveItem" :loading="saveLoading" class="bg-primary" size="small">
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-table>
  </v-form>
</template>
<script>
import useValidate from "@vuelidate/core";
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
import { required, helpers } from "@vuelidate/validators";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      v$: useValidate(),
      addDialog: false,
      protected_records: false,
      data_message: [],
      students_list: [],
      beds_list: [],
      levelsdivisions: [],
      rooms: [],
      groupsHousing: [],
      beds: [],
      previousBed: {},
      show: true,
      filter_data: {
        fk_level: undefined,
        students_list: [],
      },
      saveLoading: false,
      exist: undefined,
      isShow: undefined,
      dynamicHeaders: [],
      saveDataLoading: false,
      editDataLoading: false,
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    tableList() {
      if (this.students_list) {
        return JSON.parse(JSON.stringify(this.students_list));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getRooms();
      await this.getGroupsHousing();
      await this.getDivisionByLevel();
      await this.getBeds();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  validations() {
    return {
      filter_data: {
        fk_level_divison: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
      },
    };
  },
  methods: {
    availableBedsInRoom(currentStudent) {
      const assignedBeds = this.students_list
        .filter((student) => student.fk_centerandroombed && student !== currentStudent)
        .map((student) => student.fk_centerandroombed);
      let newBeds = this.beds.filter(
        (bed) => (!assignedBeds.includes(bed.id) && bed.is_connect_with_student == false) || bed.id == currentStudent.fk_centerandroombed  );
      return newBeds;
    },
    previousBedAssignment(student) {
      this.previousBed = { ...student };
    },
    updateBedAssignment() {
      if (this.previousBed) {
        this.beds.forEach((bed) => {
          if (bed.id === this.previousBed.fk_centerandroombed) {
            bed.is_connect_with_student = false;
          }
        });
      }
      this.previousBed = {};
    },
    async getDivisionByLevel() {
      let levelDivisions = await this.axios.get(
        this.base_url + `api/level-with-divisions/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.levelsdivisions = levelDivisions.data;
    },
    async getBeds(id) {
      //  if (this.filter_data.fk_centerandroom) {
      let room = await this.axios.get(
        this.base_url + `api/center-and-room-bed/list/`,
        // this.base_url + `api/center-and-room-bed/list/?fk_centerandroom=${this.filter_data.fk_centerandroom}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.beds = room.data;
      // this.students_list = this.students_list.filter(
      //   (student) => student.fk_centerandroombed
      // );
    },
    async getRooms() {
      let room = await this.axios.get(this.base_url + `api/center-and-room/list/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.rooms = room.data;
    },
    ...mapActions({
      getLevels: "summer/getLevels",
    }),
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    async getGroupsHousing() {
      let response = await this.axios.get(this.base_url + `api/residential-group/list/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.groupsHousing = response.data;
    },
    async getItems() {
      this.exist = undefined;
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.isShow = true;
        this.loading = true;
        await this.axios(`${this.base_url}api/connect-student-with-beds/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_level_divison: this.filter_data.fk_level_divison,
            fk_residential_group: this.filter_data.fk_residential_group,
            fk_centerandroom: this.filter_data.fk_centerandroom,
          },
        })
          .then((response) => {
            this.students_list = response.data;
            if (response.data.length == 0) {
              this.$emit("warningAlert", this.$t("globals.not-found"));
            } else {
              this.exist = true;
            }
            this.loading = false;
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },
    async saveItem() {
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        await this.axios
          .post(this.base_url + `api/connect-student-with-beds/`, this.students_list, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.getBeds();
            this.getItems();
          })
          .catch((error) => {
            if (error.response.data) {
              this.$emit("warningAlert", this.$t("summer.already-bed-student"));
            } else {
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
      }
    },
  },
};
</script>
