<template>
  <v-card class="justify-center"  v-if="school_weekly_activity?.length>0">
    <v-card-text>
      <v-row class="justify-center">
        <v-col cols="12">
          <v-form ref="table">
            <v-card class="mt-4" :disabled="saveloading" :loading="saveloading">
              <v-table density="compact" border>
                <thead>
                  <tr>
                    <th>
                      {{ $t("globals.day") }}
                    </th>
                    <th>
                      {{ $t("summer.activity.name") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(day) in school_weekly_activity"
                    :key="day"
                  >
                    <td class="w-25">
                      <span>{{ day.day_name }}</span>
                    </td>
                    <td class="w-75">
                      <v-autocomplete
                        v-model="day.activities"
                        density="compact"
                        item-title="name"
                        clearable
                        multiple
                        chips
                        closable-chips
                        item-value="id"
                        class="mb-2"
                        :no-data-text="$t('subject.not-found-activity')"
                        :items="all_activities"
                        hide-details
                        :placeholder="$t('summer.activity.select')"
                        variant="underlined"
                      >
                        <template v-slot:chip="{ props, item }">
                          <v-chip
                            class="mx-1"
                            v-bind="props"
                            :text="item.name"
                          >
                          </v-chip>
                        </template>
                      </v-autocomplete>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="px-3">
      <v-btn
        v-if="
          CheckGetPermission('legal_school.add_schoolweeklyactivity', [
            'sys_admin',
            'summer_admin',
          ])
        "
        @click="saveSchoolWeeklySchedule()"
        :loading="saveloading"
        class="bg-primary"
        size="small"
      >
        <span class="text-white">
          {{ $t("globals.save") }}
        </span>
        <v-icon icon="mdi-content-save" color="white" end></v-icon>
      </v-btn>
    
    </v-card-actions>
  </v-card>
  <!-- ############### Dialog Delete  ################### -->
  <!-- ############### end Dialog Delete  ################### -->
</template>

<script>
import useValidate from "@vuelidate/core";
import { mapGetters, mapState, mapActions } from "vuex";
export default {
  name: "WeeklyScheduleAdd",

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getActivities();
      await this.getSchoolWeekly();
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      saveloading: false,
      all_activities: [],
      school_weekly_activity:[],
    };
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({}),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },

  validations() {
    return {
      school_weekly_activity: {},
    };
  },
  methods: {
    ...mapActions({
    }),
    async saveSchoolWeeklySchedule(){
      this.saveloading=true
      await this.axios
        .post(`${this.base_url}api/summer/school-weekly-activity`,this.school_weekly_activity, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          this.saveloading=false
          this.$emit('successAlert',this.$t('alert.success.save'))
          // this.getSchoolWeekly() 
        }).catch(error=>{
          this.saveloading=false
          this.$emit('errorAlert',this.$t('globals.error_in_data'))
        })
    },
    async getActivities() {
      await this.axios
        .get(`${this.base_url}api/summer/activity/select/`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((res) => {
          this.all_activities = res.data;
        });
    },
    submitSchoolWeeklySchedule() {
      this.v$.$validate();
    },
    validations() {
      return {
        school_weekly_activity: {},
      };
    },
    async getSchoolWeekly() {
      this.is_update = false;
      await this.axios
        .get(`${this.base_url}api/summer/school-weekly-activity`, {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.school_weekly_activity=response.data
        });
    },
  },
};
</script>
