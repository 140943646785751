<template>
  <VForm
    ref="form"
    v-if="
      CheckGetPermission('legal_school.change_approach', [
        'sys_admin',
        'summer_admin',
        'user',
      ])
    "
  >
    <v-expansion-panels model-value="1">
      <v-expansion-panel class="mb-2" value="1">
        <v-expansion-panel-title class="px-4">
          <span>{{ $t("globals.select-criteria") }}</span>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <v-row class="py-2">
            <VCol cols="3" class="mb-1">
              <VAutocomplete
                :items="semesters"
                v-model="filter_data.fk_semester"
                prepend-inner-icon="mdi-calendar-month"
                item-title="name_ar"
                item-value="id"
                :label="$t('semester.name')"
                clearable
                hide-details="auto"
                persistent-hint
                density="compact"
                :rules="[$required]"
                @update:modelValue="exist=false"
              ></VAutocomplete>
            </VCol>
            <VCol cols="3">
              <VAutocomplete
                :items="levels"
                v-model="filter_data.fk_level"
                prepend-inner-icon="mdi-stairs-box"
                item-title="name_ar"
                item-value="id"
                :label="$t('summer.level.name_ar')"
                persistent-hint
                hide-details="auto"
                density="compact"
                clearable
                :rules="[$required]"
                @update:modelValue="
                  getSubjects(),
                    getDivisionByLevel(),exist=false,
                    ((this.filter_data.fk_division = null),
                    (this.filter_data.subject_id = null))
                "
              ></VAutocomplete>
            </VCol>
            <VCol cols="3" class="mb-1">
              <VAutocomplete
                :items="divisions"
                v-model="filter_data.fk_division"
                prepend-inner-icon="mdi-shape-outline"
                item-title="name"
                item-value="fk_division"
                :label="$t('division.name')"
                clearable
                hide-details="auto"
                persistent-hint
                density="compact"
                @update:modelValue="exist=false"
              ></VAutocomplete>
            </VCol>
            <VCol cols="2" class="mb-1">
              <VAutocomplete
                :items="subjects"
                v-model="filter_data.subject_id"
                prepend-inner-icon="mdi-shape-outline"
                item-title="subject_name"
                item-value="id"
                :label="$t('subject.name')"
                clearable
                hide-details="auto"
                persistent-hint
                density="compact"
                @update:modelValue="getSelectedSubject(),exist=false"
              ></VAutocomplete>
            </VCol>
            <v-col cols="1" class="mt-2">
              <v-btn
                density="comfortable"
                append-icon="mdi-presentation"
                class="w-100 text-white"
                color="primary"
                :loading="dataLoading"
                @click="getItems()"
              >
                <span>{{ $t("globals.show") }}</span>
              </v-btn>
            </v-col>
            <VCol cols="3">
              <VAutocomplete
                color="primary"
                v-model="filter_data.data_type"
                density="compact"
                item-title="name"
                item-value="id"
                hide-details="auto"
                prepend-inner-icon="mdi-account"
                :items="data_type_list"
                clearable
                :label="$t('summer.curriculum.reciever_type')"
                @update:modelValue="getItems()"
              ></VAutocomplete>
            </VCol>
          </v-row>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-card class="justify-center" :loading="dataLoading" v-if="exist">
      <v-card-text class="mt-1 pb-0">
        <v-row class="my-1">
          <VCol cols="6" class="mb-2">
            <v-text-field
              color="primary"
              v-if="filter_data.data_type == 1"
              v-model="txt_search"
              @input="getItems"
              density="compact"
              clearable
              hide-details="auto"
              prepend-inner-icon="mdi-magnify"
              :label="$t('globals.student-search')"
            />
          </VCol>
        </v-row>
  <v-card v-if="receiver_table && receiver_table.length > 0,filter_data.data_type == 1">
    <v-card-item>
      <v-data-table-virtual :headers="selectedSubjects" :items="receiver_table" :loading="loading">
        <template v-slot:headers="{ columns }">
          <tr>
            <th>#</th>
            <th>اسم الطالب</th>
            
            <th v-for="(column, i) in columns" :key="column.subject_name">
                            
              <v-sheet width="150" v-if="column.key == 'subject_name'">{{
                column.subject_name
              }}</v-sheet>

           <v-lazy v-else>
               <v-checkbox
                v-model="selected[i]"
                @change="checkAll(selected[i], i)"
                 :indeterminate="indeterminate(i)"
                :label="truncateText(column.subject_name)"
                style="width: 150px"
                hide-details
              />
           </v-lazy>
                 <v-tooltip v-if="column.subject_name.length > 20" activator="parent" location="top">{{
                column.subject_name
              }}</v-tooltip>
            </th>
          </tr>
        </template>
        <template v-slot:item="{ item ,index }">
          <tr>
            <td>{{ index +1}}</td>
            <td v-if="item.name">{{ item.name }}</td>
            <td v-for="(i, index) in item.subjects" :key="index">
               <v-lazy>
              <v-checkbox-btn
                v-model="i.is_recived_books"
                :key="index"
                @change="checked(i)"
                hide-details="true"
              />
              </v-lazy>
            </td>
          </tr>
        </template>
      </v-data-table-virtual>
    </v-card-item>
  </v-card>
        <v-card
          :disabled="dataLoading"
          v-if="receiver_table && receiver_table.length > 0,filter_data.data_type == 2"
        >
          <VTable
            density="compact"
            fixed-header
            :height="receiver_table?.length > 9 ? 420 : null"
          >
            <thead>
              <tr>
                <th>{{ $t("summer.curriculum.reciever_name") }}</th>
                <th>{{ $t("summer.curriculum.recieved?") }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="receiver in receiver_table" :key="receiver">
                <td>
                  <v-lazy>
                    {{ receiver.name }}
                  </v-lazy>
                </td>
                <td class="border">
                  <v-row dense>
                    <v-col v-for="subject in receiver.subjects" :key="subject.id" cols="2">
                      <VCheckbox
                        :label="subject.subject_name"
                        v-model="subject.is_recived_books"
                        hide-details
                        dense
                      >
                      </VCheckbox>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            </tbody>
          </VTable>

          <div class="d-flex mt-1" id="pagination-bar">
            <v-pagination
              v-model="pagination.page"
              :length="length"
              density="compact"
              show-first-last-page
            >
            </v-pagination>
            <div class="d-flex">
              <v-select
                v-model="perPage"
                class="pa-0"
                :items="itemsPerPage"
                item-value="value"
                item-title="text"
                density="compact"
                hide-details
                variant="text"
              >
              </v-select>
              <span class="mt-2 px-2"
                >{{ $t("globals.per-page") }} {{ $t("globals.from") }} [
                {{ totalItems }} ] {{ $t("globals.item") }}</span
              >
            </div>
          </div>
        </v-card>
      </v-card-text>
      <v-card-actions class="px-3">
        <v-btn
          v-if="
            !update &&
            CheckGetPermission('legal_school.change_approach', [
              'sys_admin',
              'summer_admin',
              'user',
            ])
          "
          @click="saveRecords"
          class="bg-primary"
          :loading="saveProgress"
          size="small"
        >
          <span class="text-white">
            {{ $t("globals.save") }}
          </span>
          <v-icon icon="mdi-content-save" color="white" end></v-icon>
        </v-btn>
        <v-btn @click="cleanMarks" class="mx-3 bg-ccc" size="small">
          <span>
            {{ $t("globals.clear") }}
          </span>
          <v-icon icon="mdi-broom" color="golden" end></v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </VForm>
</template>
<script>
import { mapState, mapActions } from "vuex";
import useValidate from "@vuelidate/core";

export default {
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getlevels();
      await this.getSemester();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  data() {
    return {
      v$: useValidate(),
      filter_data: {
        fk_level: undefined,
        data_type: 1,
      },
      saveProgress: false,
      dataLoading: false,
      exist: false,
      txt_search: null,
      perPage: 10,
      itemsPerPage: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: null, text: this.$t("globals.all") },
      ],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      page: 1,
      length: null,
      totalItems: null,
      receiver_table: [],
      selected: [],
      list_students: [],
      levels: [],
      semesters: [],
      divisions: [],
      subjects: [],
      selectedSubjects: [],
      data_type_list: [
        { id: 1, name: this.$t("summer.curriculum.student") },
        { id: 2, name: this.$t("summer.curriculum.teacher") },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  methods: {
    getSelectedSubject() {
      if(this.filter_data.subject_id){
        const selectHeader = this.subjects.find(header => header.id == this.filter_data.subject_id);
        this.selectedSubjects = selectHeader ? [selectHeader] : [];
      } else {
      this.selectedSubjects = this.subjects;
      }
    },    
    checkAll(value, index) {
      this.receiver_table.map((e) => {
        e.subjects[index].is_recived_books = value;
      });
    },
    checked(index) {
      if(this.filter_data.subject_id&&this.filter_data.data_type==1){
        // const is_recived_books = this.receiver_table.map((e) => e.subjects[index].is_recived_books);
        // this.selected[index] = is_recived_books.every((e) => e === true);
      }
    },   
    indeterminate(index) {
      if(!this.filter_data.subject_id&&this.filter_data.data_type==1){
        const is_recived_books = this.receiver_table.map((e) => e.subjects[index].is_recived_books);
      return is_recived_books.some((e) => e === true) && !is_recived_books.every((e) => e === true);
      }
    }, 
    truncateText(text) {
      return text.length > 20 ? text.slice(0, 20) + ".." : text;
    },    
    async getDivisionByLevel() {
      if (this.filter_data.fk_level) {
        let levelDivisions = await this.axios.get(
          this.base_url + `api/summer/get-level-division/${this.filter_data.fk_level}`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );
        this.divisions = levelDivisions.data.division;
      }
    },
    async getlevels() {
      let levels = await this.axios.get(this.base_url + `api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.levels = levels.data;
    },
    async getSemester() {
      let semester = await this.axios.get(this.base_url + `api/summer/semesters/list/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.semesters = semester.data;
      const s = this.semesters.filter((e) => e.current_semester == true);
      this.filter_data.fk_semester = s[0].id;
    },
    async getSubjects() {
      if (this.filter_data.fk_level) {
        let response = await this.axios.get(
          this.base_url + `api/level-subject/?fk_level=${this.filter_data.fk_level}`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        );
        this.subjects = response.data;
        this.subjects.forEach((e,index) => {
          this.checked(index)
        });
        this.selectedSubjects = this.subjects;
      }
    },
    async getItems() {
      this.exist = undefined;
      this.v$.$validate();
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.dataLoading = true;
        await this.axios(`${this.base_url}api/curriculum-delivery/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_level: this.filter_data.fk_level,
            fk_semester: this.filter_data.fk_semester,
            fk_division: this.filter_data.fk_division,
            levelSubjects: this.filter_data.subject_id,
            data_type: this.filter_data.data_type == 1 ? 1 : null,
            search_txt: this.txt_search,
            page: this.page,
          },
        })
          .then((response) => {
            this.exist = true;
            this.receiver_table = response.data.results;
            this.list_students = response.data.results;
            this.pagination = response.data.pagination;
            this.length = this.pagination?.num_pages;
            this.itemsPerPage[4]["value"] = this.pagination?.count;
            this.totalItems = this.pagination?.count;
            this.dataLoading = false;
          if(this.list_students.length === 0){
            this.$emit("warningAlert", this.$t("globals.not-found"));
          }
          })
          .catch((error) => {
            this.dataLoading = false;
          });
      }
    },
    async saveRecords() {
      const combinedSubjects = this.receiver_table.flatMap((student) => student.subjects);
      const { valid } = await this.$refs.form.validate();
      if (this.receiver_table.length > 0 && valid) {
        this.saveProgress = true;
        await this.axios
          .post(
            `${this.base_url}/api/curriculum-delivery/?data_type=${this.filter_data.data_type}`,
            combinedSubjects,
            {
              headers: { Authorization: "Bearer " + localStorage.getItem("token") },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_saved"));
            this.getItems();
            this.saveProgress = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveProgress = false;
          });
      } else {
        this.$emit("errorAlert", this.$t("globals.error_in_data"));
      }
    },
    cleanMarks() {
      this.getItems();
    },
  },
  watch: {
    perPage() {
      this.page = 1;
      this.getItems();
    },
    page() {
      this.getItems(this.page);
    },
  },
};
</script>
<style scoped>
#pagination-bar * {
  font-size: 0.95rem;
}
.table-container{
  max-height: 100%;
  overflow-y: auto;
}
.table-container .v-input__control{
  grid-area:auto
}
</style>
