<template>
  <!-- added by samer -->
  <!-- شاشة اضافة طالب -->
  <!-- <pre dir="ltr">{{dropList}}</pre> -->
  <div
    v-if="
      CheckGetPermission('legal_school.change_studentsummer') ||
      CheckGetPermission('legal_school.add_studentsummer')
    "
  >
    <v-card rounded="lg" elevation="3">
      <v-tabs v-model="step" v-bind="steps" stacked>
        <v-tab
          v-for="(tab, key) in tabs"
          :key="key"
          :value="key"
          density
          slider-color="black"
          rounded="lg"
          @click="next(key)"
        >
          <v-icon
            v-if="!tab.valid"
            icon="mdi-alert-circle"
            color="red"
            class="mb-2"
          />
          <v-avatar
            v-else
            :color="tab.valid ? 'primary' : 'red'"
            size="18"
            class="mb-2"
          >
            <h5>{{ key + 1 }}</h5>
          </v-avatar>
          {{ tab.name }}
        </v-tab>
      </v-tabs>
    </v-card>
   
   
    <v-card
      rounded
      class="mt-3"
      :disabled="loading"
      :loading="loading"
      
    >
      <v-card-text>
        <v-window v-model="steps">
          <v-window-item :value="0" class="mt-5" eager>
            <v-form ref="form_1" class="mb-3">
            <v-col>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="9" lg="9">
                   <v-card :title="$t('globals.personal-information')">
                  <v-card-text class="mt-5 mb-5">
                       <v-row>
                          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                            <v-text-field
                              v-model="dropList.name"
                              prepend-inner-icon="mdi-abjad-arabic"
                              :label="$t('user.fname')"
                              density="compact"
                              autofocus
                              clearable
                              :rules="[
                                $required,
                                $max_length(100),
                                $ar_letters_only,
                              ]"
                                :error-messages="dropList.uniqe_name ? $t('errors.unique') : ''"
                                @update:modelValue="dropList.uniqe_name = false"
                            />
                          </v-col>
                          <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                            <v-text-field
                              v-model="dropList.last_name"
                              prepend-inner-icon="mdi-abjad-arabic"
                              :label="$t('user.lname')"
                              density="compact"
                              :rules="[$max_length(100), $ar_letters_only]"
                              clearable
                            />
                          </v-col>
                        </v-row>
                        <v-row>                          
                          <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                            <v-autocomplete
                              v-model="dropList.gender"
                              :label="$t('school.student_gender')"
                              :items="genders"
                              item-title="name"
                              item-value="id"
                              prepend-inner-icon="mdi-gender-male-female"
                              clearable
                              :rules="[$required]"
                            />
                          </v-col>
                          <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                              <v-text-field
                              v-model="dropList.habby"
                              prepend-inner-icon="mdi-palette"
                              :label="$t('globals.habby')"
                              density="compact"                                                      
                              :rules="[$max_length(100)]"
                              clearable
                            />                           
                          </v-col>  
                          <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                            <v-text-field
                              v-model="dropList.id_card"
                              prepend-inner-icon="mdi-numeric"
                              :label="$t('school.card_id')"
                              density="compact"                            
                              clearable
                              :rules="[$max_length(11),$positive]"
                              type="number"
                            />
                          </v-col>
                        </v-row>
                     
                

                  </v-card-text>
                   </v-card>
                </v-col>
              
                <v-col cols="12" xs="12" sm="12" md="4" lg="3" >                   
                    <v-row>
                      <custom-img   v-model="dropList.student_image" :title="$t('school.student_image')"  width="150px" height="180px"/>
                    </v-row>
                </v-col>

                </v-row>

                 <v-card :title="$t('globals.place_of_birth_data')" class="mt-7">
                  <v-card-text class="mt-5 mb-5">
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" md="6" lg="4">
                          <datetime-picker
                            v-model="dropList.birthdate"
                            :calendar="'gregorg'"                           
                            :label="$t('school.student_birthdate')"
                            :rules="[$required]"
                            :maxDate="'2015-01-31'"
                                                                   
                          />
                        </v-col>
                        <v-col cols="12" md="6" lg="4">
                          <datetime-picker
                            v-model="dropList.birthdate_h"
                            :label="$t('summer.calendar.hijri-date')"
                            calendar="hijri"                           
                            :maxDate="'1446-01-01'"
                          />
                          
                        </v-col>
                        <v-col cols="12" md="6" lg="4">
                          <v-autocomplete
                            v-model="dropList.fk_governorate"
                            :items="
                              governorates.filter((e) => e.fk_country == 1)
                            "
                            item-title="name_ar"
                            item-value="id"
                            :label="$t('globals.governorate')"
                            persistent-hint
                            density="compact"
                            :rules="[$required]"
                            prepend-inner-icon="mdi-city"
                            @update:modelValue="dropList.fk_directorate = null"
                            clearable
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" md="6" lg="4">
                          <v-autocomplete
                            v-model="dropList.fk_directorate"
                            :items="
                              directorates.filter(
                                (e) =>
                                  e.fk_governorate == dropList.fk_governorate
                              )
                            "
                            item-title="name_ar"
                            item-value="id"
                            :label="$t('globals.directorate')"
                            persistent-hint
                            density="compact"
                            :rules="[$required]"
                            prepend-inner-icon="mdi-map-outline"
                            clearable
                          />
                        </v-col>
                        <v-col cols="12" md="4" lg="4">
                          <v-text-field
                            v-model="dropList.birth_place"
                            prepend-inner-icon="mdi-map-marker"
                            :label="$t('school.student_birth_place')"
                            density="compact"
                            :rules="[$required, $max_length(100),$min_length(2)]"
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" lg="4">
                          <v-text-field
                            v-model="dropList.address"
                            prepend-inner-icon="mdi-map-marker"
                            :label="$t('globals.address')"
                            density="compact"
                            clearable
                            :rules="[$max_length(100),$min_length(2)]"
                          />
                        </v-col>
                  
                      </v-row>
                    </v-col>
                   
                  </v-card-text>
                </v-card>

                <v-card :title="$t('globals.place_of_residence_data')" class="mt-2">
                <v-card-text class="mt-5 mb-5">
                    <v-col>
                      <v-row>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="4">
                          <v-text-field
                            v-model="dropList.phone_number"
                            :label="$t('globals.phone_number')"
                            density="compact"
                            :rules="[$min_length(9), $max_length(14),$positive]"
                            clearable
                            type="number" 
                            prepend-inner-icon="mdi-phone"                          
                          >
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" xs="12" sm="12" md="6" lg="4">
                          <v-text-field
                            v-model="dropList.home_number"
                            :label="$t('globals.home_number')"
                            type="number" 
                            density="compact"
                            :rules="[$min_length(6), $max_length(14),$positive]"
                            clearable
                            prepend-inner-icon="mdi-phone-classic"
                          >
                          </v-text-field>
                        </v-col>

                        <v-col cols="12" sm="12" md="6" lg="4">
                          <v-autocomplete
                            v-model="dropList.fk_governorate_housing"
                            :items="
                              governorates.filter((e) => e.fk_country === 1)
                            "
                            item-title="name_ar"
                            item-value="id"
                            :label="$t('globals.governorate')"
                            persistent-hint
                            density="compact"
                            prepend-inner-icon="mdi-city"
                            @update:modelValue="
                              dropList.directorate_housing = undefined
                            "
                            :rules="[$required]"
                            clearable
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" sm="12" md="6" lg="4">
                          <v-autocomplete
                            v-model="dropList.directorate_housing"
                            :items="
                              directorates.filter(
                                (e) =>
                                  e.fk_governorate ==
                                  dropList.fk_governorate_housing
                              )
                            "
                            item-title="name_ar"
                            item-value="id"
                            :label="$t('globals.directorate')"
                            persistent-hint
                            density="compact"
                            prepend-inner-icon="mdi-map-outline"
                            :rules="[$required]"
                            clearable
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="4">
                          <v-text-field
                            v-model="dropList.neighbourhood_housing"
                            prepend-inner-icon="mdi-map-marker"
                            :label="$t('globals.neirghbourhood_street')"
                            density="compact"
                            :rules="[$max_length(100),$min_length(2)]"
                          />
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="4">
                          <v-text-field
                            v-model="dropList.village_housing"
                            prepend-inner-icon="mdi-map-marker"
                            :label="$t('globals.neirghbourhood_collage')"
                            density="compact"
                            :rules="[$max_length(100),$min_length(2)]"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                </v-card-text>
                </v-card>

                <v-card :title="$t('globals.family_and_marital_status_data')" class="mt-7">
                      <v-card-text class="mt-5 mb-5">
                          <v-row>
                          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                              <v-text-field
                              v-model="dropList.class.num_male_family"
                              prepend-inner-icon="mdi-palette"
                              :label="$t('globals.num_male_family')"
                              density="compact"                                                      
                              :rules="[$max_value(100),$positive]"
                              clearable
                              type="number"
                            />                           
                          </v-col>             
                          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                              <v-text-field
                              v-model="dropList.class.num_fmale_family"
                              prepend-inner-icon="mdi-palette"
                              :label="$t('globals.num_fmale_family')"
                              density="compact"                                                      
                              :rules="[$max_value(100),$positive]"
                              clearable
                               type="number"
                            />                           
                          </v-col>             
                          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                              <v-text-field
                              v-model="dropList.class.student_tripp_between_brother"
                              prepend-inner-icon="mdi-palette"
                              :label="$t('globals.student_tripp_between_brother')"
                              density="compact"                                                      
                              :rules="[$max_value(100),$positive]"
                              clearable
                               type="number"
                            />                           
                          </v-col>      
                          <v-col cols="6" md="3" lg="3" >
                            <v-autocomplete
                              :items="LiveWithChoice"
                              v-model="dropList.class.student_live_with"
                              item-title="name"
                              item-value="id"
                              :label="$t('globals.student_live_with')"
                              persistent-hint
                              hide-details="auto"
                              prepend-inner-icon="mdi-shape-outline"               
                              density="compact"
                              clearable
                            
                            />
                          </v-col>       
                        </v-row>

                        <v-row>
                          <v-col cols="6" md="3" lg="3">
                          <v-autocomplete
                            :items="TypeOfOwnershipChoice"
                            v-model="dropList.class.type_of_ownership"
                            item-title="name"
                            item-value="id"
                            :label="$t('globals.type_of_ownership')"
                            persistent-hint
                            hide-details="auto"
                            prepend-inner-icon="mdi-shape-outline"                        
                            density="compact"
                            clearable
                          />
                          </v-col>                                                           
                          <v-col cols="6" md="3" lg="3">
                            <v-autocomplete
                              :items="TypeOfHousingChoice"
                              v-model="dropList.class.type_of_housing"
                              item-title="name"
                              item-value="id"
                              :label="$t('globals.type_of_housing')"
                              persistent-hint
                              hide-details="auto"
                              prepend-inner-icon="mdi-shape-outline"                         
                              density="compact"
                              clearable
                            />
                          </v-col>   
                          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                              <v-text-field
                              v-model="dropList.class.minute_distance"
                              prepend-inner-icon="mdi-palette"
                              :label="$t('globals.minute_distance')"
                              density="compact"                                                      
                              :rules="[$max_length(9),$positive]"
                              clearable
                               type="number"
                            />                           
                          </v-col> 
                          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
                              <v-text-field
                              v-model="dropList.class.meter_distance"
                              prepend-inner-icon="mdi-palette"
                              :label="$t('globals.meter_distance')"
                              density="compact"                                                      
                              :rules="[$max_length(9),$positive]"
                              clearable
                               type="number"
                            />                           
                          </v-col> 
                        </v-row>

                      </v-card-text>

                </v-card>

               

            </v-col>
            </v-form>
          </v-window-item>
          <v-window-item :value="1" class="mt-5" eager>
            <v-form ref="form_2" class="mb-3">
              <v-col>
                <v-row>                  
                <v-col cols="12" xs="12" sm="12" md="9" lg="9"  style="z-index: 100;">       
                    <!-- البيانات الدراسية -->
                    <v-card :title="$t('globals.acdemic_data')" class="mb-3">
                      <v-card-text class="mb-3">
                        <v-row class="mt-1">
                          <v-col cols="6" md="4" lg="4">
                            <v-autocomplete
                              clearable
                              v-model="dropList.class.fk_level"
                              :items="levels"
                              item-title="name_ar"
                              item-value="id"
                              :label="$t('summer.level.level')"
                              persistent-hint
                              prepend-inner-icon="mdi-stairs-box"
                              density="compact"
                              hide-details="auto"
                              :rules="[$required]"
                              @update:model-value="
                                getDivisionByLevel(),
                                  (dropList.class.fk_division = undefined),
                                  getStudentDocument(),
                                  dropList.documents=[]
                              "
                            />
                          </v-col>       
                         
                          <v-col cols="6" md="4" lg="4">
                            <v-autocomplete
                              :items="divisions"
                              v-model="dropList.class.fk_division"
                              item-title="fk_division__name"
                              item-value="fk_division__id"
                              :label="$t('division.select')"
                              persistent-hint
                              hide-details="auto"
                              prepend-inner-icon="mdi-shape-outline"
                              :rules="[$required]"
                              density="compact"
                              clearable
                            />
                          </v-col>    
                            <v-col cols="8" sm="12" md="4">
                          <VTextField
                            v-model="dropList.school_name"
                            prepend-inner-icon="mdi-school"
                            :label="$t('globals.school-name')"
                            density="compact"
                            :rules="[$max_length(150),$min_length(2)]"
                            clearable
                          />                      
                      </v-col>
                      
                   
                    </v-row>
                    <v-row>                                                                       
                      <v-col cols="12" xs="12" sm="4" md="4">
                        <v-autocomplete
                          v-model="dropList.pervious_class"
                          :label="$t('globals.pervious_class')"
                          :items="calssName"
                          item-title="name"
                          item-value="id"
                          prepend-inner-icon="mdi-arrow-left"
                          clearable        
                          :rules="[$required]"
                        />
                      </v-col> 
                      <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                            <v-text-field
                              v-model="dropList.sitting_number"
                              prepend-inner-icon="mdi-numeric"
                              :label="$t('exam.seat-number')"
                              density="compact"
                              clearable                              
                              type="number"
                              :rules="[$required,$max_length(9),$positive]"
                            />
                        
                   
                          </v-col>   
                      <v-col cols="8" sm="12" md="4">
                        <v-text-field
                          v-model="dropList.outcome"
                          prepend-inner-icon="mdi-percent"
                          :label="$t('report.percentage')"
                          density="compact"                
                          clearable
                          type="number"
                          :rules="[$required,$max_value(100),$positive]"                          
                        />                      
                    
                     </v-col> 
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" lg="4">
                      <datetime-picker
                      v-model="dropList.date_of_acquisition"
                        :calendar="'gregorg'"
                        placeholder="yyy-mm-dd"
                        :label="$t('globals.date_of_acquisition')"
                        :rules=[$required]
                        
                      />
                    </v-col>  
                        <v-col cols="6" sm="6" md="4">
                          <v-autocomplete
                            v-model="dropList.student_type"
                            item-value="id"
                            item-title="name"
                            prepend-inner-icon="mdi mdi-account"
                            :items="student_type"
                            :label="$t('school.students_type')"
                            persistent-hint
                            density="compact"
                            clearable
                          />
                      </v-col>          
                      <v-col cols="6" md="4">
                        <v-autocomplete
                          v-model="dropList.accommodation_type"
                          item-value="id"
                          item-title="name"
                          prepend-inner-icon="mdi-home"
                          :items="accommodation_types"
                          :label="$t('summer.accommodation-type')"
                          persistent-hint
                          density="compact"
                          clearable
                          :rules="[$required]"
                        />
                      </v-col>  
                    </v-row>

                     
                   

                      </v-card-text>
                    </v-card> 
                    </v-col>
                </v-row>   

                    <!-- المشاركات -->
                    <v-card :title="$t('document.view')" class="mt-3" v-if="dropList.documents.length > 0">
                      <v-card-text class="mt-5 mb-5">
                        <v-list select-strategy="classic" v-for="(doc,index) in dropList.documents" :key="index">
                          <v-list-item  :value="doc.status">                            
                            <v-checkbox-btn v-model="doc.status" :label="doc.name"/>                                                                                                              
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </v-card>

                    <!-- المشاركات السابقة -->
                    <v-card :title="$t('globals.previous_posts')" class="mt-3 mb-5">
                      <v-card-text class="mt-5 mb-5">
                        <v-row>                     
                        
                          <v-col cols="8" sm="12" md="3">
                            <VTextField
                              v-model="dropList.summer_course"
                              prepend-inner-icon="mdi-school"
                              :label="$t('globals.summer_course')"
                              density="compact"                
                              clearable
                              :rules="[$max_length(100)]"                            
                            />                      
                          </v-col>                    
                                          
                          <v-col cols="8" sm="12" md="3">
                            <VTextField
                              v-model="dropList.cultural_course"
                              prepend-inner-icon="mdi-book-open-page-variant"
                              :label="$t('globals.cultural_course')"
                              density="compact"                
                              clearable
                              :rules="[$max_length(100)]"                            
                            />                      
                          </v-col>  
                          <v-col cols="8" sm="12" md="3">
                            <VTextField
                              v-model="dropList.scientific_abandonment"
                              prepend-inner-icon="mdi-flask"
                              :label="$t('globals.scientific_abandonment')"
                              density="compact"                
                              clearable
                              :rules="[$max_length(100)]"                            
                            />                      
                        </v-col>  
                        <v-col cols="8" sm="12" md="3">
                          <VTextField
                            v-model="dropList.other_item"
                            prepend-inner-icon="mdi-dots-horizontal"
                            :label="$t('globals.other')"
                            density="compact"                
                            clearable
                            :rules="[$max_length(100)]"                            
                          />                      
                        </v-col>  
                        </v-row>

                      </v-card-text>
                    </v-card> 

                    <!-- بيانات جهة الترشيح -->
                    <v-card :title="$t('globals.official_recommendation_data')" class="mt-3 mb-5">
                    <v-card-text class="mt-5 mb-5">
                      <v-row>                     
                        <v-col cols="8" sm="12" md="3">
                          <VTextField
                            v-model="dropList.official_recommendation"
                            prepend-inner-icon="mdi-account-group"
                            :label="$t('globals.official_recommendation')"
                            density="compact"                
                            clearable
                            :rules="[$max_length(100)]"                            
                          />                      
                        </v-col>  
                        <v-col cols="8" sm="12" md="3">
                          <VTextField
                            v-model="dropList.recommendator"
                            prepend-inner-icon="mdi-filter"
                            :label="$t('globals.recommendator')"
                            density="compact"                
                            clearable
                            :rules="[$max_length(100)]"                            
                          />                      
                        </v-col>  
                        <v-col cols="8" sm="12" md="3">
                          <VTextField
                            v-model="dropList.recommendator_work"
                            prepend-inner-icon="mdi-briefcase"
                            :label="$t('globals.recommendator_work')"
                            density="compact"                
                            clearable
                            :rules="[$max_length(100)]"                            
                          />                      
                        </v-col>  
                        <v-col cols="8" sm="12" md="3">
                          <VTextField
                            v-model="dropList.recommendator_phone_number"
                            prepend-inner-icon="mdi-phone"
                            :label="$t('globals.phone_number')"
                            density="compact"                
                            clearable
                             :rules="[ $max_length(9),$positive]"
                            type="number"
                          />                      
                        </v-col>  
                    </v-row>
                    </v-card-text>                    
                    </v-card>
                <v-card :title="$t('globals.diseases')" class="mt-3">
                  <v-card-text class="mt-5 mb-5">
                    <v-row>
                      <v-col >
                        <v-table v-if="dropList.disease_student">
                          <thead>
                            <tr>
                              <td>{{$t('globals.disease')}}</td>
                              <td>{{$t('globals.rating')}}</td>
                  
                            </tr>
                          </thead>
                              <tbody>
                                <tr v-for="(item,index) in dropList.disease_student" :key="index"> 
                                  <td  style="width:300px;">
                                    <v-autocomplete
                                      v-model="item.disease"
                                      :items="TypeOfDiseases"
                                      item-title="name"
                                      item-value="id"
                                      persistent-hint
                                      density="compact"
                                      prepend-inner-icon="mdi-heart-pulse"
                                      clearable        
                                      :rules="[$required]"                            
                                    />
                                  </td>
                                  
                                  <td  style="width:300px;">
                                    <v-text-field
                                      v-model="item.evalate"
                                      prepend-inner-icon="mdi-percent"
                                      density="compact"
                                      clearable
                                      :rules="[$max_value(100)]"
                              
                                    />
                                    </td>
                                  <td>
                      
                                    
                          <v-btn
                            v-if="dropList.documents.length > 0"
                            icon="mdi-minus"
                            variant="tonal"
                            density="compact"
                            @click="removeDoc(index)"
                            />                                    
                                  </td>
                                  
                                </tr>                       
                              </tbody>
                            </v-table> 
                      </v-col>
                
                       <v-col class="ms-5">
                         
                      
                          <v-row class="mt-5 h-100">
                              <v-btn
                            icon="mdi-plus"
                            variant="tonal"
                            density="compact"
                            @click="addDoc"
                            />
                          </v-row>
                          
                        </v-col>
                        </v-row>
                    
              
                  </v-card-text>
                </v-card>
                
              </v-col>
            </v-form>
          </v-window-item>
          <v-window-item :value="2" class="mt-5" eager>
            <v-form ref="form_3" class="mb-3">
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="9" lg="9">
                  <v-row class="pa-2">
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                      <v-text-field
                        v-model="dropList.parent_relation"
                        prepend-inner-icon="mdi-human-male-boy"
                        :label="$t('school.parent_relation')"
                        density="compact"
                        clearable
                        :rules="[$max_length(100),$min_length(2)]"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                      <v-row class="pa-0 ma-0">
                        <v-autocomplete
                          v-model="dropList.fk_parent"
                          prepend-inner-icon="mdi-human-male-boy"
                          item-value="id"
                          item-title="name"
                          :items="parent_list"
                          :label="$t('school.select_parent_name')"
                          density="compact"
                          :disabled="!selected"
                          @update:modelValue="showSelectedParent(dropList.fk_parent)"
                        />

                        <VBtn
                          v-if="selected"
                          variant="tonal"
                          size="small"
                          icon
                          class="ms-1"
                          @click.prevent="newParent()"
                        >
                          <VIcon icon="mdi-plus-thick" color="success"></VIcon>
                        </VBtn>
                        <VBtn
                          v-if="!selected"
                          variant="tonal"
                          size="small"
                          icon
                          class="ms-1"
                          @click.prevent="oldParent()"
                        >
                          <VIcon icon="mdi-minus-thick" color="error"></VIcon>
                        </VBtn>
                      </v-row>
                    </v-col>
                  </v-row>
                <v-card :disabled="selected" class="pa-5" elevation="0">
                    <v-row>
                    <v-col cols="12" xs="12" sm="12" md="8" lg="8">
                      <v-text-field
                        v-model="dropList.parent.name"
                        prepend-inner-icon="mdi-abjad-arabic"
                        :label="$t('school.student_parent_name_ar')"
                        density="compact"
                        clearable
                        :rules="[$max_length(100),$min_length(2)]"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                      <v-autocomplete
                        v-model="dropList.parent.identity_type"
                        prepend-inner-icon="mdi-card-account-details"
                        item-value="id"
                        item-title="name"
                        :items="identify_type"
                        :label="$t('school.parent_id')"
                        density="compact"
                        clearable
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                      <VTextField
                        v-model="dropList.parent.identity_number"
                        prepend-inner-icon="mdi-numeric"
                        :label="$t('school.parent_id_no')"
                        density="compact"
                        clearable
                        type="number"
                        :rules="[$max_length(11),$positive]"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                      <v-text-field
                        v-model="dropList.parent.job"
                        prepend-inner-icon="mdi-account-hard-hat"
                        :label="$t('school.parent_job')"
                        density="compact"
                        clearable
                        :rules="[$max_length(100),$min_length(2)]"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                      <v-text-field
                        v-model="dropList.parent.phone_number"
                        prepend-inner-icon="mdi-cellphone-text"
                        :label="$t('school.parent_phone')"
                        density="compact"
                        clearable
                        :rules="[$min_length(9), $max_length(14),$positive]"
                        type="number" 
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="12" md="4" lg="4">
                      <v-text-field
                        v-model="dropList.parent.home_number"
                        prepend-inner-icon="mdi-phone-classic"
                        :label="$t('school.parent_landline')"
                        density="compact"
                        clearable
                        type="number"
                        :rules="[$min_length(6), $max_length(14),$positive]"
                      />
                    </v-col>
                    <v-col cols="12" xs="12" sm="6" md="8" lg="8">
                      <VTextField
                        v-model="dropList.parent.address"
                        prepend-inner-icon="mdi-map-marker"
                        :label="$t('school.parent_address')"
                        density="compact"
                        clearable
                        :rules="[$max_length(150),$min_length(2)]"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="dropList.parent.note"
                        prepend-inner-icon="mdi-note"
                        clearable
                        :label="$t('school.notes')"
                        counter="250"
                        no-resize
                        density="compact"
                        rows="2"
                        :rules="[$max_length(250)]"
                      />
                    </v-col>
                  </v-row>
                </v-card>
                </v-col>

                <v-col cols="12" xs="12" sm="12" md="3" lg="3">            
                  <v-card :disabled="selected" elevation="0">
                  <custom-img v-model="dropList.parent.identity_image" :title="$t('school.parent_id_image')"/>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-window-item>
        </v-window>
      </v-card-text>           
   
         
 <VCardActions class="px-3">
        <VBtn
          v-if="
            !id && CheckGetPermission('legal_school.add_studentsummer')
          "
          class="bg-primary"
          @click.prevent="saveData()"
          size="small"
          :loading="btn_loading"
        >
          <span class="text-white">
            {{ $t("globals.add") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn
          v-if="
            id &&
            CheckGetPermission('legal_school.change_studentsummer')
          "
          class="bg-primary"
          @click.prevent="updateData()"
          size="small"
          :loading="btn_loading"
        >
          <span class="text-white">
            {{ $t("globals.edit") }}
          </span>
          <VIcon icon="mdi-content-save" color="white" end></VIcon>
        </VBtn>
        <VBtn class="ma-3" size="small" @click="id ? $router.push({ name: 'summer-student-list' }) : resetForm()">
          {{ !id? $t("globals.clear"): $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>     
    </v-card>
  </div>
                  <!-- الملفات -->
                    <!-- <v-card :title="$t('document.view')" class="mt-3">
                    <v-card-text class="mt-5 mb-5">
                      <v-row>
                        <v-col cols="12" md="11" lg="11">
                          <v-table v-if="dropList.documents">
                            <thead>
                              <tr>
                                <td>{{$t('document.the_document')}}</td>
                                <td>{{$t('school.student_birthdate')}}</td>
                                <td>{{$t('globals.address')}}</td>
                                <td>{{$t('libraries.image')}}</td>
                              </tr>
                            </thead>
                                <tbody>
                                  <tr v-for="(doc,index) in dropList.documents" :key="index" > 
                                    <td  style="width:300px;">
                                      <v-autocomplete
                                        v-model="doc.fk_level_document"
                                        :items="documents"
                                        item-title="name"
                                        item-value="id"
                                        persistent-hint
                                        density="compact"
                                        prepend-inner-icon="mdi-file-document-multiple"
                                        clearable        
                                        :rules="[$required]"                            
                                      />
                                    </td>
                                    <td    style="width:300px;">
                                      <datetime-picker
                                        v-model="doc.release_date"
                                        :calendar="'gregorg'"
                                        placeholder="yyy-mm-dd"                                                              
                                      />
                                      </td>
                                    <td  style="width:300px;">
                                      <v-text-field
                                        v-model="doc.issuer"
                                        prepend-inner-icon="mdi-map-marker"
                                        density="compact"
                                        clearable
                                        :rules="[$max_length(250)]"
                                  
                                      />
                                      </td>
                                    <td  style="width:300px;"><custom-img v-model="doc.image" height="100" width="100" class="my-3"/></td>
                                    <td>
                        
                                      
                            <v-btn
                              v-if="dropList.documents.length > 0"
                              icon="mdi-minus"
                              variant="tonal"
                              density="compact"
                              @click="removeDoc(index)"
                              />                                    
                                    </td>
                                    
                                  </tr>                       
                                </tbody>
                              </v-table> 
                        </v-col>
                  
                        <v-col class="ms-5">
                          
                        
                            <v-row class="mt-5 h-100">
                                <v-btn
                              icon="mdi-plus"
                              variant="tonal"
                              density="compact"
                              @click="addDoc"
                              />
                            </v-row>
                            
                          </v-col>
                          </v-row>
                      
                
                    </v-card-text>
                    </v-card>

                 
                  -->
</template>

<script>
import CustomImg from "@/components/Globals/CustomImg.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: {
    CustomImg,
  },
  props: {
    id: {
      type: Number,
    },
  },
  async created() {
    this.resetForm();
    this.loading = true;
    await this.getGov();
    await this.getDir();
    await this.getGenders();
    await this.getLevels();
   
    await this.getStudyType();
    await this.getAccommodation();
    await this.getParentSummer();
    await this.getIdentifyTypeChoice();
    await this.getClassNameChoice();
    
    await this.getLiveWithChoice()
    await this.getTypeOfOwnershipChoice()
    await this.getTypeOfHousingChoice()
    await this.getTypeOfDisease()

    let student = [];
    let is_obj = false;
    if (this.id) {
      await this.axios
        .get(`${this.base_url}api/summer/student/${this.id}/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then(async(response) => {
          student = response.data;

          
          for (const key in this.dropList) {            
            for (const key2 in this.dropList[key]) {
              if(key2 =='name' || key2=='phone_number' || key2 == 'home_number' || key2 == 'address')
                continue
              else this.dropList[key][key2] = student[key2];
              is_obj = true;
            }
            if (is_obj == true) is_obj = false;
            else if(key !='documents') this.dropList[key] = student[key];            
            
         



          }

            this.dropList.documents =[]
            this.dropList.documents = [...student['documents']]        
             await this.getDivisionByLevel();
            await this.getStudentDocument()

            if(this.dropList.fk_parent)
              await this.showSelectedParent(this.dropList.fk_parent);
        });
    }
      
    this.loading = false;
  },
  data() {
    return {
      step: 0,
      steps: 0,

      dropList: {},

      tabs: [
        { name: this.$t("school.student_info"), valid: true },
        { name: this.$t("school.student_academic_info"), valid: true },
        { name: this.$t("school.parent_info"), valid: true },
      ],
      validList: ["form_1", "form_2", "form_3"],
      governorates: [],
      directorates: [],
      levels: [],
      divisions: [],
      student_type: [],
      accommodation_types: [],
      parent_list: [],
      identify_type: [], 
      calssName: [],
      documents: [],
      LiveWithChoice: [],
      TypeOfOwnershipChoice: [],
      TypeOfHousingChoice: [],
      TypeOfDiseases: [],

      btn_loading: false,
      selected: true,
      loading: false,
    };
  },
  methods: {
    ...mapActions({
      getGenders: "summer/getGenders",
    }),
     resetForm() {
      this.dropList = {
        habby:null,
        pervious_class:null,
        sitting_number:null,
        outcome:null,
        date_of_acquisition:null,
        summer_course:null,
        cultural_course:null,
        scientific_abandonment:null,
        other_item:null,
        recommendator:null,
        official_recommendation:null,
        recommendator_work:null,
        recommendator_phone_number:null,
        id_card:null,
        academic_id: null,
        student_type: null,
        accommodation_type: null,
        fk_parent: undefined,
        fk_user: null,
        fk_country: null,
        fk_governorate: null,
        fk_governorate_housing: null,
        directorate_housing: null,
        village_housing:null,
        neighbourhood_housing:null,
        fk_directorate: null,
        school_name: null,
        schoollevel: undefined,
        name: null,
        last_name: null,
        gender: null,
        nationality: null,
        birthdate: null,
        birthdate_h: null,
        birth_place: null,
        parent_relation: null,
        address: null,
        phone_number: null,
        id_card: null,
        home_number: null,
        registration_form: null,
        note: undefined,
        files: undefined,
        student_image: undefined,
        parent: {
          fk_user: null,
          name: null,
          identity_type: null,
          identity_number: null,
          identity_image: null,
          job: null,
          address: null,
          phone_number: undefined,
          id_card: null,
          home_number: undefined,
          note: undefined,
        },
        class: {
          fk_student: 0,
          fk_year: undefined,
          fk_level: undefined,
          fk_division: undefined,
          note: undefined,
          num_male_family:undefined,
          num_fmale_family:undefined,
          student_tripp_between_brother:undefined,
          student_live_with:undefined,
          type_of_ownership:undefined,
          type_of_housing:undefined,
          minute_distance:undefined,
          meter_distance:undefined
        },
        documents:[],
        disease_student:[],
      };  
    },

    // addDoc(){
       
    //   this.dropList.documents.push({
    //       fk_level_document: undefined,
    //       image: undefined,
    //       release_date: undefined,
    //       issuer: undefined,
    //   })

    // },
    // removeDoc(index){
    //    this.dropList.documents.splice(index, 1)
    // },
    addDoc(){
       
      this.dropList.disease_student.push({
          id: undefined,
          disease: undefined,
          evalate: undefined,
      })

    },
    removeDoc(index){
      this.dropList.disease_student.splice(index, 1)
    },
    async next(key) {
      let index = this.steps;

      const data = this.validList[index];
      const { valid } = await this.$refs[data].validate();
      if (!valid) {
        this.tabs[index].valid = false;
      } else this.tabs[index].valid = true;

      this.steps = key;
    },
    async getIdentifyTypeChoice() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "IdentifyTypeChoice",
          choices: true,
        })
        .then((response) => {
          this.identify_type = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getParentSummer() {
        await this.axios(`${this.base_url}api/parent-select/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        })
        .then((response) => {
          this.parent_list = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getStudyType() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "StudyTypeChoice",
          choices: true,
        })
        .then((response) => {
          this.student_type = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getAccommodation() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "AccommodationChoice",
          choices: true,
        })
        .then((response) => {
          this.accommodation_types = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getGov() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "Governorate",
          fields: ["id", "name_ar", "fk_country"],
        })
        .then((response) => {
          this.governorates = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDir() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "Directorate",
          fields: ["id", "name_ar", "fk_governorate"],
        })
        .then((response) => {
          this.directorates = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivisionByLevel() {
      await this.axios(`${this.base_url}api/division-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        params: {
          fk_level: this.dropList.class.fk_level,
        },
      })
        .then((response) => (this.divisions = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },

    //parent
    newParent() {
      this.selected = false;      
      this.dropList.fk_parent = undefined;
      this.dropList.parent= {
          fk_user: null,
          name: null,
          identity_type: null,
          identity_number: null,
          identity_image: undefined,
          job: null,
          address: null,
          phone_number: undefined,
          id_card: null,
          home_number: undefined
          }          
    },
    oldParent() {
      this.selected = true;            
    },
    showSelectedParent(id) {
      if (id != undefined) this.setParent(id);
      else this.dropList.parent= {
          fk_user: null,
          name: null,
          identity_type: null,
          identity_number: null,
          identity_image: undefined,
          job: null,
          address: null,
          phone_number: undefined,
          id_card: null,
          home_number: undefined,
          note: undefined,
        }

      
    },
    async setParent(parent) {
      await this.axios(`${this.base_url}api/summer/student/parent-by-id/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        params: {
          parent_id: parent,
        },
      })
        .then((response) => {
          this.dropList.parent = response.data;
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },

    async validate() {
      for (let i = 0; i < this.validList.length; i++) {
        try {
          const v = this.validList[i];
          const { valid } = await this.$refs[v].validate();

          
          if (valid) this.tabs[i].valid = true;
          else {
            this.tabs[i].valid = false;
            this.step = i;
            this.steps = i;
            return;
          }
        } catch {
          this.tabs[i].valid = false;
          this.step = i;
          this.steps = i;
          return;
        }
      }

      return this.tabs.every((e) => e.valid === true);
    },
   

    async saveData() {
      const valid = await this.validate();
      if (valid) {
        this.btn_loading = true;


        this.dropList.class.fk_year = localStorage.getItem("current_year");
        
        let data = {...this.dropList}
          let form = new FormData()
        if (
          data.student_image &&
          typeof(data.student_image )== "object"
        )
          data.student_image = data.student_image[0];
        else if(data.student_image == undefined) data.studentImageDelete = true;
        else data.student_image = null
        

        if (
          data.registration_form &&
          typeof(data.registration_form) == "object"
        )
          data.registration_form = data.registration_form[0];
        else if(data.registration_form == undefined) data.registrationFormImageDelete = true;
        else  data.registration_form = null

        if (
          data.parent.identity_image &&
          typeof(data.parent.identity_image) == "object"
        )
          data.parent.identity_image =
            data.parent.identity_image[0];
        else if(data.identity_image == undefined) data.identityImageDelete = true;
        else  data.parent.identity_image = null


      
        

        this.dropList.documents.forEach((e,index)=>{              
          if(e.image && typeof(e.image) == "object")
            form.append(`file_document_${index}`, e.image[0] )         
          else form.append(`file_document_${index}`, e.image )
        })
        
        if(!data['id_card'])
         data['id_card'] = null
      
        form.append("student_image",  data.student_image )
        form.append("registration_form",  data.registration_form)
        form.append("identity_image",  data.parent.identity_image)
        form.append("data", JSON.stringify(data))
        

        await this.axios
          .post(this.base_url + "/api/summer/student/", form, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.resetForm();
          })
          .catch((error) => {
            const message = error?.response?.data?.non_field_errors ?? null
            if(message !=null)
            if(message[0]=="الحقول name, birthdate, birth_place يجب أن تشكل مجموعة فريدة.")
              {
                this.tabs[0].valid = false
                this.step = 0;
                this.steps = 0;
                this.dropList.uniqe_name = true;                
                this.btn_loading = false;
                return 
              }                       
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });

        this.btn_loading = false;
      }
    },
    async updateData() {

      const valid = await this.validate();
      if (valid) {
        this.btn_loading = true;
        this.dropList.class.fk_year = localStorage.getItem("current_year");

        let data = {...this.dropList}
        
        let form = new FormData()
        if (
          data.student_image &&
          typeof(data.student_image )== "object"
        )
          data.student_image = data.student_image[0];
        
        
        if (
          data.registration_form &&
          typeof(data.registration_form) == "object"
        )
          data.registration_form = data.registration_form[0];
        

        if (
          data.parent.identity_image &&
          typeof(data.parent.identity_image) == "object"
        )
          data.parent.identity_image =
            data.parent.identity_image[0];
        

          

          this.dropList.documents.forEach((e,index)=>{              
            if(e.image && typeof(e.image) == "object")
              form.append(`file_document_${index}`, e.image[0] )         
            else form.append(`file_document_${index}`, e.image )
          })
        

        if(!data['id_card'])
         data['id_card'] = null
      
        form.append("student_image",  data.student_image )
        form.append("registration_form",  data.registration_form)
        form.append("identity_image",  data.parent.identity_image)
        form.append("data", JSON.stringify(data))

       
        await this.axios
          .put(
            `${this.base_url}/api/summer/student/${this.id}/`,
            form,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            setTimeout(() => {
              this.$router.push({ name: "summer-student-list" });
            }, 2000);
          })
          .catch((error) => {
           const message = error?.response?.data?.non_field_errors ?? null
        

            if(message !=null)
            if(message[0]=="الحقول name, birthdate, birth_place يجب أن تشكل مجموعة فريدة.")
              {
                this.tabs[0].valid = false
                this.step = 0;
                this.steps = 0;
                this.dropList.uniqe_name = true;
                this.btn_loading = false;
                return 
              }

                      
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
      this.btn_loading = false;
    },
    
     async getClassNameChoice() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "ClassNameChoice",
          choices:true
        })
        .then((response) => {
          this.calssName = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },

     async getStudentDocument() {
      if(this.dropList.class.fk_level)
       await this.axios
          .get(this.base_url + "/api/summer/document-by-level",{
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params:{
              fk_level:this.dropList.class.fk_level
            }
          })
          .then((response) => {
           
            
            const data= response.data.map(e=>{
              let status  = false 

              
              
              if(this.dropList.documents.find(f=>f.fk_level_document == e.id))            
                status =true

              return {
                fk_level_document:e.id,               
                name:e.name,
                status:status
              }
            })

            this.dropList.documents = [...data]
          })
          .catch((error) => {           
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      else this.documents = []
    },
    
    async getLiveWithChoice() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "LiveWithChoice",
          choices:true
        })
        .then((response) => {
          this.LiveWithChoice = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getTypeOfOwnershipChoice() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "TypeOfOwnershipChoice",
          choices:true
        })
        .then((response) => {
          this.TypeOfOwnershipChoice = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getTypeOfDisease() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "TypeOfDisease",
          choices:true
        })
        .then((response) => {
          this.TypeOfDiseases = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getTypeOfHousingChoice() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "TypeOfHousingChoice",
          choices:true
        })
        .then((response) => {
          this.TypeOfHousingChoice = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },

    
 

  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    ...mapState({
      genders: (state) => state.summer.genders,
      role: (state) => state.role,
    }),
  },
};
</script>