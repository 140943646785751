<template>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_talent')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getTalent"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editTalent"
    :delItem="deleteTalent"
    :del_perm="CheckGetPermission('legal_school.delete_talent',['sys_admin','gen_admin'])"
    :edit_perm="CheckGetPermission('legal_school.change_talent',['sys_admin','gen_admin'])"
    :add_perm="CheckGetPermission('legal_school.add_talent',['sys_admin','gen_admin'])"
  >
  </DataTable>
   <v-dialog
    v-model="addDialog"
    max-width="700"
    
  >
   <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form"  @submit.prevent="is_update?updateTalent():saveTalent()">
          <VRow class="mt-2">
            <VCol  cols="12" >
                <VTextField
                  v-model="talent.title"
                  autofocus
                  :label="$t('summer.name-talent')"
                  persistent-hint
                  clearable
                  density="compact"
                  prepend-inner-icon="mdi-star"
                  :rules="rules.title"
                ></VTextField>
            </VCol>
            <VCol  cols="12" >
                <VTextarea
                  v-model="talent.note"
                  color="primary"
                  clearable
                  :label="$t('summer.initiative.note')"
                  no-resize
                  counter="250"
                  density="compact"
                  rows="2"
                  prepend-inner-icon="mdi-note-outline"
                  :rules="rules.note"
                ></VTextarea>
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions
        class="mx-4"
      >
        <VBtnSave
          v-if="!is_update"
          @click="saveTalent()"
          :loading="saveDataLoading"
        >
            {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateTalent()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()" v-if="!is_close">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="closeForm()" v-if="is_close">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
  <alert-cascade-records
    v-model="alert_cascade"
    :data_message="data_message"
    :title="alert_cascade_title"
    :loading="deleteDataLoading"
    @confirm-delete="confirmDelete"
  >
  </alert-cascade-records>

</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{
    DataTable
  },
  data() {
    return {
      data_message: [],
      alert_cascade_title: "",
      alert_cascade: false,
      force_delete: false,
      addDialog:false,
      saveDataLoading: false,
      deleteDataLoading: false,
      homeworks: [],
      talents: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      
      talent: {},
      sortBy: [
        {
          key: "title",
          order: "asc",
        },
      ],
      rules: {
        title: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) || this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 2) ||
            `${this.$t("globals.lowest_number_of_character")} 2`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      v$: useValidate(),
      is_close: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("summer.name-talent"), key: "title" },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false }
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    tableList() {
      if (this.talents) {
        return JSON.parse(JSON.stringify(this.talents));
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getTalent();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    async confirmDelete() {
      this.force_delete = true;
      const res = await this.deleteTalent(this.delete_id);
      if (res == true) {
        this.$emit("successAlert", this.$t("globals.data_deleted"));
        this.getTalent();
      } else {
        this.$emit("errorAlert", this.$t("alert.failure.delete"));
      }
      this.alert_cascade = false;
    },
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    closeForm() {
      this.addDialog = false;
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.talent = {};
      this.is_close = false;
      this.is_update = false;
      this.updated_id = undefined;
    },
    editTalent(talent) {
      this.talent = Object.assign({},talent);
      this.addDialog = true
      this.is_close = true;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveTalent() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.talent.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/talent", this.talent, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.talent = {};
            this.saveDataLoading = false;
            this.resetForm();
            this.getTalent();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error.response.data.title)
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.resetForm();
          });
      }
    },
    async updateTalent() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.talent.data_entry = this.user;
        let result = await this.axios
          .put(this.base_url + "api/summer/talent/" + this.talent.id, this.talent, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.saveDataLoading = false;
            this.resetForm();
            this.closeForm();
            this.getTalent();
          })
          .catch((error) => {
            if (error.response.data.title)
              this.$emit("warningAlert", this.$t("globals.error_in_repeted_data"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
      }
    },
    async deleteTalent(delete_id) {
      if (delete_id) {
        var status ;
        this.delete_id = delete_id;
        this.deleteDataLoading = true;
        let result = await this.axios
          .delete(this.base_url + "api/summer/talent/" + delete_id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params:{
              force_delete:this.force_delete
            }
          })
          .then((response) => {
            status = true
            this.deleteDataLoading = false;
            this.force_delete=false
          })
          .catch((error) => {
            status = error
            this.force_delete=false
            if(error.message.includes('406')){
              this.alert_cascade=true
              this.alert_cascade_title=this.$t('alert.attention.delete-talent-with-student')
              this.data_message=error.response.data

            }
            this.deleteDataLoading = false;
          });
          return status
        }
    },
    async getTalent(page = 1,per_page = 10,ordering = null,search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/talent`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.talents = response.data.results;
        this.pagination = response.data.pagination;
        this.loading = false;
      });
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>

<style scoped>

</style>
