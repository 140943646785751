
<template>
  <v-card class="pa-3"  v-if="!show">
    <v-card-title>
      {{ $t("globals.select-criteria") }}
    </v-card-title>
    <v-card-text class="mt-2">
      <v-form ref="form" >
        <v-row>
          <v-col cols="12" md="3">
            <v-select
            v-model="filter.type"
              :items="[
                {
                  id: 1,
                  title: 'التقرير الشهري'
                },
                {
                  id: 2,
                  title: 'التقرير النصفي'
                },
                {
                  id: 3,
                  title: 'التقرير النهائي'
                }
              ]"
              item-title="title"
              item-value="id"

              density="compact"
              :label="$t('globals.type_report')"
              :rules="[$require]"

            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="filter.fk_level"
              :items="levels"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              hide-details="auto"
              :rules="[$required]"
              clearable
              @update:model-value="
                (filter.fk_division = undefined), getDivisionByLevel()
              "
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              :items="divisions"
              v-model="filter.fk_division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="fk_division"
              :label="$t('division.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
            />
          </v-col>
       
           <v-col cols="12" md="3" v-if="filter.type == 1">
            <v-autocomplete
              :items="persnoalEvaluations"
              v-model="filter.fk_TypeOfPersnoalEvaluation"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name"
              item-value="id"
              :label="$t('school.types_personal')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
              :rules="[$required]"
            />
          </v-col>
        <!-- </v-row>
        <v-row> -->
           <v-col cols="3" :md="filter.type == 1?3:2" >
            <v-autocomplete
              :items="semesters"
              v-model="filter.fk_semester"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name_ar"
              item-value="id"
              :label="$t('semester.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
              :rules="[$required]"
              @update:modelValue="
                getMonthsBySemester(), (this.filter.fk_monthsemester = null)
              "
            />
          </v-col>

          <v-col cols="3"  v-if="filter.type ==1">
            <v-autocomplete
              color="indigo"
              v-model="filter.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="id"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
        
            />
          </v-col>
         
          <v-col cols="1" class="mt-2">
            <custom-btn type="show" :click="() => getData()" />
          </v-col>
        </v-row>
      </v-form>
    
    </v-card-text>
  </v-card>
    <custom-table-report
        v-else
        :close="() => reset()"
        :headers="headers"
        :items="items"
        counter
        :title="title"
      />
</template>
<script>
export default {
  data() {
    return {
      filter: {type:1},

      items: [],
      headers: [],
      levels: [],
      divisions: [],
      semesters: [],
      persnoalEvaluations: [],
      months:[],

      show: false,

      title:''
    };
  },
  created() {
    this.getLevels();
 
    this.getSemester();
    this.getPersnoalEvaluation();

  },
  methods: {
    async getData() {
      const { valid } = await this.$refs.form.validate();
      if (valid){
        var fk_monthsemester =undefined
          if(this.filter.fk_monthsemester)
            fk_monthsemester= this.months.find(
                  (e) => e.month == this.filter.fk_monthsemester
                ).id

        await this.axios(
          `${this.base_url}api/summer/report/evaluation-report/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },

            params: {
              fk_monthsemester:fk_monthsemester,
            
              type: this.filter.type,
              fk_semester: this.filter.fk_semester,
              fk_level: this.filter.fk_level,
              fk_division: this.filter.fk_division,
              fk_TypeOfPersnoalEvaluation:
                this.filter.fk_TypeOfPersnoalEvaluation,
            },
          }
        )
          .then((response) => {
            this.items = response.data.data;
            this.title = response.data.title;
            if (this.items.length > 0) {
              this.headers = [];
              this.headers.push({
                title: this.$t("reportpublic.student-name"),
                key: "stu_name",
              });
              this.headers.push(...response.data.headers);
              this.headers.push({
                title: this.$t("school.notes"),
                key: "note",
              });
              // this.title = response.data.title

              this.show = true;
            } else this.$emit("infoAlert", this.$t("globals.not-found"));
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
   
    }
   },

    reset() {
      this.show = false;
      this.items = [];
      
    },

    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivisionByLevel() {
      if (this.filter.fk_level) {
        let levelDivisions = await this.axios.get(
          this.base_url +
            `api/summer/get-level-division/${this.filter.fk_level}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.divisions = levelDivisions.data.division;
      }
    },
     
     async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
      const current_semester = this.semesters.find((e) => e.current_semester);
      if (current_semester) {
        this.filter.fk_semester = current_semester.id;
        this.getMonthsBySemester();
      }
    },

    async getMonthsBySemester() {
      if (this.filter.fk_semester)
        await this.axios(
          `${this.base_url}/api/months-semester/?fk_semester=${this.filter.fk_semester}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then((response) => {
            this.months = response.data;
            const current_month = response?.data?.find((e) => e.current_month);
            if (current_month) {
              this.filter.fk_monthsemester = current_month.month;
            }
    
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      else this.months = [];
    },
    async getPersnoalEvaluation() {
      let result = await this.axios.get(
        this.base_url + `/api/type-of-persnoal-evaluation/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.persnoalEvaluations = result.data;
    },
 
  },
};
</script>