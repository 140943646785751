<template>
  <v-card v-if="!exist">
    <v-card-text>
      <h3 class="text-grey-darken-2">{{ $t("globals.select-criteria") }}</h3>
      <v-row class="my-2 mt-6">
        
        <v-col cols="12" md="4" sm="6" class="mb-1" v-if="role>=0&&role<=1">
          <VAutocomplete
            :items="filterGovernorates(1)"
            v-model="fk_governorate"
            item-title="name_ar"
            item-value="id"
            :label="$t('globals.governorate')"
            persistent-hint
            density="compact"
            hide-details
            clearable
            :no-data-text="$t('governorate.not-found')"
            prepend-inner-icon="mdi-city"
            @update:modelValue="fk_directorate=null,fk_summer=null"
            >
          </VAutocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="mb-1" v-if="role>=0&&role<=2">
          <VAutocomplete
            :items="filterDirectorates(fk_governorate)"
            v-model="fk_directorate"
            item-title="name_ar"
            item-value="id"
            :label="$t('globals.directorate')"
            persistent-hint
            hide-details
            density="compact"
            clearable
            :no-data-text="$t('directorate.not-found')"
            prepend-inner-icon="mdi-map-outline"
            @update:modelValue="getSummerSchools(),fk_summer=null"
          ></VAutocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="mb-1" v-if="role>=0&&role<=3">
          <VAutocomplete
              v-model="fk_summer"
              item-value="id"  
              item-title="name_ar"
              prepend-inner-icon="mdi-domain"    
              :items="summer_schools"
              :loading="loadingSchools"
              :label="$t('globals.summer-centre')"
              persistent-hint
              hide-details
              density="compact"
              :no-data-text="$t('summer.not-found')"
              
              clearable
          ></VAutocomplete>
        </v-col>
        <v-col cols="12" md="4" sm="6" class="mb-1">
          <v-autocomplete
            v-model="selected_talents"
            item-title="title"
            item-value="id"
            multiple
            chips
            prepend-inner-icon="mdi-stairs-box"
            :items="talents"
            hide-details="auto"
            :label="$t('globals.talent')"
          />
        </v-col>
        <v-col cols="6" sm="1" class="mt-2">
          <v-btn
            density="comfortable"
            append-icon="mdi-presentation"
            class="w-100 text-white"
            color="primary"
            :loading="loading"
            @click="checkFilterData"
          >
            <span>{{ $t("globals.show") }}</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>

  <v-card flat  id="report"  v-if="exist">
    <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("summer.report.talented-students") }}
          </div>
        </div>
      </template>
    </ReportHeader>
    <v-card-text class="mt-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="exportToExcel"
          >
            <v-icon color="green-darken-2">mdi-file-excel</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top" >
              <small>{{ $t("export.excel") }}</small>
            </v-tooltip>
          </v-btn>
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn
            density="compact"
            @click="(exist = false)"
          >
            <span class="text-primary">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>
      <div id="myTable">
        <v-card v-if="items.data.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card
          :loading="loading"
          rounded="0"
          flat
          v-if="items.data.length > 0"
        >
          <table class="report-t text-center" ref="table">
            <thead>
              <tr>
                <th style="width: 50px">{{ $t("globals.#") }}</th>
                <th >{{$t('school.student-name')}}</th>
                <th v-if="fk_governorate==null&&(role>=0 && role<=1)">{{$t('globals.governorate')}}</th>
                <th v-if="fk_directorate==null&&(role>=0 && role<=2)">{{$t('globals.directorate')}}</th>
                <th v-if="fk_summer==null&&(role>=0 && role<=3)">{{$t('summer.summer-camp')}}</th>
                <template v-if="selected_talents?.length>0" >
                  <th v-for="talent in items?.talents" :key="talent" >
                    {{ talent }}
                  </th>
                </template>
                <th v-else>
                  {{$t('globals.talents')}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(student, index) in items.data" :key="student">
                <td>{{ index + 1 }}</td>
                <td class="text-start">
                  {{ student.student }}
                </td>
                <td v-if="fk_governorate==null&&(role>=0 && role<=1)">
                  {{ student.governorate }}
                </td>
                <td v-if="fk_directorate==null&&(role>=0 && role<=2)">
                  {{ student.directorate }}
                </td>
                <td v-if="fk_summer==null&&(role>=0 && role<=3)">
                  {{ student.fk_summer }}
                </td>
                <template v-if="selected_talents?.length>0">
                  <td v-for="talent in student.talents" :key="talent">
                    <v-icon v-if="talent == true" color="success"
                      >mdi-check-circle</v-icon
                    >
                    <v-icon v-else color="error">mdi-close-circle</v-icon>
                  </td>
                </template>
                <td v-else>
                  {{student.talents?.join(' - ')}}
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";

import { required, helpers } from "@vuelidate/validators";
export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.gettalents();
      await this.getGovernorates();
      await this.getDirectorate();
      if(this.role==2){
        this.fk_governorate = parseInt(localStorage.getItem('governorate'))
      }
      if(this.role==3){
        this.fk_directorate = parseInt(localStorage.getItem('directorate'))
        await this.getSummerSchools()
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      selected_talents: [],
      fk_governorate:null,
      fk_directorate:null,
      fk_summer:null,
      loadingSchools:false,
      // role:localStorage.getItem('role'),
      summer_schools:[],
      v$: useValidate(),
      exist: false,
      loading: false,
      txt_search: "",
      selectedHead: ["id", "name", "adjective", "entity", "phone_number"],
      headers: [
        { header:this.$t("globals.number"), key: "index" },
        { header: this.$t("school.student-name"), key: "student" },
        { header: this.$t('globals.governorate'), key: "governorate"},
        { header: this.$t('globals.directorate'), key: "directorate" },
        { header: this.$t("summer.summer-camp"), key: "fk_summer" },
        { header: this.$t("globals.talents"), key: "talents" },
      ],

      perPage: 25,
      length: 0,
      totalItems: 0,
      items: [],
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
      class_divisions_hall: [],
    };
  },
  computed: {
    ...mapState({
      governorates: (state) => state.school.governorates,
      directorates: (state) => state.school.directorate,
      class_assign_hall: (state) => state.summer.class_assign_hall,
      divisions: (state) => state.summer.divisions,
      levels: (state) => state.summer.levels,
      weeks: (state) => state.summer.weeks,
      talents: (state) => state.summer.talents,
      role:(state)=>state.role
    }),

    ...mapGetters({
      getClassDivisions: "summer/getClassDivisions",
      filterGovernorates: "school/getGovernoratesByCountry",
      filterDirectorates: "school/getDirectorateByGovernorates",
    }),
    class_divisions() {
      if (this.filter_data.fk_level) {
        return this.getClassDivisions(this.filter_data.fk_level);
      }
    },
    selectedHeaders() {
      return this.headers.filter((header) =>
        this.selectedHead.includes(header.key)
      );
    },
  },
  methods: {
    ...mapActions({
      gettalents: "summer/gettalents",
      getGovernorates: "school/getGovernorates",
      getDirectorate: "school/getDirectorate",
    }),
    exportToExcel(){
      let data = JSON.parse(JSON.stringify(this.items.data))
      if(this.selected_talents?.length>0){
        const headers = this.headers.filter(header=>header.key!='talents')
        this.items.talents.forEach(talent=>{
          headers.push({
            header:talent,
            key:talent
          })
        })
        data?.forEach((item,index)=>{
          item['index']=index+1
          const talents = item.talents
          Object.keys(talents).forEach(key=>{
            talents[key]=talents[key]==true?'نعم':"لا"
          })
          delete item.talents
          Object.assign(item,{...talents})
        })
        this.$ExportToExcel(headers, data, "الطلاب الموهوبين")
      }else{
        data?.forEach((item,index)=>{
          item['index']=index+1
          item.talents = item.talents?.join(' - ')
        })
        this.$ExportToExcel(this.headers, data, "الطلاب الموهوبين")
      }
    },
    async getSummerSchools(){
      this.loadingSchools=true
      await this.axios.get(`${this.base_url}api/summer/school-select-list`, {
      params:{
        directorate:this.fk_directorate,
        governorate:this.fk_governorate
      },
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
      }).then(response=>{
        this.loadingSchools=false
        this.summer_schools=response.data
      }).catch(err=>{
        this.loadingSchools=false
      })
    },
    getTalentName(id) {
      return (
        this.talents.find((talent) => talent.id == id)?.title ?? "---------"
      );
    },
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },
    getLevelName(driver_id) {
      if (driver_id) {
        const committees = this.levels.find(
          (committees) => committees.id === driver_id
        );
        return committees.level_with_phase_name;
      }
      return "===========";
    },
    getDivisionName(driver_id) {
      if (driver_id) {
        const committees = this.divisions.find(
          (committees) => committees.id === driver_id
        );
        return committees.name;
      }
      return "===========";
    },
    checkFilterData() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.getData();
      }
    },
    async printTable() {
      window.print();
    },
    async getData() {
      this.loading = true;
      await this.axios
        .get(`${this.base_url}api/summer/report/talented-students`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },

          params: {
            talents: this.selected_talents,
            governorate:this.fk_governorate,
            directorate:this.fk_directorate,
            fk_summer: this.fk_summer
          },
        })
        .then((response) => {
          this.loading = false;
          this.exist = true;
          this.items = response.data;
        })
        .catch((error) => {
          this.loading = false;
          this.exist = false;
        });
    },
  },
  validations() {
    return {};
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>

