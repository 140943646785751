<template>
  <v-card
    class="justify-center"
    v-if="
      CheckGetPermission('legal_school.view_studentsummer', [
        'sys_admin',
        'gen_admin',
      ])
    "
  >
    <v-card-text class="mb-4">
      <v-row class="justify-center">
        <v-col cols="12">
          <!-- <v-card class="pa-2 ma-auto" > -->
          <v-form ref="form" @submit.prevent>
            <v-row class="mt-4">
              <v-col cols="12" md="5" sm="4">
                <VTextField
                  v-model="academic_id"
                  :label="$t('globals.student_id')"
                  persistent-hint
                  clearable
                  density="compact"
                  :rules="[$required]"
                  :placeholder="$t('summer.student.enter-academic-num')"
                  prepend-inner-icon="mdi-account"
                />
              </v-col>

              <v-col cols="4" md="1" class="mt-2 mb-4">
                <v-btn
                  @click="getStudenthDropout(), (clicked = true)"
                  :loading="DataLoading"
                  class="bg-primary"
                  size="small"
                >
                  <span class="text-white">
                    {{ $t("globals.show") }}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
            <v-card>
              <v-table v-if="exist" density="compact">
                <thead>
                  <tr>
                    <th style="width: 100px">{{ $t("school.academic_id") }}</th>
                    <th style="width: 200px">{{ $t("school.student_name") }}</th>
                    <th style="width: 200px">{{ $t("globals.current_school") }}</th>
                    <th style="width: 250px">{{ $t("globals.school_transfer") }}</th>
                    <th style="width: 200px">{{ $t("globals.reason_accept") }}</th>
                    <th style="width: 200px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{{ students.academic_id }}</td>
                    <td>{{ students.student_name }}</td>
                    <td>{{ students.school_name}}</td>
                    <td>
                      <VAutocomplete
                        :items="schools"
                        v-model="students.summer_id"
                        item-title="name_ar"
                        item-value="id"
                        :label="$t('summer.summer-camp')"
                        persistent-hint
                        prepend-inner-icon="mdi-school"
                        density="compact"
                        :rules="[$required]"
                        class="mt-3"
                      ></VAutocomplete>
                    </td>
                    <td>
                        <VTextField
                v-model="students.reason_transfer"
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('school.reason')"
                density="compact"
                :placeholder="$t('globals.reason_accept')"
                :rules="[$max_length(100)]"
                   class="mt-3"
              >
              </VTextField>
                    </td>
                    <td>
                      <v-card-actions v-if="exist">
                        <v-btn
                          :loading="loading"
                          v-if="
                            CheckGetPermission(
                              'legal_school.change_studentsummer',
                              ['sys_admin', 'gen_admin']
                            )
                          "
                          @click="saveStudentDropout"
                          class="bg-primary"
                          size="small"
                        >
                          <span class="text-white">
                            {{ $t("globals.move") }}
                          </span>
                          <v-icon
                            icon="mdi-file-move"
                            color="white"
                            
                          ></v-icon>
                        </v-btn>
                        <v-btn
                          @click="cleanStudentDropoutForm"
                        
                          size="small"
                        >
                          <span>
                            {{ $t("globals.clear") }}
                          </span>
                          <v-icon icon="mdi-broom" color="golden" end></v-icon>
                        </v-btn>
                      </v-card-actions>
                    </td>
                  </tr>
                </tbody>
              </v-table>
            </v-card>
          </v-form>
          <!-- </v-card> -->
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <div></div>
</template>

<script>
import useValidate from "@vuelidate/core";
import { required, helpers, maxLength, numeric } from "@vuelidate/validators";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
  name: "CorrectionDropout",
  props: {
    id: String,
  },

  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.user = this.$store.getters.getuser;
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );
      await this.getAllSchools();
      await this.getClasses();
      await this.getDivisions();
      await this.getClassAssignHall();
      await this.getStudyMode();
      await this.getDropOutReason();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      schools: [],

      loading: false,
      schedules_data: [],
      v$: useValidate(),
      showAlert: false,
      update: false,
      user: null,
      exist: false,
      DataLoading: false,
      clicked: false,
      student: [],
      valid: false,
      is_moveable: false,
      alert: {
        color: undefined,
        icon: undefined,
        title: undefined,
        message: undefined,
      },
      user: null,
      academic_id: undefined,
      class_Dropouts: [],
      Dropouts: [],
      fk_Dropout: undefined,
      studentDropout: {
        is_checked: false,
        fk_student: undefined,
        note: "",
      },
      rules: {
        title: [(value) => !!value || this.$t("globals.required_field")],
      },
      portability: [
        { name: "قابل للنقل", is_moveable: true },
        { name: "غير قابل للنقل", is_moveable: false },
      ],
      students: {
        fk_level: undefined,
        fk_division: null,
        fk_student: undefined,
      },
      max_entry: [(value) => value == null || value.length < 250 || ""],
      class_divisions_hall: [],
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.summer.levels,
      study_status: (state) => state.summer_globals.study_mode,
      place_type: (state) => state.summer_globals.drop_out_reason,
    }),

    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },

  validations() {
    return {
      title: {
        required: helpers.withMessage(
          this.$t("globals.required_field"),
          required
        ),
      },
    };
  },
  methods: {
    async getAllSchools() {
      await this.axios(`${this.base_url}/api/get-all-shool-choices`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.schools = response.data;
      });
    },
    ...mapActions({
   
   
      getStudyMode: "summer_globals/getStudyMode",
      getDropOutReason: "summer_globals/getDropOutReason",
    }),
    async getStudenthDropout() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.DataLoading = true;
        await this.axios
          .get(`${this.base_url}api/summer/reregistration-student`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              academic_id: this.academic_id,
            },
          })
          .then((response) => {
            this.exist = true;
            this.students = response.data.results;
            this.DataLoading = false;
          })
          .catch((error) => {
            this.DataLoading = false;
            this.exist = false;
            this.$emit("errorAlert", this.$t("alert.failure.exist"));
            if (error.message == "Request failed with status code 452") {
              this.$emit(
                "infoAlert",
                this.$t("alert.failure.exist-but-not-movable")
              );
            }
          });
      }
    },
    async saveStudentDropout() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {

        if(this.students.fk_summer === this.students.summer_id)
        {
          this.$emit("errorAlert", this.$t("globals.student_already_school"))          
          return
        }
        this.loading = true;
        this.axios
          .post(
            `${this.base_url}/api/summer/reregistration-student`,
            this.students,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_saved"));
            this.loading = false;
            this.academic_id = null;
            this.exist = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.insert"));
            this.loading = false;
            try {
              if (
                error.response.data.study_mode[0].includes(
                  "This field may not be null."
                )
              ) {
                this.alert.message = this.$t("alert.failure.insert");
              } else {
                this.alert.message = this.$t("alert.failure.insert");
              }
            } catch (e) {
              this.alert.message = this.$t("alert.failure.insert");
            }
          });
      }
    },
    cleanStudentDropoutForm() {
      this.v$.$reset();
      this.exist = false;
      this.DataLoading = false;
      this.clicked = false;
      this.students = [];
      this.class_Dropouts = [];
      this.fk_level = [];
    },
  },
};
</script>
