import store from "@/store/index";
import OpenSchoolsWorkersStudents from "@/views/Report/PublicReport/OpenSchoolsWorkersStudents.vue";
import OpenCoursesClosedSchools from "@/views/Report/PublicReport/OpenCoursesClosedSchools.vue";
import TotalSchoolsEmployeesStudents from "@/views/Report/PublicReport/TotalSchoolsEmployeesStudents.vue";
import TotalOpenCenters from "@/views/Report/PublicReport/TotalOpenCenters.vue";
import TotalClosedCenters from "@/views/Report/PublicReport/TotalClosedCenters.vue";
import SummerCenterStatistics from "@/views/Report/PublicReport/SummerCenterStatistics.vue";
import StatisticsSummerCentersCapitalsMunicipalityDistricts from "@/views/Report/PublicReport/StatisticsSummerCentersCapitalsMunicipalityDistricts.vue";

import SummerCurricula from "@/views/Report/PublicReport/SummerCurricula.vue";
import SummerAllDetailedSummary from "@/views/Report/PublicReport/SummerAllDetailedSummary.vue";
import SummerSchoolDetailedSummary from "@/views/Report/PublicReport/SummerSchoolDetailedSummary.vue";
import SummerStudentDetailedSummary from "@/views/Report/PublicReport/SummerStudentDetailedSummary.vue";
import SummerWorkerDetailedSummary from "@/views/Report/PublicReport/SummerWorkerDetailedSummary.vue";
import SummerActivities from "@/views/Report/PublicReport/SummerActivities.vue";

import TotalTypicalCenters from "@/views/Report/PublicReport/TotalTypicalCenters.vue";
import TalentedStudents from '@/views/Summer/reports/TalentedStudents.vue'
import DropoutStudents from '@/views/Summer/reports/DropoutStudents.vue'
import SummerAllDetailedSummaryTable from '@/views/Report/PublicReport/SummerAllDetailedSummaryTable'

// for statistics Public
import CoursesForEachTypePublicStatistics from '@/views/Report/statistics/CoursesForEachType'
import DataReportOfStudentAndStaffPresentAndActual from '@/views/Report/statistics/DataReportOfStudentAndStaffPresentAndActual'
import ActivitiesStatistics from '@/views/Report/statistics/Activities.vue'
import StudentByLevelsPublicStatistics from "@/views/Report/statistics/PublicReport/StudentByLevelsStatistics.vue";
import FeedingReport from "@/views/Report/PublicReport/FeedingReport.vue"
import FeedingReportPeriod from "@/views/Report/PublicReport/FeedingReportPeriod.vue"
import UsersReport from '@/views/Report/PublicReport/UsersReport';
import SchoolReport from '@/views/Report/PublicReport/SchoolReport';


const hasPermission = (prem) => {
  // store.dispatch("updateAuthenticated", localStorage.getItem("token"));
  store.dispatch("updatePermission", localStorage.getItem("userinfo"));
  return store.getters.checkpermission(prem);
};

function CheckSummerCenterSystem(to, from, next) {
  const summer_center = localStorage.getItem("summer_center");
  if (summer_center === "true") {
    next();
  } else {
    next({
      path: "/",
      query: { redirect: from.fullPath },
    });
  }
}
const requirePermission = (perm) => {
  return (to, from, next) => {
    if (
      hasPermission(perm) &&
      localStorage.getItem("summer_center") == "true"
    ) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};
const requireRole = (role) => {
  return (to, from, next) => {
    if (store.getters.checkrole(role)) {
      next();
    } else {
      next({
        path: "/",
        query: { redirect: from.fullPath },
      });
    }
  };
};
export default [
  {
    path: "openSchoolsWorkersStudents",
    name: "openSchoolsWorkersStudents",
    component: OpenSchoolsWorkersStudents,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-all-detailed-summary-table",
    name: "summer-all-detailed-summary-table",
    component: SummerAllDetailedSummaryTable,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
// Public
   {
    path: "CoursesForEachTypePublicStatistics",
    name: "CoursesForEachTypePublicStatistics",
    component: CoursesForEachTypePublicStatistics,
    beforeEnter: requireRole(['sys_admin','gen_admin','gov_admin','dir_admin']),
  },
   {
    path: "student-employee-present-actual",
    name: "student-employee-present-actual",
    component: DataReportOfStudentAndStaffPresentAndActual,
    beforeEnter: requireRole(['sys_admin','gen_admin','gov_admin','dir_admin','gov_financial','gen_financial']),
  },
  {
    path: "ActivitiesStatistics",
    name: "ActivitiesStatistics",
    component: ActivitiesStatistics,
    beforeEnter: requireRole(['sys_admin','gen_admin','gov_admin','dir_admin']),
  },
  
  {
    path: "StudentByLevelsPublicStatistics",
    name: "StudentByLevelsPublicStatistics",
    component: StudentByLevelsPublicStatistics,
    beforeEnter: requireRole(['sys_admin','gen_admin']),
  },
 
// Public end
//for statistics doing by bassel end
  {
    path: "openCoursesClosedSchools",
    name: "openCoursesClosedSchools",
    component: OpenCoursesClosedSchools,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "totalSchoolsEmployeesStudents",
    name: "totalSchoolsEmployeesStudents",
    component: TotalSchoolsEmployeesStudents,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {  
    path: "totalOpenCenters",
    name: "totalOpenCenters",
    component: TotalOpenCenters,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "totalClosedCenters",
    name: "totalClosedCenters",
    component: TotalClosedCenters,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summerCenterStatistics",
    name: "summerCenterStatistics",
    component: SummerCenterStatistics,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "statisticsSummerCentersCapitalsMunicipalityDistricts",
    name: "statisticsSummerCentersCapitalsMunicipalityDistricts",
    component: StatisticsSummerCentersCapitalsMunicipalityDistricts,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-all-detailed-summary",
    name: "summer-all-detailed-summary",
    component: SummerAllDetailedSummary,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-school-detailed-summary",
    name: "summer-school-detailed-summary",
    component: SummerSchoolDetailedSummary,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-courses-typical",
    name: "summer-courses-typical",
    component: TotalTypicalCenters,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "talented-students",
    name: "talented_students",
    component: TalentedStudents,
  },
  {
    path: "dropout-students",
    name: "dropout_students",
    component: DropoutStudents,
  },
  {
    path: "summer-student-detailed-summary",
    name: "summer-student-detailed-summary",
    component: SummerStudentDetailedSummary,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-worker-detailed-summary",
    name: "summer-worker-detailed-summary",
    component: SummerWorkerDetailedSummary,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-activities",
    name: "summer-activities",
    component: SummerActivities,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "summer-curricula",
    name: "summer-curricula",
    component: SummerCurricula,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "users-report",
    name: "users-report",
    component: UsersReport,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "school-report",
    name: "school-report",
    component: SchoolReport,
    beforeEnter: requireRole(["sys_admin", "gen_admin"]),
  },
  {
    path: "feeding-report",
    name: "feeding_report",
    component: FeedingReport,
    beforeEnter: requireRole(["gen_admin", "gov_admin","sys_admin",'gov_financial','gen_financial']),
  },
  {
    path: "feeding-report-periods",
    name: "feeding_report_periods",
    component: FeedingReportPeriod,
    beforeEnter: requireRole(["gen_admin", "gov_admin","sys_admin",'gov_financial','gen_financial']),
  },
];
