<template>
  <!-- filter data  -->
  <v-card v-if="!exist">
    <v-card-text>
      <v-form ref="form">
        <h3 class="text-grey-darken-2">{{ $t("globals.select-criteria") }}</h3>
        <v-row class="mt-4">
          <v-col cols="3" md="3">
            <v-autocomplete             
              :items="semesters"
              v-model="filter_data.fk_semester"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name_ar"
              item-value="id"
              :label="$t('semester.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
              :rules="[$required]"
              @update:modelValue="getMonthsBySemester(), (this.filter_data.fk_monthsemester = null)"
            />
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete            
              color="indigo"
              v-model="filter_data.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="month"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
              @update:model-value="getCurrentMonth"
            />
          </v-col>
          <v-col cols="3">
            <DatetimePicker
              v-model="filter_data.date"
              :minDate="minDate"
              :maxDate="maxDate"
              :rules="[$required]"
              :disabled="filter_data.fk_monthsemester ? false : true"
              @update:model-value="items = []"
              forma="dd"
              readOnly
            />
          </v-col>            
          <v-col cols="12" md="3">
            <v-autocomplete
              v-model="filter_data.fk_level"
              :items="levels"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              hide-details="auto"
              :rules="[$required]"
              clearable
              @update:model-value="
                (filter_data.fk_division = undefined), getDivisionByLevel()
              "
            />
          </v-col>
          <v-col cols="12" md="3" class="mb-5">
            <v-autocomplete
              :items="divisions"
              v-model="filter_data.fk_division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="fk_division"
              :label="$t('division.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
            />
          </v-col>
          <v-col cols="6" sm="1" class="mt-2">
            <v-btn
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100 text-white"
              color="primary"
              :loading="loading"
              @click="checkFilterData"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
    <ReportHeader>
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">
            {{ $t("summer-report.students-attendance") }} 
          </div>
        </div>
      </template>
    </ReportHeader>

    <v-card-text class="my-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-start" style="flex: 1"></div>
        <!-- search box  -->

        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>

          <v-btn density="compact" @click="exist = false">
            <span class="text-primary">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card
          class="pt-2"
          :loading="loading"
          v-if="items.length > 0"
          rounded="0"
          flat
        >
          <table class="report-t">
            <thead>
              <tr>
                <th style="width: 50px">{{ $t("globals.#") }}</th>
                <th> {{ $t("summer.report.fullname-with-surname") }}  </th>
                <th> {{ $t("school.curriculum") }} </th>
                <th> {{ $t("school.god_men_program") }}  </th>
                <th> {{ $t("school.afternoon_prayer") }} </th>
                <th> {{ $t("school.cultural_program") }} </th>
                <th> {{ $t("school.evening_prayer") }} </th>
                <th> {{ $t("report.total") }} </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, key) in items" :key="item">
                <td style="width: 3%">
                  {{ key + 1 }}
                </td>
                <td class="text-center" style="width: 15%"> {{ item.student_name }}    </td>
                <td class="text-center" style="width: 15%"> {{ item.curriculum }} </td>
                <td class="text-center" style="width: 15%"> {{ item.god_men_program }}    </td>
                <td class="text-center" style="width: 15%"> {{ item.afternoon_prayer }} </td>
                <td class="text-center" style="width: 15%"> {{ item.cultural_program }}    </td>
                <td class="text-center" style="width: 15%"> {{ item.evening_prayer }} </td>
                <td class="text-center" style="width: 15%"> {{ item.total }}    </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import ReportHeader from "../../../components/SummerGlobals/ReportHeader";
import moment from "moment-hijri";

export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      this.$store.dispatch(
        "updatePermission",
        localStorage.getItem("userinfo")
      );

      await this.getLevels();
      await this.getSemester();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },

  data() {
    return {
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      exist: false,
      items: [],
      semesters: [],
      months: [],
      levels: [],
      divisions: [],
      months: [],
      minDate: null,
      maxDate: null,
      loading: false,
      txt_search: "",
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      pagination: {},
      filter_data: {},
    };
  },
  methods: {
    checkFilterData() {
      this.getData();
    },
    async printTable(all) {
      window.print();
    },
    async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
      const current_semester = this.semesters.find((e) => e.current_semester);
      if (current_semester) {
        this.filter_data.fk_semester = current_semester.id;
        this.getMonthsBySemester()
      }
    },
    async getMonthsBySemester() {
      if(this.filter_data.fk_semester)
      await this.axios(
        `${this.base_url}/api/months-semester/?fk_semester=${this.filter_data.fk_semester}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.filter_data.fk_monthsemester = current_month.month;
          }
            this.getCurrentMonth();
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
      else this.months=[]
    },     
    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivisionByLevel() {
      if (this.filter_data.fk_level) {
        let levelDivisions = await this.axios.get(
          this.base_url + `api/summer/get-level-division/${this.filter_data.fk_level}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.divisions = levelDivisions.data.division;
      }
    },    
    getCurrentMonth() {
      this.filter_data.date = undefined
      if (this.filter_data.fk_monthsemester) {
        this.items = [];
        this.filter_data.date = null;
        const month =
          this.filter_data.fk_monthsemester < 10
            ? "0" + this.filter_data.fk_monthsemester.toString()
            : this.filter_data.fk_monthsemester;
        const now = moment();
        const startOfMonth = now.iYear() + "-" + month + "-01";
        const endOfMonth = moment(
          `${now.iYear()}-${this.filter_data.fk_monthsemester + 1}-01`,
          "iYYYY-iM-iDD"
        )
          .subtract(1, "day")
          .format("iYYYY-iMM-iDD");
        this.minDate = startOfMonth;
        this.maxDate = endOfMonth;

        if (
          this.months.find(
            (e) =>
              e.month == this.filter_data.fk_monthsemester &&
              e.current_month == true
          ) &&
          this.filter_data.fk_monthsemester == moment().format("iM")
        )
          this.filter_data.date = moment().format("iYYYY-iMM-iDD");
        else this.filter_data.date = this.minDate;

        this.$nextTick(() => {
        });
      }
    },    
    async getData() {
      const { valid } = await this.$refs.form.validate();

      if (valid) {
        this.loading = true;
        await this.axios
          .get(`${this.base_url}api/summer/report/student-attendance-daily-report/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
            fk_level: this.filter_data.fk_level,
            fk_division: this.filter_data.fk_division,
            fk_monthsemester: this.months.find(
              (e) => e.month == this.filter_data.fk_monthsemester
            ).id,
            date: this.filter_data.date,
            },
          })
          .then((response) => {
            this.exist = true;
            if (response.data) {
              this.items = response.data;
              this.loading = false;
            }
          })
          .catch((error) => {
            this.icon = "cancel";
            this.icon_color = "error";
            if (error.message.includes("Network Error")) {
              this.alert_title = this.$t("alert.failure.title");
              this.alert_message = this.$t("alert.failure.connect");
            } else {
              this.alert_title = this.$t("alert.not-found.title");
              this.alert_message = this.$t("alert.not-found.message");
            }
            this.process_alert = true;
            setTimeout(() => {
              this.process_alert = false;
            }, 2000);
          });
      }
    },
  },

  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>
td {
  text-align: center;
}
#stable {
  width: 100%;
}

#stable th {
  border: 1px solid #649add;
  background-color: #fff;
}

#stable td {
  border: 1px solid #649add !important;
  background-color: #fff;
}
.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}

.inside-th-cells {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
  border-bottom-width: 0px !important;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
