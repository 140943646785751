<template>
  <!-- added by samer -->
  <!-- شاشة تعيين الطلاب للانشطة -->
  <v-card class="mb-2" >
      <v-card-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-row class="py-1">
          <v-col cols="6" md="4" sm="6" class="mb-1">
            <v-autocomplete
              v-model="dropList.fk_level"
              :items="levels"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.name_ar')"
              persistent-hint
              hide-details="auto"
              density="compact"
              :rules="[$required]"
              clearable
              @update:modelValue=" (dropList.division = undefined), (divisions = []), getDivision(),exist=false"
            ></v-autocomplete>
          </v-col>
          <v-col cols="6" md="4" sm="6" class="mb-1">
            <v-autocomplete
              v-model="dropList.division"
              :items="divisions"
              prepend-inner-icon="mdi-shape-outline"
              item-title="fk_division__name"
              item-value="fk_division__id"
              :label="$t('division.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
            />
          </v-col >
          <v-col cols="4" md="1" class="mt-2">
            <v-btn :text="$t('globals.show')" color="primary" @click="getData" class="bg-primary" density="comfortable" />
          </v-col>
        </v-row>
      </v-card-text>

  </v-card>
  <v-card v-if="exist">
    <v-card-item>
      <v-data-table-virtual :headers="headers" :items="items" :loading="loading">
        <template v-slot:headers="{ columns }">
          <tr>
            <th v-for="(column, i) in columns" :key="column.key">
              <v-sheet width="250" v-if="column.key == 'name'">{{
                column.title
              }}</v-sheet>
          <v-lazy v-else>
              <v-checkbox
                v-model="selected[i - 1]"
                @change="checkAll(selected[i - 1], i - 1)"
                :indeterminate="indeterminate(i - 1)"
                :label="truncateText(column.title)"
                style="width: 150px"
                hide-details
              />

          </v-lazy>
              <v-tooltip activator="parent" location="top">{{
                column.title
              }}</v-tooltip>
            </th>
          </tr>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td v-if="item.name">{{ item.name }}</td>
            <td v-for="(i, index) in item.activities" :key="index">
              <v-checkbox-btn
                v-model="i.status"
                :key="index"
                @change="checked(index)"
                hide-details="true"
              />
            </td>
          </tr>
        </template>
      </v-data-table-virtual>
    </v-card-item>
    <v-card-actions>
      <VBtnSave @click="saveData()" :loading="btn_loading" class="ma-5">
        {{ $t("globals.save") }}
      </VBtnSave>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  data() {
    return {
      dropList: {},
      items: [],
      selected: [],
      levels: [],
      divisions: [],
      activites: [],
      headers: [],
      btn_loading: false,
      loading: false,
      exist: false,
    };
  },

  async created() {
    this.getData();
    this.getLevels();
    this.getActivites();
  },

  methods: {
    checkAll(value, index) {
      this.items.map((e) => {
        e.activities[index].status = value;
      });
    },
    checked(index) {
      const status = this.items.map((e) => e.activities[index].status);
      this.selected[index] = status.every((e) => e === true);
    },
    indeterminate(index) {
      const status = this.items.map((e) => e.activities[index].status);
      return status.some((e) => e === true) && !status.every((e) => e === true);
    },
    truncateText(text) {
      return text.length > 20 ? text.slice(0, 20) + ".." : text;
    },
    async getData() {
      this.loading = true;
      this.headers = [
        { title: this.$t("reportpublic.student-name"), key: "name" },
      ];
      if(this.dropList.fk_level  || this.dropList.division)
      await this.axios
        .get(`${this.base_url}/api/student-activities/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params:{
            fk_level:this.dropList.fk_level,
            fk_division:this.dropList.division,
            
          }
        })
        .then((response) => {
          this.items = response.data.results;
          if (this.items.length==0) {
            this.$emit("infoAlert", this.$t("globals.not-found"));
          } else {
            this.exist = true;
          }
          response.data.headers.map((e) => {
            this.headers.push({ title: e, key: e });
          });
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
      else this.items = []
      this.loading = false;
    },
    async saveData() {
        this.loading_btn = true;
        await this.axios
          .post(this.base_url + "/api/student-activities/", this.items, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));            
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });

        this.loading_btn = false;
      
    },
    async getLevels() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "Level",
          fields: ["id", "name_ar"],
        })
        .then((response) => {
          this.levels = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivision() {
      if (this.dropList.fk_level)
        await this.axios
          .post(`${this.base_url}/api/choices`, {
            model: "LevelDivision",
            fields: ["fk_division__id", "fk_division__name"],
            filter: { fk_level: this.dropList.fk_level ,fk_summer:localStorage.getItem('schoolsummer') },
          })
          .then((response) => {
            this.divisions = response.data;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
    },
    async getActivites() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "ActivitySummer",
          fields: ["id", "name"],
        })
        .then((response) => {
          this.activites = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  computed: {},
  watch: {},
};
</script>