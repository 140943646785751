<template>
  <!-- filter data  -->
  <v-card class="pa-2" v-if="!exist" >
    <h3 class="text-grey-darken-2 px-2">{{ $t("globals.select-criteria") }}</h3>
    <v-row class="my-2 mt-6">
      <v-col cols="3" md="3">
        <v-autocomplete             
          :items="semesters"
          v-model="filter_data.fk_semester"
          prepend-inner-icon="mdi-stairs-box"
          item-title="name_ar"
          item-value="id"
          :label="$t('semester.name')"
          clearable
          hide-details="auto"
          persistent-hint
          density="compact"
          :rules="[$required]"
          @update:modelValue="getMonthsBySemester(), (this.filter_data.fk_monthsemester = null)"
        />
      </v-col>
      <v-col cols="12" md="3">
        <v-autocomplete            
          color="indigo"
          v-model="filter_data.fk_monthsemester"
          density="compact"
          item-title="month_name"
          item-value="month"
          prepend-inner-icon="mdi-calendar-month"
          :items="months"
          :label="$t('month.select')"
          :rules="[$required]"
        />
      </v-col>
      <v-col cols="6" sm="1" class="d-flex align-center">
        <v-btn
          density="comfortable"
          class="w-100"
          color="primary"
          :loading="loading"
          @click="checkFilterData"
        >
          <span class="text-white">{{ $t("globals.show") }}</span>
          <VIcon icon="mdi-presentation" color="white" end></VIcon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
  <v-card flat class="justify-center" id="lectures-report" v-if="exist">
     <ReportHeader class="pb-0">
      <template v-slot:header-title>
        <div class="d-flex justify-center py-2">
          <div class="text-h5">{{ $t("summer-report.workers-displine-level") }}</div>
        </div>
      </template>
    </ReportHeader>
    <v-card-text class="my-4">
      <div class="d-flex justify-space-between mb-2" id="tableHead">
        <div class="d-flex justify-start" style="flex: 1">
          <v-menu :close-on-content-click="false">
            <v-card>
              <v-card-text>
                <v-checkbox
                  v-for="header in theaders"
                  :key="header"
                  :label="header.title"
                  :value="header.key"
                  color="primary"
                  v-model="selectedHead"
                  density="compact"
                  hide-details
                >
                </v-checkbox>
              </v-card-text>
            </v-card>
          </v-menu>
         
        </div>
        <!-- search box  -->
     
        <div class="d-flex justify-end" style="flex: 1">
          <v-btn
            density="compact"
            class="pa-0 mx-1"
            style="min-width: 25px"
            @click="printTable"
          >
            <v-icon color="icon-color">mdi-printer</v-icon>
            <v-tooltip class="tooltip" activator="parent" location="top">
              <small>{{ $t("globals.print-file") }}</small>
            </v-tooltip>
          </v-btn>
          <v-btn
            density="compact"
            @click="(exist = false)" 
          >
            <span class="text-primary">{{ $t("globals.back") }}</span>
          </v-btn>
        </div>
      </div>

      <v-row
        v-if="items.length > 0"
        class="mt-4 mb-5 text-grey-darken-2 borders pt-2 pb-2 mr-1 ml-1"
      >

        <v-col cols="3">
          <h3 class="fontsize text-grey-darken-2 mb-2">
            {{ $t("school.committee_name") }} : {{getCommitName(this.filter_data.commitee) }}
          </h3>
      
        </v-col>

     
     
      </v-row>

      <div id="myTable">
        <v-card v-if="items.length < 1" class="pa-2">
          <h3 class="text-center text-grey-darken-2">
            {{ $t("globals.not-found-items") }}
          </h3>
        </v-card>
        <v-card class="pt-2" :loading="loading"  rounded="0" flat>
          <table  id="stable" class="table" style="width: 100%; border-collapse: collapse">
            <thead class="table-light"> 
              <tr>
                
                <th v-if="$i18n.locale === 'ar'" class="bg-primary-grey-lighten-4 pa-2" >عدد العاملين</th>
                <th v-if="$i18n.locale === 'en'" class="bg-primary-grey-lighten-4 pa-2">Employee Number</th>
                <th v-if="$i18n.locale === 'ar'" class="bg-primary-grey-lighten-4 pa-2">حاضرين دون غياب</th>
                <th v-if="$i18n.locale === 'en'" class="bg-primary-grey-lighten-4 pa-2">Attendence Without Absent</th>
                <th v-if="$i18n.locale === 'ar'" class="bg-primary-grey-lighten-4 pa-2">عدد المتغيبين  خلال الاسبوع</th>
                <th v-if="$i18n.locale === 'en'" class="bg-primary-grey-lighten-4 pa-2" >Number Of Absent by Week</th>
                <th v-if="$i18n.locale === 'ar'" class="bg-primary-grey-lighten-4 pa-2" >كيف يتم تغطية الغياب</th>
                <th v-if="$i18n.locale === 'en'" class="bg-primary-grey-lighten-4 pa-2">How Absent Covered </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                
                <td  class="text-center  pp2">
                  {{items.employee_count}}
                </td>
                <td class="text-center  pp2">
                  {{items.employee_present}}
                </td>
                <td  class="text-center  pp2">
                  {{items.employee_absent}}
                </td>
                <td  class="text-center  pp2">
                </td>
              </tr>
            </tbody>
          </table>
        </v-card>
      </div>
    </v-card-text>
  </v-card>
  <Alert
    v-model="process_alert"
    :iconColor="icon_color"
    :icon="icon"
    :title="alert_title"
    :message="alert_message"
  >
  </Alert>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ReportHeader from "@/components/SummerGlobals/ReportHeader.vue";
import useValidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";

export default {
  components: { ReportHeader },
  async created() {
    try {
      await this.$store.commit('updateLoadingValue',true)
      this.$store.dispatch("updatePermission", localStorage.getItem("userinfo"));
     
      await this.getSemester();
      await this.getYears();
    
      this.current_year = this.getYearName(localStorage.getItem("current_year"));
      this.$store.commit('updateLoadingValue',false)
    } catch (error) {
      this.$store.commit('updateLoadingValue',false)
      
    }
  },

  data() {
    return {
     rules: {
        requird_filed: [(value) => !!value || this.$t("globals.required_field")],
      },
      theaders:[],

      school: [],
      current_year: "",
      fees_types: [],
      sub_list: [],
      semesters: [],
      months: [],
      drivers: [],
      parents: [],
      sub_list_sub: [],
      process_alert: false,
      icon_color: undefined,
      alert_title: undefined,
      alert_message: undefined,
      v$: useValidate(),
      exist: false,
      items: [],
      loading: false,
      txt_search: "",
      selectedHead: ["id","name", "adjective", "entity",  "phone_number"],
      headers: [
        
        { title: "#", key: "id" },
        { title: this.$t("globals.name"), key: "name" },
        { title: this.$t("globals.adjective"), key: "adjective" },
        { title: this.$t("summer.employee.place-of-work"), key: "entity" },
        { title: this.$t("globals.phone_number"), key: "phone_number" },
      
      ],
      
      perPage: 25,
      length: 0,
      totalItems: 0,
      itemsPerPage: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 15, text: "15" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 75, text: "75" },
        { value: 100, text: "100" },
        { value: "", text: this.$t("globals.all") },
      ],
      page: 1,
      pagination: {},
      filter_data: {},
    };
  },
  computed: {
    ...mapState({
      classes: (state) => state.school.classes,
      class_assign_hall: (state) => state.school.class_assign_hall,
      divisions: (state) => state.school.divisions,
      subjects: (state) => state.school.subjects,
      user: (state) => state.User,
      committee:(state)=>state.summer.committee,
      levels:(state)=>state.summer.levels,
    }),

    ...mapGetters({
      getClassDivisions: "school/getClassDivisions",
    }),

    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
  },
  methods: {
    ...mapActions({
      getClasses: "school/getClasses",
      getDivisions: "school/getDivisions",
      getClassAssignHall: "school/getClassAssignHall",
      getSubjects: "school/getSubjects",
      getCommittee:"summer/getCommittee",
      getLevels:"summer/getLevels",
    }),
    getYears() {
      this.year_data = localStorage.getItem("current_year_name_h");
      this.filter_data.year = localStorage.getItem("current_year");
      return this.year_data;
    },
   
   validations() {
    return {
      filter_data: {},
    };
  },
    getCommitName(driver_id) {
      if (driver_id) {
        const committees = this.committee.find((committees) => committees.id === committees.id);
        return committees.name;
      }
      return "===========";
    },
    checkFilterData() {
            this.getData();
      },
    async printTable() {
      window.print();
    },
    async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
      const current_semester = this.semesters.find((e) => e.current_semester);
      if (current_semester) {
        this.filter_data.fk_semester = current_semester.id;
        this.getMonthsBySemester()
      }
    },
    async getMonthsBySemester() {
      if(this.filter_data.fk_semester)
      await this.axios(
        `${this.base_url}/api/months-semester/?fk_semester=${this.filter_data.fk_semester}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
        .then((response) => {
          this.months = response.data;
           const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.filter_data.fk_monthsemester = current_month.month;
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
      else this.months=[]
    },    
    async getData() {
      if (this.filter_data.fk_monthsemester) {
        this.loading = true;   
        await this.axios
          .get(`${this.base_url}api/summer/report/employee-attendance`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") ,
            "Content-Type":'application/json'
            },        
            params: {
                fk_monthsemester: this.months.find(
                  (e) => e.month == this.filter_data.fk_monthsemester
                ).id,      
                    },
          })
          .then((response) => {
            this.exist = true;
            if (response.data) {
              this.items = response.data;
              this.loading = false;
            }
          })
          .catch((error) => {
            this.icon = "cancel";
            this.icon_color = "error";
            if (error.message.includes("Network Error")) {
              this.alert_title = this.$t("alert.failure.title");
              this.alert_message = this.$t("alert.failure.connect");
            } else {
              this.alert_title = this.$t("alert.not-found.title");
              this.alert_message = this.$t("alert.not-found.message");
            }
            this.process_alert = true;
            setTimeout(() => {
              this.process_alert = false;
            }, 2000);
          }); 
      }
    },
  },
  validations() {
    return {
      filter_data: {},
    };
  },
  watch: {
    perPage() {
      this.getData();
    },
    page() {
      this.getData(this.page);
    },
  },
};
</script>
<style scoped>


#stable{

  width: 100%;

}


.pp2{
padding: 2px!important;

}
#stable th {
border:1px solid #649add !important;
background-color: #fff;

}

#stable td  {
border: 1px solid #649add !important;
background-color: #fff;
padding: 8px;
}

.search:focus {
  outline: unset;
}
.fontsize {
  font-size: 14px;
}
.borders {
  border: solid #c1c1c1 1px;
  border-radius: 8px;
}
@media print {
  #sidebar,
  #header,
  #pagination-bar *,
  #tableHead,
  .tooltip,
  #print-menu {
    display: none !important;
  }
  #lectures-report {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
  }
}
</style>
