<template>
  <DataTable
    v-if="CheckGetPermission('globaltable.view_governorate')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="CheckGetPermission('globaltable.delete_governorate',['sys_admin','gen_admin'])"
    :edit_perm="CheckGetPermission('globaltable.change_governorate',['sys_admin','gen_admin'])"
    :add_perm="CheckGetPermission('globaltable.add_governorate',['sys_admin','gen_admin'])"
  >
  </DataTable>
   <v-dialog
    v-model="addDialog"
    max-width="700"
    
  >
   <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="is_update?updateItem():saveItem()">
          <VRow class="mt-2">
            <VCol  cols="6">
              <VTextField
                v-model="typePersonalEvaluation.name"   
                prepend-inner-icon="mdi-abjad-arabic"
                :label="$t('school.types_of_personal')"
                density="compact"
                :placeholder="$t('school.enter_types_of_personal')"
                :rules="[$ar_letters_only, $required, $max_length(50)]"
              >
              </VTextField>
            </VCol>
            <VCol  cols="6">
              <VTextField
                v-model="typePersonalEvaluation.order"
                maxLength="2"
                prepend-inner-icon="mdi-numeric"
                :label="$t('school.order')"
                density="compact"
                :placeholder="$t('school.enter_order')"
                :rules="[$required, $numeric]"
              >
              </VTextField>              
            </VCol>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
            {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()" v-if="!is_close">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="closeForm()" v-if="is_close">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>         
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{  
    DataTable
  },
  data() {
    return {
      is_close: false,
      isChecked: false,
      addDialog:false,
      protected_records: false,
      data_message: [],
      typePersonalEvaluations: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      typePersonalEvaluation: {
        name: undefined,
        order:undefined
      },
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        { title: this.$t("school.types_of_personal"), key: "name" },
        { title: this.$t("school.order"), key: "order" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.typePersonalEvaluations) {
        return JSON.parse(JSON.stringify(this.typePersonalEvaluations));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getCountries();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    ...mapActions({
      getCountries: "school/getCountries",
    }),
    resetForm() {
      this.$refs.form.reset();
      this.is_close = false;
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
    },
    closeForm() {
      this.addDialog = false;
    },      
    editItem(data) {
      this.typePersonalEvaluation = Object.assign({},data)
      this.is_close = true;
      this.is_update = true;
      this.updated_id = data.id;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async getItems(page = 1,per_page = 10,ordering = null,search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}api/type-of-persnoal-evaluation/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      }).then((response) => {
        this.typePersonalEvaluations = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.typePersonalEvaluation.data_entry = this.user;
        await this.axios
          .post(this.base_url + "api/type-of-persnoal-evaluation/", this.typePersonalEvaluation, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.name) {
              this.$emit("warningAlert", this.$t("summer.already-personal-evaluation-type"));
            }
            else{ 
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.typePersonalEvaluation.id != undefined) {
        this.editDataLoading = true;
        this.typePersonalEvaluation.updated_by = this.user;
        await this.axios
          .put(`${this.base_url}api/type-of-persnoal-evaluation/${this.typePersonalEvaluation.id}/`, this.typePersonalEvaluation, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
            this.closeForm();
          })
          .catch((error) => {
            if (error.response.data.name) {
              this.$emit("errorAlert", this.$t("summer.already-personal-evaluation-type"));
            } else this.$emit("warningAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        await this.axios
          .delete(`${this.base_url}api/type-of-persnoal-evaluation/${delete_id}/`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          });
        return status      
      }
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>
<style>

</style>
