<template>
<!-- added by samer  -->
<!-- تقرير تنفيذ البرامج التربوية  -->
  <v-card class="pa-3" v-if="!show">
    <v-card-title>
      {{ $t("globals.select-criteria") }}
    </v-card-title>
    <v-card-text class="mt-2">
      <v-form ref="form">
        <v-row>
          <v-col cols="3" md="3">
            <v-autocomplete
              :items="semesters"
              v-model="dropList.fk_semester"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name_ar"
              item-value="id"
              :label="$t('semester.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
              :rules="[$required]"
              @update:modelValue="
                getMonthsBySemester(), (this.dropList.fk_monthsemester = null)
              "
            />
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              color="indigo"
              v-model="dropList.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="month"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
              @update:model-value="getCurrentMonth"
            />
          </v-col>
          <v-col cols="3">
            <DatetimePicker
              v-model="dropList.date"
              :minDate="minDate"
              :maxDate="maxDate"
              :rules="[$required]"
              :disabled="dropList.fk_monthsemester ? false : true"
              @update:model-value="items = []"
              forma="dd"
              readOnly
            />
          </v-col>

          <v-col cols="3">
            <v-autocomplete
              v-model="dropList.iteration"
              :items="iterations"
              item-title="name"
              item-value="id"
              :label="$t('globals.iteration')"
              persistent-hint
              prepend-inner-icon="mdi-refresh"
              density="compact"
              clearable
              :rules="[$required]"
              @update:model-value="items = []"
            />
          </v-col>
          <v-col cols="1" class="mt-2">
            <custom-btn type="show" :click="() => getData()" />
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
     <custom-table-report
        v-else
        :close="() => reset()"
        :headers="headers"
        :items="items"
        counter
        :title="title"
      />
</template>
<script>
import moment from "moment-hijri";

export default {
  data() {
    return {
      dropList: {},
      items: [],
      headers: [],
      iterations: [],
      months: [],
      semesters: [],
      minDate: null,
      maxDate: null,
      show: false,
    };
  },
  created() {
    this.getIterations();
    this.getSemester();
  },
  methods: {
    async getData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        var fk_monthsemester = undefined;
        if (this.dropList.fk_monthsemester)
          fk_monthsemester = this.months.find(
            (e) => e.month == this.dropList.fk_monthsemester
          ).id;

        await this.axios(
          `${this.base_url}api/education-program-operation/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },

            params: {
                 iteration: this.dropList.iteration,
                responsible: this.dropList.responsible,
                date: this.dropList.date,      
            },
          }
        )
          .then((response) => {
            this.items = response.data.data
            if (this.items.length > 0) {
              this.show = true
            } else this.$emit("infoAlert", this.$t("globals.not-found"));
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },

    reset() {
      this.show = false;
      this.items = [];
    },

    getCurrentMonth() {
      this.dropList.date = undefined
      if (this.dropList.fk_monthsemester) {
        this.items = [];
        this.dropList.date = null;
        const month =
          this.dropList.fk_monthsemester < 10
            ? "0" + this.dropList.fk_monthsemester.toString()
            : this.dropList.fk_monthsemester;
        const now = moment();
        const startOfMonth = now.iYear() + "-" + month + "-01";
        const endOfMonth = moment(
          `${now.iYear()}-${this.dropList.fk_monthsemester + 1}-01`,
          "iYYYY-iM-iDD"
        )
          .subtract(1, "day")
          .format("iYYYY-iMM-iDD");
        this.minDate = startOfMonth;
        this.maxDate = endOfMonth;

        if (
          this.months.find(
            (e) =>
              e.month == this.dropList.fk_monthsemester &&
              e.current_month == true
          ) &&
          this.dropList.fk_monthsemester == moment().format("iM")
        )
          this.dropList.date = moment().format("iYYYY-iMM-iDD");
        else this.dropList.date = this.minDate;

        this.$nextTick(() => {
        });
      }
    },

    async getIterations() {
      await this.axios(`${this.base_url}/choices/activity-iterations`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.iterations = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },

    async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
      const current_semester = this.semesters.find((e) => e.current_semester);
      if (current_semester) {
        this.dropList.fk_semester = current_semester.id;
        this.getMonthsBySemester();
      }
    },

    async getMonthsBySemester() {
      if (this.dropList.fk_semester)
        await this.axios(
          `${this.base_url}/api/months-semester/?fk_semester=${this.dropList.fk_semester}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
          .then((response) => {
            this.months = response.data;
            const current_month = response?.data?.find((e) => e.current_month);
            if (current_month) {
              this.dropList.fk_monthsemester = current_month.month;
            }
            this.getCurrentMonth();
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      else this.months = [];
    },
  },
  computed:{
    title(){
   

      return   `تقرير البرامج التربوية ${this.semesters.find(e=>e.id == this.dropList.fk_semester).name_ar}  شهر 
         ${this.months.find(e=>e.id == this.dropList.fk_monthsemester).month_name}  بتاريخ   ${this.dropList.date}   التكرار  ${this.iterations.find(e=>e.id = this.dropList.iteration).name} `
    },

    headers() {
      return [
        {
          title: this.$t("globals.program"),
          key: "education_name",
          sortable: false,
        },
        { title: this.$t("globals.executed"), key: "is_done" ,type:'check' },
        { title: this.$t("globals.from"), key: "from_time" },
        { title: "", key: "from_date" },
        { title: this.$t("globals.to"), key: "to_time" },
        { title: "", key: "to_date" },
        {
          title: this.$t("globals.number_of_attendees"),
          key: "number_of_attendees",
        },
        {
          title: this.$t("globals.number_of_absentees"),
          key: "number_of_absentees",
        },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ];
    },
  }
};
</script>