<template>
  <DataTable
    v-if=" CheckGetPermission('legal_school.view_app')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
     CheckGetPermission('legal_school.delete_app', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_app', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :add_perm="
      CheckGetPermission('legal_school.add_app', [
        'sys_admin',
        'gen_admin',
      ])
    "
  >
    <template v-slot:item.apk_file="{ item }">
      <a :href="item.raw?.apk_file">{{ item.raw?.file_name }}</a>
    </template>
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
   
  >
    <v-card flat  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm
          ref="form"
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <VCol cols="4">
              <VAutocomplete
                v-model="application_file.version"
                :label="$t('mobile.version-name')"
                persistent-hint
                :items="versions"
                item-title="version"
                item-value="id"
                density="compact"
                prepend-inner-icon="mdi-cellphone-cog"
                :error-messages="
                  v$.application_file.version.$errors.map((e) => e.$message)
                "
                @input="v$.application_file.version.$touch"
              ></VAutocomplete>
            </VCol>
            <VCol cols="12">
              <v-file-input
                v-model="application_file.apk_file"
                density="compact"
                :show-size="selected_file ? false : true"
                :label="$t('globals.application-file')"
                prepend-inner-icon="mdi-paperclip"
                prepend-icon=""
                accept=".apk,.zip,.rar"
                @change="selected_file = ''"
                :messages="selected_file"
                :error-messages="
                  v$.application_file.apk_file.$errors.map((e) => e.$message)
                "
                @input="v$.application_file.apk_file.$touch"
              >
              </v-file-input>
            </VCol>
          </VRow>
          <v-progress-linear
            v-model="application_file.loading"
            v-if="saveDataLoading"
            color="primary"
            dir="rtl"
            height="25"
          >
            <template v-slot:default="{ value }">
              <strong class="mx-2"> {{ application_file.fileSize }} </strong>
              <strong> {{ Math.ceil(value) }}% </strong>
            </template>
          </v-progress-linear>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import useValidate from "@vuelidate/core";
import {
  required,
  helpers,
  maxLength,
  minLength,
  email,
} from "@vuelidate/validators";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      v$: useValidate(),
      addDialog: false,
      saveDataLoading: false,
      showProgress: false,
      items: [],
      selected_file: "",
      versions: [],
      application_file: {
        version: null,
        apk_file: null,
        loading: 0,
        fileSize: 0,
      },
      is_update: false,
      headers: [
        { title: this.$t("mobile.version-name"), key: "version_name" },
        { title: this.$t("globals.application-file"), key: "file_name" },
        { title: this.$t("globals.app-link"), key: "apk_file" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      pagination: {
        
      },
    };
  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getMobileVersions();
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    convertFileSizeToBytes(fileSize) {
      if (fileSize === 0) return "0 B";
      const units = ["B", "KB", "MB", "GB"];
      const threshold = 1024;
      let i = 0;
      let size = fileSize;
      while (size >= threshold && i < units.length - 1) {
        size /= threshold;
        i++;
      }
      return units[i] + " " + size.toFixed(1);
    },
    resetForm() {
      this.v$.$reset();
      this.$refs.form.reset();
      this.selected_file = "";
      this.application_file.loading = 0;
      this.is_update = false;
      this.addDialog = false;
      this.updated_id = undefined;
    },
    editItem(application_file) {
      this.application_file = Object.assign({}, application_file);
      this.application_file.apk_file = [application_file?.file_name];
      this.selected_file = application_file?.file_name;
      this.is_update = true;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveItem() {
      this.v$.$validate();
      if (!this.v$.$error) {
        this.saveDataLoading = true;
        var app = Object.assign({}, this.application_file);
        if (this.application_file.apk_file) {
          app.apk_file = this.application_file?.apk_file[0];
        }
        let result = await this.axios
          .post(this.base_url + "api/summer/app/", app, {
            onUploadProgress: ({ loaded, total }) => {
              this.application_file.loading = Math.floor(
                (loaded / total) * 100
              );
              this.application_file.fileSize =
                this.convertFileSizeToBytes(loaded) +
                " / " +
                this.convertFileSizeToBytes(total);
            },
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.getItems();
            this.resetForm();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error.response.data.title)
              this.$emit(
                "warningAlert",
                this.$t("globals.error_in_repeted_data")
              );
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async updateItem() {
      this.v$.$validate();
      if (!this.v$.$error && this.application_file?.id) {
        this.saveDataLoading = true;
        var app = Object.assign({}, this.application_file);
        if (this.application_file.apk_file) {
          app.apk_file = this.application_file.apk_file[0];
        }
        let result = await this.axios
          .put(
            `${this.base_url}api/summer/app/${this.application_file?.id}/`,
            app,
            {
              onUploadProgress: ({ loaded, total }) => {
                this.application_file.loading = Math.floor(
                  (loaded / total) * 100
                );
                this.application_file.fileSize =
                  this.convertFileSizeToBytes(loaded) +
                  " / " +
                  this.convertFileSizeToBytes(total);
              },
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.saveDataLoading = false;
            this.resetForm();
            this.getItems();
          })
          .catch((error) => {
            this.saveDataLoading = false;
            if (error.response.data.title)
              this.$emit(
                "warningAlert",
                this.$t("globals.error_in_repeted_data")
              );
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(`${this.base_url}api/summer/app/${delete_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => (status = true))
          .catch((error) => (status = error));
        return status;
      }
    },
    async getMobileVersions() {
      this.loading = true;
      await this.axios(`${this.base_url}api/summer/mobile-version/list/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.versions = response.data;
      });
    },
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      if (ordering == "version_name") ordering = "version";
      if (ordering == "-version_name") ordering = "-version";
      if (ordering == "file_name") ordering = "apk_file";
      if (ordering == "-file_name") ordering = "-apk_file";
      await this.axios(`${this.base_url}api/summer/app/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          ordering: ordering,
        },
      }).then((response) => {
        this.items = response.data?.results;
        this.pagination = response.data?.pagination;
      });
    },
  },
  validations() {
    return {
      application_file: {
        version: {
          required: helpers.withMessage(this.$t("errors.required"), required),
        },
        apk_file: {
          required: helpers.withMessage(this.$t("errors.required"), required),
          extension: helpers.withMessage(
            `${this.$t("globals.extension-required")} [apk, .zip.]`,
            (files) => {
              const allowed_extensions = ["apk", "zip", "rar"];
              const extension = files[0]?.name?.split(".").pop();
              if (extension && !allowed_extensions.includes(extension)) {
                return false;
              }
              return true;
            }
          ),
          maxValue: helpers.withMessage(
            `${this.$t("globals.file-size")} MB 100 `,
            (files) => {
              if (typeof files[0] != "string" && files[0].size > 100000000) {
                return false;
              }
              return true;
            }
          ),
        },
      },
    };
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>

