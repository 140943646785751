<template>
  <VListGroup :value="$t('report.general-management')" v-if="checkRole(['gen_admin'])" class="border-bottom" :fluid="false">
    <template v-slot:activator="{ props }">
      <VListItem v-bind="props" min-height="40" >
        <template v-slot:title>
          <span class="v-select-title">{{
            $t("report.general-management")
          }}</span>
        </template>

        <template v-slot:prepend>
          <v-tooltip :text="$t('report.general-management')">
            <template v-slot:activator="{ props }">
              <v-icon v-show="expand == true" v-bind="props">
                mdi-chart-pie
              </v-icon>
              <v-icon v-show="expand == false"> mdi-chart-pie </v-icon>
            </template>
          </v-tooltip>
        </template>
      </VListItem>
    </template>
    <VListItem
      :to="{ name: 'school-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('reportpublic.SchoolReport')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("reportpublic.SchoolReport")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'lesson-implementFor-school-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('globals.lessonImplementForSchool_report')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("globals.lessonImplementForSchool_report")
        }}</span>
      </template>
    </VListItem>
    <VListItem
      :to="{ name: 'students-dropout-report' }"
      :class="direction === 'rtl' ? 'prtl-30' : 'pltr-30'"
      :value="this.$t('summer-sidebar.dropout-students')"
    >
      <template v-slot:title>
        <v-icon size="medium" class="me-2">mdi-minus</v-icon>
        <span class="v-select-subtitle">{{
          this.$t("summer-sidebar.dropout-students")
        }}</span>
      </template>
    </VListItem>
  </VListGroup>
</template>

<script>
export default {
  props: {
    show: Boolean,
    expand: Boolean,
    direction: String,
    CheckGetPermission: {
      type: Function,
      required: true,
    },
  },
  computed: {
    checkRole() {
      return (role) => {
        if (localStorage.getItem("userinfo"))
          return this.$store.getters.checkrole(role);
      };
    },
  },
};
</script>
<style scoped>
.prtl-5 {
  padding-right: 5px !important;
}
.prtl-10 {
  padding-right: 10px !important;
}
.prtl-15 {
  padding-right: 15px !important;
}
.prtl-20 {
  padding-right: 20px !important;
}
.prtl-25 {
  padding-right: 25px !important;
}
.prtl-30 {
  padding-right: 30px !important;
}
.prtl-40 {
  padding-right: 40px !important;
}
.prtl-45 {
  padding-right: 45px !important;
}
.prtl-50 {
  padding-right: 52px !important;
}
.prtl-60 {
  padding-right: 62px !important;
}
.pltr-5 {
  padding-left: 5px !important;
}
.pltr-10 {
  padding-left: 10px !important;
}
.pltr-15 {
  padding-left: 15px !important;
}
.pltr-20 {
  padding-left: 20px !important;
}
.pltr-25 {
  padding-left: 25px !important;
}
.pltr-30 {
  padding-left: 30px !important;
}
.pltr-40 {
  padding-left: 40px !important;
}
.pltr-45 {
  padding-left: 45px !important;
}
.pltr-50 {
  padding-left: 52px !important;
}
.pltr-60 {
  padding-left: 62px !important;
}
</style>
