<template>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_initiative')"
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :create="
      () => {
        addDialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="CheckGetPermission('legal_school.delete_initiative',['sys_admin','summer_admin'])"
    :edit_perm="CheckGetPermission('legal_school.change_initiative',['sys_admin','summer_admin'])"
    :add_perm="CheckGetPermission('legal_school.add_initiative',['sys_admin','summer_admin'])"
  >
  </DataTable>
   <v-dialog
    v-model="addDialog"
    max-width="700"
    
  >
   <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form" @submit.prevent="is_update?updateItem():saveItem()">
          <VRow class="mt-4" >
            <v-col cols="12" md="10" lg="10" >
              <v-row>
                <VCol class="pt-0 " cols="12" md="6">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="initiative.name_ar"
                      :label="$t('summer.initiative.name')"
                      persistent-hint
                      clearable
                      density="compact"
                      prepend-inner-icon="mdi-handshake"
                      :rules="rules.initiativeNameArRule"
                    ></VTextField>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 " cols="12" md="6">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="initiative.description"
                      :label="$t('summer.initiative.description')"
                      persistent-hint
                      clearable
                      density="compact"
                      prepend-inner-icon="mdi-help-rhombus"
                      :rules="rules.initiativeDescriptionRule"
                    ></VTextField>
                  </VCardItem>
                </VCol>
              </v-row>
            </v-col>
            <v-col cols="12" md="10" lg="10"  class="mt-6">
              <v-row>
                <VCol class="pt-0 " cols="12" md="4">
                  <DatetimePicker
                    type="date"
                    v-model="initiative.time"
                    :rules="[$required]"
                    :label="$t('summer.initiative.time')"
                  />
                </VCol>
                <VCol class="pt-0 " xs="12" md="4">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="initiative.supervisor_name"
                      :label="$t('summer.initiative.supervisor')"
                      persistent-hint
                      clearable
                      prepend-inner-icon="mdi-account-tie"
                      density="compact"
                      :rules="rules.initiativeSupervisorRule"
                    ></VTextField>
                  </VCardItem>
                </VCol>
                <VCol class="pt-0 " xs="12" md="4">
                  <VCardItem class="pa-0">
                    <VTextField
                      v-model="initiative.number"
                      type="number"
                      :label="$t('summer.activity.student_count')"
                      persistent-hint
                      clearable
                      prepend-inner-icon="mdi mdi-numeric"
                      density="compact"
                      :rules="rules.number"
                    ></VTextField>
                  </VCardItem>
                </VCol>
              </v-row>
            </v-col>
            <v-col cols="12" md="10" lg="10"  class="my-6">
              <v-row>
                <VCol cols="12"  xs="12" md="5" lg="12">
                  <VTextarea
                    v-model="initiative.note"
                    clearable
                    :label="$t('summer.initiative.note')"
                    no-resize
                    counter="250"
                    density="compact"
                    rows="2"
                    :rules="rules.note"
                    prepend-inner-icon="mdi-note-outline"
                  ></VTextarea>
                </VCol>
              </v-row>
            </v-col>
          </VRow>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
            {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components:{  
    DataTable
  },
  data() {
    return {
      addDialog:false,
      protected_records: false,
      data_message: [],
      initiatives: [],
      saveDataLoading: false,
      editDataLoading: false,
      deleteDataLoading: false,
      initiative: {
        name_ar: undefined,
        name_en: undefined,
        degree_max: undefined,
        degree_min:undefined,
        note: undefined
      },     
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
     selectedHead: [
        "name_ar",
        "description",
        "time",
        "supervisor_name",
        "number",
        "note",
        "actions",
      ],
      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("summer.initiative.name"), key: "name_ar" },
        { title: this.$t("summer.initiative.description"), key: "description" },
        { title: this.$t("summer.initiative.time"), key: "time" },
        { title: this.$t('summer.activity.student_count'), key: "number" },
        { title: this.$t("summer.initiative.supervisor"),  key: "supervisor_name", },
        { title: this.$t("globals.note"), key: "note", sortable: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
      rules: {
        number: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) => {
            if (value == null || value == undefined)
              return this.$t("globals.required_field");
            if (value.length > 3) {
              return `${this.$t("globals.max_numbers")} 3`;
            }
            return true;
          },
        ],
        initiativeNameArRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) ||
            this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 100) ||
            `${this.$t("globals.biggest_number_of_character")} 100`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        initiativeDescriptionRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) ||
            this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 250) ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        initiativeTimeRule: [
          (value) => !!value || this.$t("globals.required_field"),
        ],
        initiativeSupervisorRule: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            /^[\u0621-\u064A ]+$/.test(value) ||
            this.$t("globals.must_be_letters"),
          (value) =>
            (value && value.length <= 50) ||
            `${this.$t("globals.biggest_number_of_character")} 50`,
          (value) =>
            (value && value.length >= 3) ||
            `${this.$t("globals.lowest_number_of_character")} 3`,
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },      
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    selectedHeaders() {
      return this.headers.filter((header) => this.selectedHead.includes(header.key));
    },
    tableList() {
      if (this.initiatives) {
        return JSON.parse(JSON.stringify(this.initiatives));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getItems();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    checkrole(role=[]) {
        return this.$store.getters.checkrole(role);
    },
    resetForm() {
      this.$refs.form.reset();
      this.is_update = false;
      this.updated_id = undefined;
      this.saveDataLoading = false;
      this.editDataLoading = false;
      this.deleteDataLoading = false;
      this.addDialog = false;
    },
    editItem(data) {
      this.initiative = Object.assign({},data)
      this.is_update = true;
      this.updated_id = data.id;
      this.addDialog = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.del_dialog = false;
    },
    async getItems(page = 1,per_page = 10,ordering = null,search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/initiative`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.initiatives = response.data.results;
        this.pagination = response.data.pagination;
        this.length = response.data.pagination.num_pages;
        this.loading = false;
      });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.initiative.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "/api/summer/initiative", this.initiative, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.non_field_errors) {
              this.$emit("warningAlert", this.$t("summer.already-estimate"));
            }
            else{ 
              this.$emit("errorAlert", this.$t("globals.error_in_data"));
            }
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid && this.initiative.id != undefined) {
        this.editDataLoading = true;
        this.initiative.updated_by = this.user;
        let result = await this.axios
          .put(`${this.base_url}/api/summer/initiative/${this.initiative.id}`, this.initiative, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.name_ar) {
              this.$emit("errorAlert", this.$t("summer.already-governorate"));
            } else if (error.response.data.name_en) {
              this.$emit("warningAlert", this.$t("summer.already-governorate-en"));
            } else this.$emit("warningAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(`${this.base_url}/api/summer/initiative/${delete_id}`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then(() => {
            status = true
          })
          .catch((error) => {
            status = error
          });
        return status
      
      }
    },
  },
  watch: {
    addDialog(val){
      if(!val){
        this.resetForm()
      }
    }
  },
};
</script>
