<template>
  <DataTable
    v-if=" CheckGetPermission('legal_school.view_vacation')"
    :create="
      () => {
        addDialog = true;
      }
    "
    :items="tableList"
    :headers="headers"
    density="compact"
    :method="getItems"
    :pagination="pagination"
    :editItem="editItem"
    :delItem="deleteItem"
    :del_perm="
      CheckGetPermission('legal_school.delete_vacation', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_vacation', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :add_perm="
      CheckGetPermission('legal_school.add_vacation', [
        'sys_admin',
        'gen_admin',
      ])
    "
  >
  </DataTable>
  <v-dialog
    v-model="addDialog"
    max-width="700"
  >
    <v-card flat  :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <v-form
          ref="form"
          @submit.prevent="is_update ? updateItem() : saveItem()"
        >
          <VRow class="mt-2">
            <VCol cols="12" md="6">
              <VTextField
                v-model="vacation.title"
                :label="$t('summer.visitors.occasion')"
                persistent-hint
                autofocus
                counter="50"
                prepend-inner-icon="mdi-calendar-week"
                :rules="rules.title"
              ></VTextField>
            </VCol>
            <VCol cols="12" md="6">
              <VAutocomplete
                :items="calendars"
                v-model="vacation.fk_calendar"
                item-title="date"
                item-value="id"
                :label="$t('summer.calendar.study-day')"
                persistent-hint
                prepend-inner-icon="mdi-calendar-range"
                density="compact"
                :rules="rules.fk_calendar"
              ></VAutocomplete>
            </VCol>
            <VCol cols="12">
              <VTextarea
                v-model="vacation.note"
                :label="$t('summer.initiative.note')"
                no-resize
                counter="250"
                rows="2"
                :rules="rules.note"
                prepend-inner-icon="mdi-note-outline"
              ></VTextarea>
            </VCol>
          </VRow>
        </v-form>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!is_update"
          @click="saveItem()"
          :loading="saveDataLoading"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="is_update"
          @click="updateItem()"
          :loading="editDataLoading"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()" v-if="!is_close">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
        <VBtn class="mx-3" size="small" @click="removeDialog()" v-if="is_close">
          {{ $t("globals.cancel") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import DataTable from "@/components/Globals/DataTable.vue";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      addDialog: false,
      saveDataLoading: false,
      editDataLoading: false,
      vacations: [],
      pagination: {
        count: 0,
        current_page: 1,
        num_pages: 0,
      },
      vacation: {},
      rules: {
        fk_calendar: [(value) => !!value || this.$t("globals.required_field")],
        title: [
          (value) => !!value || this.$t("globals.required_field"),
          (value) =>
            (!!value && value.length <= 50) ||
            this.$t("globals.max_characters"),
        ],
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      v$: useValidate(),
      is_close: false,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      headers: [
        // { title: "", key: "checkbox" },
        { title: this.$t("summer.calendar.study-day"), key: "calendar_date" },
        { title: this.$t("summer.visitors.occasion"), key: "title" },
        { title: this.$t("globals.note"), key: "note" },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    ...mapState({
      calendars: (state) => state.summer.calendars,
      user: (state) => state.User,
      role: (state) => state.role,
    }),
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    ...mapGetters({}),
    tableList() {
      if (this.vacations) {
        return JSON.parse(JSON.stringify(this.vacations));
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      // await this.getItems();
      await this.getCalendars();
      await this.getItems();
      
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    ...mapActions({
      getCalendars: "summer/getCalendars",
    }),
    resetForm() {
      this.$refs.form.resetValidation();
      this.vacation = {};
      this.is_close = false;
      this.is_update = false;
      this.updated_id = undefined;
    },
    removeDialog() {
      this.addDialog = false;
    },
    editItem(vacation) {
      this.addDialog = true;
      this.vacation = vacation;
      this.is_close = true;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async saveItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        this.vacation.data_entry = this.user;
        let result = await this.axios
          .post(this.base_url + "api/summer/vacation", this.vacation, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.vacation = {};
            this.saveDataLoading = false;
            this.resetForm();
          })
          .catch((error) => {
            if (error.response.data.fk_calendar)
              this.$emit("warningAlert", this.$t("summer.already-calender"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.saveDataLoading = false;
          });
        this.getItems();
      }
    },
    async updateItem() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.editDataLoading = true;
        this.vacation.data_entry = this.user;
        await this.axios
          .put(
            this.base_url + "api/summer/vacation/" + this.vacation.id,
            this.vacation,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.editDataLoading = false;
            this.resetForm();
            this.removeDialog();
          })
          .catch((error) => {
            if (error.response.data.fk_calendar)
              this.$emit("warningAlert", this.$t("summer.already-calender"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
            this.editDataLoading = false;
          });
        this.getItems();
      }
    },
    async deleteItem(delete_id) {
      if (delete_id) {
        var status;
        let result = await this.axios
          .delete(this.base_url + "api/summer/vacation/" + delete_id, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then(() => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async getItems(page = 1, per_page = 10, ordering = "id", search = null) {
      this.loading = true;
      await this.axios(`${this.base_url}/api/summer/vacation`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        params: {
          search: search,
          page: page,
          page_size: per_page,
          sort_by: ordering,
        },
      }).then((response) => {
        this.vacations = response.data.results;
        this.pagination = response.data.pagination;
      });
    },
  },
  watch: {
    addDialog(val) {
      if (!val) {
        this.resetForm();
      }
    },
  },
};
</script>

<style>
</style>
