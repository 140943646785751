<template>
  <!-- added by samer -->
  <!-- شاشة  سجل الدروس -->
  <v-form ref="form">
    <v-card class="pa-5">
      <v-row>
        <v-col cols="3" md="3">
          <VAutocomplete
            clearable
            :items="levels"
            v-model="dropList.fk_level"
            item-title="level_with_phase_name"
            item-value="id"
            :label="$t('summer.level.level')"
            persistent-hint
            prepend-inner-icon="mdi-stairs-box"
            density="compact"
            hide-details="auto"
            :rules="[$required]"
            @update:modelValue="
              (dropList.fk_division = undefined),
                (divisions = []),
                (items = []),
                getDivisionByLevel(),this.exist = false
            "
          ></VAutocomplete>
        </v-col>
        <v-col cols="3" md="3">
          <VAutocomplete
            clearable
            :items="divisions"
            v-model="dropList.fk_division"
            item-title="fk_division__name"
            item-value="fk_division__id"
            :label="$t('division.select')"
            persistent-hint
            hide-details="auto"
            prepend-inner-icon="mdi-shape-outline"
            :rules="[$required]"
            density="compact"
            @update:model-value="items = [],this.exist = false"
          ></VAutocomplete>
        </v-col>
        <v-col cols="3">
          <v-autocomplete
            color="indigo"
            v-model="dropList.fk_monthsemester"
            density="compact"
            item-title="month_name"
            item-value="month"
            prepend-inner-icon="mdi-calendar-month"
            :items="months"
            :label="$t('month.select')"
            :rules="[$required]"
            @update:model-value="getCurrentMonth"
          />
        </v-col>
        <v-col cols="2">
          <DatetimePicker
            v-if="show"
            v-model="dropList.date"
            :minDate="minDate"
            :maxDate="maxDate"
            :rules="[$required]"
            :disabled="dropList.fk_monthsemester ? false : true"
            @update:model-value="(recordVisualSubjects = []), (exist = 0)"
            forma="dd"
            readOnly
          />
        </v-col>
        <v-btn
          :text="$t('globals.show')"
          color="primary"
          @click="getData"
          density="comfortable"
          class="mt-2"
          append-icon="mdi-presentation"
          :loading="btn_loading"
        />
      </v-row>
    </v-card>
  </v-form>
  <v-card v-if="this.exist == true"> 
    <v-data-table-virtual
      v-if="CheckGetPermission('legal_school.view_lecturelog')"
      :items="items"
      :headers="headers"
      density="compact"
      :loading="loading"
    >
      <template v-slot:header.is_done="{ column }">
        <v-checkbox
          v-model="selected"
          @change="checkAll(selected, i - 1)"
          :indeterminate="indeterminate()"
          :label="column.title"
          hide-details
        />
      </template>
      <template v-slot:item.is_done="{ item }">
        <v-checkbox-btn v-model="item.is_done" @change="checked(index)" />
      </template>
    </v-data-table-virtual>

    <v-btn
      class="ma-3"
      v-if="items.length > 0"
      :text="$t('globals.save')"
      color="primary"
      @click="saveData"
      :loading="save_loading"
    />
  </v-card>
</template>
<script>
import DataTable from "@/components/Globals/DataTable.vue";
import moment from "moment-hijri";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      dropList: {},
      items: [],
      months: [],
      levels: [],
      divisions: [],
      exist: false,
      show: true,
      loading: false,
      save_loading: false,
      selected: false,
      btn_loading: false,

      minDate: null,
      maxDate: null,
    };
  },
  created() {
    this.getMonths();
    this.getLevels();
  },

  methods: {
    checkAll(value) {
      this.items.map((e) => {
        e.is_done = value;
      });
    },
    checked() {
      this.selected = this.items.every((e) => e.is_done === true);
    },
    indeterminate() {
      return (
        this.items.some((e) => e.is_done === true) &&
        !this.items.every((e) => e.is_done === true)
      );
    },
    getCurrentMonth() {
      this.recordVisualSubjects = [];
      this.show = false;
      this.dropList.date = null;
      const month =
        this.dropList.fk_monthsemester < 10
          ? "0" + this.dropList.fk_monthsemester.toString()
          : this.dropList.fk_monthsemester;
      const now = moment();
      const startOfMonth = now.iYear() + "-" + month + "-01";
      const endOfMonth =
        now.iYear() +
        "-" +
        this.dropList.fk_monthsemester +
        "-" +
        now.endOf("iMonth").iDate();
      this.minDate = startOfMonth;
      this.maxDate = endOfMonth;
      this.dropList.date = this.minDate;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async getData() {
      this.items = [];
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.exist = false;
        this.loading = true;
        this.btn_loading = true;
        
        await this.axios(`${this.base_url}/api/lecture-logs/`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            fk_monthsemester: this.months.find(
              (e) => e.month == this.dropList.fk_monthsemester
            ).id,
            date: this.dropList.date,
            fk_level: this.dropList.fk_level,
            fk_division: this.dropList.fk_division,
          },
        })
          .then((response) => {
            this.items = response.data;
            if (response.data.length == 0) {
              this.$emit("warningAlert", this.$t("globals.not-found"));
            } else {
              this.exist = true;
            }
            this.checked();
          })
          .catch((e) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      } else this.items = [];

      this.loading = false;
      this.btn_loading = false;
    },

    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.dropList.fk_monthsemester = current_month.month;
            this.getCurrentMonth();
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async saveData() {
      this.save_loading = true;
      const data = this.items.map((e) => {
        return {
          ...e,
          fk_monthsemester: this.months.find(
            (e) => e.month == this.dropList.fk_monthsemester
          ).id,
          date: this.dropList.date,

          fk_division: this.dropList.fk_division,
        };
      });

      await this.axios
        .post(`${this.base_url}/api/lecture-logs/`, data, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((e) => {
          this.getData();
          this.$emit("successAlert", this.$t("globals.data_updated"));
        })
        .catch((e) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
      this.save_loading = false;
    },

    async getLevels() {
      await this.axios
        .get(`${this.base_url}api/summer/level`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((response) => {
          this.levels = response.data.results;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getDivisionByLevel() {
      await this.axios(`${this.base_url}api/division-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        params: {
          fk_level: this.dropList.fk_level,
        },
      })
        .then((response) => (this.divisions = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
    headers() {
      return [
        {
          title: this.$t("lecture.name"),
          key: "name",
          sortable: false,
        },
        { title: this.$t("globals.executed"), key: "is_done", sortable: false },
        { title: this.$t("globals.note"), key: "note", sortable: false },
      ];
    },
  },
};
</script>

