`<template>
  <!-- added by samer -->
  <!-- شاشة الانشطة -->
    <v-card class="pa-5 mb-2">
      <v-card-panel-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-card-panel-title>
      <v-card-panel-text>
        <VRow class="mt-1">
          <VCol cols="12" xs="12" sm="12" md="3" lg="3">
            <VAutocomplete
              :items="activityType"
              v-model="filter.fk_activity_type"
              item-title="name"
              item-value="id"
              :label="$t('summer.activity.type')"
              persistent-hint
              prepend-inner-icon="mdi-soccer"
              density="compact"
              clearable
            ></VAutocomplete>
          </VCol>
          <VCol cols="12" xs="12" sm="12" md="3" lg="3">
            <VAutocomplete
              :items="iterations"
              v-model="filter.iteration"
              item-title="name"
              item-value="id"
              :label="$t('activity.iteration')"
              persistent-hint
              prepend-inner-icon="mdi-refresh"
              density="compact"
              clearable
            >
            </VAutocomplete>
          </VCol>
          <v-col cols="4" md="1" class="mt-2">
            <v-btn
              @click="getData()"
              class="bg-primary"
              density="comfortable"               
              append-icon="mdi-presentation"
            >
              <span class="text-white">
                {{ $t("globals.show") }}
              </span>
            </v-btn>
          </v-col>
        </VRow>
      </v-card-panel-text>
    </v-card>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_activitysummer')"
    :items="items"
    :headers="headers"
    density="compact"
    :method="getData"
    :create="
      () => {
        dialog = true;
      }
    "
    :pagination="pagination"
    :editItem="editData"
    :delItem="delData"
    :del_perm="
      CheckGetPermission('legal_school.delete_activitysummer', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_activitysummer', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :add_perm="
      CheckGetPermission('legal_school.add_activitysummer', [
        'sys_admin',
        'gen_admin',
      ])
    "
  > 
    <template v-slot:item.status="{ item }">
      <span>
        <v-icon v-if="item.status == true" color="success"
          >mdi-check-circle</v-icon
        >
        <v-icon v-if="item.status == false" color="error"
          >mdi-close-circle</v-icon
        >
      </span>
    </template>
  </DataTable>
  <v-dialog v-model="dialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <VForm ref="form">                 
          <custom-fields :data="dropList" :fields="fields">
          <template v-slot = "{field}">                                        
            <VAutocomplete
              v-if="field.name == 'fk_activity_type'"
              :items="activityType"
              v-model="dropList[field.name]"
              item-title="name"
              item-value="id"
              :label="$t('summer.activity.type')"
              persistent-hint
              prepend-inner-icon="mdi-soccer"
              density="compact"
              clearable
              :rules="[$required]"
              
            />  
          </template>
          </custom-fields>
        </VForm>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!dropList.id"
          @click="saveData()"
          :loading="loading_btn"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="dropList.id"
          @click="updateData()"
          :loading="loading_btn"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="resetForm()">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>  
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      dropList: {},
      filter: {},
      pagination: {},
      items: [],
      iterations: [],
      activityType: [],
      fields: [],
      loading_btn: false,
      dialog: false,
      headers: [
        { title: this.$t("summer.activity.type"), key: "activity_type_name" },
        { title: this.$t("summer.activity.name"), key: "name" },
        { title: this.$t("summer.activity.evaluation_act"), key: "evalution", show: false,},
        { title: this.$t("summer.activity.student_count"),key: "student_number", show: false, },
        { title: this.$t("activity.goal"), key: "goal" },
        { title: this.$t("activity.iteration"), key: "iteration_name" },
        { title: this.$t("summer.status"), key: "status" },
        { title: this.$t("activity.school-recommendation"),key: "school_recommendation", },
        { title: this.$t("activity.media-recommendation"),key: "media_recommendation",show: false,},
        { title: this.$t("activity.execution"), key: "execution" },
        { title: this.$t("activity.participating-group"),key: "participating_group",show: false},
        { title: this.$t("activity.entity-to-coordinate"),key: "entity_to_coordinate",show: false },
        { title: this.$t("globals.note"), key: "note", show: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  async created() {
    this.getIterations();
    this.getActivityType();
  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  methods: {
    resetForm(){
      this.dropList = {}
    },
    async getData(page = 1, per_page = 10, ordering = "id", search = null) {
      if(ordering == 'activity_type_name') ordering = 'fk_activity_type__name'
      else if(ordering == '-activity_type_name') ordering = '-fk_activity_type__name'
      this.loading_btn = true;
      await this.axios
        .get(`${this.base_url}api/summer/activity/`, {
          params: {        
            fk_activity_type: this.filter.fk_activity_type,
            iteration: this.filter.iteration,
            search: search,
            page: page,
            page_size: per_page,
            ordering: ordering,
          },
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        })
        .then((response) => {
          this.items = response.data.results;
          this.fields = response.data.fields;
          this.pagination = response.data.pagination;
        });
      this.loading_btn = false;
    },
    editData(data) {
      this.dropList = data
      this.dialog = true;
    },
    async delData(delete_id) {
      if (delete_id) {
        var status;
        await this.axios
          .delete(`${this.base_url}api/summer/activity/${delete_id}/`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((responce) => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
    async saveData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading_btn = true;
        await this.axios
          .post(this.base_url + "api/summer/activity/", this.dropList, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.dropList = {}
            this.getData()
          })
          .catch((error) => {
            if (error.response.data.name)
              this.$emit("errorAlert", this.$t("summer.already-visitor"));
            else this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.loading_btn = false;
      }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading_btn = true;
        await this.axios
          .put(
            this.base_url + `api/summer/activity/${this.dropList.id}/`,
            this.dropList,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.dialog = false
            this.getData()

          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.loading_btn = false;
      }
    },
    async getIterations() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "IterationActivityChoice",
          choices: true,
        })
        .then((response) => {
          this.iterations = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getActivityType() {
      await this.axios
        .post(`${this.base_url}/api/choices`, {
          model: "ActivityType",
          fields: ["id", "name"],
        })
        .then((response) => {
          this.activityType = response.data;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.dropList = {};
      }
    },
  },
};
</script>

