<template>
  <v-card class="mb-2">
      <v-card-title class="px-4">
        <span>{{ $t("globals.select-criteria") }}</span>
      </v-card-title>
      <v-card-text>
        <v-row class="py-2">
          <v-col cols="3" md="3">
              <VAutocomplete
                :items="semesters"
                v-model="filter.fk_semester"
                prepend-inner-icon="mdi-calendar-month"
                item-title="name_ar"
                item-value="id"
                :label="$t('semester.semester')"
                clearable
                hide-details="auto"
                persistent-hint
                density="compact"
              ></VAutocomplete>
          </v-col>
          <v-col cols="6" md="3" sm="4">
            <VAutocomplete
              clearable
              :items="levels"
              v-model="filter.fk_level"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.level')"
              persistent-hint
              prepend-inner-icon="mdi-stairs-box"
              density="compact"
              hide-details="auto"
              :rules="[$required]"
              @update:model-value="filter.fk_subject = undefined"
            ></VAutocomplete>
          </v-col>

          <v-col cols="6" md="3" sm="4">
            <v-autocomplete
              color="primary"
              v-model="filter.fk_subject"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="mdi-bookshelf"
              clearable
              hide-details="auto"
              :items="subjects.filter((e) => e.fk_level == filter.fk_level)"
              :label="$t('subject.select')"
            />
          </v-col>
          <v-col cols="6" md="1" class="mt-2">
            <v-btn
              @click="getData()"
              :loading="loading"
              class="bg-primary"
              density="comfortable"               
              append-icon="mdi-presentation"
            >
              <span class="text-white">
                {{ $t("globals.show") }}
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
  </v-card>
  <DataTable
    v-if="CheckGetPermission('legal_school.view_lecturesummer')"
    :create="
      () => {
        dialog = true;
      }
    "
    :items="items"
    :headers="headers"
    density="compact"
    :method="getData"
    :pagination="pagination"
    :editItem="editData"
    :delItem="deleteData"
    :del_perm="
      CheckGetPermission('legal_school.delete_lecturesummer', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :edit_perm="
      CheckGetPermission('legal_school.change_lecturesummer', [
        'sys_admin',
        'gen_admin',
      ])
    "
    :add_perm="
      CheckGetPermission('legal_school.add_lecturesummer', [
        'sys_admin',
        'gen_admin',
      ])
    "
  />

  <v-dialog v-model="dialog" max-width="700">
    <v-card flat :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
      <v-card-text class="pb-0">
        <v-form ref="form">
          <v-row class="mt-2">
            <v-col cols="12" md="6">
              <v-text-field
                color="primary"
                v-model="dropList.name"
                counter="100"
                autofocus
                prepend-inner-icon="mdi-format-text"
                :label="$t('lecture.name')"
                :rules="[$required]"
              />
            </v-col>
            <VCol cols="12" md="6">
              <VAutocomplete
                :items="semesters"
                v-model="dropList.fk_semester"
                prepend-inner-icon="mdi-calendar-month"
                item-title="name_ar"
                item-value="id"
                :label="$t('semester.semester')"
                clearable
                hide-details="auto"
                persistent-hint
                density="compact"
                :rules="[$required]"
              ></VAutocomplete>
            </VCol>
            <v-col cols="6" md="6" sm="6">
              <VAutocomplete
                clearable
                :items="levels"
                v-model="dropList.fk_level"
                item-title="name_ar"
                item-value="id"
                :label="$t('summer.level.level')"
                persistent-hint
                prepend-inner-icon="mdi-stairs-box"
                density="compact"
                hide-details="auto"
                :rules="[$required]"
                @update:model-value="dropList.fk_levelsubject = undefined"
              ></VAutocomplete>
            </v-col>
            <v-col cols="6" md="6" sm="6">
              <v-autocomplete
                v-model="dropList.fk_levelsubject"
                item-title="name"
                item-value="id"
                prepend-inner-icon="mdi-bookshelf"
                clearable
                :items="subjects.filter((e) => e.fk_level == dropList.fk_level)"
                :label="$t('subject.select')"
                :rules="[$required]"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                color="primary"
                v-model="dropList.note"
                hide-details="auto"
                counter="250"
                rows="3"
                prepend-inner-icon="mdi-note-outline"
                :label="$t('globals.note')"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <VCardActions class="mx-4">
        <VBtnSave
          v-if="!dropList.id"
          @click="saveDate()"
          :loading="loading_btn"
        >
          {{ $t("globals.add") }}
        </VBtnSave>
        <VBtnUpdate
          v-if="dropList.id"
          @click="updateData()"
          :loading="loading_btn"
        >
          {{ $t("globals.edit") }}
        </VBtnUpdate>
        <VBtn class="mx-3" size="small" @click="dropList = {}">
          {{ $t("globals.clear") }}
          <VIcon icon="mdi-broom" color="golden" end></VIcon>
        </VBtn>
      </VCardActions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "LecturesList",

  data() {
    return {
      dropList: {},
      filter: {},
      pagination: {},

      items: [],
      levels: [],
      subjects: [],

      subjects_filter: [],

      dialog: false,
      loading: false,
      loading_btn: false,
      headers: [
        { title: this.$t("lecture.name"), key: "name" },
        { title: this.$t("semester.semester"), key: "semester_name" },
        { title: this.$t("summer.level.name_ar"), key: "level_name" },

        { title: this.$t("subject.name"), key: "subject_name" },

        { title: this.$t("globals.note"), key: "note", show: false },
        { title: this.$t("globals.actions"), key: "actions", sortable: false },
      ],
    };
  },
  async created() {
    this.getSemester();
    this.getLevels();
    this.getAllSubjects();
  },
  computed: {
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },

  methods: {
    async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
      const s = this.semesters.filter((e)=>e.current_semester==true);
      this.dropList.fk_semester = s[0].id;
      this.filter.fk_semester = s[0].id;
    },

    async getLevels() {
      await this.axios(`${this.base_url}api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => (this.levels = response.data))
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    async getAllSubjects() {
      await this.axios(`${this.base_url}/api/summer/subjects-by-level`, {
        params: {
          level: "all",
        },
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.subjects = response.data;
      });
    },

    async getData(page = 1, per_page = 10, ordering = "id", search = null) {
      this.loading = true;
      await this.axios(
        `${this.base_url}api/summer/lecture-by-subject-and-level/`,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          params: {
            search: search,
            page: page,
            page_size: per_page,
            fk_semester: this.filter.fk_semester,
            level: this.filter.fk_level,
            subject: this.filter.fk_subject,
            ordering: ordering,
          },
        }
      )
        .then((response) => {
          this.items = response.data.results;
          this.pagination = response.data.pagination;
          this.loading = false;
        })
        .catch((error) => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    editData(data) {
      this.dropList = { ...data };
      this.dialog = true;
    },
    async saveDate() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading_btn = true;
        await this.axios
          .post(
            this.base_url + "api/summer/lecture-by-subject-and-level/",
            this.dropList,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.dropList = {};
            this.getData();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.loading_btn = false;
      }
    },
    async updateData() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        await this.axios
          .put(
            this.base_url +
              "api/summer/lecture-by-subject-and-level/" +
              this.dropList.id +
              "/",
            this.dropList,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.dialog = false;
            this.getData();
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.title"));
          });
      }
    },
    async deleteData(delete_id) {
      if (delete_id) {
        var status;
        await this.axios
          .delete(
            this.base_url +
              "api/summer/lecture-by-subject-and-level/" +
              delete_id +
              "/",
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then((e) => {
            status = true;
          })
          .catch((error) => {
            status = error;
          });
        return status;
      }
    },
  },
  watch: {
    dialog() {
      if (!this.dialog) this.dropList = {};
    },
  },
};
</script>