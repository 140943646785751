<template>
  <v-form ref="form">
    <v-card class="pa-2">
      <v-card-title>
        {{ $t("globals.select-criteria") }}
      </v-card-title>
      <v-card-text class="mt-3">
        <v-row>
          <VCol cols="3" class="mb-1">
            <VAutocomplete
              :items="semesters"
              v-model="filter_data.fk_semester"
              prepend-inner-icon="mdi-book-open"
              item-title="name_ar"
              item-value="id"
              :label="$t('semester.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
              @update:modelValue="
                getMonthsBySemester(),
                  (this.filter_data.fk_monthsemester = null),
                  (this.exist = false)
              "
              :rules="[$required]"
            ></VAutocomplete>
          </VCol>
          <v-col cols="3">
            <v-autocomplete
              color="indigo"
              v-model="filter_data.fk_monthsemester"
              density="compact"
              item-title="month_name"
              item-value="month"
              prepend-inner-icon="mdi-calendar-month"
              :items="months"
              :label="$t('month.select')"
              :rules="[$required]"
              @update:modelValue="(this.exist = false)"              
            />
          </v-col>
          <VCol cols="3" class="mb-1">
            <VAutocomplete
              :items="levels"
              v-model="filter_data.fk_level"
              prepend-inner-icon="mdi-stairs-box"
              item-title="name_ar"
              item-value="id"
              :label="$t('summer.level.name_ar')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
              :rules="[$required]"
              @update:modelValue="
                getDivisionByLevel(),
                  (this.filter_data.fk_division = null),
                  (this.exist = false)
              "
            ></VAutocomplete>
          </VCol>
          <VCol cols="3" class="mb-1">
            <VAutocomplete
              :items="divisions"
              v-model="filter_data.fk_division"
              prepend-inner-icon="mdi-shape-outline"
              item-title="name"
              item-value="fk_division"
              :label="$t('division.name')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
              @update:modelValue="(this.exist = false)"              
            ></VAutocomplete>
          </VCol>
          <VCol cols="3" class="mb-1">
            <VAutocomplete
              :items="persnoalEvaluations"
              v-model="filter_data.fk_TypeOfPersnoalEvaluation"
              prepend-inner-icon="mdi-star"
              item-title="name"
              item-value="id"
              :label="$t('school.types_personal')"
              clearable
              hide-details="auto"
              persistent-hint
              density="compact"
              :rules="[$required]"
              @update:modelValue="(this.exist = false)"
            ></VAutocomplete>
          </VCol>
          <v-col cols="1" class="mt-2">
            <v-btn
              density="comfortable"
              append-icon="mdi-presentation"
              class="w-100 text-white"
              color="primary"
              :loading="btn_loading"
              @click="getItems()"
            >
              <span>{{ $t("globals.show") }}</span>
            </v-btn>
          </v-col>
        </v-row>
            <VCol cols="12" class="mt-5">
              <v-text-field
                color="primary"
                v-model="search_student"
                @keyup.enter="getItems"
                density="compact"
                hide-details="auto"
                prepend-inner-icon="mdi-magnify"
                :label="$t('globals.student-search')"
              />
            </VCol>
        <v-table
          density="compact"
          class="rounded elevation-1 striped-table mt-5"
          fixed-header
          :height="months?.length > 10 ? 400 : null"
          v-if="exist"
        >
          <thead>
            <tr>
              <th>البيانات</th>
              <template
                v-for="header in list_personalEvaluation_header"
                :key="header.typeOfPersonal_name"
              >
                <template v-if="header.list_typeOfPersonal">
                  <th
                    class="text-center"
                    :colspan="header.list_typeOfPersonal?.length || 1"
                  >
                    {{ header.typeOfPersonal_name }}
                  </th>
                </template>
              </template>
            </tr>
            <tr>
              <th>{{ $t("school.student_name") }}</th>
              <template
                v-for="header in list_personalEvaluation_header"
                :key="header.typeOfPersonal_name"
              >
                <template v-if="header.list_typeOfPersonal">
                  <th
                    v-for="personal in header.list_typeOfPersonal"
                    :key="personal.personal_evaluation_name"
                  >
                    <span v-if="personal.personal_evaluation_name">
                      {{ personal.personal_evaluation_name }}
                    </span>
                    <span v-else>{{ personal.total }}</span>
                  </th>
                </template>
              </template>
              <th class="text-center">
                {{ $t("school.notes") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(student, wIndex) in list_students" :key="wIndex">
              <td>{{ student.student_name }}</td>
              <template
                v-for="type in student.list_typeOfPersonal"
                :key="type.typeOfPersonal_name"
              >
                <td
                  v-for="personal in type.list_personalEvaluation"
                  :key="personal.personal_evaluation_name"
                >
                  <VTextField
                    v-if="personal.personal_evaluation_name"
                    v-model="personal.degree"
                    density="compact"
                    type="number"
                    @input="personal.degree = parseInt(personal.degree)"
                    :rules="[$max_length(2), $max_value(10)]"
                    style="width: 70px"
                  >
                  </VTextField>

                  <span v-else>{{ personal.total }} </span>
                </td>
              </template>
              <!-- <td>{{ student.all_total_personal_for_student }}</td> -->
              <v-hover v-slot:default="{ isHovering, props }">
                <td v-bind="props">
                  <v-icon
                    :icon="isHovering ? 'mdi-plus-circle' : 'mdi-alert-circle'"
                    :color="'primary'"
                    @click="student.dialogs = true"
                  />
                  <v-tooltip
                    v-model="student.dialog"
                    activator="parent"
                    location="top"
                    theme="dark"
                    v-if="student.list_notefollwed.length > 0"
                  >
                    <v-sheet width="500" class="bg-transparent">
                      <v-label :text="$t('school.notes')" />
                      <v-data-table
                        :headers="[
                          { title: this.$t('user.user'), key: 'data_entry' },
                          {
                            title: this.$t('school.notes'),
                            key: 'note',
                          },
                        ]"
                        :items="student.list_notefollwed"
                        class="bg-transparent"
                      >
                        <template #bottom></template
                      ></v-data-table>
                    </v-sheet>
                  </v-tooltip>
                  <v-dialog
                    height="500"
                    width="500"
                    v-model="student.dialogs"
                    :close-on-content-click="(note = undefined)"
                    :open-on-click="(note = undefined)"
                  >
                    <v-card
                      flat
                      :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                      height="500"
                      width="500"
                    >
                      <v-card-text>
                        <v-col>
                          <v-row class="text-center justify-center ma-0">
                            <v-label
                              :text="`${$t('school.student_name')} :  `"
                            />
                            <td>{{ student.student_name }}</td>
                          </v-row>
                          <v-row style="align-items: center">
                            <VTextarea
                              v-model="note"
                              clearable
                              prepend-inner-icon="mdi-note-outline"
                              :label="$t('globals.note')"
                              counter="250"
                              density="compact"
                              rows="3"
                            />
                            <VBtnSave
                              @click="
                                note
                                  ? student.list_notefollwed.push({
                                      note: note,
                                      fk_levelstudentyear:
                                        student.fk_levelstudentyear,
                                      fk_monthsemester:
                                        filter_data.fk_monthsemeste
                                    })
                                  : ''
                              "
                              :loading="saveDataLoading"
                              class="ma-5"
                            >
                              {{ $t("globals.add") }}
                            </VBtnSave>
                          </v-row>
                          <v-data-table
                            :headers="[
                              {
                                title: this.$t('user.user'),
                                key: 'data_entry',
                              },
                              {
                                title: this.$t('school.notes'),
                                key: 'note',
                              },
                              {
                                title: this.$t('globals.actions'),
                                key: 'actions',
                                sortable: false,
                              },
                            ]"
                            :items="student.list_notefollwed"
                            class="bg-transparent"
                          >
                            <template v-slot:item.note="{item}">

                              <v-text-field v-model="item.note"> </v-text-field>
                            </template>

                            <template v-slot:item.actions="{ index }">
                              <span>
                                
                                <v-btn
                                  variant="text"
                                  size="small"
                                  class="v-btn-icon"
                                  @click="
                                    student.list_notefollwed.splice(index, 1)
                                  "
                                >
                                  <v-icon color="error"
                                    >mdi-trash-can-outline</v-icon
                                  >
                                </v-btn>
                              </span>
                            </template>
                            <template #bottom></template
                          ></v-data-table>
                        </v-col> </v-card-text
                    ></v-card>
                  </v-dialog>
                </td>
              </v-hover>
            </tr>
          </tbody>
          <v-card-actions class="mx-2 pt-0">
            <v-btn
              @click="saveItem"
              :loading="saveDataLoading"
              class="bg-primary"
              size="small"
            >
              <span class="text-white">
                {{ $t("globals.save") }}
              </span>
              <v-icon icon="mdi-content-save" color="white" end></v-icon>
            </v-btn>
          </v-card-actions>
        </v-table>
      </v-card-text>
    </v-card>
  </v-form>
</template>
<script>
import { mapState, mapActions } from "vuex";
import DataTable from "@/components/Globals/DataTable.vue";
import moment from "moment-hijri";

export default {
  components: {
    DataTable,
  },
  data() {
    return {
      btn_loading: false,
      list_students: [],
      list_personalEvaluation_header: [],
      divisions: [],
      levels: [],
      semesters: [],
      months: [],
      show: true,
      filter_data: {},
      monthsemester: null,
      exist: undefined,
      persnoalEvaluations: [],
      saveDataLoading: false,
      note:undefined
   
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    tableList() {
      if (this.list_students) {
        return JSON.parse(JSON.stringify(this.list_students));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getMonths();
      await this.getSemester();
      await this.getPersnoalEvaluation();
      await this.getlevels();
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
 
  methods: {
      getTotal(obj){
        let sum = obj.attendance + obj.assigments + obj.oral + obj.behaviour_charity + obj.monthly_activity;
        return sum;
      },    
    addNote(student) {
      student.list_notefollwed.push({
        fk_levelstudentyear: student.fk_levelstudentyear,
        note: "",
      });
    },
    getTotal(obj) {
      let sum = obj.degree;
      return sum;
    },
    async getDivisionByLevel() {
      if (this.filter_data.fk_level) {
        let levelDivisions = await this.axios.get(
          this.base_url +
            `api/summer/get-level-division/${this.filter_data.fk_level}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.divisions = levelDivisions.data.division;
      }
    },

    async getPersnoalEvaluation() {
      let semester = await this.axios.get(
        this.base_url + `/api/type-of-persnoal-evaluation/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.persnoalEvaluations = semester.data;
    },
    async getSemester() {
      let semester = await this.axios.get(
        this.base_url + `api/summer/semesters/list/`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      );
      this.semesters = semester.data;
    },
    async getMonthsBySemester() {
      if(this.filter_data.fk_semester)
      await this.axios(
        `${this.base_url}/api/months-semester/?fk_semester=${this.filter_data.fk_semester}`,
        {
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
        }
      )
        .then((response) => {
          this.months = response.data;
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
      else this.months =[] 
    },
    getCurrentMonth() {
      this.list_students = [];
      this.show = false;
      this.filter_data.date = null;
      const month =
        this.filter_data.fk_monthsemester < 10
          ? "0" + this.filter_data.fk_monthsemester.toString()
          : this.filter_data.fk_monthsemester;
      const now = moment();
      const startOfMonth = now.iYear() + "-" + month + "-01";
      const endOfMonth =
        now.iYear() +
        "-" +
        this.filter_data.fk_monthsemester +
        "-" +
        now.endOf("iMonth").iDate();
      this.minDate = startOfMonth;
      this.maxDate = endOfMonth;
      this.filter_data.date = this.minDate;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async getMonths() {
      await this.axios(`${this.base_url}/api/month-choices/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      })
        .then((response) => {
          this.months = response.data;
          const current_month = response?.data?.find((e) => e.current_month);
          if (current_month) {
            this.filter_data.fk_monthsemester = current_month.month;
            this.filter_data.fk_semester = current_month.fk_semester;
            this.getCurrentMonth();
          }
        })
        .catch(() => {
          this.$emit("errorAlert", this.$t("globals.error_in_data"));
        });
    },
    checkrole(role = []) {
      return this.$store.getters.checkrole(role);
    },
    async getlevels() {
      let levels = await this.axios.get(this.base_url + `api/level-choices`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.levels = levels.data;
    },
    async getItems() {
      
      this.exist = undefined;
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.btn_loading = true;
        await this.axios(
          `${this.base_url}api/personal-evaluation-student-degree/`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            params: {
              fk_level: this.filter_data.fk_level,
              search:this.search_student,
              fk_division: this.filter_data.fk_division,
              fk_monthsemester: this.months.find(
        (month) => month.month === this.filter_data.fk_monthsemester
      ).id,
              fk_TypeOfPersnoalEvaluation:
                this.filter_data.fk_TypeOfPersnoalEvaluation,
            },
          }
        )
          .then((response) => {
            this.list_students = response.data.list_students;
            this.list_personalEvaluation_header = response.data.list_personalEvaluation_header;
            if(this.list_students.length === 0){
              this.$emit("infoAlert", this.$t("globals.not-found"));
            } else {
              this.exist = true;
            }
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
        this.loading = false;
        this.btn_loading = false;
      }
    },
    async saveItem() {
      const combinedNote = this.list_students.flatMap(
        (student) => student.list_notefollwed
      );
      const filteredNote = combinedNote.map((item) => {
        if (item.note === "") {
          return { ...item, note: null ,fk_monthsemester:this.filter_data.fk_monthsemester};
        }
        return {...item,fk_monthsemester:this.filter_data.fk_monthsemester};
      });

      const combinedEvaluation = this.list_students.flatMap((student) =>
        student.list_typeOfPersonal.flatMap(
          (type) => type.list_personalEvaluation
        )
      );
      const filteredEvaluation = combinedEvaluation.filter(
        (item) => !item.hasOwnProperty("total")
      );

      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.saveDataLoading = true;
        await this.axios
          .post(
            this.base_url + `api/personal-evaluation-student-degree/`,
            {
              list_personalEvaluation: filteredEvaluation,
              list_notefollwed: filteredNote,
              fk_monthsemester:this.months.find(
        (month) => month.month === this.filter_data.fk_monthsemester
      ).id
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            }
          )
          .then(() => {

            this.getItems()
            this.$emit("successAlert", this.$t("globals.data_added"));
                     
          })
          .catch((error) => {    
              if (error.response.data[0]) {
                this.$emit("warningAlert", error.response.data[0]);
              } else {
                this.$emit("errorAlert", this.$t("globals.error_in_data"));
              }     
            
          });
            this.saveDataLoading = false;
      }
    },
  },
};
</script>
