<template>
<v-dialog
    v-model="value"
    transition="dialog-top-transition"
    scrollable
    width="350"
    elevation="12"
  >
    <v-card
        max-width="600"
        elevation="10"
        rounded="lg"
        color="white"
        width="100%"
        class="pa-4 text-center mx-auto"
    >
        <div class="d-flex align-center">

            <div style="flex:1">
                <v-icon color="icon-color" size="60">mdi-server-network</v-icon>
            </div>
            <div  class="d-table">
                <span class="d-block ">{{$t('request.request')}}</span>
                <span class="d-block text-success text-h4 ">-----------------------</span>
                <span class="d-block text-error text-h4">- - - - - - - - - - - - -</span>
                <span class="d-block">{{$t('request.response')}} </span>
            </div>
            <div style="flex:1">
                <v-icon color="primary" size="40">mdi-laptop</v-icon>
            </div>
        </div>
        <h3>
            {{$t('request.timeout')}}
        </h3>
        <v-btn density="compact" @click="cancelRequest"  class="mt-2" rounded="lg" color="primary" >{{$t('globals.cancel')}}</v-btn>
    </v-card>
  </v-dialog>

</template>

<script>
import store from '@/store'

export default{
    props:{
        customFunction:{
            type:Function,
            required:true
        },
        modelValue:{
            type:Boolean,
            default: false,
        },
    },
    computed:{
        value: {
            get(){
                return this.modelValue
            },
            set(value){
                this.$emit('update:modelValue',value)
            }
        },
    },
    methods:{
        cancelRequest(){
            store.commit('setTimeoutAlert',false)
        }
    },
    watch:{
        value(val){
            if(val==false){
                this.cancelRequest()
            }
        }
    }
    
}


</script>