<template>
  <VCard class="mb-2 pa-0">
    <v-card-text class="pb-0">
      <VForm ref="form" class="" @submit.prevent="savevisitor">
        <VRow class="mt-2">
          <VCol cols="12" md="9">
            <VCol class="pt-0 px-3" cols="12">
              <VCardItem class="pa-0">
                <VTextField
                  v-model="visitor.name_ar"
                  :label="$t('summer.visitors.name-visitor')"
                  persistent-hint
                  clearable
                  density="compact"
                  prepend-inner-icon="mdi-account"
                  :rules="[$required, $ar_letters_only]"
                ></VTextField>
              </VCardItem>
            </VCol>
            <VRow class="mt-2">
              <VCol class="pt-0 px-3" cols="12" xs="10" md="6" lg="6">
                <VCardItem class="pa-0">
                  <v-select
                    v-model="visitor.entity"
                    :label="$t('summer.visitors.entity-visitor')"
                    persistent-hint
                    item-title="name"
                    item-value="id"
                    clearable
                    density="compact"
                    :items="dir_visitors"
                    prepend-inner-icon="mdi-account"
                    :rules="[$required]"
                  ></v-select>
                </VCardItem>
              </VCol>
              <VCol class="pt-0 px-3" cols="12" xs="10" md="6" lg="6">
                <VAutocomplete
                  :items="semesters"
                  v-model="visitor.fk_semester"
                  prepend-inner-icon="mdi-stairs-box"
                  item-title="name_ar"
                  item-value="id"
                  :label="$t('semester.name')"
                  clearable
                  hide-details="auto"
                  persistent-hint
                  density="compact"
                  @update:modelValue="
                    getMonthsBySemester(), (this.visitor.fk_monthsemester = null)
                  "
                  :rules="[$required]"
                ></VAutocomplete>
              </VCol>
              <VCol class="pt-0 px-3" cols="12" xs="10" md="6" lg="6">
                <VAutocomplete
                  color="indigo"
                  v-model="visitor.fk_monthsemester"
                  density="compact"
                  item-title="month_name"
                  item-value="id"
                  prepend-inner-icon="mdi-calendar-month"
                  :items="months"
                  :label="$t('month.select')"
                  :rules="[$required]"
                  @update:model-value="getCurrentMonth"
                ></VAutocomplete>
              </VCol>
              <VCol cols="12" xs="12" sm="12" md="6" lg="6">
                <DatetimePicker
                  v-if="show"
                  v-model="visitor.date"
                  :minDate="minDate"
                  :maxDate="maxDate"
                  :rules="[$required]"
                  :disabled="visitor.fk_monthsemester ? false : true"
                  @update:model-value="recordVisualSubjects = []"
                  forma="dd"
                  readOnly
                />
              </VCol>
            </VRow>
            <VRow class="mt-2">
              <VCol class="pt-0 px-3" cols="12" xs="10" md="6" lg="6">
                <VCardItem class="pa-0">
                  <VTextarea
                    v-model="visitor.subject_submitted_to_school"
                    clearable
                    :label="$t('summer.visitors.title-to-school')"
                    rows="2"
                    persistent-hint
                    density="compact"
                    prepend-inner-icon="mdi-account-group"
                    :rules="[$max_length(100), $required]"
                  ></VTextarea>
                </VCardItem>
              </VCol>
              <VCol class="pt-0 px-3" cols="12" xs="10" md="6" lg="6">
                <VCardItem class="pa-0">
                  <VTextarea
                    v-model="visitor.evaluation"
                    clearable
                    :label="$t('summer.visitors.evaluation')"
                    counter="250"
                    rows="2"
                    persistent-hint
                    density="compact"
                    color="primary"
                    prepend-inner-icon="mdi-star"
                    :rules="rules.note"
                  ></VTextarea>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow class="mt-2">
              <VCol cols="12" md="6" lg="4">
                <VTextField
                  v-model="visitor.from_time"
                  :label="$t('summer.visitors.from')"
                  density="compact"
                  type="time"
                  clearable
                >
                </VTextField>
              </VCol>
              <VCol class="pt-0 px-3" cols="12" xs="10" md="6" lg="4">
                <VCardItem class="pa-0">
                  <VTextField
                    v-model="visitor.to_time"
                    :label="$t('summer.visitors.to')"
                    density="compact"
                    type="time"
                    clearable
                  >
                  </VTextField>
                </VCardItem>
              </VCol>
              <VCol class="pt-4 px-3" cols="12" xs="12" md="6" lg="4">
                <VCardItem class="pa-0">
                  <VRadioGroup
                    inline
                    v-model="visitor.visitortype"
                    @update:modelValue="
                      (this.visitor.adjective = null),
                        (this.visitor.occasion = null),
                        (this.visitor.impression = null),
                        (this.visitor.recommendation = null)
                    "
                  >
                    <VRadio :label="$t('summer.visitors.visit')" :value="false"></VRadio>
                    <VRadio
                      :label="$t('summer.visitors.dir-support')"
                      :value="true"
                    ></VRadio>
                  </VRadioGroup>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow class="pt-0 pb-0">
              <VCol
                class="pt-0 px-3"
                cols="12"
                xs="10"
                md="6"
                lg="6"
                v-if="!visitor.visitortype"
              >
                <VCardItem class="pa-0">
                  <VTextarea
                    v-model="visitor.impression"
                    :label="$t('summer.visitors.impression')"
                    persistent-hint
                    clearable
                    rows="1"
                    counter="50"
                    density="compact"
                    prepend-inner-icon="mdi-emoticon"
                    :rules="[$max_length(50)]"
                  ></VTextarea>
                </VCardItem>
              </VCol>
              <VCol
                class="pt-0 px-3"
                cols="12"
                xs="10"
                md="6"
                lg="6"
                v-if="!visitor.visitortype"
              >
                <VCardItem class="pa-0">
                  <VTextarea
                    v-model="visitor.recommendation"
                    :label="$t('summer.visitors.recommendations')"
                    persistent-hint
                    clearable
                    counter="50"
                    rows="1"
                    density="compact"
                    prepend-inner-icon="mdi-lightbulb-on"
                    :rules="[$max_length(50)]"
                  ></VTextarea>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow class="mt-2">
              <VCol
                class="pt-0 px-3"
                cols="12"
                xs="10"
                md="6"
                lg="6"
                v-if="!visitor.visitortype"
              >
                <VCardItem class="pa-0">
                  <VTextarea
                    v-model="visitor.adjective"
                    :label="$t('summer.visitors.adjective-visitor')"
                    persistent-hint
                    clearable
                    rows="1"
                    density="compact"
                    prepend-inner-icon="mdi-account-group"
                    :rules="[$max_length(50)]"
                  ></VTextarea>
                </VCardItem>
              </VCol>
              <VCol
                class="pt-0 px-3"
                cols="12"
                xs="10"
                md="6"
                lg="6"
                v-if="visitor.visitortype"
              >
                <VCardItem class="pa-0">
                  <VTextarea
                    v-model="visitor.occasion"
                    :label="$t('summer.visitors.occasion')"
                    persistent-hint
                    clearable
                    density="compact"
                    :rules="[$max_length(50)]"
                    prepend-inner-icon="mdi-calendar-star"
                  ></VTextarea>
                </VCardItem>
              </VCol>
            </VRow>
            <VRow class="pt-0 pb-0">
              <VCol cols="12" class="pt-0 px-3" xs="10" md="12" lg="12">
                <VCardItem class="pa-0">
                  <VTextarea
                    v-model="visitor.note"
                    color="primary"
                    clearable
                    :label="$t('summer.initiative.note')"
                    counter="250"
                    density="compact"
                    rows="2"
                    prepend-inner-icon="mdi-note-outline"
                    :rules="rules.note"
                  ></VTextarea>
                </VCardItem>
              </VCol>
            </VRow>
          </VCol>
          <VCol cols="12" md="3">
            <ImageInput
              :reset="image_reset"
              :image="visitor.img"
              :text="$t('summer.visitors.image-visitor')"
              @ImageSelected="saveIdImage"
              @resetRequest="imageReset"
            ></ImageInput>
          </VCol>
        </VRow>
      </VForm>
    </v-card-text>
    <VCardActions
      class="mx-1"
      v-if="
        CheckGetPermission('legal_school.add_visitor', [
          'sys_admin',
          'summer_admin',
          'user',
        ]) ||
        CheckGetPermission('legal_school.change_visitor', [
          'sys_admin',
          'summer_admin',
          'user',
        ])
      "
    >
      <VBtn
        v-if="
          !is_update &&
          CheckGetPermission('legal_school.add_visitor', [
            'sys_admin',
            'summer_admin',
            'user',
          ])
        "
        class="bg-primary"
        @click="savevisitor()"
        size="small"
        :loading="loading"
      >
        <span class="text-white">
          {{ $t("globals.add") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn
        v-if="
          is_update &&
          CheckGetPermission('legal_school.change_visitor', [
            'sys_admin',
            'summer_admin',
            'user',
          ])
        "
        class="bg-primary"
        @click="updatevisitor()"
        size="small"
        :loading="loading"
      >
        <span class="text-white">
          {{ $t("globals.edit") }}
        </span>
        <VIcon icon="mdi-content-save" color="white" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="resetForm()" v-if="this.id == undefined">
        {{ $t("globals.clear") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
      <VBtn class="mx-3" size="small" @click="showVisitor()" v-if="this.id != undefined">
        {{ $t("globals.cancel") }}
        <VIcon icon="mdi-broom" color="golden" end></VIcon>
      </VBtn>
    </VCardActions>
  </VCard>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import useValidate from "@vuelidate/core";
import DatetimePicker from "@/components/Globals/DatetimePicker.vue";
import ImageInput from "@/components/Globals/ImageInput.vue";
import moment from "moment-hijri";

export default {
  props: {
    id: {
      type: Number,
    },
  },
  data() {
    return {
      image_reset: 0,
      id_click: null,
      loading: false,
      homeworks: [],
      semesters: [],
      months: [],
      show: true,
      minDate: null,
      maxDate: null,
      visitor: {
        name_ar: undefined,
        adjective: "",
        impression: "",
        recommendation: "",
        evaluation: "",
        visitortype: false,
        occasion: undefined,
        date: undefined,
        fk_week: undefined,
        data_entry: undefined,
        updated_by: undefined,
      },
      sortBy: [
        {
          key: "name_ar",
          order: "asc",
        },
      ],
      dir_visitors: [],
      rules: {
        note: [
          (value) =>
            value == null ||
            value.length <= 250 ||
            `${this.$t("globals.biggest_number_of_character")} 250`,
        ],
      },
      dialogVisible: false,
      listWeeks: [],
      v$: useValidate(),
      icon_color: undefined,
      icon: undefined,
      alert_title: undefined,
      alert_message: undefined,
      is_update: false,
      updated_id: undefined,
      delete_id: undefined,
      adj_entity: "adjective",
      txt_search: undefined,
    };
  },
  components: { DatetimePicker, ImageInput },
  computed: {
    ...mapState({
      user: (state) => state.User,
    }),
    tableList() {
      if (this.visitors) {
        return JSON.parse(JSON.stringify(this.visitors));
      }
    },
    order_data() {
      try {
        return this.sortBy[0].order == "desc"
          ? `-${this.sortBy[0].key}`
          : this.sortBy[0].key;
      } catch (error) {
        return "id";
      }
    },
    CheckGetPermission() {
      return (prem, role) => {
        return this.$store.getters.checkpermission(prem, role);
      };
    },
  },
  async created() {
    try {
      await this.$store.commit("updateLoadingValue", true);
      await this.getDirVisitorsr();
      if (this.id != undefined) {
        await this.axios
          .get(`${this.base_url}/api/summer/visitor/${this.id}`, {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          })
          .then((response) => {
            this.visitor = response.data.results;
            this.visitor.img =
              this.visitor.img != null && this.visitor.img.search(this.base_url) == -1
                ? this.base_url + this.visitor.img
                : this.visitor.img;
          })
          .catch((err) => {});
        this.is_update = true;
      }
      if (this.visitor.fk_semester) {
        await this.getMonthsBySemester();
        await this.getSemester();
      } else {
        await this.getMonths();
        await this.getSemester();
      }
      this.$store.commit("updateLoadingValue", false);
    } catch (error) {
      this.$store.commit("updateLoadingValue", false);
    }
  },
  methods: {
    showVisitor() {
      this.$router.push({ name: "visitor" });
    },
    resetForm() {
      this.$refs.form.resetValidation();
      this.visitor = {};
      this.visitor.visitortype = false;
      this.is_update = false;
      this.updated_id = undefined;
      this.image_reset++;
    },
    imageReset(event) {
      this.image_reset = event;
    },
    saveIdImage(event) {
      this.visitor.img = event;
    },
    editvisitor(visitor) {
      this.visitor = visitor;
      this.is_update = true;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    cancelDeleteItem() {
      this.delete_id = undefined;
      this.dialogVisible = false;
    },
    async getSemester() {
      let semester = await this.axios.get(this.base_url + `api/summer/semesters/list/`, {
        headers: { Authorization: "Bearer " + localStorage.getItem("token") },
      });
      this.semesters = semester.data;
      if (!this.visitor.fk_semester) {
        const s = this.semesters.filter((e) => e.current_semester == true);
        this.visitor.fk_semester = s[0].id;
      }
    },
    async savevisitor() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        this.visitor.data_entry = this.user;
        if (this.visitor.img) this.visitor.img = this.visitor.img[0];

        // this.visitor.from_time = "01:12:00";
        if (!this.visitor.visitortype) this.visitor.occasion = "لايوجد";
        await this.axios
          .post(this.base_url + "api/summer/visitor", this.visitor, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_added"));
            this.visitor = {};
            this.resetForm();
            this.loading = false;
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("globals.failure"));
            if (error.response.data.title)
              this.alert_message = error.response.data.title[0];
            else this.alert_message = this.$t("globals.error_in_data");
            this.loading = false;
          });
      }
    },
    async getMonthsBySemester() {
      if (this.visitor.fk_semester) {
        await this.axios(
          `${this.base_url}/api/months-semester/?fk_semester=${this.visitor.fk_semester}`,
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        )
          .then((response) => {
            this.months = response.data;
          })
          .catch(() => {
            this.$emit("errorAlert", this.$t("globals.error_in_data"));
          });
      } else{        
        this.months = [];
      }
    },
    getCurrentMonth() {
      this.visitors = [];
      this.show = false;
      this.visitor.date = null;
      const monthId = this.months
        .filter((e) => e.id == this.visitor.fk_monthsemester)
        .reduce((map, item) => {
          map[item.id] = item.month;
          return map;
        });
      const month = monthId.month < 10 ? "0" + monthId.month.toString() : monthId.month;
      const now = moment();
      const startOfMonth = now.iYear() + "-" + month + "-01";
      const endOfMonth =
        now.iYear() + "-" + monthId.month + "-" + now.endOf("iMonth").iDate();
      this.minDate = startOfMonth;
      this.maxDate = endOfMonth;
      this.visitor.date = this.minDate;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    async getMonths() {
      this.loading = true;
      await this.axios(`${this.base_url}api/months-semester/`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.months = response.data;
        this.visitor.fk_monthsemester = this.months[0].id;
        this.loading = false;
        this.getCurrentMonth();
      });
    },
    async updatevisitor() {
      const { valid } = await this.$refs.form.validate();
      if (valid) {
        this.loading = true;
        this.visitor.data_entry = this.user;
        if (typeof this.visitor.img == "string") this.visitor.img = undefined;
        await this.axios
          .put(this.base_url + "api/summer/visitor/" + this.visitor.id, this.visitor, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          })
          .then((response) => {
            this.$emit("successAlert", this.$t("globals.data_updated"));
            this.loading = false;
            setTimeout(() => {
              this.resetForm();
              this.$router.push({ name: "visitor" });
            }, 1500);
          })
          .catch((error) => {
            this.$emit("errorAlert", this.$t("alert.failure.title"));
            this.loading = false;
          });
      }
    },
    async getDirVisitorsr() {
      await this.axios(`${this.base_url}/api/summer/choices/support-committees`, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }).then((response) => {
        this.dir_visitors = response.data;
      });
    },
  },
  watch: {},
};
</script>

<style scoped></style>
