import i18n from '@/i18n'
import moment from "moment-hijri";

const ValueValidationPlugin = {
  install(Vue) {

    // added by samer
    
   
    // required validation
    Vue.config.globalProperties.$required = (value) =>
      !!value ||
      typeof value === 'boolean' ||
      value === 0 ||
      i18n.global.t('errors.required')
    // numeric validation
    Vue.config.globalProperties.$numeric = (value) =>
      !value ||
      /^[0-9+',.]+$/.test(value) ||
      i18n.global.t('globals.must_be_numbers')
    // max_length validation

    Vue.config.globalProperties.$max_length =
      (length = 100) =>
      (value) => {
        if (value) {
          if (value && value.length > length)
            return `${
              i18n.global.t(
                isNaN(parseInt(value))
                  ? 'globals.biggest_number_of_character'
                  : 'globals.max_numbers_value',
              ) +
              ' ' +
              length
            }`
        }
        return true
      }
      Vue.config.globalProperties.$positive =       
      (value) => {
        if (value) {
          if (value && value < 0)
            return  i18n.global.t('errors.cannot_enter_negative_value')
        }
        return true
      }
    Vue.config.globalProperties.$max_length =
      (length = 100) =>
      (value) => {
        if (value) {
          if (value && value.length > length)
            return `${
              i18n.global.t(
                isNaN(parseInt(value))
                  ? 'globals.biggest_number_of_character'
                  : 'globals.max_numbers_value',
              ) +
              ' ' +
              length
            }`
        }
        return true
      }
    // min_length validation
    Vue.config.globalProperties.$min_length =
      (length = 10) =>
      (value) =>
        !value ||
        (value && value.length >= length) ||
        `${
          i18n.global.t('globals.lowest_number_of_character') +
          ' ' +
          length
        }`

    // ar_letters_only validation
    Vue.config.globalProperties.$ar_letters_only = (value) =>
      !value ||
      /^[\u0621-\u064A '0-9+ '-.,@#$%^&*()_+=!*?]+$/.test(value) ||
      i18n.global.t('globals.must_be_letters')

    // en_letters_only validation
    Vue.config.globalProperties.$en_letters_only = (value) =>
      !value ||
      /^[a-zA-Z' '0-9+  '-.,@#$%^&*()_+=!*?]+$/.test(value) ||
      i18n.global.t('globals.must_be_letters_en')

    // ar_with_numbers validation
    Vue.config.globalProperties.$ar_with_numbers = (value) =>
      !value ||
      /^[\u0621-\u064A0-9 ]+$/.test(value) ||
      i18n.global.t('globals.must_be_letters_with_number')

    // en_with_numbers validation
    Vue.config.globalProperties.$en_with_numbers = (value) =>
      !value ||
      /^[a-zA-Z'0-9 ]+$/.test(value) ||
      i18n.global.t('globals.must_be_letters_en_with_number')

    // max_date validation
    Vue.config.globalProperties.$max_date = (year) => (value) =>
      !value ||
      !year ||
      year >= value ||
      `${i18n.global.t('globals.max_date') + ' ' + year}`

    // max_date validation
    Vue.config.globalProperties.$min_date = (year) => (value) =>
      !value ||
      year < value ||
      `${i18n.global.t('globals.min_date') + ' ' + year}`

    // max_value validation
    Vue.config.globalProperties.$max_value =
      (length = 10) =>
      (value) => {
        if (value) {
          if (value && value > length)
            return `${
              i18n.global.t(
                isNaN(parseInt(value))
                  ? 'globals.max_characters'
                  : 'globals.max_numbers_value',
              ) +
              ' ' +
              length
            }`
        }
        return true
      }
    // min_value validation
    Vue.config.globalProperties.$min_value =
      (length = 10) =>
      (value) => {
        if (value) {
          if (value && value < length)
            return `${
              i18n.global.t(
                isNaN(parseInt(value))
                  ? 'globals.min_characters'
                  : 'globals.min_numbers_value',
              ) +
              ' ' +
              length
            }`
        }
        return true
      }

      Vue.config.globalProperties.$int_valid =
       
      (value) => {
        if (value) {
          if (value && value > 2147483647)  
            return  i18n.global.t('globals.max_characters')             
        }
        return true
      }
   
      Vue.config.globalProperties.$bigInt_valid =
       
      (value) => {
        if (value) {
          if (value && value > 9223372036854775807)
            return `${
              i18n.global.t(
                isNaN(parseInt(value))
                  ? 'globals.max_characters'
                  : 'globals.max_numbers_value',
              ) +
              ' ' +
              '9223372036854775807'
            }`
        }
        return true
      }


    // email validation
    Vue.config.globalProperties.$email = (value) =>
      !value ||
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ||
        i18n.global.t('email')

    // more than
    Vue.config.globalProperties.$more_than = (value2) => (value) =>
      !value ||
      !value2 ||
      parseFloat(value) > parseFloat(value2) ||
      i18n.global.t(' اقل من ') + value2

    // less than
    Vue.config.globalProperties.$less_than = (value2) => (value) =>
      !value ||
      !value2 ||
      parseFloat(value) < parseFloat(value2) ||
      i18n.global.t(' أكبر من ') + value2

    // less than
    
    Vue.config.globalProperties.$isTime =    
      (value) => {        
         const pattern = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/
         if(pattern.test(value))return true
         else return i18n.global.t('less_than')        
      }
    Vue.config.globalProperties.$isDate =    
      (value) => { 

        const pattern = /^\d{4}-\d{2}-\d{2}$/
        if(pattern.test(value) || !value)return true
        else  return i18n.global.t('errors.invalid-date') 

        
      }
    Vue.config.globalProperties.$time_min_max =    
    (min,max) => {
      if(min && max){
        const [hour1,minute1] = min.split(":")
        const [hour2,minute2] = max.split(":")
        const date1 = new Date()
        date1.setHours(parseInt(hour1,10))
        date1.setMinutes(parseInt(minute1,10))
        const date2 = new Date()
        date2.setHours(parseInt(hour2,10))
        date2.setMinutes(parseInt(minute2,10))
  
        if(min>max || min==max)
        return 'وقت النهاية اصغر من البداية'
  
        else return true
      }     
    },
    Vue.config.globalProperties.$validUniqe =
    (value) => {
      if (value) return  'موجود مسبقاََ'      
      return true
    }
    // check between two times
    Vue.config.globalProperties.$compare_time =
      (start, end) => {
        if (start && end) {
          const [start_hr, start_min] = start.split(':').map(Number)
          const [end_hr, end_min] = end.split(':').map(Number)

          const startTime = start_hr * 60 + start_min
          const endTime = end_hr * 60 + end_min

          return startTime <= endTime || i18n.global.t('wrong_time')
        }
    }
    Vue.config.globalProperties.$max_number =
    (length = 10) =>
    (value) => {
      if (value) {
        if (value && value > length)
          return `${
            i18n.global.t(
             "globals.max_numbers") +
            ' ' +
            length
          }`
      }
      return true
    }

    Vue.config.globalProperties.$addNameToRouter =function
    (name,name_ar){     
      let route = this.$route.matched.find((e)=>e.name == name)
      route.name_ar = name_ar
    }


  function compraHijriData (start, end) {
      if (start && end) {
        const hijriDate1 = moment(start,'iYYYY-iMM-iDD')
        const hijriDate2 = moment(end,'iYYYY-iMM-iDD')        
        return hijriDate1.diff(hijriDate2)
      }
  }
    //مقارنة اذا كان التاريخ الثاني اكبر من الاول 
    Vue.config.globalProperties.$compare_date =
      (start, end) => {
        if (start && end) {
          return compraHijriData(start,end) > 0 ?true : i18n.global.t('errors.start-date')
          }
          
        }

        Vue.config.globalProperties.$getCurrentMonths =function
        (fk_monthsemester){     
            if(fk_monthsemester){
                var date = null;
                const month =
                 fk_monthsemester < 10
                    ? "0" +fk_monthsemester.toString()
                    :fk_monthsemester;
                const now = moment();
                const startOfMonth = now.iYear() + "-" + month + "-01";
              
          
                const endOfMonth = moment(`${now.iYear()}-${ fk_monthsemester +1 }-01`,'iYYYY-iM-iDD').subtract(1,'day').format('iYYYY-iMM-iDD')
                const minDate = startOfMonth;
                const maxDate = endOfMonth;
          
                if (current_month && fk_monthsemester == moment().format("iM"))
                  date = moment().format("iYYYY-iMM-iDD")
                else 
                date = minDate;
          
          
                this.$nextTick(() => {
                  // this.show = true;
                });
              }                  
        }
        
    }
   

  
}

export default ValueValidationPlugin
